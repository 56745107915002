import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { QUESTIONER_MODE_EXTENRAL } from '../../background/socialv1/socialv1Reducer';
import { RISK_LEVELS, RISK_LEVEL_COLORS, RISK_LEVEL_LABELS } from '../../shared/Constants/RiskLevelConstants';


const RiskLevelItem = ({ riskLevel, type }) => {
    const { t } = useTranslation();
    const theme = useSelector((state)=>state.brand.theme);

    return (
        <div className={type === riskLevel ? 'risk-item risk-item--active' : 'risk-item'}>
            <div style={{ backgroundColor: RISK_LEVEL_COLORS[type] }}>
                &nbsp;
            </div>
            <span style={{color:theme?.Text?.SystemColor}}>{t(RISK_LEVEL_LABELS[type])}</span>
        </div>
    );
};


const RiskLevelDisplay = ({ yearPnl, user }) => {
    const { t } = useTranslation();
    const questionerMode = useSelector((state) => { return state.account.socialv1.socialAccount.questionerMode; });

    if (questionerMode === QUESTIONER_MODE_EXTENRAL) {
        if (yearPnl) {
            if (yearPnl?.UserMeasures?.MaxDrawDown <= 0) {
                return null;
            }
            let score = 0;
            if (yearPnl.UserMeasures.MaxDrawDown * 100 > 50) {
                score = 2;
            } else if (yearPnl.UserMeasures.MaxDrawDown * 100 > 25) {
                score = 1;
            }
            return (
                <div className='risk-level__container'>
                    <div className='risk-level__header'>{t('Social.Profile.RiskLevel')}</div>
                    <div className='risk-level__text'>
                        <RiskLevelItem type={RISK_LEVELS.RISK_HIGH} riskLevel={score} />
                        <RiskLevelItem type={RISK_LEVELS.RISK_MEDIUM} riskLevel={score} />
                        <RiskLevelItem type={RISK_LEVELS.RISK_LOW} riskLevel={score} />
                    </div>
                </div>
            );
        }
    } else {
        const riskLevel = user?.UserDetails?.RiskLevel || '';
        return (
            <div className='profile-page__one-column green'>
                {t('Social.Profile.RiskLevel')}
                :
                &nbsp;
                {riskLevel}
            </div>
        );
    }

    return null;
};

export default RiskLevelDisplay;
