/* eslint-disable complexity */
import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import Radio from '@material-ui/core/Radio';
// import PropTypes from 'prop-types';
import PubsubSubscription from '../../background/repositories/PubsubSubscription';

import { CommandFactory } from '../../background/commands/CommandFactory';
import InputStepper from '../primitives/InputStepper';
import Button from '../primitives/Button';
import LoadSpinner from '../primitives/LoadSpinner';
import ModalWrapper from '../primitives/ModalWrapper';
import depositButtonClickHandler from '../utilsUI/depositButtonClickHandler';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';
import { QUESTIONER_MODE_EXTENRAL } from '../../background/socialv1/socialv1Reducer';
import { normalizedEditNumber } from '../OpenPositions/updateNumericHelper';

import CurrencySelectExpanded from './CurrencySelectExpanded';
import { InvalidAmountCopy, OverCopy } from './InvalidAmountCopy';
import CopyDisclaimer from './CopyDisclaimer';
import CopyBlockDisclaimer from './CopyBlockDisclaimer';


const CopyTab = ({
    nickname,
    yearPnl,
    user,
    alreadyCopied,
    setSelectedTrader,
    getProfileData,
    handlePersistCopyTab,
    handleUncopy,
    generalColor,
    copyColor,
    generalButtonColor,
    systemColor,
    setQuestionnaire,
    setJoinPopup
}) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [isEquityValidForCopy, setIsEquityValidForCopy] = useState(true);
    const [showDisclaimer, setShowDisclaimer] = useState(false);
    const [showBlockDisclaimer, setShowBlockDisclaimer] = useState(false);
    const [requestResult, setRequestResult] = useState(null);
    const [copyData, setCopyData] = useState(null);
    const [userData, setUserData] = useState({
        amount: null,
        equity: '',
        lotAmount: '',
        instruments: [],
        copyType: 0
    });
    const [radio, setRadio] = useState(false);
    const [showResponseInfo, setShowResponseInfo] = useState(false);
    const [currencySelect, setCurrencySelect] = useState(false);
    const {
        copyRelationsCommands: {
            addFollower,
            updateFollower
        },
        profileCommands: { getDataForCopy },
        socialAccount: {
            questionerMode,
            externalRiskScore,
            queryRiskScoreCMD
        },
        lfsRegulationsDetails,
        hideCopyByFixedLot,
        userDetails
    } = useSelector((state) => { return (state.account.socialv1); }, shallowEqual);
    const { currency: { symbol } } = useSelector((state) => (state.account.accountInfo), shallowEqual);
    const { balance } = useSelector((state) => (state.account.financial), shallowEqual);
    const { MainColor: { ActiveText, InactiveText } } = useSelector((state) => (state.brand.theme), shallowEqual);
    const { deposit: { depositUrl, getDepositUrlCommand } } = useSelector((state) => { return (state.account.accountInfo.featuresData); }, shallowEqual);

    const handleCloseCopyTab = () => {
        setSelectedTrader({ nickname, avatar: user?.UserDetails?.Avatar });
        handlePersistCopyTab(false);
    };

    const toggleDisclaimer = () => {
        setShowDisclaimer((prevState) => !prevState);
    };

    const getCopyData = async () => {
        setIsLoading(true);
        const userInput = { masterNickname: nickname || user?.UserDetails?.Nickname };
        const command = CommandFactory.createCommand(getDataForCopy);

        try {
            // setRequestResult(null);
            const res = await command.execute(userInput);

            if (res.ok === false) {
                setCopyData({ result: res.statusText });
            }
            if (res.isSuccessful === true) {
                setCopyData(res.payload);
            } else if (res.isSuccessful === false) {
                setCopyData(res);
            }
        } catch (err) {
            setCopyData(err);
        }

        // setIsLoading(false);
    };

    // Calculate isEquityValidForCopy
    useEffect(() => {
        let isSet = false;
        const subscription = PubsubSubscription.subscribeObjNative({ key: 'MOVINGPNL', repId: 'ACCOUNT_PNL' }, (pnl) => {
            if (!isSet) {
                const equityStepperAmount = normalizedEditNumber(userData?.equity, parseFloat(userData?.equity));
                const isInvalidEquityAmount = equityStepperAmount > pnl.equity && !radio;
                const isOverCopy = equityStepperAmount < pnl.equity && (((userData?.copyType === 1 || userData?.copyType === 2) && equityStepperAmount + copyData?.TotalAllocationMargin - userData?.amount > balance)
                                   || (!userData?.copyType === 1 && !userData?.copyType === 2 && equityStepperAmount + copyData?.TotalAllocationMargin > equityStepperAmount > balance)) && !radio;

                if (isEquityValidForCopy) {
                    if (isInvalidEquityAmount || isOverCopy) {
                        setIsEquityValidForCopy(false);
                        isSet = true;
                    }
                } else if (!isInvalidEquityAmount && !isOverCopy) {
                    setIsEquityValidForCopy(true);
                    isSet = true;
                }
            }
        });

        // useEffect Cleanup
        return () => {
            subscription.dispose();
        };
    }, [user, isEquityValidForCopy, radio, balance, userData?.equity, userData?.copyType, userData?.amount, copyData, copyData?.TotalAllocationMargin]);

    useEffect(() => {
        const eq = user?.CopyType === 2 ? user?.Amount : user?.DefaultEquityAllocationAmount;
        const lot = user?.CopyType === 1 ? user?.Amount : user?.DefaultCopyByFixedLotAmount;
        setUserData({
            amount: user?.Amount,
            equity: eq,
            lotAmount: lot,
            instruments: user?.Instruments || [],
            copyType: user?.CopyType
        });
        getCopyData().then(() => setIsLoading(false));
        if (user?.CopyType === 1) {
            setRadio(true);
        }
    }, [user]);

    useEffect(() => {
        SendEventToGoogleAnalytics('Profile:CopyTabOpened');
    }, []);

    const handleAddFollower = async (remove = false) => {
        setIsLoading(true);
        const userInput = {
            masterNickname: nickname,
            amount: radio ? normalizedEditNumber(userData?.lotAmount, Number(userData?.lotAmount)) : normalizedEditNumber(userData?.equity, Number(userData?.equity)),
            copyType: radio ? 1 : 2,
            copiedSourceType: 0,
            instruments: userData?.instruments
        };

        // if (alreadyCopied && remove) {
        //     userInput = { guruNickname: nickname, amount: 1, copyType: 4 };
        //     commandObj = {
        //         ...updateFollower,
        //         userInput
        //     };
        // }

        const command = CommandFactory.createCommand(addFollower);

        try {
            setRequestResult(null);
            const res = await command.execute(userInput);

            if (res.ok === false) {
                setRequestResult({ result: res.statusText });
            }
            if (res.isSuccessful === true) {
                setRequestResult(res.payload);
                handlePersistCopyTab(true);
                if (remove) {
                    handleCloseCopyTab();
                } else {
                    setShowResponseInfo(true);
                }
                getProfileData(nickname);
            } else if (res.isSuccessful === false) {
                setRequestResult(res);
                setIsLoading(false);
            }
        } catch (err) {
            setRequestResult(err);
            setIsLoading(false);
        }
    };

    const handleValue = (value) => {
        setUserData((prevState) => ({
            ...prevState, equity: value
        }));
    };

    const handleLotValue = (value) => {
        setUserData((prevState) => ({
            ...prevState, lotAmount: value
        }));
    };

    const handleSetInstruments = (value) => {
        setUserData((prevState) => ({
            ...prevState, instruments: value
        }));
    };

    const toggleRadio = () => {
        setRadio((prevState) => !prevState);
    };

    const canCopy = (riskLevel,maxDrawDown) =>
    {
        if (maxDrawDown <=0 || maxDrawDown === undefined || maxDrawDown === null)
        {
            return false;
        }

        let masterScore = 0;
        if (maxDrawDown * 100 > 50)
        {
            masterScore = 2;
        }
        else if (maxDrawDown * 100 > 25)
        {
            masterScore = 1;
        }
        
        if (riskLevel <= 0)
        {
            return false;
        }

        if (riskLevel >= 1 && riskLevel <25)
        {
            return masterScore === 0;
        }
        else if (riskLevel >= 25 && riskLevel <75)
        {
            return masterScore === 0 || masterScore === 1;
        }
        else if (riskLevel >= 75)
        {
            return true;
        }

        return false;
    }
    
    const externalQuestionerMode = (riskLevel) => {
        setIsLoading(false);
        if (yearPnl?.UserMeasures?.MaxDrawDown) {
            if (canCopy(riskLevel,yearPnl?.UserMeasures?.MaxDrawDown)) {
                handleAddFollower(false);
            } else {
                setShowBlockDisclaimer(true);
            }
        } else {
            setShowBlockDisclaimer(true);
        }
    };

    const handleAddCopy = () => {
        SendEventToGoogleAnalytics(alreadyCopied ? 'Profile:Copy:EditCopyClick' : 'Profile:Copy:CopyClick', { masterNickname: nickname });
        if (!userDetails.isEnabled) {
            setJoinPopup(true);
        } else if (questionerMode === QUESTIONER_MODE_EXTENRAL) {
            setIsLoading(true);
            if (!externalRiskScore) {
                try {
                    CommandFactory.createCommand(queryRiskScoreCMD).execute()
                        .catch((ex) => { externalQuestionerMode(0); })
                        .then((value) => {
                            if (value?.payload?.socialAccount?.externalRiskScore) {
                                externalQuestionerMode(value.payload.socialAccount.externalRiskScore);
                            } else {
                                externalQuestionerMode(0);
                            }
                        });
                } catch (exception) { externalQuestionerMode(0); }
            } else {
                externalQuestionerMode(externalRiskScore);
            }
        } else if (!lfsRegulationsDetails.isLfsRegulated) {
            handleAddFollower(false);
        } else if (!lfsRegulationsDetails.lfsFinishedSuitabilityTest) {
            setQuestionnaire(true);
        } else if (user.UserDetails.RiskLevel > lfsRegulationsDetails.lfsRiskScore) {
            setShowDisclaimer(true);
        } else {
            handleAddFollower(false);
        }
    };

    const handleRemoveCopy = () => {
        SendEventToGoogleAnalytics('Profile:Copy:UnCopyClick', { masterNickname: nickname });
        handleUncopy();
        // handleAddFollower(true);
    };

    const handleOpenCurrency = () => {
        setCurrencySelect(true);
    };

    const handleCloseCurrency = () => {
        setCurrencySelect(false);
    };

    const handleDepositClick = () => {
        depositButtonClickHandler(depositUrl, getDepositUrlCommand);
    };

    const text = radio ? t('Social.CopyTab.ByEquityDesc', { nickname, symbol: userData?.lotAmount, equity: '(lots)' }) : t('Social.CopyTab.ByEquityDesc', { nickname, symbol, equity: userData?.equity });
    const textAlreadyCopied = userData?.copyType === 1 ? t('Social.CopyTab.CurrCopyStatus', { nickname, symbol: userData?.amount, equity: '(lots)' }) : t('Social.CopyTab.CurrCopyStatus', { nickname, symbol, equity: userData?.amount });
    const disableCopy = parseFloat(userData?.lotAmount) <= 0 || parseFloat(userData?.equity) <= 0 || userData?.instruments === null ? 'btn btn-copy no-pointer' : 'btn btn-copy';
    let hideCopyByFixedLotState = hideCopyByFixedLot;
    if (hideCopyByFixedLotState && alreadyCopied && userData?.copyType === 1) {
        hideCopyByFixedLotState = false;
    }

    return (
        <div className='copy-tab' style={generalColor}>
            {showResponseInfo ? (
                <div className='copy-tab__response'>
                    <p>
                        {t('Social.CopyTab.CopyResponse', { nickName: nickname })}
                    </p>
                    <Button text={t('General.Close')} className='btn btn-close' style={copyColor} onClickHandler={handleCloseCopyTab} />
                </div>
            ) : (
                <Fragment>
                    {!isLoading ? (
                        <Fragment>
                            {alreadyCopied && (
                                <div className='already-copied'>
                                    <h4>{textAlreadyCopied}</h4>
                                    <Button
                                        text={t('Button.Uncopy')}
                                        className='btn btn-uncopy btn--general'
                                        style={generalButtonColor}
                                        iconStart='fas fa-redo'
                                        onClickHandler={handleRemoveCopy}
                                    />
                                </div>
                            )}
                            <div className='copy-tab--not-copied'>
                                <p>{alreadyCopied ? `${t('Social.CopyTab.EditCopyDesc')}:` : `${t('Social.CopyTab.NewCopyDesc')}:`}</p>
                                <div className='copy-tab__form-row'>
                                    <div className='copy-tab__form-radio'>
                                        <Radio
                                            checked={!radio}
                                            onChange={toggleRadio}
                                            name='radio-button-equity'
                                            inputProps={{ 'aria-label': t('Social.CopyTab.ByEquity') }}
                                            style={!radio ? { color: ActiveText } : { color: InactiveText }}
                                        />
                                        <span onClick={toggleRadio}>{t('Social.CopyTab.ByEquity')}</span>
                                    </div>
                                    {radio === false && (
                                        <div className='copy-tab__form-group'>
                                            <div>
                                                <InputStepper selectedUnits={userData?.equity} setValue={handleValue} min={user?.MinimumEquityAllocationAmount} stepValue={1} pipDigit={0} />
                                            </div>
                                            {symbol}
                                            <span>{t('General.Equity')}</span>
                                        </div>
                                    )}
                                </div>
                                {!hideCopyByFixedLotState
                                  && (
                                      <div className='copy-tab__form-row'>
                                          <div className='copy-tab__form-radio'>
                                              <Radio
                                                  checked={radio}
                                                  onChange={toggleRadio}
                                                  name='radio-button-equity'
                                                  inputProps={{ 'aria-label': t('Social.CopyTab.ByFixed') }}
                                                  style={radio ? { color: ActiveText } : { color: InactiveText }}
                                              />
                                              <span onClick={toggleRadio}>{t('Social.CopyTab.ByFixed')}</span>
                                          </div>
                                          {radio === true && (
                                              <div className='copy-tab__form-group'>
                                                  <div>
                                                      <InputStepper selectedUnits={userData?.lotAmount} setValue={handleLotValue} max={user?.MaximumCopyByFixedLotAmount} stepValue={0.10} pipDigit={2} roundMax />
                                                  </div>
                                                  (lots)
                                                  <span>{t('Social.CopyTab.ByFixedHeader')}</span>
                                              </div>
                                          )}
                                      </div>
                                  )}
                                {parseFloat(userData?.equity) <= 0 && !radio && (
                                    <div className='warning'>
                                        {t('Social.CopyTab.Errors.InvalidAmount')}
                                    </div>
                                )}
                                {userData?.equity === '' && !radio && (
                                    <div className='warning'>
                                        {t('Social.CopyTab.Errors.EmptyEquity')}
                                    </div>
                                )}
                                {parseFloat(userData?.lotAmount) <= 0 && radio && (
                                    <div className='warning'>
                                        {t('Social.CopyTab.Errors.InvalidAmount')}
                                    </div>
                                )}
                                {userData?.lotAmount === '' && radio && (
                                    <div className='warning'>
                                        {t('Social.CopyTab.Errors.EmptyEquity')}
                                    </div>
                                )}
                                <InvalidAmountCopy userData={userData} radio={radio} />
                                <OverCopy userData={userData} radio={radio} copyData={copyData} />
                                <p className='copy-tab__result'>{text}</p>
                                <div className='currency-select'>
                                    <div className='currency-list--inline'>
                                        <p>
                                            <span>{t('Social.CopyTab.SelectedInstrumentP1')}</span>
                                            {userData?.instruments?.length ? userData?.instruments.map((item) => <strong key={item}>{item}</strong>) : <strong>{t('Social.CopyTab.SelectedInstrumentAll')}</strong>}
                                            <span>{t('Social.CopyTab.SelectedInstrumentP2')}</span>
                                        </p>
                                    </div>
                                    <div className='currency-select__group'>
                                        <Button
                                            text={t('Social.CopyTab.SelectSymbols')}
                                            className='btn btn-currency btn--general'
                                            // style={generalButtonColor}
                                            onClickHandler={handleOpenCurrency}
                                        />
                                        {currencySelect && copyData && (
                                            <CurrencySelectExpanded
                                                portfolio={copyData}
                                                handleClick={handleCloseCurrency}
                                                instruments={userData?.instruments}
                                                setInstruments={handleSetInstruments}
                                                systemColor={systemColor}
                                            />
                                        )}
                                    </div>
                                </div>
                                <hr />
                                {!isEquityValidForCopy
                                    ? (
                                        <div
                                            className='link-copy'
                                            style={copyColor}
                                            onClick={handleDepositClick}
                                        >
                                            {userData?.copyType === 1 || userData?.copyType === 2 ? t('General.Save') : t('Socials.Button.Copy')}
                                        </div>
                                    ) : (
                                        <Button
                                            text={userData?.copyType === 1 || userData?.copyType === 2 ? t('General.Save') : t('Socials.Button.Copy')}
                                            className={disableCopy}
                                            style={copyColor}
                                            onClickHandler={handleAddCopy}
                                        />
                                    )}
                            </div>
                            <ModalWrapper
                                open={showDisclaimer}
                                heading={t('Socialv1.Suitability.Copy.Disclaimer.Title')}
                                handleClose={toggleDisclaimer}
                                childClassName='modal-window--disclaimer'
                                contentClassName='content'
                            >
                                <CopyDisclaimer
                                    masterNickname={nickname}
                                    handleClose={toggleDisclaimer}
                                    copyColor={copyColor}
                                    handleAddCopy={handleAddFollower}
                                />
                            </ModalWrapper>

                            <ModalWrapper
                                open={showBlockDisclaimer}
                                heading={t('Socialv1.Suitability.Copy.Disclaimer.Title')}
                                handleClose={() => setShowBlockDisclaimer(false)}
                                childClassName='modal-window--disclaimer'
                                contentClassName='content'
                            >
                                <CopyBlockDisclaimer
                                    masterNickname={nickname}
                                    handleClose={() => setShowBlockDisclaimer(false)}
                                />
                            </ModalWrapper>
                        </Fragment>
                    ) : (
                        <LoadSpinner />
                    )}
                </Fragment>
            )}
        </div>
    );
};

// CopyTab.propTypes = {};

export default CopyTab;
