import {useCallback, useEffect, useMemo, useRef} from 'react';

import {
	getTouches,
	swipeBurger,
	swipeFooter,
	swipeProfile,
	swipeTrade,
} from '../helpers/swipe.helpers';

import {getStyleItem} from '../helpers/carousel.helpers';

import useWindowSize from '../../UI/utilsUI/useWindowSize';

const getTypeMove = (type, data) => {
	switch (type) {
		case 'footer':
			return swipeFooter.move(data);
		case 'trade':
			return swipeTrade.move(data);
		case 'profile':
		case 'positionClose':
		case 'positionUpdate':
		case 'avatar':
			return swipeProfile.move(data);
		case 'burger':
			return swipeBurger.move(data);
		default:
			return data => data;
	}
};

const getTypeEnd = (type, data) => {
	switch (type) {
		case 'footer':
			return swipeFooter.end(data);
		case 'trade':
			return swipeTrade.end(data);
		case 'profile':
		case 'positionClose':
		case 'positionUpdate':
		case 'avatar':
			return swipeProfile.end(data);
		case 'burger':
			return swipeBurger.end(data);
		default:
			return data => data;
	}
};

const useSwiper = props => {
	const {type, touchRef = null, callback, options} = props;
	const {onStart, onMove, onEnd, onOpen, onClose} = callback;
	const {height, isOpen} = options;

	const [width] = useWindowSize();

	const contentRef = useRef(null);

	const ref = useMemo(() => {
		return touchRef && touchRef?.current
				? touchRef?.current
				: contentRef?.current;
	}, [contentRef?.current, touchRef, touchRef?.current]);

	const handleStart = useCallback(event => {
		const firstTouch = getTouches(event)[0];

		let xDown = firstTouch.clientX;
		let yDown = firstTouch.clientY;

		let xDiff = null;
		let yDiff = null;

		let xUp = null;
		let yUp = null;

		let yMove = 0;
		let xMove = 0;

		const item = contentRef?.current;
		const style = getStyleItem(item);

		const transformY = style?.transformY || 0;
		const transformX = style?.transformX || 0;

		const startData = {
			...props,
			...callback,
			...options,
			item,
			style,
			contentRef,
			touchRef,
			xDown,
			yDown,
			height,
			isOpen,
			onOpen,
			onClose,
			yMove,
			xMove,
			width,
		};

		onStart && onStart(startData);

		const handleMove = evt => {
			if (!xDown || !yDown) return null;

			xUp = evt.touches[0].clientX;
			yUp = evt.touches[0].clientY;

			xDiff = xDown - xUp;
			yDiff = yDown - yUp;

			yMove = transformY - yDiff;
			xMove = transformX - xDiff;

			const moveData = {
				...startData,
				xUp,
				yUp,
				xDiff,
				yDiff,
				yMove,
				xMove,
				width,
			};

			onMove && onMove(moveData);
			getTypeMove(type, moveData);
		};

		const handleEnd = () => {
			const endData = {
				...startData,
				xDiff,
				yDiff,
				xUp,
				yUp,
				yMove,
				xMove,
				isOpen,
				onOpen,
				onClose,
				width,
			};

			onEnd && onEnd(endData);
			getTypeEnd(type, endData);

			document.removeEventListener('touchmove', handleMove, {passive: false});
			document.removeEventListener('touchend', handleEnd);
		};

		document.addEventListener('touchmove', handleMove, {passive: false});
		document.addEventListener('touchend', handleEnd);
	}, [height, isOpen, ref]);

	useEffect(() => {
		if (!height || !ref) return;
		ref.addEventListener('touchstart', handleStart);
		return () => {
			ref.removeEventListener('touchstart', handleStart);
		};
	}, [height, isOpen, ref, touchRef]);

	return {
		contentRef,
	};
};

export default useSwiper;
