import React, {useRef} from 'react';
import useControls from '../../context/contextHook/useControls';
import {shallowEqual, useSelector} from 'react-redux';
import PubsubSubscription from '../../../background/repositories/PubsubSubscription';
import MobileFooterHeader from './MobileFooterHeader';
import MobileFooterSection from './MobileFooterSection';
import MobileSwiper from '../../components/MobileSwiper';

const MobileFooterOld = () => {

	const {
		footerControls: {
			isOpen,
			handleOpen,
			footerRef,
			isVisible,
		},
	} = useControls();

	const touchRef = useRef(null);

	const {
		financial: {balance, credit, margin},
		accountInfo: {currency: {symbol}},
	} = useSelector(state => state.account, shallowEqual);

	const pnlTick = PubsubSubscription.subscribeObj(
			{key: 'MOVINGPNL', repId: 'ACCOUNT_PNL'});

	return (
			<>
				{!!isVisible && (
						<footer
								ref={footerRef}
								className="mobile-footer__container"
						>
							<MobileSwiper
									touchRef={touchRef}
									className="mobile-footer-content"
									type='footer'
							>
								<div className="mobile-footer-content__wrapper">
									<MobileFooterHeader
											reference={touchRef}
											onOpen={handleOpen}
											isOpen={isOpen}
											symbol={symbol}
											pnlTick={pnlTick}
											balance={balance}
											margin={margin}
											credit={credit}
									/>
									{isOpen && (
											<MobileFooterSection isOpen={isOpen}/>
									)}
								</div>
							</MobileSwiper>

						</footer>
				)}
			</>
	);
};

export default MobileFooterOld;
