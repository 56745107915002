import React, {useMemo} from 'react';
import {ReactComponent as Edit} from '../../assets/icons/edit.svg';
import {shallowEqual, useSelector} from 'react-redux';

const MobileBurgerMenuHeader = props => {
  const {handleAvatarClick, currentAvatar, userDetails} = props;

  const {
    accountInfo: {
      rightPanelSettings: {
        socialv1: {isVisible},
      },
    },
    socialv1: {isEnabled},
  } = useSelector(state => state.account);

  const environments = useSelector(state => state.environments.environments,
      shallowEqual);

  const envName = environments[userDetails.envId]?.displayName ?? '';

  const renderName = useMemo(() => {
    return `${userDetails.name} (${userDetails.tpUserId})`;
  }, [userDetails]);

  const isSocialsEnabled = useMemo(() => {
    return isEnabled && isVisible;
  }, [isEnabled, isVisible]);

  const hideAvatarIcon = useMemo(() => {
    return isSocialsEnabled;
  }, []);

  return (
      <div className='mobile-burger__header'>

        <div className="mobile-burger__header__avatar-content">
          {!hideAvatarIcon && (
              <div
                  className="mobile-burger__header__avatar__wrap"
                  onClick={handleAvatarClick}
              >
                <div className="mobile-burger__header__avatar">
                  {currentAvatar && (
                      <img src={currentAvatar} alt="avatar"/>
                  )}
                  <div className="mobile-burger__header__avatar__edit">
                    <Edit/>
                  </div>
                </div>
              </div>
          )}
        </div>

        <h2 className="mobile-burger__header__head">
          {renderName}
        </h2>

        <p className="mobile-burger__header__name">
          {userDetails.showEnvironment && (
              <>
                {envName}
              </>
          )}
        </p>
      </div>
  );
};

export default MobileBurgerMenuHeader;
