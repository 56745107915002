import React, {useCallback, useMemo, useRef, useState} from 'react';

import MobileNothingFound from '../../MobileEmpty/MobileNothingFound';
import MobileAutosizer from '../../MobileAutosizer/MobileAutosizer';
import MobileProfilePositionItemOpen
	from './MobileProfilePositionItem/MobileProfilePositionItemOpen';
import MobileProfilePositionItemHead
	from './MobileProfilePositionItem/MobileProfilePositionItemHead';
import {sortProfilePosition} from '../../../helpers/sort.helpers';

const initialSort = {
	value: 'open_time',
	type: true,
};

const MobileProfilePositionOpen = ({positions}) => {
	const [sortData, setSortData] = useState(initialSort);

	const ref = useRef(null);

	const handleFilterClick = useCallback(item => {
		ref && ref.current && ref.current.forceUpdateGrid();
		return setSortData(prev => {
			const value = item.value;
			const type = prev.value === item.value
					? !prev.type
					: true;
			return {value, type};
		});
	}, [sortData, setSortData]);

	const sortedOptions = useMemo(() => {
		return sortProfilePosition(positions, sortData);
	}, [sortData, positions]);

	const renderRow = useCallback(data => {
		const {item, props} = data;
		return (
				<MobileProfilePositionItemOpen
						{...item}
						{...props}
						itemType="open"
				/>
		);
	}, [sortedOptions]);

	if (!positions || !positions.length) {
		return (
				<MobileNothingFound/>
		);
	}

	return (
			<div className="mobile-profile-position__wrapper">
				<MobileProfilePositionItemHead
						type='open'
						active
						sort={sortData}
						onActive={handleFilterClick}
				/>
				<MobileAutosizer
						reference={ref}
						options={sortedOptions}
						renderRow={renderRow}
						rowHeight={40}
				/>
			</div>
	);
};

export default MobileProfilePositionOpen;
