import {adjustURL}  from './mobileUrlService'

export const STDCMDAsyncServerCmdID = "STD.CMD.AsyncServer"; 



export async function STDCMDAsyncServerHandler(cmd,userInput)
{
  let store = cmd.getStore();
  let staticData = cmd.getStatic();
  let storeData = cmd.getStoreData();
  if (!userInput)
  {
    userInput = cmd.getUserInput();
  }
  
  if (staticData.postUrl)
  {
    fetch(adjustURL(staticData.postUrl),{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'superweb-session':window.sessionStorage.getItem('superweb-session') ?? ''
      },
      body: JSON.stringify({
        userInput: userInput,
        storeData: storeData,
        staticData:staticData})
      });
  }

  if (staticData.type)
  {
    let f = store.dispatch({
      type:staticData.type,
      payload:userInput,
      storeData: storeData,
      staticData:staticData,
    });

    return f;
  }

} 
