import React from 'react';
import { useTranslation } from 'react-i18next';


const FormattedSLTPProfit = ({ profit, symbol, noText = false }) => {
    const { t } = useTranslation();
    let formatProfit = null;
    const profitToDisplay = Math.round((profit + Number.EPSILON) * 100) / 100;
    if (profit >= 0) {
        formatProfit = (
            <span className='green'>
                {symbol}
                {profitToDisplay}
            </span>
        );
    } else {
        formatProfit = (
            <span className='red'>
                -
                {symbol}
                {profitToDisplay * -1}
            </span>
        );
    }

    return (
        <p>
            {!noText && (
                <span>
                    {t('Trade.Execution.EstimatedProfit')}
                    :
                </span>
            )}
            &nbsp;
            {formatProfit}
        </p>
    );
};

export default FormattedSLTPProfit;
