import { getStore } from '../storeContainer';

const updateFunction = function () {
    const store = getStore().getState();
    if (store.brand.theme?.MainColor?.ActiveText) {
        document.documentElement.style.setProperty('--line-color', store.brand.theme.MainColor.ActiveText);
        document.documentElement.style.setProperty('--copy-button', store.brand.theme.Social.CopyBackground);
        document.documentElement.style.setProperty('--scroll-color', store.brand.theme.MainColor.ScrollColor);
        document.documentElement.style.setProperty('--main-text-color', store.brand.theme.Text.GeneralColor);
    }
    if (store.brand.themeSettings?.ActiveTheme !== 'dark') {
        // document.body.setAttribute('class', 'theme--light');
        // document.documentElement.style.setProperty('--main-bg-color', '#fff');
        document.documentElement.style.setProperty('--main-bg-color', '#f5f6fa');
        // document.documentElement.style.setProperty('--secondary-bg-color', '#f5f5fa');
        document.documentElement.style.setProperty('--secondary-bg-color', '#E9EBF3');
        document.documentElement.style.setProperty('--tertiary-bg-color', '#fff');
        // document.documentElement.style.setProperty('--field-bg-color', '#dcdcdc');
        document.documentElement.style.setProperty('--field-bg-color', '#fff');
        document.documentElement.style.setProperty('--main-text-color', '#283B55');
        document.documentElement.style.setProperty('--positive-digit', '#04B66B');
        document.documentElement.style.setProperty('--negative-digit', '#CE4B4B');
        document.documentElement.style.setProperty('--scroll-color', store.brand.theme.MainColor.ScrollColor);
    }
    if (store.brand.themeSettings?.ActiveTheme === 'dark') {
        // document.body.setAttribute('class', 'theme--dark');
        document.documentElement.style.removeProperty('--main-bg-color');
        document.documentElement.style.removeProperty('--secondary-bg-color');
        document.documentElement.style.removeProperty('--tertiary-bg-color');
        document.documentElement.style.removeProperty('--field-bg-color');
        document.documentElement.style.removeProperty('--main-text-color');
        // document.documentElement.style.setProperty('--positive-digit', store.brand.theme.Profit.Positive);
        document.documentElement.style.setProperty('--positive-digit', '#66C3A7');
        // document.documentElement.style.setProperty('--negative-digit', store.brand.theme.Profit.Negative);
        document.documentElement.style.setProperty('--negative-digit', '#DB6161');
    }
};

export default function Init() {
    const store = getStore();
    function select(state) {
        return state.brand.themeSettings.ActiveTheme;
    }

    let currentValue = select(store.getState());

    store.subscribe(() => {
        const previousValue = currentValue;
        currentValue = select(store.getState());

        if (previousValue !== currentValue) {
            updateFunction();
        }
    });
}
