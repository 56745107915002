import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';

const TickItemBidAsk = ({ bid, ask, subHeaderColor }) => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <div className='bid-item__bid'>
                <span style={subHeaderColor}>{t('MarketRates.Bid')}</span>
                <div>{bid}</div>
            </div>
            <div className='bid-item__ask'>
                <span style={subHeaderColor}>{t('MarketRates.Ask')}</span>
                <div>{ask}</div>
            </div>
        </Fragment>
    );
};

// TickItemBidAsk.propTypes = {};

export default TickItemBidAsk;
