import { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { CommandFactory } from '../../../background/commands/CommandFactory';

const useProfileUserData = (userName) => {
    const [userProfile, setUserProfile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const { getUserProfilePage } = useSelector(
        (state) => state.account.socialv1.profileCommands, shallowEqual
    );

    const getUserData = useCallback(async () => {
        if (!userName || !getUserProfilePage) return;
        const userInput = { nickname: userName, FolowersMax: 4, followingMax: 4 };

        const command = CommandFactory.createCommand(getUserProfilePage);
        setIsLoading(true);
        try {
            const res = await command.execute(userInput);
            if (res.isSuccessful) setUserProfile(res.payload);
            setIsLoading(false);
            return res;
        } catch (err) {
            console.error(err);
            setIsLoading(false);
        }
    }, [getUserProfilePage, userName]);

    useEffect(() => {
        getUserData();
    }, [getUserData]);

    return {
        userProfile,
        isLoading,
        requestData: () => getUserData()
    };
};

export default useProfileUserData;
