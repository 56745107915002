/* eslint-disable complexity */
import React, { Fragment, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
// import PropTypes from 'prop-types';


import { CommandFactory } from '../../background/commands/CommandFactory';
import PasswordField from '../primitives/PasswordField';
import CheckBoxField from '../primitives/CheckBoxField';
import LoadSpinner from '../primitives/LoadSpinner';
import LanguageSelector from '../LanguageSelector/LanguageSelector';

import QuickLogin from './QuickLogin';
import './LoginPage.css';


const LoginPage = () => {
    const { environments, environmentsDisplayOrder } = useSelector((state) => (state.environments), shallowEqual);
    const { generalInfo: { logoLogin }, enableChangeLanguage } = useSelector((state) => (state.brand), shallowEqual);
    const { t } = useTranslation();
    const envSelection = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const envToDisplay in environmentsDisplayOrder) {
        const foundEnv = environments[environmentsDisplayOrder[envToDisplay]];
        if (foundEnv) {
            envSelection.push(foundEnv);
        }
    }

    const [stateObject, setStateObject] = useState({ userId: '', tpPassword: '', environment: envSelection.length ? envSelection[0].id : '' });
    const [displayFullForm, setDisplayFullForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRememberMe, setIsRememberMe] = useState(false);
    const [error, setError] = useState(false);
    const { userId, tpPassword, environment } = stateObject;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setStateObject((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleReturnToFullForm = () => {
        setDisplayFullForm((prevState) => (!prevState));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const selectedEnv = envSelection.find((item) => item.id === environment);

        const command = CommandFactory.createCommand(selectedEnv.loginWithTPId);

        setIsLoading(true);

        try {
            setError('');
            const res = await command.execute({
                rememberMe: isRememberMe,
                tpNumber: userId,
                tpPassword,
                requestVer: 3,
            });
            if (res.isSuccessful === undefined) {
                setError(res);
                setIsLoading(false);
            } else if (res.isSuccessful === false) {
                setError(res);
                setIsLoading(false);
            }
        } catch (err) {
            setError(err);
            setIsLoading(false);
        }
    };

    const handleClickRemeberme = () => {
        setIsRememberMe((prevState) => (!prevState));
    };

    const toggleIsLoading = () => {
        setIsLoading((prevState) => !prevState);
    };


    if (!envSelection.length) {
        return <p>{t('Smth.Went.Wrong')}</p>;
    }

    const shouldDisplayQuickLogin = envSelection.find((item) => Object.keys(item.prevLogins).length > 0);


    return (
        <div className='login__page'>
            {isLoading && <LoadSpinner overlay />}
            <div className='form__wrapper'>
                <div className='form__container'>
                    <div className='login__page__logo'>
                        <img src={logoLogin} />
                    </div>
                    <form name='form'>
                        { shouldDisplayQuickLogin && !displayFullForm ? (
                            <Fragment>
                                <QuickLogin
                                    environments={envSelection}
                                    backAction={handleReturnToFullForm}
                                    isRememberMe={isRememberMe}
                                    handleClickRemeberme={handleClickRemeberme}
                                    toggleIsLoading={toggleIsLoading}
                                />
                            </Fragment>
                        ) : (
                            <Fragment>
                                <h2>{t('Login.Header.SignIn')}</h2>
                                <FormControl fullWidth className='login__select'>
                                    <TextField
                                        id='standard-select-environment'
                                        select
                                        placeholder='environment'
                                        autoComplete='environment'
                                        name='environment'
                                        value={environment}
                                        onChange={handleChange}
                                        helperText={t('Login.Form.Select.Environment')}
                                    >
                                        {envSelection.map((option) => (
                                            <MenuItem key={option.displayName} value={option.id}>
                                                {t(option.translatedName)}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                                <FormControl fullWidth>
                                    <Input
                                        id='input-with-icon-adornment'
                                        placeholder={t('Login.Form.UserId')}
                                        autoComplete='userId'
                                        name='userId'
                                        onChange={handleChange}
                                    />
                                </FormControl>
                                <PasswordField password={tpPassword} handlePasswordChange={handleChange} />
                                <CheckBoxField isRememberMe={isRememberMe} handleClickRemeberme={handleClickRemeberme} />

                                { error && (
                                    error.status
                                        ? (
                                            <p className='error__message'>
                                                {error.status}
                                                &nbsp;
                                                {error.statusText}
                                            </p>
                                        ) : <p className='error__message'>{error.errorDescription ? t(error.errorDescription) : t('Login.Thenical.Issue')}</p>
                                )}

                                <Button fullWidth className='button--primary' onClick={handleSubmit} type='submit'>
                                    {t('Login.Button.Login')}
                                </Button>
                                { shouldDisplayQuickLogin && displayFullForm && (
                                    <div className='backward-block' onClick={handleReturnToFullForm}>
                                        <IconButton aria-label='back to previous environments'>
                                            <ArrowBackIosIcon />
                                        </IconButton>
                                    </div>
                                )}
                            </Fragment>
                        )}
                    </form>
                    {!!enableChangeLanguage && (
                        <div className='language-picker__container'>
                            <LanguageSelector />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

// LoginPage.propTypes = {};

export default LoginPage;
