import React from 'react';
// import PropTypes from 'prop-types';

const Candels = ({ fill }) => {
    return (
        <svg width='90' height='80' viewBox='0 0 90 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M20.4129 22.019C20.4129 21.1906 19.7413 20.519 18.9129 20.519C18.0844 20.519 17.4129 21.1906 17.4129 22.019L17.4129 31.6311H12C10.8954 31.6311 10 32.5266 10 33.6311V63.8904C10 64.995 10.8954 65.8904 12 65.8904H17.4129L17.4129 75.5004C17.4129 76.3288 18.0844 77.0004 18.9129 77.0004C19.7413 77.0004 20.4129 76.3288 20.4129 75.5004L20.4129 65.8904H25.5926C26.6972 65.8904 27.5926 64.995 27.5926 63.8904V33.6311C27.5926 32.5266 26.6972 31.6311 25.5926 31.6311H20.4129L20.4129 22.019ZM13 34.6311V62.8904H24.5926V34.6311H13Z' fill={fill} />
            <path fillRule='evenodd' clipRule='evenodd' d='M45.3972 3.5C45.3972 2.67157 44.7257 2 43.8972 2C43.0688 2 42.3972 2.67157 42.3972 3.5L42.3972 12.3821H36.9844C35.8798 12.3821 34.9844 13.2775 34.9844 14.3821V42.3948C34.9844 43.4994 35.8798 44.3948 36.9844 44.3948H42.3972L42.3972 53.2777C42.3972 54.1062 43.0688 54.7777 43.8972 54.7777C44.7257 54.7777 45.3972 54.1062 45.3972 53.2777L45.3972 44.3948H50.577C51.6816 44.3948 52.577 43.4994 52.577 42.3948V14.3821C52.577 13.2775 51.6816 12.3821 50.577 12.3821H45.3972V3.5ZM37.9844 15.3821V41.3948H49.577V15.3821H37.9844Z' fill={fill} />
            <path fillRule='evenodd' clipRule='evenodd' d='M72.2644 15.5381C72.2644 14.7097 71.5929 14.0381 70.7644 14.0381C69.936 14.0381 69.2644 14.7097 69.2644 15.5381L69.2644 25.1497H63.8516C62.747 25.1497 61.8516 26.0451 61.8516 27.1497V57.409C61.8516 58.5135 62.747 59.409 63.8516 59.409H69.2644L69.2644 69.0194C69.2644 69.8478 69.936 70.5194 70.7644 70.5194C71.5929 70.5194 72.2644 69.8478 72.2644 69.0194L72.2644 59.409H77.4442C78.5487 59.409 79.4442 58.5135 79.4442 57.409V27.1497C79.4442 26.0451 78.5487 25.1497 77.4442 25.1497H72.2644L72.2644 15.5381ZM64.8516 28.1497V56.409H76.4442V28.1497H64.8516Z' fill={fill} />
        </svg>
    );
};

// Candels.propTypes = {};

export default Candels;
