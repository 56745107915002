import React, {useCallback, useState} from 'react';

import MobileSocialExplore
	from '../components/MobileSocial/MobileSocialExplore/MobileSocialExplore';
import MobileWatchModal from '../components/MobileModal/MobileWatchModal';
import useStreamConnect from '../hook/useStreamConnect';
import useTopRankedData from '../hook/useFetchData/useTopRankedData';
import useProfile from '../context/contextHook/useProfile';
import MobileLoader from '../components/MobileLoader/MobileLoader';

const MobileSocialExplorePage = ({isVisible}) => {
	const {tradersAll} = useStreamConnect(); //TODO: mb this array?

	const [watchModalName, setWatchModalName] = useState(null);

	const {
		profileUser: {openProfileUser},
	} = useProfile();

	const handleOpenWatchModal = useCallback(name => {
		setWatchModalName(name);
	}, [setWatchModalName]);

	const handleCloseWatchModal = useCallback(() => {
		setWatchModalName(null);
	}, [setWatchModalName]);

	const handleOpenProfile = useCallback(() => {
		handleCloseWatchModal && handleCloseWatchModal();
		openProfileUser && openProfileUser(watchModalName, true);
	}, [openProfileUser, watchModalName]);

	const {topTraders, isLoading} = useTopRankedData();

	return (
			<>
				{isVisible && (
						<>
							<MobileLoader isLoading={isLoading} size="large"/>

							<MobileWatchModal
									isOpen={!!watchModalName}
									onClose={handleCloseWatchModal}
									onSuccess={handleOpenProfile}
									userName={watchModalName}
							/>

							{!isLoading && (
									<MobileSocialExplore
											handleOpenWatchModal={handleOpenWatchModal}
											options={topTraders}
									/>
							)}
						</>

				)}
			</>
	);
};

export default MobileSocialExplorePage;
