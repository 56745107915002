/* eslint-disable complexity */
import React, {
    useState, useEffect, useMemo, Fragment
} from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';

import { CommandFactory } from '../../background/commands/CommandFactory';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';
import Button from '../primitives/Button';
// import LoadSpinner from '../primitives/LoadSpinner';
import ModalWrapper from '../primitives/ModalWrapper';
import { formatDateOnly } from '../utilsUI/formatTime';
import CountryFlagByCode from '../Flag/CountryFlagByCode';
import TooltipWrapper from '../primitives/TooltipWrapper';

import ProfileTab from './ProfileTab';
import PortfolioTab from './PortfolioTab';
import CommunityTab from './CommunityTab';
import PositionsTab from './PositionsTab';
import CopyTab from './CopyTab';
import Suggestions from './Suggestions';
import WatchPopup from './WatchPopup';
import UncopyPopup from './UncopyPopup';
import RiskLevelDisplay from './RiskLevelDisplay';
import './ProfilePage.css';

// export const ProficiencyLevelType = {
//     CHAMPION: 0,
//     GRANDMASTER: 1,
//     MASTER: 2,
//     EXPERT: 3,
//     TRADER: 4
// };

export const PROFICIENCY_LEVEL = [
    'CHAMPION',
    'GRANDMASTER',
    'MASTER',
    'EXPERT',
    'TRADER'
];

const ProfilePage = ({
    user,
    yearPnl,
    setSelectedTrader,
    toggleExplorePage,
    clodeUserModal,
    openCopyTab,
    handleCloseCopyTab,
    isLoadingProfile,
    userName,
    avatar,
    copiers,
    joinDate,
    portfolio,
    isSameUser,
    getProfileData,
    setQuestionnaire,
    setJoinPopup
}) => {
    const { t } = useTranslation();
    const [tabValue, setTabValue] = useState(openCopyTab ? 4 : 0);
    const [persistCopyTab, setPersistCopyTab] = useState(false);
    const joinDateValue = user?.UserDetails?.MetaJoinDate || joinDate;
    // const nickName = user?.UserDetails?.Nickname || '';
    const [nickName, setNickName] = useState(null);
    const [history, setHistory] = useState([]);
    const [forward, setForward] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [showUncopyPopup, setShowUncopyPopup] = useState(false);
    const riskLevel = user?.UserDetails?.RiskLevel || '';
    const avatarValue = user?.UserDetails?.Avatar || avatar;
    const copiersCount = user?.CopiersCount || copiers || 0;
    const socialFeed = user?.SocialFeed || [];
    const suggestionPerformers = user?.SuggestionPerformers || [];
    const alreadyCopied = user?.CopyType === 1 || user?.CopyType === 2;
    const watching = user?.CopyType === 4 || alreadyCopied;
    const copying = user?.Following || [];
    const flag = user?.UserDetails?.Country || '';
    const proficiency = user?.UserDetails?.ProficiencyLevel || 0;
    const { copyRelationsCommands: { updateFollower, removeFollower } } = useSelector((state) => { return (state.account.socialv1); }, shallowEqual);
    const {
        MainColor, Text: { GeneralColor, SystemColor }, Social: { CopyBackground, CopyTextColor }, Buttons: { TextColor }
    } = useSelector((state) => (state.brand.theme));
    const generalColor = useMemo(() => ({ color: GeneralColor }), [GeneralColor]);
    const copyColor = useMemo(() => ({ color: CopyTextColor, backgroundColor: CopyBackground }), [CopyBackground, CopyTextColor]);
    const generalButtonColor = useMemo(() => ({ color: TextColor }), [TextColor]);

    const toggleUncopyPopup = () => {
        setShowUncopyPopup((prevState) => !prevState);
    };

    const handleWatch = async () => {
        const userInput = { guruNickname: userName, amount: watching ? 0 : 1, copyType: watching ? 0 : 4 };

        let commandObj = {
            ...updateFollower,
            userInput
        };

        if (watching) {
            commandObj = {
                ...removeFollower,
                userInput: {
                    guruNickname: userName
                }
            };
        }
        SendEventToGoogleAnalytics(watching ? 'Profile:UnWatchClick' : 'Profile:WatchClick', { masterNickname: userName });
        const command = CommandFactory.createCommand(commandObj);

        try {
            const res = await command.execute();

            if (res.ok === false) {
                // setRequestResult({ result: res.statusText });
            }
            if (res.isSuccessful === true) {
                setSelectedTrader({ nickname: userName, avatar: user?.UserDetails?.Avatar });
                // eslint-disable-next-line no-unused-expressions
                !watching && setShowPopup(true);
            } else if (res.isSuccessful === false) {
                // setRequestResult(res);
            }
        } catch (err) {
            // setRequestResult(err);
        }
    };

    const handleUncopyFollower = async () => {
        // setIsLoading(true);
        const userInput = { guruNickname: userName, amount: 1, copyType: 4 };

        const command = CommandFactory.createCommand(updateFollower);

        try {
            // setRequestResult(null);
            const res = await command.execute(userInput);

            if (res.ok === false) {
                // setRequestResult({ result: res.statusText });
            }
            if (res.isSuccessful === true) {
                getProfileData(userName);
                setTabValue(0);
                handleCloseCopyTab();
                toggleUncopyPopup();
            } else if (res.isSuccessful === false) {
                // setRequestResult(res);
            }
        } catch (err) {
            // setRequestResult(err);
        }

        // setIsLoading(false);
    };

    const handleOuterOpenTab = () => {
        SendEventToGoogleAnalytics(alreadyCopied ? 'Profile:EditCopyClick' : 'Profile:CopyClick', { masterNickname: userName });
        setTabValue(4);
    };

    const handlePersistCopyTab = (value) => {
        setPersistCopyTab(value);
        if (!value) {
            setTabValue(0);
        }
    };

    const goToCommunity = () => {
        setTabValue(2);
    };

    const clearActiveTab = () => {
        setTabValue(0);
    };

    const handleTabChange = (event, newValue) => {
        let newTabValue;
        if (event) {
            newTabValue = event.target.getAttribute('name');
        } else {
            newTabValue = newValue;
        }
        const finalTabValue = parseInt(newTabValue, 10);
        setTabValue(finalTabValue);

        let eventName = '';
        if (finalTabValue === 0) {
            eventName = 'ProfileTabClick';
        } else if (finalTabValue === 1) {
            eventName = 'PortfolioTabClick';
        } else if (finalTabValue === 2) {
            eventName = 'CommunityTabClick';
        } else if (finalTabValue === 3) {
            eventName = 'PositionsTabClick';
        } else if (finalTabValue === 4) {
            eventName = 'CopyTabClick';
        }
        SendEventToGoogleAnalytics(`Profile:${eventName}`);
    };

    useEffect(() => {
        if (openCopyTab || persistCopyTab) {
            setTabValue(4);
        } else {
            setTabValue(0);
        }

        setNickName(user?.UserDetails?.Nickname);
        // return handleCloseCopyTab();
    // }, [user]);
    }, [user]);

    const handleUserSelect = (selectedUser) => {
        if (selectedUser.nickname !== userName) {
            setSelectedTrader(selectedUser);
            setHistory([...history, userName]);
            setForward([]);
            handleCloseCopyTab();
        }
    };

    const openUser = (e) => {
        handleUserSelect({ nickname: e.target.getAttribute('data-name'), avatar: e.target.getAttribute('data-avatar'), shouldOpenCopy: false });
    };

    const closeWatchPopup = () => {
        setShowPopup(false);
    };

    const handleUncopy = () => {
        handleUncopyFollower();
    };

    const goBack = () => {
        if (history.length > 0) {
            const lastPage = history[history.length - 1];
            setHistory(history.slice(0, history.length - 1));
            setForward([userName, ...forward]);
            setSelectedTrader({ nickname: lastPage });
        }
    };

    const goForward = () => {
        if (forward.length > 0) {
            const nextPage = forward[0];
            setHistory([...history, userName]);
            setForward(forward.slice(1, forward.length));
            setSelectedTrader({ nickname: nextPage });
        }
    };


    return (
        <div className='profile-page__container' style={{ color: GeneralColor }}>
            <div className='profile-page__top'>
                <div className='profile-page__left'>
                    <div className='profile-page__name-block'>
                        <Button className={history.length > 0 ? 'btn-history' : 'btn-history btn-history--disabled'} iconStart='fa fa-arrow-left' onClickHandler={goBack} />
                        <Button className={forward.length > 0 ? 'btn-history' : 'btn-history btn-history--disabled'} iconEnd='fa fa-arrow-right' onClickHandler={goForward} />
                    </div>
                    <p className='profile-page__nickname'>
                        {nickName || userName}
                    </p>
                    <div className='profile-page__joined'>
                        <span>
                            {t('Social.Profile.JoinLabel')}
                            :&nbsp;
                            {joinDateValue && formatDateOnly(new Date(joinDateValue))}
                        </span>
                    </div>
                    <div className='profile-page__copiers'>
                        <span>
                            {t('Social.CopiersText')}
                            :
                            &nbsp;
                            {copiersCount}
                        </span>
                    </div>
                    <div className='avatar-wrapper'>
                        <div className={`avatar ${avatarValue.toLowerCase()}`}>
                            <div className={`proficiency--flag proficiency--${PROFICIENCY_LEVEL[proficiency].toLowerCase()}-flag`} />
                            <div className='country__flag'>
                                {flag && CountryFlagByCode(flag)}
                            </div>
                            <div className={`proficiency proficiency--${PROFICIENCY_LEVEL[proficiency].toLowerCase()}`} />
                        </div>
                    </div>
                    <div className='profile-page__two-columns profile-page__two-columns--small'>
                        <span>
                            &nbsp;
                        </span>
                        <span className={`proficiencyColor proficiencyColor--${PROFICIENCY_LEVEL[proficiency].toLowerCase()}`}>{PROFICIENCY_LEVEL[proficiency].toLowerCase()}</span>
                    </div>
                    <RiskLevelDisplay user={user} yearPnl={yearPnl} />
                    {/* <div className='profile-page__two-columns large-font'>
                        <span>{t('Social.CopyingText')}</span>
                        <Button text={t('Social.Profile.More')} style={generalButtonColor} className='btn btn-more btn--general' onClickHandler={goToCommunity} />
                    </div> */}
                    {/* <div className='profile-page__following-list'>
                        {Array.isArray(copying) && copying?.length > 0 && copying.map((item) => {
                            return (
                                <div className={`avatar ${item?.Avatar.toLowerCase()}`} data-name={item?.Nickname} data-avatar={item?.Avatar} onClick={openUser} key={item?.Nickname}>
                                    <div className='tooltip'>{item?.Nickname}</div>
                                </div>
                            );
                        })}
                    </div> */}
                    <div className='profile-page__buttons-block'>
                        {!isSameUser && !isLoadingProfile && (
                            <Button
                                text={alreadyCopied ? t('Social.Button.EditCopy') : t('Socials.Button.Copy')}
                                className='btn btn-copy'
                                style={copyColor}
                                onClickHandler={handleOuterOpenTab}
                            />
                        )}
                        {!isSameUser && !isLoadingProfile && (
                            <Button
                                text={watching ? t('Social.Button.Unwatch') : t('Social.Button.Watch')}
                                className={alreadyCopied ? 'btn btn-watch btn--general no-pointers' : 'btn btn-watch btn--general'}
                                // style={generalButtonColor}
                                onClickHandler={handleWatch}
                            />
                        )}
                    </div>
                </div>
                <div className='profile-page__right'>
                    <div className='tabs'>
                        <div className='tab-list'>
                            <div className='profile-page__tabs'>
                                <TooltipWrapper
                                    arrow
                                    classes={{ tooltip: 'profile-tooltip' }}
                                    title={t('ProfilePage.Profile.Title')}
                                >
                                    <div
                                        className={tabValue === 0 ? 'tab-list-item tab-list-active' : 'tab-list-item'}
                                        onClick={handleTabChange}
                                        name={0}
                                        style={{ color: tabValue === 0 ? MainColor.ActiveText : MainColor.InactiveText }}
                                    >
                                        {t('ProfilePage.Profile.Title')}
                                    </div>
                                </TooltipWrapper>
                                <TooltipWrapper arrow classes={{ tooltip: 'profile-tooltip' }} title={t('ProfilePage.Portfolio.Title')}>
                                    <div
                                        className={tabValue === 1 ? 'tab-list-item tab-list-active' : 'tab-list-item'}
                                        onClick={handleTabChange}
                                        name={1}
                                        style={{ color: tabValue === 1 ? MainColor.ActiveText : MainColor.InactiveText }}
                                    >
                                        {t('ProfilePage.Portfolio.Title')}
                                    </div>
                                </TooltipWrapper>
                                <TooltipWrapper arrow classes={{ tooltip: 'profile-tooltip' }} title={t('ProfilePage.Community.Title')}>
                                    <div
                                        className={tabValue === 2 ? 'tab-list-item tab-list-active' : 'tab-list-item'}
                                        onClick={handleTabChange}
                                        name={2}
                                        style={{ color: tabValue === 2 ? MainColor.ActiveText : MainColor.InactiveText }}
                                    >
                                        {t('ProfilePage.Community.Title')}
                                    </div>
                                </TooltipWrapper>
                                <TooltipWrapper arrow classes={{ tooltip: 'profile-tooltip' }} title={t('ProfilePage.Positions.Title')}>
                                    <div
                                        className={tabValue === 3 ? 'tab-list-item tab-list-active' : 'tab-list-item'}
                                        onClick={handleTabChange}
                                        name={3}
                                        style={{ color: tabValue === 3 ? MainColor.ActiveText : MainColor.InactiveText }}
                                    >
                                        {t('ProfilePage.Positions.Title')}
                                    </div>
                                </TooltipWrapper>
                                {!isSameUser && (
                                    <TooltipWrapper arrow classes={{ tooltip: 'profile-tooltip' }} title={t('ProfilePage.Copy.Title')}>
                                        <div
                                            className={tabValue === 4 ? 'tab-list-item tab-list-active' : 'tab-list-item'}
                                            onClick={handleTabChange}
                                            name={4}
                                            style={{ color: tabValue === 4 ? MainColor.ActiveText : MainColor.InactiveText }}
                                        >
                                            {t('ProfilePage.Copy.Title')}
                                        </div>
                                    </TooltipWrapper>
                                )}
                            </div>
                        </div>
                        <div className='tab-content'>
                            {tabValue === 0 && (
                                <ProfileTab
                                    socialFeed={socialFeed}
                                    risklevel={riskLevel}
                                    userName={userName}
                                    joinDateValue={joinDateValue}
                                    generalColor={generalColor}
                                />
                            )}

                            {tabValue === 1 && <PortfolioTab nickname={userName} generalColor={generalColor} activeColor={MainColor.ActiveText} />}

                            {tabValue === 2 && <CommunityTab nickname={userName} getProfileData={handleUserSelect} generalColor={generalColor} />}

                            {tabValue === 3 && <PositionsTab nickname={userName} setSelectedTrader={handleUserSelect} generalColor={generalColor} />}

                            {!isSameUser && tabValue === 4 && (
                                <Fragment>
                                    {user && (
                                        <CopyTab
                                            nickname={userName}
                                            user={user}
                                            yearPnl={yearPnl}
                                            alreadyCopied={alreadyCopied}
                                            setSelectedTrader={handleUserSelect}
                                            portfolio={portfolio}
                                            getProfileData={getProfileData}
                                            handlePersistCopyTab={handlePersistCopyTab}
                                            handleUncopy={toggleUncopyPopup}
                                            generalColor={generalColor}
                                            copyColor={copyColor}
                                            generalButtonColor={generalButtonColor}
                                            systemColor={SystemColor}
                                            setQuestionnaire={setQuestionnaire}
                                            setJoinPopup={setJoinPopup}
                                        />
                                    )}
                                </Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className='profile-page__bottom'>
                <Suggestions
                    users={suggestionPerformers}
                    getProfileData={handleUserSelect}
                    toggleExplorePage={toggleExplorePage}
                    clodeUserModal={clodeUserModal}
                    isLoadingProfile={isLoadingProfile}
                    clearActiveTab={clearActiveTab}
                    generalColor={generalColor}
                    generalButtonColor={generalButtonColor}
                />
            </div>
            {showPopup && (
                <ModalWrapper heading={t('Social.Button.Watch')} open={showPopup} childClassName='modal--small'>
                    <WatchPopup setOpenCopyTab={handleOuterOpenTab} handleClick={closeWatchPopup} copiedUserName={nickName} copyColor={copyColor} generalButtonColor={generalButtonColor} />
                </ModalWrapper>
            )}
            {showUncopyPopup && (
                <ModalWrapper heading={t('Social.ConfirmUncopy')} open={showUncopyPopup} childClassName='modal--small'>
                    <UncopyPopup handleUncopy={handleUncopy} handleClick={toggleUncopyPopup} copiedUserName={nickName} copyColor={copyColor} generalButtonColor={generalButtonColor} />
                </ModalWrapper>
            )}
        </div>
    );
};

// ProfilePage.propTypes = {};

export default ProfilePage;
