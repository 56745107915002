import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import { CommandFactory } from '../../../background/commands/CommandFactory'

const useCommunityFetch = (nickname, kind) => {
	const {getFriends} = useSelector(
			state => state.account.socialv1.profileCommands, shallowEqual);

	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState(null);

	const requestData = useCallback(async () => {
		if (!nickname) return null;
		const userInput = {nickname, kind: kind, length: 100};
		
		const command = CommandFactory.createCommand(getFriends);
		try {
			setIsLoading(true);
			const res = await command.execute(userInput);
			if (!res.isSuccessful) {
				const err = `Get profile friends for ${userInput.nickname} request error`;
				console.error(err); //TODO: remove log set error
				setIsLoading(false);
			}
			if (res.isSuccessful) {
				setData(res.payload);
				setIsLoading(false);
			}
		} catch (err) {
			console.error(err); //TODO: remove log
			setIsLoading(false);
		}
	}, [nickname, kind, getFriends, setIsLoading, setData]);

	useEffect(() => {
		requestData();
	}, [requestData]);

	return {isLoading, data, requestData};
};

export default useCommunityFetch;
