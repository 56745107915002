import React, {useCallback, useEffect, useState} from 'react';

import useWindowSize from '../../../UI/utilsUI/useWindowSize';
import useControls from '../../context/contextHook/useControls';

const MobileSection = ({children}) => {
	const [sectionHeight, setSectionHeight] = useState();
	const {
		headerControls: {headerRef},
		footerControls: {footerRef, isVisible},
		sectionControls: {setHeight},
	} = useControls();

	const [width, height] = useWindowSize();

	const handleSetHeight = useCallback(heightValue => {
		const currentHeight = heightValue || sectionHeight;
		setHeight && setHeight(currentHeight);
		setSectionHeight(currentHeight);
	}, [sectionHeight, setHeight]);

	const getHeaderHeight = useCallback(() => {
		if (headerRef && !headerRef.current) return 0;
		return headerRef.current.offsetHeight;
	}, [headerRef]);

	const getFooterHeight = useCallback(() => {
		if ((footerRef && !footerRef.current) || !isVisible) return 0;
		return footerRef.current.offsetHeight;
	}, [footerRef, isVisible]);

	useEffect(() => {
		const header = getHeaderHeight();
		const footer = getFooterHeight();
		const h = height - header - footer;
		handleSetHeight(h);
	}, [width, height, getHeaderHeight, getFooterHeight, handleSetHeight]);

	return (
			<section
					className="mobile-section"
					style={{height: sectionHeight}}
			>
				{children}
			</section>
	);
};

export default MobileSection;
