import React, {useCallback, useMemo} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import { CommandFactory } from '../../../background/commands/CommandFactory'

const THEME = {
	DARK: 'dark',
	LIGHT: 'light',
};

const useProviderTheme = () => {
	const {theme, themeSettings} = useSelector(state => state.brand,
			shallowEqual);
	const generalInfo = useSelector(state => state.brand.generalInfo,
			shallowEqual);

	const {logoMain} = useSelector(
			state => state.brand.generalInfo, shallowEqual);

	const logo = useMemo(() => {
		let currentLogo = themeSettings.ActiveTheme === THEME.DARK
				? themeSettings.DarkTheme.LogoIcon
				: themeSettings.LightTheme.LogoIcon;
		if (!currentLogo) {
			currentLogo = generalInfo.logoMain;
		}
		return currentLogo;
	}, [generalInfo.logoMain, themeSettings.ActiveTheme]);

	const handleToggleTheme = useCallback(async () => {
		const {ToggleThemeCommand} = themeSettings;
		const command = CommandFactory.createCommand(ToggleThemeCommand);
		try {
			return await command.execute();
		} catch (err) {
			console.error(err);
		}
	}, [themeSettings]);

	return {
		theme,
		themeSettings,
		allowChangeTheme: themeSettings.AllowChangeTheme,
		activeTheme: themeSettings.ActiveTheme,
		isDarkTheme: themeSettings.ActiveTheme === THEME.DARK,
		isLightTheme: themeSettings.ActiveTheme === THEME.LIGHT,
		handleToggleTheme: handleToggleTheme,
		logo: logo || logoMain,
		hideLogo: themeSettings.hideLogo,
		lightTheme: themeSettings.LightTheme,
		darkTheme: themeSettings.DarkTheme,
	};
};

export default useProviderTheme;
