import {getPathObject}  from '../StoreUtils/getPathObject'
import PubsubSubscription from '../repositories/PubsubSubscription'
import {getStore} from '../storeContainer'

const _cmdMap = new Map()

class Command
{
  constructor(handlerFunc,cmdData)
  {
    this._cmdData = cmdData;
    this._handlerFunc = handlerFunc;
    this.execute = this.execute.bind(this);
  }

  getStore()
  {
    return getStore();
  }

  getStatic()
  {
    if (!this._cmdData.staticParams)
    {
      return {};
    }

    return this._cmdData.staticParams
  }

  getUserInput()
  {
    if (!this._cmdData.userInput)
    {
      return {};
    }

    return this._cmdData.userInput
  }

  getStoreData()
  {
    if (!this._cmdData.storeRef)
    {
      return {};
    }

    let store = getStore();
    let curr = this._cmdData.storeRef;
      let dd = Object.keys(curr);
      let obj = {}
      for (let i =0;i<dd.length;i++)
      {
        let storeRefData = curr[dd[i]];
        if (PubsubSubscription.isSubscriptionObj(storeRefData))
        {
          obj[dd[i]] = PubsubSubscription.getValueNative(storeRefData);
        }
        else
        {
          obj[dd[i]] = getPathObject(store.getState(),curr[dd[i]]);
        }
      }
      return obj;
  }

  execute(userInput)
  {
    return this._handlerFunc(this,userInput,this._cmdData);
  }
}

class CommandFactoryClass
{
  isCommand(instance)
  {
    if (instance && typeof instance === 'object')
    {
      if (instance.cmdId)
      {
        let handler = _cmdMap.get(instance.cmdId);
        return handler !== undefined && handler !== null;
      }
    }
    return false;
  }

  registerHandler(id,handlerFunc)
  {
    if (!handlerFunc || !id)
    {
      throw new Error("CommandFactory::registerHandler Id and handlerFunc must be set");
    }
    let found = _cmdMap.get(id);
    if (found)
    {
      console.warn(`CommandRegistry::registerHandler id '${id}' was already register, will override`);
    }
    _cmdMap.set(id,handlerFunc);
  }

  createCommand(instance)
  {
    if (!getStore())
    {
      throw new Error("CommandFactory Must setStore before calling createCommand");
    }

    if (!this.isCommand(instance))
    {
      console.error(`CommandFactory::createCommand ${instance} is not a command`);
      return new Command(()=>{});
    }

    let internalHandler = _cmdMap.get(instance.cmdId);
    if (!internalHandler)
    {
      console.error(`CommandFactory::createCommand id '${instance.cmdId}' has no command handler`);
      return new Command(()=>{});
    }

    return new Command(internalHandler,instance);
  }
} 

const cmdFactorySingleton = new CommandFactoryClass();
export default cmdFactorySingleton;
export const CommandFactory = cmdFactorySingleton; 