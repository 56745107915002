/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import LeftPanelActionsBlock from '../LeftPanelActionsBlock/LeftPanelActionsBlock';
import TicksList from '../TicksList/TicksList';

import './LeftPanel.css';


const LeftBlock = ({ onSymbolSelect, selectedSymbol, width, initialLeftWidth }) => {
    const [isLoading, setIsLoading] = useState(true);
    const { symbols, symbolsOrder, pinSymbols } = useSelector((state) => (state.account.symbols), shallowEqual);
    const { marketRatesSettings } = useSelector((state) => { return (state.account.accountInfo); }, shallowEqual);
    const { Text: { GeneralColor } } = useSelector((state) => (state.brand.theme));
    const [filteredArray, setFilteredArray] = useState([]);
    const [orderedSymbols, setOrderedSymbols] = useState([]);
    const [nothingFound, setNothingFound] = useState(false);
    const [symbolGroups, setSymbolGroups] = useState(null);
    // const [width] = useWindowSize();
    // const [leftScrollWidth, setLeftScrollWidth] = useState(0);
    const { t } = useTranslation();

    // useEffect(() => {
    //     setLeftScrollWidth(document.getElementsByClassName('bid-item__container')?.item(0)?.scrollWidth);
    // }, [shouldDisplayPin, shouldDisplayInfo, shouldDisplayTime]);

    useEffect(() => {
        const temp = [];
        const pinnedTemp = [];
        let symbolsToShow = new Set();
        for (const symbolsToDisplay in symbolsOrder) {
            symbolsToShow.add(symbolsOrder[symbolsToDisplay]);
            const foundSymbol = symbols[symbolsOrder[symbolsToDisplay]];
            if (foundSymbol) {
                if (!foundSymbol.isPin) {
                    temp.push(foundSymbol);
                }
            }
        }
        for (const symbolsToDisplay in pinSymbols) {
            const foundSymbol = symbols[pinSymbols[symbolsToDisplay]];
            if (foundSymbol) {
                if (symbolsToShow.has(foundSymbol.id))
                {
                    if (foundSymbol.isPin) {
                        pinnedTemp.push(foundSymbol);
                    }
                }
            }
        }
        setOrderedSymbols(pinnedTemp.concat(temp));
        setIsLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [symbols, symbolsOrder]);

    useEffect(() => {
      const all = 'MarketRates.Groups.All';
      var arr = [all];
      var groups = new Set();
      groups.add(all);

      for (const symbolId of symbolsOrder) {
        const stateSymbol = symbols[symbolId];
        if (stateSymbol) {
            if (stateSymbol.group) {
                groups.add(stateSymbol.group);
            }
        }
      }

      if(marketRatesSettings.groupsOrder?.length > 0){
        for(const group of marketRatesSettings.groupsOrder){
          if(groups.has(group)){
            arr.push(group);
          }
        }
      }else{
         arr = [...groups];
      }

        // const prepareGroups = [...new Set(orderedSymbols.map(item => item.group))];
        setSymbolGroups(arr);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSelect = (group) => {
        if (group === 'MarketRates.Groups.All') {
            setFilteredArray([]);
        } else {
            const filteredSymbols = orderedSymbols.filter((item) => item.group.toLowerCase().includes(group.toLowerCase()));
            if (filteredSymbols.length > 0) {
                setNothingFound(false);
                setFilteredArray(filteredSymbols);
            } else {
                setNothingFound(true);
            }
        }
    };

    const handleSearch = (symbol, symbolGroup) => {
        if (!symbol && symbolGroup === 'MarketRates.Groups.All') {
            setFilteredArray([]);
            setNothingFound(false);
        } else if (!symbol && symbolGroup !== 'MarketRates.Groups.All') {
            handleSelect(symbolGroup);
        } else if (symbolGroup === 'MarketRates.Groups.All') {
            const filteredSymbols = orderedSymbols.filter((item) => item.id.toLowerCase().includes(symbol.toLowerCase())
            || item.description.toLowerCase().includes(symbol.toLowerCase())
            || item.group.toLowerCase().includes(symbol.toLowerCase()));
            if (filteredSymbols.length > 0) {
                setNothingFound(false);
                setFilteredArray(filteredSymbols);
            } else {
                setNothingFound(true);
            }
        } else {
            const filteredSymbols = orderedSymbols.filter((item) => item.id.toLowerCase().includes(symbol.toLowerCase())
            || item.description.toLowerCase().includes(symbol.toLowerCase())
            || item.group.toLowerCase().includes(symbol.toLowerCase()));
            const finalFilterred = filteredSymbols.filter((item) => item.group.toLowerCase() === symbolGroup.toLowerCase());
            if (finalFilterred.length > 0) {
                setNothingFound(false);
                setFilteredArray(finalFilterred);
            } else {
                setNothingFound(true);
            }
        }
    };

    const symbolsList = filteredArray.length > 0 ? filteredArray : orderedSymbols;

    const shouldDisplayPin = marketRatesSettings.pin.isVisible;
    const setShouldDisplayPin = marketRatesSettings.pin.toggleMarketRatesCommand;

    const shouldDisplayTime = marketRatesSettings.lastUpdate.isVisible;
    const setShouldDisplayTime = marketRatesSettings.lastUpdate.toggleMarketRatesCommand;

    const shouldDisplayInfo = marketRatesSettings.info.isVisible;
    const setShouldDisplayInfo = marketRatesSettings.info.toggleMarketRatesCommand;

    return (
        <div className='left-panel__container' id='left-panel__container'>
            <h2 style={{ color: GeneralColor }}>{t('MarketRates.Heading')}</h2>
            {symbolGroups && (
                <LeftPanelActionsBlock
                    searchSubmit={handleSearch}
                    symbolGroups={symbolGroups}
                    selectSubmit={handleSelect}
                    shouldDisplayPin={shouldDisplayPin}
                    setShouldDisplayPin={setShouldDisplayPin}
                    shouldDisplayInfo={shouldDisplayInfo}
                    setShouldDisplayInfo={setShouldDisplayInfo}
                    // shouldDisplayFlags={shouldDisplayFlags}
                    // setShouldDisplayFlags={setShouldDisplayFlags}
                    shouldDisplayTime={shouldDisplayTime}
                    setShouldDisplayTime={setShouldDisplayTime}
                    width={width}
                    initialLeftWidth={initialLeftWidth}
                />
            )}
            {/* {!nothingFound && false && ( */}
            {!nothingFound && (
                <TicksList
                    orderedSymbols={symbolsList}
                    handleItemSelect={onSymbolSelect}
                    selectedSymbol={selectedSymbol}
                    shouldDisplayPin={shouldDisplayPin}
                    shouldDisplayInfo={shouldDisplayInfo}
                    // shouldDisplayFlags={shouldDisplayFlags}
                    shouldDisplayTime={shouldDisplayTime}
                    // leftScrollWidth={leftScrollWidth}
                />
            )}
            {nothingFound && <div className='left-panel__nothing-found'>{t('MarketRates.NothingFound')}</div>}
        </div>
    );
};


// eslint-disable-next-line import/prefer-default-export, consistent-return
export const LeftBlockMemo = React.memo(LeftBlock, (prev, next) => {
    if (prev.initialLeftWidth !== next.initialLeftWidth) {
        return false;
    }
    // eslint-disable-next-line no-self-compare
    if ((prev.onSymbolSelect !== next.onSymbolSelect && prev.selectedSymbol !== prev.selectedSymbol)) {
        return true;
    }
});
