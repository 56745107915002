import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';

import Tabs from '../primitives/Tabs/Tabs';

import ExplorePageMostCopied from './ExplorePageMostCopied';
import ExplorePageMostProfitable from './ExplorePageMostProfitable';
import ExplorePageTopRanked from './ExplorePageTopRanked';
import ExplorerPageAdvancedSearch from './ExplorerPageAdvancedSearch';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';
import {SendEventToFullStory} from '../../background/storeListener/fullStoryListener';
import {SendEventToLuckyOrange} from '../../background/storeListener/luckyOrangeListener';
import './ExplorePage.css';


const ExplorePage = ({ handleItemSelect, closeExplorePage }) => {
    const [activateTab, setActivateTab] = useState('');
    const { Buttons: { TextColor }, Social: { CopyBackground, CopyTextColor }, Profit: { Positive } } = useSelector((state) => (state.brand.theme));
    const copyColor = useMemo(() => ({ color: CopyTextColor, backgroundColor: CopyBackground }), [CopyBackground, CopyTextColor]);
    const { t } = useTranslation();

    useEffect(() => {
        setActivateTab(t('ProfilePage.Profile.Title'));
        SendEventToGoogleAnalytics("ExplorePageOpened");
    }, []);

    const handleUserSelect = (userData) => {
        handleItemSelect(userData);
        closeExplorePage();
    };

    const onTabClick = (tab)=>{
      SendEventToGoogleAnalytics(`Explore:${tab}TabClick`, {'tabName': tab});
      SendEventToFullStory(`Explore:${tab}TabClick`, {'tabName_str': tab});
      SendEventToLuckyOrange(`Explore:${tab}TabClick`);
    };


    return (
        <div className='explore-page__container'>
            <div className='explore-page__tabs'>
                <Tabs onTabClick={onTabClick} className='explore-page__tabs'>
                    <li name={t('ExplorePage.TopRanked')}>
                        <ExplorePageTopRanked location="TopRanked" handleItemSelect={handleUserSelect} limit={20} copyColor={copyColor} positiveColor={Positive} isTopThree />
                    </li>
                    <li name={t('ExplorePage.MostCopied')}>
                        <ExplorePageMostCopied location="MostCopied" handleItemSelect={handleUserSelect} limit={20} copyColor={copyColor} />
                    </li>
                    <li name={t('ExplorePage.MostProfitable')}>
                        <ExplorePageMostProfitable location="MostProfitable" handleItemSelect={handleUserSelect} limit={20} copyColor={copyColor} />
                    </li>
                    <li name={t('ExplorePage.Advanced')}>
                        <ExplorerPageAdvancedSearch location="AdvancedSearch" handleItemSelect={handleUserSelect} limit={20} copyColor={copyColor} buttonColor={{ color: TextColor }} />
                    </li>
                </Tabs>
            </div>
        </div>
    );
};

// ExplorePage.propTypes = {};

export default ExplorePage;
