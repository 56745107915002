import React, {useMemo} from 'react';

const MobileAreaChartTooltip = props => {
	const {payload, label, active} = props;

	const action = useMemo(() => {
		if (!active || !payload || !payload.length) return null;
		return {
			...payload[0].payload,
			payload: payload[0],
		};
	}, [payload, active]);

	if (!active) return null;
	return (
			<div className="mobile-chart-area__tooltip">
				{action?.name}
			</div>
	);
};

export default MobileAreaChartTooltip;
