import React, {useCallback, useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';

import MobileOpenPositionHead from './MobileOpenPositionHead';
import MobileOpenPositionItem from './MobileOpenPositionItem';
import MobileAutosizer
	from '../../../components/MobileAutosizer/MobileAutosizer';
import useControls from '../../../context/contextHook/useControls';

const MobileOpenPosition = props => {
	const {
		setCloseOrderData,
		setUpdateOrderData,
	} = props;

	const {positionControls: {openPosition: {toId, setToId}}} = useControls();

	const statePositions = useSelector(
			state => state.account.positions.open.positions);

	const tradeLinks = useSelector(state => state.account.socialv1.tradeLinks);
	const isSocialEnabled = useSelector(
			(state) => state.account.socialv1.isEnabled);
	const isSocialVisible = useSelector(
			(state) => state.account.accountInfo.rightPanelSettings.socialv1.isVisible);
	const showCopiedFrom = isSocialEnabled && isSocialVisible;

	const positionOrdered = useMemo(() => {
		const positionOrdered = [];
		for (const currPosKey in statePositions) {
			const currPos = statePositions[currPosKey];
			positionOrdered.push(currPos);
		}
		return positionOrdered.sort((a,b) => b.openTimeS-a.openTimeS);
	}, [statePositions]);

	const scrollToIndex = useMemo(() => {
		return positionOrdered.findIndex(pos => pos.id === toId);
	}, [positionOrdered, toId]);

	const handleSetCloseOrderData = useCallback(data => {
		setCloseOrderData && setCloseOrderData(data);
	}, []);

	const handleSetUpdateOrderData = useCallback(data => {
		setUpdateOrderData && setUpdateOrderData(data);
	}, [setUpdateOrderData]);

	const renderColumns = useMemo(() => {
		return [
			'instrument',
			'type',
			'amount',
			'profit',
			'user',
			'close',
		];
	}, []);

	useEffect(() => {
		return () => {
			setToId && setToId(null);
		};
	}, [setToId]);

	const renderRow = useCallback(data => {
		const {item, props} = data;
		return (
				<MobileOpenPositionItem
						{...item}
						{...props}
						columns={renderColumns}
				/>
		);
	}, [renderColumns]);

	return (
			<div className="mobile-position">
				<MobileOpenPositionHead
						columns={renderColumns}
				/>

				<MobileAutosizer
						showCopiedFrom={showCopiedFrom}
						tradeLinks={tradeLinks}
						options={positionOrdered}
						renderRow={renderRow}
						scrollToIndex={scrollToIndex}
						scrollToRow={scrollToIndex}
						className="mobile-open-position__row"
						positionType="open"
				/>
			</div>
	);
};

export default MobileOpenPosition;
