import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from '@material-ui/core/Slider';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// import PropTypes from 'prop-types';


const QuestionStepTwo = ({ steps, setSteps, systemColor, buttonColor }) => {
    const { t } = useTranslation();
    useEffect(() => {
        const isFulfilled = Object.keys(steps.stepData[1]).every((k) => { return steps.stepData[1][k]; });
        if (isFulfilled && steps.stepData[1].isFulfilled !== true) {
            const nextStepData = steps.stepData;
            nextStepData[1] = { ...nextStepData[1], isFulfilled: true };
            setSteps((prevState) => ({
                ...prevState, stepData: nextStepData
            }));
        }
    }, [steps]);
    const {
        first,
        second,
        slide
    } = steps.stepData[1];
    const options = {
        first: [
            { id: 1, value: t('Socialv1.Suitability.Q2.Answer1') },
            { id: 2, value: t('Socialv1.Suitability.Q2.Answer2') },
            { id: 3, value: t('Socialv1.Suitability.Q2.Answer3') }
        ],
        second: [
            { id: 1, value: t('Socialv1.Suitability.Q4.Answer1') },
            { id: 2, value: t('Socialv1.Suitability.Q4.Answer2') },
            { id: 3, value: t('Socialv1.Suitability.Q4.Answer3') },
            { id: 4, value: t('Socialv1.Suitability.Q4.Answer4') },
            { id: 5, value: t('Socialv1.Suitability.Q4.Answer5') }
        ]
    };
    const marks = [
        {
            value: 0,
            label: t('Socialv1.Suitability.Q3.Answer1')
        },
        {
            value: 25,
            label: t('Socialv1.Suitability.Q3.Answer2')
        },
        {
            value: 50,
            label: t('Socialv1.Suitability.Q3.Answer3')
        },
        {
            value: 75,
            label: t('Socialv1.Suitability.Q3.Answer4')
        },
        {
            value: 100,
            label: t('Socialv1.Suitability.Q3.Answer5')
        }
    ];

    const handleChange = (e) => {
        const nextStepData = steps.stepData;
        nextStepData[1] = { ...nextStepData[1], [e.target.name]: e.target.value };
        setSteps((prevState) => ({
            ...prevState, stepData: nextStepData
        }));
    };

    const handleSlideChange = (e, number) => {
        e.stopPropagation();
        e.preventDefault();
        const nextStepData = steps.stepData;
        nextStepData[1] = { ...nextStepData[1], slide: (number / 25) + 1 };
        setSteps((prevState) => ({
            ...prevState, stepData: nextStepData
        }));
    };

    const AirbnbThumbComponent = (props) => {
        return (
            <span {...props}>
                <span className='bar' />
                <span className='bar' />
                <span className='bar' />
            </span>
        );
    };


    return (
        <div className='step step-two'>
            <p>
                {t('Socialv1.Suitability.Q2.Title')}
            </p>
            <div className='question-select'>
                <FormControl>
                    <InputLabel htmlFor='first-label'>{t('Socialv1.Suitability.PleaseSelect')}</InputLabel>
                    <Select
                        id='first'
                        IconComponent={KeyboardArrowDownIcon}
                        name='first'
                        value={first}
                        defaultValue=''
                        onChange={handleChange}
                        style={{ color: buttonColor || '#fff' }}
                        labelId='first-label'
                        MenuProps={{ className: 'outer-exclude' }}
                    >
                        {options.first.map((option) => {
                            return (
                                <MenuItem key={option.value} value={option.id} style={{ color: systemColor }}>
                                    {option.value}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>
            <div className='question__slider-group'>
                <p>
                    {t('Socialv1.Suitability.Q3.Title')}
                </p>
                <div className='questions__slider'>
                    <Slider
                        defaultValue={(slide - 1) * 25}
                        value={(slide - 1) * 25}
                        aria-labelledby='discrete-slider-custom'
                        step={25}
                        valueLabelDisplay='auto'
                        marks={marks}
                        track={false}
                        ThumbComponent={AirbnbThumbComponent}
                        onChange={handleSlideChange}
                    />
                </div>
            </div>
            <p>
                {t('Socialv1.Suitability.Q4.Title')}
            </p>
            <div className='question-select'>
                <FormControl>
                    <InputLabel htmlFor='second-label'>{t('Socialv1.Suitability.PleaseSelect')}</InputLabel>
                    <Select
                        id='second'
                        IconComponent={KeyboardArrowDownIcon}
                        name='second'
                        value={second}
                        defaultValue=''
                        onChange={handleChange}
                        style={{ color: buttonColor || '#fff' }}
                        labelId='second-label'
                        MenuProps={{ className: 'outer-exclude' }}
                    >
                        {options.second.map((option) => {
                            return (
                                <MenuItem key={option.value} value={option.id} style={{ color: systemColor }}>
                                    {option.value}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>
        </div>
    );
};

// QuestionStepTwo.propTypes = {};

export default QuestionStepTwo;
