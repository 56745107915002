import {overrideState} from '../StoreUtils/overrideState'
import url from 'url-parameters';

function getUrlParameters(params)
{
  if (params)
  {
    for(let paramKey in params){
      let currValue = params[paramKey];
      if (typeof currValue === 'string' || currValue instanceof String)
      {
        try
        {
          params[paramKey] = decodeURIComponent(currValue);
        }
        catch (e)
        {
          console.error(e);
        }
      }
    }
  }
  return params;
}

export function publishUrlParameters(store)
{
  let params = getUrlParameters(url.getParams());
  
  store.dispatch({
    type:"init-url-parameters",
    payload: params,
  })
}

const initState = getUrlParameters(url.getParams());

export const urlParametersReducer = function reducer(state = initState, action){
  switch (action.type) {
    case "init-url-parameters":
      return overrideState(state,action.payload ?? {});
    default:
      break;
  }

  return state;
}