import { ReactComponent as AUD } from './AUD.svg';
import { ReactComponent as CAD } from './CAD.svg';
import { ReactComponent as CHF } from './CHF.svg';
import { ReactComponent as EUR } from './EUR.svg';
import { ReactComponent as GBP } from './GBP.svg';
import { ReactComponent as JPY } from './JPY.svg';
import { ReactComponent as NZD } from './NZD.svg';
import { ReactComponent as USD } from './USD.svg';
import { ReactComponent as BTC } from './BTC.svg';
import { ReactComponent as BCH } from './BCH.svg';
import { ReactComponent as CNH } from './CNH.svg';
import { ReactComponent as CZK } from './CZK.svg';
import { ReactComponent as DKK } from './DKK.svg';
import { ReactComponent as EOS } from './EOS.svg';
import { ReactComponent as ETC } from './ETC.svg';
import { ReactComponent as ETH } from './ETH.svg';
import { ReactComponent as HKD } from './HKD.svg';
import { ReactComponent as HUF } from './HUF.svg';
import { ReactComponent as ILS } from './ILS.svg';
import { ReactComponent as LTC } from './LTC.svg';
import { ReactComponent as MXN } from './MXN.svg';
import { ReactComponent as NOK } from './NOK.svg';
import { ReactComponent as PLN } from './PLN.svg';
import { ReactComponent as RON } from './RON.svg';
import { ReactComponent as RUB } from './RUB.svg';
import { ReactComponent as SEK } from './SEK.svg';
import { ReactComponent as SGD } from './SGD.svg';
import { ReactComponent as TRY } from './TRY.svg';
import { ReactComponent as XLM } from './XLM.svg';
import { ReactComponent as XMR } from './XMR.svg';
import { ReactComponent as XRP } from './XRP.svg';
import { ReactComponent as ZAR } from './ZAR.svg';
import { ReactComponent as DASH } from './DASH.svg';
import { ReactComponent as Default } from './Default.svg';

import React from 'react';

export function getSymbolFlag(flag){
  if (flag?.single) {
    const singleFlagData = flags[flag.single];
    if(singleFlagData){
      const singleFlag = React.createElement(singleFlagData.flag, { viewBox: "15 0 60 60", height: "100%", width: "100%" });
      return  (<div className="flag-container single-flag">
                {singleFlag}
              </div>)
    }
  }

  if (flag?.left && flag?.right) {
    let leftFlagData = flags[flag.left];
    let rightFlagData = flags[flag.right];

    if(leftFlagData && rightFlagData){
      let leftViewBox = "0 0 30 60";
      if (leftFlagData.cutMethod === 'center') {
        leftViewBox = "15 0 30 60";
      }
      let leftFlag = React.createElement(leftFlagData.flag, { viewBox: leftViewBox, width:'100%', height:'100%'});

      let rightViewBox = "0 0 30 60";
      if (rightFlagData.cutMethod === 'center') {
        rightViewBox = "15 0 30 60";
      }
      let rightFlag = React.createElement(rightFlagData.flag, { viewBox: rightViewBox, width:'100%', height:'100%'});

      return (
        <div className='flag-container'>
          <div className='left-flag'>
            {leftFlag}
          </div>
          <div className='right-flag'>
            {rightFlag}
          </div>
        </div>);
    }
  }

  let defaultFlagData = flags["Default"];
  if (defaultFlagData)
  {
    let defaultFlag = React.createElement(defaultFlagData.flag, { viewBox: "15 0 60 60", height: "100%", width: "100%" });
    return (<div className='flag-container single-flag'>
              {defaultFlag}
            </div>);
  }
  else
  {
    return (<div className='flag-container single-flag'/>);
  }
};


const flags = {
  'BCH': { flag: BCH, cutMethod: 'center' },
  'CNH': { flag: CNH, cutMethod: 'left' },
  'CZK': { flag: CZK, cutMethod: 'left' },
  'DKK': { flag: DKK, cutMethod: 'left' },
  'EOS': { flag: EOS, cutMethod: 'center' },
  'ETC': { flag: ETC, cutMethod: 'center' },
  'ETH': { flag: ETH, cutMethod: 'center' },
  'HKD': { flag: HKD, cutMethod: 'center' },
  'HUF': { flag: HUF, cutMethod: 'center' },
  'ILS': { flag: ILS, cutMethod: 'center' },
  'LTC': { flag: LTC, cutMethod: 'center' },
  'MXN': { flag: MXN, cutMethod: 'center' },
  'NOK': { flag: NOK, cutMethod: 'left' },
  'PLN': { flag: PLN, cutMethod: 'center' },
  'RON': { flag: RON, cutMethod: 'center' },
  'RUB': { flag: RUB, cutMethod: 'center' },
  'SEK': { flag: SEK, cutMethod: 'left' },
  'SGD': { flag: SGD, cutMethod: 'left' },
  'TRY': { flag: TRY, cutMethod: 'left' },
  'XLM': { flag: XLM, cutMethod: 'center' },
  'XMR': { flag: XMR, cutMethod: 'center' },
  'XRP': { flag: XRP, cutMethod: 'center' },
  'ZAR': { flag: ZAR, cutMethod: 'left' },
  'AUD': { flag: AUD, cutMethod: 'left' },
  'CAD': { flag: CAD, cutMethod: 'center' },
  'CHF': { flag: CHF, cutMethod: 'center' },
  'EUR': { flag: EUR, cutMethod: 'center' },
  'GBP': { flag: GBP, cutMethod: 'center' },
  'JPY': { flag: JPY, cutMethod: 'center' },
  'NZD': { flag: NZD, cutMethod: 'left' },
  'USD': { flag: USD, cutMethod: 'left' },
  'BTC': { flag: BTC, cutMethod: 'center' },
  'DASH': { flag: DASH, cutMethod: 'center' },
  'Default': { flag: Default, cutMethod: 'center' }
}
