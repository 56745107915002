import React, {useMemo} from 'react';

import useSymbol from '../../context/contextHook/useSymbol';

import PubsubSubscription from '../../../background/repositories/PubsubSubscription';
import {prepareBidAsk} from '../../../UI/utilsUI/formatWSTick';

const MobileCarouselSymbolTick = ({currentSymbol}) => {
	const {selectedSymbol} = useSymbol();

	const symbol = useMemo(() => {
		return currentSymbol || selectedSymbol;
	}, [currentSymbol, selectedSymbol]);

	const pipDigit = useMemo(() => {
		return symbol?.displayDigit;
	}, [symbol]);

	const [symbolTick, previousTick] = PubsubSubscription.subscribeObjAndPrev(
			symbol?.tick);

	const {bidOutput, askOutput} = prepareBidAsk(symbolTick, previousTick,
			pipDigit);

	return (
			<div className="mobile-trading-chart__tick">
				<div className="mobile-trade__header__desc__tick">
					{askOutput}
				</div>
				<div className="mobile-trade__header__desc__tick">
					{bidOutput}
				</div>
			</div>
	);
};

export default MobileCarouselSymbolTick;
