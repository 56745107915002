/* eslint-disable complexity */
import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';

import {ReactComponent as IconUnlink} from '../../../assets/icons/icon-unlink.svg';

import {
	getRenderColumn,
	renderType,
	renderTypeMulti,
} from '../../../helpers/position.helpers';
import usePosition from '../../../context/contextHook/usePosition';

import pubsubSubscription from '../../../../background/repositories/PubsubSubscription';
import FormatProfitSwapCommision
	from '../../../../UI/utilsUI/FormatProfitSwapCommision';
import OpenPositionsVirtualCloseRate
	from '../../../../UI/OpenPositions/OpenPositionsVirtualCloseRate';
import MobileButtonIcon from '../../../components/Buttons/MobileButtonIcon';
import useProfile from '../../../context/contextHook/useProfile';
import MobileClosedPositionActive
	from '../MobileClosedPosition/MobileClosedPositionActive';
import MobileSlidePosition from '../MobileSlidePosition';

const MobileOpenPositionItem = props => {
	const {
		showCopiedFrom,
		tradeLinks,
		deleteOrderData,
		symbolName,
		actionType,
		amount,
		openRate,
		stopLoss,
		takeProfit,
		profit,
		columns,
		tpId,
		positionType = 'open',
		closeTradeLinks,
		handleOpenRow,
		isActive,
		minHeight,
		maxHeight,
	} = props;

	const {profileUser: {openProfileUser}} = useProfile();
	const {positionClose, positionUpdate} = usePosition();

	const {t} = useTranslation();

	const profitValueSubscription = pubsubSubscription.subscribeObj(
			{key: tpId, repId: 'PNL'});

	const profitValue = useMemo(() => {
		let profitValue;
		switch (positionType) {
			case 'open' :
				profitValue = profitValueSubscription?.profit;
				break;
			case 'closed':
				profitValue = profit;
				break;
			default:
				profitValue = 0;
		}
		return profitValue;
	}, [profitValueSubscription, positionType]);

	const currentCloseTradeLink = useMemo(() => {
		if (!closeTradeLinks) return null;
		return closeTradeLinks[tpId];
	}, [closeTradeLinks]);

	const tradeLink = useMemo(() => {
		if (!tradeLinks) return null;
		return tradeLinks[tpId];
	}, [tradeLinks]);

	const shouldCopiedUser = useMemo(() => {
		if (positionType === 'open') return tradeLink?.sourceNickName;
		if (positionType === 'closed') return currentCloseTradeLink?.sourceNickName;
	}, [positionType, tradeLink, currentCloseTradeLink]);

	const shouldCopiedUnlink = useMemo(() => {
		if (positionType === 'open') return !!tradeLink;
		if (positionType === 'closed') return false;
	}, [positionType, tradeLink]);

	const handleClosePositionClick = (e) => {
		e && e.preventDefault();
		if (!positionClose || (positionType === 'closed')) return null;
		const {handleCloseOpen} = positionClose;
		
		const closeData = {
			...props,
		};
		handleCloseOpen && handleCloseOpen(closeData);
	};

	const handleDeletePositionClick = useCallback(e => {
		e && e.preventDefault();
		deleteOrderData && deleteOrderData({...props});
	}, [deleteOrderData]);

	const handleRowClick = useCallback((e, data) => {
		e && e.preventDefault();
		if (positionType !== 'closed') return null;
		if (isActive) return handleOpenRow && handleOpenRow(null);
		const propsData = typeof data !== 'undefined' ? data : props;
		return handleOpenRow && handleOpenRow(propsData);
	}, [positionType, handleOpenRow, props]);

	const handleUpdatePosition = useCallback(e => {
		e && e.preventDefault();
		if (!positionUpdate || (positionType === 'closed')) return null;
		const {handleUpdateOpen} = positionUpdate;
		handleUpdateOpen && handleUpdateOpen(props);
	}, [positionUpdate]);

	const handleOpenProfile = useCallback(e => {
		e && e.preventDefault();
		e && e.stopPropagation();
		handleRowClick(e, null);
		const nick = tradeLink?.sourceNickName
				|| currentCloseTradeLink?.sourceNickName;
		if (!nick) return null;
		openProfileUser && openProfileUser(nick);
	}, [openProfileUser, tradeLink, handleRowClick, currentCloseTradeLink]);

	const currentType = useMemo(() => {
		const label = renderType(actionType, positionType === 'pending');
		return label.transition ? t(label.transition) : label.type;
	}, [actionType, positionType]);

	const renderActiveItem = useMemo(() => {
		if (!isActive) return null;
		switch (positionType) {
			case 'closed':
				return <MobileClosedPositionActive {...props} />;
			default:
				return <></>;
		}
	}, [positionType, isActive]);

	const currentMinHeight = useMemo(() => {
		return minHeight || '';
	}, [minHeight]);

	const currentMaxHeight = useMemo(() => {
		return maxHeight || '';
	}, [maxHeight]);

	const currentActiveBlockHeight = useMemo(() => {
		return currentMaxHeight - currentMinHeight;
	}, [currentMinHeight, currentMaxHeight]);

	const actionTypeColorsClass = useMemo(() => {
		if (positionType) return '';
		return clsx(actionType === 0 && 'green', actionType === 1 && 'red');
	}, [positionType, actionType]);

	const isSlideRow = useMemo(() => {
		return positionType !== 'closed';
	}, [positionType]);

	return (
			// <div className={clsx('mobile-open-position__item', positionType)}>
			<MobileSlidePosition isSlide={!!isSlideRow}>
				<div
						className={clsx('mobile-open-position__item__wrapper',
								`${positionType}-position`,
								'gradient-position')}
						onClick={handleRowClick}
				>

					<div
							className="mobile-open-position__item"
							style={{height: currentMinHeight}}
					>
						{getRenderColumn(columns, 'instrument') && (
								<div
										className={clsx('mobile-open-position__item', 'symbol')}
										onClick={handleUpdatePosition}
								>
									{symbolName}
								</div>
						)}


						{getRenderColumn(columns, 'type') && (
								<div
										className={clsx('mobile-open-position__item',
												'type',
												actionTypeColorsClass,
												// actionType === 0 && 'green',
												// actionType === 2 && 'green',
												// actionType === 1 && 'red',
												// actionType === 3 && 'red',
										)}
										onClick={handleUpdatePosition}
								>
									{currentType}
								</div>
						)}

						{getRenderColumn(columns, 'amount') && (
								<div
										className={clsx('mobile-open-position__item', 'amount')}
										onClick={handleUpdatePosition}
								>
									{amount}
								</div>
						)}

						{getRenderColumn(columns, 'price') && (
								<div
										className={clsx('mobile-open-position__item', 'price')}
										onClick={handleUpdatePosition}
								>
									{openRate}
								</div>
						)}

						{getRenderColumn(columns, 'stopLoss') && (
								<div
										className={clsx('mobile-open-position__item', 'stop-loss')}
										onClick={handleUpdatePosition}
								>
									{stopLoss}
								</div>
						)}

						{getRenderColumn(columns, 'takeProfit') && (
								<div
										className={clsx('mobile-open-position__item',
												'take-profit')}
										onClick={handleUpdatePosition}
								>
									{takeProfit}
								</div>
						)}

						{getRenderColumn(columns, 'currentPrice') && (
								<div
										className={clsx('mobile-open-position__item',
												'price-current')}
										onClick={handleUpdatePosition}
								>
									<OpenPositionsVirtualCloseRate
											symbolName={symbolName}
											actionType={renderTypeMulti(actionType)}
									/>
								</div>
						)}

						{getRenderColumn(columns, 'profit') && (
								<div
										className={clsx('mobile-open-position__item', 'profit')}
										onClick={handleUpdatePosition}
								>
									<FormatProfitSwapCommision tickValue={profitValue}/>
								</div>
						)}

						{showCopiedFrom && (
								<>
									{getRenderColumn(columns, 'user') && (
											<div
													className={clsx('mobile-open-position__item',
															'group')}
											>
												{shouldCopiedUser && (
														<MobileButtonIcon
																onClick={handleOpenProfile}
																type="user"
														/>
												)}
											</div>
									)}

									{getRenderColumn(columns, 'unlink') && (
											<div
													className={clsx('mobile-open-position__item',
															'group')}
											>
												{shouldCopiedUnlink && (
														<button
																className={clsx(
																		'mobile-open-position__item-icon',
																		tradeLink?.linkType !== 0 &&
																		'no-pointers')}>
															<IconUnlink/>
														</button>
												)}
											</div>
									)}
								</>
						)}

						{getRenderColumn(columns, 'arrow') && (
								<div
										className={clsx('mobile-open-position__item', 'arrow')}
								>
									<MobileButtonIcon
											type={`arrow${isActive ? '-down' : '-right'}`}
									/>
								</div>
						)}

						{getRenderColumn(columns, 'close') && (
								<div
										className={clsx('mobile-open-position__item', 'close')}
								>
									<button
											className="mobile-open-position__item-close__button"
											onClick={handleClosePositionClick}
									>
										{t('InstrumetInfo.Details.Close')}
									</button>
								</div>
						)}

						{getRenderColumn(columns, 'delete') && (
								<div
										className={clsx('mobile-open-position__item', 'delete')}
								>
									<button
											className="mobile-open-position__item-close__button"
											onClick={handleDeletePositionClick}
									>
										{t('InstrumetInfo.Details.Delete')}
									</button>
								</div>
						)}
					</div>


					{!!isActive && (
							<div
									className="mobile-open-position__item__active"
									style={{height: currentActiveBlockHeight}}
							>
								{renderActiveItem}
							</div>
					)}

				</div>
			</MobileSlidePosition>
	);
};

export default MobileOpenPositionItem;
