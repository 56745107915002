import React, {useRef} from 'react';
import clsx from 'clsx';

import useClickOutside from '../../hook/useClickOutside';

const MobileModal = ({children, onClose, isOpen, className}) => {
	const backdropRef = useRef(null);

	useClickOutside(backdropRef, () => {
		onClose && onClose();
	});

	return (
			<>
				{isOpen && (
						<div
								className={clsx('mobile-modal__backdrop', className)}
								ref={backdropRef}
						>
							<div className="mobile-modal__modal">
								{children}
							</div>

						</div>
				)}
			</>
	);
};

export default MobileModal;
