import React, { useEffect, useState, Fragment } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { CommandFactory } from '../../background/commands/CommandFactory';
import LoadSpinner from '../primitives/LoadSpinner';


const CalendarFrame = () => {
    const [calendarDynamicUrl, setCalendarDynamicUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { calendar } = useSelector((state) => { return (state.account.accountInfo.featuresData); }, shallowEqual);
    const getCalendarUrl = async () => {
        if (CommandFactory.isCommand(calendar.getCalendarUrlCommand)) {
            try {
                const response = await CommandFactory.createCommand(calendar.getCalendarUrlCommand).execute();
                if (!response?.isSuccessful) {
                    if (calendar?.calendarUrl) {
                        setCalendarDynamicUrl(calendar.calendarUrl);
                    }
                } else {
                    setCalendarDynamicUrl(response?.payload?.calendarUrl);
                }
            } catch (err) {
                console.error('error', err);
            }
            setIsLoading(false);
        } else {
            if (calendar?.calendarUrl) {
                setCalendarDynamicUrl(calendar.calendarUrl);
            }
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getCalendarUrl();
    }, []);

    if (isLoading) {
        return <LoadSpinner />;
    }

    return (
        <Fragment>
            {calendarDynamicUrl ? (
                <iframe
                    src={calendarDynamicUrl}
                    frameBorder='0'
                    title='Calendar'
                />
            ) : (
                <div>Unable to reach server</div>
            )}
        </Fragment>
    );
};

export default CalendarFrame;
