import React from 'react';
// import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';

// import PubsubSubscription from '../../Repositories/PubsubSubscription';

import InputStepper from '../primitives/InputStepper';


const OrderUpdate = ({
    symbolId,
    selectedUnits,
    setSelectedUnits,
    shouldFocus,
    id
}) => {
    const { symbols } = useSelector((state) => (state.account.symbols), shallowEqual);
    let currentSymbol;
    // eslint-disable-next-line no-restricted-syntax
    for (const obj in symbols) {
        if (symbols[obj].id === symbolId) {
            currentSymbol = symbols[obj];
            break;
        }
    }

    // const symbolTick = PubsubSubscription.subscribeObj(currentSymbol.tick);
    // const [selectedUnits, setSelectedUnits] = useState(0);
    let pipNumber = 1;
    for (let i = 0; i < currentSymbol?.displayDigit; i += 1) {
        pipNumber /= 10;
    }

    const handleValueChange = (value) => {
        setSelectedUnits(value);
    };


    return (
        <div className='order-update__container'>
            <InputStepper
                selectedUnits={selectedUnits}
                stepValue={pipNumber}
                setValue={handleValueChange}
                pipDigit={currentSymbol?.displayDigit}
                shouldFocus={shouldFocus}
                id={id}
            />
        </div>
    );
};

// StopLoss.propTypes = {};

export default OrderUpdate;
