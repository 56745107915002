import React, {useCallback, useMemo, useState} from 'react';
import {Cell, Pie, PieChart, ResponsiveContainer, Sector} from 'recharts';
import {
	createChartColors,
	createPieChartData,
} from '../../../helpers/chart.helpers';
import MobilePieChartLabel from './MobilePieChartLabel';
import MobilePieChartLabelActive from './MobilePieChartLabelActive';
import useTheme from '../../../context/contextHook/useTheme';

const renderActiveShape = props => {
	const RADIAN = Math.PI / 180;
	const {
		cx,
		cy,
		midAngle,
		innerRadius,
		outerRadius,
		startAngle,
		endAngle,
		fill,
		payload,
		percent,
		value,
	} = props;
	const sin = Math.sin(-RADIAN * midAngle);
	const cos = Math.cos(-RADIAN * midAngle);
	const sx = cx + (outerRadius + 10) * cos;
	const sy = cy + (outerRadius + 10) * sin;
	const mx = cx + (outerRadius + 30) * cos;
	const my = cy + (outerRadius + 30) * sin;
	const ex = mx + (cos >= 0 ? 1 : -1) * 22;
	const ey = my;
	const textAnchor = cos >= 0 ? 'start' : 'end';

	return (
			<g>
				<text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
					{payload.name}
				</text>
				<Sector
						cx={cx}
						cy={cy}
						innerRadius={innerRadius}
						outerRadius={outerRadius}
						startAngle={startAngle}
						endAngle={endAngle}
						fill={fill}
				/>
				<Sector
						cx={cx}
						cy={cy}
						startAngle={startAngle}
						endAngle={endAngle}
						innerRadius={outerRadius + 6}
						outerRadius={outerRadius + 10}
						fill={fill}
				/>
				<path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill}
				      fill="none"/>
				<circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
				<text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor}
				      fill="#333">{`PV ${value}`}</text>
				<text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18}
				      textAnchor={textAnchor} fill="#999">
					{`(Rate ${(percent * 100).toFixed(2)}%)`}
				</text>
			</g>
	);
};

const MobilePieChart = ({options}) => {
	const [activeIndex, setActiveIndex] = useState(null);

	const {isDarkTheme} = useTheme();

	const onPieClick = useCallback((_, index) => {
		setActiveIndex(prev => prev === index ? null : index);
	}, [setActiveIndex]);

	const renderLabel = useMemo(() => {
		if (typeof activeIndex === 'number') return null;
		return <MobilePieChartLabel isDarkTheme={isDarkTheme}/>;
	}, [activeIndex, MobilePieChartLabel, isDarkTheme]);

	const renderLabelActive = useMemo(() => {
		return <MobilePieChartLabelActive isDarkTheme={isDarkTheme}/>;
	}, [activeIndex, MobilePieChartLabelActive, isDarkTheme]);

	const opt = useMemo(() => {
		return createPieChartData(options);
	}, [options]);

	return (
			<ResponsiveContainer
					width="100%"
					height="100%"
					className="mobile-chart-pie"
			>
				<PieChart>
					<Pie
							data={opt}
							dataKey="value"
							cx="50%"
							cy="50%"
							innerRadius={78}
							outerRadius={86}
							label={renderLabel}
							activeIndex={activeIndex}
							activeShape={renderLabelActive}
							onClick={onPieClick}
					>
						{
							opt.map((entry, index) => {
								const fill = createChartColors(index);
								return (
										<Cell
												key={`cell-${index}`}
												fill={fill}
												stroke="none"
										/>
								);
							})
						}
					</Pie>
				</PieChart>
			</ResponsiveContainer>
	);
};

export default MobilePieChart;
