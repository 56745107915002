/* eslint-disable complexity */
import React, {
    useCallback, useEffect, useMemo, useState, Fragment
} from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { formatDate } from '../../../../UI/utilsUI/formatTime';
import pubsubSubscription from '../../../../background/repositories/PubsubSubscription';
import { renderType } from '../../../helpers/position.helpers';

import Button from '../../../../UI/primitives/Button';
import MobileUpdatePositionBlock from './MobileUpdatePositionBlock';
import { ProfitCalculationService } from '../../../../background/calculations/profitCalculationService';
import FormatProfitSwapCommision
    from '../../../../UI/utilsUI/FormatProfitSwapCommision';
import MobileUpdatePositionInput from './MobileUpdatePositionInput';
import MobileButton from '../../Buttons/MobileButton';
import FormattedSLTPProfit from '../../../../UI/TradePopup/FormattedSLTPProfit';
import MobileModalError from '../../MobileModal/MobileModalError';
import MobileModalSuccess from '../../MobileModal/MobileModalSuccess';
import useProfile from '../../../context/contextHook/useProfile';
import usePosition from '../../../context/contextHook/usePosition';
import { maskNumericalInput } from '../../../helpers/input.helpers';
import { pipDigital } from '../../../helpers/number.helpers';
import { normalizedEditNumber } from '../../../../UI/OpenPositions/updateNumericHelper';

const MobileUpdatePositionMain = (props) => {
    const {
        symbolName,
        symbolId,
        openTimeS,
        actionType,
        amount,
        profit,
        swaps,
        commission,
        openRate,
        tpId,
        stopLoss,
        takeProfit,
        handleCloseOrder,
        handleUpdateOrder,
        responseErrorData,
        responseSuccessData,
        handleOnClose,
        handleUnlinkOpen,
        handleChangeTakeProfit,
        isUpdate
    } = props;

    const {
        rightPanelSettings: { socialv1: { isVisible } },
        currency: { symbol },
        drawerSettings: { closePositionsTab }
    } = useSelector((state) => state.account.accountInfo, shallowEqual);
    const { symbols } = useSelector((state) => (state.account.symbols), shallowEqual);

    const { profileUser: { openProfileUser } } = useProfile();
    const { positionUpdate: { handleUpdateHide } } = usePosition();

    const [editMode, setEditMode] = useState(
        { stopLoss: stopLoss || '', takeProfit: takeProfit || '' }
    );

    const { t } = useTranslation();

    const tradeLinks = useSelector((state) => state.account.socialv1.tradeLinks);

    const profitValueSubscription = pubsubSubscription.subscribeObj(
        { key: profit?.key, repId: profit?.repId }
    );

    const closeValue = pubsubSubscription.subscribeObj(
        { key: symbolName, repId: 'ticks' }
    );

    const closeConvertion = actionType === 1 ? closeValue?.bidProfitConversion : closeValue?.askProfitConversion;

    const profitValue = useMemo(() => {
        return profitValueSubscription?.profit;
    }, [profitValueSubscription]);

    const totalProfit = profitValueSubscription?.profitWithCommitions ? <FormatProfitSwapCommision tickValue={profitValueSubscription?.profitWithCommitions} /> : '--';

    const type = useMemo(() => {
        const label = renderType(actionType);
        return label.transition ? t(label.transition) : label.type;
    }, [actionType]);

    const handleChangeInput = useCallback(({ type, value }) => {
        const units = maskNumericalInput(value);
        setEditMode((prev) => ({
            ...prev,
            [type]: units
        }));
        if (type === 'takeProfit') {
            return handleChangeTakeProfit
				&& handleChangeTakeProfit();
        }
    }, [setEditMode, handleChangeTakeProfit]);

    const closeRate = useMemo(() => {
        if (!closeValue) return '--';
        const a = closeValue.ask;
        const b = closeValue.bid;
        const value = actionType === 0 ? b : a;
        // return pipDigital(value);
        return value;
    }, [actionType, closeValue]);

    const handleOnCloseOrder = useCallback(() => {
        handleCloseOrder && handleCloseOrder(amount);
    }, [handleCloseOrder, closeRate]);

    const handleOnUpdateOrder = useCallback(() => {
        handleUpdateOrder && handleUpdateOrder(editMode);
    }, [handleUpdateOrder, editMode]);

    const currentTakeProfit = useMemo(() => {
        return profitValue
            ? <FormatProfitSwapCommision tickValue={profitValue} />
            : '--';
    }, [profitValue]);

    const currentSwap = useMemo(() => {
        return profitValue
            ? <FormatProfitSwapCommision tickValue={swaps} />
            : '--';
    }, [swaps]);

    const currentCommission = useMemo(() => {
        return profitValue
            ? <FormatProfitSwapCommision tickValue={commission} />
            : '--';
    }, [commission]);

    const isCopiedForm = useMemo(() => {
        const tradeLink = tradeLinks[tpId];
        return tradeLink;
    }, [tradeLinks]);

    useEffect(() => {
        return () => {
            setEditMode({ stopLoss: '', takeProfit: '' });
        };
    }, []);

    const renderCopied = () => {
        const tradeLink = tradeLinks[tpId];

        const openProfilePage = () => {
            const nick = tradeLink?.sourceNickName;
            if (!nick) return null;
            handleUpdateHide && handleUpdateHide();
            openProfileUser && openProfileUser(nick);
        };

        const handleUnlinkOrder = () => {
            handleUnlinkOpen && handleUnlinkOpen(tpId);
        };

        if (tradeLink) {
            return (
                <div className='copied-from__container'>
                    <span
                        className='copied-from'
                        onClick={openProfilePage}
                    >
                        {tradeLink.sourceNickName}
                    </span>
                    <Button
                        text={t('InstrumetInfo.Details.Unlink')}
                        onClickHandler={handleUnlinkOrder}
                        className={clsx('close-unlink',
                            tradeLink.linkType !== 0 && 'no-pointers')}
                    />
                </div>
            );
        }
        return null;
    };

    return (
        <div className='mobile-update-position-popup__main'>

            {/* ERROR */}
            {responseErrorData && (
                <div className='mobile-update-position-popup__wrapper'>
                    <MobileModalError
                        title={responseErrorData}
                        onClose={(e) => handleOnClose(e, 'error')}
                    />
                </div>
            )}

            {/* SUCCESS */}
            {responseSuccessData && (
                <div className='mobile-update-position-popup__wrapper'>
                    {responseSuccessData?.type === 'close' && (
                        <MobileModalSuccess
                            onClose={(e) => handleOnClose(e, 'success')}
                            title={t('Close.Order.Success')}
                        >
                            <div>
                                <span>
                                    {t('InstrumetInfo.Details.CloseRate')}
                                    :&nbsp;
                                </span>
                                <span>{responseSuccessData?.price || 0}</span>
                            </div>
                            <div>
                                <span>
                                    {t('InstrumetInfo.Details.Profit')}
                                    :&nbsp;
                                </span>
                                <span>
                                    <FormatProfitSwapCommision
                                        tickValue={responseSuccessData?.profitValue || 0}
                                    />
                                </span>
                            </div>
                        </MobileModalSuccess>
                    )}
                    {responseSuccessData?.type === 'update' && (
                        <MobileModalSuccess
                            onClose={handleOnClose}
                            title='Position update successfully!' // TODO: locale this
                        />
                    )}
                </div>
            )}

            <div className='mobile-update-position-popup__main__inner'>
                {/* SECTION 1 */}
                <div className='mobile-update-position-popup__main__section'>

                    {/* ROW 1 */}
                    <div className='mobile-update-position-popup__main__section__row'>

                        <MobileUpdatePositionBlock
                            label={t('InstrumetInfo.Details.OpenTime')}
                            value={formatDate(openTimeS)}
                            time
                            left
                        />

                        <MobileUpdatePositionBlock
                            label={t('InstrumetInfo.Details.Type')}
                            value={type}
                            center
                        />
                        <MobileUpdatePositionBlock
                            label={t('InstrumetInfo.Details.Amount')}
                            value={amount}
                            right
                        />
                    </div>

                    {/* ROW 2 */}
                    <div className='mobile-update-position-popup__main__section__row'>

                        <MobileUpdatePositionBlock
                            label={t('InstrumetInfo.Details.OpenRate')}
                            value={openRate}
                            left
                        />

                        <MobileUpdatePositionBlock
                            label={t('InstrumetInfo.Details.CloseRate')}
                            value={closeRate}
                            center
                        />

                        {/* TODO: dynamic data */}
                        <MobileUpdatePositionBlock
                            // TODO: locale this
                            label='Distance'
                            // TODO: dynamic data
                            value='+3.1'
                            right
                        />
                    </div>

                    {/* ROW 3 */}
                    <div className='mobile-update-position-popup__main__section__row'>

                        <MobileUpdatePositionBlock
                            label={t('InstrumetInfo.Details.Profit')}
                            value={currentTakeProfit}
                            left
                        />

                        <MobileUpdatePositionBlock
                            label={t('InstrumetInfo.Details.Swap')}
                            value={currentSwap}
                            center
                        />
                        <MobileUpdatePositionBlock
                            label={t('InstrumetInfo.Details.Commission')}
                            value={currentCommission}
                            right
                        />
                    </div>

                    {/* ROW 4 */}
                    <div className='mobile-update-position-popup__main__section__row'>

                        <MobileUpdatePositionBlock
                            label={t('InstrumetInfo.Details.TotalProfit')}
                            value={totalProfit}
                            left
                        />

                        <MobileUpdatePositionBlock
                            label={t('InstrumetInfo.Details.CopiedFrom')}
                            value={renderCopied()}
                            disabled={!isCopiedForm}
                            double
                        />

                    </div>

                </div>

                {/* SECTION 2 */}
                <div className={clsx('mobile-update-position-popup__main__section',
                    'dark')}
                >

                    <div className='mobile-update-position-popup__main__section__row'>
                        <div
                            className={clsx(
                                'mobile-update-position-popup__main__section__row__block',
                                'offset'
                            )}
                        >
                            <MobileUpdatePositionInput
                                label={t('InstrumetInfo.Details.StopLoss')}
                                types='stopLoss'
                                tpId={tpId}
                                symbolId={symbolId}
                                defaultValue={stopLoss || ''}
                                selectedUnits={editMode.stopLoss}
                                onChange={handleChangeInput}
                                shouldClear
                                inputmode='numeric'
                            />
                        </div>

                        <div
                            className={clsx(
                                'mobile-update-position-popup__main__section__row__block',
                                'offsetMini'
                            )}
                        >
                            <div
                                className='mobile-update-position-popup__main__section__row__block__inner'
                            >
                                <div
                                    className='mobile-update-position-popup__main__section__row__block__inner__block'
                                >
                                    {!!editMode.stopLoss && (
                                        <Fragment>
                                            <span
                                                className='mobile-update-position-popup__main__section__row__block__inner__block__title'
                                            >
                                                {t('Trade.Execution.EstimatedProfit')}
                                                &nbsp;
                                                {t('InstrumetInfo.Details.PL')}
                                                :&nbsp;
                                            </span>
                                            <span>
                                                <FormattedSLTPProfit
                                                    profit={ProfitCalculationService.calculateProfit(symbols[symbolName], actionType, amount, openRate, normalizedEditNumber(editMode.stopLoss,editMode.stopLoss), closeConvertion)}
                                                    symbol={symbol}
                                                    noText
                                                />
                                            </span>
                                        </Fragment>
                                    )}
                                </div>

                                {/* TODO: distance */}
                                {/*	<div */}
                                {/*			className="mobile-update-position-popup__main__section__row__block__inner__block"> */}
                                {/*		/!*TODO: locale this*!/ */}
                                {/*		<span */}
                                {/*				className="mobile-update-position-popup__main__section__row__block__inner__block__title"> */}
                                {/*		Distance */}
                                {/*		:&nbsp; */}
                                {/*	</span> */}
                                {/*		/!*TODO: dynamic data*!/ */}
                                {/*		<span> */}
                                {/*		&nbsp; */}
                                {/*			+10 */}
                                {/*	</span> */}
                                {/*	</div> */}

                            </div>
                        </div>
                    </div>

                    <div className='mobile-update-position-popup__main__section__row'>
                        <div
                            className={clsx(
                                'mobile-update-position-popup__main__section__row__block',
                                'offset'
                            )}
                        >
                            <MobileUpdatePositionInput
                                label={t('InstrumetInfo.Details.TakeProfit')}
                                types='takeProfit'
                                tpId={tpId}
                                symbolId={symbolId}
                                defaultValue={takeProfit || ''}
                                selectedUnits={editMode.takeProfit}
                                onChange={handleChangeInput}
                                shouldClear
                                inputmode='numeric'
                            />
                        </div>

                        <div
                            className={clsx(
                                'mobile-update-position-popup__main__section__row__block',
                                'offsetMini'
                            )}
                        >
                            <div
                                className='mobile-update-position-popup__main__section__row__block__inner'
                            >
                                <div
                                    className='mobile-update-position-popup__main__section__row__block__inner__block'
                                >
                                    {!!editMode.takeProfit && (
                                        <Fragment>
                                            <span
                                                className='mobile-update-position-popup__main__section__row__block__inner__block__title'
                                            >
                                                {t('Trade.Execution.EstimatedProfit')}
                                                &nbsp;
                                                {t('InstrumetInfo.Details.PL')}
                                                :&nbsp;
                                            </span>
                                            <span>
                                                <FormattedSLTPProfit
                                                    profit={ProfitCalculationService.calculateProfit(symbols[symbolName], actionType, amount, openRate, normalizedEditNumber(editMode.takeProfit,editMode.takeProfit), closeConvertion)}
                                                    symbol={symbol}
                                                    noText
                                                />
                                            </span>
                                        </Fragment>
                                    )}
                                </div>

                                {/* TODO: distance */}
                                {/* <div */}
                                {/*		className="mobile-update-position-popup__main__section__row__block__inner__block"> */}
                                {/*	/!*TODO: locale this*!/ */}
                                {/*	<span */}
                                {/*			className="mobile-update-position-popup__main__section__row__block__inner__block__title" */}
                                {/*	> */}
                                {/*	Distance */}
                                {/*	:&nbsp; */}
                                {/* </span> */}
                                {/*	/!*TODO: dynamic data*!/ */}
                                {/*	<span> */}
                                {/*	&nbsp; */}
                                {/*		+10 */}
                                {/* </span> */}
                                {/* </div> */}

                            </div>
                        </div>
                    </div>

                </div>
            </div>


            {/* BUTTON */}
            <div className='mobile-update-position-popup__btn-wrapper'>
                {/* TODO: locale this / add handler */}
                <MobileButton
                    onClick={handleOnCloseOrder}
                    title={`Close at rate ${closeRate}`}
                    className='mobile-update-position-popup__btn'
                />
                {!!isUpdate && (
                    <MobileButton
                        onClick={handleOnUpdateOrder}
                        title={t('InstrumetInfo.Details.Update')}
                        className='mobile-update-position-popup__btn'
                    />
                )}
            </div>

        </div>
    );
};

export default MobileUpdatePositionMain;
