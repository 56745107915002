import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
// import PropTypes from 'prop-types';

import CheckBox from '../primitives/CheckBox';
import OuterClick from '../primitives/OuterClick';
import Button from '../primitives/Button';


const CurrencySelectExpanded = ({ portfolio, instruments, setInstruments, handleClick, systemColor }) => {
    const [currencies, setCurrencies] = useState(null);
    const [initialCurrencies, setInitialCurrencies] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [all, setAll] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        const result = {};
        if (instruments?.length) {
            if (portfolio?.TopInstruments?.length) {
                portfolio.TopInstruments.forEach((item) => {
                    result[item?.Symbol] = instruments.includes(item?.Symbol);
                });
            } else if (portfolio?.AllowedInstruments?.length) {
                portfolio.AllowedInstruments.forEach((item) => {
                    result[item?.Symbol] = instruments.includes(item?.Symbol);
                });
            }
        } else if (portfolio?.TopInstruments?.length) {
            portfolio.TopInstruments.forEach((item) => {
                result[item?.Symbol] = true;
            });
        } else if (portfolio?.AllowedInstruments?.length) {
            portfolio.AllowedInstruments.forEach((item) => {
                result[item?.Symbol] = true;
            });
        }
        setCurrencies(result);
        setInitialCurrencies(result);
    }, [instruments]);

    useEffect(() => {
        const searchResult = {};

        if (searchValue) {
            Object.entries(currencies).forEach(([key, value]) => {
                if (key.toLowerCase().includes(searchValue.toLowerCase())) {
                    searchResult[key] = value;
                }
            });
            setCurrencies(searchResult);
        } else {
            // eslint-disable-next-line no-unused-expressions
            initialCurrencies && setCurrencies(initialCurrencies);
        }
    }, [searchValue]);

    const selectCurrency = (e) => {
        setCurrencies((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    };

    const toggleAll = () => {
        const changedCurrencies = {};
        Object.keys(currencies).forEach((key) => {
            if (!all) {
                changedCurrencies[key] = true;
            } else {
                changedCurrencies[key] = false;
            }
        });
        setCurrencies(changedCurrencies);
    };

    useEffect(() => {
        if (currencies) {
            const allTrue = Object.values(currencies).every((item) => item);
            setAll(allTrue);
        }
    }, [currencies]);

    const handleCurrencies = () => {
        const arrayToUse = [];

        Object.entries(currencies).forEach(([key, value]) => { if (value) { arrayToUse.push(key); } });
        if (!all) {
            setInstruments(arrayToUse);
        } else {
            setInstruments([]);
        }
        handleClick();
    };

    const handleSearch = (e) => {
        setSearchValue(e.target.value);
    };

    const resetSearch = () => {
        setSearchValue('');
    };

    const icon = searchValue ? (
        <InputAdornment position='end'>
            <Icon className='fas fa-times' onClick={resetSearch} />
        </InputAdornment>
    ) : (
        <InputAdornment position='end'>
            <Icon className='fas fa-search' />
        </InputAdornment>
    );


    return (
        <div className='currency-expanded__container'>
            <div className='currency-expanded__search-container'>
                <Input
                    id='symbol-search'
                    placeholder={t('MarketRates.Search')}
                    name='symbol-search'
                    value={searchValue}
                    onChange={handleSearch}
                    fullWidth
                    endAdornment={icon}
                    autoComplete='off'
                />
            </div>
            <div className='currency__list'>
                <CheckBox
                    value={all}
                    name='all'
                    label='All'
                    labelPlacement
                    color={systemColor}
                    handleCheckBoxClick={toggleAll}
                    key={all}
                />
                {currencies && Object.entries(currencies).map(([key, value]) => {
                    return (
                        <CheckBox
                            value={value}
                            name={key}
                            label={key}
                            labelPlacement
                            color={systemColor}
                            handleCheckBoxClick={selectCurrency}
                            key={key}
                        />
                    );
                })}
            </div>
            <div className='currency-expanded__buttons'>
                <Button text='Cancel' className='btn btn-cancel btn--general' onClickHandler={handleClick} />
                <Button text='Ok' className='btn btn-update btn--general' onClickHandler={handleCurrencies} />
            </div>
        </div>
    );
};

// CurrencySelectExpanded.propTypes = {};

export default OuterClick(CurrencySelectExpanded);
