import React, { useImperativeHandle, forwardRef, Component } from 'react';
// import { Chart } from 'frappe-charts/dist/frappe-charts.min.esm';
// import { Chart } from 'frappe-charts/dist/frappe-charts.esm';
import { Chart } from './chart';
import './Chart.css';


class Graph extends Component {
    componentDidMount() {
        const {
            title, data, type, height = 250, onSelect, isLoading, ...rest
        } = this.props;
        this.graph = new Chart(this.chart, {
            title,
            data,
            type,
            height,
            is_navigable: !!onSelect,
            ...rest
        });
        if (onSelect) {
            this.graph.parent.addEventListener('data-select', onSelect);
        }
    }

    componentDidUpdate(prevProps) {
        const { data, isLoading } = this.props;
        if (prevProps.data !== data && !isLoading && data) {
            this.graph.update(data);
        }
    }

    render() {
        return <div ref={(chart) => (this.chart = chart)} />;
    }
}

export default Graph;

// const Graph = forwardRef((props, parentRef) => {
//     const ref = React.useRef(null);
//     const chart = React.useRef(null);
//     const { onDataSelect } = props;

//     // useImperativeHandle(parentRef, () => ({
//     //     export: () => {
//     //         if (chart && chart.current) {
//     //             chart.current.export();
//     //         }
//     //     }
//     // }));

//     React.useEffect(() => {
//         chart.current = new Chart(ref.current, {
//             isNavigable: onDataSelect !== undefined,
//             ...props
//         });
//         if (onDataSelect) {
//             chart.current.parent.addEventListener('data-select', (e) => {
//                 e.preventDefault();
//                 onDataSelect(e);
//             });
//         }
//     }, []);

//     React.useEffect(() => {
//         chart.current.update(props.data);
//     }, [props.data]);

//     return <div ref={ref} />;
// });

// export default Graph;
