import React, {useCallback, useMemo, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';

import MobileModal from '../../components/MobileModal/MobileModal';
import MobileButton from '../../components/Buttons/MobileButton';

import { CommandFactory } from '../../../background/commands/CommandFactory'

import MobileOpenPositionHead
	from './MobileOpenPosition/MobileOpenPositionHead';
import MobileAutosizer from '../../components/MobileAutosizer/MobileAutosizer';
import MobileOpenPositionItem
	from './MobileOpenPosition/MobileOpenPositionItem';
import usePosition from '../../context/contextHook/usePosition';

const MobilePendingOrders = () => {
	const [deleteDate, setDeleteData] = useState(null);
	const [commandError, setCommandError] = useState('');

	const {positionUpdate} = usePosition()
	const {t} = useTranslation();

	const {positions} = useSelector(
			state => state.account.positions.pending, shallowEqual);

	const positionsToRender = useMemo(() => {
		const positionsToRender = [];
		for (const key in positions) {
			const position = positions[key];
			positionsToRender.push(position);
		}
		positionsToRender.sort((a,b) => b.openTimeS-a.openTimeS);
		return positionsToRender;
	}, [positions]);

	const renderColumns = useMemo(() => {
		return [
			'instrument',
			'type',
			'amount',
			'price',
			'currentPrice',
			'delete',
		];
	}, [positionsToRender]);

	const handleSetUpdateOrderData = useCallback(data => {
		if (!positionUpdate) return null;
		const {handleUpdateOpen} = positionUpdate
		handleUpdateOpen && handleUpdateOpen(data);
	}, [positionUpdate]);

	const handleDeleteOrderData = useCallback(data => {
		setDeleteData(data);
	}, []);

	const handleDeleteConfirmClose = useCallback(() => {
		setDeleteData(null);
	}, []);

	const handleButtonConfirm = useCallback(async () => {
		const cmd = deleteDate?.deletePedingCMD;
		setCommandError(null);
		try {
			const command = CommandFactory.createCommand(cmd);
			const res = await command.execute();

			if (res.isSuccessful) {
				handleDeleteConfirmClose();
			}
			if (!res.isSuccessful) {
				setCommandError(res.result ?? 'trading.error.GeneralError');
			}
		} catch (err) {
			setCommandError('trading.error.GeneralError');
		}
	}, [deleteDate]);

	if (!positionsToRender || !positionsToRender.length) {
		return (
				<div className="note">
					<div className="note__text">
						{/*TODO: locale this*/}
						No Pending Orders
					</div>
				</div>
		);
	}

	const renderRow = data => {
		const {item, props} = data;
		return (
				<MobileOpenPositionItem
						{...item}
						{...props}
						columns={renderColumns}
				/>
		);
	};

	return (
			<>
				<div className="mobile-position">
					<MobileOpenPositionHead
							columns={renderColumns}
							type="pending"
					/>
					<MobileAutosizer
							options={positionsToRender}
							updateOrderDate={handleSetUpdateOrderData}
							deleteOrderData={handleDeleteOrderData}
							renderRow={renderRow}
							className="mobile-open-position__row"
							positionType="pending"
					/>
				</div>

				<MobileModal
						isOpen={!!deleteDate}
						onClose={handleDeleteConfirmClose}
						className="top"
				>
					<div className="mobile-modal-delete">
						{commandError && (
								<p className='red error'>
									{t(commandError)}
								</p>
						)}

						{!commandError && (
								<p>
									{t('InstrumetInfo.Details.SureDelete')}
								</p>
						)}

						<div className="mobile-modal-delete__section">
							<div className="mobile-modal-delete__btn-wrapper">
								<MobileButton
										type="success"
										className={clsx('mobile-modal-delete__btn', 'success')}
										onClick={handleButtonConfirm}
										title={t('InstrumetInfo.Details.Delete')}
										disabled={commandError}
								/>
							</div>
							<div className="mobile-modal-delete__btn-wrapper">
								<MobileButton
										type="cancel"
										className={clsx('mobile-modal-delete__btn', 'cancel')}
										onClick={handleDeleteConfirmClose}
										title={t('InstrumetInfo.Details.Cancel')}
								/>
							</div>
						</div>
					</div>

				</MobileModal>
			</>
	);
};

export default MobilePendingOrders;
