import React, {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import MobileClosedPositionActiveRow from './MobileClosedPositionActiveRow';
import MobileClosedPositionActiveBlock from './MobileClosedPositionActiveBlock';

import {formatDate} from '../../../../UI/utilsUI/formatTime';
import FormatProfitSwapCommision
	from '../../../../UI/utilsUI/FormatProfitSwapCommision';
import useTheme from '../../../context/contextHook/useTheme';

const MobileClosedPositionActive = props => {
	const {
		openTimeS,
		closeTimeS,
		openRate,
		closeRate,
		commission,
		swaps,
		TakeProfit,
		StopLoss,
	} = props;

	const {Profit} = useSelector(state => state.brand.theme);
	const {isDarkTheme} = useTheme();

	const {Positive, Negative, Neutral} = useMemo(() => {
		return Profit;
	}, [Profit]);

	const renderProfit = useCallback(value => {
		return <div>
			<FormatProfitSwapCommision
					tickValue={value}
					positive={isDarkTheme ? Positive : '#04B66B'}
					negative={isDarkTheme ? Negative : '#CE4B4B'}
					neutral={isDarkTheme ? Neutral : '#283B55'}
			/>
		</div>;
	}, [Positive, Negative, Neutral, isDarkTheme]);

	const openTime = useMemo(() => {
		return formatDate(openTimeS);
	}, [openTimeS]);

	const closeTime = useMemo(() => {
		return formatDate(closeTimeS);
	}, [closeTimeS]);

	const {t} = useTranslation();

	return (
			<div className="mobile-closed-position__active">
				{/*ROW 1*/}
				<MobileClosedPositionActiveRow>
					<MobileClosedPositionActiveBlock
							title={t('InstrumetInfo.Details.OpenTime')}
							data={openTime}
					/>

					<div className="mobile-closed-position__active__inner">
						<MobileClosedPositionActiveBlock
								title={t('InstrumetInfo.Details.OpenRate')}
								data={openRate}
						/>
						<MobileClosedPositionActiveBlock
								title={t('InstrumetInfo.Details.Commission')}
								data={renderProfit(commission)}
						/>
					</div>

				</MobileClosedPositionActiveRow>

				{/*ROW 2*/}
				<MobileClosedPositionActiveRow>
					<MobileClosedPositionActiveBlock
							title={t('InstrumetInfo.Details.CloseTime')}
							data={closeTime}
					/>

					<div className="mobile-closed-position__active__inner">
						<MobileClosedPositionActiveBlock
								title={t('InstrumetInfo.Details.CloseRate')}
								data={closeRate}
						/>
						<MobileClosedPositionActiveBlock
								title={t('InstrumetInfo.Details.Swap')}
								data={renderProfit(swaps)}
						/>
					</div>

				</MobileClosedPositionActiveRow>

				{/*ROW 3*/}
				<MobileClosedPositionActiveRow>
					<div className="mobile-closed-position__active__inner">
						<MobileClosedPositionActiveBlock
								title={t('InstrumetInfo.Details.TakeProfit')}
								data={TakeProfit || '--'}
						/>
						<MobileClosedPositionActiveBlock
								title={t('InstrumetInfo.Details.StopLoss')}
								data={StopLoss || '--'}
						/>
					</div>
				</MobileClosedPositionActiveRow>
			</div>
	);
};

export default MobileClosedPositionActive;
