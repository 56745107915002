import React from 'react';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';

import MobileSocialExploreStatisticBlock
	from '../../components/MobileSocial/MobileSocialExplore/MobileSocialExploreStatisticBlock';

const MobileProfileStatistics = ({isActive, pnl, daysFromNow, successRate}) => {

	const {t} = useTranslation();

	return (
			<div
					className="mobile-social-explore__item__row-statistics__row">
				<div
						className={clsx(
								'mobile-social-explore__item__row-statistics__row__item',
								isActive && 'active')}
				>
					{/*TODO: locale this*/}
					<MobileSocialExploreStatisticBlock
							label='Days'
							number={daysFromNow}
							isActive={isActive}
					/>
				</div>

				<div
						className={clsx(
								'mobile-social-explore__item__row-statistics__row__item',
								isActive && 'active')}
				>
					<MobileSocialExploreStatisticBlock
							label={t('Socials.PNL')}
							number={`${pnl}%`}
							isActive={isActive}
							className={clsx(pnl < 0 && 'red')}
					/>
				</div>

				<div
						className={clsx(
								'mobile-social-explore__item__row-statistics__row__item',
								isActive && 'active')}
				>
					<MobileSocialExploreStatisticBlock
							label={t('Socials.SuccessRate')}
							number={successRate || '0'}
							isActive={isActive}
					/>
				</div>
			</div>
	);
};

MobileProfileStatistics.defaultProps = {
	isActive: false,
	pnl: 0,
	daysFromNow: 0,
	successRate: 0,
};

export default MobileProfileStatistics;
