export const getEvent = e => {
	return (e && e.type.search('touch') !== -1) ? e.touches[0] : e;
};

export const getTransformFromMatrix = transform => {
	const y = transform
			.replace('matrix', '')
			.replace('(', '')
			.replace(')', '')
			.split(',')
			.map(el => Number(el))
			.find((el, i, arr) => i === arr.length - 1);
	// .filter((el, i, arr) => i === arr.length - 1)
	// .map(el => el + 'px')
	// .join();

	const x = transform
			.replace('matrix', '')
			.replace('(', '')
			.replace(')', '')
			.split(',')
			.map(el => Number(el))
			.find((el, i, arr) => i === arr.length - 2);

	return {y, x};
};

export const getStyleItem = item => {
	if (!item) return null;
	const css = item.currentStyle || window.getComputedStyle(item);
	return {
		css,
		width: item.offsetWidth,
		height: item.offsetHeight,
		marginRight: parseInt(css.marginRight),
		transformY: getTransformFromMatrix(css.transform).y,
		transformX: getTransformFromMatrix(css.transform).x,
		offsetWidth: item.offsetWidth / 2,
		offsetHeight: item.offsetHeight / 2,
	};
};

export const getRectItem = item => {
	const style = item.getBoundingClientRect();
	return {
		x1: style.x,
		x2: style.x + style.width,
		y1: style.y,
		y2: style.y + style.height,
		h: style.height,
		w: style.width,
		top: style.top,
		right: style.right,
		bottom: style.bottom,
		left: style.left,
	};
};

export const getCarouselListStyle = index => {
	return {
		transform: `translateX(-${index * 100}%)`,
	};
};
