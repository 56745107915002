/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';

import UsersList from './UsersList';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';


const TopRankedList = ({ handleItemSelect, limit }) => {
    const { topTraders, topTradersOrder } = useSelector((state) => { return (state.account.socialv1); }, shallowEqual);
    const [orderedTopTraders, setOrderedTopTraders] = useState([]);

    useEffect(() => {
        let temp = [];
        for (const tradersToDisplay in topTradersOrder) {
            const foundTrader = topTraders[topTradersOrder[tradersToDisplay]];
            if (foundTrader) {
                temp.push(foundTrader);
            }
        }
        if (limit) {
            temp = temp.slice(0, 20);
        }
        setOrderedTopTraders(temp);
        // setIsLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topTraders, topTradersOrder]);

    useEffect(() => {
      SendEventToGoogleAnalytics("SocialPanel:TopRankedTabOpened")
  }, []);


    return (
        <UsersList
            users={orderedTopTraders}
            handleItemSelect={handleItemSelect}
        />
    );
};

// TopRankedList.propTypes = {};

export default TopRankedList;
