import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const types = {
	open: ['instrument', 'action', 'open_rate', 'pnl'],
};

const inactive = {
	open: ['none'],
};

const heads = [
	{
		id: 0,
		value: 'instrument',
		label: 'Instrument',
		translate: 'InstrumetInfo.Details.Instrument',
	},
	{
		id: 1,
		value: 'action',
		label: 'Action',
		translate: 'InstrumetInfo.Details.Type',
	},
	{
		id: 2,
		value: 'open_rate',
		label: 'Open Rate',
		translate: 'InstrumetInfo.Details.OpenRate',
	},
	{
		id: 3,
		value: 'pnl',
		label: 'P/L (%)',
		translate: 'InstrumetInfo.Details.PL',
	},
];

const typesHead = type => {
	return heads.filter(({value}) => types[type].includes(value));
};

const MobileProfilePositionItemHead = ({type, active, onActive, sort}) => {

	const {t} = useTranslation();

	const renderHeadList = useMemo(() => {
		return typesHead(type);
	}, [type]);

	const handleClick = useCallback((e, item) => {
		e && e.preventDefault();
		e && e.stopPropagation();
		if (!active) return null;
		if (inactive[type].includes(item.value)) return null;
		onActive && onActive(item);
	}, [active, type]);

	const sortClass = item => {
		if (!sort || !active || !item) return null;
		const isActive = sort.value === item.value;
		const isUp = sort.type;
		return clsx(isActive && 'active', isUp && 'up');
	};

	return (
			<ul className={clsx('mobile-profile-position-head', type)}>
				{renderHeadList.map((el, i) => (
						<div
								key={`head-item_${el.id}`}
								onClick={e => handleClick(e, {...el, index: i})}
								className="mobile-profile-position-head__item"
						>
							<div className="mobile-profile-position-head__item__inner">

								<div
										className={clsx('mobile-profile-position-head__item__arrow',
												sortClass(el))}
								>
									<ArrowDropDownIcon/>
								</div>

								<p className="mobile-profile-position-head__item__title">
									{/*TODO: locale this*/}
									{/*{el.translate ? t(el.translate) : el.label}*/}
									{el.label}
								</p>
							</div>

						</div>
				))}
			</ul>
	);
};

export default MobileProfilePositionItemHead;
