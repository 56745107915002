import React, {useCallback, useMemo} from 'react';
import clsx from 'clsx';

import MobileSwipeRow from '../../../components/MobileSwipeRow/MobileSwipeRow';

import PubsubSubscription from '../../../../background/repositories/PubsubSubscription';
import {prepareBidAsk} from '../../../../UI/utilsUI/formatWSTick';
import useWindowSize from '../../../../UI/utilsUI/useWindowSize';
import MobileSymbolIcon
	from '../../../components/MobileSymbolIcon/MobileSymbolIcon';

const MobileTradingAllItem = props => {
	const {
		id,
		index,
		displayName,
		flag,
		tick,
		displayDigit,
		handleBackClick,
		onClick,
		handleIndex,
		isActive,
		isPin,
	} = props;

	const [symbolTick, previousTick] = PubsubSubscription.subscribeObjAndPrev(
			tick);
	const {bidOutput, askOutput} = prepareBidAsk(symbolTick, previousTick,
			displayDigit);

	const [width] = useWindowSize();

	const handleButtonClick = useCallback(e => {
		e && e.preventDefault();
		handleBackClick && handleBackClick();
	}, [handleBackClick]);

	const handleRowClick = useCallback(e => {
		e && e.preventDefault();
		onClick && onClick(props);
		handleIndex && handleIndex(index);
	}, [onClick, handleIndex]);

	const leftButtons = useMemo(() => {
		return [
			// <div className="mobile-trading-all__item__content__button_swipe">
			// 	<MobileButtonPin/>,
			// </div>,
			// <div style={{
			// 	padding: '0 20px',
			// 	background: 'orange',
			// 	height: '50px',
			// 	// width: width * 0.3,
			// 	// marginRight: '30px'
			// }}>pin</div>,
			// <div style={{padding: '12px', background: 'orange', height: '100%'}}>test
			// 	pin button</div>,
			// <div style={{padding: '12px', background: 'gray'}}>Pin</div>,
			// <div style={{padding: '12px', background: 'pink'}}>Mute</div>
		];
	}, [width]);

	return (
			<MobileSwipeRow
					key={id}
					rowId={id}
					leftButtons={leftButtons}
					disableSwipeLeft
					rowHeight={'50px'}
			>
				<div
						className="mobile-trading-all__item__content"
						onClick={handleRowClick}
				>
					<div className="mobile-trading-all__item__content__left">

						<div
								className={clsx('mobile-trading-all__item__content__pin',
										// isPin && 'active',
								)}
						/>

						<div className="mobile-trade__header__desc">
							<div className="mobile-trade__header__desc__flag">
								<MobileSymbolIcon name={displayName} flag={flag}/>
							</div>
							<p className="mobile-trade__header__desc__title">
								{displayName}
							</p>
						</div>

					</div>

					<div className="mobile-trading-all__item__content__tick">
						{isActive && (
								<button
										className="mobile-trading-all__item__content__tick__button"
										onClick={e => handleButtonClick(e)}
								>
									{/*TODO: locale*/}
									Go to the chart
								</button>
						)}

						{!isActive && (
								<div
										className="mobile-trading-all__item__content__tick__wrapper">
									<div className="bid-item__bid">
										<div>{bidOutput}</div>
									</div>
									<div className="bid-item__ask">
										<div>{askOutput}</div>
									</div>
								</div>
						)}
					</div>
				</div>
			</MobileSwipeRow>
	);
};

export default MobileTradingAllItem;
