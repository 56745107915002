import React, {useState} from 'react';

import {
	allTradesStream,
	topRankedTradeStream,
	watchTradersStream,
} from '../../background/socialv1/tradesStreams';

const useStreamConnect = () => {
	const [tradersIWatch] = useState(
			watchTradersStream);

	const [allTrades] = useState(
			allTradesStream);

	const [topTraders] = useState(
			topRankedTradeStream);

	const [, setRerenderTime] = useState(null);

	const tradersWatchList = [];
	const tradersAllList = [];
	const tradersTopList = [];

	for (const item of tradersIWatch.getStackOrder()) {
		tradersWatchList.push(item);
	}

	for (const item of allTrades.getStackOrder()) {
		tradersAllList.push(item);
	}

	for (const item of topTraders.getStackOrder()) {
		tradersTopList.push(item);
	}

	tradersIWatch.subscribeReact(() => {
		setRerenderTime(Date.now());
	});
	allTrades.subscribeReact(() => {
		setRerenderTime(Date.now());
	});
	topTraders.subscribeReact(() => {
		setRerenderTime(Date.now());
	});

	return {
		tradersIWatch: tradersWatchList,
		tradersAll: tradersAllList,
		tradersTop: tradersTopList,
	};
};

export default useStreamConnect;
