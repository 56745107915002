import React, { useRef, useState, Fragment } from 'react';

import { CommandFactory } from '../../background/commands/CommandFactory';
import ToolTip from '../primitives/ToolTip';
// import Flag from '../Flag/Flag';

import TickItemToolTipContent from './TickItemToolTipContent';
import TickItemBidAsk from './TickItemBidAsk';
import './TickItem.css';


const TickItem = ({
    name,
    bid,
    ask,
    tickData,
    isActive,
    shouldDisplayPin,
    shouldDisplayInfo,
    // shouldDisplayFlags,
    shouldDisplayTime,
    subHeaderColor,
    timeUpdate,
    tickUp
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [isLoading, setIsLoading] = useState(false);
    const toolTip = useRef();
    const symbolClass = isActive ? 'bid-item__container active' : 'bid-item__container';

    const pinSymbol = async (e) => {
        e.stopPropagation();
        const command = CommandFactory.createCommand(tickData.togglePinSymbolCMD);
        setIsLoading(true);

        try {
            // setResponse(null);
            const res = await command.execute();

            if (res.isSuccessful === true) {
                // setOrderInfo((prevState) => {
                //     return { ...prevState, status: 'success', price: pendingPrice };
                // });
            } else if (res.isSuccessful === false) {
                // setResponse(res);
            }
        } catch (err) {
            // setResponse(err);
        }

        setIsLoading(false);
    };


    const handleOnMouseOut = (evt) => {
        // eslint-disable-next-line no-unused-expressions
        evt && evt.stopPropagation();

        toolTip.current.hide();
        setIsExpanded(false);
    };

    const handleOnMouseOver = (evt) => {
        evt.stopPropagation();

        const el = evt.currentTarget;
        setIsExpanded(true);

        if (el != null) {
            const rect = el.getBoundingClientRect();
            toolTip.current.show(rect);
        }
    };


    return (
        <div className={symbolClass} data-symbol={name} data-pinned={tickData.isPin}>
            {/* <div style={{ display: 'flex' }}> */}
            {shouldDisplayPin && (
                <div className='bid-item__star'>
                    {tickData.isPin ? (
                        <div className='favorite' onClick={pinSymbol}>
                            <i className='fas fa-thumbtack' />
                        </div>
                    ) : (
                        <div onClick={pinSymbol}>
                            <i className='fas fa-thumbtack' />
                        </div>
                    )}
                </div>
            )}
            {/* {shouldDisplayFlags && <Flag name={name} />} */}
            <div className='bid-item__name'>
                {name}
            </div>
            <TickItemBidAsk bid={bid} ask={ask} subHeaderColor={subHeaderColor} tickUp={tickUp} />
            {shouldDisplayTime && (
                <div className='bid-item__time'>
                    {timeUpdate}
                </div>
            )}
            {shouldDisplayInfo && (
                <Fragment>
                    <div
                        className='bid-item__info'
                        onClick={handleOnMouseOver}
                    >
                        <i className='fas fa-info' />
                    </div>
                    {!isExpanded ? <ToolTip ref={toolTip} /> : (
                        <ToolTip ref={toolTip}>
                            <TickItemToolTipContent tickData={tickData} onCloseTooltip={handleOnMouseOut} handleClick={handleOnMouseOut} />
                        </ToolTip>
                    )}
                </Fragment>
            )}
            {/* </div> */}
        </div>
    );
};


export default TickItem;
