import React from 'react';
// import PropTypes from 'prop-types';

import pubsubSubscription from '../../background/repositories/PubsubSubscription';


const OpenPositionsVirtualCloseRate = ({ symbolName, actionType }) => {
    const closeValue = pubsubSubscription.subscribeObj({ key: symbolName, repId: 'ticks' });
    const closeValueData = actionType === 0 ? closeValue?.bid : closeValue?.ask;

    return (
        <div>
            {closeValue && closeValueData}
        </div>
    );
};

// OpenPositionsVirtualCloseRate.propTypes = {};

export default OpenPositionsVirtualCloseRate;
