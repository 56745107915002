import React from 'react';
// import PropTypes from 'prop-types';
import './Switch.css';

// eslint-disable-next-line object-curly-newline
const Switch = ({ offText, onText, handleSwitch, isSelected, name, generalColor = {}, activeColor }) => {
    const toggleSwitch = (e) => {
        e.stopPropagation();
        handleSwitch(e);
    };

    const inputProps = {
        name,
        type: 'checkbox',
        onClick: toggleSwitch,
        value: isSelected
        // checked: isSelected
    };

    const containerClassName = isSelected ? 'switch-container checked' : 'switch-container';

    return (
        <div className={containerClassName}>
            <div className='switch__left-text' style={!isSelected ? { color: activeColor } : generalColor}>{offText}</div>
            <div className='switch__button' onClick={toggleSwitch} data-name={name} role='button' tabIndex='0' aria-label='pending order' aria-hidden='true'>
                <input {...inputProps} readOnly />
                <span className='switch__button-track' data-name={name} />
                <span className='switch__button-thumb' style={isSelected ? { color: activeColor, backgroundColor: activeColor } : {}} />
            </div>
            <div className='switch__right-text' style={isSelected ? { color: activeColor } : generalColor}>{onText}</div>
        </div>
    );
};

// Switch.defaultProps = {
//     offText: '',
//     onText: ''
// };


// Switch.propTypes = {
//     offText: PropTypes.string,
//     onText: PropTypes.string,
//     handleSwitch: PropTypes.func.isRequired
// };

export default Switch;
