const sortArray = (a = null, b = null, type = true) => {
	if (!a || !b) return 0;
	const sortUp = () => a > b ? 1 : -1;
	const sortDown = () => a < b ? 1 : -1;
	return type ? sortUp() : sortDown();
};

const sortOpenTime = ({options, type}) => {
	return options
			.sort((a, b) => sortArray(a.OpenTime, b.OpenTime, type));
};
const sortOpenRate = ({options, type}) => {
	return options
			.sort((a, b) => sortArray(a.OpenPrice, b.OpenPrice, type));
};
const sortInstrument = ({options, type}) => {
	return options
			.sort((a, b) => sortArray(a.InstrumentName, b.InstrumentName, type));
};
const sortAction = ({options, type}) => {
	return options
			.sort((a, b) => sortArray(a.TradeActionType, b.TradeActionType, type));
};
const sortPnl = ({options, type}) => {
	return options
			.sort((a, b) => sortArray(a.PL, b.PL, type));
};

export const SORT_TYPE = {
	'open_time': data => sortOpenTime(data),
	'open_rate': data => sortOpenRate(data),
	'instrument': data => sortInstrument(data),
	'action': data => sortAction(data),
	'pnl': data => sortPnl(data),
};

export const sortProfilePosition = (options = [], sort = {}) => {
	if (!options || !options.length) return options;
	return SORT_TYPE[sort.value]({options, type: sort.type});
};
