import React from 'react';
// import PropTypes from 'prop-types';

const EducationIcon = ({ fill }) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21' fill='none'>
            <path d='M17.925 3.075C16.025 1.175 13.4 0 10.5 0C7.6 0 4.975 1.175 3.075 3.075C1.175 4.975 0 7.6 0 10.5C0 13.4 1.175 16.025 3.075 17.925C4.975 19.825 7.6 21 10.5 21C13.4 21 16.025 19.825 17.925 17.925C19.825 16.025 21 13.4 21 10.5C21 7.6 19.825 4.975 17.925 3.075ZM17.025 17.025C15.35 18.7 13.05 19.725 10.5 19.725C7.95 19.725 5.65 18.7 3.975 17.025C2.325 15.35 1.275 13.05 1.275 10.5C1.275 7.95 2.3 5.65 3.975 3.975C5.65 2.3 7.95 1.275 10.5 1.275C13.05 1.275 15.35 2.3 17.025 3.975C18.7 5.65 19.725 7.95 19.725 10.5C19.725 13.05 18.675 15.35 17.025 17.025Z' fill={fill} />
            <path d='M15.6271 9.95475C15.5856 9.91327 15.5234 9.83029 15.4612 9.74732C15.4404 9.72657 15.4404 9.72657 15.4197 9.70583C15.3575 9.6436 15.2952 9.60211 15.2123 9.56062L12.1422 7.79739C12.1422 7.79739 12.1214 7.79739 12.1214 7.77665L9.03057 5.99267C8.7609 5.82672 8.44974 5.80597 8.15933 5.86821C7.86891 5.95118 7.61999 6.13788 7.45404 6.40755C7.39181 6.49052 7.37106 6.59424 7.32957 6.67722C7.30883 6.73945 7.30883 6.82243 7.28809 6.9054C7.28809 6.92614 7.28809 6.96763 7.28809 6.98838V10.5771V14.145C7.28809 14.4562 7.41255 14.7466 7.61999 14.954C7.82743 15.1615 8.11784 15.2859 8.429 15.2859C8.53272 15.2859 8.65718 15.2652 8.7609 15.2237C8.86462 15.1822 8.96834 15.1407 9.05132 15.0785L12.1007 13.3153L12.1422 13.2945L15.2123 11.5313C15.233 11.5313 15.233 11.5105 15.2537 11.5105C15.5027 11.3446 15.6894 11.0957 15.7516 10.826C15.8346 10.5356 15.7931 10.2244 15.6271 9.95475ZM14.7351 10.5978C14.7351 10.6186 14.7144 10.6393 14.6937 10.6393H14.6729L11.6236 12.4233L11.6028 12.444L8.55346 14.2073C8.53272 14.2073 8.53272 14.228 8.51198 14.228C8.51198 14.228 8.49123 14.228 8.49123 14.2487H8.47049C8.44974 14.2487 8.429 14.228 8.40826 14.228C8.38751 14.2073 8.38751 14.1865 8.38751 14.1658V10.5771V7.00912V6.98838V6.96763C8.40826 6.94689 8.429 6.92614 8.44974 6.92614C8.47049 6.92614 8.49123 6.92614 8.51198 6.92614L11.5821 8.68938L11.6028 8.71012L14.6937 10.4941L14.7144 10.5148L14.7351 10.5356C14.7351 10.5563 14.7351 10.5771 14.7351 10.5978Z' fill={fill} />
        </svg>
    );
};

// EducationIcon.propTypes = {};

export default EducationIcon;
