import React, { Component } from 'react';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import Tab from './Tab';
import './Tabs.css';

class Tabs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: ''
        };
    }

    componentDidMount() {
        // eslint-disable-next-line react/destructuring-assignment
        const openedTab = this.props.children[0].props.name ? this.props.children[0].props.name : this.props.children.find((child) => !!child.props.name).props.name;
        this.setState({ activeTab: openedTab });
    }

    componentDidUpdate(prevProps) {
        if (this.props.outerOpenTab && prevProps.outerOpenTab !== this.props.outerOpenTab) {
            this.setState({ activeTab: this.props.outerOpenTab });
        }
    }

    onClickTabItem = (tab) => {
        if(this.props.onTabClick){
          this.props.onTabClick(tab);
        }
        this.setState({ activeTab: tab });
        this.props.unsetOuterOpenTab && this.props.unsetOuterOpenTab();
    }

    render() {
        const {
            onClickTabItem,
            props: {
                children,
                hideName,
                tabContentId,
                activeColor,
                inactiveColor
            },
            state: {
                activeTab
            }
        } = this;


        return (
            <div className='tabs'>
                <div className='tab-list'>
                    {children.map((child) => {
                        const { name, label, tabChildComponent, shouldHide } = child.props;

                        if (name !== undefined && !shouldHide) {
                            return (
                                <Tab
                                    activeTab={activeTab}
                                    key={name}
                                    label={label || name}
                                    name={name}
                                    onClick={onClickTabItem}
                                    tabChildComponent={(tabChildComponent && name === activeTab) || hideName ? tabChildComponent : null}
                                    shouldHideName={hideName}
                                    activeColor={activeColor}
                                    inactiveColor={inactiveColor}
                                />
                            );
                        }

                        return null;
                    })}
                </div>
                <div className='tab-content' id={tabContentId || ''}>
                    {children.map((child) => {
                        if (child.props.name !== activeTab || child.props.name === undefined) return undefined;
                        return child.props.children;
                    })}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { MainColor: { ActiveText, InactiveText } } = state.brand.theme;

    return {
        activeColor: ActiveText,
        inactiveColor: InactiveText
    };
};

// Tabs.propTypes = {
//     children: PropTypes.instanceOf(Array).isRequired
// };

export default connect(mapStateToProps)(Tabs);
