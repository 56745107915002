import React, { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';


const TooltipWrapper = ({
    children, arrow, classes, title, ...rest
}) => {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = (e) => {
        const { target } = e;
        if ((target.scrollWidth > target.offsetWidth)) {
            setOpen(true);
        }
    };

    return (
        <Tooltip
            arrow
            classes={classes}
            title={title}
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            {...rest}
        >
            {children}
        </Tooltip>
    );
};

export default TooltipWrapper;
