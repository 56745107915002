import React, { Fragment, useState } from 'react';
// import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

import { CommandFactory } from '../../background/commands/CommandFactory';
import ExplorePage from '../ExplorePage/ExplorePage';
import Tabs from '../primitives/Tabs/Tabs';
import AutoComplite from '../primitives/AutoComplite';
import ModalWrapper from '../primitives/ModalWrapper';
// import LoadSpinner from '../primitives/LoadSpinner';
import Button from '../primitives/Button';
import JoinPage from '../JoinPage/JoinPage';
import TopRankedList from './TopRankedList';
import SpotLight from './SpotLight';
import SocialStream from '../SocialStream/SocialStream';

import './SocialPanel.css';
import { SendEventToFullStory } from '../../background/storeListener/fullStoryListener';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';
import { SendEventToLuckyOrange } from '../../background/storeListener/luckyOrangeListener';

const SocialsPanel = ({
    setSelectedTrader,
    setOpenCopyTab,
    explorePage,
    toggleExplorePage,
    setReloadApp,
    isJoined,
    hasInit,
    joinPopup,
    setJoinPopup,
    setQuestionnaire,
    generalColor,
    generalButtonColor
}) => {
    const { t } = useTranslation();
    const [trader, setTrader] = useState('');
    const [requestResult, setRequestResult] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    // const [joinPopup, setJoinPopup] = useState(false);
    // const [error, setError] = useState(false);
    const { searchProfileCommand } = useSelector((state) => { return (state.account.socialv1); }, shallowEqual);

    const requestTradersList = async (text) => {
        setIsLoading(true);
        const userInput = { searchString: text };

        const command = CommandFactory.createCommand(searchProfileCommand);

        try {
            setRequestResult(null);
            const res = await command.execute(userInput);

            if (res.ok === false) {
                setRequestResult({ result: res.statusText });
            }
            if (res.isSuccessful === true) {
                setRequestResult(res.payload);
            } else if (res.isSuccessful === false) {
                setRequestResult(res);
            }
        } catch (err) {
            setRequestResult(err);
        }

        setIsLoading(false);
    };

    const handleChange = (e) => {
        e.preventDefault();
        const { value } = e.target;
        requestTradersList(value);
        setTrader(value);
    };

    const resetSearch = () => {
        setTrader('');
        setRequestResult(null);
    };

    const handleItemSelect = (item) => {
        setTrader('');
        setRequestResult(null);
        // setSelectedTrader({ nickname: item?.Nickname });
        setSelectedTrader({
            nickname: item?.Nickname, avatar: item?.Avatar, copiers: item?.Copiers || item?.CopiersCount, joinDate: item?.JoinDate || item?.JoinDateTime, shouldOpenCopy: item?.shouldOpenCopy || false
        });
    };

    const handleExplorePage = () => {
        SendEventToGoogleAnalytics('SocialPanel:ExplorePageClick');
        toggleExplorePage();
    };

    const handleOpenCopyTab = () => {
        setOpenCopyTab(true);
    };

    const openJoin = () => {
        setJoinPopup((prevState) => !prevState);
    };

    const onTabClick = (tab) => {
        SendEventToGoogleAnalytics(`SocialPanel:${tab}TabClick`, { tabName: tab });
        SendEventToFullStory(`SocialPanel:${tab}TabClick`, { tabName_str: tab });
        SendEventToLuckyOrange(`SocialPanel:${tab}TabClick`);
    };


    return (
        <Fragment>
            <div className={!isJoined ? 'social-panel__container show-join' : 'social-panel__container'}>
                <div className='social-panel__join'>
                    {/* <h3 style={generalColor}>
                        <i className='fas fa-users' />
                        {t('Social.Title')}
                    </h3> */}
                    {hasInit && !isJoined && <Button className='btn btn-join' text={t('Socials.Button.Stream.Join')} onClickHandler={openJoin} />}
                </div>
                <div className='social-panel__search-container'>
                    <AutoComplite
                        autoCompliteResult={requestResult}
                        inputId='trader-search'
                        inputName='trader'
                        inputClassName='right-panel__trader-search'
                        inputPlaceHolder={t('MarketRates.Search')}
                        inputValue={trader}
                        handleInputChange={handleChange}
                        resetSearch={resetSearch}
                        handleItemSelect={handleItemSelect}
                        isLoading={isLoading}
                        generalButtonColor={generalButtonColor}
                        generalColor={generalColor}
                    />
                    <Button className='btn btn-explore' style={generalButtonColor} text={t('Socials.Button.Explore')} onClickHandler={handleExplorePage} iconStart='far fa-eye' />
                </div>
                <Tabs onTabClick={onTabClick} className='bottom-panel__tabs'>
                    <li name='TopRanked' label={t('Social.TopRanked')}>
                        <TopRankedList handleItemSelect={handleItemSelect} />
                    </li>
                    <li name='SpotLight' label={t('Social.SpotLight')}>
                        <SpotLight handleItemSelect={handleItemSelect} />
                    </li>
                </Tabs>
            </div>
            <SocialStream handleItemSelect={handleItemSelect} toggleOpenCopyTab={handleOpenCopyTab} generalColor={generalColor} generalButtonColor={generalButtonColor} isClickable />
            {explorePage && (
                <ModalWrapper
                    open={explorePage}
                    handleClose={toggleExplorePage}
                    childClassName='user-page'
                    // hideCloseButton
                >
                    <ExplorePage handleItemSelect={handleItemSelect} closeExplorePage={handleExplorePage} />
                </ModalWrapper>
            )}
            {joinPopup && (
                <ModalWrapper
                    heading={t('Socials.Button.Stream.Join')}
                    open={joinPopup}
                    handleClose={openJoin}
                    childClassName='join-page'
                    contentClassName='content'
                >
                    <JoinPage closeJoin={openJoin} setReloadApp={setReloadApp} setQuestionnaire={setQuestionnaire} />
                </ModalWrapper>
            )}
            {/* {isLoading && <LoadSpinner overlay />} */}
        </Fragment>
    );
};

// SocialsPanel.propTypes = {};

export default SocialsPanel;
