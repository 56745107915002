/* eslint-disable import/prefer-default-export */
/* eslint-disable indent */
import { getUserLocales } from 'get-user-locale';
import produce from 'immer';

import i18n from 'i18next';
import url from 'url-parameters';
import { setLocalStoreLang, getLocalStoreLang } from '../storeListener/translationsInit';
import { addOrUpdateState } from '../StoreUtils/addOrUpdateState';
import { replaceIfChanged,overrideState } from '../StoreUtils/overrideState';

const initState = {
 languages: {},
                    languagesDisplayOrder: [],
                    languageCode: getLocalStoreLang() ?? 'en',
                    mobile: { isEnabled: false },
                    enableChangeLanguage: false,
                    language: {
                      userLang: '',
                      browserLang: '',
                      defaultLang: '',
                      urlLang: '',
                      cookieLang: ''
                    },
                    theme: {
                      MainColor: { ActiveText: '#e74e0f', InactiveText: '#a4b0e4', ScrollColor: '#2a3469' },
                      SubHeader: { Text: '#ffffff47' },
                      Text: { GeneralColor: 'white', SystemColor: 'white', Link: 'white' },
                      Buttons: { TextColor: '#ffffff' },
                      Trade: { ButtonFirst: '#3ab6a1', ButtonSecond: '#44e680', BoxShadow: 'rgba(109,203,7,0.4)' },
                      Deposit: { ButtonFirst: '#E6B11C', ButtonSecond: '#AD330B' },
                      Social: { ChartColor: 'white', CopyBackground: '#008000', CopyTextColor: 'white' },
                      Profit: { Positive: '#66C3A7', Negative: '#DB6161', Neutral: 'white' },
                      Tick: {
                        ChartUp: '#4B8907', ChartDown: '#D0021B', TickUp: 'green', TickDown: 'red', TickNeutral: '#fff'
                      },
                      Execution: {
                        BuyBackground: '#1CB5EE', SellBackground: 'lightcoral', SuccessColor: 'white', FailedColor: 'white'
                      }
                    },
                    themeSettings: {
                      HideLogo: false,
                      AllowChangeTheme: false,
                      ActiveTheme: 'dark',
                      ToggleThemeCommand: null,
                      DarkTheme: {
                        LogoIcon: "",
                        MainColor: { ActiveText: '#e74e0f', InactiveText: '#a4b0e4', ScrollColor: '#345480' },
                        SubHeader: { Text: '#ffffff47' },
                        Text: { GeneralColor: 'white', SystemColor: 'white', Link: 'white' },
                        Buttons: { TextColor: '#ffffff' },
                        Trade: { ButtonFirst: '#3ab6a1', ButtonSecond: '#44e680' },
                        Deposit: { ButtonFirst: '#E6B11C', ButtonSecond: '#AD330B' },
                        Social: { ChartColor: 'white', CopyBackground: '#008000', CopyTextColor: 'white' },
                        Profit: { Positive: 'green', Negative: 'red', Neutral: 'white' },
                        Tick: {
                          ChartUp: '#4B8907', ChartDown: '#D0021B', TickUp: 'green', TickDown: 'red', TickNeutral: '#fff'
                        },
                        Execution: {
                          BuyBackground: '#1CB5EE', SellBackground: 'lightcoral', SuccessColor: 'white', FailedColor: 'white'
                        }
                      },
                      LightTheme: {
                        LogoIcon: "",
                        MainColor: { ActiveText: '#1CB5EE', InactiveText: '#283B5560', ScrollColor: '#345480' },
                        SubHeader: { Text: '#A4A8BA' },
                        Text: { GeneralColor: '#283B55', SystemColor: '#283B55', Link: 'gray' },
                        Buttons: { TextColor: '#283B55' },
                        Trade: { ButtonFirst: '#3ab6a1', ButtonSecond: '#44e680' },
                        Deposit: { ButtonFirst: '#E6B11C', ButtonSecond: '#AD330B' },
                        Social: { ChartColor: 'white', CopyBackground: '#008000', CopyTextColor: 'white' },
                        Profit: { Positive: '#04B66B', Negative: '#CE4B4B', Neutral: '#283B55' },
                        Tick: {
                          ChartUp: '#04B66B', ChartDown: '#CE4B4B', TickUp: '#04B66B', TickDown: '#CE4B4B', TickNeutral: '#283B55'
                        },
                        Execution: {
                          BuyBackground: '#1CB5EE', SellBackground: 'lightcoral', SuccessColor: 'white', FailedColor: 'white'
                        }
                      }
                    },
                    generalInfo: {
                      favIcon: '',
                      logoLogin: '',
                      logoMain: '',
                      logoMainUrl:'',
                      windowTitle: ''
                    },
                    guestModeSettings: {
                        loginAsGuest: null,
                        isEnabled: false
                    }
                   };

function isSupportedLang(state, browserLang) {
  return state.languages[browserLang] !== undefined;
}

function getBrowserLang() {
  const userLang = getUserLocales();

  if (userLang) {
    for (let i = 0; i < userLang.length; i++) {
      if (i18n.getResourceBundle(userLang[i].toLowerCase())) {
        return userLang[i].toLowerCase();
      }
      if (userLang[i].indexOf('-') > 0) {
        const baseLang = userLang[i].substring(0, userLang[i].indexOf('-')).toLowerCase();
        if (i18n.getResourceBundle(baseLang)) {
          return baseLang;
        }
      }
    }
  }

  return null;
}

function getLanguageCode(state){
  if (isSupportedLang(state, state.language.urlLang)) {
    return state.language.urlLang;
  } else if (state.language.userLang && isSupportedLang(state, state.language.userLang)) {
    return state.language.userLang;
  } else if (isSupportedLang(state, state.language.cookieLang)) {
    return state.language.cookieLang;
  } else if (isSupportedLang(state, state.language.browserLang)) {
    return state.language.browserLang;
  } else if (isSupportedLang(state, state.language.defaultLang)) {
    return state.language.defaultLang;
  }

  return 'en';
}

function setThemeSettings(state, themeData,globalState) {
  if(themeData?.DarkTheme){
    replaceIfChanged(state.themeSettings,'DarkTheme', themeData.DarkTheme);
  }

  if(themeData?.LightTheme){
    replaceIfChanged(state.themeSettings,'LightTheme', themeData.LightTheme);
  }

  replaceIfChanged(state.themeSettings,'ToggleThemeCommand', themeData.ToggleThemeCommand);
  if (globalState.urlParameters.theme === 'dark' || globalState.urlParameters.theme === 'light')
  {
    state.themeSettings.ActiveTheme = globalState.urlParameters.theme;
  }
  else
  {
    state.themeSettings.ActiveTheme = themeData.ActiveTheme;
  }

  state.themeSettings.AllowChangeTheme = themeData.AllowChangeTheme;  
  state.themeSettings.HideLogo = themeData.HideLogo;
  replaceIfChanged(state,'theme', state.themeSettings.ActiveTheme === 'dark' ? state.themeSettings.DarkTheme:state.themeSettings.LightTheme);
}

export const brandReducer = function brandReducer(state = initState, action, globalState) {
  switch (action.type) {
    case 'login-success':
      {
        return produce(state,draf=>{
          if(action.payload.themeData){
            setThemeSettings(draf, action.payload.themeData,globalState);
          }

          if (action.payload.featuresData.mobile)
          {
            replaceIfChanged(draf,'mobile', action.payload.featuresData.mobile);
          }

          draf.language.userLang = action.payload.generalData.userDetails.userLang;
          draf.languageCode = getLanguageCode(draf);
          setLocalStoreLang(draf.languageCode);
          return draf;
        });
      }
    case 'init-brand+login':      
    case 'init-brand':
      const o = { ...state };
      const lang = action.payload.brand.languages;
      if (action.payload.brand.generalInfo != null) {
        o.generalInfo = action.payload.brand.generalInfo;
      }

      const langobj = {};
      const langOrder = [];
      lang.forEach((l) => {
        if (i18n.getResourceBundle(l.code)) {
          l.displayName = i18n.getFixedT(l.code, '')('Branding.Language.Name');
          if (l.code) {
            langobj[l.code] = l;
            langOrder.push(l.code);
          }
        }
      });
      o.languages = langobj;
      o.enableChangeLanguage = action.payload.brand.enableChangeLanguage;
      o.languagesDisplayOrder = langOrder;
      o.guestModeSettings = action.payload.brand.guestModeSettings;

      o.language.userLang = action.payload.brand.userLang;
      o.language.browserLang = getBrowserLang();
      o.language.defaultLang = action.payload.brand.defaultLang;
      o.language.urlLang = globalState?.urlParameters?.lang;
      o.language.cookieLang = getLocalStoreLang();
      o.languageCode = getLanguageCode(o);
      setLocalStoreLang(o.languageCode);

      if (action.payload.loginTradingData?.themeData) {
        setThemeSettings(o, action.payload.loginTradingData.themeData,globalState);
      }

      if (action.payload.brand.mobile)
      {
        replaceIfChanged(o,'mobile',action.payload.brand.mobile)
      }

      return overrideState(state, o);
    case 'brand.language.change':
      const o2 = addOrUpdateState(state, action.payload);
      setLocalStoreLang(o2.languageCode);
      return o2;

    case 'brand.theme.change':
      return produce(state, draf=> {
        if (action.storeData.ActiveTheme === 'dark') {
          draf.themeSettings.ActiveTheme = 'light';
          replaceIfChanged(draf,'theme',draf.themeSettings?.LightTheme);
        } else {
          draf.themeSettings.ActiveTheme = 'dark';
          replaceIfChanged(draf,'theme',draf.themeSettings?.DarkTheme);
        }
      });
    default:
      return state;
  }
};
