import React from 'react';

import Portal from './Portal';
import './ToolTip.css';


class ToolTip extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false, x: 0, y: 0, type: 'none'
        };
        this.currentToolTip = React.createRef();
    }

    shouldComponentUpdate(nextProps, nextState) {
        // eslint-disable-next-line react/destructuring-assignment
        return this.state.visible !== nextState.visible || this.state.x !== nextState.x || this.state.y !== nextState.y;
    }

    show(hoverRect) {
        const ttNode = this.currentToolTip.current;

        if (ttNode != null) {
            let x = 0; let
                y = 0;

            const docWidth = document.documentElement.clientWidth;
            const docHeight = document.documentElement.clientHeight;

            const ttRect = { width: 260, height: 370 };

            const rx = hoverRect.x + ttRect.width; // most right x
            const lx = hoverRect.x; // most left x
            const ty = hoverRect.y; // most top y
            const by = hoverRect.y + ttRect.height; // most bottom y

            const bRight = (rx + ttRect.width) <= (window.scrollX + docWidth);
            const bLeft = (lx - ttRect.width) >= 0;
            const bBellow = (ty + ttRect.height) <= docHeight;
            // const bAbove = (ty + ttRect.height) >= docHeight;

            // console.log('r', bRight, 'l', bLeft, 'a', bAbove, 'b', bBellow);

            let newState = {};

            if (bRight && bBellow) {
                x = rx - ttRect.width;
                y = ty + (hoverRect.height);

                newState.type = 'right';
            } else if (bRight && !bBellow) {
                y = ty - ttRect.height;
                x = lx;

                newState.type = 'top';
            } else if (bBellow) {
                y = by - ttRect.height;
                x = lx - ttRect.width;

                newState.type = 'bottom';
            } else if (bLeft) {
                x = lx - ttRect.width;
                y = ty - ttRect.height;

                newState.type = 'left';
            }

            newState = {
                ...newState, x, y, visible: true
            };

            this.setState(newState);
        }
    }

    hide() {
        this.setState({ visible: false });
    }

    render() {
        const {
            visible, x, y, type
        } = this.state;
        const { children } = this.props;
        const style = {
            left: (`${x + window.scrollX}px`),
            top: (`${y + window.scrollY}px`)
        };


        return (
            <div id='tooltip' className={type} ref={this.currentToolTip}>
                {visible && (
                    <Portal>
                        <div className={`tooltip-inner ${type}`} style={style}>{children}</div>
                    </Portal>
                )}
            </div>
        );
    }
}

export default ToolTip;
