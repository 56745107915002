/* eslint-disable no-nested-ternary */
/* eslint-disable no-lonely-if */
/* eslint-disable max-len */
/* eslint-disable complexity */
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';

import Switch from '../primitives/Switch';
import Button from '../primitives/Button';
import InputStepper from '../primitives/InputStepper';
import { ProfitCalculationService } from '../../background/calculations/profitCalculationService';
import TickItemToolTipContent from '../TickItem/TickItemToolTipContent';
import roundToPrecision from '../utilsUI/roundToPrecision';
import countDecimals from '../utilsUI/countDecimals';
import FormattedSLTPProfit from './FormattedSLTPProfit';

import PendingDropDown from './PendingDropDown';
import { ACTION_TYPE } from './TradePopup';


const TradePopupStepTwo = ({
    pendingOrder,
    handleSwitchChange,
    // cancelOrder,
    price,
    status,
    onClose,
    isPending,
    handleSetValue,
    pendingOrderPrice,
    isBidType,
    selectedSymbol,
    selectedUnits,
    symbol,
    response,
    tickObj,
    activeTextColor,
    inactiveTextColor,
    generalColor,
    systemColor,
    enableSLTP,
    lotStep,
    // SL,
    // TP,
    // setSL,
    // setTP,
    buySL,
    sellSL,
    setBuySL,
    setSellSL,
    buyTP,
    sellTP,
    setBuyTP,
    setSellTP,
    pendingSL,
    pendingTP,
    setPendingSL,
    setPendingTP
}) => {
    const [showError, setShowError] = useState('');
    const [showInfo, setShowInfo] = useState(false);
    const [pendingDropDown, setPendingDropDown] = useState(false);
    const { t } = useTranslation();
    let { pipDigit, displayDigit } = selectedSymbol;
    displayDigit = Math.max(displayDigit, pipDigit);
    const isBid = !!isBidType;
    const type = isBidType ? 1 : 0;
    let openRate = isBid ? tickObj?.bid : tickObj?.ask;
    if (isPending) {
        openRate = pendingOrderPrice;
    }
    const closeConvertion = isBid ? tickObj?.bidProfitConversion : tickObj?.askProfitConversion;

    let pipNumber = 1;
    for (let i = 0; i < pipDigit; i += 1) {
        pipNumber /= 10;
    }

    // useEffect(() => {
    //     setPendingPrice(pendingOrderPrice);
    // }, [pendingOrderPrice]);

    // const executionDescription = isPending ? '(Pending Order)' : '(Market Execution)';
    const handleShowError = (error) => {
        setShowError(error);
    };

    const toggleInfo = () => {
        setShowInfo((prevState) => !prevState);
    };

    const togglePendingDropDown = () => {
        setPendingDropDown((prevState) => !prevState);
    };

    const closePendingDropDown = () => {
        setPendingDropDown(false);
    };

    const enterSL = () => {
        if ((!pendingSL?.number) && isPending) {
            let selectedSL = null;
            if (isBid) {
                selectedSL = pendingOrderPrice + 4 * pipNumber;
            } else {
                selectedSL = pendingOrderPrice - 4 * pipNumber;
            }
            setPendingSL({
                number: roundToPrecision(selectedSL, displayDigit),
                representation: roundToPrecision(selectedSL, displayDigit).toString()
            });
        }
        if ((!buySL?.number) && !isBid && !isPending) {
            setBuySL({
                number: roundToPrecision((tickObj.bid - 4 * pipNumber), displayDigit),
                representation: roundToPrecision((tickObj.bid - 4 * pipNumber), displayDigit).toString()
            });
        }
        if ((!sellSL?.number) && isBid && !isPending) {
            setSellSL({
                number: roundToPrecision((tickObj.ask + 4 * pipNumber), displayDigit),
                representation: roundToPrecision((tickObj.ask + 4 * pipNumber), displayDigit).toString()
            });
        }
    };

    const enterTP = () => {
        if ((!pendingTP?.number)  && isPending) {
            let selectedTP = null;
            if (isBid) {
                selectedTP = pendingOrderPrice - 4 * pipNumber;
            } else {
                selectedTP = pendingOrderPrice + 4 * pipNumber;
            }
            setPendingTP({
                number: roundToPrecision(selectedTP, displayDigit),
                representation: roundToPrecision(selectedTP, displayDigit).toString()
            });
        }
        if ((!buyTP?.number)  && !isBid && !isPending) {
            setBuyTP({
                number: roundToPrecision((tickObj.bid + 4 * pipNumber), displayDigit),
                representation: roundToPrecision((tickObj.bid + 4 * pipNumber), displayDigit).toString()
            });
        }
        if ((!sellTP?.number) && isBid && !isPending) {
            setSellTP({
                number: roundToPrecision((tickObj.ask - 4 * pipNumber), displayDigit),
                representation: roundToPrecision((tickObj.ask - 4 * pipNumber), displayDigit).toString()
            });
        }
    };

    const handleSLChange = (SLPrice) => {
        let verifiedPrice = SLPrice === '' ? null : SLPrice;
        const decimals = countDecimals(SLPrice.replace(/[^0-9\.]/g, ''));
        if (decimals > displayDigit) {
            verifiedPrice = roundToPrecision(verifiedPrice, displayDigit, true).toString();
        }
        const SLPriceObject = { number: (SLPrice === '0' || SLPrice === '') ? null : parseFloat(verifiedPrice.replace(',', ''), decimals), representation: verifiedPrice };
        if (isPending) {
            setPendingSL(SLPriceObject);
        } else {
            if (isBid) {
                setSellSL(SLPriceObject);
            } else {
                setBuySL(SLPriceObject);
            }
        }
    };

    const handleTPChange = (TPPrice) => {
        let verifiedPrice = TPPrice === '' ? null : TPPrice;
        const decimals = countDecimals(TPPrice.replace(/[^0-9\.]/g, ''));
        if (decimals > displayDigit) {
            verifiedPrice = roundToPrecision(verifiedPrice, displayDigit, true).toString();
        }
        const TPPriceObject = { number: (TPPrice === '0' || TPPrice === '') ? null : parseFloat(verifiedPrice.replace(',', ''), decimals), representation: verifiedPrice };
        if (isPending) {
            setPendingTP(TPPriceObject);
        } else {
            if (isBid) {
                setSellTP(TPPriceObject);
            } else {
                setBuyTP(TPPriceObject);
            }
        }
    };


    return (
        <Fragment>
            <div className='order-step2'>
                <Switch
                    isSelected={pendingOrder}
                    handleSwitch={handleSwitchChange}
                    name='pendingOrder'
                    // offText='Order'
                    onText={t('Trade.PendingOrder.Title')}
                    generalColor={generalColor}
                    activeColor={activeTextColor.color}
                />
                {/* <span className='input-description'>{executionDescription}</span> */}
                {isPending && (
                    <Fragment>
                        <InputStepper
                            selectedUnits={pendingOrderPrice}
                            stepValue={pipNumber}
                            setValue={handleSetValue}
                            pipDigit={pipDigit}
                            showError={handleShowError}
                            errorMessage='Input.Error.PendingPrice.Incorrect'
                            isError={showError}
                            onFocusAction={togglePendingDropDown}
                            id='pending-price-stepper'
                            shouldSelect={pendingDropDown}
                            onKeyUp={closePendingDropDown}
                        />
                        {showError && <span className='input-description error'>{t(showError)}</span>}
                        {/* <span className='input-description'>{pendingOrderText}</span> */}
                        {pendingDropDown && (
                            <div className='trade-popup__units-list'>
                                <PendingDropDown
                                    selectPendingPrice={handleSetValue}
                                    togglePendingDropDown={togglePendingDropDown}
                                    handleClick={togglePendingDropDown}
                                    pendingOrderPrice={pendingOrderPrice}
                                    // contractSize={contractSize}
                                    // stepValue={lotStep}
                                    tickObj={tickObj}
                                    isBidActive={isBid}
                                    pipDigit={pipDigit}
                                    displayDigit={displayDigit}
                                    // unitsName={unitsName}
                                />
                            </div>
                        )}
                    </Fragment>
                )}
                {enableSLTP && (
                    <div className='SLTP-container'>
                        <div className='SLTP__item'>
                            <p>{t('InstrumetInfo.Details.StopLoss')}</p>
                            <InputStepper
                                selectedUnits={isPending ? pendingSL?.representation : isBid ? sellSL?.representation : buySL?.representation}
                                stepValue={pipNumber}
                                setValue={handleSLChange}
                                pipDigit={pipDigit}
                                showError={handleShowError}
                                onFocusAction={enterSL}
                                id='SL-stepper'
                            />
                            {!isPending && !!buySL?.number && !isBid && (
                                <FormattedSLTPProfit
                                    profit={ProfitCalculationService.calculateProfit(selectedSymbol, type, selectedUnits, openRate, buySL?.number, closeConvertion)}
                                    symbol={symbol}
                                />
                            )}
                            {!isPending && !!sellSL?.number && isBid && (
                                <FormattedSLTPProfit
                                    profit={ProfitCalculationService.calculateProfit(selectedSymbol, type, selectedUnits, openRate, sellSL?.number, closeConvertion)}
                                    symbol={symbol}
                                />
                            )}
                            {isPending && !!pendingSL?.number && (
                                <FormattedSLTPProfit
                                    profit={ProfitCalculationService.calculateProfit(selectedSymbol, type, selectedUnits, openRate, pendingSL?.number, closeConvertion)}
                                    symbol={symbol}
                                />
                            )}
                        </div>
                        <div className='SLTP__item'>
                            <p>{t('InstrumetInfo.Details.TakeProfit')}</p>
                            <InputStepper
                                selectedUnits={isPending ? pendingTP?.representation : isBid ? sellTP?.representation : buyTP?.representation}
                                stepValue={pipNumber}
                                setValue={handleTPChange}
                                pipDigit={pipDigit}
                                showError={handleShowError}
                                onFocusAction={enterTP}
                                id='TP-stepper'
                            />
                            {!isPending && !!buyTP?.number && !isBid && (
                                <FormattedSLTPProfit
                                    profit={ProfitCalculationService.calculateProfit(selectedSymbol, type, selectedUnits, openRate, buyTP?.number, closeConvertion)}
                                    symbol={symbol}
                                />
                            )}
                            {!isPending && !!sellTP?.number && isBid && (
                                <FormattedSLTPProfit
                                    profit={ProfitCalculationService.calculateProfit(selectedSymbol, type, selectedUnits, openRate, sellTP?.number, closeConvertion)}
                                    symbol={symbol}
                                />
                            )}
                            {isPending && !!pendingTP?.number && (
                                <FormattedSLTPProfit
                                    profit={ProfitCalculationService.calculateProfit(selectedSymbol, type, selectedUnits, openRate, pendingTP?.number, closeConvertion)}
                                    symbol={symbol}
                                />
                            )}
                        </div>
                    </div>
                )}
                <Button
                    className={showInfo ? 'btn-open-close btn-open-close--active' : 'btn-open-close'}
                    style={showInfo ? activeTextColor : generalColor}
                    text={t('Trade.Info.Title')}
                    onClickHandler={toggleInfo}
                    iconStart={showInfo ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
                />
                {showInfo && <TickItemToolTipContent tickData={selectedSymbol} generalColor={generalColor} systemColor={systemColor} hideHeadings />}
            </div>
            {(response || status) && (
                <div className='status-container'>
                    {response && (
                        <Fragment>
                            <p className='error__title'>{t('Trade.Execute.Failed')}</p>
                            <span className='error__message'>{t(response.result ?? 'trading.error.GeneralError')}</span>
                        </Fragment>
                    )}
                </div>
            )}
        </Fragment>
    );
};

// TradePopupStepTwo.propTypes = {};

export default TradePopupStepTwo;
