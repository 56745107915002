import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

const MobileNothingFound = ({isOpen}) => {
	const {t} = useTranslation();

	return (
			<>
				{isOpen && (
						<div className="mobile-empty">
							<p className="mobile-empty__text">
								{t('MarketRates.NothingFound')}
							</p>
						</div>
				)}
			</>
	);
};

MobileNothingFound.defaultProps = {
	isOpen: true,
};

MobileNothingFound.propTypes = {
	isOpen: PropTypes.bool,
};

export default MobileNothingFound;
