import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {UNITS_MULTIPLIER} from './MobileUnitsOptions';
import {ACTION_TYPE} from '../../../UI/TradePopup/TradePopup';

import calcMarginRequirement from '../../../UI/utilsUI/calcMarginRequirement';
import scrollToElement from '../../../UI/utilsUI/scrollToElement';
import {ProfitCalculationModes} from '../../../background/reducers/symbolsReducer';
import {roundNumberPipDigit} from '../../../UI/utilsUI/formatWSTick';
import OuterClick from '../../../UI/primitives/OuterClick';

const prepareUnitsList = (
		currentUnits, stepValue, tickObj, type, selectedSymbol, pnlTick) => {
	const resultSet = new Set();
	const resultArray = [];
	let counter = 0;
	UNITS_MULTIPLIER.forEach((multiply) => {
		// eslint-disable-next-line prefer-template
		const unitValue = +(Math.round(
				(Math.round((currentUnits * multiply) / stepValue) * stepValue) +
				'e+2') + 'e-2');
		if (unitValue) {
			resultSet.add(unitValue);
		}
	});

	[...resultSet].forEach((item) => {
		if (Math.round(
				calcMarginRequirement(tickObj, type, selectedSymbol, item)) <=
				pnlTick?.equity) {
			resultArray.push(item);
		} else if (counter < 3 &&
				Math.round(calcMarginRequirement(tickObj, type, selectedSymbol, item)) >
				pnlTick?.equity) {
			counter += 1;
			resultArray.push(item);
		}
	});

	return resultArray;
};

const MobileUnitsClosePosition = props => {

	const {
		selectUnits,
		toggleUnitsDropDown,
		selectedUnits,
		contractSize,
		stepValue,
		tickObj,
		isBidActive,
		unitsName,
		selectedSymbol,
		pnlTick,
		currency,
		handleClick,
	} = props;

	const {t} = useTranslation();

	const [initialUnitsList, setInitialUnitsList] = useState(null);

	const selectedUnitsNumber = useMemo(() => {
		return typeof selectedUnits === 'string'
				? selectedUnits.replace(/[^0-9\.]/g, '')
				: selectedUnits;
	}, [selectedUnits]);

	const type = useMemo(() => {
		return isBidActive ? ACTION_TYPE.BID : ACTION_TYPE.ASK;
	}, [isBidActive]);

	const handleUnitsSelect = useCallback(e => {
		e && e.stopPropagation();
		if (e.target.closest('.units-row')) {
			selectUnits(e.target.closest('.units-row').firstChild.innerHTML);
		}
		handleClick && handleClick();
	}, [handleClick]);

	const convertionRate = useMemo(() => {
		return isBidActive
				? (tickObj?.askProfitConversion || 1)
				: (tickObj?.bidProfitConversion || 1);
	}, [isBidActive, tickObj]);

	const currentPrice = useMemo(() => {
		return isBidActive
				? (tickObj?.bid || 1)
				: (tickObj?.ask || 1);
	}, [isBidActive, tickObj]);

	useEffect(() => {
		setInitialUnitsList(
				prepareUnitsList(selectedUnitsNumber, stepValue, tickObj, type,
						selectedSymbol, pnlTick));
	}, []);

	useEffect(() => {
		if (initialUnitsList) {
			const container = document.getElementById('trade-popup__units-list');
			const position = document.getElementById('units-scroll-pointer');
			if (container && position) {
				scrollToElement(container, position);
			}
		}
	}, [initialUnitsList]);

	return (
			<div onClick={handleUnitsSelect}>
				<div className='units-header'>
					<div className='units-header__fixed'>
						<div>{unitsName}</div>
						<div>{t('Units.Value')}</div>
						<div>{t('Trade.Margin.Title')}</div>
					</div>
				</div>
				<div className='units-list' id='trade-popup__units-list'>
					{initialUnitsList?.length > 0 && initialUnitsList.map((unit) => {
						return (
								<div
										id={unit.toString() === selectedUnitsNumber.toString()
												? 'units-scroll-pointer'
												: ''}
										className='units-row' key={unit}
										data-disabled={Math.round(
												calcMarginRequirement(tickObj, type, selectedSymbol,
														unit)) > pnlTick?.equity}>
									<div>{unit}</div>
									<div>
										{currency}
										{selectedSymbol.profitCalcMode ===
										ProfitCalculationModes.Sirix
												? roundNumberPipDigit(
														unit * currentPrice * contractSize * convertionRate,
														2)
												: roundNumberPipDigit(
														unit * currentPrice * contractSize / convertionRate,
														2)}
									</div>
									<div>
										{currency}
										{roundNumberPipDigit(
												calcMarginRequirement(tickObj, type, selectedSymbol,
														unit), 2)}
									</div>
								</div>
						);
					})}
				</div>
			</div>
	);
};

export default OuterClick(MobileUnitsClosePosition, '#units-stepper');
