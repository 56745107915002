import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';

import Button from '../primitives/Button';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';

export default ({ handleClose, masterNickname }) => {
    const { t } = useTranslation();

    SendEventToGoogleAnalytics('Profile:Copy:LFSDisclaimer:Block', { masterNickname });

    return (
        <div>
            <div>
                <p>
                    <b>Warning:&nbsp;</b>
                    The Strategy you have selected is NOT in line with your Investment and Risk Profile and LFS does not recommend this Strategy/Trader for you.
                    We recommend you consider a strategy in your Investment and Risk Profile or below.
                </p>
                <p>
                    We would like you to note that past performance of all Trading strategies is not a guarantee, nor indicative of future returns.
                    There is a high level of risk involved with trading forex, cryptocurrencies, commodities, indices and other CFDs.
                    Funds placed under management should be risk capital funds that if lost will not significantly affect one’s personal financial well-being.
                </p>
            </div>
            <div className='question-page__cancel-buttons'>
                <Button className='btn btn--general btn--cancel' text={t('General.Cancel')} onClickHandler={handleClose} />
            </div>
        </div>
    );
};
