/* eslint-disable complexity */
import React, { useMemo, useRef } from 'react';
import clsx from 'clsx';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import { roundNumberPipDigit } from '../../../UI/utilsUI/formatWSTick';
import countDecimals from '../../../UI/utilsUI/countDecimals';

import '../../../UI/primitives/InputStepper.css';


const MobileInputStepper = (props) => {
    const {
        min,
        max,
        selectedUnits,
        setValue,
        stepValue,
        shouldFocus,
        pipDigit = 1,
        showError,
        errorMessage,
        checkDivider,
        roundMax,
        isError,
        onFocusAction,
        id = null,
        onKeyUp,
        inputDescription,
        pattern = '',
        inputmode = ''
    } = props;

    const currentValue = useMemo(() => {
        if (!selectedUnits) {
            return '';
        }
        return selectedUnits;
    }, [selectedUnits]);

    const inputEl = useRef(null);

    const incrementValue = () => {
        const newValue = typeof selectedUnits === 'string'
            ? Number(selectedUnits.replace(/[^0-9\.]/g, '')) + stepValue
            : selectedUnits + stepValue;
        if (newValue > max) return;

        setValue(newValue.toFixed(pipDigit));
    };

    const decrementValue = () => {
        const newValue = typeof selectedUnits === 'string'
            ? Number(selectedUnits.replace(/[^0-9\.]/g, '')) - stepValue
            : selectedUnits - stepValue;
        if (newValue < min || newValue <= 0) return;

        setValue(newValue.toFixed(pipDigit));
    };

    const handleInputChange = (e) => {
        const inputValue = e.target.value.replace(/[^0-9\.]/g, '');
        setValue(inputValue);
        if (showError) {
            showError('');
        }
    };

    const onLeaveInput = () => {
        let selectedUnitsNumber = selectedUnits;
        if (typeof selectedUnits === 'string') {
            selectedUnitsNumber = selectedUnits.replace(/[^0-9\.]/g, '');
            selectedUnitsNumber = roundNumberPipDigit(selectedUnitsNumber,
                countDecimals(selectedUnitsNumber));
            setValue(selectedUnitsNumber);
        }
        if (showError) {
            if (checkDivider) {
                if (typeof selectedUnits === 'string') {
                    selectedUnitsNumber = selectedUnits?.replace(/[^0-9\.]/g, '');
                }
                if (selectedUnitsNumber < min
						|| (selectedUnitsNumber * (10 ** pipDigit))
						% (stepValue * (10 ** pipDigit)) !== 0 || selectedUnitsNumber > max) {
                    showError(errorMessage);
                } else {
                    showError('');
                }
            } else if (selectedUnitsNumber === '0' || selectedUnitsNumber === '') {
                showError(errorMessage);
            } else {
                showError('');
            }
        }
        if (roundMax) {
            if (selectedUnitsNumber > max) {
                setValue(max.toFixed(pipDigit));
            }
        }
        document.getSelection().removeAllRanges();
    };

    const handleOnFocus = () => {
        if (inputEl.current) {
            inputEl.current.select();
            onFocusAction && onFocusAction();
        }
    };

    const buttonStyle = useMemo(() => {
        return { width: '20%' };
    }, []);

    return (
        <div className={clsx('input-stepper', isError && 'input-stepper--error')}>
            <button
                type='button'
                onClick={decrementValue}
                style={{ ...buttonStyle }}
            >
                <RemoveIcon />
            </button>
            <div>
                <input
                    type='text'
                    id={id || null}
                    onChange={handleInputChange}
                    onBlur={onLeaveInput}
                    onFocus={handleOnFocus}
                    value={currentValue}
                    autoFocus={shouldFocus}
                    ref={inputEl}
                    onKeyUp={onKeyUp || null}
                    autoComplete='off'
                    pattern={pattern}
                    inputMode={inputmode}
                />
                {inputDescription && <div className='description'>{inputDescription}</div>}
            </div>
            <button
                type='button'
                onClick={incrementValue}
                style={{ ...buttonStyle }}
            >
                <AddIcon />
            </button>
        </div>
    );
};

export default MobileInputStepper;
