import CommandFactory from "./CommandFactory"
import{STDCMDAsyncServerHandler,STDCMDAsyncServerCmdID } from "./asyncServerCommand"
import {STDCMDServerHandler,STDCMDServerCmdID} from "./serverCommand"
import {CMDChartsHandler,CMDChartCmdID} from "../chart/chartCommand"
import {STDCMDStoreHandler,STDCMDStoreCmdID} from "./storeCommand"
import {STDCMDServerCmdAuthID,STDCMDServerAuthHandler}  from './authServerCommand'

export default function RegisterAll()
{
  CommandFactory.registerHandler(STDCMDAsyncServerCmdID,STDCMDAsyncServerHandler);
  CommandFactory.registerHandler(STDCMDServerCmdID,STDCMDServerHandler);
  CommandFactory.registerHandler(CMDChartCmdID,CMDChartsHandler);
  CommandFactory.registerHandler(STDCMDStoreCmdID,STDCMDStoreHandler);
  CommandFactory.registerHandler(STDCMDServerCmdAuthID,STDCMDServerAuthHandler);
}