import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {getCurrentAvatar} from '../helpers/options.helpers';

const MobileAvatarIcon = ({avatar, isActive}) => {

	const currentAvatar = useMemo(() => {
		return getCurrentAvatar(avatar)?.icon?.type;
	}, [avatar]);

	const handleLoad = useCallback(e => {
		const img = e.currentTarget;
		if (img.complete) img.classList.add('visible');
	}, []);

	return (
			<div className="mobile-avatar-icon">
				<div className={clsx('mobile-avatar-icon__wrap', isActive && 'active')}>
					<div className="mobile-avatar-icon__avatar">
						<img
								onLoad={handleLoad}
								src={currentAvatar}
								alt="avatar"
								className="mobile-avatar-icon__avatar__image"
						/>
					</div>
				</div>
			</div>
	);
};

MobileAvatarIcon.defaultProps = {
	avatar: 'defaultavatar1',
	isActive: false,
};

MobileAvatarIcon.propTypes = {
	avatar: PropTypes.string,
	isActive: PropTypes.bool,
};

export default MobileAvatarIcon;
