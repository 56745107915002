import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const MobileUpdatePositionBlock = props => {
	const {label, value, time, left, center, right, double, disabled} = props;

	if (disabled) {
		return null;
	}

	return (
			<div
					className={clsx('mobile-update-position-popup__main__section__block',
							left && 'left',
							center && 'center',
							right && 'right',
							double && 'double',
					)}
			>
				<div
						className="mobile-update-position-popup__main__section__block__title">
					{label}
				</div>
				<div
						className={clsx(
								'mobile-update-position-popup__main__section__block__desc',
								time && 'wrap',
						)}
				>
					{value}
				</div>
			</div>
	);
};

MobileUpdatePositionBlock.defaultProps = {
	label: '',
	value: '',
	time: '',
	left: false,
	center: false,
	right: false,
	double: false,
	disabled: false,
};

MobileUpdatePositionBlock.propTypes = {
	label: PropTypes.string,
	left: PropTypes.bool,
	center: PropTypes.bool,
	right: PropTypes.bool,
	double: PropTypes.bool,
	disabled: PropTypes.bool,
	time: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	value: PropTypes.oneOfType([
		PropTypes.string, PropTypes.number, PropTypes.node,
	]),
};

export default MobileUpdatePositionBlock;
