import React, {useMemo} from 'react';
import clsx from 'clsx';

import {getCarouselListStyle} from '../../helpers/carousel.helpers';

const CarouselTabList = ({children, options = [], index, style, classname}) => {

  const renderItems = useMemo(() => {
    if (children && children.length) return children;
    return options;
  }, [children, options]);

  return (
      <ul
          className={clsx('mobile-carousel-list', classname)}
          style={{...getCarouselListStyle(index), ...style}}
      >
        {renderItems.map((item, idx) => (
            <li
                key={idx}
                className={clsx('mobile-carousel-list__item',
                    index === idx && 'active')}
            >
              {index === idx && (
                  <>
                    {item}
                  </>
              )}
            </li>
        ))}
      </ul>
  );
};

export default CarouselTabList;
