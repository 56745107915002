import React from 'react';
// import PropTypes from 'prop-types';

const StarIcon = ({ fill }) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='18' height='17' viewBox='0 0 18 17' fill='none'>
            <path d='M8.04865 0.927049C8.348 0.00573802 9.65141 0.00574017 9.95076 0.927051L11.2448 4.90983C11.3787 5.32185 11.7627 5.60081 12.1959 5.60081H16.3836C17.3524 5.60081 17.7551 6.84043 16.9714 7.40983L13.5835 9.87132C13.233 10.126 13.0863 10.5773 13.2202 10.9894L14.5143 14.9721C14.8136 15.8934 13.7592 16.6596 12.9754 16.0902L9.58749 13.6287C9.237 13.374 8.76241 13.374 8.41192 13.6287L5.02397 16.0902C4.24025 16.6596 3.18577 15.8934 3.48513 14.9721L4.77921 10.9894C4.91308 10.5773 4.76643 10.126 4.41594 9.87132L1.02798 7.40983C0.244268 6.84043 0.647046 5.60081 1.61577 5.60081H5.80351C6.23674 5.60081 6.62069 5.32185 6.75457 4.90983L8.04865 0.927049Z' fill={fill} />
        </svg>
    );
};

// StarIcon.propTypes = {};

export default StarIcon;
