import produce from "immer"
import { replaceIfChanged } from '../StoreUtils/overrideState';
const  initState = {
  positions:{},
  deleteAllCommand: null
};

export const PendingType = {
  BuyLimit  :0,
  SellLimit :1,
  BuyStop   :2,
  SellStop  :3
}

export const pendingPositionsReducer = function pendingPositionsReducer(state = initState, action){
  switch (action.type) {
    case "login-logout":
      return initState;
    case "init-brand+login":
    {
      let serverPositions = action.payload.loginTradingData.tradingData.pendingPositions;
      return produce(state,draf=>{
        HandlePending(draf, serverPositions);        
      });
    }
    case "update-trading-data":
    case "login-success":
    {
      let serverPositions = action.payload.tradingData.pendingPositions;
      return produce(state,draf=>{
        HandlePending(draf, serverPositions);        
      });
    }
      default:
        return state; 
  }
}

function HandlePending(draf, payload) {
  let serverPositions = payload.positions;
  replaceIfChanged(draf,'deleteAllCommand', serverPositions.deleteAllCommand);

  let positionsToRemove = new Set();
  let statePositions = draf.positions;
  for (let statePosition of Object.values(statePositions)) {
    positionsToRemove.add(statePosition.id);
  }

  for (let serverPositionKey in serverPositions) {
    let serverPosition = serverPositions[serverPositionKey];
    positionsToRemove.delete(serverPosition.id);
    let statePosition = statePositions[serverPosition.id];
    if (!statePosition) {
      statePosition = { id: serverPosition.id };
      statePositions[serverPosition.id] = statePosition;
    }
    statePosition.tpId = serverPosition.tpId;
    statePosition.actionType = serverPosition.actionType;
    statePosition.type = serverPosition.type;
    statePosition.expirationS = serverPosition.expirationS;
    statePosition.expirationUTC = serverPosition.expirationUTC;
    statePosition.amount = serverPosition.amount;
    statePosition.stopLoss = serverPosition.stopLoss;
    statePosition.takeProfit = serverPosition.takeProfit;
    statePosition.symbolId = serverPosition.symbolId;
    statePosition.symbolName = serverPosition.symbolName;
    statePosition.openRate = serverPosition.openRate;
    statePosition.openTimeS = serverPosition.openTimeS;
    statePosition.openTimeUTC = serverPosition.openTimeUTC;

    replaceIfChanged(statePosition,'editPendingCMD', serverPosition.editPendingCMD);
    replaceIfChanged(statePosition,'deletePedingCMD', serverPosition.deletePedingCMD);
  }

  for (let positionToRemove of positionsToRemove) {
    delete statePositions[positionToRemove];
  }
}
