/* eslint-disable no-unused-expressions */
/* eslint-disable no-mixed-operators */
/* eslint-disable complexity */
/* eslint-disable max-len */

import React, { Fragment, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';
import PubsubSubscription from '../../background/repositories/PubsubSubscription';
import { CommandFactory } from '../../background/commands/CommandFactory';
import {refreshStateWorkaround} from '../../background/refreshStateWorkaround';

import OuterClick from '../primitives/OuterClick';
import InputStepper from '../primitives/InputStepper';
import { roundNumberPipDigit } from '../utilsUI/formatWSTick';
import roundToPrecision from '../utilsUI/roundToPrecision';
import countDecimals from '../utilsUI/countDecimals';
import { ProfitCalculationModes } from '../../background/reducers/symbolsReducer';
import calcMarginRequirement from '../utilsUI/calcMarginRequirement';
import Button from '../primitives/Button';
import LoadSpinner from '../primitives/LoadSpinner';
import LoadingIcon from '../icons/LoadingIcon';

import TradePopupStepTwo from './TradePopupStepTwo';
import TradePopupWSTick from './TradePopupWSTick';
import UnitsDropDown from './UnitsDropDown';
import * as TradePopupAnalyticsEvents from './TradePopupAnalyticsEvents';
import './TradePopup.css';
// import { MonetizationOn } from '@material-ui/icons';

export const ACTION_TYPE = {
    BID: 'bid',
    ASK: 'ask'
};

const calcPipValue = (prices, selectedSymbol, selectedUnits, orderType) => {
    const { pipDigit, contractSize, profitCalcMode } = selectedSymbol;
    // let pip = Math.pow(10, -pipDigit);
    const pip = Math.pow(10, -pipDigit);
    if (!prices) {
        return 0;
    }
    let conversionRate = (prices.askProfitConversion + prices.bidProfitConversion) / 2;

    if (orderType) {
        conversionRate = orderType === ACTION_TYPE.BID
            ? prices.bidProfitConversion
            : prices.askProfitConversion;
    }

    if (profitCalcMode === ProfitCalculationModes.Sirix) {
        return pip * selectedUnits * conversionRate * contractSize;
    }
    return pip * selectedUnits / conversionRate * contractSize;
};


const TradePopup = ({ onClose, selectedSymbol, mainChartController }) => {
    const enableSLTP = useSelector((state) => { return (state.account.accountInfo.featuresData.execution.enableTpSl); });
    const { currency: { symbol } } = useSelector((state) => { return (state.account.accountInfo); }, shallowEqual);
    const [isLoading, setIsLoading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [unitsDropDown, setUnitsDropDown] = useState(false);
    const [showError, setShowError] = useState('');
    const [pendingOrder, setPendingOrder] = useState(false);
    const [pendingOrderPrice, setPendingOrderPrice] = useState(null);
    const [response, setResponse] = useState('');
    const [orderInfo, setOrderInfo] = useState({ status: '', price: null });
    const [type, setType] = useState(ACTION_TYPE.ASK);
    const [isPopupOpen] = useState({ isOpen: true });
    const [buySL, setBuySL] = useState({ number: null , representation: '' });
    const [buyTP, setBuyTP] = useState({ number: null , representation: '' });
    const [sellSL, setSellSL] = useState({ number: null , representation: '' });
    const [sellTP, setSellTP] = useState({ number: null , representation: '' });
    const [pendingSL, setPendingSL] = useState({ number: null , representation: '' });
    const [pendingTP, setPendingTP] = useState({ number: null , representation: '' });
    const { t } = useTranslation();
    const {
        Execution: {
            BuyBackground, SellBackground, SuccessColor, FailedColor
        },
        SubHeader: { Text },
        MainColor: { ActiveText, InactiveText },
        Text: { GeneralColor, SystemColor },
        Tick: { TickUp, TickDown, TickNeutral }
    } = useSelector((state) => (state.brand.theme));
    const { ActiveTheme } = useSelector((state) => { return (state.brand.themeSettings); }, shallowEqual);
    const tradePopupButton = { backgroundColor: ActiveTheme === 'dark' ? '#283b55' : '#D3D5E0' };
    const {
        displayName,
        description,
        contractSize,
        tick,
        minimumLot,
        maximumLot,
        lotStep
    } = selectedSymbol;
    const [selectedUnits, setSelectedUnits] = useState({ number: Math.max(minimumLot, lotStep), representation: roundNumberPipDigit(Math.max(minimumLot, lotStep)) });
    const tickObj = PubsubSubscription.subscribeObj(tick);
    const pnlTick = PubsubSubscription.subscribeObj({ key: 'MOVINGPNL', repId: 'ACCOUNT_PNL' });

    const isBidType = type === ACTION_TYPE.BID;

    const marginRequirement = calcMarginRequirement(tickObj, type, selectedSymbol, selectedUnits.number);
    const pipValue = calcPipValue(tickObj, selectedSymbol, selectedUnits.number, type);

    const handleSetValue = (units) => {
        setShowError('');
        const decimals = countDecimals(units.replace(/[^0-9\.]/g, ''));
        const unitsValue = decimals === 0 ? units.replace(/[^0-9\.]/g, '') : roundNumberPipDigit(units, decimals);
        setSelectedUnits({ number: roundToPrecision(unitsValue, decimals), representation: units });
    };

    const handleSelectValue = (units) => {
        setShowError('');
        const decimals = countDecimals(units);
        setSelectedUnits({ number: roundToPrecision(units, decimals), representation: roundNumberPipDigit(units, decimals) });
    };

    const handleSetPendingPrice = (units) => {
        response && setResponse('');
        if (typeof units === 'number') {
            setPendingOrderPrice(units);
        }
        if (typeof units === 'string') {
            const decimals = countDecimals(units.replace(/[^0-9\.]/g, ''));
            const unitsValue = decimals === 0 ? units.replace(/[^0-9\.]/g, '') : roundToPrecision(units, decimals);
            setPendingOrderPrice(unitsValue);
        }
    };

    const handleBid = () => {
        response && setResponse('');
        setType(ACTION_TYPE.BID);
    };

    const handleAsk = () => {
        response && setResponse('');
        setType(ACTION_TYPE.ASK);
    };

    const cancelOrder = () => {
        setResponse('');
        setType('');
    };

    const handleOpenPosition = async (e) => {
        e.preventDefault();

        const command = CommandFactory.createCommand(selectedSymbol.openPositionCMD);
        setOrderInfo((prevState) => {
            return { ...prevState, price: isBidType ? PubsubSubscription.getValueNative(tick).bid : PubsubSubscription.getValueNative(tick).ask };
        });
        setIsProcessing(true);


        const sendOpenPositionEventFunc = TradePopupAnalyticsEvents.createOpenPositionEvent(tickObj, roundNumberPipDigit(pipValue, 2), roundNumberPipDigit(marginRequirement, 2), selectedSymbol.displayName, selectedUnits.number, type, mainChartController);
        try {
            setResponse(null);
            let refreshWorkaround = refreshStateWorkaround();
            const res = await command.execute({
                actionType: isBidType ? 1 : 0,
                amount: selectedUnits.number,
                stopLoss: isBidType ? sellSL?.number : buySL?.number,
                takeProfit: isBidType ? sellTP?.number : buyTP?.number
            });

            if (res.isSuccessful) {
                refreshWorkaround.completed();
                sendOpenPositionEventFunc(!isPopupOpen.isOpen, true, res.price, res.result);

                setOrderInfo((prevState) => {
                    return { ...prevState, status: 'success', price: res.price };
                });
            } else {
                sendOpenPositionEventFunc(!isPopupOpen.isOpen, false, 0, res.result ?? 'trading.error.GeneralError');

                setResponse(res);
            }
        } catch (err) {
            sendOpenPositionEventFunc(!isPopupOpen.isOpen, false, 0, 'trading.error.GeneralError');

            setResponse(err);
        }

        setIsProcessing(false);
    };

    const handleSwitchChange = () => {
        response && setResponse('');
        if (pendingOrderPrice === null) {
            setPendingOrderPrice(isBidType ? PubsubSubscription.getValueNative(tick).bid : PubsubSubscription.getValueNative(tick).ask);
        }
        setPendingOrder((prevState) => !prevState);
    };

    const handleShowError = (error) => {
        setShowError(error);
    };

    const toggleUnitsDropDown = () => {
        setUnitsDropDown((prevState) => !prevState);
    };

    const openUnitsDropDown = () => {
        response && setResponse('');
        setUnitsDropDown(true);
    };

    useEffect(() => {
        const input = document.getElementById('units-stepper');
        const onLeaveInput = (e) => {
            if (e.key === 'Enter' || e.key === 'Tab') {
                setUnitsDropDown(false);
                input.blur();
            }
        };
        document.addEventListener('keyup', onLeaveInput);
        return function cleanup() {
            document.removeEventListener('keyup', onLeaveInput);
            isPopupOpen.isOpen = false;
        };
    }, []);

    useEffect(() => {
        const input = document.getElementById('units-stepper');
        function resizeInput() {
            input.style.width = `${input.value.length}ch`;
        }
        resizeInput();
    }, [selectedUnits]);

    const requestSuccess = orderInfo.status === 'success';
    const popupClass = requestSuccess ? 'trade-popup trade-popup--no-pointer' : 'trade-popup';
    const lotPipNumber = countDecimals(lotStep);
    let unitsName = '';
    let convertionHeader = '';
    if (contractSize !== 1) {
        unitsName = selectedSymbol?.serverUnits || 'unit';
        convertionHeader = `1 ${unitsName} = ${roundNumberPipDigit(contractSize)} ${selectedSymbol?.contractUnitType || ''}`;
    } else {
        unitsName = selectedSymbol?.contractUnitType || 'unit';
    }

    const typeClass = isBidType ? 'bid' : 'ask';
    const buttonClass = showError ? 'open-order no-pointer' : `open-order ${typeClass}`;
    let pendingOrderText = '';
    if (pendingOrder) {
        if (isBidType) {
            pendingOrderText = pendingOrderPrice <= tickObj?.bid ? `SELL Stop ${selectedSymbol?.id} ${selectedUnits.representation} at ${pendingOrderPrice}` : `SELL Limit ${selectedSymbol?.id} ${selectedUnits.representation} at ${pendingOrderPrice}`;
        } else {
            pendingOrderText = pendingOrderPrice > tickObj?.ask ? `BUY Stop ${selectedSymbol?.id} ${selectedUnits.representation} at ${pendingOrderPrice}` : `BUY Limit ${selectedSymbol?.id} ${selectedUnits.representation} at ${pendingOrderPrice}`;
        }
    }

    const handlePendingOrder = async (e) => {
        e.preventDefault();

        const command = CommandFactory.createCommand(selectedSymbol.openPendingOrderCMD);
        setIsProcessing(true);
        const sendPendingOrderEventFunc = TradePopupAnalyticsEvents.createPendingOrderEvent(tickObj, roundNumberPipDigit(pipValue, 2), roundNumberPipDigit(marginRequirement, 2), selectedSymbol.displayName, selectedUnits.number, type, pendingOrderPrice, mainChartController);

        try {
            setResponse(null);
            let refreshWorkaround = refreshStateWorkaround();
            const res = await command.execute({
                actionType: isBidType ? 1 : 0,
                amount: selectedUnits.number,
                stopLoss: pendingSL?.number,
                takeProfit: pendingTP?.number,
                price: pendingOrderPrice
            });

            if (res.isSuccessful) {
                refreshWorkaround.completed();
                sendPendingOrderEventFunc(!isPopupOpen.isOpen, true, res.result);

                setOrderInfo((prevState) => ({ ...prevState, status: 'success', price: pendingOrderPrice }));
            } else {
                sendPendingOrderEventFunc(!isPopupOpen.isOpen, false, res.result ?? 'trading.error.GeneralError');

                setResponse(res);
            }
        } catch (err) {
            sendPendingOrderEventFunc(!isPopupOpen.isOpen, false, 'trading.error.GeneralError');

            setResponse(err);
        }

        setIsProcessing(false);
    };


    return (
        <Fragment>
            {ActiveTheme === 'light' && <div className='trade-popup trade-popup--light' />}
            <div className={popupClass}>
                {!requestSuccess && (
                    <div className='trade-popup__scrollable'>
                        <div className='trade-popup__scrollable-prevent-jumping' style={{ color: SystemColor }}>
                            <i className='fas fa-times' onClick={onClose} />
                            <h2>{displayName}</h2>
                        </div>
                        <h3 style={{ color: SystemColor }}>{description}</h3>
                        <TradePopupWSTick
                            selectedSymbol={selectedSymbol}
                            onBidClick={handleBid}
                            onAskClick={handleAsk}
                            isBidActive={isBidType}
                            isAskActive={!isBidType}
                            tradePopupButton={tradePopupButton}
                            tradePopupButtonBuy={BuyBackground}
                            tradePopupButtonSell={SellBackground}
                            tickUp={TickUp}
                            tickDown={TickDown}
                            tickNeutral={TickNeutral}
                        />
                        {/* <div className='trade-popup__units'>0.01 Unit = 100 EURO</div> */}
                        {/* <div className='trade-popup__scrollable'> */}
                        {selectedSymbol?.serverUnits && selectedSymbol?.contractUnitType && (
                            <Fragment>
                                <div className='trade-popup__units' style={{ color: Text }}>
                                    {convertionHeader}
                                </div>
                            </Fragment>
                        )}
                        <div className='trade-popup__units' style={{ marginTop: '5px' }}>
                            {unitsDropDown && (
                                <Fragment>
                                    <span style={{ color: Text }}>
                                        {t('Trade.PipValue.Title')}
                                        :&nbsp;
                                        <strong style={{ color: SystemColor, opacity: 0.8 }}>
                                            {symbol}
                                            {roundNumberPipDigit(pipValue, 2)}
                                        </strong>
                                    </span>
                                    <span style={{ color: Text }}>
                                        {t('Trade.Margin.Title')}
                                        :&nbsp;
                                        <strong style={{ color: SystemColor, opacity: 0.8 }}>
                                            {symbol}
                                            {roundNumberPipDigit(marginRequirement, 2)}
                                        </strong>
                                    </span>
                                </Fragment>
                            )}
                        </div>
                        <InputStepper
                            selectedUnits={selectedUnits.representation}
                            stepValue={lotStep}
                            setValue={handleSetValue}
                            min={minimumLot}
                            max={maximumLot}
                            pipDigit={lotPipNumber}
                            showError={handleShowError}
                            errorMessage='Input.Error.Amount.Incorrect'
                            checkDivider
                            isError={showError}
                            onFocusAction={openUnitsDropDown}
                            id='units-stepper'
                            shouldSelect={unitsDropDown}
                            inputDescription={selectedSymbol?.serverUnits}
                            // onKeyUp={closeUnitsDropDown}
                        />
                        {showError && <span className='input-description error'>{t(showError)}</span>}
                        {unitsDropDown && (
                            <div className='trade-popup__units-list'>
                                <UnitsDropDown
                                    selectUnits={handleSelectValue}
                                    toggleUnitsDropDown={openUnitsDropDown}
                                    handleClick={toggleUnitsDropDown}
                                    selectedUnits={selectedUnits.number}
                                    contractSize={contractSize}
                                    stepValue={lotStep}
                                    tickObj={tickObj}
                                    isBidActive={isBidType}
                                    unitsName={unitsName}
                                    selectedSymbol={selectedSymbol}
                                    pnlTick={pnlTick}
                                    currency={symbol}
                                />
                            </div>
                        )}
                        {/* <span className='input-description'>Estimated margin requirement:</span> */}
                        <div className='trade-popup__units-info'>
                            <span style={{ color: Text }}>
                                {t('Trade.PipValue.Title')}
                                :&nbsp;
                                <strong style={{ color: SystemColor }}>
                                    {symbol}
                                    {roundNumberPipDigit(pipValue, 2)}
                                </strong>
                            </span>
                            <span style={{ color: Text }}>
                                {t('Trade.Margin.Title')}
                                :&nbsp;
                                <strong style={{ color: SystemColor }}>
                                    {symbol}
                                    {roundNumberPipDigit(marginRequirement, 2)}
                                </strong>
                            </span>
                        </div>
                        <TradePopupStepTwo
                            pendingOrder={pendingOrder}
                            handleSwitchChange={handleSwitchChange}
                            cancelOrder={cancelOrder}
                            handleOpenPosition={handleOpenPosition}
                            price={orderInfo?.price}
                            status={requestSuccess}
                            onClose={onClose}
                            isBidType={isBidType}
                            isPending={pendingOrder}
                            tick={tick}
                            pendingOrderPrice={pendingOrderPrice}
                            handleSetValue={handleSetPendingPrice}
                            selectedSymbol={selectedSymbol}
                            selectedUnits={selectedUnits.number}
                            tickObj={tickObj}
                            setOrderInfo={setOrderInfo}
                            setIsLoading={setIsLoading}
                            setResponse={setResponse}
                            response={response}
                            minimumLot={minimumLot}
                            maximumLot={maximumLot}
                            lotStep={lotStep}
                            symbol={symbol}
                            shouldBlockButton={!!showError}
                            subHeaderColor={{ color: Text }}
                            activeTextColor={{ color: ActiveText }}
                            inactiveTextColor={{ color: InactiveText }}
                            generalColor={{ color: GeneralColor }}
                            systemColor={{ color: SystemColor }}
                            enableSLTP={enableSLTP}
                            buySL={buySL}
                            sellSL={sellSL}
                            setBuySL={setBuySL}
                            setSellSL={setSellSL}
                            buyTP={buyTP}
                            sellTP={sellTP}
                            setBuyTP={setBuyTP}
                            setSellTP={setSellTP}
                            pendingSL={pendingSL}
                            setPendingSL={setPendingSL}
                            pendingTP={pendingTP}
                            setPendingTP={setPendingTP}
                        />
                    </div>
                )}
                {isLoading && <LoadSpinner isLocal />}
                {isProcessing && (
                    <div className='loading-spinner__overlay loading-spinner__overlay--local overlay--dark'>
                        <div className='order-processing'>
                            <LoadingIcon fill={GeneralColor} />
                            <p><strong>{t('Trade.Execute.PleaseWait')}</strong></p>
                            <p>{t('Trade.Execute.Processing')}</p>
                        </div>
                    </div>
                )}
                {!requestSuccess && (
                    <div className='order-buttons'>
                        {/* <button className='cancel-order' onClick={cancelOrder}>Cancel</button> */}
                        {pendingOrder ? (
                            <Button
                                className={buttonClass}
                                style={tradePopupButton}
                                // style={tradePopupButton}
                                onClickHandler={handlePendingOrder}
                                onFocusAction={isBidType ? SellBackground : BuyBackground}
                                onBlurAction={tradePopupButton.backgroundColor}
                                text={pendingOrderText}
                            />
                        ) : (
                            <Button
                                className={buttonClass}
                                style={tradePopupButton}
                                onClickHandler={handleOpenPosition}
                                onFocusAction={isBidType ? SellBackground : BuyBackground}
                                onBlurAction={tradePopupButton.backgroundColor}
                                text={isBidType ? `Sell ${selectedSymbol?.id} ${selectedUnits.representation} at ${tickObj?.bid}` : `Buy ${selectedSymbol?.id} ${selectedUnits.representation} at ${tickObj?.ask}`}
                            />
                        )}
                    </div>
                )}
                {requestSuccess && (
                    <div className='order__successful-container'>
                        <div className='order__successful'>
                            <div className='order__successful-image' />
                            <h3>
                                {pendingOrder ? t('Trade.Execute.SuccessPending') : t('Trade.Execute.Success')}
                            </h3>
                            {!pendingOrder && (
                                <span>
                                    {t('Trade.Execute.SuccessPrice')}
                                    &nbsp;
                                    {orderInfo?.price}
                                </span>
                            )}
                        </div>
                        <button className='close-order' style={tradePopupButton} onClick={onClose} type='button'>{t('General.Done')}</button>
                    </div>
                )}
                {/* {response && <span className='error__message'>{response.result}</span>} */}
            </div>
        </Fragment>
    );
};

// TradePopup.propTypes = {};

export default OuterClick(TradePopup);
