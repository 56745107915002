const STYLES = document.documentElement.style;

export const SIZE = ['small', 'middle', 'large'];
export const POSITION = ['top', 'center', 'bottom'];
export const TYPE = ['full', 'section'];

export const PROPERTY = {
	width: '--spinner-width',
	border: '--spinner-border-width',
	colorTop: '--spinner-color-top',
	colorRight: '--spinner-color-right',
	zindex: '--zindex-loading-spinner',
	maskDark: '--modal-mask-dark',
	maskLight: '--modal-mask-light-dark',
};

export const VALUE = {
	width: {small: '30px', middle: '50px', large: '75px'},
	border: '2px',
	colorTop: '#f05724',
	colorRight: 'transparent',
	zindex: 1000000,
	maskDark: 'rgba(241, 241, 241, 0.7)',
	maskLight: 'rgba(28, 49, 78, 0.7)',
};

export const loaderMobileInit = () => {
	STYLES.setProperty(PROPERTY.width, VALUE.width.middle);
};

export const loaderDesktopInit = () => {
	STYLES.setProperty(PROPERTY.width, VALUE.width.large);
};

export const loaderChangeWidth = size => {
	const currentSize = SIZE.includes(size) ? size : 'middle';
	STYLES.setProperty(PROPERTY.width, VALUE.width[currentSize]);
};
