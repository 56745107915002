import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';

import CheckBox from '../primitives/CheckBox';
import OuterClick from '../primitives/OuterClick';


const ColumnsSelectExpanded = ({
    shouldDisplayPin,
    shouldDisplayInfo,
    // shouldDisplayFlags,
    setShouldDisplayPin,
    setShouldDisplayInfo,
    // setShouldDisplayFlags,
    shouldDisplayTime,
    setShouldDisplayTime,
    generalColor
}) => {
    const [checkboxesState, setCheckboxesState] = useState(null);

    useEffect(() => {
        setCheckboxesState({
            displayPin: shouldDisplayPin,
            displayInfo: shouldDisplayInfo,
            // displayFlags: shouldDisplayFlags,
            displayTime: shouldDisplayTime
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changed = (e) => {
        const { name } = e.target;
        setCheckboxesState((prevState) => ({ ...prevState, [name]: !prevState[name] }));
        switch (name) {
        case 'displayPin':
            setShouldDisplayPin();
            break;
        case 'displayInfo':
            setShouldDisplayInfo();
            break;
        // case 'displayFlags':
        //     setShouldDisplayFlags();
        //     break;
        case 'displayTime':
            setShouldDisplayTime();
            break;

        default:
            break;
        }
    };

    return (
        <div className='left-panel__columns-select--expanded'>
            <CheckBox
                value={checkboxesState?.displayPin || false}
                name='displayPin'
                label='Show Pin'
                labelPlacement
                color={generalColor}
                handleCheckBoxClick={changed}
            />
            <CheckBox
                value={checkboxesState?.displayInfo || false}
                name='displayInfo'
                label='Show Info'
                labelPlacement
                color={generalColor}
                handleCheckBoxClick={changed}
            />
            {/* <CheckBox
                value={checkboxesState?.displayFlags || false}
                name='displayFlags'
                label='Show Flags'
                labelPlacement
                color={generalColor}
                handleCheckBoxClick={changed}
            /> */}
            <CheckBox
                value={checkboxesState?.displayTime || false}
                name='displayTime'
                label='Last Update Time'
                labelPlacement
                color={generalColor}
                handleCheckBoxClick={changed}
            />
        </div>
    );
};

// ColumnsSelectExpanded.propTypes = {};

export default OuterClick(ColumnsSelectExpanded);
