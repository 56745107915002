import { getStore } from '../storeContainer'
import { StatusModes } from '../reducers/generalStatusReducer';
let isGoogleAnalyticsRunning = false;

let initGoogleAnalytics = function (googleAnalyticsData) {
  isGoogleAnalyticsRunning = true;

  var head = document.getElementsByTagName('head')[0];
  var googleAnalyticsScript1 = document.createElement('script');
  googleAnalyticsScript1.src = "https://www.googletagmanager.com/gtag/js?id=" + googleAnalyticsData.measurementId;
  googleAnalyticsScript1.id = "googleAnalyticsScript1";
  head.appendChild(googleAnalyticsScript1);

  var googleAnalyticsScript2 = document.createElement('script');
  googleAnalyticsScript2.text = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${googleAnalyticsData.measurementId}');`;
  googleAnalyticsScript2.id = "googleAnalyticsScript2";
  head.appendChild(googleAnalyticsScript2);
}

let setUserVars = function (googleAnalyticsData, userDetails, envName) {
  window.gtag('set', 'user_properties', {
    'brandName': userDetails.brandName,
    'brokerName': userDetails.brokerName,
    'tpUserId': userDetails.tpUserId,
    'displayName': userDetails.name,
    'envId': userDetails.envId,
    'envName': envName
  });

  window.gtag('config', `'${googleAnalyticsData.measurementId}'`, {
    'user_id': userDetails.userId
  });
}

export function SendEventToGoogleAnalytics(eventName, eventProperties) {
if(isGoogleAnalyticsRunning){
    var finalEventName = eventName.replace(/ /g,"_");
    window.gtag('event', finalEventName, eventProperties);
  }
}

export default function Init() {
  let store = getStore();
  function selectUserGuid(state) {
    return state.account.accountInfo.userDetails.userId;
  }

  let currentUserGuid = selectUserGuid(store.getState());

  store.subscribe(() => {
    let state = store.getState();
    let loginStatus = state.generalStatus.status;
    let googleAnalyticsData = state.account.accountInfo.analyticData.googleAnalyticsData;
    let userDetails = state.account.accountInfo.userDetails;

    if (loginStatus === StatusModes.accountLogin) {
      if (!isGoogleAnalyticsRunning && googleAnalyticsData.isEnabled && googleAnalyticsData.measurementId){
          initGoogleAnalytics(googleAnalyticsData);
      }

      if (isGoogleAnalyticsRunning)
      {
        let previousUserGuid = currentUserGuid;
        currentUserGuid = selectUserGuid(state);
        if (previousUserGuid !== currentUserGuid){
          let envName = state.environments.environments[userDetails.envId]?.displayName ?? "";
          setUserVars(googleAnalyticsData, userDetails, envName);
        }
      }
    }
  });
}
