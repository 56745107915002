import CommandFactory from '../commands/CommandFactory'
import {CMDChartCmdID} from './chartCommand'

const MAX_BAR_TIME = 2524608000000; // Year 2050

class period
{
  Period1Minute = 1;
  Period5Minutes = 5;
  Period15Minutes = 15;
  Period30Minutes = 30;
  Period1Hour = 60;
  Period4Hours = 240;
  Period1Day = 1440;
  Period1Week = 10080;
  Period1Month = 43200;
};

const PeriodInstance = new period();

let getBarTime = function(year,month,day,dateofweek,hours,minutes,period)
{
  switch (period)
  {
    case PeriodInstance.Period1Minute:
      return new Date(Date.UTC(year,month,day,hours,minutes,0,0));
    case PeriodInstance.Period5Minutes:
      return new Date(Date.UTC(year,month,day,hours,minutes - (minutes%5),0,0));
    case PeriodInstance.Period15Minutes:
      return new Date(Date.UTC(year,month,day,hours,minutes - (minutes%15),0,0));
    case PeriodInstance.Period30Minutes:
      return new Date(Date.UTC(year,month,day,hours,minutes - (minutes%30),0,0));
    case PeriodInstance.Period1Hour:
      return new Date(Date.UTC(year,month,day,hours,0,0,0));
    case PeriodInstance.Period4Hours:
      return new Date(Date.UTC(year,month,day,hours -(hours%4),0,0,0));
    case PeriodInstance.Period1Day:
      return new Date(Date.UTC(year,month,day,0,0,0,0));
    case PeriodInstance.Period1Week:
        return new Date(Date.UTC(year,month,day - (dateofweek-1),0,0,0,0));
      case PeriodInstance.Period1Month:
        return new Date(Date.UTC(year,month,0,0,0,0,0));
    default:
      return new Date(Date.UTC(year,month,day,hours,minutes,0,0));
  }
}


class chartService
{
  constructor() {
    this._chartData = new Map();
    this._tickData = new Map();
    this._idSeq = 0;
  }

  async getMoreBars(cmd, period,barCount,timestampFrom)
  {
    let command = CommandFactory.createCommand(cmd);
    let symbolId =command.getStatic().symbolId
    let key = `${symbolId}|${period}`;
    let chartData = this._chartData.get(key);
    if (chartData)
    {
      if (chartData.hasMore)
      {
        let command = CommandFactory.createCommand(cmd);
        return await command.execute(Object.assign({},command.getUserInput(),{period:period,barCount:barCount,from:timestampFrom,relativeCharting:true}));
      }
    }
  }

  getOldestBar(symbolId,period)
  {
    let key = `${symbolId}|${period}`;
    let chartData = this._chartData.get(key);
    if (chartData)
    {
      return chartData.oldestBarTime;
    }

    return MAX_BAR_TIME;
  }

  subscribe(cmd, period,barCount, callback)
  {
    let command = CommandFactory.createCommand(cmd);
    let symbolId =command.getStatic().symbolId

    command.execute(Object.assign({},command.getUserInput(),{period:period,barCount:barCount,relativeCharting:true}));

    let seq = this._idSeq++;
    let disposed = false;
    let key = `${symbolId}|${period}`;
    let chartData = this._chartData.get(key);

    if (!chartData)
    {
      let resolutionFunc = null;
      let promise = new Promise((promisResolutionFunc)=>{ resolutionFunc = promisResolutionFunc});
      chartData = {
        subscriptionPromiseAction: resolutionFunc,
        subscriptionPromise: promise,
        hasData: false,
        callbacks:new Map(),
        barsMap:new Map(),
        oldestBarTime:MAX_BAR_TIME        
      };
      this._chartData.set(key,chartData);
    }

    chartData.subscriptionPromise.then(()=>{
      if (!disposed)
      {
        let arrayOfdata =[]
        for (let value of chartData.barsMap.values()) {
          arrayOfdata.push(value);
        }
    
        arrayOfdata.sort(function(a, b) {return a[0] - b[0];});
        
        var callbackAction = callback(arrayOfdata);
        if (callbackAction === true)
        {
          chartData.callbacks.set(seq,callback);
        }
      }
    });

    return {
      promise:chartData.subscriptionPromise,
      dispose:()=>{ 
        disposed = true;
        chartData.callbacks.delete(seq);
      }
    } 
  }

  updateData(symbolId,period,bars,hasMore)
  {
    let key = `${symbolId}|${period}`;
    let chartData = this._chartData.get(key);
    if (!chartData)
    {
      let resolutionFunc = null;
      let promise = new Promise((promisResolutionFunc)=>{ resolutionFunc = promisResolutionFunc});
      chartData = {
        subscriptionPromiseAction: resolutionFunc,
        subscriptionPromise: promise,
        hasData: true,
        hasMore: (hasMore === undefined) ? false:hasMore,
        barsMap:new Map(),
        oldestBarTime:MAX_BAR_TIME,
      };
      this._chartData.set(key,chartData);
    }
    if (hasMore !== undefined)
    {
      chartData.hasMore = hasMore;
    }


    let arrayOfdata =[]
    for(var bar of bars)
    {
      let localtime = new Date(bar.ServerTimeStamp);
      let utcTime = new Date(bar.ServerTimeStampUTC);
      let currBar = [localtime,utcTime,bar.Open,bar.High,bar.Low,bar.Close,bar.Close];
      arrayOfdata.push(currBar);
      if (chartData.oldestBarTime > bar.ServerTimeStamp)
      {
        chartData.oldestBarTime = bar.ServerTimeStamp;
      }

      chartData.barsMap.set(localtime.getTime(),currBar)
    }

    arrayOfdata.sort(function(a, b) {return a[0] - b[0];});
    
    // Update the latest bar...
    let currentTick = this._tickData.get(symbolId);
    if (currentTick)
    {
      let tickTime = new Date(currentTick.t);
      let year = tickTime.getUTCFullYear();
      let month =tickTime.getUTCMonth();
      let day =tickTime.getUTCDate();
      let dayofweek = tickTime.getUTCDay();
      let hours = tickTime.getUTCHours();
      let minutes = tickTime.getUTCMinutes();

      let offset = currentTick.t - currentTick.tu;
      
      let currBar = this._updateBarStateWithTick(getBarTime(year,month,day,dayofweek,hours,minutes,period),offset, currentTick, chartData);
      if (currBar)
      {
        arrayOfdata.push(currBar);
      }
    }

    chartData.hasData = true;
    chartData.subscriptionPromiseAction();

    let lstRemoveCallbacks = [];
    for (let callback of chartData.callbacks.values()) {
      let x = callback(arrayOfdata);
      if (!x)
      {
        lstRemoveCallbacks.push(callback);
      }
    }
    lstRemoveCallbacks.forEach(callback => {chartData.callbacks.delete(callback);});
  }

  _processTick(tick,barTime,period)
  {
    var offset = tick.t - tick.tu;
    let key = `${tick.sm}|${period}`;
    let chartData = this._chartData.get(key);
    if (chartData && chartData.hasData)
    {
      let lstRemoveCallbacks = null;
      let prevBarToPublish = null;
      if (!chartData.barsMap.get(barTime.getTime()))
      {
        if (chartData.latestPublish)
        {
          let prevBar = chartData.barsMap.get(chartData.latestPublish);
          if (prevBar)
          {
            let rebuildBar = [...prevBar];
            rebuildBar[5] = rebuildBar[6];  // Set User Close to Absulute Close.
            chartData.barsMap.set(chartData.latestPublish, rebuildBar);
            prevBarToPublish = rebuildBar;
          }
        }
      }

      let publishBar = this._updateBarStateWithTick(barTime, offset, tick, chartData);
      if (publishBar)
      {
        if (chartData.oldestBarTime > barTime)
        {
          chartData.oldestBarTime = barTime;
        }

        chartData.barsMap.set(barTime.getTime(),publishBar);

        /*if (prevBarTime !== MIN_BAR_TIME && prevBarTime !== publishBar[0])
        {
          let prevBar = chartData.barsMap.get(prevBarTime);
          if (prevBar)
          {
            let rebuildBar = [...prevBar];
            rebuildBar[5] = rebuildBar[6];  // Set User Close to Absulute Close.
            chartData.barsMap.set(prevBarTime, rebuildBar);
            prevBarToPublish = rebuildBar;
          }
        }*/

        chartData.latestPublish = publishBar[0].getTime();
        for (let callback of chartData.callbacks.values()) {
          let x = null;
          if (prevBarToPublish)          {
            x = callback([prevBarToPublish,publishBar]);
          }
          else{
            x = callback([publishBar]);
          }

          if (!x)
          {
            if (!lstRemoveCallbacks)
            {
              lstRemoveCallbacks = [];
            }
            lstRemoveCallbacks.push(callback);
          }
        }
        if (lstRemoveCallbacks)
        {
          lstRemoveCallbacks.forEach(callback => {
            chartData.callbacks.delete(callback);
          });
        }

      }
    }
  }

  _updateBarStateWithTick(barTime,offset, tick, chartData) {
    // We dont to any thing with the utc for now...
    let publishBar  =null;
    let currentBar = chartData.barsMap.get(barTime.getTime());

    if (!currentBar) {
      publishBar = [barTime, barTime - offset, tick.b1o, tick.b1h, tick.b1l, tick.b, tick.b1c];
      chartData.barsMap.set(barTime.getTime(), publishBar);
    }
    else {

      if (currentBar[3] < tick.b1h) {
        if (!publishBar)
          publishBar = [...currentBar];
        publishBar[3] = tick.b1h;
      }

      if (currentBar[4] > tick.b1l) {
        if (!publishBar)
          publishBar = [...currentBar];
        publishBar[4] = tick.b1l;
      }

      if (currentBar[6] !== tick.b1c) {
        if (!publishBar)
          publishBar = [...currentBar];
        publishBar[6] = tick.b1c;
      }

      if (currentBar[5] !== tick.b) {
        if (!publishBar)
          publishBar = [...currentBar];
        publishBar[5] = tick.b;
      }
    }
    return publishBar;
  }

  onTicks(ticks)
  {
    for(let tick of ticks)
    {
      let currentTick = this._tickData.get(tick.sm);
      if (!currentTick){
        currentTick ={
          sm:tick.sm
        };
        this._tickData.set(tick.sm,currentTick);
      }
      
      currentTick.a = tick.a;
      currentTick.b = tick.b;
      currentTick.t = tick.t;
      currentTick.tu = tick.tu;
      currentTick.b1o = tick.b1o;
      currentTick.b1h = tick.b1h;
      currentTick.b1l = tick.b1l;
      currentTick.b1c = tick.b1c;

      let tickTime = new Date(tick.t);
      let year = tickTime.getUTCFullYear();
      let month =tickTime.getUTCMonth();
      let day =tickTime.getUTCDate();
      let dayofweek = tickTime.getUTCDay();
      let hours = tickTime.getUTCHours();
      let minutes = tickTime.getUTCMinutes();
      
      this._processTick(tick,getBarTime(year,month,day,dayofweek,hours,minutes,PeriodInstance.Period1Minute),PeriodInstance.Period1Minute);
      this._processTick(tick,getBarTime(year,month,day,dayofweek,hours,minutes,PeriodInstance.Period5Minutes),PeriodInstance.Period5Minutes);
      this._processTick(tick,getBarTime(year,month,day,dayofweek,hours,minutes,PeriodInstance.Period15Minutes),PeriodInstance.Period15Minutes);
      this._processTick(tick,getBarTime(year,month,day,dayofweek,hours,minutes,PeriodInstance.Period30Minutes),PeriodInstance.Period30Minutes);
      this._processTick(tick,getBarTime(year,month,day,dayofweek,hours,minutes,PeriodInstance.Period1Hour),PeriodInstance.Period1Hour);
      this._processTick(tick,getBarTime(year,month,day,dayofweek,hours,minutes,PeriodInstance.Period4Hours),PeriodInstance.Period4Hours);
      this._processTick(tick,getBarTime(year,month,day,dayofweek,hours,minutes,PeriodInstance.Period1Day),PeriodInstance.Period1Day);
      this._processTick(tick,getBarTime(year,month,day,dayofweek,hours,minutes,PeriodInstance.Period1Week),PeriodInstance.Period1Week);
      this._processTick(tick,getBarTime(year,month,day,dayofweek,hours,minutes,PeriodInstance.Period1Month),PeriodInstance.Period1Month);
    }
  }

  isRequsetRequired(symbolId,period)
  {
    let symbolData = this._chartData.get(`${symbolId}|${period}`)
    if (symbolData)
    {
      if (symbolData.hasData)
      {
        return false;
      }
    }

    return true;
  }

  isChart(instance)
  {
    if (instance && typeof instance === 'object')
    {
      if (instance.cmdId === CMDChartCmdID)
      {
        return true;
      }
    }
  }
}

export const Period = PeriodInstance;
export const ChartService = new chartService();