/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
import React, { useState, Fragment } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
// import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CommandFactory } from '../../background/commands/CommandFactory';
import Tabs from '../primitives/Tabs/Tabs';
import OpenPositions from '../OpenPositions/OpenPositions';
import PendingOrders from '../PendingOrders/PendingOrders';
import ClosedPositions from '../ClosedPositions/ClosedPositions';
import ClosePosition from '../OpenPositions/ClosePosition';
import OpenPositionsCounter from '../OpenPositions/OpenPositionsCounter';
import UnlinkPopup from '../OpenPositions/UnlinkPopup';
import PendingOrdersCounter from '../PendingOrders/PendingOrdersCounter';
import ModalWrapper from '../primitives/ModalWrapper';
import ClosedPositionSuccess from '../OpenPositions/ClosedPositionSuccess';
import PNL from '../PNL/PNL';
import ExpandedPNL from '../PNL/ExpandedPNL';
import ClosedPositionsCounter from '../ClosedPositions/ClosedPositionsCounter';
import { SendEventToFullStory } from '../../background/storeListener/fullStoryListener';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';
import {SendEventToLuckyOrange} from '../../background/storeListener/luckyOrangeListener';

import './BottomPanel.css';


const BottomPanel = ({
    setSelectedTrader, isBottomPanelPinned, initialBottomHeight, setIsBottomPanelPinned, isBottomOpen, setIsBottomOpen, width, shouldDisplayDrawerPin
}) => {
    const [closedOrderData, setClosedOrderData] = useState(null);
    const [shouldCloseOrder, setShouldCloseOrder] = useState(null);
    const [shouldShowUnlinkPopup, setShoudShowUnlinkPopup] = useState({ tpId: null, shouldShow: false });
    const { financial: { balance, credit, margin }, accountInfo: { currency: { symbol } } } = useSelector((state) => { return (state.account); }, shallowEqual);

    const { unlinkTradeCommand } = useSelector((state) => (state.account.socialv1.copyRelationsCommands), shallowEqual);
    const { SubHeader, Text: { GeneralColor, SystemColor, Link } } = useSelector((state) => (state.brand.theme));
    const chevronUp = <i className='fas fa-chevron-up' />;
    const chevronDown = <i className='fas fa-times' />;
    const isBottomOpened = isBottomOpen || isBottomPanelPinned;
    const isSmallDesktop = width < 1200;
    const systemColor = { color: SystemColor };
    const subHeaderColor = { color: SubHeader.Text };
    const linkColor = { color: Link };
    const generalColor = { color: GeneralColor };
    let bottomPanelClass = 'bottom-panel bottom-panel--unpinned';
    if (isBottomPanelPinned) {
        bottomPanelClass = 'bottom-panel bottom-panel--pinned bottom-panel--opened';
    } else if (isBottomOpen) {
        bottomPanelClass = 'bottom-panel bottom-panel--opened bottom-panel--unpinned';
    }
    const { t } = useTranslation();
    // eslint-disable-next-line object-curly-newline
    const callModalOfClosedOrder = ({ symbolName, orderId, openTimeS, type, openRate, amount, profitValue, price }) => {
        setClosedOrderData({
            symbolName, orderId, openTimeS, type, openRate, amount, profitValue, price
        });
    };

    const closeSuccessOrder = () => {
        setClosedOrderData(null);
    };

    const handleClickOutside = (event) => {
        if (!event.target.classList.contains('outer-exclude') && !event.target.closest('.outer-exclude')) {
            document.removeEventListener('click', handleClickOutside);
            setIsBottomOpen(false);
        }
    };

    const handleUnlinkOrder = async (tpId) => {
        const userInput = { followerPositionID: Number(tpId) };

        const command = CommandFactory.createCommand(unlinkTradeCommand);
        // setIsLoading(true);

        try {
            // setResponse(null);
            const res = await command.execute(userInput);

            if (res.ok === false) {
                // setResponse({ result: res.statusText });
            }
            if (res.isSuccessful === true) {
                setShoudShowUnlinkPopup({ tpId: null, shouldShow: false });
            } else if (res.isSuccessful === false) {
                // setResponse(res);
            }
        } catch (err) {
            // setResponse(err);
        }

        // setIsLoading(false);
    };

    const handleUnlink = (tpId) => {
        if (tpId) {
            setShoudShowUnlinkPopup((prevState) => ({ tpId, shouldShow: !prevState.shouldShow }));
        } else {
            setShoudShowUnlinkPopup((prevState) => ({ tpId: null, shouldShow: !prevState.shouldShow }));
        }
    };

    const closeUnlinkPopup = () => {
        setShoudShowUnlinkPopup({ tpId: null, shouldShow: false });
    };

    const openBottomPanel = () => {
        SendEventToGoogleAnalytics('DrawerClicked', { isOpen: true });
        SendEventToFullStory('DrawerClicked', { isOpen_bool: true });
        SendEventToLuckyOrange('DrawerClicked:isOpen:true');

        setIsBottomOpen(true);
        document.addEventListener('click', handleClickOutside);
    };

    const closeBottomPanel = () => {
        SendEventToGoogleAnalytics('DrawerClicked', { isOpen: false });
        SendEventToFullStory('DrawerClicked', { isOpen_bool: false });
        SendEventToLuckyOrange('DrawerClicked:isOpen:false');

        document.removeEventListener('click', handleClickOutside);
        setIsBottomOpen(false);
    };

    const callModalOfClosedOrderHandler = (obj) => {
        setShouldCloseOrder(null);
        callModalOfClosedOrder(obj);
    };

    const handleCancelOrderClose = () => {
        setShouldCloseOrder(null);
    };

    const pinBottomPanel = () => {
        setIsBottomOpen(true);
        const command = CommandFactory.createCommand(setIsBottomPanelPinned);
        command.execute();
    };

    const unpinBottomPanel = (e) => {
        e.stopPropagation();
        closeBottomPanel();
        const command = CommandFactory.createCommand(setIsBottomPanelPinned);
        command.execute();
    };

    const onTabClick = (tab)=>{
      SendEventToGoogleAnalytics(`Drawer:${tab}TabClick`, {'tabName': tab});
      SendEventToFullStory(`Drawer:${tab}TabClick`, {'tabName_str': tab});
      SendEventToLuckyOrange(`Drawer:${tab}TabClick`);
    };

    return (
        <Fragment>
            <div className={bottomPanelClass} onClick={!isBottomOpen ? openBottomPanel : null}>
                {!isBottomPanelPinned ? (
                    <div className='bottom-panel__toggler outer-exclude'>
                        {isBottomOpen ? (
                            shouldDisplayDrawerPin ? (
                                <div className='bottom-panel__pin-close'>
                                    <div onClick={pinBottomPanel}>
                                        <i className='fas fa-thumbtack' />
                                    </div>
                                    <div onClick={!isBottomOpen ? null : closeBottomPanel}>{chevronDown}</div>
                                </div>
                            ) : (
                                <div onClick={!isBottomOpen ? null : closeBottomPanel}>{chevronDown}</div>
                            )
                        ) : chevronUp}
                    </div>
                ) : (
                    <div className='bottom-panel__toggler bottom-panel__toggler--pinned'>
                        {shouldDisplayDrawerPin && <div onClick={unpinBottomPanel}>{chevronDown}</div>}
                    </div>
                )}
                {isBottomOpened && (
                    <div className='bottom-panel__tabs outer-exclude' id='bottom-panel__tabs'>
                        <Tabs onTabClick={onTabClick} className='bottom-panel__tabs' tabContentId='bottom-panel__content'>
                            <li name='Open' label={t('Positions.Open.Title')} tabChildComponent={<OpenPositionsCounter />}>
                                <OpenPositions
                                    setShouldCloseOrder={setShouldCloseOrder}
                                    setSelectedTrader={setSelectedTrader}
                                    handleUnlink={handleUnlink}
                                    initialBottomHeight={initialBottomHeight}
                                    isBottomPanelPinned={isBottomPanelPinned}
                                    isSmallDesktop={isSmallDesktop}
                                    subHeaderColor={subHeaderColor}
                                    systemColor={systemColor}
                                    linkColor={linkColor}
                                />
                            </li>
                            <li name='Pending' label={t('Positions.Pending.Title')} tabChildComponent={<PendingOrdersCounter />}>
                                <PendingOrders
                                    isBottomPanelPinned={isBottomPanelPinned}
                                    subHeaderColor={subHeaderColor}
                                    systemColor={systemColor}
                                    linkColor={linkColor}
                                    generalColor={generalColor}
                                />
                            </li>
                            <li name='Closed' label={t('Positions.Closed.Title')} tabChildComponent={<ClosedPositionsCounter />}>
                                <ClosedPositions
                                    setSelectedTrader={setSelectedTrader}
                                    isBottomPanelPinned={isBottomPanelPinned}
                                    subHeaderColor={subHeaderColor}
                                    systemColor={systemColor}
                                    linkColor={linkColor}
                                    generalColor={generalColor}
                                />
                            </li>
                        </Tabs>
                        <ExpandedPNL
                            balance={balance}
                            credit={credit}
                            symbol={symbol}
                            margin={margin}
                            subHeaderColor={subHeaderColor}
                            systemColor={systemColor}
                        />
                    </div>
                )}
                {!isBottomOpened && (
                    <Fragment>
                        <PNL
                            balance={balance}
                            credit={credit}
                            symbol={symbol}
                            subHeaderColor={subHeaderColor}
                            systemColor={systemColor}
                        />
                    </Fragment>
                )}
            </div>
            <ModalWrapper
                open={!!closedOrderData || !!shouldCloseOrder}
                heading={t('Close.Order.Title')}
                handleClose={shouldCloseOrder ? handleCancelOrderClose : closeSuccessOrder}
                childClassName='no-animation close-order__modal outer-exclude'
                contentClassName='close-order__modal-content'
                BackdropProps={{ className: 'outer-exclude' }}
            >
                {closedOrderData && (
                    <ClosedPositionSuccess
                        symbolName={closedOrderData?.symbolName}
                        orderId={closedOrderData?.orderId}
                        openTimeS={closedOrderData?.openTimeS}
                        type={closedOrderData?.type}
                        openRate={closedOrderData?.openRate}
                        amount={closedOrderData?.amount}
                        profitValue={closedOrderData?.profitValue}
                        closePrice={closedOrderData?.price}
                        generalColor={generalColor}
                        closeSuccessOrder={closeSuccessOrder}
                    />
                )}
                {shouldCloseOrder && (
                    <ClosePosition
                        closePositionCMD={shouldCloseOrder?.closePositionCMD}
                        symbolName={shouldCloseOrder?.symbolName}
                        handleCancelOrderClose={handleCancelOrderClose}
                        orderId={shouldCloseOrder?.orderId}
                        openTimeS={shouldCloseOrder?.openTimeS}
                        type={shouldCloseOrder?.type}
                        openRate={shouldCloseOrder?.openRate}
                        amount={shouldCloseOrder?.amount}
                        callModalOfClosedOrder={callModalOfClosedOrderHandler}
                        tradeLink={shouldCloseOrder?.tradeLink}
                        generalColor={generalColor}
                        stopLoss={shouldCloseOrder?.stopLoss}
                        takeProfit={shouldCloseOrder?.takeProfit}
                    />
                )}
            </ModalWrapper>
            {shouldShowUnlinkPopup.shouldShow && (
                <ModalWrapper
                    open={shouldShowUnlinkPopup.shouldShow}
                    heading={t('Social.Unlink.ConfirmTitle')}
                    handleClose={closeUnlinkPopup}
                    BackdropProps={{ className: 'outer-exclude' }}
                    childClassName='unlink-modal modal--small outer-exclude'
                >
                    <UnlinkPopup handleUnlinkOrder={handleUnlinkOrder} handleClick={closeUnlinkPopup} orderId={shouldShowUnlinkPopup.tpId} />
                </ModalWrapper>
            )}
        </Fragment>
    );
};

// BottomPanel.propTypes = {};

export default BottomPanel;
