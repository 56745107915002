import {WebsocketConnection} from "./WebsocketConnection"
import {TicksRepository} from "../repositories/TicksRepository"
import {ChartService} from '../../background/chart/ChartService'
import CommandFactory from "../commands/CommandFactory"
import {onSocialV1TradeArrived} from "../socialv1/tradesStreams"
import {getStore} from '../storeContainer'
import {StatusModes} from '../reducers/generalStatusReducer'

let shouldRefresh = false;
let _lastChartSubscription = null;
let _ackFunction = ()=>{};

const ticks_connection = new WebsocketConnection({
  TicksV2:function(ticks,socket)  {
    for (let i =0;i<ticks.length;i++){
      let item = ticks[i];
      item.bpc = item.c;
      item.apc = item.c;
      item.mc = item.c;
    }

    TicksRepository.publish(ticks);
  },
  Bar:function(bars,socket){
    let barsByTopic = {}
    for(var bar of bars)
    {
      let key = `${bar.sm}|${bar.p}`
      if (barsByTopic[key] === undefined)
      {
        barsByTopic[key] = {
          symbol : bar.sm,
          period : bar.p,
          bars : []
        }
      }

      barsByTopic[key].bars.push({
        ServerTimeStamp : bar.t,
        ServerTimeStampUTC:bar.t,
        Open:bar.o,
        High:bar.h,
        Low:bar.l,
        Close:bar.c
      })
    }
    Object.keys(barsByTopic).forEach(e =>
      ChartService.updateData(barsByTopic[e].symbol,barsByTopic[e].period,barsByTopic[e].bars,undefined)
      );
  },
  Ticks:function(ticks,socket)  {
    TicksRepository.publish(ticks);
    ChartService.onTicks(ticks);
  },
  __open:(messages,socket)=>{
    if (_lastChartSubscription)
    {
      socket.send(JSON.stringify(_lastChartSubscription));
    }
  },
  __closed:(messages,socket)=>
  {
    shouldRefresh = true;
  },
  KA: (messages,socket)=>{
    _ackFunction = ()=>{socket.send('{"Type":"ACK"}')};
    requestAnimationFrame(()=>
    {
      try
      {
        _ackFunction();
      }
      catch{}
      finally{
        _ackFunction = ()=>{};
      }

    });
    if (shouldRefresh)
    {
      shouldRefresh  =false;
      if (getStore().getState().account.accountInfo.refreshStateCommand)
      {
        CommandFactory.createCommand(getStore().getState().account.accountInfo.refreshStateCommand).execute({mode:'reconnection_refresh'})
      }
    }
  },
  SOCIALV1_TRADES:function(trades){
    onSocialV1TradeArrived(trades);
  },
  Command:function(commands)
  {
    for (let i = 0;i<commands.length;i++)
    {
      let cmd = commands[i];
      if (CommandFactory.isCommand(cmd))
      {
        // This is an hack to support V3 witout changing the getTradingData featrue.
        if (cmd.staticParams?.postUrl === 'api/tradingData/getTradingData') 
        {
          var symbols = getStore().getState().account.symbols.symbols;
          var contractSizes = {};
          for (const symbolName in symbols) {
              contractSizes[symbolName] =symbols[symbolName].contractSize;
            }

          CommandFactory.createCommand(cmd).execute({clientContractSizes:contractSizes});
        }
        else
        {
          CommandFactory.createCommand(cmd).execute();
        }
      }
    }
  }
});

ticks_connection.url = '/api/ws'


let updateFunction = function()
  {
    let store = getStore().getState();
  
    if (store.generalStatus.status === StatusModes.accountLogin)
    {
      ticks_connection.host = store.account.accountInfo.userDetails.wshost;
      ticks_connection.start();
    }
    else if (store.generalStatus.status === StatusModes.requireLogin)
    {
      ticks_connection.stop();
    }
  }


export default function Init()
{
  let store = getStore();
  function select(state) {
    return state.generalStatus.status;
  }
    
  let currentValue = select(store.getState());
  
  store.subscribe(()=>{
    let previousValue = currentValue
    currentValue = select(store.getState())
  if (previousValue !== currentValue) {
      updateFunction();
  }});
}

export const wsChartSubscription = (msg)=>{
  _lastChartSubscription = msg;
  ticks_connection.send(msg);
};


