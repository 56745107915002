import {toWsUrl} from '../commands/mobileUrlService'
import pako from 'pako';

export class WebsocketConnection {
  constructor(messageHanlders){
      this.messageHanlders = messageHanlders;
      this.webSocket = null;
      this.url = "/api/ws";
      this._stop = false;
      this.start = this.start.bind(this);
      this.stop = this.stop.bind(this);
      this._wasConnected = false;
      this.status = 'offline';
      this.host = '';

      let local =this;
  }

  stop()
  {
      this._stop = true;
      if (this.webSocket)
      {
          this.webSocket.close();
      }
  }

  send(msg)
  {
    if (this.webSocket)
    {
        if (this.status == 'open')
        {
            this.webSocket.send(JSON.stringify(msg));
        }
    }
  }

  start()
  {
      if (this.webSocket)
      {
          this.webSocket.close();
      }
      let session = encodeURIComponent(window.sessionStorage.getItem('superweb-session') ?? '');
      this.webSocket = new WebSocket(toWsUrl(`${this.url}?_dc=${Date.now()}&relativeCharting=true&compressed=true&token=${session}`));

      this.status = 'connecting';
      this._stop = false;
      let socket = this.webSocket;
      let local =this;
      this.webSocket.onopen =  ()=>{
            local.status = 'open';
          local._system_message(socket,"__open");
          if (local._wasConnected)
          {
            local._system_message(socket,"__reconnected");
          }
          local._wasConnected = true;
      };

      this.webSocket.onerror = this._onSocketClosed.bind(this);
      this.webSocket.onclose = this._onSocketClosed.bind(this);
      this.webSocket.onmessage = (d)=>{
            var socketDataObj = null;
            if (d.data instanceof ArrayBuffer)
            {
                let inflator = new pako.Inflate({
                    to:"string"
                });
                inflator.push(d.data,true);
                socketDataObj = inflator.result;
            }
            else if (d.data instanceof Blob)
            {
                let local = this;
                d.data.arrayBuffer().then(buffer=>{
                    if (buffer)
                    {
                        let inflator = new pako.Inflate({
                            to:"string"
                        });
                        inflator.push(buffer,true);
                        var socketData = inflator.result;
                        if (socketData)
                        {
                            var jsonData = JSON.parse(socketData);
                            if (jsonData.Type)
                            {
                                let handler = local.messageHanlders[jsonData.Type];
                                if (handler)
                                {
                                    handler(jsonData.PayLoad,socket);
                                }
                            }
                        }
                    }
                })
            }
            else if (typeof d.data === "string")
            {
                socketDataObj = d.data;
            }

            if (socketDataObj)
            {
                try
                {
                    var jsonData = JSON.parse(socketDataObj);
                    if (jsonData?.Type)
                    {
                        let handler = this.messageHanlders[jsonData.Type];
                        if (handler)
                        {
                            handler(jsonData.PayLoad,socket);
                        }
                    }
                }
                catch(e)
                {
                    console.error(e,socketDataObj);
                }
            }
      }

  }

  _system_message(socket,systemType)
  {
    let handler = this.messageHanlders[systemType];
    if (handler)
    {
        handler([],socket);
    }
  }

  _onSocketClosed(event)
  {
      if (this.status != 'offline')
      {
        this.status = 'offline';
        this._system_message(this.webSocket,"__closed");

        let webSocket = this.webSocket;
        let thisScope = this;
        
        setTimeout(function(){
                if (!thisScope._stop)
            {
                if (webSocket === thisScope.webSocket)
                {
                    thisScope.start()
                }
            }}, 1000);
      }
  }
}
