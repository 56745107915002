import {getStore} from '../storeContainer'

let updateFunction = function()
{
  let store = getStore().getState();
  window.document.title= store.brand.generalInfo.windowTitle;
      
  var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = store.brand.generalInfo.favIcon;
  document.getElementsByTagName('head')[0].appendChild(link);
}

export default function Init()
{
  let store = getStore();
  function select(state) {
    return state.brand.generalInfo;
  }
    
  let currentValue = select(store.getState());
  
  store.subscribe(()=>{
    let previousValue = currentValue
    currentValue = select(store.getState())
  if (previousValue !== currentValue) {
      updateFunction();
  }});
}