/* eslint-disable no-restricted-syntax */
/* eslint-disable complexity */
import React from 'react';
import { connect } from 'react-redux';
import { Period, ChartService } from '../../background/chart/ChartService';

const internalPeriodToTrade4Pro = {};

export const CHART_TYPES = {
    CANDLES: 'candles',
    BARS: 'bars',
    LINE: 'line',
    AREA: 'area'
};

// Period1Month = "1M"; // NOT Supported
internalPeriodToTrade4Pro[Period.Period1Minute] = '1M';
internalPeriodToTrade4Pro[Period.Period5Minutes] = '5M';
internalPeriodToTrade4Pro[Period.Period15Minutes] = '15M';
internalPeriodToTrade4Pro[Period.Period30Minutes] = '30M';
internalPeriodToTrade4Pro[Period.Period1Hour] = '1H';
internalPeriodToTrade4Pro[Period.Period4Hours] = '4H';
internalPeriodToTrade4Pro[Period.Period1Day] = '1D';
internalPeriodToTrade4Pro[Period.Period1Week] = '1W';

export const trade4ProToInternal = {
    '1M': Period.Period1Minute,
    '5M': Period.Period5Minutes,
    '15M': Period.Period15Minutes,
    '30M': Period.Period30Minutes,
    '1H': Period.Period1Hour,
    '4H': Period.Period4Hours,
    '1D': Period.Period1Day,
    '1W': Period.Period1Week
};

export const defaultChartOptions = {
    // 'colors.grid': '#1C314E',
    'colors.candleShadow': '#979797',
    'colors.axisText': '#979797',
    'colors.frameActive': '#979797',
    'colors.futureBackground': 'rgba(256, 256, 256, 0.05)',
    'colors.futureLine': 'rgba(256, 256, 256, 0.1)',
    'system.frameWidth': 0,
    'system.axisLineWidth': 0,
    'system.roundedCandles': false
};

let globalChartObj = null;

export const getGlobalChart = function () {
    return globalChartObj;
};

class ChartPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        const local = this;

        // eslint-disable-next-line func-names
        this.trading4ProRef = function (element) {
            local.elementUpdated(element);
        };
    }


    shouldComponentUpdate(nextProps, nextState) {
        // || nextProps.addIndicator !== this.props.addIndicator
        // || nextProps.shouldClearIndicators !== this.props.shouldClearIndicators

        if (nextProps.activeSymbolId !== this.props.activeSymbolId
            || nextProps.period !== this.props.period
            || nextProps.chartType !== this.props.chartType) {
            this.updateChart(nextProps);
        }
        if (nextProps.isLight !== this.props.isLight) {
            this.updateChartStyle(nextProps);
        }
        return false;
    }

    updateChartStyle(props) {
        if (this.trading4ProChartInstance)
        {
            const chart = this.trading4ProChartInstance;
            const { isLight } = props;

            if (isLight) {
                chart.setColor('background', '#fafafa');
                chart.setColor('axisLine', '#fafafa');
                chart.setColor('grid', '#E9EBF3');
                chart.setConfig('system.gridDash', [1, 0]);
            } else {
                chart.setColor('background', '#13172D');
                chart.setColor('axisLine', '#13172D');
                chart.setColor('grid', '#1C314E');
                chart.setConfig('system.gridDash', [1, 3]);
            }
        }
    }

    updateChart(props) {
        const chart = this.trading4ProChartInstance;
        if (chart)
        {
            const { chartType, period, activeSymbolId, symbols } = props;
            let internalPeriod = period;
            if (internalPeriod instanceof String) {
                internalPeriod = parseInt(internalPeriod, 10);
            }

            const timePeriod = internalPeriodToTrade4Pro[internalPeriod];

            const symbol = symbols[activeSymbolId];
            if (!symbol) {
                chart.setDisplayName('');
                chart.setChartType(chartType);
                chart.setTimeframe('5M');
            } else {
                chart.setSymbol(activeSymbolId);
                chart.setDisplayName(symbol.displayName);
                chart.setChartType(chartType);
                chart.setTimeframe(timePeriod);
            }
        }
    }

    subscribeToCandles(symbol, internalPeriod, barCount, timePeriod, trading4ProCallback, chart) {
            if (this.subscription) {
                this.subscription.dispose();
                this.subscription = null;
            }
            let local = this;
            this.subscription = ChartService.subscribe(symbol.chart, internalPeriod, barCount, (bars) => {
                const outputArray = [];
                for (const bar of bars) {
                    let timeToUse = (bar[0] / 1000);
                    if (local.props.serverTimeZone)
                    {
                        timeToUse = (bar[1] / 1000);
                    }

                    outputArray.push({
                        symbol: symbol.displayName,
                        timeframe: timePeriod,
                        timestamp: timeToUse,
                        open: bar[2],
                        high: bar[3],
                        low: bar[4],
                        close: bar[5]
                    });
                }

                trading4ProCallback(outputArray);

                return true;
            });
        }

    elementUpdated(element) {
        const { isLight, chartUp, chartDown } = this.props;
        if (this.subscription) {
            this.subscription.dispose();
            this.subscription = null;
        }

        if (this.trading4ProChartInstance) {
            this.trading4ProChartInstance.destroy();
            this.trading4ProChartInstance = null;
        }

        if (element) {
            const chartOptions = {
                ...defaultChartOptions,
                'colors.background': isLight ? '#fafafa' : '#13172D',
                // 'colors.frameActive': isLight ? '#ccc' : '#13172D',
                'colors.grid': isLight ? '#E9EBF3' : '#1C314E',
                'colors.candleRiseBorder': chartUp,
                'colors.candleFallBorder': chartDown,
                'colors.candleRise': chartUp,
                'colors.candleFall': chartDown,
                'colors.barRise': chartUp,
                'colors.barFall': chartDown,
                'colors.axisLine': isLight ? '#fafafa' : '#13172D',
                'system.gridDash': isLight ? [1, 0] : [1, 3],
                ...this.props.customChartOptions
            };

            const chart = new window.T4PChart(element, { 'toolbar.disable': true, ...chartOptions });
            if (this.props.serverTimeZone)
            {
                chart.setTimezone(this.props.serverTimeZone);
            }
            this.props.chartController.setChartInstance(chart);

            globalChartObj = chart;
            this.trading4ProChartInstance = chart;
            const local = this;

            let activeSymbolKey = null;
            let activeSymbolPeriod = null;
            chart.addEventHandler('onCandlesRequest', (symbol, timeframe, timestampFrom, timestampTo, count, callback) => {
                if (!timestampFrom && !timestampTo) {
                    if (activeSymbolKey !== symbol
                        || activeSymbolPeriod !== timeframe) {
                        let stateSymbol = local.props.symbols[symbol];
                        if (!stateSymbol) {
                            if (activeSymbolKey) {
                                setTimeout(() => {
                                    stateSymbol = local.props.symbols[activeSymbolKey];
                                    if (stateSymbol) {
                                        chart.setSymbol(stateSymbol.id);
                                        chart.setDisplayName(stateSymbol.displayName);
                                        chart.setTimeframe(activeSymbolPeriod);
                                        activeSymbolKey = null; // addEventHandler will be called when the symbol will be reset and we want to reload the chart
                                    }
                                }, 0);
                            }
                        } else {
                            activeSymbolKey = symbol;
                            activeSymbolPeriod = timeframe;
                            if (local.props.chartController) {
                                local.props.chartController.callChartUpdated(symbol, trade4ProToInternal[timeframe]);
                            }

                            if (local.props.symbols) {
                                const stateSymbol = local.props.symbols[symbol];
                                if (stateSymbol) {
                                    local.subscribeToCandles(stateSymbol, trade4ProToInternal[timeframe], count, timeframe, callback, chart);
                                }
                            }
                        }
                    }
                } else if (timestampFrom && !timestampTo) {
                    const currentSymbol = local.props.symbols[symbol];
                    if (currentSymbol) {
                        // let timestampFromQuery = timestampFrom*1000;
                        const timestampFromQuery = ChartService.getOldestBar(symbol, trade4ProToInternal[timeframe]);
                        ChartService.getMoreBars(currentSymbol.chart, trade4ProToInternal[timeframe], count, timestampFromQuery).then((t) => {
                            if (t === true) {
                                callback([]);
                            }
                        });
                    }
                }
            });

            this.updateChart(this.props);
        }
    }

    render() {
        return <div id='trading4pro-chart' style={{ height: '100%' }} ref={this.trading4ProRef} />;
    }
}

function mapStateToProps(state) {
    return { symbols: state.account?.symbols?.symbols,
        serverTimeZone: state.account.accountInfo.serverTimeZone
    };
}

export default connect(mapStateToProps)(ChartPanel);
