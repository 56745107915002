import React, { useCallback } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Virtual } from 'swiper';

import 'swiper/swiper-bundle.css';
import clsx from 'clsx';
import MobileCarouselSymbolTick from './MobileCarouselSymbolTick';
import MobileSymbolIcon from '../MobileSymbolIcon/MobileSymbolIcon';

SwiperCore.use([Virtual]);

const MobileCarouselSymbol = ({ options, value, onChange }) => {

  const handleChange = useCallback(swiper => {
    const index = swiper.activeIndex;
    const option = {
      index,
      value: options[index],
    };
    onChange && onChange(option);
  }, [options, onChange]);

  return (

    <div
      className={clsx('mobile-carousel-symbol',
        'gradient-carousel-symbol')}
    >

      <Swiper
        tag="div"
        wrapperTag="ul"
        slidesPerView={3}
        spaceBetween={0}
        centeredSlides={true}
        lazy={true}
        initialSlide={value}
        slideToClickedSlide={true}
        onSlideChange={handleChange}
        virtual
      >
        {options.map((option, index) => (
							<SwiperSlide
									tag="li"
									key={`slide-${index}`}
									virtualIndex={index}
							>
								{({isActive}) => (
										<>
											<div className="mobile-carousel-symbol__wrapper">
												<div
														className={clsx('mobile-carousel-symbol__item-flag',
																isActive ? 'active' : 'notActive')}
												>
													<MobileSymbolIcon
															name={option.displayName}
															flag={option.flag}
													/>
												</div>
												<p
														className={clsx('mobile-carousel-symbol__item-name',
																isActive && 'active')}
												>
													{option.displayName}
												</p>
											</div>
											<div
													className={clsx('mobile-carousel-symbol__tick',
															isActive && 'active')}
											>
												<MobileCarouselSymbolTick
														currentSymbol={option}
												/>
											</div>
										</>
								)}
							</SwiperSlide>
					))}
      </Swiper>
    </div>
  );
};

export default MobileCarouselSymbol;
