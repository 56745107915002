import React, {useCallback, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {shallowEqual, useSelector} from 'react-redux';

import {CommandFactory} from '../../background/commands/CommandFactory';

import useControls from '../context/contextHook/useControls';
import {createAvatarName, getCurrentAvatar} from '../helpers/options.helpers';
import {AVATARS} from '../constants/options';

import MobilePopUpHeader from './MobilePopUp/MobilePopUpHeader';
import MobilePopUp from './MobilePopUp/MobilePopUp';
import MobileButtonClose from './Buttons/MobileButtonClose';
import MobileButton from './Buttons/MobileButton';

import MobileCarouselAvatar from './MobileCarousel/MobileCarouselAvatar';
import MobileSwiper from './MobileSwiper';
import MobileLoader from './MobileLoader/MobileLoader';

const MobileChangeAvatar = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [currentAvatar, setCurrentAvatar] = useState('');

	const {t} = useTranslation();
	const touchRef = useRef(null);

	const {updateAvatarCommand} = useSelector(
			state => state.account.socialv1.registrationCommands, shallowEqual);

	const userDetails = useSelector(
			state => state.account.accountInfo.userDetails, shallowEqual);

	//TODO: ?? or
	const {userDetails: {avatar}} = useSelector(
			state => state.account.socialv1, shallowEqual);

	const {
		avatarControls: {isOpen, handleClose},
		burgerControls: {handleOpen},
	} = useControls();

	const handleChangeAvatar = useCallback(props => {
		const avatarName = createAvatarName(props.value.id);
		setCurrentAvatar(avatarName);
	}, [setCurrentAvatar]);

	const handleUpdateClick = useCallback(async () => {
		if (!currentAvatar || !updateAvatarCommand) return null;
		const userInput = {avatarUrl: currentAvatar};
		
		const command = CommandFactory.createCommand(updateAvatarCommand);
		setIsLoading(true);
		try {
			const res = await command.execute(userInput);
			if (!res.isSuccessful) {
				console.error('Profile update error'); //TODO: remove log add err
			}
			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
			console.error(err); //TODO: remove log add err
		}
	}, [currentAvatar, updateAvatarCommand, setIsLoading]);

	const handleCloseClick = useCallback(e => {
		e && e.preventDefault();
		e && e.stopPropagation();
		const t = setTimeout(() => {
			handleClose && handleClose(); //close avatar
			clearTimeout(t);
		}, 100);
		handleOpen && handleOpen(); //open burger
	}, [handleOpen, handleClose]);

	const currentValue = useMemo(() => {
		return getCurrentAvatar(avatar)?.index;
	}, [avatar]);

	const isVisibleAvatars = useMemo(() => {
		return typeof currentValue !== 'undefined';
	}, [currentValue]);

	return (
			<MobilePopUp isOpen={isOpen}>
				<MobileSwiper
						className="mobile-avatar"
						touchRef={touchRef}
						type='avatar'
				>
					{/*HEADER*/}
					<MobilePopUpHeader>
						<div
								ref={touchRef}
								className="mobile-avatar__header"
						>
							<div className="mobile-avatar__header__title">
								{/*TODO: locale this*/}
								Edit your profile picture
							</div>
							<div className="mobile-trade__header__close">
								<MobileButtonClose
										onClick={handleCloseClick}
								/>
							</div>
						</div>
					</MobilePopUpHeader>

					<MobileLoader
							isLoading={isLoading}
							size="large"
							overlay
					/>

					{/*MAIN SECTION*/}
					<div className="mobile-avatar__main">

						<div className="mobile-avatar__main__title">
							{/*TODO: locale this*/}
							{`Hi ${userDetails.name}!`}
						</div>

						<div className="mobile-avatar__main__subtitle">
							{/*TODO: locale this*/}
							Here you can change your profile picture
						</div>

						<div className="mobile-avatar__main__carousel">
							{isVisibleAvatars && (
									<MobileCarouselAvatar
											options={AVATARS}
											onChange={handleChangeAvatar}
											value={currentValue}
									/>
							)}
						</div>

						<div className="mobile-avatar__main__button">
							<MobileButton
									title={t('InstrumetInfo.Details.Update')}
									onClick={handleUpdateClick}
							/>
						</div>


					</div>

				</MobileSwiper>
			</MobilePopUp>
	);
};

export default MobileChangeAvatar;
