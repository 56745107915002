import React from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';

import { SendEventToGoogleAnalytics } from '../../../../background/storeListener/googleAnalyticsListener';

import MobileButton from '../../Buttons/MobileButton';


const MobileCopyBlockDisclaimer = ({ handleClose, masterNickname }) => {
    const { t } = useTranslation();

    SendEventToGoogleAnalytics('Profile:Copy:LFSDisclaimer:Block', { masterNickname });

    return (
        <div className='mobile-profile-copy__success'>
            <div className='mobile-profile-copy__success__wrapper'>
                <p>
                    <b>Warning:&nbsp;</b>
                    The Strategy you have selected is NOT in line with your Investment and Risk Profile and LFS does not recommend this Strategy/Trader for you.
                    We recommend you consider a strategy in your Investment and Risk Profile or below.
                </p>
                <p>
                    We would like you to note that past performance of all Trading strategies is not a guarantee, nor indicative of future returns.
                    There is a high level of risk involved with trading forex, cryptocurrencies, commodities, indices and other CFDs.
                    Funds placed under management should be risk capital funds that if lost will not significantly affect one’s personal financial well-being.
                </p>
            </div>
            <div className='mobile-profile-copy__success__button'>
                <MobileButton
                    title={t('General.Close')}
                    onClick={handleClose}
                />
            </div>
        </div>
    );
};

export default MobileCopyBlockDisclaimer;
