import React, { Fragment } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import DepositIcon from '../icons/DepositIcon';
import PlusRounded from '../icons/PlusRounded';
import depositButtonClickHandler from '../utilsUI/depositButtonClickHandler';


export default () => {
    const deposit = useSelector((state) => { return (state.account.accountInfo.featuresData.deposit); }, shallowEqual);
    const showMarginCallDisclaimer = useSelector((state) => { return (state.account.accountInfo.featuresData.showMarginCallDisclaimer); }, shallowEqual);
    const marginStatus = useSelector((state) => (state.account.financial.marginStatus), shallowEqual);
    const { Text: { GeneralColor } } = useSelector((state) => state.brand.theme);

    const shouldShowMarginAlert = (marginStatus === 1 || marginStatus === 2) && showMarginCallDisclaimer;

    if (!deposit.isEnabled) {
        return null;
    }

    const handleDepositClick = () => {
        depositButtonClickHandler(deposit?.depositUrl, deposit?.getDepositUrlCommand);
    };

    return (
        <div className={shouldShowMarginAlert ? 'btn btn-deposit btn-deposit--highlight' : 'btn btn-deposit'}>
            {shouldShowMarginAlert && (
                <Fragment>
                    <div className='triangle' />
                    <div className='plus-rounded'><PlusRounded fill='#CE4B4B' /></div>
                </Fragment>
            )}
            <div onClick={handleDepositClick} className='deposit-button' target='_blank' rel='noopener noreferrer'>
                <DepositIcon fill={shouldShowMarginAlert ? '#CE4B4B' : GeneralColor} />
            </div>
        </div>
    );
};
