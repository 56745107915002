/* eslint-disable complexity */
import React from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';

import timeAgo from '../utilsUI/formatTimeAgo';
import Format2Fixed from '../utilsUI/formatNumber';
import Button from '../primitives/Button';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';

import './SocialStream.css';


const SocialStreamItem = ({streamType, user, handleItemSelect, toggleOpenCopyTab, isClickable, isSmallDesktop }) => {
    const {
        AvatarURL,
        Nickname,
        NumberOfCopiedPositions,
        InstrumentName,
        OpenPrice,
        ClosePrice,
        OpenTime,
        CloseTime,
        TradeActionType,
        Country,
        TradeType,
        PendingType,
        PL,
        Price,
        PendingOpenTime,
        IsPartial,
        TimeStamp
    } = user;
    const { t } = useTranslation();
    let price = TradeType === 0 ? OpenPrice : ClosePrice;
    let time = TradeType === 0 ? timeAgo(OpenTime) : timeAgo(CloseTime);
    let translationId = TradeType === 0 ? 'Stream.User.Opened.Position' : 'Stream.User.Closed.Position';
    let actionType = TradeActionType === 0 ? t('Stream.Buy') : t('Stream.Sell');
    if (IsPartial) {
        translationId = 'Stream.User.Partial.Closed.Position';
    }
    if (TradeType === 2) {
        price = Price;
        time = timeAgo(PendingOpenTime);
        translationId = 'Stream.User.Pending.Position';
        switch (PendingType) {
            case 0:
                actionType = t('PendingOrder.Buy.Limit');
                break;
            case 1:
                actionType = t('PendingOrder.Sell.Limit');
                break;
            case 2:
                actionType = t('PendingOrder.Buy.Stop');
                break;
            case 3:
                actionType = t('PendingOrder.Sell.Stop');
                break;
            default:
                actionType = t('PendingOrder.Buy.Limit');
        }
    }
    let borderStyle;
    if (TradeType === 1) {
        borderStyle = 'stream__container white-border';
    } else if (TradeActionType === 0) {
        borderStyle = 'stream__container green-border';
    } else {
        borderStyle = 'stream__container red-border';
    }

    const streamText = t(translationId, {
        nickName: Nickname,
        actionType,
        instrument: InstrumentName,
        price
    });

    const openProfileCopy = () => {
        SendEventToGoogleAnalytics(`SocialStream:${streamType}:CopyClick`, {nickname: user?.Nickname});
        handleItemSelect({ Nickname: user?.Nickname, Avatar: user?.AvatarURL, shouldOpenCopy: true });
        toggleOpenCopyTab();
    };

    const openProfile = () => {
      SendEventToGoogleAnalytics(`SocialStream:${streamType}:ProfileClick`, {nickname: user?.Nickname});
      handleItemSelect({ Nickname: user?.Nickname, Avatar: user?.AvatarURL, shouldOpenCopy: false });
    };


    return (
        <div className={borderStyle}>
            <div className='stream__user-info' onClick={isClickable ? openProfile : null}>
                <div className={`avatar ${AvatarURL.toLowerCase()}`} />
                <div className='stream__user-name'>
                    {/* <h4>
                        <span>{Country}</span>
                    </h4> */}
                </div>
                {/* <div><Button className='btn white transparent no-outline' onClickHandler={shouldReload} iconStart='fas fa-redo-alt' /></div> */}
            </div>
            <div className='stream__info'>
                <div className='stream__info-data'>
                    {isSmallDesktop ? (
                        <span onClick={openProfileCopy}>{streamText}</span>
                    ) : (
                        streamText
                    )}
                    {PL > 0 && (
                        <div className='green stream__earning'>
                            {t('Stream.Earning')}
                            &nbsp;
                            {Format2Fixed(PL * 100)}
                            %
                        </div>
                    )}
                    {NumberOfCopiedPositions > 0 && PL > 0 && (
                        <div className='stream__copied-number'>
                            {NumberOfCopiedPositions}
                            &nbsp;copied this position
                        </div>
                    )}
                </div>
                {!isSmallDesktop && (
                    <div className='stream__time'>
                        <i className='far fa-clock' />
                        {time}
                    </div>
                )}
            </div>
            {isClickable && !isSmallDesktop && (
                <div className='stream__copy'>
                    <Button className='btn btn-stream-copy' iconStart='far fa-copy' onClickHandler={openProfileCopy} />
                </div>
            )}
        </div>
    );
};

// SocialStreamItem.propTypes = {};

export default SocialStreamItem;
