import {getStore} from '../storeContainer'
import {ProfitCalculationService} from '../calculations/profitCalculationService'

let updateFunction = function()
{
  ProfitCalculationService._recalAll();
}

export default function Init()
{
  let store = getStore();
    
  let currOpenPositions = store.getState().account.positions.open.positions;
  let currSymbols = store.getState().account.symbols.symbols;
  let currFinancial = store.getState().account.financial;
  
  store.subscribe(()=>{
    let previousValue   = currOpenPositions;
    let previousSymbols = currSymbols;
    let prevfinancial   = currFinancial;
    
    currOpenPositions = store.getState().account.positions.open.positions;
    currSymbols = store.getState().account.symbols.symbols;
    currFinancial = store.getState().account.financial;
    
    if (previousValue !== currOpenPositions ||
       previousSymbols !== currSymbols ||
       prevfinancial !== currFinancial) {
        updateFunction();
    };
  });
}