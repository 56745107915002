import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getSymbolFlag } from '../../../shared/FlagsData/symbolIconService';
import "../../../shared/FlagsData/symbol-icon.css";

const MobileSymbolIcon = props => {
  const { name, flag } = props;
  const { ActiveTheme } = useSelector((state) => (state.brand.themeSettings));

  const symbolIcon = getSymbolFlag(flag);

  return (
    <div className={ActiveTheme === 'dark' ? "symbol-icon-white" : "symbol-icon-black"}>
      {symbolIcon}
    </div>
  );
};


export default MobileSymbolIcon;
