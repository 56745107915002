import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Column, Table, SortDirection, List, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css';
// import PropTypes from 'prop-types';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';
import { formatDate } from '../utilsUI/formatTime';
import FormatProfitSwapCommision from '../utilsUI/FormatProfitSwapCommision';

const PositionsVirtualList = ({
    positionsToRender,
    setSelectedTrader,
    closeTradeLinks,
    showCopiedFrom,
    isBottomPanelPinned,
    subHeaderColor,
    systemColor,
    linkColor
}) => {
    const { t } = useTranslation();
    const { Profit: { Positive, Negative, Neutral } } = useSelector((state) => (state.brand.theme));

    const renderType = ({ cellData }) => {
        return cellData === 0 ? 'Buy' : 'Sell';
    };

    const renderTime = ({ cellData }) => {
        return formatDate(cellData);
    };

    const renderProfit = ({ cellData }) => {
        return <div><FormatProfitSwapCommision tickValue={cellData} positive={Positive} negative={Negative} neutral={Neutral} /></div>;
    };

    const renderCopied = ({ cellData }) => {
        let tradeLink = null;
        Object.keys(closeTradeLinks).forEach((key) => {
            if (key === cellData) {
                tradeLink = closeTradeLinks[key].sourceNickName;
            }
        });
        const openProfilePage = () => {
            setSelectedTrader({ nickname: tradeLink });
            SendEventToGoogleAnalytics("Drawer:Closed:ProfileClick",{masterNickname: tradeLink});
        };
        return (
            <span className='copied-from' style={linkColor} onClick={openProfilePage}>{tradeLink}</span>
        );
    };

    return (
        <div className='full-height' style={systemColor}>
            <AutoSizer disableHeight={!isBottomPanelPinned}>
                {({ height, width }) => (
                    <Table
                        width={width}
                        height={isBottomPanelPinned ? height : 210}
                        // height={isBottomPanelPinned ? height : 180}
                        headerHeight={33}
                        headerStyle={subHeaderColor}
                        rowHeight={35}
                        rowCount={positionsToRender.length}
                        rowGetter={({ index }) => positionsToRender[index]}
                    >
                        <Column label={t('InstrumetInfo.Details.Instrument')} dataKey='symbolName' width={126} className='' />
                        {/* <Column label={t('InstrumetInfo.Details.Type')} dataKey='type' width={60} className='' /> */}
                        <Column label={t('InstrumetInfo.Details.Type')} dataKey='actionType' width={60} cellRenderer={renderType} className='' />
                        <Column label={t('InstrumetInfo.Details.Amount')} dataKey='amount' width={100} className='' />
                        <Column label={t('InstrumetInfo.Details.OpenRate')} dataKey='openRate' width={100} className='' />
                        {/* <Column label={t('InstrumetInfo.Details.OpenTime')} dataKey='symbolName' width={195} className='' /> */}
                        <Column label={t('InstrumetInfo.Details.OpenTime')} dataKey='openTimeS' width={195} cellRenderer={renderTime} className='' />
                        <Column label={t('InstrumetInfo.Details.CloseRate')} dataKey='closeRate' width={100} className='' />
                        {/* <Column label={t('InstrumetInfo.Details.CloseTime')} dataKey='symbolName' width={195} className='' /> */}
                        <Column label={t('InstrumetInfo.Details.CloseTime')} dataKey='closeTimeS' width={195} cellRenderer={renderTime} className='' />
                        <Column label={t('InstrumetInfo.Details.Profit')} dataKey='profit' width={100} cellRenderer={renderProfit} className='' />
                        <Column label={t('InstrumetInfo.Details.Swap')} dataKey='swaps' width={100} cellRenderer={renderProfit} className='' />
                        <Column label={t('InstrumetInfo.Details.Commission')} dataKey='commission' width={100} cellRenderer={renderProfit} className='' />
                        {showCopiedFrom && (
                            <Column label={t('InstrumetInfo.Details.CopiedFrom')} dataKey='tpId' width={100} cellRenderer={renderCopied} className='' />
                        )}
                    </Table>
                )}
            </AutoSizer>
        </div>
    );
};

// PositionsVirtualList.propTypes = {};

export default PositionsVirtualList;
