import React from 'react';
import { withTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import './PasswordField.css';

class PasswordField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showPassword: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    }

    handleClickShowPassword = () => {
        this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
    }

    handleChange(e) {
        const { handlePasswordChange } = this.props;
        handlePasswordChange(e);
    }

    render() {
        const { showPassword } = this.state;
        const { password, errorMessage, t } = this.props;

        return (
            <FormControl fullWidth>
                <Input
                    id='standard-adornment-password'
                    // placeholder='password'
                    placeholder={t('Login.Form.Password')}
                    autoComplete='tpPassword'
                    name='tpPassword'
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={this.handleChange}
                    endAdornment={
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <InputAdornment position='end'>
                            <IconButton
                                aria-label='toggle password visibility'
                                onClick={this.handleClickShowPassword}
                                className='password-icon'
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
                { errorMessage && <p className='error__message'>{t(errorMessage)}</p>}
            </FormControl>
        );
    }
}

// PasswordField.propTypes = {};

export default withTranslation()(PasswordField);
