import React, {useCallback, useEffect, useState} from 'react';
import clsx from 'clsx';

import PubsubSubscription from '../../../background/repositories/PubsubSubscription';
import {prepareBidAsk} from '../../../UI/utilsUI/formatWSTick';

import {ACTION_TYPE} from '../../../UI/TradePopup/TradePopup';

const MobileOrderSwitch = ({name, style, onChange, selectedSymbol, type}) => {
	const [types, setTypes] = useState(type);
	const pipDigit = selectedSymbol.displayDigit;
	const [symbolTick, previousTick] = PubsubSubscription.subscribeObjAndPrev(
			selectedSymbol?.tick);
	const {bidOutput, askOutput} = prepareBidAsk(symbolTick, previousTick,
			pipDigit);

	const handleClick = useCallback(types => {
		setTypes(types);
	}, []);

	useEffect(() => {
		setTypes(type);
	}, [type]);

	useEffect(() => {
		onChange && onChange(types);
	}, [types]);

	return (
			<div
					name={name}
					className="mobile-order-switch"
					style={{...style}}
			>
				<div
						className='mobile-order-switch__list'
				>
					<div
							className={clsx('mobile-order-switch__item',
									types === ACTION_TYPE.ASK && 'active')}
							onClick={() => handleClick(ACTION_TYPE.ASK)}
					>
						<p className="mobile-order-switch__item__text">Buy</p>
						<div className="mobile-order-switch__item__text">{askOutput}</div>
					</div>
					<div
							className={clsx('mobile-order-switch__item',
									types === ACTION_TYPE.BID && 'active')}
							onClick={() => handleClick(ACTION_TYPE.BID)}
					>
						<p className="mobile-order-switch__item__text">Sell</p>
						<p className="mobile-order-switch__item__text">{bidOutput}</p>
					</div>
				</div>
				<div
						className={clsx('mobile-order-switch__wrapper',
								types === ACTION_TYPE.BID && 'right')}
				/>
			</div>
	);
};

export default MobileOrderSwitch;
