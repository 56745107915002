/* eslint-disable complexity */
import React, { Fragment, useState, useEffect, useRef } from 'react';
// import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

// import debounce from '../utilsUI/debounce';
import MainTreeView from '../../treeUI/MainTreeView';
import IndicatorsList from '../IndicatorsPopup/IndicatorsList';
import IndicatorsPopup from '../IndicatorsPopup/IndicatorsPopup';
import TradePopup from '../TradePopup/TradePopup';
import ToolTip from '../primitives/ToolTip';
import Button from '../primitives/Button';
import Modal from '../primitives/Modal';
import ChartPanel, { trade4ProToInternal, CHART_TYPES } from '../../shared/Charts/ChartPanel';
import { INDICATORS } from '../../shared/Charts/indicators';
import Candels from '../icons/Candels';
import GraphIcon from '../icons/GraphIcon';
import LineIcon from '../icons/LineIcon';
import AreaIcon from '../icons/AreaIcon';
import IndicatorsIcon from '../icons/IndicatorsIcon';
import ClearIcon from '../icons/ClearIcon';
import OpenPositionsOnChart from '../OpenPositionsOnChart';
import './CenterPanel.css';
import { symbol } from 'prop-types';


const CenterPanel = ({
  selectedSymbol,
  orderedSymbols,
  onSymbolSelect,
  mainChartController,
  centerWidth,
  setCenterWidth,
  shouldDisplayRightPanel,
  initialLeftWidth,
  initialRightWidth
}) => {
  const { t } = useTranslation();
  const { MainColor: { ActiveText, InactiveText }, Tick: { ChartUp, ChartDown }, Text: { SystemColor }, Trade: { ButtonFirst, ButtonSecond, BoxShadow }, Buttons: { TextColor } } = useSelector((state) => (state.brand.theme));
  const { ActiveTheme } = useSelector((state) => (state.brand.themeSettings));
  const { symbols } = useSelector((state) => (state.account.symbols), shallowEqual);
  const hasTick = symbols[selectedSymbol?.id]?.hasTick;
  const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isIndicatorsExpanded, setIsIndicatorsExpanded] = useState(false);
  const toolTip = useRef();
  const [chartType, setChartType] = useState(CHART_TYPES.CANDLES);
  const [indicator, setIndicator] = useState('');

  const resizeLayout = () => {
    const widthBody = document.getElementById('root').offsetWidth;
    if (shouldDisplayRightPanel) {
      setCenterWidth(widthBody - initialLeftWidth - initialRightWidth);
    } else {
      setCenterWidth(widthBody - initialLeftWidth);
    }
  };

  useEffect(() => {
    resizeLayout();
    // const debouncedResizeLayout = debounce(resizeLayout, 200);
    window.addEventListener('resize', resizeLayout);
    return () => window.removeEventListener('resize', resizeLayout);
  }, []);

  useEffect(() => {
    resizeLayout();
  }, [initialLeftWidth, centerWidth, initialRightWidth]);

  const popupToggle = () => {
    setIsPopupOpen((prevState) => { return !prevState; });
  };

  const handleChange = (e) => {
    onSymbolSelect(orderedSymbols.find(((item) => item.id === e.target.value)));
  };

  const timeFrames = [];

  Object.entries(trade4ProToInternal).forEach((item) => {
    timeFrames.push({ timeKey: item[1], timeValue: item[0] });
  });

  const [timeSelected, setTimeSelected] = useState(timeFrames[1]);

  mainChartController.setPeriodChangedCallback((period) => {
    if (timeSelected.timeKey !== period) {
      Object.entries(trade4ProToInternal).forEach((item) => {
        if (item[1] === period) {
          setTimeSelected({ timeKey: item[1], timeValue: item[0] });
        }
      });
    }
  });

  const handleTimeChange = (e) => {
    setTimeSelected(timeFrames.find((item) => item.timeKey === e.target.value));
  };

  const changeChartType = (e) => {
    setChartType(e.target.value);
  };

  const selectIndicator = (e) => {
    setIndicator(INDICATORS.find((item) => item.name === e.target.getAttribute('data-value')));
  };

  const handleUpdateIndicator = (newValue) => {
    mainChartController.addIndicator(newValue);
  };

  const toggleIndicatorModal = () => {
    setIndicator('');
  };

  const clearIndicators = () => {
    setIndicator('');
    setIsIndicatorsExpanded(false);
    toolTip.current.hide();
    mainChartController.clearIndicators();
  };

  const handleOnMouseOut = (evt) => {
    // eslint-disable-next-line no-unused-expressions
    evt && evt.stopPropagation();

    toolTip.current.hide();
    setIsIndicatorsExpanded((prevState) => !prevState);
  };

  const handleOnMouseOver = (evt) => {
    evt.stopPropagation();

    const el = evt.currentTarget;
    setIsIndicatorsExpanded((prevState) => !prevState);

    if (el != null) {
      const rect = el.getBoundingClientRect();
      toolTip.current.show(rect);
    }
  };

  const resetSearch = () => {
    const element = document.getElementById('trader-search');
    if (element) {
      element.blur();
    }
  };


    return (
        <div className='content panel' style={{ width: centerWidth }}>
            <div id='content-block'>
                {selectedSymbol && (
                    <Fragment>
                        <OpenPositionsOnChart selectedSymbol={selectedSymbol} mainChartController={mainChartController}/>
                        <div className='center-panel__actions-block'>
                            <div
                                className={isPopupOpen ? 'trade-button__gradient trade-button__gradient--active' : 'trade-button__gradient'}
                                style={{
                                    borderImageSource: `linear-gradient(to left, ${ButtonFirst}, ${ButtonSecond})`,
                                }}
                            >
                                <div
                                    className='trade-button'
                                    onClick={popupToggle}
                                >
                                    {t('Chart.Trade')}
                                </div>
                                {isPopupOpen && hasTick && (
                                    <TradePopup handleClick={popupToggle} onClose={popupToggle} selectedSymbol={selectedSymbol} mainChartController={mainChartController} />
                                )}
                            </div>
                            <div className='symbol-group'>
                                <Select
                                    id='select-symbol'
                                    IconComponent={KeyboardArrowDownIcon}
                                    name='select-symbol'
                                    value={selectedSymbol.id}
                                    onChange={handleChange}
                                    style={{ color: TextColor }}
                                >
                                    {orderedSymbols.map((option) => {
                                        return (
                                            <MenuItem key={option.displayName} value={option.id}>
                                                {/* {t(option.translatedName)} */}
                                                {option.id}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <Select
                                    id='select-timeframe'
                                    name='select-timeframe'
                                    value={timeSelected.timeKey}
                                    onChange={handleTimeChange}
                                    style={{ color: TextColor }}
                                >
                                    {timeFrames.map((option) => {
                                        return (
                                            <MenuItem key={option.timeKey} value={option.timeKey}>
                                                {option.timeValue}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </div>
                            <div className='center-panel__action-buttons'>
                                <Select
                                    id='select-chart'
                                    name='select-chart'
                                    value={chartType}
                                    onChange={changeChartType}
                                >
                                    <MenuItem key={CHART_TYPES.CANDLES} value={CHART_TYPES.CANDLES}>
                                        <Candels fill={TextColor || '#fff'} />
                                        <span>{t(`Select.Chart.${CHART_TYPES.CANDLES}`)}</span>
                                    </MenuItem>
                                    <MenuItem key={CHART_TYPES.BARS} value={CHART_TYPES.BARS}>
                                        <GraphIcon fill={TextColor || '#fff'} />
                                        <span>{t(`Select.Chart.${CHART_TYPES.BARS}`)}</span>
                                    </MenuItem>
                                    <MenuItem key={CHART_TYPES.LINE} value={CHART_TYPES.LINE}>
                                        <LineIcon fill={TextColor || '#fff'} />
                                        <span>{t(`Select.Chart.${CHART_TYPES.LINE}`)}</span>
                                    </MenuItem>
                                    <MenuItem key={CHART_TYPES.AREA} value={CHART_TYPES.AREA}>
                                        <AreaIcon fill={TextColor || '#fff'} />
                                        <span>{t(`Select.Chart.${CHART_TYPES.AREA}`)}</span>
                                    </MenuItem>
                                </Select>
                                <Button
                                    className='btn'
                                    onClickHandler={handleOnMouseOver}
                                    // iconStart='indicator-icon'
                                    iconStartSVG={<IndicatorsIcon fill={TextColor || '#fff'} width={30} height={25} className='icon-strat' />}
                                    ariaHaspopup='listbox'
                                    ariaExpanded={isIndicatorsExpanded}
                                    ariaControls='indicators'
                                />
                                {!isIndicatorsExpanded ? <ToolTip ref={toolTip} /> : (
                                    <ToolTip ref={toolTip} isOpen={!!indicator?.name}>
                                        <IndicatorsList selectIndicator={selectIndicator} handleClick={handleOnMouseOut} clearIndicators={clearIndicators} systemColor={SystemColor} />
                                    </ToolTip>
                                )}
                                <Button
                                    className='btn'
                                    // iconStart='clear-icon'
                                    iconStartSVG={<ClearIcon fill={TextColor || '#fff'} width={30} height={25} className='icon-strat' />}
                                    onClickHandler={clearIndicators}
                                />
                            </div>
                        </div>
                        <div className='chart__container' onClick={resetSearch}>
                            <ChartPanel
                                chartController={mainChartController}
                                activeSymbolId={selectedSymbol.id}
                                period={timeSelected.timeKey}
                                chartType={chartType}
                                chartUp={ChartUp}
                                chartDown={ChartDown}
                                isLight={ActiveTheme !== 'dark'}
                            // addIndicator={indicatorToApply}
                            // shouldClearIndicators={shouldClearIndicators}
                            // unsetShouldClearIndicators={unsetShouldClearIndicators}
                            // handleUpdateIndicator={handleUpdateIndicator}
                            />
                        </div>
                    </Fragment>
                )}
                {false && <MainTreeView/>}
                {indicator?.name && (
                    <Modal show heading={t(`Select.Indicator${indicator.name}`)} onClose={toggleIndicatorModal} className='update-indicator__popup outer-exclude'>
                        <IndicatorsPopup indicator={indicator} handleUpdateIndicator={handleUpdateIndicator} closeModal={toggleIndicatorModal} />
                    </Modal>
                )}
            </div>
        </div>
    );
};

// CenterPanel.propTypes = {
//     lastRemeberMeCMD: PropTypes.object
// };

export default CenterPanel;
