import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';

import formatDaysFromNow from '../../../../UI/utilsUI/formatDaysFromNow';

import MobileSocialExploreChart from './MobileSocialExploreChart';

import MobileAvatarIcon from '../../MobileAvatarIcon';
import MobileButtonIcon from '../../Buttons/MobileButtonIcon';
import MobileButton from '../../Buttons/MobileButton';

import MobileSocialExploreStatisticBlock
	from './MobileSocialExploreStatisticBlock';

const MobileSocialExploreItemDraw = props => {
	const {
		isActive,
		Avatar,
		userProfile,
		Nickname,
		CopiersCount,
		successRate,
		isLoadingWatch,
		isLoadingCopy,
		alreadyCopied,
		alreadyWatched,
		JoinDateTime,
		CumulativePnl,
		handleToggleIsOpen,
		handleOpenProfile,
		handleClickCopy,
		handleClickWatch,
	} = props;

	const {t} = useTranslation();

	const copyBtnTitle = useMemo(() => {
		const result = alreadyCopied
				? t('Social.Button.EditCopy')
				: t('Socials.Button.Copy');
		return result.toLowerCase();
	}, [alreadyCopied]);

	const watchBtnTitle = useMemo(() => {
		const result = alreadyWatched
				? t('Social.Button.Unwatch')
				: t('Social.Button.Watch');

		return result.toLowerCase();
	}, [userProfile, isLoadingWatch, alreadyWatched]);

	const daysFromNow = useMemo(() => {
		return formatDaysFromNow(new Date(JoinDateTime));
	}, [JoinDateTime]);

	const pnl = useMemo(() => {
		return Math.round((CumulativePnl + Number.EPSILON) * 10000) / 100;
	}, [CumulativePnl]);

	return (
			<div
					className={clsx('mobile-social-explore__item', isActive && 'open')}
			>
				<div className="mobile-social-explore__item__inner">

					<div
							onClick={handleToggleIsOpen}
							className="mobile-social-explore__item__row-head"
					>

						<div className="mobile-social-explore__item__row-head__inner">
							<div
									onClick={handleOpenProfile}
									className="mobile-social-explore__item__row-head__avatar"
							>
								<MobileAvatarIcon avatar={Avatar}/>
							</div>

							<div
									onClick={handleOpenProfile}
									className="mobile-social-watch__item__user__desc"
							>
								<p className="mobile-social-watch__item__user__nickname">{Nickname}</p>
								<div className="mobile-social-watch__item__user__state">
							<span className="mobile-social-watch__item__user__state__title">
								{t('Socail.Profile.Copiers').toLowerCase()}
								:&nbsp;
							</span>
									<span
											className="mobile-social-watch__item__user__state__count"
									>
								{CopiersCount}
							</span>
								</div>
							</div>

						</div>

						<div className="mobile-social-explore__item__row-head__more">
							<MobileButtonIcon
									onClick={handleToggleIsOpen}
									isActive={isActive}
									type="more"
							/>
						</div>
					</div>

					<div className="gradient_line"/>

					<div className="mobile-social-explore__item__row-statistics">
						<div
								className="mobile-social-explore__item__row-statistics__inner">

							<div
									onClick={handleToggleIsOpen}
									className="mobile-social-explore__item__row-statistics__row"
							>
								<div
										className={clsx(
												'mobile-social-explore__item__row-statistics__row__item',
												isActive && 'active')}
								>
									{/*TODO: locale this*/}
									<MobileSocialExploreStatisticBlock
											label='Days'
											number={daysFromNow}
											isActive={isActive}
									/>
								</div>

								<div
										className={clsx(
												'mobile-social-explore__item__row-statistics__row__item',
												isActive && 'active')}
								>
									<MobileSocialExploreStatisticBlock
											label={t('Socials.PNL')}
											number={`${pnl}%`}
											isActive={isActive}
											className={clsx(pnl < 0 && 'red')}
									/>
								</div>

								<div
										className={clsx(
												'mobile-social-explore__item__row-statistics__row__item',
												isActive && 'active')}
								>
									<MobileSocialExploreStatisticBlock
											label={t('Socials.SuccessRate')}
											number={successRate}
											isActive={isActive}
									/>
								</div>

								{!isActive && (
										<div
												className={clsx(
														'mobile-social-explore__item__row-statistics__row__item',
														isActive && 'active',
												)}
										>
											<MobileButtonIcon
													type='copy'
													isActive={alreadyCopied}
													onClick={handleClickCopy}
											/>
										</div>
								)}
							</div>

							<div className={clsx('mobile-social-explore__item__row-bott',
									isActive && 'active')}>
								{isActive && (
										<>
											<div
													className="mobile-social-explore__item__row-bott__chart"
											>
												<MobileSocialExploreChart
														userName={Nickname}
												/>
											</div>

											<div
													className="mobile-social-explore__item__row-bott__buttons">
												<div
														className="mobile-social-explore__item__row-bott__btn">
													<MobileButton
															title={watchBtnTitle}
															onClick={handleClickWatch}
															isLoading={isLoadingWatch}
															type="secondary"
													/>
												</div>
												<div
														className="mobile-social-explore__item__row-bott__btn">
													<MobileButton
															title={copyBtnTitle}
															onClick={handleClickCopy}
															isLoading={isLoadingCopy}
															type="primary"
													/>
												</div>
											</div>
										</>
								)}

							</div>

						</div>
					</div>

				</div>
			</div>
	);
};

export default MobileSocialExploreItemDraw;
