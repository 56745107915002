import React, { useState, useEffect, Fragment } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
// import PropTypes from 'prop-types';

import { CommandFactory } from '../../background/commands/CommandFactory';
import LoadSpinner from '../primitives/LoadSpinner';
import { TIMEFRAMES } from '../ProfilePage/PortfolioTab';
import ProfilePageRadioGroup from '../ProfilePage/ProfilePageRadioGroup';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';

import ExplorePageUsersList from './ExplorePageUsersList';


const ExplorePageMostProfitable = ({ location, handleItemSelect, limit, toggleOpenCopyTab, copyColor }) => {
    const [orderedTopTraders, setOrderedTopTraders] = useState([]);
    const [orderedTopThreeTraders, setOrderedTopThreeTraders] = useState([]);
    const [timeframe, setTimeframe] = useState(1);
    const [, setResponse] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { getMostProfitable } = useSelector((state) => { return (state.account.socialv1.exploreCommands); }, shallowEqual);

    const handleUserSelect = (data)=>{
      handleItemSelect(data, "MostProfitable");
    }

    const getMostProfitableUsers = async (time) => {

        const command = CommandFactory.createCommand(getMostProfitable);
        setIsLoading(true);

        try {
            setResponse(null);
            const res = await command.execute({
                timeframe: time || timeframe,
                limit
            });

            if (res.isSuccessful === true) {
                setOrderedTopThreeTraders(res.payload.slice(0, 3));
                setOrderedTopTraders(res.payload.slice(3, limit));
            } else if (res.isSuccessful === false) {
                setResponse(res);
            }
        } catch (err) {
            setResponse(err);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        getMostProfitableUsers();
        SendEventToGoogleAnalytics("Explore:MostProfitableTabOpened")
    }, []);

    const setTimeframeValue = function(value){
        setTimeframe(parseInt(value, 10));
        getMostProfitableUsers(parseInt(value, 10));
    };


    return (
        <div>
            <div className='explore-page__search-buttons'>
                <ProfilePageRadioGroup groupList={TIMEFRAMES} selectedItem={timeframe} handleSelectItem={setTimeframeValue} />
            </div>
            {isLoading ? (
                <LoadSpinner />
            ) : (
                <Fragment>
                    <ExplorePageUsersList
                    location={location}
                        users={orderedTopTraders}
                        topThreeUsers={orderedTopThreeTraders}
                        handleItemSelect={handleUserSelect}
                        toggleOpenCopyTab={toggleOpenCopyTab}
                        copyColor = {copyColor}
                    />
                </Fragment>
            )}
        </div>
    );
};

// ExplorePageMostProfitable.propTypes = {};

export default ExplorePageMostProfitable;
