export class CreateListener {
	constructor(ref, callback, stop = false) {
		this.ref = ref;
		this.callback = callback;
		this.isStop = stop;
	}

	init(ref, callback) {
		this.ref = ref;
		this.callback = callback;
	}

	stop() {
		this.isStop = true;
	}

	listener(e) {
		if (this.isStop) {
			e && e.preventDefault();
			e && e.stopPropagation();
		}
		this.callback && this.callback(e, this.ref);
	}

	get refs() {
		const r = this.ref;
		if (!r || !r.current) return null;
		return r.current;
	}

	add() {
		if (this.refs) {
			this.refs.addEventListener('click', this.listener.bind(this), true);
			this.refs.addEventListener('touchstart', this.listener.bind(this),
					{passive: false});
		}
	}

	remove() {
		if (this.refs) {
			this.refs.removeEventListener('click', this.listener.bind(this), true);
			this.refs.removeEventListener('touchstart', this.listener.bind(this),
					true);
		}
	}
}
