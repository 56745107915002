/* eslint-disable complexity */
import React from 'react';

import PubsubSubscription from '../../background/repositories/PubsubSubscription';

const formatCache = new Map();

export const roundNumberPipDigit = (value, pipDigit) => {
    if (value || value === 0) {
        let f = formatCache.get(pipDigit);
        if (!f) {
            f = new Intl.NumberFormat('en-US', { style: 'decimal', maximumFractionDigits: pipDigit, minimumFractionDigits: pipDigit });
            formatCache.set(pipDigit, f);
        }
        return f.format(value);
    }

    return '';
};

export const formatSymbolPrice = (newElem, oldElem, pipDigit, displayDigit, tickUp, tickDown, tickNeutral) => {
    if (displayDigit === undefined) {
        // eslint-disable-next-line no-param-reassign
        displayDigit = pipDigit;
    }

    // we dont support negative values....
    if (newElem < 0 || oldElem < 0) {
        return [];
    }

    const upDownStyle = newElem > oldElem ? 'up' : 'down';
    const digitStyle = newElem > oldElem ? { color: tickUp } : { color: tickDown };

    let stringValue = roundNumberPipDigit(newElem, displayDigit);
    let stringOldVlaue = roundNumberPipDigit(oldElem, displayDigit);

    let ignoreChars = 0;
    if (stringValue.length > stringOldVlaue.length) {
        ignoreChars = stringValue.length - stringOldVlaue.length;
        stringValue = stringValue.padStart('0', stringValue.length - stringOldVlaue.length);
    } else if (stringOldVlaue.length > stringValue.length) {
        stringOldVlaue = stringOldVlaue.padStart('0', stringOldVlaue.length - stringValue.length);
    }

    let strongIndex = 10000; // Not Strong
    if (pipDigit > 0) {
        const dotIndex = stringValue.indexOf('.');
        if (dotIndex >= 0) {
            // eslint-disable-next-line eqeqeq
            if (pipDigit == 1) {
                strongIndex = dotIndex - 1;
            } else {
                strongIndex = dotIndex + pipDigit - 1;
            }
        }
    }

    const digitArrays = [];
    let changed = false;
    for (let i = 0; i < stringValue.length; i += 1) {
        const newChar = stringValue.charAt(i);
        if (!changed) {
            const oldChar = stringOldVlaue.charAt(i);
            changed = newChar !== oldChar;
        }

        if (ignoreChars <= i) {
            const isPipDigit = i >= strongIndex && i <= strongIndex + 1;
            digitArrays.push(<strong className={`${changed ? upDownStyle : ''} ${isPipDigit ? 'pipDigit' : ''}`} style={changed ? digitStyle : { color: tickNeutral }} key={i + newChar}>{newChar}</strong>);
        }
    }

    return digitArrays;
};

export const prepareBidAsk = (symbolTick, previousTick, pipDigit, tickUp, tickDown, tickNeutral) => {
    if (!symbolTick) {
        return { bidOutput: (<div>--</div>), askOutput: (<div>--</div>) };
    }
    return {
        bidOutput: formatSymbolPrice(symbolTick.bid, previousTick.bid, pipDigit, undefined, tickUp, tickDown, tickNeutral),
        askOutput: formatSymbolPrice(symbolTick.ask, previousTick.ask, pipDigit, undefined, tickUp, tickDown, tickNeutral)
    };
};


const FormatWSTick = ({
    selectedSymbol, onBidClick, onAskClick, isBidActive, isTradePopup, tickUp, tickDown
}) => {
    const pipDigit = selectedSymbol.displayDigit;
    const [symbolTick, previousTick] = PubsubSubscription.subscribeObjAndPrev(selectedSymbol?.tick);
    const { bidOutput, askOutput } = prepareBidAsk(symbolTick, previousTick, pipDigit, tickUp, tickDown);
    const thumbClass = isBidActive ? 'trade-popup__thumb' : 'trade-popup__thumb trade-popup__thumb--active';

    return (
        <div className='trade-popup__bid-ask'>
            {isTradePopup && <div className={thumbClass} />}
            <div className='bid-item__bid' onClick={onBidClick}>
                <span>Sell</span>
                <div>{bidOutput}</div>
            </div>
            <div className='bid-item__ask' onClick={onAskClick}>
                <span>Buy</span>
                <div>{askOutput}</div>
            </div>
        </div>
    );
};

// FormatWSTick.propTypes = {};

export default FormatWSTick;
