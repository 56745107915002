import {useEffect, useMemo, useRef, useState} from 'react';

const useProviderControls = () => {

	// POSITION
	const [openPositionToId, setOpenPositionToId] = useState(0);

	// SECTION
	const [sectionHeight, setSectionHeight] = useState(0);

	const sectionControls = useMemo(() => {
		return {
			sectionHeight,
			setHeight: height => setSectionHeight(height),
		};
	}, [sectionHeight, setSectionHeight]);

	//FOOTER
	const footerRef = useRef(null);
	const [footerIsOpen, setFooterIsOpen] = useState(false);
	const [footerIsVisible, setFooterIsVisible] = useState(true);
	const [footerHeight, setFooterHeight] = useState(0);

	useEffect(() => {
		if (!footerRef || !footerRef.current) return;
		setFooterHeight(footerRef.current.offsetHeight);
	}, [footerRef, footerIsOpen]);

	const footerControls = useMemo(() => {
		return {
			isVisible: footerIsVisible,
			isOpen: footerIsOpen,
			footerRef: footerRef,
			height: footerHeight,
			setIsOpen: state => setFooterIsOpen(state),
			handleToggle: () => setFooterIsOpen(prev => !prev),
			handleOpen: () => {
				// close other
				setTradeIsOpen(false);
				setBurgerIsOpen(false);
				setTradingChartIsOpen(false);
				//open
				setFooterIsOpen(true);
			},
			handleClose: () => setFooterIsOpen(false),
			handleOnVisible: () => setFooterIsVisible(true),
			handleOnHide: () => setFooterIsVisible(false),
		};
	}, [
		footerIsOpen,
		footerRef,
		footerHeight,
		footerIsVisible,
		setFooterIsVisible]);

	//HEADER
	const headerRef = useRef(null);
	const [headerHeight, setHeaderHeight] = useState(0);

	useEffect(() => {
		if (!headerRef || !headerRef.current) return;
		setFooterHeight(headerRef.current.offsetHeight);
	}, [headerRef]);

	const headerControls = useMemo(() => {
		return {
			headerRef: headerRef,
			height: headerHeight,
		};
	}, [headerRef, headerHeight]);

	//BURGER
	const burgerRef = useRef(null);
	const [burgerIsOpen, setBurgerIsOpen] = useState(false);
	const [burgerOpenWidth, setBurgerOpenWidth] = useState(0);

	const burgerControls = useMemo(() => {
		return {
			isOpen: burgerIsOpen,
			burgerRef: burgerRef,
			burgerOpenWidth,
			onWidth: percent => setBurgerOpenWidth(percent),
			toggleIsOpen: () => setBurgerIsOpen(prev => !prev),
			setIsOpen: state => setBurgerIsOpen(state),
			handleOpen: () => {
				setBurgerOpenWidth(100);
				setBurgerIsOpen(true);
			},
			handleClose: () => {
				setBurgerOpenWidth(0);
				setBurgerIsOpen(false);
			},
		};
	}, [burgerIsOpen, burgerRef, burgerOpenWidth]);

	// TRADING CHART
	const [tradingChartIsOpen, setTradingChartIsOpen] = useState(false);

	const tradingChartControls = useMemo(() => {
		return {
			isOpen: tradingChartIsOpen,
			handleOpen: () => setTradingChartIsOpen(true),
			handleClose: () => setTradingChartIsOpen(false),
		};
	}, [tradingChartIsOpen]);

	// AVATAR
	const [avatarIsOpen, setAvatarIsOpen] = useState(false);

	const avatarControls = useMemo(() => {
		return {
			isOpen: avatarIsOpen,
			handleOpen: () => {
				// other close
				setBurgerIsOpen(false);
				// open
				setAvatarIsOpen(true);
			},
			handleClose: () => setAvatarIsOpen(false),
		};
	}, [avatarIsOpen]);

	//TRADE
	const [tradeIsOpen, setTradeIsOpen] = useState(false);
	const [tradeIsVisible, setTradeIsVisible] = useState(false);

	useEffect(() => {
		const isVisible = tradingChartIsOpen
				&& !footerIsOpen
				&& !burgerIsOpen
				&& !tradeIsOpen
				&& !avatarIsOpen;

		setTradeIsVisible(isVisible);
	}, [
		tradingChartIsOpen,
		footerIsOpen,
		burgerIsOpen,
		tradeIsOpen,
		avatarIsOpen]);

	const tradeControls = useMemo(() => {
		return {
			isOpen: tradeIsOpen,
			isVisible: tradeIsVisible,
			toggleIsOpen: () => setTradeIsOpen(prev => !prev),
			setIsOpen: state => setTradeIsOpen(state),
			handleOpen: () => {
				// other close
				setFooterIsOpen(false);
				setBurgerIsOpen(false);
				// open
				setTradeIsOpen(true);
			},
			handleClose: () => setTradeIsOpen(false),
		};
	}, [tradeIsOpen, tradeIsVisible]);

	return {
		burgerControls,
		headerControls,
		sectionControls,
		footerControls,
		tradingChartControls,
		avatarControls,
		tradeControls,
		positionControls: {
			openPosition: {
				toId: openPositionToId,
				setToId: setOpenPositionToId,
			},
			pendingOrders: {},
			closedPosition: {},
		},
	};
};

export default useProviderControls;
