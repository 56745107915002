import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import 'frappe-charts/dist/frappe-charts.min.css';

import CountryFlagByCode from '../Flag/CountryFlagByCode';
import Graph from '../primitives/Graph';
import Button from '../primitives/Button';
import LoadSpinner from '../primitives/LoadSpinner';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';

const SpotlightGroupType = {
    COPIERS: 0,
    ONEYEARPL: 1,
    ONEMONTHPL: 3
};


const SpotLight = ({ handleItemSelect }) => {
    const { spotlightTraders } = useSelector((state) => { return (state.account.socialv1); }, shallowEqual);
    const [data, setData] = useState(null);
    const [user, setUser] = useState(null);
    const [reloadUsers, setReloadUsers] = useState(false);
    const { t } = useTranslation();
    // const chartRef = useRef();

    useEffect(() => {
        const labels = [];
        const values = [];
        const pnl = user?.PnLGraph;
        let total = 0;
        if (pnl?.length) {
            pnl.forEach((item) => {
                labels.push(new Date(item.Date).getDate());
                total += (Math.round(item.NormalizedPL * 100000) / 100000);
                values.push(total);
            });
        }
        setData({ labels, values });
    }, [user, reloadUsers]);

    const reloadUser = () => {
        const keys = Object.keys(spotlightTraders);
        const randomProperty = spotlightTraders[keys[keys.length * Math.random() << 0]];
        if (randomProperty === user && keys.length > 1) {
            reloadUser();
        } else {
            setUser(randomProperty);
        }
    };

    useEffect(() => {
        reloadUser();
    }, [spotlightTraders, reloadUsers]);

    useEffect(() => {
      SendEventToGoogleAnalytics("SocialPanel:SpotlightTabOpened");
  }, []);

    const shouldReload = () => {
        setReloadUsers((prevState) => !prevState);
    };

    const openProfile = () => {
        SendEventToGoogleAnalytics("SocialPanel:Spotlight:ProfileClick", {nickname: user?.Nickname});
        handleItemSelect({ Nickname: user?.Nickname, Avatar: user?.Avatar, Copiers: user?.Copiers, JoinDate: user?.JoinDate });
    };

    if (!user) {
        return <div />;
    }

    const openProfileCopy = () => {
      SendEventToGoogleAnalytics("SocialPanel:Spotlight:CopyClick", {nickname: user?.Nickname});
      handleItemSelect({ Nickname: user?.Nickname, Avatar: user?.Avatar, Copiers: user?.Copiers, JoinDate: user?.JoinDate, shouldOpenCopy: true });
        // toggleOpenCopyTab();
    };


    return (
        <div className='spotlight__container'>
            <div className='spotlight__user-info'>
                <div className={`avatar ${user?.Avatar.toLowerCase()}`} onClick={openProfile} />
                <div className='spotlight__user-name'>
                    <h4>
                        {user?.Nickname}
                        <div className='country__flag--small'>
                            {user?.Country && CountryFlagByCode(user?.Country)}
                        </div>
                    </h4>
                    <div className='spotlight__copiers'>{`${t('Socials.Copiers')}: ${user?.Copiers}`}</div>
                </div>
                <div><Button className='btn white transparent no-outline' onClickHandler={shouldReload} iconStart='fas fa-redo-alt' /></div>
            </div>
            <div className='spotlight__chart'>
                {(data && data.labels.length > 0) && (
                    <Graph
                        type='line'
                        data={{
                            labels: data?.labels,
                            datasets: [{
                                values: data?.values
                            }]
                        }}
                        height={150}
                        onSelect={false}
                        heatline
                        region_fill
                        isNavigable={false}
                        lineOptions={{
                            hideDots: 1,
                            regionFill: 1,
                            areaFill: 1
                        }}
                        // ref={chartRef}
                    />
                )}
            </div>
            <div className='spotlight__bottom-data'>
                <div className='spotlight__profit'>
                    <div className='spotlight__profit__PL'>
                        {(user?.Profit * 100).toFixed(2)}
                        %
                    </div>
                    <div>PL (1M)</div>
                </div>
                <div className='added-reason'>
                    {user?.AddedReason === 0 && (
                        <div className='reason--copiers'>
                            <strong>Top 5</strong>
                            <p># of copiers</p>
                        </div>
                    )}
                    {user?.AddedReason === 1 && (
                        <div className='reason--year-pl'>
                            <strong>Top 5</strong>
                            <p>Yearly P/L</p>
                        </div>
                    )}
                    {user?.AddedReason === 3 && (
                        <div className='reason--month-pl'>
                            <strong>Top 5</strong>
                            <p>Monthly P/L</p>
                        </div>
                    )}
                </div>
            </div>
            <div className='top-three__copy'>
                <Button className='btn btn-top-three-copy' text={t('Socials.Button.Copy')} iconStart='far fa-copy' onClickHandler={openProfileCopy} />
            </div>
        </div>
    );
};

// SpotLight.propTypes = {};

export default SpotLight;
