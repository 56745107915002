import React, {useCallback, useState} from 'react';
import MobileButton from '../../Buttons/MobileButton';
import {useTranslation} from 'react-i18next';
import MobileLoader from '../../MobileLoader/MobileLoader';

const MobileProfileCopyUncopy = ({nickname = '', onClose, onAction}) => {
	const {t} = useTranslation();

	const [isLoading, setIsLoading] = useState(false);

	const handleClose = useCallback(() => {
		setIsLoading(false);
		onClose && onClose();
	}, [onClose]);

	const handleSuccess = useCallback(() => {
		setIsLoading(true);
		onAction && onAction()
				.then(() => setIsLoading(false))
				.catch(() => setIsLoading(false));
	}, [onAction, setIsLoading]);

	return (
			<>
				<MobileLoader isLoading={isLoading} size="large"/>

				<div className="mobile-profile-copy__uncopy">

					<div className="mobile-profile-copy__uncopy__scrollable">
						<p className="mobile-profile-copy__uncopy__head">
							{t('Social.ConfirmUncopy')}
						</p>
						<div className="mobile-profile-copy__uncopy__content">
							<p className="mobile-profile-copy__uncopy__content__head">
								{t('Social.Uncopy.DisclaimerHeader')}
								&nbsp;
							</p>
							<p className="mobile-profile-copy__uncopy__content__name">
								{nickname}
							</p>
							<p className="mobile-profile-copy__uncopy__content__disclaimer">
								{t('Social.Uncopy.Disclaimer')}
							</p>
						</div>
					</div>

					<div
							className="mobile-profile-copy__uncopy__button-wrapper"
					>
						<div className="mobile-profile-copy__uncopy__button">
							<MobileButton
									title={t('InstrumetInfo.Details.Cancel')}
									onClick={handleClose}
									type="secondary"
							/>
						</div>
						<div className="mobile-profile-copy__uncopy__button">
							<MobileButton
									title={t('Button.Uncopy')}
									onClick={handleSuccess}
									type="success"
							/>
						</div>
					</div>

				</div>

			</>
	);
};

export default MobileProfileCopyUncopy;
