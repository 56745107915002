export function replaceIfChanged(container, property, override) {
  if (container)
  {
    let state = container[property];
    if (isChanged(state,override))
    {
      container[property] = override;
    }
  }
}

function isChanged(state, override) {
  if (state === override) {
    return false;
  }
  else if (typeof override === 'object' && override !== null && override !== undefined) {
    if (state === null || state === undefined) {
      return true;
    }
    if (typeof state !== 'object' || state === null || state === undefined) {
      return true;
    }

    let keys = Object.keys(override);
    for (let i = 0; i < keys.length; i++) {
      if (isChanged(state[keys[i]], override[keys[i]]))
      {
        return true;
      }
    }
    return false;
  }
  else {
    return true;
  }
}

export function overrideState(state, override) {
  if (state === override) {
    return state;
  }
  else if (typeof override === 'object' && override !== null && override !== undefined) {
    if (state === null || state === undefined) {
      return override;
    }
    if (typeof state !== 'object' || state === null || state === undefined) {
      return override;
    }
    let returnObject = override;
    let hasAnyDiff = false;
    let keys = Object.keys(override);
    for (let i = 0; i < keys.length; i++) {
      let overridedeepState = overrideState(state[keys[i]], override[keys[i]]);
      if (overridedeepState !== state[keys[i]]) {
        hasAnyDiff = true;
      }
      returnObject[keys[i]] = overridedeepState;
    }
    if (!hasAnyDiff) {
      return state;
    }
    else {
      return returnObject;
    }
  }
  else {
    return override;
  }
}
