import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
// import PropTypes from 'prop-types';

import SearchIcon from '../icons/SearchIcon';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';

import AutoCompliteExpanded from './AutoCompliteExpanded';
import './AutoComplite.css';


const AutoComplite = ({
    autoCompliteResult,
    inputId,
    inputName,
    inputClassName,
    inputPlaceHolder,
    inputValue,
    isLoading,
    handleInputChange,
    handleItemSelect,
    resetSearch,
    generalButtonColor,
    generalColor
}) => {
    const { t } = useTranslation();
    const [shouldClose, setShouldClose] = useState(false);
    const icon = inputValue ? (
        <InputAdornment position='start'>
            <Icon className='fas fa-times' onClick={resetSearch} />
        </InputAdornment>
    ) : (
        <InputAdornment position='start'>
            {/* <Icon className='fas fa-search' /> */}
            <SearchIcon fill={generalColor?.color} />
        </InputAdornment>
    );

    const handleSelect = (e) => {
        let nickName = e.target.getAttribute('name');
        SendEventToGoogleAnalytics("SocialPanel:Search:ProfileClick", {nickname: nickName})
        handleItemSelect({ Nickname: nickName, Avatar: e.target.getAttribute('data-avatar') });
    };

    const handleClose = () => {
        setShouldClose(true);
    };

    const handleOpen = () => {
        setShouldClose(false);
    };


    return (
        <div className='autocomplete__container'>
            <Input
                id={inputId}
                placeholder={t(inputPlaceHolder)}
                name={inputName}
                className={inputClassName}
                value={inputValue}
                onChange={handleInputChange}
                onFocus={handleOpen}
                startAdornment={icon}
                style={generalButtonColor}
                autoComplete='off'
            />
            <AutoCompliteExpanded autoCompliteResult={autoCompliteResult} isLoading={isLoading} handleSelect={handleSelect} handleClick={handleClose} shouldClose={shouldClose} />
        </div>
    );
};

// AutoComplite.propTypes = {};

export default AutoComplite;
