import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import clsx from 'clsx';

import {ACTION_TYPE} from '../../../../UI/TradePopup/TradePopup';
import { CommandFactory } from '../../../../background/commands/CommandFactory';

import useSymbol from '../../../context/contextHook/useSymbol';
import useTheme from '../../../context/contextHook/useTheme';

import PubsubSubscription from '../../../../background/repositories/PubsubSubscription';
import {roundNumberPipDigit} from '../../../../UI/utilsUI/formatWSTick';
import countDecimals from '../../../../UI/utilsUI/countDecimals';
import roundToPrecision from '../../../../UI/utilsUI/roundToPrecision';
import {calcPipValue} from '../MobileTradeMain';
import chartController from '../../../../background/chart/chartController';


import UnitsDropDown from '../../../../UI/TradePopup/UnitsDropDown';
import * as TradePopupAnalyticsEvents
	from '../../../../UI/TradePopup/TradePopupAnalyticsEvents';

import {MobileTradeDown, MobileTradeUnitsUp} from './MobileTradeInfo';
import MobileLoader from '../../../components/MobileLoader/MobileLoader';
import MobileTradeProcessing from './MobileTradeProcessing';
import MobileTradeSuccess from './MobileTradeSuccess';
import MobileTradeError from './MobileTradeError';
import MobileTradeScrollable from './MobileTradeScrollable';
import MobileTradeDescription from './MobileTradeDescription';
import MobileOrderSwitch
	from '../../../components/MobileSwitch/MobileOrderSwitch';
import MobileTransition from '../../../components/MobileTransition';
import InputStepper from '../../../../UI/primitives/InputStepper';
import calcMarginRequirement
	from '../../../../UI/utilsUI/calcMarginRequirement';
import MobileButton from '../../../components/Buttons/MobileButton';
import useControls from '../../../context/contextHook/useControls';
import MobileTradeTwo from './MobileTradeTwo';

const mainChartController = new chartController();

const MobileTradeSection = () => {
	const {selectedSymbol} = useSymbol();
	const {theme} = useTheme();
	const {
		tradeControls,
		footerControls: {isVisible, handleOnVisible, handleOnHide},
	} = useControls();

	const {
		tick,
		contractSize,
		minimumLot,
		maximumLot,
		lotStep,
	} = useMemo(() => {
		return selectedSymbol;
	}, [selectedSymbol]);

	const {symbol} = useSelector(state => state.account.accountInfo.currency,
		shallowEqual);

	// STATE
	const [type, setType] = useState(ACTION_TYPE.ASK);
	const [isProcessing, setIsProcessing] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [focusPending, setFocusPending] = useState(false);
	const [pendingOrder, setPendingOrder] = useState(false);
	const [pendingOrderPrice, setPendingOrderPrice] = useState(null);
	const [requestSuccess, setRequestSuccess] = useState(false);
	const [showError, setShowError] = useState('');
	const [response, setResponse] = useState('');
	const [unitsDropDown, setUnitsDropDown] = useState(false);
	const [orderInfo, setOrderInfo] = useState({status: '', price: null});
	const [selectedUnits, setSelectedUnits] = useState({
		number: Math.max(minimumLot, lotStep),
		representation: Math.max(minimumLot, lotStep),
	});

	const tickObj = PubsubSubscription.subscribeObj(tick);
	const pnlTick = PubsubSubscription.subscribeObj(
		{key: 'MOVINGPNL', repId: 'ACCOUNT_PNL'});

	// MEMO
	const {
		SubHeader: {Text},
		MainColor: {ActiveText, InactiveText},
		Text: {GeneralColor, SystemColor},
	} = useMemo(() => {
		return theme;
	}, [theme]);

	const {unitsName, convertionHeader} = useMemo(() => {
		const {contractSize, serverUnits, contractUnitType} = selectedSymbol;
		let unitsName = '';
		let convertionHeader = '';
		if (contractSize !== 1) {
			unitsName = serverUnits || 'unit';
			convertionHeader = `1 ${unitsName} = ${roundNumberPipDigit(contractSize)}
			 ${contractUnitType || ''}`;
		} else {
			unitsName = selectedSymbol?.contractUnitType || 'unit';
		}
		return {
			unitsName,
			convertionHeader,
		};
	}, [selectedSymbol]);

	const isOpenProcessing = useMemo(() => {
		return isProcessing || isLoading;
	}, [isProcessing, isLoading]);

	const lotPipNumber = useMemo(() => {
		return countDecimals(lotStep);
	}, [lotStep]);

	const isBidType = useMemo(() => {
		return type === ACTION_TYPE.BID;
	}, [type]);

	const marginRequirement = useMemo(() => {
		const {number} = selectedUnits;
		return calcMarginRequirement(tickObj, type, selectedSymbol, number);
	}, [tickObj, type, selectedSymbol, selectedUnits.number]);

	const pipValue = useMemo(() => {
		const {number} = selectedUnits;
		return calcPipValue(tickObj, selectedSymbol, number, type);
	}, [tickObj, selectedSymbol, selectedUnits, type]);

	const buttonStyle = useMemo(() => {
		const isBid = type === ACTION_TYPE.BID;
		return {
			backgroundColor: showError
				? ''
				: isBid ? '#DB6161' : '#1CB5EE',
		};
	}, [type, showError]);

	const buttonClass = useMemo(() => {
		return clsx(
			'mobile-open-order',
			showError && 'no-pointer',
			showError && type === ACTION_TYPE.BID && 'bid',
			showError && type !== ACTION_TYPE.BID && 'ask',
		);
	}, [showError, type]);

	const pendingOrderText = useMemo(() => {
		let txt = '';
		if (pendingOrder) {
			if (type === ACTION_TYPE.BID) {
				txt = pendingOrderPrice <= tickObj?.bid ?
					`SELL Stop ${selectedSymbol?.id} ${selectedUnits.representation} at ${pendingOrderPrice}` :
					`SELL Limit ${selectedSymbol?.id} ${selectedUnits.representation} at ${pendingOrderPrice}`;
			} else {
				txt = pendingOrderPrice > tickObj?.ask ?
					`BUY Stop ${selectedSymbol?.id} ${selectedUnits.representation} at ${pendingOrderPrice}` :
					`BUY Limit ${selectedSymbol?.id} ${selectedUnits.representation} at ${pendingOrderPrice}`;
			}
		}
		return txt;
	}, [pendingOrder, type, pendingOrderPrice, tickObj]);

	// CALLBACK
	const onChangeSwitch = useCallback(type => {
		if (!type) return null;
		setType(type);
	}, [type]);

	const handleSetValue = useCallback(units => {
		setShowError('');
		const decimals = countDecimals(units.replace(/[^0-9\.]/g, ''));
		const unitsValue = decimals === 0
			? units.replace(/[^0-9\.]/g, '')
			: roundNumberPipDigit(units, decimals);
		setSelectedUnits(
			{
				number: roundToPrecision(unitsValue, decimals),
				representation: units,
			});
	}, []);

	const handleShowError = useCallback(error => {
		setShowError(error);
	}, []);

	const openUnitsDropDown = useCallback(() => {
		response && setResponse('');
		setUnitsDropDown(true);
	}, [response]);

	const toggleUnitsDropDown = useCallback(() => {
		setUnitsDropDown(prev => !prev);
	}, []);

	const handleSwitchChange = useCallback(() => {
		response && setResponse('');
		setPendingOrderPrice(isBidType
			? PubsubSubscription.getValueNative(tick).bid
			: PubsubSubscription.getValueNative(tick).ask);
		setPendingOrder(prev => !prev);
	}, [response, isBidType, tick]);

	const cancelOrder = useCallback(() => {
		setResponse('');
		setType('');
	}, []);

	const handleSetPendingPrice = useCallback(units => {
		response && setResponse('');
		setPendingOrderPrice(units);
	}, [response]);

	const handleOnClose = useCallback(e => {
		e && e.preventDefault();
		if (!tradeControls) return null;
		const {handleClose} = tradeControls;
		handleClose && handleClose();
	}, [tradeControls]);

	const focusPendingOrder = useCallback(isFocus => {
		setFocusPending(isFocus);
	}, []);

	// HANDLERS
	const handleOpenPosition = useCallback(async e => {
		e && e.preventDefault();

		const commandObj = {
			cmdId: selectedSymbol.openPositionCMD.cmdId,
			staticParams: selectedSymbol.openPositionCMD.staticParams,
			storeRef: selectedSymbol.openPositionCMD.storeRef,
			userInput: {
				actionType: isBidType ? 1 : 0,
				amount: selectedUnits.number,
			},
		};
		const command = CommandFactory.createCommand(commandObj);
		setOrderInfo(prev => ({
			...prev,
			price: isBidType
				? PubsubSubscription.getValueNative(tick).bid
				: PubsubSubscription.getValueNative(tick).ask,
		}));
		setIsProcessing(true);

		const sendOpenPositionEventFunc = TradePopupAnalyticsEvents.createOpenPositionEvent(
			tickObj, roundNumberPipDigit(pipValue, 2),
			roundNumberPipDigit(marginRequirement, 2), selectedSymbol.displayName,
			selectedUnits.number, type, mainChartController);

		try {
			setResponse(null);
			const res = await command.execute();

			if (res.isSuccessful) {
				sendOpenPositionEventFunc(false, true, res.price, res.result);
				setOrderInfo(prev => ({
					...prev,
					status: 'success',
					price: res.price,
				}));
			} else {
				sendOpenPositionEventFunc(false, false, 0,
					res.result ?? 'trading.error.GeneralError');
				setResponse(res);
			}
		} catch (err) {
			sendOpenPositionEventFunc(false, false, 0, 'trading.error.GeneralError');
			setResponse(err);
		}

		setIsProcessing(false);
	}, [
		selectedSymbol,
		isBidType,
		selectedUnits,
		tick,
		tickObj,
		pipValue,
		marginRequirement,
		type,
		mainChartController]);

	const handlePendingOrder = useCallback(async e => {
		e && e.preventDefault();

		const commandObj = {
			...selectedSymbol.openPendingOrderCMD,
			userInput: {
				actionType: isBidType ? 1 : 0,
				amount: selectedUnits.number,
				price: pendingOrderPrice,
			},
		};

		const command = CommandFactory.createCommand(commandObj);
		setIsLoading(true);
		const sendPendingOrderEventFunc = TradePopupAnalyticsEvents.createPendingOrderEvent(
			tickObj, roundNumberPipDigit(pipValue, 2),
			roundNumberPipDigit(marginRequirement, 2), selectedSymbol.displayName,
			selectedUnits.number, type, pendingOrderPrice, mainChartController);

		try {
			setResponse(null);
			const res = await command.execute();

			if (res.isSuccessful) {
				sendPendingOrderEventFunc(false, true, res.result);
				setOrderInfo(
					prev => ({...prev, status: 'success', price: pendingOrderPrice}));
			} else {
				sendPendingOrderEventFunc(false, false,
					res.result ?? 'trading.error.GeneralError');
				setResponse(res);
			}
		} catch (err) {
			sendPendingOrderEventFunc(false, false, 'trading.error.GeneralError');
			setResponse(err);
		}

		setIsLoading(false);
	}, [selectedSymbol, isBidType, selectedUnits, pendingOrderPrice]);

	useEffect(() => {
		if (orderInfo && orderInfo.status === 'success') {
			setRequestSuccess(true);
		}
	}, [orderInfo]);

	useEffect(() => {
		if (!!unitsDropDown || !!focusPending) {
			handleOnHide && handleOnHide();
		} else {
			handleOnVisible && handleOnVisible();
		}
		return () => {
			handleOnVisible && handleOnVisible();
		};
	}, [unitsDropDown, focusPending]);

	return (
		<div className={clsx('mobile-trade-popup',
			requestSuccess && 'trade-popup--no-pointer')}
		>
			{/*processing*/}
			<MobileTradeProcessing isOpen={isOpenProcessing}/>

			{/*loading*/}
			<MobileLoader isLoading={isLoading} overlay/>

			{/*success*/}
			<MobileTradeSuccess
				isOpen={requestSuccess}
				price={orderInfo?.price}
				pendingOrder={pendingOrder}
				onClose={handleOnClose}
			/>

			<MobileTradeScrollable unitsDropDown={unitsDropDown}>
				{isVisible && (
					<div className="mobile-trade-popup__switch">
						<MobileOrderSwitch
							selectedSymbol={selectedSymbol}
							type={type}
							onChange={onChangeSwitch}
						/>
					</div>
				)}

				{!focusPending && (
					<>
						<MobileTradeDescription/>

						<MobileTransition
							isOpen={selectedSymbol?.serverUnits &&
							selectedSymbol?.contractUnitType}
							type="fade"
						>
							<div className='mobile-trade-popup__units'>
								{convertionHeader}
							</div>
						</MobileTransition>

						<MobileTradeUnitsUp
							unitsDropDown={unitsDropDown}
							pipValue={pipValue}
							marginRequirement={marginRequirement}
						/>

						<InputStepper
							selectedUnits={selectedUnits.representation}
							stepValue={lotStep}
							setValue={handleSetValue}
							min={minimumLot}
							max={maximumLot}
							pipDigit={lotPipNumber}
							showError={handleShowError}
							errorMessage='Input.Error.Amount.Incorrect'
							checkDivider
							isError={showError}
							onFocusAction={openUnitsDropDown}
							id='units-stepper'
							shouldSelect={unitsDropDown}
							inputDescription={selectedSymbol?.serverUnits}
						/>

						{unitsDropDown && (
							<div className='trade-popup__units-list'>
								<UnitsDropDown
									selectUnits={handleSetValue}
									toggleUnitsDropDown={openUnitsDropDown}
									handleClick={toggleUnitsDropDown}
									selectedUnits={selectedUnits.number}
									contractSize={contractSize}
									stepValue={lotStep}
									tickObj={tickObj}
									isBidActive={isBidType}
									unitsName={unitsName}
									selectedSymbol={selectedSymbol}
									pnlTick={pnlTick}
									currency={symbol}
								/>
							</div>
						)}

						{/*error*/}
						<MobileTradeError showError={showError}/>

						<MobileTradeDown
							pipValue={pipValue}
							marginRequirement={marginRequirement}
						/>
					</>
				)}

				<MobileTradeTwo
					focusPendingOrder={focusPendingOrder}
					pendingOrder={pendingOrder}
					handleSwitchChange={handleSwitchChange}
					cancelOrder={cancelOrder}
					handleOpenPosition={handleOpenPosition}
					price={orderInfo?.price}
					status={requestSuccess}
					type={type}
					isPending={pendingOrder}
					tick={tick}
					pendingOrderPrice={pendingOrderPrice}
					handleSetValue={handleSetPendingPrice}
					selectedSymbol={selectedSymbol}
					selectedUnits={selectedUnits.number}
					tickObj={tickObj}
					setOrderInfo={setOrderInfo}
					setIsLoading={setIsLoading}
					setResponse={setResponse}
					response={response}
					minimumLot={minimumLot}
					maximumLot={maximumLot}
					shouldBlockButton={!!showError}
					subHeaderColor={{color: Text}}
					activeTextColor={{color: ActiveText}}
					inactiveTextColor={{color: InactiveText}}
					generalColor={{color: GeneralColor}}
					systemColor={{color: SystemColor}}
				/>
			</MobileTradeScrollable>

			{/*buttons*/}
			<MobileTransition
				isOpen={!requestSuccess}
				type="fade"
			>
				<div className='mobile-order-buttons'>
					{pendingOrder && (
						<MobileButton
							onClick={handlePendingOrder}
							className={buttonClass}
							style={{...buttonStyle}}
						>
							{pendingOrderText}
						</MobileButton>
					)}
					{!pendingOrder && (
						<MobileButton
							onClick={handleOpenPosition}
							className={buttonClass}
							style={{...buttonStyle}}
						>
							{type === ACTION_TYPE.BID ?
								`Sell ${selectedSymbol?.id} ${selectedUnits.representation} at ${tickObj?.bid}` :
								`Buy ${selectedSymbol?.id} ${selectedUnits.representation} at ${tickObj?.ask}`}
						</MobileButton>
					)}
				</div>
			</MobileTransition>
		</div>
	);
};

export default MobileTradeSection;
