import React, {useMemo} from 'react';
import useSymbol from '../../../context/contextHook/useSymbol';

const MobileTradeDescription = () => {
	const {selectedSymbol} = useSymbol();

	const description = useMemo(() => {
		return selectedSymbol?.description || '';
	}, [selectedSymbol]);

	return (
		<div className="mobile-trade-popup__lot">
			<p>{description}</p>
		</div>
	);
};

export default MobileTradeDescription;
