import React, {useCallback, useEffect, useState} from 'react';

const useWindowSize = () => {
	const [windowSize, setWindowSize] = useState({
		innerWidth: undefined,
		innerHeight: undefined,
		clientWidth: undefined,
		clientHeight: undefined,
	});

	const handleResize = useCallback(() => {
		setWindowSize(prev => ({
			...prev,
			innerWidth: window.innerWidth,
			innerHeight: window.innerHeight,
			clientWidth: document.body.clientWidth,
			clientHeight: document.body.clientHeight,
		}));
	}, [setWindowSize]);

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		handleResize();

		return () => window.removeEventListener('resize', handleResize);
	}, [handleResize]);

	return {
		...windowSize,
		width: windowSize.innerWidth,
		height: windowSize.innerHeight,
	};
};

export default useWindowSize;
