// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react';


const _callbacks = new Set();

const enqueueCallbacks = function(limitedList){
  let startLength = _callbacks.size;
  _callbacks.add(limitedList);
  if (startLength === 0)
  {
    setTimeout(()=>{
      let callbacks = Array.from( _callbacks.values());
      _callbacks.clear();
      for (let current of callbacks)
      {
        current._internalCallBacks();
      }
    },0);
  }

}

export default class LimitedSizeList
{
  #_buffer = new Map();
  #_callbacks = new Map();
  #_size;
  #_callbackIndex = 0;
  #_isInCallback = false;
  #_itemsToDelete = [];
  #_identityFunc;
  #_ageIndex = -1;
  
  #_ageArray;

  constructor(identityFunc, size)
  {
    this.#_identityFunc = identityFunc;
    this.#_size = size;
    this.#_ageArray = new Array(this.#_size); 
  }

  *getStackOrder()
  {
    for (let i = 0;i<this.#_size;i++)
    {
      if ((this.#_ageIndex -i) >= 0)
      {
        let currValue = (this.#_ageIndex -i) % this.#_size;
        yield this.#_buffer.get(this.#_ageArray[currValue]);
      }
    }
  }

  getUnordered()
  {
    return this.#_buffer.values();
  }

  subscribe(callback)
  {
    let  index = this.#_callbackIndex++;
    this.#_callbacks.set(index,
      {
        deleted:false,
        callback:callback,
      });
    let local = this;
    return ()=>{
      if (local.#_isInCallback)
      {
        let currCallback = local.#_callbacks.get(index);
        if (currCallback)
        {
          currCallback.deleted = true;
          local.#_itemsToDelete.push(index);
        }
      }
      else
      {
        local.#_callbacks.delete(index);
      }
    }
  }

  subscribeReact(callback)
  {
    let disposable = null;

    disposable = this.subscribe(()=>{
      callback();
      disposable();
    });
    
    useEffect(() => {
      return function cleanup() {
        disposable();
      };
    });
    return disposable;
  }

  pushItem(item)
  {
    let id = this.#_identityFunc(item);
    let foundItem = this.#_buffer.get(id)
    if (foundItem)
    {
      this.#_buffer.set(id,item);
    }
    else
    {
      this.#_ageIndex++;
      let current =  this.#_ageIndex;
      let ageIndex = current % this.#_size;
      if (ageIndex < current)
      {
        let oldItemKey = this.#_ageArray[ageIndex];
        this.#_buffer.delete(oldItemKey)
      }

      this.#_ageArray[ageIndex] = id;
      this.#_buffer.set(id,item);
    }

    if (this.#_callbacks.size)
    {
      enqueueCallbacks(this);
    }
  }

  _internalCallBacks()
  {
    this.#_isInCallback = true;    
    for (const item of Array.from(this.#_callbacks.values())) {
      if (!item.deleted)
      {
        try
        {
          item.callback();
        }
        catch
        {

        }
      }
    }

    if (this.#_itemsToDelete.length)
    {
      for (let itemToDelete of this.#_itemsToDelete)
      {
        this.#_buffer.delete(itemToDelete);
      }
      this.#_itemsToDelete = []; // clear the list
    }
    this.#_isInCallback = false;
  }

}