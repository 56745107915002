import React, {useCallback, useRef} from 'react';

const MobileSlidePosition = ({isSlide, children, threshold}) => {
	const rowRef = useRef();

	const maxScroll = useCallback(item => {
		if (!item) return threshold;
		return item.scrollWidth - item.clientWidth;
	}, [threshold]);

	const handleSwipeLeft = useCallback(() => {
		const item = rowRef?.current;
		const rect = item?.getBoundingClientRect();
		item && item.scrollTo({
			top: 0,
			left: rect.width,
			behavior: 'smooth',
		});
	}, [rowRef]);

	const handleSwipeRight = useCallback(() => {
		const item = rowRef?.current;
		const rect = item?.getBoundingClientRect();
		item && item.scrollTo({
			top: 0,
			left: -rect.width,
			behavior: 'smooth',
		});
	}, [rowRef]);

	const handleSwipe = useCallback((start, end) => {
		const item = rowRef?.current;
		if (!item) return null;
		const maxS = maxScroll(item);
		if (end - start < -(maxS * 0.75)) {
			handleSwipeLeft();
		} else {
			handleSwipeRight();
		}
	}, [rowRef, maxScroll]);

	const handleStart = useCallback(event => {
		const startX = event.touches[0].clientX;

		const handleEnd = evt => {
			const endX = evt.changedTouches[0].clientX;
			handleSwipe(startX, endX);
			document.removeEventListener('touchend', handleEnd);
		};

		document.addEventListener('touchend', handleEnd);
	}, [handleSwipe]);

	return (
			<div
					ref={rowRef}
					onTouchStart={handleStart}
					className="mobile-slide-position"
			>
				{children}
			</div>
	);
};

MobileSlidePosition.defaultProps = {
	isSlide: true,
	threshold: 30,
};

export default MobileSlidePosition;
