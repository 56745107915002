import { CommandFactory } from "./CommandFactory";
import {adjustURL}  from './mobileUrlService'

export const STDCMDServerCmdID = "STD.CMD.Server"; 

export async function STDCMDServerHandler(cmd,userInput)
{
  let staticData = cmd.getStatic();
  let storeData = cmd.getStoreData();

  if (!userInput)
  {
    userInput = cmd.getUserInput();
  }
  
  if (staticData.postUrl)
  {
    try
    {
      let response = await fetch(adjustURL(staticData.postUrl),{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          'superweb-session':window.sessionStorage.getItem('superweb-session') ?? ''
        },
        body: JSON.stringify({
          userInput: userInput,
          staticData: staticData,
          storeData: storeData})
        });

        if (!response.ok){
          // Server Error
          return response;
        }
        else{
          let data = await response.json();
          if (data)
          {
            if (CommandFactory.isCommand(data))
            {
              var res = CommandFactory.createCommand({cmdId:data.cmdId,storeRef:data.storeRef, staticParams:{type:data.storeDispatch}, userInput:data.payload}).execute();
              if (res)
              {
                await res;
              }
            }
          }

          return data;
        }
    }
    catch
    {
      // Server Error
      return null;
    }
  }

  // Invalid CMD
  return null;
} 