import React, {useCallback} from 'react';

import MobileAutosizer from '../../MobileAutosizer/MobileAutosizer';
import MobileSocialWatchItem from './MobileSocialWatchItem';

const MobileSocialWatch = props => {
	const {
		options = [],
		handleUnwatch,
		handleCopy,
		handleOpen,
	} = props;

	const renderRow = useCallback(data => {
		const {item, props} = data;

		return (
				<MobileSocialWatchItem
						{...props}
						{...item}
				/>
		);
	}, []);

	return (
			<div className="mobile-social">
				<MobileAutosizer
						handleCopy={handleCopy}
						handleUnwatch={handleUnwatch}
						handleOpen={handleOpen}
						options={options}
						renderRow={renderRow}
						rowHeight={70}
						className="mobile-social-watch__item"
				/>
			</div>
	);
};

export default MobileSocialWatch;
