import {useCallback, useEffect, useMemo, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';

const useProviderSymbol = () => {
	const {symbols, symbolsOrder, pinSymbols} = useSelector(
			state => state.account.symbols, shallowEqual);

	const [selectedSymbol, setSelectedSymbol] = useState(null);
	const [allSymbols, setAllSymbols] = useState(null);

	// const getAllSymbols = useMemo(() => {
	// 	const temp = [];
	// 	for (const symbolsToDisplay in symbolsOrder) {
	// 		const foundSymbol = symbols[symbolsOrder[symbolsToDisplay]];
	// 		if (foundSymbol) {
	// 			temp.push(foundSymbol);
	// 		}
	// 	}
	// 	return temp.sort((a, b) => b.isPin - a.isPin);
	// }, [symbols, symbolsOrder]);

	const getAllSymbols = useMemo(() => {
		if (!symbols && !symbolsOrder) return null;
		const temp = [];
		const pinnedTemp = [];
		let symbolsToShow = new Set();

		for (const symbolsToDisplay in symbolsOrder) {
			symbolsToShow.add(symbolsOrder[symbolsToDisplay]);
			const foundSymbol = symbols[symbolsOrder[symbolsToDisplay]];
			if (foundSymbol) {
				if (!foundSymbol.isPin) {
					temp.push(foundSymbol);
				}
			}
		}

		for (const symbolsToDisplay in pinSymbols) {
			const foundSymbol = symbols[pinSymbols[symbolsToDisplay]];
			if (foundSymbol) {
				if (symbolsToShow.has(foundSymbol.id)) {
					if (foundSymbol.isPin) {
						pinnedTemp.push(foundSymbol);
					}
				}
			}
		}

		return pinnedTemp.concat(temp);
	}, [symbols, symbolsOrder, pinSymbols]);

	const setInitialSymbol = useCallback(symbols => {
		if (selectedSymbol) return;
		setSelectedSymbol(symbols[0]);
	}, [selectedSymbol, setSelectedSymbol]);

	const setInitialSymbols = useCallback(symbols => {
		if (allSymbols) return;
		setAllSymbols(symbols);
	}, [allSymbols, setAllSymbols]);

	useEffect(() => {
		setInitialSymbol(getAllSymbols);
		setInitialSymbols(getAllSymbols);
	}, [getAllSymbols, setInitialSymbol, setInitialSymbols]);

	return {
		selectedSymbol,
		setSelectedSymbol,
		orderedSymbols: allSymbols,
		setOrderedSymbols: setAllSymbols,
		symbols,
	};
};

export default useProviderSymbol;
