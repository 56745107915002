import React, {useCallback} from 'react';
import {shallowEqual, useSelector} from 'react-redux';

import {CommandFactory} from '../../background/commands/CommandFactory';

import MobileSocialWatch
	from '../components/MobileSocial/MobileSocialWatch/MobileSocialWatch';
import useProfile from '../context/contextHook/useProfile';
import useCommunityFetch from '../hook/useFetchData/useCommunityFetch';
import MobileLoader from '../components/MobileLoader/MobileLoader';
import MobileNothingFound from '../components/MobileEmpty/MobileNothingFound';

const MobileSocialWatchPage = ({isVisible}) => {
	const {profileUser: {openProfileUser}} = useProfile();

	// const {tradersIWatch} = useStreamConnect(); //TODO: mb this array

	const {updateFollower, removeFollower} = useSelector(
			state => state.account.socialv1.copyRelationsCommands, shallowEqual);

	const {userDetails} = useSelector(state => state.account.socialv1,
			shallowEqual);

	const {data, isLoading} = useCommunityFetch(userDetails?.nickname, 0);

	const handleUnwatch = useCallback(async ({Nickname}) => {
		if (!Nickname || !updateFollower || !removeFollower) return null;
		const userInput = {
			guruNickname: Nickname,
			amount: 0,
			copyType: 0,
		};

		const command = CommandFactory.createCommand(removeFollower);

		try {
			//TODO: add err handler
			return await command.execute(userInput);
		} catch (err) {
			console.error(err); //TODO: remove log
		}
	}, [updateFollower, removeFollower]);

	const handleOpen = useCallback(name => {
		if (!name) return null;
		openProfileUser && openProfileUser(name);
	}, [openProfileUser]);

	const handleCopy = useCallback(name => {
		openProfileUser && openProfileUser(name, true);
	}, []);

	return (
			<>
				{isVisible && (
						<>
							<MobileLoader isLoading={isLoading} size="large"/>

							{data?.UserFriends && !data?.UserFriends.length && !isLoading && (
									<MobileNothingFound/>
							)}

							{data?.UserFriends && !!data?.UserFriends.length && (
									<MobileSocialWatch
											handleUnwatch={handleUnwatch}
											handleCopy={handleCopy}
											handleOpen={handleOpen}
											options={data?.UserFriends}
											type="watch"
									/>
							)}
						</>
				)}
			</>
	);
};

export default MobileSocialWatchPage;
