import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import Button from '../primitives/Button';


const ProfilePageRadioGroup = ({ groupList, selectedItem, handleSelectItem }) => {
    const { MainColor } = useSelector((state) => (state.brand.theme), shallowEqual);
    // const profileFilterButtonActive = {
    //     color: MainColor.ActiveText
    // };
    // const profileFilterButton = {
    //     // backgroundColor: '#283B55',
    //     color: MainColor.InactiveText
    // };
    const RadioButtonsGroup = Object.entries(groupList).map(([key, value]) => {
        return (
            <Button
                text={value}
                dataName={key}
                key={key}
                className={parseInt(key, 10) === selectedItem ? 'btn active btn-timeframe' : 'btn btn-timeframe'}
                // style={parseInt(key, 10) === selectedItem ? profileFilterButtonActive : profileFilterButton}
                onClickHandler={() => handleSelectItem(key)}
            />
        );
    });

    return (
        <div>
            {RadioButtonsGroup}
        </div>
    );
};

export default ProfilePageRadioGroup;
