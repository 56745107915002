import React, {useCallback, useState} from 'react';

import {PROFILE_COMMUNITY_MENU} from '../../../constants/options';

import MobileChartSwitch from '../../MobileSwitch/MobileChartSwitch';
import CarouselTabList from '../../MobileCarousel/CarouselTabList';
import MobileProfileCommunityWrapper from './MobileProfileCommunityWrapper';

const MobileProfileCommunity = ({nickname}) => {
	const [timeframe, setTimeframe] = useState(1); //TODO: return to 1

	const handleTimeChange = useCallback(({timeKey}) => {
		setTimeframe && setTimeframe(timeKey);
	}, [setTimeframe]);

	return (
			<div className="mobile-profile-community">
				<MobileChartSwitch
						options={PROFILE_COMMUNITY_MENU}
						onChange={handleTimeChange}
						value={timeframe}
						type='profile'
				/>
				<CarouselTabList
						index={timeframe - 1}
						classname="hT50"
				>
					<MobileProfileCommunityWrapper nickname={nickname} kind={1}/>
					<MobileProfileCommunityWrapper nickname={nickname} kind={0}/>
					<MobileProfileCommunityWrapper nickname={nickname} kind={3}/>
					<MobileProfileCommunityWrapper nickname={nickname} kind={2}/>
				</CarouselTabList>
			</div>
	);
};

export default MobileProfileCommunity;
