import React from 'react';

import MobileRiskLevelNew from './MobileRiskLevelNew';
import MobileRiskLevelOld from './MobileRiskLevelOld';


const MobileRiskLevelContainer = ({ level, title, label, yearPnl, isExternal }) => {
    if (isExternal) {
        return <MobileRiskLevelNew externalRiskScore={yearPnl} title={title} />;
    }

    return (
        <MobileRiskLevelOld
            level={level}
            title={title}
            label={label}
        />
    );
};

export default MobileRiskLevelContainer;
