import React from 'react';

const MobilePieChartLabel = props => {
	const {
		cx,
		cy,
		midAngle,
		outerRadius,
		fill,
		name,
		label,
		isDarkTheme,
	} = props;

	const RADIAN = Math.PI / 180;
	const sin = Math.sin(-RADIAN * midAngle);
	const cos = Math.cos(-RADIAN * midAngle);
	const sx = cx + (outerRadius) * cos;
	const sy = cy + (outerRadius) * sin;
	const mx = cx + (outerRadius + 10) * cos;
	const my = cy + (outerRadius + 10) * sin;
	const ex = mx + (cos >= 0 ? 1 : -1) * 22;
	const ey = my;
	const textAnchor = cos >= 0 ? 'start' : 'end';

	return (
			<g>
				<path
						d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
						stroke={fill}
						fill="none"
				/>
				<circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
				<text
						className="chart-pie__label-text"
						dominantBaseline="central"
						x={ex + (cos >= 0 ? 1 : -1) * 12}
						y={ey}
						dy={-6}
						textAnchor={textAnchor}
						// fill={fill}
						fill={isDarkTheme ? '#fff' : '#283B55'}
				>
					{label}
				</text>
				<text
						className="chart-pie__label-text"
						dominantBaseline="central"
						x={ex + (cos >= 0 ? 1 : -1) * 12}
						y={ey}
						dy={6}
						textAnchor={textAnchor}
						// fill="#fff"
						fill={isDarkTheme ? '#fff' : '#283B55'}
				>
					{name}
				</text>
			</g>
	);
};

export default MobilePieChartLabel;
