import React, {useCallback, useRef} from 'react';

import usePosition from '../../../context/contextHook/usePosition';

import MobileClosePositionHeader from './MobileClosePositionHeader';
import MobilePopUp from '../../MobilePopUp/MobilePopUp';
import MobileClosePositionMain from './MobileClosePositionMain';
import MobileSwiper from '../../MobileSwiper';

const MobileClosePosition = () => {
	const {positionClose: {closeData, handleCloseHide}} = usePosition();

	const touchRef = useRef(null);

	const handleClose = useCallback(() => {
		handleCloseHide && handleCloseHide();
	}, [handleCloseHide]);

	return (
			<MobilePopUp
					isOpen={!!closeData}
					onClose={handleClose}
			>
				<MobileSwiper
						touchRef={touchRef}
						className="mobile-position-popup"
						type='positionClose'
				>
					<MobileClosePositionHeader
							reference={touchRef}
							id={closeData?.tpId}
					/>
					{closeData && (
							<MobileClosePositionMain
									{...closeData}
									handleClose={handleClose}
							/>
					)}
				</MobileSwiper>
			</MobilePopUp>
	);
};

export default MobileClosePosition;
