import React, {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';

import {getCurrentPositionsAll} from '../../../helpers/position.helpers';
import useSymbol from '../../../context/contextHook/useSymbol';
import MobileOpenPositionHead
	from '../../MobilePosition/MobileOpenPosition/MobileOpenPositionHead';
import MobileAutosizer
	from '../../../components/MobileAutosizer/MobileAutosizer';
import usePosition from '../../../context/contextHook/usePosition';
import MobileOpenPositionItem
	from '../../MobilePosition/MobileOpenPosition/MobileOpenPositionItem';

const MobileTradingChartPosition = () => {
	const {selectedSymbol} = useSymbol();
	const {positionUpdate: {handleUpdateOpen}} = usePosition();

	const tradeLinks = useSelector(state => state.account.socialv1.tradeLinks);

	const isSocialEnabled = useSelector(
			(state) => state.account.socialv1.isEnabled);
	const isSocialVisible = useSelector(
			(state) => state.account.accountInfo.rightPanelSettings.socialv1.isVisible);

	const statePositions = useSelector(
			state => state.account.positions.open.positions);

	const showCopiedFrom = useMemo(() => {
		return isSocialEnabled && isSocialVisible;
	}, [isSocialEnabled, isSocialVisible]);

	const positions = useMemo(() => {
		return getCurrentPositionsAll(statePositions, selectedSymbol?.id)
				.sort((a,b) => b.openTimeS-a.openTimeS);
	}, [selectedSymbol, statePositions]);

	const isEmpty = useMemo(() => {
		return !positions || !positions?.length;
	}, [positions]);

	const renderColumns = useMemo(() => {
		return [
			'instrument',
			'type',
			'amount',
			'profit',
			'user',
			'close',
		];
	}, [positions]);

	const updateOrderDate = useCallback(props => {
		handleUpdateOpen && handleUpdateOpen(props);
	}, [handleUpdateOpen]);

	const renderRow = useCallback(data => {
		const {item, props} = data;
		return (
				// <MobileTradingChartPositionItem
				// 		{...item}
				// 		{...props}
				// />
				<MobileOpenPositionItem
						{...item}
						{...props}
						columns={renderColumns}
				/>
		);
	}, [positions, statePositions, selectedSymbol]);

	return (
			<div className="mobile-chart-position">
				{isEmpty && (
						<div className="mobile-chart-position__empty">
							{/*TODO: locale this*/}
							No Open Position on {selectedSymbol.displayName}
						</div>
				)}
				{!isEmpty && (
						<>
							{/*<MobileOpenPositionHead*/}
							{/*		columns={renderColumns}*/}
							{/*		type="chart"*/}
							{/*/>*/}
							<MobileOpenPositionHead
									columns={renderColumns}
							/>
							<div className="mobile-chart-position__list">
								<MobileAutosizer
										tradeLinks={tradeLinks}
										showCopiedFrom={showCopiedFrom}
										updateOrderDate={updateOrderDate}
										options={positions}
										rowHeight={30}
										renderRow={renderRow}
										className="mobile-open-position__row"
										// positionType="chart"
										positionType="open"
								/>
							</div>
						</>
				)}
			</div>
	);
};

export default MobileTradingChartPosition;
