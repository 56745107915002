export const normalizedEditNumber = function(editValue,originalValue)
{
  let value =editValue ?? 0;
  if (typeof value === 'string')
  {
      if (editValue == ''){
          value = 0;
      }
      else
      {
          value = parseFloat(editValue.replace(",", ""));
      
          if (isNaN(value)){
                  value = originalValue;
              }
      }
  }  

  return value;
}