export function getPathObject(node, path) {
  let currentNode = node;
  for (let i = 0; i < path.length; i++) {
    let key = path[i];
    if (currentNode === null || currentNode === undefined) {
      return null;
    }
    else {
      currentNode = currentNode[key];
    }
  }
  return currentNode;
}
