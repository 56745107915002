import React, {useCallback, useMemo, useState} from 'react';
import MobileButton from '../../components/Buttons/MobileButton';
import {useTranslation} from 'react-i18next';
import { CommandFactory } from '../../../background/commands/CommandFactory'
import {shallowEqual, useSelector} from 'react-redux';
import useProfile from '../../context/contextHook/useProfile';
import useProfileUserData from '../../hook/useFetchData/useProfileUserData';

const MobileProfileButtonBlock = props => {
	const {
		nickname,
		onWatch,
	} = props;

	const {
		copiedUser: {handleCopiedOpen},
	} = useProfile();

	const {userProfile, requestData} = useProfileUserData(nickname);

	const [isLoadingWatch, setIsLoadingWatch] = useState(false);
	const [isLoadingCopy, setIsLoadingCopy] = useState(false);

	const {updateFollower, removeFollower} = useSelector(
			state => state.account.socialv1.copyRelationsCommands, shallowEqual);

	const {t} = useTranslation();

	const alreadyCopied = useMemo(() => {
		return userProfile?.CopyType === 1 || userProfile?.CopyType === 2;
	}, [userProfile]);

	const alreadyWatched = useMemo(() => {
		return userProfile?.CopyType === 4 || alreadyCopied;
	}, [userProfile, alreadyCopied]);

	const watchBtnTitle = useMemo(() => {
		const result = alreadyWatched
				? t('Social.Button.Unwatch')
				: t('Social.Button.Watch');

		return result.toLowerCase();
	}, [alreadyWatched]);

	const copyBtnTitle = useMemo(() => {
		const result = alreadyCopied
				? t('Social.Button.EditCopy')
				: t('Socials.Button.Copy');
		return result.toLowerCase();
	}, [alreadyCopied]);

	const handleClickWatch = useCallback(async () => {
		if (!nickname) return null;
		const userInput = {
			guruNickname: nickname,
			amount: alreadyWatched ? 0 : 1,
			copyType: alreadyWatched ? 0 : 4,
		};

		let commandObj = {};
		if (alreadyWatched) {
			commandObj = {
				...removeFollower,
				userInput,
			};
		}
		if (!alreadyWatched) {
			commandObj = {
				...updateFollower,
				userInput,
			};
		}
		const command = CommandFactory.createCommand(commandObj);
		setIsLoadingWatch(true);
		try {
			const res = await command.execute();
			if (res.isSuccessful) {
				requestData && requestData().then(() => {
					setIsLoadingWatch(false);
				});
				if (!alreadyWatched) {
					onWatch && onWatch(nickname);
				}
			}
		} catch (err) {
			console.error(err);
			setIsLoadingWatch(false);
		}
	}, [
		alreadyWatched,
		nickname,
		updateFollower,
		removeFollower,
		setIsLoadingWatch,
		requestData,
	]);

	const handleClickCopy = useCallback(() => {
		handleCopiedOpen && handleCopiedOpen();
	}, [handleCopiedOpen]);

	return (
			<div className="mobile-profile__content__button">
				<div
						className="mobile-social-explore__item__row-bott__buttons">
					<div
							className="mobile-social-explore__item__row-bott__btn">
						<MobileButton
								title={watchBtnTitle}
								onClick={handleClickWatch}
								isLoading={isLoadingWatch}
								type="secondary"
						/>
					</div>
					<div
							className="mobile-social-explore__item__row-bott__btn">
						<MobileButton
								title={copyBtnTitle}
								onClick={handleClickCopy}
								isLoading={isLoadingCopy}
								type="primary"
						/>
					</div>
				</div>
			</div>
	);
};

export default MobileProfileButtonBlock;
