import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import MobileAvatarIcon from '../../MobileAvatarIcon';
import MobileButtonRow from '../../Buttons/MobileButtonRow';

const MobileSocialCopyItem = props => {
	const {
		Avatar,
		Nickname,
		NumberOfCopiedPositions,
		CopiersCount,
		handleOpen,
		handleUncopy,
	} = props;
	const {t} = useTranslation();

	const handleClickOpen = useCallback(e => {
		e && e.preventDefault();
		e && e.stopPropagation();
		handleOpen && handleOpen(Nickname);
	}, [handleOpen, Nickname]);

	const handleClickUncopy = useCallback(() => {
		handleUncopy && handleUncopy(Nickname);
	}, [handleUncopy, Nickname]);

	const getCopiers = useMemo(() => {
		return NumberOfCopiedPositions || CopiersCount || 0;
	}, [NumberOfCopiedPositions, CopiersCount]);

	return (
			<>
				<div
						className="mobile-social-watch__item__inner"
						onClick={handleClickOpen}
				>

					<div className="mobile-social-watch__item__user">
						<div className="mobile-social-watch__item__user__avatar">
							<MobileAvatarIcon avatar={Avatar || ''}/>
						</div>
						<div className="mobile-social-watch__item__user__desc">
							<p className="mobile-social-watch__item__user__nickname">{Nickname}</p>
							<div className="mobile-social-watch__item__user__state">
							<span className="mobile-social-watch__item__user__state__title">
								{t('Socail.Profile.Copiers').toLowerCase()}
								:&nbsp;
							</span>
								<span
										className="mobile-social-watch__item__user__state__count"
								>
								{getCopiers}
							</span>
							</div>
						</div>
					</div>

					<div className="mobile-social-copy__item__controls">
						<MobileButtonRow
								title={t('Button.Uncopy')}
								onClick={handleClickUncopy}
						/>
					</div>

				</div>
			</>
	);
};

export default MobileSocialCopyItem;
