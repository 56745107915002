import React from 'react';
// import PropTypes from 'prop-types';

const DepositIcon = ({ fill }) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='11' height='22' viewBox='0 0 11 22' fill='none'>
            <path d='M7.46729 12.8457C7.46729 12.3753 7.31787 11.988 7.01904 11.6836C6.72021 11.3737 6.22217 11.097 5.5249 10.8535C4.82764 10.6045 4.28532 10.3748 3.89795 10.1646C2.60856 9.47282 1.96387 8.44906 1.96387 7.09326C1.96387 6.17464 2.24333 5.41927 2.80225 4.82715C3.36117 4.23503 4.1193 3.88363 5.07666 3.77295V1.96338H6.40479V3.78955C7.36768 3.9279 8.11198 4.3374 8.6377 5.01807C9.16341 5.6932 9.42627 6.57308 9.42627 7.65771H7.41748C7.41748 6.96045 7.25977 6.4126 6.94434 6.01416C6.63444 5.61019 6.2111 5.4082 5.67432 5.4082C5.14307 5.4082 4.72803 5.55208 4.4292 5.83984C4.13037 6.1276 3.98096 6.53988 3.98096 7.07666C3.98096 7.55811 4.1276 7.94548 4.4209 8.23877C4.71973 8.52653 5.22331 8.80046 5.93164 9.06055C6.63997 9.32064 7.19613 9.56136 7.6001 9.78271C8.00407 10.0041 8.3444 10.2586 8.62109 10.5464C8.89779 10.8286 9.11084 11.1551 9.26025 11.5259C9.40967 11.8966 9.48438 12.3311 9.48438 12.8291C9.48438 13.7643 9.19661 14.5225 8.62109 15.1035C8.05111 15.6846 7.25423 16.0277 6.23047 16.1328V17.7515H4.91064V16.1411C3.81494 16.0194 2.97103 15.6265 2.37891 14.9624C1.79232 14.2983 1.49902 13.4185 1.49902 12.3228H3.51611C3.51611 13.02 3.69043 13.5596 4.03906 13.9414C4.39323 14.3232 4.89128 14.5142 5.5332 14.5142C6.16406 14.5142 6.64274 14.362 6.96924 14.0576C7.30127 13.7533 7.46729 13.3493 7.46729 12.8457Z' fill={fill} />
        </svg>
    );
};

// DepositIcon.propTypes = {};

export default DepositIcon;
