import React from 'react';
import countryFlags from './index';

const CountryFlagByCode = (countryCode) => {
    return (
        <div>
            <img src={countryFlags[countryCode.toLowerCase()]} alt={`${countryCode} flag`} title={`${countryCode} flag`} />
        </div>
    );
};

export default CountryFlagByCode;
