import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';

import { ReactComponent as UserIcon } from '../icons/userIcon.svg';

import QuickLoginItem from './QuickLoginItem';
import QuickPasswordLoginItem from './QuickPasswordLoginItem';


const QuickLogin = ({ environments, backAction, toggleIsLoading }) => {
    const { t } = useTranslation();
    const [selectedEnv, setSelectedEnv] = useState(null);
    const [displayName, setdisplayName] = useState(null);
    const [tpId, setTpId] = useState(null);

    const envsPrevLogins = [];
    environments.forEach((env) => {
        if (env.prevLogins) {
            // eslint-disable-next-line no-restricted-syntax
            for (const prevObj in env.prevLogins) {
                envsPrevLogins.push(
                    {
                        id: env.id,
                        displayName: env.displayName,
                        translatedName: env.translatedName,
                        loginWithTPId: env.loginWithTPId,
                        dataToDisplay: env.prevLogins[prevObj]
                    }
                );
            }
        }
    });

    const handleBackAction = () => {
        backAction();
    };

    const selectEnv = (env, selectedDisplayName, id) => {
        setSelectedEnv(env);
        setdisplayName(selectedDisplayName);
        setTpId(id);
    };

    if (!selectedEnv) {
        return (
            <Fragment>
                <h2>{t('Login.Header.ChooseAcc')}</h2>
                <div id='login__user__list'>
                    {envsPrevLogins.map((item) => (
                        <QuickLoginItem
                            title={item.id}
                            key={`${item.id}|${item.dataToDisplay.tpId}`}
                            envData={item}
                            selectEnv={selectEnv}
                            toggleIsLoading={toggleIsLoading}
                        />
                    ))}
                </div>
              <div className='login__env-item short' onClick={handleBackAction}>
                             <UserIcon />
                              <div className='login__env-item__content'>
                               <strong>{t('Login.Button.ChooseAnotherAccount')}</strong>
                             </div>
                            </div>
            </Fragment>
        );
    }

    return (
        <Fragment>
            <QuickPasswordLoginItem displayName={displayName} id={tpId} cmd={selectedEnv} backAction={selectEnv} />
        </Fragment>
    );
};

// QuickLogin.propTypes = {};

export default QuickLogin;
