/* eslint-disable no-mixed-operators */
/* eslint-disable complexity */
import { ProfitCalculationModes } from '../../background/reducers/symbolsReducer';
import { ACTION_TYPE } from '../TradePopup/TradePopup';

const calcMarginRequirement = (tickObj, type, selectedSymbol, size) => {
    const { marginPercentage, tickSize, tickValue, profitCalcMode, leverage, marginInitial, contractSize,mt5marginMultiplier,mt5marginBase } = selectedSymbol;
    if (!tickObj) {
        return 0;
    }

    const openRate = type === ACTION_TYPE.BID ? tickObj.bid : tickObj.ask;
    const marginRate = !tickObj.marginConversion || tickObj.marginConversion <= 0 ? 1 : tickObj.marginConversion;
    const finalLeverage = !leverage || leverage <= 0 ? 1 : leverage;
    const amount = (size || 0) * (marginInitial === 0 ? contractSize : marginInitial);
    let marginReq = 0;

    switch (profitCalcMode) {
    case ProfitCalculationModes.Sirix:
        marginReq = (amount * openRate * marginRate / finalLeverage);
        break;

    case ProfitCalculationModes.MT5CFDLeverage:
        {
            let basePrice = mt5marginBase;
            if (basePrice == 0)
            {
                basePrice = openRate * contractSize;
            }
            marginReq = (marginRate * _calculateMT5Leverage(leverage,mt5marginMultiplier) * size * basePrice * mt5marginMultiplier);
        }break;
    case ProfitCalculationModes.MT5CFDIndex:
        {
            let basePrice = mt5marginBase;
            if (basePrice == 0)
            {
                basePrice = (openRate * ((contractSize * tickValue) / tickSize));
            }
            marginReq = (marginRate * size * basePrice * mt5marginMultiplier);
        }break;
    case ProfitCalculationModes.MT5Forex:
        {
            marginReq = (marginRate * _calculateMT5Leverage(leverage,mt5marginMultiplier) * size * mt5marginBase * mt5marginMultiplier);
        } break;
    case ProfitCalculationModes.MT5CFD:
        {
            let basePrice = mt5marginBase;
            if (basePrice == 0)
            {
                basePrice = openRate * contractSize;
            }
            marginReq = (marginRate * size * basePrice * mt5marginMultiplier);
        } break;
    case ProfitCalculationModes.Forex:
        marginReq = (amount / finalLeverage * marginRate);
        break;

    case ProfitCalculationModes.CFD:
        marginReq = (amount * openRate * marginRate / finalLeverage );
        break;

    case ProfitCalculationModes.Future:
    case ProfitCalculationModes.MT5Futures:
        //marginReq = amount * marginInitial / marginPercentage * marginRate;
        marginReq = amount / marginPercentage * marginRate;
        break;
            default:
        break;
    }

    return marginReq;
};

const _calculateMT5Leverage= (leverage,multiplier)=>
{
    let userLeverage = leverage * multiplier;
    if (userLeverage === 0)
    {
        return 1;
    }
    return (1/userLeverage);
}

export default calcMarginRequirement;
