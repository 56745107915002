import React, {useCallback, useMemo, useRef} from 'react';

import useClickOutside from '../../hook/useClickOutside';
import useControls from '../../context/contextHook/useControls';

import MobileTransition from '../MobileTransition';
import useWindowSize from '../../hook/useWindowSize';

const MobilePopUp = ({
	                     style,
	                     children,
	                     onClose,
	                     isOpen,
	                     type = 'full',
	                     transition = 'slide',
                     }) => {
	const backdropRef = useRef(null);

	const {
		footerControls: {footerRef},
		headerControls: {headerRef},
		sectionControls: {sectionHeight},
	} = useControls();

	const handleOutsideClick = useCallback(() => {
		//TODO: outside click close
		// onClose && onClose();
	}, [onClose]);

	useClickOutside(backdropRef, handleOutsideClick);

	const {height, clientHeight, innerHeight} = useWindowSize();

	const bottomStyle = useMemo(() => {
		if (!clientHeight || !innerHeight) return 0;
		if (clientHeight !== innerHeight) return clientHeight - innerHeight;
		return 0;
	}, [clientHeight, innerHeight]);

	const getStyles = useMemo(() => {
		const blockHeight = height - 90;
		const styles = {
			height: `${blockHeight}px`,
			bottom: `${bottomStyle}px`,
		};
		if (footerRef && !footerRef.current) return styles;
		if (headerRef && !headerRef.current) return styles;
		const headerHeight = headerRef.current.offsetHeight;
		const footerHeight = footerRef.current.offsetHeight;

		// TODO: add height 14px for climb on header
		if (type === 'full') {
			styles.height = `${height - headerHeight}px`;
			return styles;
		}
		if (type === 'section') {
			styles.height = `${height - headerHeight - footerHeight}px`;
			styles.bottom = 0;
			return styles;
		}
		if (type === 'footer') {
			styles.height = `${height - headerHeight - 136}px`;
			styles.bottom = '136px';
			return styles;
		}
		styles.height = `${height - footerHeight - headerHeight + 14}px`;
		styles.bottom = `${footerHeight}px`;
		return styles;
	}, [isOpen, type, height, sectionHeight, bottomStyle]);

	return (
			<MobileTransition
					isOpen={isOpen}
					type={transition}
			>
				<div
						ref={backdropRef}
						className="mobile-popup"
						style={{...getStyles, ...style}}
				>
					<div className="mobile-popup__content">
						{children}
					</div>
				</div>
			</MobileTransition>
	);
};

export default MobilePopUp;
