import produce from "immer"
import { replaceIfChanged } from "../StoreUtils/overrideState";
import {TicksRepository} from "../repositories/TicksRepository"
import {getStore} from "../storeContainer"
import { unpack } from "../messageUnpacker";
import { DeepDiff } from 'deep-diff'

const symbolsFlagsMap = {
  "BROB": { single: "BROBTEST", left: "", right: "" },
  "DASHUSD": {single: "", left: "DASH", right: "USD"}
}

const  initState = {
  symbols:{},
  symbolsOrder:[]
};

TicksRepository.subscribeEventsNative((changes)=>{
  let store = getStore();
  if (store)
  {
    if (changes.created.length > 0)
    {
      store.dispatch({
        type:"symbols-push-Updates",
        payload: changes,
      });
    }
  }
});

export const ProfitCalculationModes =
{
  Forex : 0,
  CFD : 1,
  Future : 2,
  Sirix : 3,
  MT5Forex : 4,
	MT5CFD: 5,
	MT5Futures: 6,
	MT5CFDIndex: 7,
	MT5CFDLeverage: 8,
}

export const symbolsReducer = function symbolsReducer(state = initState, action){
  switch (action.type) {
    case "login-logout":
      return initState;
    case "symbols-pin-toggle":
      return produce(state,draf=>{
        let stateSymbols = draf.pinSymbols;
        draf.pinSymbols = [];
        stateSymbols.forEach(element => {
          if (element !== action.staticData.symbolId){
            draf.pinSymbols.push(element);
          }
        });

        if (!action.storeData.isPin)
        {
          draf.pinSymbols.push(action.staticData.symbolId);
        }

        let  symbol =draf.symbols[action.staticData.symbolId];
        if (symbol)
        {
          symbol.isPin = !action.storeData.isPin;
        }
      });
    case "symbols-push-Updates":
      return produce(state,draf=>{
        let stateSymbols = draf.symbols;
        for (let i=0 ;i<action.payload.created.length;i++)
        {
          let createdSymbol = action.payload.created[i];
          let stateSymbol = stateSymbols[createdSymbol.key]
          if(stateSymbol)
          {
            let currentTick = TicksRepository.getNative(createdSymbol.key);
            stateSymbol.hasTick = currentTick != null;
          }
        }
      });
    case "init-brand+login":
    {
      if (action.payload.loginTradingData.sharedTradingData.symbols?.symbolsPack)
      {
        let unpaked = unpack(action.payload.loginTradingData.sharedTradingData.symbols.symbolsPack);
        setSymbolsCommand(unpaked);
        if (unpaked,action.payload.loginTradingData.sharedTradingData.symbols.symbols)
        {
          addRemovedProps(unpaked,action.payload.loginTradingData.sharedTradingData.symbols.symbols);
          var differences = DeepDiff(action.payload.loginTradingData.sharedTradingData.symbols.symbols, unpaked);
        }
        action.payload.loginTradingData.sharedTradingData.symbols.symbols = unpaked;
      }
      let serversymbols = action.payload.loginTradingData.sharedTradingData.symbols;
      return produce(state,draf=>{
        HandleSymbols(draf, serversymbols);
      });
    }
    case "login-success":
    {
      if (action.payload.sharedTradingData.symbols?.symbolsPack)
      {
        let unpaked = unpack(action.payload.sharedTradingData.symbols.symbolsPack);
        setSymbolsCommand(unpaked);
        if (action.payload.sharedTradingData.symbols.symbols)
        {
          addRemovedProps(unpaked,action.payload.sharedTradingData.symbols.symbols);
          var differences = DeepDiff(action.payload.sharedTradingData.symbols.symbols, unpaked);
        }
        
        action.payload.sharedTradingData.symbols.symbols = unpaked;
      }

      let serversymbols = action.payload.sharedTradingData.symbols;
      return produce(state,draf=>{
        HandleSymbols(draf, serversymbols);
      });
    }
    default:
      return state;
  }
}

function addRemovedProps(target,soruce)
{
  let sourceDic = {};
  soruce.forEach(x=>{
    sourceDic[x.id] = x;
  })

  target.forEach(x=>{
    if (sourceDic[x.id])
    {
      x.tradingSessions = sourceDic[x.id].tradingSessions;
      x.tradingSessionDef = sourceDic[x.id].tradingSessionDef;
      
      sourceDic[x.id].altDescription = x.altDescription;
      sourceDic[x.id].sessionData = x.sessionData;
      sourceDic[x.id].altContractSize = x.altContractSize;
    }
  });
}
function setSymbolsCommand(symbolsArray) {
  symbolsArray.forEach(x => {

    x.chart = {
      cmdId: "STD.CMD.ChartBars",
      staticParams: { symbolId: x.id, postUrl: "api/tradingData/getChart" },
      userInput: { period: 5, from: null, barCount: 600 },
      storeRef:{},
    };
    x.openPositionCMD = {
      cmdId: "STD.CMD.Server",
      staticParams: { symbolId: x.id, contractSize: x.serverContractSize ?? x.contractSize, postUrl: "api/trading/openPosition" },
      storeRef: { tick: { repId: "ticks", key: x.id } ,clientContractSize: ["account", "symbols", "symbols", x.id, "contractSize"]},
      userInput: { actionType: 0, amount: 1, stopLoss: 0.0, takeProfit: 0.0 },
    };
    x.togglePinSymbolCMD = {
      cmdId: "STD.CMD.AsyncServer",
      staticParams: { symbolId: x.id, postUrl: "api/userActions/togglePinSymbol", type: "symbols-pin-toggle" },
      userInput: {},
      storeRef: { isPin: ["account", "symbols", "symbols", x.id, "isPin"] },
    };
    x.openPendingOrderCMD = {
      cmdId: "STD.CMD.Server",
      staticParams: { symbolId: x.id,contractSize: x.serverContractSize ?? x.contractSize, postUrl: "api/trading/createPending" },
      storeRef: { tick: { repId: "ticks", key: x.id },clientContractSize: ["account", "symbols", "symbols", x.id, "contractSize"] },
      userInput: { price: 1, amount: 1, actionType: 0, stopLoss: 0.0, takeProfit: 0.0 },
    };
  });
}

function HandleSymbols(draf, payload) {
  let serversymbols = payload.symbols;
  let symbolsOrder = payload.symbolsOrder;
  let pinSymbols = {

  };

  payload.pinSymbols.forEach(element => {
    pinSymbols[element] = true;
  });

  replaceIfChanged(draf,'pinSymbols', payload.pinSymbols);

  let symbolsToRemove = new Set();
  let stateSymbols = draf.symbols;
  for (let stateSymbol of Object.values(stateSymbols)) {
    symbolsToRemove.add(stateSymbol.id);
  }

  for (let serverSymbolkey in serversymbols) {
    let serverSymbol = serversymbols[serverSymbolkey];
    symbolsToRemove.delete(serverSymbol.id);
    let stateSymbol = stateSymbols[serverSymbol.id];
    if (!stateSymbol) {
      stateSymbol = { id: serverSymbol.id, tradingSessions: [], tick: { repId: "ticks", key: serverSymbol.id } };
      stateSymbols[serverSymbol.id] = stateSymbol;
    }
    stateSymbol.displayName = serverSymbol.displayName;
    stateSymbol.description = serverSymbol.description;
    stateSymbol.group = serverSymbol.group;
    stateSymbol.flag = serverSymbol.flag;
    stateSymbol.displayDigit = serverSymbol.displayDigit;
    stateSymbol.pipDigit= serverSymbol.pipDigit;
    stateSymbol.tradeMode = serverSymbol.tradeMode;
    stateSymbol.contractUnitType = serverSymbol.contractUnitType;
    stateSymbol.serverUnits = serverSymbol.serverUnits;
    stateSymbol.profitCalcMode = serverSymbol.profitCalcMode;
    stateSymbol.contractSize = serverSymbol.contractSize;
    stateSymbol.leverage = serverSymbol.leverage;
    stateSymbol.marginPercentage = serverSymbol.marginPercentage;
    stateSymbol.tickSize = serverSymbol.tickSize;
    stateSymbol.tickValue = serverSymbol.tickValue;
    stateSymbol.marginInitial = serverSymbol.marginInitial;
    stateSymbol.swapLong = serverSymbol.swapLong;
    stateSymbol.swapShort = serverSymbol.swapShort;
    stateSymbol.swapType = serverSymbol.swapType;
    stateSymbol.minimumLot = serverSymbol.minimumLot;
    stateSymbol.maximumLot = serverSymbol.maximumLot;
    stateSymbol.lotStep = serverSymbol.lotStep;
    stateSymbol.stopLevel = serverSymbol.stopLevel;
    stateSymbol.isVisible = serverSymbol.isVisible;
    stateSymbol.mt5marginMultiplier = serverSymbol.mt5marginMultiplier;
    stateSymbol.mt5marginBase = serverSymbol.mt5marginBase;

    stateSymbol.t4Pro = serverSymbol.t4Pro;

    replaceIfChanged(stateSymbol,'icon', serverSymbol.icon)
    replaceIfChanged(stateSymbol,'tradingSessions', serverSymbol.tradingSessions);
    replaceIfChanged(stateSymbol,'openPendingOrderCMD', serverSymbol.openPendingOrderCMD);
    replaceIfChanged(stateSymbol,'openPositionCMD', serverSymbol.openPositionCMD);
    replaceIfChanged(stateSymbol,'togglePinSymbolCMD', serverSymbol.togglePinSymbolCMD);

    replaceIfChanged(stateSymbol,'chart', serverSymbol.chart);

    let currentTick = TicksRepository.getNative(serverSymbol.id);
    stateSymbol.hasTick = currentTick != null;

    stateSymbol.isPin = pinSymbols[serverSymbol.id] ? true:false;

    var flagData = getSymbolFlagMap(stateSymbol.displayName);
    replaceIfChanged(stateSymbol,'flag', flagData);
  }

  for (let symbolToRemove of symbolsToRemove) {
    delete stateSymbols[symbolToRemove];
  }

  replaceIfChanged(draf,'symbolsOrder', symbolsOrder);
}

function getSymbolFlagMap(symbolName) {
  //search in map
  var flag = symbolsFlagsMap[symbolName];
  if (flag) {
    return flag;
  }

  //isContract
  var splitIndex = symbolName.lastIndexOf("-");

  if (splitIndex !== -1) {
    var month = symbolName.substring(splitIndex + 1, splitIndex + 4);
    var year = symbolName.substring(splitIndex + 4, splitIndex + 6);

    if (contractMonths.has(month) && parseInt(year)) {
      var pattern = symbolName.substring(0, splitIndex);
      var flag = symbolsFlagsMap[pattern];
      if (flag) {
        return flag;
      }
    }
  }

  //isForex
  var leftPair = symbolName.substring(0, 3);
  var rightPair = symbolName.substring(3, 6);

  if (forexPairs.has(leftPair) && forexPairs.has(rightPair)) {
    if (symbolName.length > 6) {
      var endPattern = symbolName.substring(6);
      if (symbolSpecialEndings.has(endPattern)) {
        return { single: "", left: leftPair, right: rightPair };
      }
    }
    else {
      return { single: "", left: leftPair, right: rightPair };
    }
  }

  return { single: "", left: "", right: "" };
}

const contractMonths = new Set([
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC"
]);

const forexPairs = new Set([
  "AUD",
  "BTC",
  "CAD",
  "CHF",
  "EUR",
  "GBP",
  "JPY",
  "NZD",
  "USD",
  "TRY",
  "BCH",
  "CNH",
  "CZK",
  "DKK",
  "EOS",
  "ETC",
  "ETH",
  "HKD",
  "HUF",
  "ILS",
  "LTC",
  "MXN",
  "NOK",
  "PLN",
  "RON",
  "RUB",
  "SEK",
  "SGD",
  "XLM",
  "XMR",
  "XRP",
  "ZAR"
]);

const symbolSpecialEndings = new Set([
  "*",
  "#",
  "1",
  ".",
  "$",
  "!",
  "_"
]);
