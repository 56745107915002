import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import useProfile from '../../context/contextHook/useProfile';

import MobilePopUpHeader from '../../components/MobilePopUp/MobilePopUpHeader';
import MobileAvatarIcon from '../../components/MobileAvatarIcon';
import MobileButtonClose from '../../components/Buttons/MobileButtonClose';

const MobileProfileHeader = ({avatar, nickname, copiersCount, reference}) => {
	const {copiedUser: {isCopied, handleCopiedClose}} = useProfile();

	const {t} = useTranslation();

	const currentCopiersCount = useMemo(() => {
		if (typeof copiersCount === 'undefined') return '--';
		return copiersCount || 0;
	}, [copiersCount]);

	const handleClose = useCallback(e => {
		if (!isCopied) return null;
		if (isCopied) {
			e && e.preventDefault();
			e && e.stopPropagation();
			handleCopiedClose && handleCopiedClose();
		}
	}, [handleCopiedClose, isCopied]);

	return (
			<MobilePopUpHeader>
				<div
						ref={reference}
						className="mobile-profile__header"
				>
					<div className="mobile-profile__header__user">
						<div className="mobile-profile__header__user__avatar">
							<MobileAvatarIcon avatar={avatar}/>
						</div>

						<div className="mobile-profile__header__user__desc">
							<p className="mobile-social-watch__item__user__nickname">{nickname}</p>
							<div className="mobile-social-watch__item__user__state">
							<span className="mobile-social-watch__item__user__state__title">
								{t('Socail.Profile.Copiers').toLowerCase()}
								:&nbsp;
							</span>
								<span
										className="mobile-social-watch__item__user__state__count"
								>
								{currentCopiersCount}
							</span>
							</div>
						</div>

					</div>
					<div className="mobile-profile__header__close">
						<MobileButtonClose
								onClick={handleClose}
						/>
					</div>
				</div>
			</MobilePopUpHeader>
	);
};

MobileProfileHeader.defaultProps = {
	avatar: '',
	nickname: '',
	copiersCount: 0,
};

MobileProfileHeader.propTypes = {
	avatar: PropTypes.string,
	nickname: PropTypes.string,
	copiersCount: PropTypes.number,
	reference: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({current: PropTypes.instanceOf(Element)}),
	]).isRequired,
};

export default MobileProfileHeader;
