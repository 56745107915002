import React, {useCallback, useMemo, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';

import {roundNumberPipDigit} from '../../../UI/utilsUI/formatWSTick';
import PubsubSubscription from '../../../background/repositories/PubsubSubscription';
import calcMarginRequirement from '../../../UI/utilsUI/calcMarginRequirement';
import countDecimals from '../../../UI/utilsUI/countDecimals';
import roundToPrecision from '../../../UI/utilsUI/roundToPrecision';
import {ProfitCalculationModes} from '../../../background/reducers/symbolsReducer';
import { CommandFactory } from '../../../background/commands/CommandFactory'

import {ACTION_TYPE} from '../../../UI/TradePopup/TradePopup';
import MobileTradeMainDraw from './MobileTradeMainDraw';
import MobileLoader from '../../components/MobileLoader/MobileLoader';
import * as TradePopupAnalyticsEvents
	from '../../../UI/TradePopup/TradePopupAnalyticsEvents';
import chartController from '../../../background/chart/chartController';
import {maskNumericalInput} from '../../helpers/input.helpers';

const mainChartController = new chartController();

export const calcPipValue = (prices, selectedSymbol, selectedUnits, orderType) => {
	const {pipDigit, contractSize, profitCalcMode} = selectedSymbol;
	let pip = Math.pow(10, -pipDigit);
	if (!prices) {
		return 0;
	}
	let conversionRate = (prices.askProfitConversion +
			prices.bidProfitConversion) / 2;

	if (orderType) {
		conversionRate = orderType === ACTION_TYPE.BID ?
				prices.bidProfitConversion :
				prices.askProfitConversion;
	}

	if (profitCalcMode === ProfitCalculationModes.Sirix) {
		return pip * selectedUnits * conversionRate * contractSize;
	} else {
		return pip * selectedUnits / conversionRate * contractSize;
	}

};

const MobileTradeMain = ({selectedSymbol}) => {
	const {symbol} = useSelector(state => state.account.accountInfo.currency,
			shallowEqual);

	const {
		contractSize,
		tick,
		minimumLot,
		maximumLot,
		lotStep,
		description,
	} = useMemo(() => selectedSymbol, [selectedSymbol]);

	const [isLoading, setIsLoading] = useState(false);
	const [isProcessing, setIsProcessing] = useState(false);
	const [unitsDropDown, setUnitsDropDown] = useState(false);
	const [showError, setShowError] = useState('');
	const [pendingOrder, setPendingOrder] = useState(false);
	const [pendingOrderPrice, setPendingOrderPrice] = useState(null);
	const [response, setResponse] = useState('');
	const [orderInfo, setOrderInfo] = useState({status: '', price: null});
	const [type, setType] = useState(ACTION_TYPE.ASK);
	const [selectedUnits, setSelectedUnits] = useState({
		number: Math.max(minimumLot, lotStep),
		representation: Math.max(minimumLot, lotStep),
	});

	const tickObj = PubsubSubscription.subscribeObj(tick);
	const pnlTick = PubsubSubscription.subscribeObj(
			{key: 'MOVINGPNL', repId: 'ACCOUNT_PNL'});

	const marginRequirement = useMemo(() => {
		const {number} = selectedUnits;
		return calcMarginRequirement(tickObj, type, selectedSymbol, number);
	}, [tickObj, type, selectedSymbol, selectedUnits]);

	const pipValue = useMemo(() => {
		const {number} = selectedUnits;
		return calcPipValue(tickObj, selectedSymbol, number, type);
	}, [tickObj, selectedSymbol, selectedUnits, type]);

	const handleSetValue = useCallback(value => {
		setShowError('');
		const units = maskNumericalInput(value);
		const decimals = countDecimals(units.replace(/[^0-9\.]/g, ''));
		const unitsValue = decimals === 0 ?
				units.replace(/[^0-9\.]/g, '') :
				roundNumberPipDigit(units, decimals);
		setSelectedUnits({
			number: roundToPrecision(unitsValue, decimals),
			representation: units,
		});
	}, [setShowError, setSelectedUnits]);

	const handleSetPendingPrice = useCallback(value => {
		response && setResponse('');
		const units = maskNumericalInput(value);
		setPendingOrderPrice(units);
	}, [setPendingOrderPrice, setResponse, response]);

	const cancelOrder = useCallback(() => {
		setResponse('');
		setType('');
	}, [setResponse, setType]);

	const onChangeSwitch = useCallback(type => {
		if (!type) return null;
		response && setResponse('');
		setType(type);
	}, [type, response]);

	const handleSwitchChange = useCallback(() => {
		response && setResponse('');
		setPendingOrderPrice(type === ACTION_TYPE.BID ?
				PubsubSubscription.getValueNative(tick).bid :
				PubsubSubscription.getValueNative(tick).ask);
		setPendingOrder(prevState => !prevState);
	}, [response, type, tick]);

	const handleShowError = useCallback(error => {
		setShowError(error);
	}, []);

	const toggleUnitsDropDown = useCallback(() => {
		setUnitsDropDown(prev => !prev);
	}, [setUnitsDropDown]);

	const closeUnitsDropDown = useCallback(() => {
		response && setResponse('');
		setUnitsDropDown(false);
	}, [response, setResponse, setUnitsDropDown]);

	const openUnitsDropDown = useCallback(() => {
		response && setResponse('');
		setUnitsDropDown(true);
	}, [response, setResponse, setUnitsDropDown]);

	const requestSuccess = useMemo(() => {
		const {status} = orderInfo;
		return status === 'success';
	}, [orderInfo]);

	const lotPipNumber = useMemo(() => {
		return countDecimals(lotStep);
	}, [lotStep]);

	const handleOpenPosition = useCallback(async e => {
		e && e.preventDefault();

		const command = CommandFactory.createCommand(selectedSymbol.openPositionCMD);

		setOrderInfo(prev => {
			return {
				...prev,
				price: type === ACTION_TYPE.BID ?
						PubsubSubscription.getValueNative(tick).bid :
						PubsubSubscription.getValueNative(tick).ask,
			};
		});

		setIsProcessing(true);
		try {
			setResponse(null);
			const res = await command.execute({
				actionType: type === ACTION_TYPE.BID ? 1 : 0,
				amount: selectedUnits.number,
			});

			if (res.isSuccessful) {
				setOrderInfo((prevState) => {
					return {...prevState, status: 'success', price: res.price};
				});
			}
			if (!res.isSuccessful) {
				setResponse(res);
			}
		} catch (err) {
			setResponse(err);
		}

		setIsProcessing(false);
	}, [
		selectedUnits,
		selectedSymbol,
		setIsProcessing,
		setResponse,
		setOrderInfo,
		type]);

	const handlePendingOrder = useCallback(async e => {
		e && e.preventDefault();

		const userInput = {
			actionType: type === ACTION_TYPE.BID ? 1 : 0,
			amount: selectedUnits.number,
			price: pendingOrderPrice,
		};

		const command = CommandFactory.createCommand(selectedSymbol.openPendingOrderCMD);

		setIsLoading(true);

		const sendPendingOrderEventFunc = TradePopupAnalyticsEvents.createPendingOrderEvent(
				tickObj, roundNumberPipDigit(pipValue, 2),
				roundNumberPipDigit(marginRequirement, 2),
				selectedSymbol.displayName, selectedUnits.number, type,
				pendingOrderPrice, mainChartController);

		try {
			setResponse(null);
			const res = await command.execute(userInput);

			if (res.isSuccessful) {
				sendPendingOrderEventFunc(false, true,
						res.result);

				setOrderInfo((prevState) => ({
					...prevState,
					status: 'success',
					price: pendingOrderPrice,
				}));
			}
			if (!res.isSuccessful) {
				sendPendingOrderEventFunc(false, false,
						res.result ?? 'trading.error.GeneralError');

				setResponse(res);
			}
		} catch (err) {
			sendPendingOrderEventFunc(
					false,
					false,
					'trading.error.GeneralError');
			setResponse(err);
		}
		setIsLoading(false);
	}, [
		setOrderInfo,
		setResponse,
		setIsLoading,
		type,
		selectedUnits,
		pendingOrderPrice,
		selectedSymbol,
	]);

	return (
			<>
				<MobileLoader isLoading={isLoading} overlay/>

				<MobileTradeMainDraw
						description={description}
						symbol={symbol}
						requestSuccess={requestSuccess}
						selectedSymbol={selectedSymbol}
						type={type}
						onChangeSwitch={onChangeSwitch}
						unitsDropDown={unitsDropDown}
						pipValue={pipValue}
						marginRequirement={marginRequirement}
						selectedUnits={selectedUnits}
						lotStep={lotStep}
						handleSetValue={handleSetValue}
						minimumLot={minimumLot}
						maximumLot={maximumLot}
						lotPipNumber={lotPipNumber}
						handleShowError={handleShowError}
						showError={showError}
						openUnitsDropDown={openUnitsDropDown}
						closeUnitsDropDown={closeUnitsDropDown}
						toggleUnitsDropDown={toggleUnitsDropDown}
						contractSize={contractSize}
						tickObj={tickObj}
						pnlTick={pnlTick}
						pendingOrder={pendingOrder}
						handleSwitchChange={handleSwitchChange}
						cancelOrder={cancelOrder}
						handleOpenPosition={handleOpenPosition}
						orderInfo={orderInfo}
						tick={tick}
						pendingOrderPrice={pendingOrderPrice}
						handleSetPendingPrice={handleSetPendingPrice}
						setOrderInfo={setOrderInfo}
						setIsLoading={setIsLoading}
						setResponse={setResponse}
						response={response}
						isLoading={isLoading}
						isProcessing={isProcessing}
						handlePendingOrder={handlePendingOrder}
				/>
			</>
	);
};

export default MobileTradeMain;
