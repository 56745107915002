import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {TIMEFRAMES_CHART_PIE} from '../../../constants/options';

import MobileChartSwitch from '../../MobileSwitch/MobileChartSwitch';
import clsx from 'clsx';
import MobilePieChart from '../../MobileChart/MobilePieChart/MobilePieChart';
import MobileLoader from '../../MobileLoader/MobileLoader';
import usePortfolioData from '../../../hook/useFetchData/usePortfolioData';

const MobileProfilePortfolio = ({nickname}) => {
	const [timeframe, setTimeframe] = useState(1);
	const {t} = useTranslation();

	const handleTimeChange = useCallback(({timeKey}) => {
		setTimeframe && setTimeframe(timeKey);
	}, [setTimeframe]);

	const {portfolioData, isLoading} = usePortfolioData(nickname, timeframe);

	return (
			<div className='mobile-profile-portfolio'>
				<MobileChartSwitch
						options={TIMEFRAMES_CHART_PIE}
						onChange={handleTimeChange}
						value={timeframe}
						type='profile'
				/>
				<div className={clsx('mobile-profile-portfolio__chart', 'hT50')}>

					<MobileLoader isLoading={isLoading}/>

					{!isLoading && nickname && (
							<div className="mobile-profile-portfolio__chart__wrapper">
								<MobilePieChart options={portfolioData}/>
							</div>
					)}
				</div>
			</div>
	);
};

export default MobileProfilePortfolio;
