import React from 'react';

import './LoadSpinner.css';


const LoadSpinner = ({ overlay = false, isLocal, isBottom }) => {
    if (overlay) {
        return (
            <div className='loading-spinner__overlay'>
                <div className='loading-spinner' />
            </div>
        );
    }

    if (isLocal) {
        return (
            <div className='loading-spinner__overlay loading-spinner__overlay--local'>
                <div className='loading-spinner' />
            </div>
        );
    }

    if (isBottom) {
        return (
            <div className='loading-spinner__overlay loading-spinner__overlay--bottom'>
                <div className='loading-spinner' />
            </div>
        );
    }

    return (
        <div className='loading-spinner' />
    );
};

export default LoadSpinner;
