import React, {useCallback, useMemo} from 'react';
import clsx from 'clsx';

import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Pagination, Virtual} from 'swiper';
import 'swiper/swiper-bundle.css';

SwiperCore.use([Virtual, Pagination]);

const MobileCarouselSwitch = props => {
	const {value, options, onChange, type} = props;

	const handleChange = useCallback(swiper => {
		const index = swiper.activeIndex;
		const opt = options[index];
		onChange && onChange(opt);
	}, [onChange, options]);

	const isActiveItem = useCallback((value, option) => {
		return value === option.timeKey;
	}, [value]);

	const initialSlide = useMemo(() => {
		let index = 0;
		options.forEach((el, i) => {
			if (el.timeKey !== value) return null;
			index = i;
		});
		return index;
	}, [value, options]);

	return (
			<div className="mobile-carousel-switch">
				<Swiper
						tag="div"
						wrapperTag="ul"
						slidesPerView={4}
						spaceBetween={0}
						centeredSlides={true}
						lazy={true}
						initialSlide={initialSlide}
						slideToClickedSlide={true}
						onSlideChange={handleChange}
				>
					{options.map((option, index) => (
							<SwiperSlide
									tag="li"
									key={`slide-${index}`}
									virtualIndex={index}

							>
								{({isActive}) => (
										<div
												className={clsx('mobile-carousel-switch__item',
														isActiveItem(value, option) && 'active')}
										>
											{option.timeValue}
										</div>
								)}
							</SwiperSlide>
					))}
				</Swiper>
			</div>
	);
};

MobileCarouselSwitch.defaultValue = {
	value: '',
	options: [],
	type: 'timekey',
};

export default MobileCarouselSwitch;
