import React from 'react';
import Logo from './Logo';
import TopPanelActions from '../TopPanelActions/TopPanelActions';
import './TopPanel.css';


const TopPanel = ({
    handleUserSelect, setJoinPopup, shouldShowTriangle
}) => {
    return (
        <header>
            <Logo></Logo>
            <div className='header__account-info'>
                <TopPanelActions handleUserSelect={handleUserSelect} setJoinPopup={setJoinPopup} shouldShowTriangle={shouldShowTriangle} />
            </div>
        </header>
    );
};

export default TopPanel;
