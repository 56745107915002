import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const LinearProgressBarWithLabel = ({ value, hideLabel }) => {
    return (
        <Box display='flex' alignItems='center'>
            <Box width='100%' height='10px' mr={1}>
                <LinearProgress variant='determinate' value={value * 10} />
            </Box>
            {!hideLabel && (
                <Box minWidth={35}>
                    <Typography variant='body2' color='textSecondary'>
                        {`${Math.round(
                            value
                        )}/10`}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default LinearProgressBarWithLabel;
