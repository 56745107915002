/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import PropTypes from 'prop-types';


// eslint-disable-next-line object-curly-newline
const CheckBox = ({ value, label, labelPlacement, name, handleCheckBoxClick, color }) => {
    const { t } = useTranslation();
    const { MainColor: { ActiveText, InactiveText } } = useSelector((state) => (state.brand.theme));
    const labelText = t(`${label}`);
    const handleClick = (e) => {
        const request = {
            target: {
                name: e.target.name,
                value: !value
            }
        };
        handleCheckBoxClick(request);
    };

    return (
        <FormControlLabel
            value='end'
            control={
                <Checkbox
                    checked={value}
                    name={name}
                    onChange={handleClick}
                    color={color || 'default'}
                    style={value ? { color: ActiveText } : { color: InactiveText }}
                />
            }
            label={labelText}
            labelPlacement={labelPlacement ? 'end' : 'start'}
            style={{ color }}
        />
    );
};

// CheckBox.propTypes = {};

export default CheckBox;
