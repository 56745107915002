import React from 'react';
import useCommunityFetch from '../../../hook/useFetchData/useCommunityFetch';
import MobileLoader from '../../MobileLoader/MobileLoader';
import MobileNothingFound from '../../MobileEmpty/MobileNothingFound';
import MobileProfileCommunityList from './MobileProfileCommunityList';

const MobileProfileCommunityWrapper = ({nickname, kind}) => {
	const {
		data,
		isLoading,
	} = useCommunityFetch(nickname, kind);

	if (isLoading) {
		return <MobileLoader/>;
	}

	return (
			<>
				<MobileLoader isLoading={isLoading}/>

				{data && !data.UserFriends.length && (
						<MobileNothingFound/>
				)}
				{data && !!data.UserFriends.length && (
						<MobileProfileCommunityList {...data} />
				)}
			</>
	);
};

export default MobileProfileCommunityWrapper;
