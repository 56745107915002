import React from 'react';

import PubsubSubscription from '../../background/repositories/PubsubSubscription';

import { prepareBidAsk } from '../utilsUI/formatWSTick';
import { formatTime } from '../utilsUI/formatTime';

import TickItem from './TickItem';
import './TickItem.css';


const TickItemWrapper = ({
    name,
    pipDigit,
    repObj,
    tickData,
    handleItemSelect,
    isActive,
    shouldDisplayPin,
    shouldDisplayInfo,
    // shouldDisplayFlags,
    shouldDisplayTime,
    subHeaderColor,
    tickUp,
    tickDown,
    tickNeutral
}) => {
    const [symbolTick, previousTick] = PubsubSubscription.subscribeObjAndPrev(repObj);
    const { bidOutput, askOutput } = prepareBidAsk(symbolTick, previousTick, pipDigit, tickUp, tickDown, tickNeutral);
    const timeUpdate = formatTime(symbolTick?.timestamp);


    return (
        <TickItem
            name={name}
            bid={bidOutput}
            ask={askOutput}
            tickData={tickData}
            handleItemSelect={handleItemSelect}
            isActive={isActive}
            shouldDisplayPin={shouldDisplayPin}
            shouldDisplayInfo={shouldDisplayInfo}
            // shouldDisplayFlags={shouldDisplayFlags}
            shouldDisplayTime={shouldDisplayTime}
            timeUpdate={timeUpdate}
            subHeaderColor={subHeaderColor}
            tickUp={tickUp}
        />
    );
};


export default TickItemWrapper;
