import { SendEventToFullStory } from '../../background/storeListener/fullStoryListener';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';
import {SendEventToLuckyOrange} from '../../background/storeListener/luckyOrangeListener';

export function createOpenPositionEvent(tickObj, pipValue, marginRequirement, selectedSymbol, selectedUnits, orderType, mainChartController) {
  let start = new Date().getTime();
  let selectedIndicators = mainChartController.getIndicators();
  let selectedIndicatorsArr = selectedIndicators.map(x => x.name());
  let selectedChartPeriod = mainChartController.getChartPeriod();

  let fsEventParams = createFullStoryExecutionEventParams(tickObj, pipValue, marginRequirement, selectedSymbol, selectedUnits, orderType, selectedIndicatorsArr, selectedChartPeriod, false);
  let gaEventParams = createGoogleAnalyticsExecutionEventParams(tickObj, pipValue, marginRequirement, selectedSymbol, selectedUnits, orderType, selectedIndicatorsArr, selectedChartPeriod, false);

  return (wasTradePopupClosed, isSuccessful, openPrice, error) => {
    try {
      var executionTime = new Date().getTime() - start;

      fsEventParams.openPrice_str = openPrice.toString();
      fsEventParams.isSuccessful_bool = isSuccessful;
      fsEventParams.error_str = error;
      fsEventParams.wasTradePopupClosed_bool = wasTradePopupClosed;
      fsEventParams.executionTime_str = executionTime.toString();

      gaEventParams.openPrice = openPrice.toString();
      gaEventParams.isSuccessful = isSuccessful;
      gaEventParams.error = error;
      gaEventParams.wasTradePopupClosed = wasTradePopupClosed;
      gaEventParams.executionTime = executionTime.toString();

      SendEventToGoogleAnalytics("OpenPosition", gaEventParams);
      SendEventToFullStory("OpenPosition", fsEventParams);
      SendEventToLuckyOrange([`OpenPosition`, `OpenPosition:symbol:${selectedSymbol}`, `OpenPosition:success:${isSuccessful}`]);
    } catch (ex) {
    }
  }
}


export function createPendingOrderEvent(tickObj, pipValue, marginRequirement, selectedSymbol, selectedUnits, orderType, requestPrice, mainChartController) {

  let start = new Date().getTime();
  let selectedIndicators = mainChartController.getIndicators();
  let selectedIndicatorsArr = selectedIndicators.map(x => x.name());
  let selectedChartPeriod = mainChartController.getChartPeriod();

  let fsEventParams = createFullStoryExecutionEventParams(tickObj, pipValue, marginRequirement, selectedSymbol, selectedUnits, orderType, selectedIndicatorsArr, selectedChartPeriod, true, requestPrice);
  let gaEventParams = createGoogleAnalyticsExecutionEventParams(tickObj, pipValue, marginRequirement, selectedSymbol, selectedUnits, orderType, selectedIndicatorsArr, selectedChartPeriod, true, requestPrice);

  return (wasTradePopupClosed, isSuccessful, error) => {
    try {
      var executionTime = new Date().getTime() - start;

      fsEventParams.isSuccessful_bool = isSuccessful;
      fsEventParams.error_str = error;
      fsEventParams.wasTradePopupClosed_bool = wasTradePopupClosed;
      fsEventParams.executionTime_str = executionTime.toString();

      gaEventParams.isSuccessful = isSuccessful;
      gaEventParams.error = error;
      gaEventParams.wasTradePopupClosed = wasTradePopupClosed;
      gaEventParams.executionTime = executionTime.toString();

      SendEventToGoogleAnalytics("PendingOrderRequest", gaEventParams);
      SendEventToFullStory("PendingOrderRequest", fsEventParams);
      SendEventToLuckyOrange([`PendingOrderRequest`, `PendingOrderRequest:symbol:${selectedSymbol}`, `PendingOrderRequest:success:${isSuccessful}`]);
    }
    catch (ex) {
    }
  }
}

  const createFullStoryExecutionEventParams = (tickObj, pipValue, marginRequirement, selectedSymbol, selectedUnits, orderType, selectedIndicatorsArr, selectedChartPeriod, ispending, requestPrice = null) => {
    let fsEventParams = {
      pipValue_str: pipValue,
      marginRequirement_str: marginRequirement,
      symbol_str: selectedSymbol,
      amount_real: selectedUnits,
      actionType_str: orderType === "bid" ? "Sell" : "Buy",
      ask_real: tickObj.ask,
      bid_real: tickObj.bid,
      sl_real: 0,
      tp_real: 0,
      selectedIndicators_strs: selectedIndicatorsArr,
      selectedChartPeriod_str: selectedChartPeriod,
    };

    if (ispending) {
      fsEventParams.requestPrice_str = requestPrice;

      if (orderType === "bid") {
        fsEventParams.actionType_str = requestPrice <= tickObj?.bid ? "SellStop" : "SellLimit"
      } else {
        fsEventParams.actionType_str = requestPrice > tickObj?.ask ? "BuyStop" : "BuyLimit";
      }
    }
    return fsEventParams;
  }

  const createGoogleAnalyticsExecutionEventParams = (tickObj, pipValue, marginRequirement, selectedSymbol, selectedUnits, orderType, selectedIndicatorsArr, selectedChartPeriod, ispending, requestPrice = null) => {
    let gaEventParams = {
      pipValue: pipValue,
      marginRequirement: marginRequirement,
      symbol: selectedSymbol,
      amount: selectedUnits,
      actionType: orderType === "bid" ? "Sell" : "Buy",
      ask: tickObj.ask,
      bid: tickObj.bid,
      sl: 0,
      tp: 0,
      selectedIndicators: selectedIndicatorsArr,
      selectedChartPeriod: selectedChartPeriod,
    };
    if (ispending) {
      gaEventParams.requestPrice = requestPrice;

      if (orderType === "bid") {
        gaEventParams.actionType = requestPrice <= tickObj?.bid ? "SellStop" : "SellLimit"
      } else {
        gaEventParams.actionType = requestPrice > tickObj?.ask ? "BuyStop" : "BuyLimit";
      }
    }
    return gaEventParams;
  }
