/* eslint-disable complexity */
import React, { useRef } from 'react';

import PubsubSubscription from '../../background/repositories/PubsubSubscription';
import { prepareBidAsk } from '../utilsUI/formatWSTick';


const TradePopupWSTick = ({
    selectedSymbol, onBidClick, onAskClick, isBidActive, tradePopupButton, tradePopupButtonBuy, tradePopupButtonSell, tickUp, tickDown, tickNeutral
}) => {
    const pipDigit = selectedSymbol.displayDigit;
    const [symbolTick, previousTick] = PubsubSubscription.subscribeObjAndPrev(selectedSymbol?.tick);
    const { bidOutput, askOutput } = prepareBidAsk(symbolTick, previousTick, pipDigit, tickUp, tickDown, tickNeutral);
    const thumbClass = isBidActive ? 'trade-popup__thumb' : 'trade-popup__thumb trade-popup__thumb--active';
    const bidStyle = isBidActive ? { backgroundColor: tradePopupButtonSell } : {};
    const askStyle = !isBidActive ? { backgroundColor: tradePopupButtonBuy } : {};
    const bidContainer = useRef();
    const askContainer = useRef();
    const handleOnFocusBid = () => {
        bidContainer.current.style.backgroundColor = tradePopupButtonSell;
    };
    const handleOnFocusAsk = () => {
        askContainer.current.style.backgroundColor = tradePopupButtonBuy;
    };
    const handleOnBlurBid = () => {
        bidContainer.current.style.backgroundColor = isBidActive ? tradePopupButtonSell : tradePopupButton.backgroundColor;
    };
    const handleOnBlurAsk = () => {
        askContainer.current.style.backgroundColor = !isBidActive ? tradePopupButtonBuy : tradePopupButton.backgroundColor;
    };

    return (
        <div className='trade-popup__bid-ask' style={tradePopupButton}>
            <div
                className={thumbClass}
                style={isBidActive ? { backgroundColor: tradePopupButtonSell } : { backgroundColor: tradePopupButtonBuy }}
            />
            <div
                className='bid-item__bid'
                onClick={onBidClick}
                style={bidStyle}
                onFocus={() => undefined}
                onBlur={() => undefined}
                onMouseOver={handleOnFocusBid}
                onMouseOut={handleOnBlurBid}
                ref={bidContainer}
            >
                <span>Sell</span>
                <div>{bidOutput}</div>
            </div>
            <div
                className='bid-item__ask'
                onClick={onAskClick}
                style={askStyle}
                onFocus={() => undefined}
                onBlur={() => undefined}
                onMouseOver={handleOnFocusAsk}
                onMouseOut={handleOnBlurAsk}
                ref={askContainer}
            >
                <span>Buy</span>
                <div>{askOutput}</div>
            </div>
        </div>
    );
};

// TradePopupWSTick.propTypes = {};

export default TradePopupWSTick;
