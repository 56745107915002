import React, {Fragment, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';

import {renderType} from '../../../../helpers/position.helpers';
import {format2Digits} from '../../../../../UI/utilsUI/formatNumber';
import {FormatPercentFloat} from '../../../../../UI/ProfilePage/ProfileTabDetails';

const MobileProfilePositionItemOpen = props => {
	const {
		InstrumentName,
		TradeActionType,
		pendingOrders,
		OpenPrice,
		Price,
		DecimalPrecision,
		PL,
		itemType,
	} = props;

	const {t} = useTranslation();

	const currentType = useMemo(() => {
		const label = renderType(TradeActionType, pendingOrders);
		return label.transition ? t(label.transition) : label.type;
	}, [pendingOrders, TradeActionType]);

	const currentRate = useMemo(() => {
		return pendingOrders
				? format2Digits(Price, DecimalPrecision)
				: OpenPrice;
	}, [pendingOrders, OpenPrice]);

	return (
			<div className={clsx('mobile-profile-position-item', itemType)}>
				<div className="mobile-profile-position-item__cell">{InstrumentName}</div>
				<div className="mobile-profile-position-item__cell">{currentType}</div>
				<div className="mobile-profile-position-item__cell">{currentRate}</div>
				<div className="mobile-profile-position-item__cell">{<FormatPercentFloat value={PL} />}</div>
			</div>
	);
};

export default MobileProfilePositionItemOpen;
