import React, {useCallback, useMemo} from 'react';
import clsx from 'clsx';

import useSwiper from '../hook/useSwiper';
import useWindowSize from '../hook/useWindowSize';

import useControls from '../context/contextHook/useControls';
import useProfile from '../context/contextHook/useProfile';
import usePosition from '../context/contextHook/usePosition';

const MobileSwiper = props => {
	const {touchRef, children, className, type} = props;

	const {
		footerControls,
		tradeControls,
		avatarControls,
		burgerControls,
		headerControls: {headerRef},
		sectionControls: {sectionHeight},
	} = useControls();

	const {profileUser, copiedUser} = useProfile();
	const {positionClose, positionUpdate} = usePosition();

	const {height, clientHeight} = useWindowSize();

	const headerHeight = useCallback(() => {
		if (headerRef && !headerRef.current) return 0;
		return headerRef.current.offsetHeight;
	}, [headerRef, clientHeight, height]);

	const contentHeight = useMemo(() => {
		switch (type) {
			case 'footer' :
			case 'avatar' :
			case 'profile' :
				return height - headerHeight();
			case 'positionClose' :
			case 'positionUpdate' :
				return height - headerHeight();
			case 'trade' :
				return sectionHeight;
			case 'burger' :
				return height;
			default:
				return height;
		}
	}, [height, clientHeight, headerHeight, type, sectionHeight]);

	const contentIsOpen = useMemo(() => {
		switch (type) {
			case 'footer' :
				return footerControls.isOpen;
			case 'trade' :
				return tradeControls.isOpen;
			case 'profile' :
				return !!profileUser.profileUserName;
			case 'positionClose' :
				return !!positionClose.closeData;
			case 'positionUpdate' :
				return !!positionUpdate.updateData;
			case 'avatar' :
				return avatarControls.isOpen;
			case 'burger' :
				return burgerControls.isOpen;
			default:
				return false;
		}
	}, [
		type,
		footerControls,
		footerControls.isOpen,
		tradeControls,
		tradeControls.isOpen,
		profileUser,
		profileUser.profileUserName,
		positionClose,
		positionClose.closeData,
		positionUpdate,
		positionUpdate.updateData,
		avatarControls,
		avatarControls.isOpen,
		burgerControls,
		burgerControls.isOpen,
	]);

	const contentHandlers = useMemo(() => {
		switch (type) {
			case 'footer':
				return {
					onOpen: footerControls.handleOpen,
					onClose: footerControls.handleClose,
				};
			case 'trade':
				return {
					onOpen: tradeControls.handleOpen,
					onClose: tradeControls.handleClose,
				};
			case 'profile':
				return {
					onOpen: d => d,
					onClose: profileUser.closeProfileUser,
				};
			case 'positionClose':
				return {
					onOpen: d => d,
					onClose: positionClose.handleCloseHide,
				};
			case 'positionUpdate':
				return {
					onOpen: d => d,
					onClose: positionUpdate.handleUpdateHide,
				};
			case 'avatar':
				return {
					onOpen: d => d,
					onClose: avatarControls.handleClose,
				};
			case 'burger':
				return {
					onOpen: d => d,
					onClose: burgerControls.handleClose,
					onWidth: burgerControls.onWidth,
				};
			default:
				return null;
		}
	}, [
		type,
		footerControls,
		tradeControls,
		profileUser,
		positionClose,
		positionUpdate,
		avatarControls,
		burgerControls,
	]);

	const callback = {
		onOpen: () => contentHandlers.onOpen(),
		onClose: () => contentHandlers.onClose(),
		...contentHandlers,
	};

	const options = {
		height: contentHeight || '',
		isOpen: contentIsOpen || false,
	};

	const {contentRef} = useSwiper({
		type,
		touchRef,
		callback,
		options,
	});

	return (
			<div
					id="mobile-swiper"
					ref={contentRef}
					className={clsx('mobile-swiper', className)}
					style={{height: `${contentHeight}px`}}
			>
				{children}
			</div>
	);
};

export default MobileSwiper;
