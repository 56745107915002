import React, {useMemo} from 'react';

import MobileButtonClose from '../../components/Buttons/MobileButtonClose';

import PubsubSubscription from '../../../background/repositories/PubsubSubscription';
import {prepareBidAsk} from '../../../UI/utilsUI/formatWSTick';
import MobileSymbolIcon
	from '../../components/MobileSymbolIcon/MobileSymbolIcon';

const MobileTradeHeader = ({onClose, selectedSymbol, reference}) => {

	const pipDigit = useMemo(() => {
		const {displayDigit} = selectedSymbol;
		return displayDigit;
	}, [selectedSymbol]);

	const [symbolTick, previousTick] = PubsubSubscription.subscribeObjAndPrev(
			selectedSymbol?.tick);

	const {bidOutput, askOutput} = useMemo(() => {
		return prepareBidAsk(symbolTick, previousTick, pipDigit);
	}, [symbolTick, previousTick, pipDigit]);

	const title = useMemo(() => {
		const {displayName} = selectedSymbol;
		return displayName;
	}, [selectedSymbol]);

	return (
			<div
					ref={reference}
					className="mobile-trade__header"
			>

				<div className="mobile-trade__header__desc">
					<div className="mobile-trade__header__desc__flag">
						<MobileSymbolIcon
								name={selectedSymbol.displayName}
								flag={selectedSymbol.flag}
						/>
					</div>
					<p className="mobile-trade__header__desc__title">
						{title}
					</p>
				</div>

				<div className="mobile-trade__header__desc__tick">
					{askOutput}
				</div>

				<div className="mobile-trade__header__desc__tick">
					{bidOutput}
				</div>

				<div className="mobile-trade__header__close" onClick={onClose}>
					<MobileButtonClose/>
				</div>
			</div>
	);
};

export default MobileTradeHeader;
