import { useMemo, useState } from 'react';

const useProviderProfile = () => {
    const [isCopied, setIsCopied] = useState(false);
    const [profileUserName, setProfileUserName] = useState('');

    const copiedUser = useMemo(() => {
        return {
            isCopied,
            handleCopiedOpen: () => setIsCopied(true),
            handleCopiedClose: () => setIsCopied(false),
            handleCopiedSet: (status) => setIsCopied(status)
        };
    }, [isCopied, setIsCopied]);

    const profileUser = useMemo(() => {
        return {
            profileUserName,
            openProfileUser: (nickname = '', copy = false) => {
                if (!nickname) return null;
                setProfileUserName(nickname);
                if (copy) {
                    setIsCopied(true);
                }
            },
            closeProfileUser: () => {
                setProfileUserName('');
                setIsCopied(false);
            }
        };
    }, [profileUserName, setProfileUserName, setIsCopied]);

    return {
        copiedUser,
        profileUser
    };
};

export default useProviderProfile;
