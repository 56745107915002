import React, {
    useCallback, useMemo, useRef, useState
} from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { CommandFactory } from '../../../background/commands/CommandFactory'

import { getCurrentAvatar } from '../../helpers/options.helpers';

import useClickOutside from '../../hook/useClickOutside';

import useControls from '../../context/contextHook/useControls';
import useTheme from '../../context/contextHook/useTheme';

import MobileSwiper from '../../components/MobileSwiper';

import MobileBurgerMenuHeader from './MobileBurgerMenuHeader';
import MobileBurgerMenuSection from './MobileBurgerMenuSection';

import MobileTransition from '../../components/MobileTransition';
import MobileSectionModal
    from '../../components/MobileModal/MobileSectionModal';
import MobileLanguageSelector
    from '../../components/MobileLanguageSelector/MobileLanguageSelector';
import MobileLoader from '../../components/MobileLoader/MobileLoader';

const MobileBurgerMenu = () => {
    const [isMenuLanguage, setIsMenuLanguage] = useState(false);
    const [isEduExpanded, setIsEduExpanded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const touchRef = useRef(null);

    const {
        featuresData,
        userDetails,
        rightPanelSettings: { socialv1: { isVisible } },
        logoutCommand
    } = useSelector((state) => state.account.accountInfo, shallowEqual);

    const { userDetails: { nickname, avatar } } = useSelector(
        (state) => state.account.socialv1, shallowEqual
    );

    const { isDarkTheme } = useTheme();

    const {
        avatarControls,
        burgerControls: {
            isOpen, burgerRef, handleClose, burgerOpenWidth
        }
    } = useControls();

    const { education } = useMemo(() => {
        return featuresData;
    }, [featuresData]);

    const currentAvatar = useMemo(() => {
        return getCurrentAvatar(avatar)?.icon.type;
    }, [avatar]);

    const handleBurgerClose = useCallback(() => {
        handleClose && handleClose();
    }, [handleClose]);

    const handleAvatarClick = useCallback((e) => {
        e && e.preventDefault();
        e && e.stopPropagation();
        const { handleOpen } = avatarControls;
        handleOpen && handleOpen();
    }, []);

    const toggleEducation = useCallback((e) => {
        e && e.preventDefault();
        e && e.stopPropagation();
        setIsEduExpanded((prev) => !prev);
    }, []);

    const toggleLanguage = useCallback((e) => {
        e && e.preventDefault();
        e && e.stopPropagation();
        setIsMenuLanguage((prev) => !prev);
    }, []);

    const handleLogout = useCallback(async (e) => {
        e && e.preventDefault();
        e && e.stopPropagation();
        const command = CommandFactory.createCommand(logoutCommand);
        setIsLoading(true);
        try {
            const res = await command.execute();
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            console.log(err); // TODO: remove log
        }
    }, [logoutCommand, setIsLoading]);

    useClickOutside(burgerRef, handleBurgerClose);

    const style = useMemo(() => {
        const startPercent = burgerOpenWidth / 16e1;
        const endPercent = burgerOpenWidth * 16e1;

        const startColor = isDarkTheme
            ? `rgba(33, 39, 71, ${startPercent})`
            : `rgba(211, 213, 224, ${startPercent})`;
        const endColor = isDarkTheme
            ? `rgba(19, 23, 45, ${endPercent})`
            : `rgba(213, 214, 221, ${endPercent})`;

        return {
            background: `linear-gradient(180deg, ${startColor} 0%, ${endColor} 100%)`
        };
    }, [burgerOpenWidth, isDarkTheme]);

    return (
        <MobileTransition
            isOpen={isOpen}
            type='burger'
        >
            <div
                className='mobile-burger'
                style={{ ...style }}
            >
                <MobileSwiper
                    touchRef={touchRef}
                    type='burger'
                >
                    <MobileLoader isLoading={isLoading} />
                    <div
                        ref={burgerRef}
                        className='mobile-burger__content'
                    >
                        {/* HEADER */}
                        <MobileBurgerMenuHeader
                            handleAvatarClick={handleAvatarClick}
                            currentAvatar={currentAvatar}
                            userDetails={userDetails}
                        />

                        {/* SECTION */}
                        <MobileBurgerMenuSection
                            toggleLanguage={toggleLanguage}
                            education={education}
                            toggleEducation={toggleEducation}
                            handleLogout={handleLogout}
                        />

                        <MobileSectionModal
                            title='Trader Tools & Education'
                            isOpen={isEduExpanded}
                            onClose={toggleEducation}
                            overlay
                            full
                        >
                            <iframe
                                src={education?.educationUrl}
                                frameBorder='0'
                                title='Education'
                                width='100%'
                                height='100%'
                            />
                        </MobileSectionModal>

                        <MobileLanguageSelector
                            isOpen={isMenuLanguage}
                            onClose={toggleLanguage}
                        />
                    </div>
                </MobileSwiper>
            </div>
        </MobileTransition>
    );
};

export default MobileBurgerMenu;
