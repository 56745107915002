import {getStore} from "../storeContainer"
import CommandFactory  from '../commands/CommandFactory';

let evaluateSession = function()
{
  let store = getStore()
  if (store)
  {
    let state = store.getState();
    let renewSessionCommand = state?.account?.accountInfo?.renewSessionCommand;
    if (renewSessionCommand)
    {
      if (CommandFactory.isCommand(renewSessionCommand))
      {
        CommandFactory.createCommand(renewSessionCommand).execute();
      }
    }
  }
}

class RenewSessionService
{
  constructor()
  {
    this._interval = null;
  }

  start()
  {
    if (this._interval)
    {
      clearInterval(this._interval);
      this._interval = null;
    }
    if (!this._interval)
    {
      this._interval = setInterval(evaluateSession, 1000*60*8);
    }
  }
}

export default new RenewSessionService();