import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import MobileProfileStatistics from './MobileProfileStatistics';
import MobileSocialExploreChart
    from '../../components/MobileSocial/MobileSocialExplore/MobileSocialExploreChart';
import MobileRiskLevelContainer from '../../components/MobileRiskLevel/MobileRiskLevelContainer';
import MobileCarouselMenu
    from '../../components/MobileCarousel/MobileCarouselMenu';
import { PROFILE_MENU } from '../../constants/options';
import CarouselTabList from '../../components/MobileCarousel/CarouselTabList';
import MobileProfileStream
    from '../../components/MobileProfile/MobileProfileStream/MobileProfileStream';
import MobileProfilePositions
    from '../../components/MobileProfile/MobileProfilePositions/MobileProfilePositions';
import MobileProfilePortfolio
    from '../../components/MobileProfile/MobileProfilePortfolio/MobileProfilePortfolio';
import MobileProfileCommunity
    from '../../components/MobileProfile/MobileProfileCommunity/MobileProfileCommunity';
import MobileProfileButtonBlock from './MobileProfileButtonBlock';
import formatDaysFromNow from '../../../UI/utilsUI/formatDaysFromNow';
import { FormatPercentFloat } from '../../../UI/ProfilePage/ProfileTabDetails';
import { QUESTIONER_MODE_EXTENRAL } from '../../../background/socialv1/socialv1Reducer';

const MobileProfileMain = (props) => {
    const {
        UserDetails,
        WinRatio,
        yearPnl,
        SuccessRate,
        nickname,
        SocialFeed,
        CumulativePnl,
        handleOpenWatchModal,
        isCurrentUserProfile
    } = props;
    const [carouselTab, setCarouselTab] = useState(0);
    const { questionerMode } = useSelector(
        (state) => state.account.socialv1.socialAccount
    );
    const isExternalQuestionnaire = questionerMode === QUESTIONER_MODE_EXTENRAL;

    const daysFromNow = useMemo(() => {
        return formatDaysFromNow(new Date(UserDetails?.MetaJoinDate));
    }, [UserDetails]);

    const pnl = useMemo(() => {
        return Math.round(
            (CumulativePnl + Number.EPSILON) * 10000
        ) / 100;
    }, [CumulativePnl]);

    const successRate = useMemo(() => {
        return WinRatio
            ? FormatPercentFloat({ value: WinRatio })
            : SuccessRate && FormatPercentFloat({ value: SuccessRate }) || 0;
    }, [WinRatio, SuccessRate]);

    const handleTabChange = useCallback(({ index }) => {
        setCarouselTab && setCarouselTab(index);
    }, [setCarouselTab]);

    return (
        <div className='mobile-profile__content'>

            <div className='mobile-profile__content__statistics'>
                <MobileProfileStatistics
                    daysFromNow={daysFromNow}
                    pnl={pnl}
                    successRate={successRate || 0}
                />
                {nickname && (
                    <MobileSocialExploreChart userName={nickname} />
                )}
                <MobileRiskLevelContainer level={UserDetails?.RiskLevel} yearPnl={yearPnl} isExternal={isExternalQuestionnaire} />
            </div>

            <div
                className={
                    clsx('mobile-profile__content__carousel',
                        carouselTab === 2 && 'mobile-profile__content__carousel--chart',
                        isExternalQuestionnaire && 'mobile-profile__content__carousel--external')
                }
            >
                <MobileCarouselMenu
                    options={PROFILE_MENU}
                    onChange={handleTabChange}
                    type='secondary'
                    profile
                    value={carouselTab}
                />
                <CarouselTabList
                    index={carouselTab}
                    classname={clsx(!isCurrentUserProfile && 'hT50',
                        isCurrentUserProfile && 'h100')}
                >
                    <MobileProfileStream options={SocialFeed} />
                    <MobileProfilePositions nickname={nickname} />
                    <MobileProfilePortfolio nickname={nickname} />
                    <MobileProfileCommunity nickname={nickname} />
                </CarouselTabList>
            </div>

            {!isCurrentUserProfile && carouselTab !== 2 && (
                <MobileProfileButtonBlock
                    nickname={nickname}
                    onWatch={handleOpenWatchModal}
                />
            )}
        </div>
    );
};

export default MobileProfileMain;
