import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';

import { CommandFactory } from '../../../../background/commands/CommandFactory';
import {TIME_SWITCH__POSITION} from '../../../constants/options';

import MobileOpenPositionHead
	from '../MobileOpenPosition/MobileOpenPositionHead';
import MobileAutosizer
	from '../../../components/MobileAutosizer/MobileAutosizer';
import MobileOpenPositionItem
	from '../MobileOpenPosition/MobileOpenPositionItem';
import MobileLoader from '../../../components/MobileLoader/MobileLoader';
import MobileNothingFound
	from '../../../components/MobileEmpty/MobileNothingFound';
import MobileModalError from '../../../components/MobileModal/MobileModalError';
import {PreparePNL} from '../../../../UI/PNL/PNL';

const MobileClosedPosition = () => {

	const [timeSelected, setTimeSelected] = useState(TIME_SWITCH__POSITION[3]);
	const [currentItem, setCurrentItem] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [closedPosition, setClosedPosition] = useState([]);
	const [requestError, setRequestError] = useState(null);
	const [showTotalProfit, setShowTotalProfit] = useState(false);
	const [totalProfit, setTotalProfit] = useState(null);

	const {t} = useTranslation();

	const {positions, getAllCommand, isGetAllRequsted} = useSelector(
			state => state.account.positions.closed, shallowEqual);

	const {
		rightPanelSettings: {socialv1: {isVisible}},
		currency: {symbol},
		drawerSettings: {closePositionsTab},
	} = useSelector(state => state.account.accountInfo, shallowEqual);

	const {
		tradeLinks,
		isEnabled,
		closeTradeLinks,
		copyRelationsCommands: {getClosedPositionsLinksCommand},
	} = useSelector(state => state.account.socialv1, shallowEqual);

	const loading = useMemo(() => {
		return {
			on: () => setIsLoading(true),
			off: () => setIsLoading(false),
		};
	}, [setIsLoading]);

	const error = useMemo(() => {
		return {
			links: err => {
				setIsLoading({title: 'Links request error', error: err});
			},
			position: err => {
				setIsLoading({title: 'Closed Positions request error', error: err});
			},
			clear: () => setIsLoading(null),
		};
	}, [setRequestError]);

	const showCopiedFrom = useMemo(() => {
		return isEnabled && isVisible;
	}, [isEnabled, isVisible]);

	const positionsToRender = useMemo(() => {
		const positionsToRender = [];
		for (const obj in positions) {
			positionsToRender.push(positions[obj]);
		}
		positionsToRender.sort((a,b) => b.closeTimeUTC-a.closeTimeUTC);
		if (isGetAllRequsted) {
			setClosedPosition(positionsToRender);
		}
		return positionsToRender;
	}, [positions, isGetAllRequsted]);

	const handleTimeChange = useCallback(option => {
		if (!option) return null;
		setTimeSelected(option);
	}, []);

	const handleGetCloseLinks = useCallback(async () => {
		const commandObject = getClosedPositionsLinksCommand;
		if (!commandObject) return;
		const command = CommandFactory.createCommand(commandObject);
		return await command.execute();
	}, [getClosedPositionsLinksCommand]);

	const handleGetClosePosition = useCallback(async () => {
		if (isGetAllRequsted) return;
		const command = CommandFactory.createCommand(getAllCommand);
		loading.on();
		try {
			const res = await command.execute({period: 1});
			if (res.isSuccessful) {
				const pos = res.payload?.closedPositions?.positions;
				setClosedPosition(pos);
			}
			if (!res.isSuccessful) {
				error.position('Closed position request error');
			}
			loading.off();
		} catch (err) {
			loading.off();
			error.position(err?.message);
		}
	}, [getAllCommand, isGetAllRequsted, setClosedPosition, loading, error]);

	const handleOpenRow = useCallback(props => {
		setCurrentItem(props);
	}, [setCurrentItem]);

	const renderColumns = useMemo(() => {
		return [
			'instrument',
			'type',
			'amount',
			'profit',
			'user',
			'arrow',
		];
	}, [positionsToRender]);

	const sortPosition = useMemo(() => {
		if (!closedPosition.length) return [];
		return closedPosition.sort((a,b) => b.closeTimeUTC-a.closeTimeUTC);
	}, [closedPosition]);

	const handleTotalProfit = useCallback(() => {
		let total = 0;
		if (closePositionsTab.totalProfitType === 'includeCommissions') {
			for (const position of sortPosition) {
				total += position.profit + position.commission + position.swaps;
			}
			setTotalProfit(total);
			setShowTotalProfit(true);
		} else if (closePositionsTab.totalProfitType === 'excludeCommissions') {
			for (const position of sortPosition) {
				total += position.profit;
			}
			setTotalProfit(total);
			setShowTotalProfit(true);
		}
	}, [sortPosition, closePositionsTab, closePositionsTab.totalProfitType]);

	useEffect(() => {
		handleTotalProfit();
	}, [handleTotalProfit]);

	useEffect(() => {
		handleGetCloseLinks();
		handleGetClosePosition();
	}, []);

	const renderRow = data => {
		const {item, props} = data;
		return (
				<MobileOpenPositionItem
						{...item}
						{...props}
						columns={renderColumns}
				/>
		);
	};

	return (
			<div className="mobile-position__closed">
				<MobileLoader isLoading={isLoading} overlay/>

				<MobileNothingFound isOpen={!closedPosition.length && !isLoading}/>

				<MobileModalError
						isOpen={!!requestError}
						title={requestError?.title}
						subtitle={requestError?.error}
						onClose={error.clear}
				/>

				{!!closedPosition.length && (
						<div className="mobile-position">
							<MobileOpenPositionHead columns={renderColumns} sort/>

							<div
									className={clsx('mobile-position__closed-wrapper',
											showTotalProfit && 'profit--show')}
							>
								<MobileAutosizer
										minHeight={40}
										maxHeight={220}
										currentItem={currentItem}
										handleOpenRow={handleOpenRow}
										showCopiedFrom={showCopiedFrom}
										tradeLinks={tradeLinks}
										// options={positionsToRender}
										// options={closedPosition}
										options={sortPosition}
										renderRow={renderRow}
										closeTradeLinks={closeTradeLinks}
										className="mobile-open-position__row"
										positionType="closed"
								/>
							</div>

							{showTotalProfit && (
									<div className='closed-tab__profit-total'>
										<p className='closed-tab__profit-total__title'>
											{t('InstrumetInfo.Details.Profit')}
											:&nbsp;
										</p>
										<PreparePNL
												pnlTick={{openPl: totalProfit}}
												symbol={symbol}
												shouldFormat
										/>
									</div>
							)}

						</div>
				)}

			</div>

	);
};

export default MobileClosedPosition;
