import {TicksRepository} from './TicksRepository'
import {ProfitsRepository,AccountMovingPLRepository} from './ProfitsRepository'


let _subscriptions = new Map()

_subscriptions.set('ticks',TicksRepository);
_subscriptions.set('PNL',ProfitsRepository);
_subscriptions.set('ACCOUNT_PNL',AccountMovingPLRepository);

class pubsubSubscription
{
  isSubscriptionObj(obj)
  {
    if (obj !== null && obj !== undefined)
    {
      if (obj.repId)
      {
        if (_subscriptions.has(obj.repId))
        {
          if (obj.key !== undefined && obj.key !== null)
          {
            return true;
          }
        }
      }
    }
  }

  getPubsub(obj)
  {
    let pubsub =_subscriptions.get(obj.repId);
    return pubsub;
  }

  getValueNative(obj)
  {
    let pubsub =_subscriptions.get(obj.repId);
    if (pubsub)
    {
      return pubsub.getNative(obj.key);
    }
  }

  subscribeObjNative(obj,callback)
  {
    let pubsub =_subscriptions.get(obj.repId);
    if (pubsub)
    {
      return pubsub.subscribeNative(obj.key,callback);
    }
    return {dispose:()=>{}}
  }

  subscribeObjAndPrev(obj)
  {
    let pubsub =_subscriptions.get(obj.repId);
    if (pubsub)
    {
      return pubsub.subscribeReactWithPrev(obj.key);
    }
  }

  subscribeObj(obj)
  {
    let pubsub =_subscriptions.get(obj.repId);
    if (pubsub)
    {
      return pubsub.subscribeReact(obj.key);
    }
  }
};

export default new pubsubSubscription();