import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useTheme from '../../context/contextHook/useTheme';

import { ReactComponent as Language } from '../../assets/icons/language.svg';
import { ReactComponent as About } from '../../assets/icons/about.svg';
import { ReactComponent as Tutorial } from '../../assets/icons/tutorial.svg';
import { ReactComponent as Feedback } from '../../assets/icons/feedback.svg';
import { ReactComponent as Logout } from '../../assets/icons/logout.svg';

import Switch from '../../../UI/primitives/Switch';

const MobileBurgerMenuSection = (props) => {
    const {
        toggleLanguage, education, toggleEducation, handleLogout
    } = props;

    const { languages, languageCode, enableChangeLanguage } = useSelector((state) => state.brand, shallowEqual);
    const { t } = useTranslation();
    const { handleToggleTheme, isDarkTheme, allowChangeTheme } = useTheme();

    const selectedLanguage = useMemo(() => {
        if (!languages || !languageCode) return null;
        const { displayName } = languages[languageCode];
        return displayName;
    }, [languages, languageCode]);

    return (
        <div className='mobile-burger__section'>
            <ul className='mobile-burger__section__menu'>
                {!!enableChangeLanguage && (
                    <li
                        className='mobile-burger__section__menu__item'
                        onClick={toggleLanguage}
                    >
                        <div className='mobile-burger__section__menu__item__inner'>
                            <Language />
                            <p className='mobile-burger__section__menu__item__title'>
                                {/* TODO: local this */}
                                Languages
                            </p>
                        </div>
                        <div className='mobile-burger__section__menu__item__lang'>
                            {selectedLanguage || languageCode || ''}
                        </div>
                    </li>
                )}

                <li
                    className='mobile-burger__section__menu__item'
                    // style={{position: 'relative', left: '-22px'}}
                >
                    {allowChangeTheme && (
                        <Switch
                            isSelected={isDarkTheme}
                            handleSwitch={handleToggleTheme}
                            name='toggleTheme'
                            offText={t('Settings.Theme.Dark')}
                        />
                    )}
                </li>

                <li
                    className='mobile-burger__section__menu__item'
                    onClick={handleLogout}
                >
                    <div className='mobile-burger__section__menu__item__inner'>
                        <Logout />
                        <p className='mobile-burger__section__menu__item__title'>
                            {/* TODO: local this */}
                            Swich Account
                        </p>
                    </div>
                </li>

            </ul>
        </div>
    );
};

export default MobileBurgerMenuSection;
