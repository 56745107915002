import React from 'react';
import { useTranslation } from 'react-i18next';

import PubsubSubscription from '../../background/repositories/PubsubSubscription';
import { roundNumberPipDigit } from '../utilsUI/formatWSTick';
import '../ResizableLayout/BottomPanel.css';


export const PreparePNL = ({ pnlTick, shouldFormat, symbol }) => {
    if (shouldFormat) {
        let { openPl } = pnlTick;
        const isNegative = openPl < 0;
        const pnlClass = isNegative ? 'red' : 'green';
        if (isNegative) {
            openPl = Math.abs(openPl);
        }
        return (
            <strong className={pnlClass}>
                {isNegative && '-'}
                {symbol}
                {roundNumberPipDigit(openPl, 2)}
            </strong>
        );
    }

    return (
        <strong>
            {symbol}
            {roundNumberPipDigit(pnlTick.equity, 2)}
        </strong>
    );
};


const PNL = ({ balance, credit, symbol, subHeaderColor, systemColor }) => {
    const { t } = useTranslation();
    const pnlTick = PubsubSubscription.subscribeObj({ key: 'MOVINGPNL', repId: 'ACCOUNT_PNL' });

    return (
        <div className='pnl__container' style={systemColor}>
            <div>
                <span>
                    {t('General.Balance')}
                </span>
                <strong>
                    {symbol}
                    {roundNumberPipDigit(balance, 2)}
                </strong>
            </div>
            <div className='pnl__seperator'>
                <strong>+</strong>
            </div>
            <div>
                <span>
                    {t('General.Credit')}
                </span>
                <strong>
                    {symbol}
                    {roundNumberPipDigit(credit, 2)}
                </strong>
            </div>
            <div className='pnl__seperator'>
                <strong>+</strong>
            </div>
            <div>
                <span>
                    {t('General.OpenPl')}
                </span>
                <PreparePNL pnlTick={pnlTick} shouldFormat symbol={symbol} />
            </div>
            <div className='pnl__seperator'>
                <strong>=</strong>
            </div>
            <div>
                <span>
                    {t('General.Equity')}
                </span>
                <PreparePNL pnlTick={pnlTick} symbol={symbol} />
            </div>
        </div>
    );
};

export default PNL;
