import React, {useCallback, useMemo} from 'react';
// import {AreaChart, Line, ComposedChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from recharts;
import {Area, ComposedChart, Tooltip} from 'recharts';

import useWindowSize from '../../../../UI/utilsUI/useWindowSize';
import MobileAreaChartTooltip from './MobileAreaChartTooltip';

const MobileAreaChart = props => {
	const {
		data,
		width,
		height,
		type,
	} = props;

	const [winWidth, winHeight] = useWindowSize();

	const currentWidth = useMemo(() => {
		return width || winWidth - 40;
	}, [data, width, winWidth]);

	const currentHeight = useMemo(() => {
		return height || 100;
	}, [data, height, winHeight]);

	const renderChart = useCallback(() => {
		return type
	}, [type, data])

	return (
			<div className="mobile-chart-area">
				<ComposedChart
						data={data}
						width={currentWidth}
						height={currentHeight}
						margin={{top: 0, right: 0, left: 0, bottom: 0}}>
					>
					<defs>
						<linearGradient id="colorMain" x1="0" y1="0" x2="0" y2="1">
							<stop offset="0%" stopColor="#1CB5EE" stopOpacity={0.3}/>
							<stop offset="100%" stopColor="#1CB5EE" stopOpacity={0.01}/>
						</linearGradient>
					</defs>
					<Tooltip content={<MobileAreaChartTooltip/>}/>
					<Area
							type="monotone"
							baseLine={0}
							dataKey="value"
							stroke='#1CB5EE'
							strokeWidth={1}
							fillOpacity={1}
							fill="url(#colorMain)"
					/>
				</ComposedChart>
			</div>
	);
};

MobileAreaChart.deefaultProps = {
	data: [],
	width: 0,
	height: 0,
	type: 'area',
};

export default MobileAreaChart;
