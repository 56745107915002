import React, { useState, useEffect, Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { CommandFactory } from '../../background/commands/CommandFactory';
import ModalWrapper from '../primitives/ModalWrapper';
import Button from '../primitives/Button';

import QuestionStepOne from './QuestionStepOne';
import QuestionStepTwo from './QuestionStepTwo';
import QuestionStepThree from './QuestionStepThree';
import './Questionnaire.css';


const Questionnaire = ({ setQuestionnaire, open }) => {
    const [steps, setSteps] = useState({
        step: 0,
        stepData: [
            { isFulfilled: false, selectedOption: null },
            {
                first: '',
                second: '',
                slide: 1
            },
            {
                third: '',
                fourth: ''
            }
        ]
    });
    const [showThankYou, setShowThankYou] = useState(false);
    const [showCancel, setShowCancel] = useState(false);
    const { t } = useTranslation();
    const { registrationCommands: { getLfsSuitabilityTestResultsCommand, updateLfsSuitabilityTestResultCommand }, userDetails } = useSelector((state) => state.account.socialv1);
    const { Text: { SystemColor }, Buttons: { TextColor } } = useSelector((state) => (state.brand.theme), shallowEqual);
    const getQuestionaireData = async () => {
        const userInput = { nickname: userDetails?.nickname };

        const command = CommandFactory.createCommand(getLfsSuitabilityTestResultsCommand);

        try {
            const res = await command.execute(userInput);

            if (res.ok === false) {
                // setError({ result: res.statusText });
            }
            if (res.isSuccessful === true) {
                const payload = res?.payload;
                setSteps((prevState) => ({
                    ...prevState,
                    stepData: [
                        { isFulfilled: !!payload.Q1, selectedOption: payload.Q1 },
                        {
                            first: payload.Q2 || '',
                            second: payload.Q4 || '',
                            slide: payload.Q3 || 1
                        },
                        {
                            third: payload.Q5P1 || '',
                            fourth: payload.Q5P2 || ''
                        }
                    ]
                }));
            } else if (res.isSuccessful === false) {
                // setError(res);
            }
        } catch (err) {
            // setError(err);
        }
    };

    const updateQuestionnaireData = async () => {
        const userInput = {
            ver: 1,
            q1: steps.stepData[0].selectedOption,
            q2: steps.stepData[1].first,
            q3: steps.stepData[1].slide,
            q4: steps.stepData[1].second,
            q5p1: steps.stepData[2].third,
            q5p2: steps.stepData[2].fourth
        };

        const command = CommandFactory.createCommand(updateLfsSuitabilityTestResultCommand);

        try {
            const res = await command.execute(userInput);

            if (res.ok === false) {
                // setError({ result: res.statusText });
            }
            if (res.isSuccessful === false) {
                // setError(res);
            }
        } catch (err) {
            // setError(err);
        }
    };

    useEffect(() => {
        getQuestionaireData();
    }, []);

    const handleNextChange = () => {
        if (steps.stepData[2].isFulfilled && steps.step === 2) {
            // setShowThankYou(true);
            updateQuestionnaireData().then(
                // () => setQuestionnaire(false)
                () => setShowThankYou(true)
            );
        } else if (steps.stepData[steps.step].isFulfilled) {
            setSteps((prevState) => ({
                ...prevState, step: prevState.step + 1
            }));
        }
    };

    const handleStepChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (steps.stepData[steps.step].isFulfilled || steps.step > value) {
            setSteps((prevState) => ({
                ...prevState, step: value
            }));
        }
    };

    const onQuestionnaireClosed = () => {
        if (showThankYou) {
            setQuestionnaire(false);
        } else {
            setShowCancel(true);
        }
    };

    const onAbortQuestionnaireClosed = () => {
        setShowCancel(false);
    };

    const handleAbandonQuestions = () => {
        updateQuestionnaireData().then(
            setQuestionnaire(false)
        );
    };

    const handleClose = () => {
        setQuestionnaire(false);
    };

    return (
        <ModalWrapper
            open={open}
            handleClose={onQuestionnaireClosed}
            onBackdropClick={onQuestionnaireClosed}
            heading={t('Socialv1.Suitability.Header')}
            childClassName='question-page'
            contentClassName='content'
            disableBackdropClick
        >
            <div><LinearProgress variant='determinate' value={(steps.step + 1) * 33.33} /></div>
            {steps.step === 0 && !showThankYou && (
                <QuestionStepOne steps={steps} setSteps={setSteps} handleNextChange={handleNextChange} />
            )}
            {steps.step === 1 && !showThankYou && (
                <QuestionStepTwo steps={steps} setSteps={setSteps} systemColor={SystemColor} buttonColor={TextColor} />
            )}
            {steps.step === 2 && !showThankYou && (
                <QuestionStepThree steps={steps} setSteps={setSteps} />
            )}
            {!showThankYou ? (
                <Fragment>
                    {(steps.step === 1 || steps.step === 2) && <Button className='btn btn--general btn--next' text={t('General.Next')} onClickHandler={handleNextChange} />}
                    <div className='question__pagination'>
                        <RadioGroup row value={steps.step} defaultValue={0} aria-label='gender' onChange={handleStepChange}>
                            <Radio icon={<div className='dot' />} checkedIcon={<div className='dot dot--checked' />} disableRipple value={0} />
                            <Radio icon={<div className='dot' />} checkedIcon={<div className='dot dot--checked' />} disableRipple value={1} />
                            <Radio icon={<div className='dot' />} checkedIcon={<div className='dot dot--checked' />} disableRipple value={2} />
                        </RadioGroup>
                    </div>
                </Fragment>
            ) : (
                <div className='order__successful-container'>
                    <div className='order__successful'>
                        <div className='question__successful-image' />
                        <h3>
                            <Trans i18nKey='Socialv1.Suitability.Thanks' />
                        </h3>
                    </div>
                    <button className='btn btn--general btn--next btn--done' onClick={() => { setQuestionnaire(false); }} type='button'>{t('General.Done')}</button>
                </div>
            )}
            <ModalWrapper
                open={showCancel}
                handleClose={onAbortQuestionnaireClosed}
                childClassName='question-page__cancel'
                contentClassName='question-page__cancel-content'
            >
                <h3>{t('Socialv1.Suitability.Abandone.Title')}</h3>
                <p>{t('Socialv1.Suitability.Abandone.InnerTitle')}</p>
                <div className='question-page__cancel-buttons'>
                    <Button className='btn btn--general' text={t('General.Yes')} onClickHandler={handleAbandonQuestions} />
                    <Button className='btn btn--general btn--cancel' text={t('General.Cancel')} onClickHandler={onAbortQuestionnaireClosed} />
                </div>
            </ModalWrapper>
        </ModalWrapper>
    );
};

// Questionnaire.propTypes = {};

export default Questionnaire;
