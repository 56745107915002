import React, { Component } from 'react';
// import PropTypes from 'prop-types';


class Tab extends Component {
    onClick = () => {
        const { name, onClick } = this.props;
        onClick(name);
    }

    render() {
        const {
            onClick,
            props: {
                activeTab,
                label,
                name,
                tabChildComponent,
                shouldHideName,
                activeColor,
                inactiveColor
            }
        } = this;

        let className = 'tab-list-item';
        let style = { color: inactiveColor };

        if (activeTab === name) {
            className += ' tab-list-active';
            style = { color: activeColor };
        }


        return (
            <div
                className={className}
                onClick={onClick}
                style={style}
            >
                {!shouldHideName && label}
                {tabChildComponent}
            </div>
        );
    }
}

// Tab.defaultProps = {
//     label: undefined
// };

// Tab.propTypes = {
//     activeTab: PropTypes.string.isRequired,
//     label: PropTypes.string,
//     onClick: PropTypes.func.isRequired
// };

export default Tab;
