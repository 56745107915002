import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
//  import 'react-colorful/dist/index.css';
// import PropTypes from 'prop-types';

import Button from '../primitives/Button';


const IndicatorsPopup = ({ indicator, handleUpdateIndicator, closeModal }) => {
    const { name, settings, styles } = indicator;
    const [settingsList, setSettingsList] = useState(settings);
    const { t } = useTranslation();
    if (!settings) {
        handleUpdateIndicator({ name, styles });
        closeModal();
        return null;
    }

    const handleIndicatorChange = (e) => {
        e.persist();
        setSettingsList((prevState) => { return { ...prevState, [e.target.name]: parseInt(e.target.value, 10) || '' }; });
    };

    const updateIndicator = () => {
        handleUpdateIndicator({ name, settings: settingsList, styles });
        closeModal();
    };

    const renderForm = Object.entries(settingsList).map(([key, value]) => {
        let text;
        if (name === 'StochasticOscillator' && key === 'n') {
            text = t('Indicator.StochasticOscillator.Settings.N');
        } else if (key === 'n') {
            text = t('Indicator.Settings.Length');
        } else {
            text = t(`Indicator.${name}.Settings.${key}`);
        }

        return (
            <div key={key}>
                <div className='update-indicator__field'>
                    <InputLabel htmlFor={`indicator-${key}`}>{text}</InputLabel>
                    <Input fullWidth type='number' value={value} onChange={handleIndicatorChange} id={`indicator-${key}`} name={key} />
                </div>
            </div>
        );
    });


    return (
        <div className='indicators-form'>
            <div className='indicators-form__fields'>
                <div>
                    {renderForm}
                </div>
            </div>
            <Button className='btn succes' text='Create Indicator' onClickHandler={updateIndicator} />
        </div>
    );
};

// IndicatorsPopup.propTypes = {};

export default IndicatorsPopup;
