import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {shallowEqual, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';

import MobileInputSearch
	from '../../../components/MobileAction/MobileInputSearch';
import MobileTransition from '../../../components/MobileTransition';

import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '../../../../UI/icons/SearchIcon';
import MobileCarouselMenu
	from '../../../components/MobileCarousel/MobileCarouselMenu';

const MobileTradingAllActionBlock = props => {
	const {onChangeSearch, onChangeGroup, isVisible} = props;

	const {t} = useTranslation();

	const {Buttons: {TextColor}, Text: {GeneralColor}} = useSelector(
			state => state.brand.theme, shallowEqual);
	const {symbols, symbolsOrder} = useSelector(state => state.account.symbols,shallowEqual);
  const { marketRatesSettings } = useSelector(state => state.account.accountInfo, shallowEqual);
	const [symbolGroups, setSymbolGroups] = useState([]);
	const [groupsTab, setGroupsTab] = useState(0);
	const [stateObject, setStateObject] = useState({symbol: '', symbolGroup: ''});

	const handleChange = useCallback(e => {
		const {name, value} = e.target;
		setStateObject((prev) => ({
			...prev,
			[name]: value,
		}));
		if (name === 'symbol') return onChangeSearch && onChangeSearch(value);
		if (name === 'symbolGroup') return onChangeGroup && onChangeGroup(value);
	}, [setStateObject, onChangeSearch, onChangeGroup]);

	const resetSearch = useCallback(() => {
		setStateObject((prev) => ({
			...prev,
			symbol: '',
		}));
		return onChangeSearch && onChangeSearch('');
		// searchSubmit(null, 'MarketRates.Groups.All');
	}, [setStateObject, handleChange, onChangeSearch]);

	const handleGroupChange = useCallback(({index, value}) => {
		setGroupsTab(index);
		onChangeGroup && onChangeGroup(value.value);
	}, [onChangeGroup]);

	const icon = useMemo(() => {
		return stateObject.symbol ? (
				<InputAdornment position='end'>
					<Icon className='fas fa-times' onClick={resetSearch}/>
				</InputAdornment>
		) : (
				<InputAdornment position='end'>
					<SearchIcon fill={GeneralColor}/>
				</InputAdornment>
		);
	}, [GeneralColor, resetSearch, stateObject, stateObject.symbol]);

	useEffect(() => {
    const all = 'MarketRates.Groups.All';
		var arr = [all];
    var groups = new Set();
    groups.add(all);

    for (const symbolId of symbolsOrder) {
      const stateSymbol = symbols[symbolId];
      if (stateSymbol) {
          if (stateSymbol.group) {
              groups.add(stateSymbol.group);
          }
      }
    }

    if(marketRatesSettings.groupsOrder?.length > 0){
      for(const group of marketRatesSettings.groupsOrder){
        if(groups.has(group)){
          arr.push(group);
        }
      }
    }else{
       arr = [...groups];
    }

		const rates = arr.map((el, i) => {
			return {
				id: i,
				label: el,
				value: el,
				translation: el,
			};
		});
		setSymbolGroups(rates);
		setStateObject(prev => ({...prev, symbolGroup: rates[0]}));
	}, [symbolsOrder, symbols]);

	return (
			<MobileTransition type='action' isOpen={isVisible}>
				<div
						className={clsx('mobile-trading-all__action',
								!isVisible && 'hide')}
				>
					<MobileCarouselMenu
							options={symbolGroups}
							onChange={handleGroupChange}
							type="secondary"
							value={groupsTab}
					/>

					<div className="mobile-trading-all__action__wrapper-search">
						<MobileInputSearch
								id='symbol-search'
								placeholder={t('MarketRates.Search')}
								name='symbol'
								className='mobile-trading-all__action__search'
								value={stateObject.symbol}
								onChange={handleChange}
								endAdornment={icon}
								style={{color: TextColor}}
						/>
					</div>

				</div>
			</MobileTransition>
	);
};

MobileTradingAllActionBlock.defaultProps = {
	isVisible: true,
};

MobileTradingAllActionBlock.propTypes = {
	isVisible: PropTypes.bool,
};

export default MobileTradingAllActionBlock;
