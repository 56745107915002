import {
  AVATARS,
  MAIN_MENU,
  POSITION_MENU,
  PROFILE_COPY,
  TRADING_MENU,
} from '../constants/options';

const DEFAULT_AVATAR_NAME = 'defaultavatar';

export const getProfileCopyOptions = (hideFixed = false) => {
  if (!hideFixed) return PROFILE_COPY;
  return PROFILE_COPY.filter(el => el.key !== 'fixed');
};

export const getMainMenu = (socialIsVisible,signalsEnabled) => {
  let menu = MAIN_MENU;
  
  if (!socialIsVisible)
    menu = menu.filter(option => option.type !== 'social')
  if (!signalsEnabled)
    menu = menu.filter(option => option.value !== 'signals')

  return menu;
};

export const getFooterMenu = socialIsVisible => {
  if (socialIsVisible) return POSITION_MENU;
  return POSITION_MENU.filter(option => option.type !== 'social');
};

export const getTradingMenu = favoriteIsVisible => {
  if (favoriteIsVisible) return TRADING_MENU;
  return TRADING_MENU.filter(option => option.value !== 'favorites');
};

export const getAvatarImage = index => {
  return {
    id: AVATARS[index].id,
    icon: AVATARS[index].icon,
  };
};

export const getCurrentAvatar = avatar => {
  if (!avatar) return null;
  const isAvatars = AVATARS.find(
      el => el.avatar.toLowerCase().includes(avatar.toLowerCase()));
  const currentAvatar = !!isAvatars ? avatar : 'defaultAvatar8';

  const id = Number(
      currentAvatar.toLowerCase().replace(DEFAULT_AVATAR_NAME, ''));

  const obj = AVATARS.find(el => el.id === id);
  const index = AVATARS.findIndex(el => el.id === id);

  return {...obj, index};
};

export const createAvatarName = id => {
  return `${DEFAULT_AVATAR_NAME}${id}`;
};
