import produce from 'immer';
import { replaceIfChanged } from '../StoreUtils/overrideState';
import {
    allTradesStream, topRankedTradeStream, watchTradersStream, isSupportedSymbol
} from './tradesStreams';

export const QUESTIONER_MODE_EXTENRAL = 'external';

const initState = {
    isEnabled: false,
    hasInit: false,
    hideCopyByFixedLot: false,
    showSocialStream: { cmd: 'TradesStrem' },
    initSocialDataCommand: null,
    moreSocialDataCommand: null,
    searchProfileCommand: null,
    socialAccount: {
        questionerMode: 'internal',
        externalRiskScore: null,
        queryRiskScoreCMD: null
    },
    exploreCommands: {
        getTopRanked: null,
        getMostCopied: null,
        getMostProfitable: null,
        advanceSearch: null
    },
    registrationCommands: {
        joinSocialCommand: null,
        leaveSocialCommand: null,
        isMasterCommand: null,
        generateNickNameCommand: null,
        updateAvatarCommand: null,
        updatePersonalDescriptionCommand: null,
        logLfsCopyRequestWithDisclaimerCommand: null,
        getLfsSuitabilityTestResultsCommand: null,
        updateLfsSuitabilityTestResultCommand: null,
        abandonSuitabilityTestCommand: null
    },
    copyRelationsCommands: {
        addFollower: null,
        updateFollower: null,
        removeFollower: null,
        unlinkTradeCommand: null,
        getClosedPositionsLinksCommand: null
    },
    profileCommands: {
        getUserProfilePage: null,
        getPortfolioBreakdown: null,
        getFriends: null,
        getPositions: null,
        getDataForCopy: null,
        getGetPlAndStats: null
    },
    tradeLinks: {},
    closeTradeLinks: {},
    copying: {},
    following: {},
    isJoined: false,
    topTraders: {},
    topTradersOrder: [],
    spotlightTraders: {},
    userDetails: {
        nickname: null,
        isEnabled: false,
        avatar: null
    },
    lfsRegulationsDetails: {
        isLfsRegulated: false,
        lfsFinishedSuitabilityTest: false,
        lfsRiskScore: 0
    }
};

export const linkType = {
    Linked: 0,
    Unlinked: 1,
    Manual: 2
};

export const socialv1Reducer = function generalStatusReducer(state = initState, action, globalState) {
    switch (action.type) {
    case 'login-logout':
        return initState;
    case 'init-brand+login':
    {
        const socialData = action.payload.loginTradingData.socialV1Data ?? {};
        return produce(state, (draf) => {
            handleSocialDataUpdated(draf, socialData);
        });
    }
    case 'login-success':
    {
        const socialData = action.payload.socialV1Data ?? {};
        return produce(state, (draf) => {
            handleSocialDataUpdated(draf, socialData);
        });
    }
    case 'socialv1-external-updated':
    {
        return produce(state, (draf) => {
            draf.socialAccount.externalRiskScore = action.payload.socialAccount.externalRiskScore;
        });
    }
    case 'socialv1-push-tradeLink':
    {
        return produce(state, (draf) => {
            const isCloseLinksHandle = false;
            handlePositionsLinks(draf, action.payload.tradeLinks, isCloseLinksHandle);
        });
    }
    case 'socialv1-closed-tradeLinks':
    {
        return produce(state, (draf) => {
            if (action.payload) {
                const isCloseLinksHandle = true;
                handlePositionsLinks(draf, action.payload, isCloseLinksHandle);
            }

            if (draf.copyRelationsCommands.getClosedPositionsLinksCommand && draf.copyRelationsCommands.getClosedPositionsLinksCommand.userInput.isFirstTime) {
                if (Object.keys(draf.closeTradeLinks).length > 0) {
                    draf.copyRelationsCommands.getClosedPositionsLinksCommand.userInput.isFirstTime = false;
                }
            }
        });
    }
    case 'socialv1-init':
    {
        const socialData = action.payload;
        return produce(state, (draf) => {
            handleSocailInitData(draf, socialData);
        });
    }
    case 'socialv1-more-data':
    {
        const socialData = action.payload;
        return produce(state, (draf) => {
            for (const source of socialData.topRankedPerformers ?? []) {
                setTopTrader(source, draf.topTraders, draf.topTradersOrder);
            }

            for (const source of socialData.spotLightPerformers ?? []) {
                setTopTrader(source, draf.spotlightTraders, []);
            }

            if (socialData.initSocialStream) {
                for (const source of socialData.initSocialStream.EveryoneStream ?? []) {
                    if (isSupportedSymbol(source, globalState.account.symbols.symbols)) {
                        allTradesStream.pushItem(source);
                    }
                }

                for (const source of socialData.initSocialStream.TopTradersStream ?? []) {
                    if (isSupportedSymbol(source, globalState.account.symbols.symbols)) {
                        topRankedTradeStream.pushItem(source);
                    }
                }

                for (const source of socialData.initSocialStream.TradersIWatchStream ?? []) {
                    if (isSupportedSymbol(source, globalState.account.symbols.symbols)) {
                        watchTradersStream.pushItem(source);
                    }
                }
            }
        });
    }
    case 'socialv1-init-after-registration-action': {
        const socialData = action.payload.payload;
        return produce(state, (draf) => {
            handleSocailInitData(draf, socialData);
        });
    }
    default:
        return state;
    }
};

function handlePositionsLinks(draf, positionsLinks, isClosedLinks) {
    const relevantCollection = isClosedLinks ? draf.closeTradeLinks : draf.tradeLinks;
    for (const link of positionsLinks) {
        const tpId = link.PositionID.toString();
        let currLink = relevantCollection[tpId];

        if (!currLink) {
            currLink = {
                tpId
            };
            relevantCollection[tpId] = currLink;
        }

        currLink.linkType = link.TradeLinkType;
        currLink.sourceStopLoss = link.MasterStopLossRate;
        currLink.sourceTakeProfit = link.MasterTakeProfitRate;
        currLink.sourceNickName = link.MasterNickname;
    }
}

function handleSocailInitData(draf, socialData) {
    draf.hasInit = true;
    replaceIfChanged(draf, 'searchProfileCommand', socialData.searchProfileCommand);
    replaceIfChanged(draf.exploreCommands, 'getTopRanked', socialData.exploreCommands.getTopRanked);
    replaceIfChanged(draf.exploreCommands, 'getMostCopied', socialData.exploreCommands.getMostCopied);
    replaceIfChanged(draf.exploreCommands, 'getMostProfitable', socialData.exploreCommands.getMostProfitable);
    replaceIfChanged(draf.exploreCommands, 'advanceSearch', socialData.exploreCommands.advanceSearch);

    replaceIfChanged(draf.profileCommands, 'getUserProfilePage', socialData.profileCommands.getUserProfilePage);
    replaceIfChanged(draf.profileCommands, 'getPortfolioBreakdown', socialData.profileCommands.getPortfolioBreakdown);
    replaceIfChanged(draf.profileCommands, 'getFriends', socialData.profileCommands.getFriends);
    replaceIfChanged(draf.profileCommands, 'getPositions', socialData.profileCommands.getPositions);
    replaceIfChanged(draf.profileCommands, 'getDataForCopy', socialData.profileCommands.getDataForCopy);
    replaceIfChanged(draf.profileCommands, 'getGetPlAndStats', socialData.profileCommands.getGetPlAndStats);
    replaceIfChanged(draf.copyRelationsCommands, 'addFollower', socialData.copyRelationsCommands.addFollower);
    replaceIfChanged(draf.copyRelationsCommands, 'updateFollower', socialData.copyRelationsCommands.updateFollower);
    replaceIfChanged(draf.copyRelationsCommands, 'removeFollower', socialData.copyRelationsCommands.removeFollower);
    replaceIfChanged(draf.copyRelationsCommands, 'unlinkTradeCommand', socialData.copyRelationsCommands.unlinkTradeCommand);
    replaceIfChanged(draf.copyRelationsCommands, 'getClosedPositionsLinksCommand', socialData.copyRelationsCommands.getClosedPositionsLinksCommand);

    replaceIfChanged(draf.registrationCommands, 'joinSocialCommand', socialData.registrationCommands.joinSocialCommand);
    replaceIfChanged(draf.registrationCommands, 'isMasterCommand', socialData.registrationCommands.isMasterCommand);
    replaceIfChanged(draf.registrationCommands, 'leaveSocialCommand', socialData.registrationCommands.leaveSocialCommand);
    replaceIfChanged(draf.registrationCommands, 'generateNickNameCommand', socialData.registrationCommands.generateNickNameCommand);
    replaceIfChanged(draf.registrationCommands, 'updateAvatarCommand', socialData.registrationCommands.updateAvatarCommand);
    replaceIfChanged(draf.registrationCommands, 'updatePersonalDescriptionCommand', socialData.registrationCommands.updatePersonalDescriptionCommand);
    replaceIfChanged(draf.registrationCommands, 'logLfsCopyRequestWithDisclaimerCommand', socialData.registrationCommands.logLfsCopyRequestWithDisclaimerCommand);
    replaceIfChanged(draf.registrationCommands, 'getLfsSuitabilityTestResultsCommand', socialData.registrationCommands.getLfsSuitabilityTestResultsCommand);
    replaceIfChanged(draf.registrationCommands, 'updateLfsSuitabilityTestResultCommand', socialData.registrationCommands.updateLfsSuitabilityTestResultCommand);
    replaceIfChanged(draf.registrationCommands, 'abandonSuitabilityTestCommand', socialData.registrationCommands.abandonSuitabilityTestCommand);

    replaceIfChanged(draf, 'moreSocialDataCommand', socialData.moreSocialDataCommand);

    draf.isJoined = socialData.userData.UserDetails?.IsEnabled;
    draf.userDetails.nickname = socialData.userData.UserDetails?.Nickname;
    draf.userDetails.isEnabled = socialData.userData.UserDetails?.IsEnabled;
    draf.userDetails.avatar = socialData.userData.UserDetails?.Avatar;

    draf.lfsRegulationsDetails.isLfsRegulated = socialData.userData.IsLfsRegulated;
    draf.lfsRegulationsDetails.lfsFinishedSuitabilityTest = socialData.userData.LfsFinishedSuitabilityTest;
    draf.lfsRegulationsDetails.lfsRiskScore = socialData.userData.LfsRiskScore;

    if (socialData.socialAccount) {
        //draf.socialAccount.questionerMode = QUESTIONER_MODE_EXTENRAL;
        //draf.socialAccount.externalRiskScore = 3 //socialData.socialAccount.externalRiskScore;

        draf.socialAccount.questionerMode = socialData.socialAccount.questionerMode;
        draf.socialAccount.externalRiskScore = socialData.socialAccount.externalRiskScore;
        
        replaceIfChanged(draf.socialAccount, 'queryRiskScoreCMD', socialData.socialAccount.queryRiskScoreCMD);
    }

    if (socialData.userData.TradesLiks) {
        const isCloseLinksHandle = false;
        handlePositionsLinks(draf, socialData.userData.TradesLiks, isCloseLinksHandle);
    }

    if (socialData.userData.TraderMasters) {
        for (const source of socialData.userData.TraderMasters) {
            draf.copying[source] = true;
        }
    }

    if (socialData.userData.TraderFollowMasters) {
        for (const source of socialData.userData.TraderFollowMasters) {
            draf.following[source] = true;
        }
    }

    if (socialData.userData.FeaturedTraders) {
        for (const source of socialData.userData.FeaturedTraders) {
            setTopTrader(source, draf.topTraders, draf.topTradersOrder);
        }
    }

    if (socialData.userData.TopTraders) {
        for (const source of socialData.userData.TopTraders) {
            setTopTrader(source, draf.topTraders, draf.topTradersOrder);
        }
    }
}

function setTopTrader(source, tradersObject, tradersArray) {
    if (source.Nickname) {
        let trader = tradersObject[source.Nickname];
        if (!trader) {
            if (tradersArray) {
                tradersArray.push(source.Nickname);
            }

            trader = { Nickname: source.Nickname };

            tradersObject[source.Nickname] = trader;
        }

        trader.Avatar = source.Avatar;
        trader.JoinDate = source.JoinDate;
        trader.Country = source.Country;
        trader.Copiers = source.Copiers;
        trader.Profit = source.Profit;
        trader.Rank = source.Rank;
        trader.WinRatio = source.WinRatio;
        trader.MaxSingleTradeProfit = source.MaxSingleTradeProfit;
        trader.AverageDaily = source.AverageDaily;
        trader.AverageCopySlippage = source.AverageCopySlippage;
        trader.CopySlippageRank = source.CopySlippageRank;
        trader.PnLGraph = source.PnLGraph;
        trader.TradingRiskLevel = source.TradingRiskLevel;
        trader.AddedReason = source.AddedReason;
    }
}

function handleSocialDataUpdated(state, socialData) {
    state.isEnabled = socialData.isEnabled;
    state.hideCopyByFixedLot = socialData.hideCopyByFixedLot;
    replaceIfChanged(state, 'initSocialDataCommand', socialData.initSocialDataCommand);
}
