import brandListener from './brandListener';
import translationListener from './translationsInit';
import profitCalculationListener from './profitCalculationListener'
import RenewSessionService from './renewSessionService'
import socialV1Listener from '../socialv1/socialListener'
import websocketListener from '../WebSocket/websocketListener'
import fullStoryListener from './fullStoryListener'
import googleAnalyticsListener from './googleAnalyticsListener'
import luckyOrangeListener from './luckyOrangeListener'
import themeListener from './themeListener'

export default function StartListener()
{
  translationListener();
  brandListener();
  profitCalculationListener();
  RenewSessionService.start();
  socialV1Listener();
  websocketListener();
  googleAnalyticsListener();
  luckyOrangeListener();
  fullStoryListener();
  themeListener();
}
