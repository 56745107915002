import React, { useEffect } from 'react';

const _dirtyNotifications = new Map()
let _isInNotification = false;

let _removeFromDirty = function(reactSetterInstance)
{
  let value = _dirtyNotifications.get(reactSetterInstance);
  if (value === 0)
  {
    _dirtyNotifications.set(reactSetterInstance,1);
  }
}

let _setDirty= function(reactSetterInstance)
{
  if (!_dirtyNotifications.has(reactSetterInstance))
  {
    _dirtyNotifications.set(reactSetterInstance,0);
  }
  
  if(!_isInNotification)
  {
    _isInNotification = true;
    Promise.resolve().then(()=>{
      let dummyObject=  Object.create(null);
      for (let [reactSetter, value] of _dirtyNotifications) {
        if (!value)
        {
          reactSetter(dummyObject);
        }
      }

      _dirtyNotifications.clear();
      _isInNotification = false;
    });
  }
}

export default class PubsubRepository
{
  #_dataset = new Map();
  #_subscriptions = new Map();
  #_nativeSubscriptions = new Set();
  #_indexObj;
  #_globalSeq = 1;
  constructor(indexObj){
    this.#_indexObj= indexObj;
  }

  subscribeEventsNative(callback)
  {
    this.#_nativeSubscriptions.add(callback);
  }

  getNative(key) 
  {
    let myData = this.#_dataset.get(key);
    if (myData)
    {
      return myData.data;
    }
    return null;
  }

  subscribeNative(key,callback)
  {
    let keySubscriptions = this.#_subscriptions.get(key);
    if (!keySubscriptions)
    {
      keySubscriptions = new Set();
      this.#_subscriptions.set(key,keySubscriptions);
    }

    let disposed = false;
    let local = this;
    let keySubscription = {
      reactSetValue: ()=>{
          if (!disposed)
          {
            let myData = local.#_dataset.get(key);
            keySubscriptions.add(keySubscription);
            if (myData)
            {
              local.CloneData(myData);
              callback(myData.transferData[0]);
            }
          }
        },
    };

    keySubscriptions.add(keySubscription);
    
    keySubscription.reactSetValue(); // call the callback now

    return {
      dispose:()=>{
        disposed = true;
        keySubscriptions.delete(keySubscription);}
    };
  }
  
  subscribeReactWithPrev(key)
  {
    let data = this._subscribeAndGetData(key);
    if (data)
    {
      return data.transferData;
    }
    return [undefined,undefined];
  }

  subscribeReact(key)
  {
    let data = this._subscribeAndGetData(key);
    if (data)
    {
      return data.transferData[0];
    }
    return null;
  }

  _subscribeAndGetData(key)
  {
    let keySubscriptions = this.#_subscriptions.get(key);
    if (!keySubscriptions)
    {
      keySubscriptions = new Set();
      this.#_subscriptions.set(key,keySubscriptions);
    }
    
    const [,setValue] = React.useState(Object.create(null));

    _removeFromDirty(setValue);
    let keySubscription = {
      reactSetValue: setValue,
    }
    useEffect(() => {
      return function cleanup() {
        keySubscriptions.delete(keySubscription);
      };
    });

    keySubscriptions.add(keySubscription);

    let myData = this.#_dataset.get(key);
    if (myData)
    {
      this.CloneData(myData);
      return myData;
    }
    return null;
  }

  CloneData(myData) {
    if (myData.cloneSeq !== myData.changeSeq) {
      let newInstance = this.#_indexObj.createInstance();
      let newInstancePrev = this.#_indexObj.createInstance();
      
      this.#_indexObj.deepClone(newInstance, myData.data);
      this.#_indexObj.deepClone(newInstancePrev, myData.prevData);

      myData.transferData = [newInstance, newInstancePrev];
      myData.cloneSeq = myData.changeSeq;
    }
  }

  publishDelete(items)
  {
    if (items === null || items === undefined){
      return;
    }

    let nativeCallbacks = undefined;
    if (this.#_nativeSubscriptions.size > 0)
    {
      nativeCallbacks = {
        created:[],
        updated:[],
        deleted:[]
      };
    }

    if (Array.isArray(items)){
      for (let i =0;i<items.length;i++){
        let item = items[i];
        this._handlePublishDelete(item,nativeCallbacks);
      }
    }
    else
    {
      this._handlePublishDelete(items,nativeCallbacks);
    }

    if (nativeCallbacks)
    {
      this.#_nativeSubscriptions.forEach(currCallback=>{
        currCallback(nativeCallbacks);
      });
    }
  }

  publish(items)
  {
    if (items === null || items === undefined){
      return;
    }

    let nativeCallbacks = undefined;
    if (this.#_nativeSubscriptions.size > 0)
    {
      nativeCallbacks = {
        created:[],
        updated:[],
        deleted:[]
      };
    }

    if (Array.isArray(items)){
      for (let i =0;i<items.length;i++){
        let item = items[i];
        this._handlePublish(item,nativeCallbacks);
      }
    }
    else
    {
      this._handlePublish(items,nativeCallbacks);
    }

    if (nativeCallbacks)
    {
      this.#_nativeSubscriptions.forEach(currCallback=>{
        currCallback(nativeCallbacks);
      });
    }
  }

  _handlePublishDelete(item,nativeCallbacks)
  {
    let key = this.#_indexObj.getKey(item);
    let prevDataItem = this.#_dataset.get(key);
    if (prevDataItem == null) {
      // we do nothing and we are done
    }
    else{
      if (nativeCallbacks){
        nativeCallbacks.deleted.push({key:key,data:prevDataItem.data});
      }
      
      this.#_dataset.delete(key);
      let subscriptions = this.#_subscriptions.get(key);
      if (subscriptions) {
        if (subscriptions.size) {
          for (let item of subscriptions) {
            _setDirty(item.reactSetValue);
          }
          subscriptions.clear();
        }
      }
    }
  }
  
  _handlePublish(item,nativeCallbacks) {
    let key = this.#_indexObj.getKey(item);
    let prevDataItem = this.#_dataset.get(key);
    this.#_globalSeq++;
    if (prevDataItem == null) {
      let itemData = this.#_indexObj.createInstance();
      let previtemData = this.#_indexObj.createInstance();
      this.#_indexObj.transform(itemData, item);
      this.#_indexObj.transform(previtemData, item);
      prevDataItem = {
        data: itemData,
        prevData: previtemData,
        changeSeq: this.#_globalSeq,
        cloneSeq:0,
        transferData: null
      };
      this.#_dataset.set(key, prevDataItem);
      if (nativeCallbacks){
        nativeCallbacks.created.push({key:key,data:prevDataItem.data});
      }
    }
    else {
      this.#_indexObj.deepClone(prevDataItem.prevData,prevDataItem.data);
      this.#_indexObj.transform(prevDataItem.data, item);
      prevDataItem.changeSeq = this.#_globalSeq;
      
      if (nativeCallbacks){
        nativeCallbacks.updated.push({key:key,data:prevDataItem.data});
      }
    }
    
    let subscriptions = this.#_subscriptions.get(key);
    if (subscriptions) {
      if (subscriptions.size) {
        for (let item of subscriptions) {
          _setDirty(item.reactSetValue);
        }
        subscriptions.clear();
      }
    }
  }
};