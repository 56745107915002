const Format2Fixed = (value) => {
    return parseFloat(value).toLocaleString('en-US', {
        style: 'decimal',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
    });
};

export const format2Digits = (value, digits) => {
    return parseFloat(value).toLocaleString('en-US', {
        style: 'decimal',
        maximumFractionDigits: digits,
        minimumFractionDigits: digits
    });
};

export default Format2Fixed;
