import React from 'react';
// import PropTypes from 'prop-types';

const SocialsIcon = ({ fill }) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='30' height='20' viewBox='0 0 30 20' fill='none'>
            <path d='M15.0012 0C17.5186 0 19.5664 2.09329 19.5664 4.66664C19.5664 7.23999 17.5186 9.33328 15.0012 9.33328C12.4838 9.33328 10.436 7.23992 10.436 4.66664C10.436 2.09335 12.4838 0 15.0012 0ZM15.0012 7.99991C16.7986 7.99991 18.262 6.50393 18.262 4.66664C18.262 2.82934 16.7986 1.33336 15.0012 1.33336C13.2038 1.33336 11.7404 2.82934 11.7404 4.66664C11.7404 6.50393 13.2038 7.99991 15.0012 7.99991Z' fill={fill} />
            <path d='M5.86808 5.33398C7.66544 5.33398 9.12891 6.82997 9.12891 8.66726C9.12891 10.5046 7.66544 12.0005 5.86808 12.0005C4.07072 12.0005 2.60726 10.5046 2.60726 8.66726C2.60726 6.82997 4.07072 5.33398 5.86808 5.33398ZM5.86808 10.6672C6.94679 10.6672 7.82459 9.76994 7.82459 8.66726C7.82459 7.56459 6.94679 6.66728 5.86808 6.66728C4.78937 6.66728 3.91158 7.56459 3.91158 8.66726C3.91158 9.76994 4.78944 10.6672 5.86808 10.6672Z' fill={fill} />
            <path d='M24.0361 5.33398C25.8348 5.33398 27.2969 6.82997 27.2969 8.66726C27.2969 10.5046 25.8334 12.0005 24.0361 12.0005C22.2387 12.0005 20.7752 10.5046 20.7752 8.66726C20.7752 6.82997 22.2374 5.33398 24.0361 5.33398ZM24.0361 10.6672C25.1148 10.6672 25.9926 9.76994 25.9926 8.66726C25.9926 7.56459 25.1148 6.66728 24.0361 6.66728C22.9573 6.66728 22.0795 7.56459 22.0795 8.66726C22.0795 9.76994 22.9574 10.6672 24.0361 10.6672Z' fill={fill} />
            <path d='M14.9983 10.666C19.6731 10.666 23.4766 14.554 23.4766 19.3326C23.4766 19.7006 23.1844 19.9993 22.8244 19.9993C22.4644 19.9993 22.1722 19.7006 22.1722 19.3326C22.1722 15.2887 18.9544 11.9994 14.9983 11.9994C11.0423 11.9994 7.82451 15.2887 7.82451 19.3326C7.82451 19.7006 7.53232 19.9993 7.17232 19.9993C6.81232 19.9993 6.52013 19.7006 6.52013 19.3326C6.52013 14.554 10.3236 10.666 14.9983 10.666Z' fill={fill} />
            <path d='M5.86982 13.334C6.94327 13.334 7.99459 13.634 8.91023 14.2007C9.21673 14.3927 9.3159 14.802 9.12936 15.1167C8.94025 15.4313 8.54113 15.5313 8.23328 15.3407C7.52246 14.8993 6.70592 14.6673 5.86982 14.6673C3.35247 14.6673 1.30462 16.7606 1.30462 19.3339C1.30462 19.7019 1.01242 20.0006 0.652426 20.0006C0.292429 20.0006 0.000237465 19.7019 0.000237465 19.3339C0.0002985 16.026 2.63376 13.334 5.86982 13.334Z' fill={fill} />
            <path d='M21.0914 14.1993C22.0057 13.634 23.057 13.334 24.1305 13.334C27.3665 13.334 30 16.026 30 19.3339C30 19.7019 29.7078 20.0006 29.3478 20.0006C28.9878 20.0006 28.6956 19.7019 28.6956 19.3339C28.6957 16.7606 26.6478 14.6673 24.1305 14.6673C23.2944 14.6673 22.4779 14.8993 21.7683 15.3393C21.4618 15.53 21.06 15.43 20.8722 15.1153C20.6844 14.8006 20.7836 14.3913 21.0914 14.1993Z' fill={fill} />
        </svg>
    );
};

// SocialsIcon.propTypes = {};

export default SocialsIcon;
