/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CommandFactory } from '../../background/commands/CommandFactory';
import LoadSpinner from '../primitives/LoadSpinner';
import '../OpenPositions/OpenPositions.css';
import { PreparePNL } from '../PNL/PNL';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';
import PositionsVirtualList from './PositionsVirtualList';

const ClosedPositions = ({
    setSelectedTrader, isBottomPanelPinned, subHeaderColor, systemColor, linkColor
}) => {
    const { symbols } = useSelector((state) => (state.account.symbols), shallowEqual);
    const { positions, getAllCommand, isGetAllRequsted } = useSelector((state) => (state.account.positions.closed), shallowEqual);
    const [isLoading, setIsLoading] = useState(!isGetAllRequsted);
    const {
        isEnabled, isJoined, copyRelationsCommands: { getClosedPositionsLinksCommand }, closeTradeLinks
    } = useSelector((state) => { return (state.account.socialv1); }, shallowEqual);
    const { rightPanelSettings: { socialv1: { isVisible } }, currency: { symbol }, drawerSettings: { closePositionsTab } } = useSelector((state) => { return (state.account.accountInfo); }, shallowEqual);
    const { t } = useTranslation();

    useEffect(() => {
       SendEventToGoogleAnalytics("Drawer:ClosedOrdersTabOpened");
        let getClosedPositionsCopied = async () => {};
        if (isEnabled && isVisible && getClosedPositionsLinksCommand) {
            getClosedPositionsCopied = async () => {
                const command = CommandFactory.createCommand(getClosedPositionsLinksCommand);
                try {
                    const res = await command.execute();
                } catch (err) {
                }
            };
        }

        let handleUpdateOrder = async () => {};
        if (getAllCommand) {
            handleUpdateOrder = async () => {
                const command = CommandFactory.createCommand(getAllCommand);
                try {
                    var contractSizes = {};
                    for (const symbolName in symbols) {
                        contractSizes[symbolName] =symbols[symbolName].contractSize;
                      }
                    const res = await command.execute({
                        clientContractSizes:contractSizes
                    });
                } catch (err) {
                }
            };
        }

        Promise.allSettled([handleUpdateOrder(), getClosedPositionsCopied()])
            .then(() => {
                if (isLoading) {
                    setIsLoading(false);
                }
            });
    }, []);

    if (isLoading) {
        return <LoadSpinner isBottom />;
    }

    const positionsToRender = [];
    const showCopiedFrom = isEnabled && isVisible;
    // eslint-disable-next-line no-restricted-syntax
    for (const obj in positions) {
        positionsToRender.push(positions[obj]);
    }
    positionsToRender.sort((a,b) => b.closeTimeUTC - a.closeTimeUTC);

    let showTotalProfit = false;
    let totalProfit = 0;
    if (closePositionsTab.totalProfitType === 'includeCommissions') {
        showTotalProfit = true;
        for (const position of positionsToRender) {
            totalProfit += position.profit + position.commission + position.swaps;
        }
    } else if (closePositionsTab.totalProfitType === 'excludeCommissions') {
        showTotalProfit = true;
        for (const position of positionsToRender) {
            totalProfit += position.profit;
        }
    }

    return (
        <div>
            <div className={showTotalProfit ? 'closed-table__wrapper' : 'closed-table__wrapper closed-table__wrapper--profit-hidden'}>
                <PositionsVirtualList
                    showCopiedFrom={showCopiedFrom}
                    positionsToRender={positionsToRender}
                    setSelectedTrader={setSelectedTrader}
                    closeTradeLinks={closeTradeLinks}
                    isBottomPanelPinned={isBottomPanelPinned}
                    subHeaderColor={subHeaderColor}
                    systemColor={systemColor}
                    linkColor={linkColor}
                />
            </div>
            {showTotalProfit && (
                <div className='closed-tab__profit-total'>
                    {t('InstrumetInfo.Details.Profit')}
                    :&nbsp;
                    <PreparePNL pnlTick={{ openPl: totalProfit }} shouldFormat symbol={symbol} />
                </div>
            )}
        </div>
    );
};

// ClosedPositions.propTypes = {};

export default ClosedPositions;
