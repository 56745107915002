import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import MobileAvatarIcon from '../../MobileAvatarIcon';
import MobileButtonIcon from '../../Buttons/MobileButtonIcon';
import MobileLoader from '../../MobileLoader/MobileLoader';

const MobileSocialWatchItem = props => {
	const {
		AvatarURL,
		Nickname,
		NumberOfCopiedPositions,
		handleCopy,
		handleUnwatch,
		handleOpen,
		Avatar,
		CopiersCount,
		IsCopyAllowed,
		CopyType,
		removeRow,
	} = props;

	const [isLoading, setIsLoading] = useState(false);

	const {t} = useTranslation();

	const alreadyCopied = useMemo(() => {
		return CopyType === 1 || CopyType === 2;
	}, [CopyType]);

	const alreadyWatched = useMemo(() => {
		return CopyType === 4 || alreadyCopied;
	}, [CopyType, alreadyCopied]);

	const getCopiers = useMemo(() => {
		return NumberOfCopiedPositions || CopiersCount || 0;
	}, [NumberOfCopiedPositions]);

	const handleClickOpen = useCallback(e => {
		e && e.preventDefault();
		handleOpen && handleOpen(Nickname);
	}, [handleOpen, Nickname]);

	const handleClickUnwatch = useCallback(e => {
		e && e.stopPropagation();
		setIsLoading(true);
		handleUnwatch &&
		handleUnwatch({...props, alreadyWatched})
				.then(res => {
					if (res?.isSuccessful) {
						removeRow && removeRow();
					}
					setIsLoading(false);
				})
				.catch(err => {
					console.error(err);
					setIsLoading(false);
				});
	}, [alreadyWatched, props, handleUnwatch, setIsLoading, Nickname]);

	const handleClickCopy = useCallback(e => {
		e && e.stopPropagation();
		if (!IsCopyAllowed) return null;
		handleCopy && handleCopy(Nickname);
	}, [Nickname, IsCopyAllowed, handleCopy]);

	return (
			<>
				<MobileLoader isLoading={isLoading} overlay/>

				<div
						className="mobile-social-watch__item__inner"
						onClick={handleClickOpen}
				>
					<div className="mobile-social-watch__item__user">
						<div className="mobile-social-watch__item__user__avatar">
							<MobileAvatarIcon avatar={AvatarURL || Avatar}/>
						</div>
						<div className="mobile-social-watch__item__user__desc">
							<p className="mobile-social-watch__item__user__nickname">{Nickname}</p>
							<div className="mobile-social-watch__item__user__state">
							<span className="mobile-social-watch__item__user__state__title">
								{t('Socail.Profile.Copiers').toLowerCase()}
								:&nbsp;
							</span>
								<span
										className="mobile-social-watch__item__user__state__count"
								>
								{getCopiers}
							</span>
							</div>
						</div>
					</div>

					<div className="mobile-social-watch__item__controls">
						<div className="mobile-social-watch__item__controls__btn-hide">
							<MobileButtonIcon stop type='hide' onClick={handleClickUnwatch}/>
						</div>
						<div className="mobile-social-watch__item__controls__btn-copy">
							<MobileButtonIcon
									stop
									type='copy'
									isActive={alreadyCopied}
									disabled={!IsCopyAllowed}
									onClick={handleClickCopy}
							/>
						</div>
					</div>
				</div>
			</>
	);
};

export default MobileSocialWatchItem;
