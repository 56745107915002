
import { useSelector, shallowEqual } from 'react-redux';
import pubsubSubscription from '../background/repositories/PubsubSubscription';
import React, { useState, useEffect } from 'react';
import ChartDealElemenet from '../shared/Charts/ChartDealElement';
import { ProfitCalculationService } from '../background/calculations/profitCalculationService';

const SinglePositionView = ({ position, mainChartController }) => {
  const { symbols } = useSelector((state) => (state.account.symbols), shallowEqual);
  const  currency  = useSelector((state) =>  (state.account.accountInfo.currency));

  const symbolConfig = symbols[position.symbolId];

  const profitValueSubscription = pubsubSubscription.subscribeObj(position.profit);
  const tickValueSubscription = pubsubSubscription.subscribeObj(symbolConfig.tick);

  if (!profitValueSubscription || !tickValueSubscription) {
    return null;
  }

  const profit = Math.round((profitValueSubscription.profit + Number.EPSILON) * 100) / 100;
  const profitValue = getProfitValue(profit, currency.symbol);
  let closeConvertion = position.actionType === 0 ? tickValueSubscription?.askProfitConversion : tickValueSubscription?.bidProfitConversion;

  let estimatedProfitSL = null;
  if (position.stopLoss) {
    var value = ProfitCalculationService.calculateProfit(symbolConfig, position.actionType, position.amount, position.openRate, position.stopLoss, closeConvertion);
    estimatedProfitSL = getProfitValue(Math.round((value + Number.EPSILON) * 100) / 100, currency.symbol);
  }
  let estimatedProfitTP = null;
  if (position.takeProfit) {
    var value = ProfitCalculationService.calculateProfit(symbolConfig, position.actionType, position.amount, position.openRate, position.takeProfit, closeConvertion);
    estimatedProfitTP = getProfitValue(Math.round((value + Number.EPSILON) * 100) / 100, currency.symbol);
  }

  const setProfitSL = (sl) => {};

  const setProfitTP = (tp) => {};

  return (
    <ChartDealElemenet
      chartController={mainChartController}
      symbol={position.symbolName}
      openRate={ position.openRate }
      openTime={ position.openTimeUtc }

      actionType={position.actionType}
      color={profit > 0 ? 'green' : (profit === 0 ? 'grey' : 'red' )}
      title={`${position.actionType === 0 ? "Buy" : "Sell"}, ${position.amount}`}
      display={profitValue}
      tpValue={position.takeProfit}
      slValue={position.stopLoss}
      slDisplay={estimatedProfitSL}
      tpDisplay={estimatedProfitTP}

      // onSLValueUpdated = { setProfitSL }
      // onSLValueBeginUpdate={ onExitEditMode }
      // onSLValueEndUpdate = { onExitEditMode }
      // onSLClear = {onSlClear}
      // onTPValueUpdated = { setProfitTP }
      // onTPValueBeginUpdate={ onExitEditMode }
      // onTPValueEndUpdate = { onExitEditMode }
      // onTPClear = { onSlClear }
    />
  );
}

export default ({ mainChartController, selectedSymbol}) => {
  const userDetails = useSelector((state) => state.account.accountInfo.userDetails, shallowEqual);
  if(!userDetails.showPositionsOnChart){
    return null;
  }

  const statePositions = useSelector((state) => state.account.positions.open.positions);
  return Object.values(statePositions).map(x => {
    if(x.symbolId === selectedSymbol.id){
      return <SinglePositionView key={x.id} position={x} mainChartController={mainChartController} />
    }
  });
}

const getProfitValue = function(profit, currencySymbol){
  let value = '0';
    if(profit >= 0){
      value = `${currencySymbol}${profit}`;
    }else{
      value = `-${currencySymbol}${profit*-1}`;
    }

    return value;
}
