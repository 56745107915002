import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';

import FormatProfitSwapCommision from '../utilsUI/FormatProfitSwapCommision';
import Button from '../primitives/Button';


const ClosedPositionSuccess = ({
    profitValue,
    closePrice,
    closeSuccessOrder
}) => {
    const { t } = useTranslation();


    return (
        <Fragment>
            <div className='order-update__container order-close__success-container'>
                <div className='highlight'>
                    <div className='order__successful-image' />
                    <h3>{t('Close.Order.Success')}</h3>
                    <div>
                        {t('InstrumetInfo.Details.CloseRate')}
                        :&nbsp;
                        {closePrice}
                    </div>
                    <div className='highlight__profit'>
                        {t('InstrumetInfo.Details.Profit')}
                        :&nbsp;
                        <strong>
                            <FormatProfitSwapCommision tickValue={profitValue} />
                        </strong>
                    </div>
                </div>
            </div>
            <Button className='btn btn--general' onClickHandler={closeSuccessOrder} text={t('General.Done')} />
        </Fragment>
    );
};

// ClosedPositionSuccess.propTypes = {};

export default ClosedPositionSuccess;
