export const COLORS_RED = [
	'#DE4A4A',
	'#F86464',
	'#FF7D7D',
	'#FF9797',
	'#FFB0B0',
	// '#FFC9C9',
];

export const COLORS_YELLOW = [
	'#DEC84A',
	'#F8E264',
	'#FFFB7D',
	'#FFFF97',
	'#FFFFB0',
	// '#FFFFC9',
];

export const COLORS_GREEN = [
	'#51DE4A',
	'#6BF864',
	'#84FF7D',
	'#9EFF97',
	'#B7FFB0',
	// '#D0FFC9',
];

export const COLORS_BLUE = [
	'#4A4FDE',
	'#6469F8',
	'#7D82FF',
	'#979CFF',
	'#B0B5FF',
	// '#C9CEFF',
];

const getColorsArray = (index = 0) => {
	let arr = [];
	const cIndex = index % 4;
	switch (cIndex) {
		case 0:
			arr = COLORS_RED;
			break;
		case 1:
			arr = COLORS_YELLOW;
			break;
		case 2:
			arr = COLORS_GREEN;
			break;
		case 3:
			arr = COLORS_BLUE;
			break;
		default:
			arr = COLORS_BLUE;
	}
	return arr;
};

const getColorsIndex = (index = 0) => {
	const length = COLORS_RED.length - 1 || 4;
	const i = ~~(index / length);
	return i > length ? length : i;
};

export const createChartColors = index => {
	const colorsArray = getColorsArray(index);
	const colorsIndex = getColorsIndex(index);
	return colorsArray[colorsIndex];
};

export const createChartLabel = label => {
	// label => '17/01/2021 / -0.80%'
	return label
			.split('/')
			.filter(el => el.includes('%'))
			.join();
};

export const createChartData = (value, label) => {
	return value.map((item, index) => {
		return {
			name: createChartLabel(label[index]),
			value: item,
		};
	});
};

export const createPieChartData = data => {
	if (!data) return [];
	return data
			.map(item => ({
				name: item.InstrumentName,
				value: item.AllocationPercentage,
				label: `${(item.AllocationPercentage * 100).toFixed(2)}%`,
			}));
};
