import React from 'react';
import PropTypes from 'prop-types';
import MobileSwipeRowClass from './MobileSwipeRowClass';

const MobileSwipeRow = props => {
	const {
		leftButtons,
		rightButtons,
		isClose,
		switchThreshold,
		deltaThreshold,
		flickThreshold,
		transitionFunc,
		disableSwipeLeft,
		disableSwipeRight,
		disableParallax,
		disableExpand,
		children,
		rowHeight,
		rowId,
	} = props;
	return (
			<MobileSwipeRowClass
					rowId={rowId}
					leftButtons={leftButtons}
					// rightButtons={rightButtons}
					// isClose={isClose}
					// switchThreshold={switchThreshold}
					// deltaThreshold={deltaThreshold}
					// flickThreshold={flickThreshold}
					// transitionFunc={transitionFunc}
					disableSwipeLeft={disableSwipeLeft}
					// disableSwipeRight={disableSwipeRight}
					// disableParallax={disableParallax}
					// disableExpand={disableExpand}
					rowHeight={rowHeight}
			>
				{children}
			</MobileSwipeRowClass>
	);
};

MobileSwipeRow.defaultProps = {
	leftButtons: [],
	rightButtons: [],
	isClose: undefined,
	switchThreshold: 0.5,
	deltaThreshold: 10,
	flickThreshold: 200,
	transitionFunc: 'all .3s cubic-bezier(0, 0, 0, 1)',
	rowHeight: '100%',
	disableSwipeLeft: false,
	disableSwipeRight: false,
	disableParallax: false,
	disableExpand: false,
};

MobileSwipeRow.defaultProps = {
	className: PropTypes.string,
	transitionFunc: PropTypes.string,
	rowHeight: PropTypes.string,
	onTouchStart: PropTypes.func,
	onTouchMove: PropTypes.func,
	onTouchEnd: PropTypes.func,
	leftButtons: PropTypes.array,
	rightButtons: PropTypes.array,
	switchThreshold: PropTypes.number,
	deltaThreshold: PropTypes.number,
	flickThreshold: PropTypes.number,
	isClose: PropTypes.bool,
	disableSwipeLeft: PropTypes.bool,
	disableSwipeRight: PropTypes.bool,
	disableParallax: PropTypes.bool,
	disableExpand: PropTypes.bool,
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.oneOf(['null']),
	]),
};

export default MobileSwipeRow;
