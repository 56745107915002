import produce from "immer"

const initState = {
  credit:0,
  balance:0,
  margin:0,
  marginStatus:0,
  movingPL :{ key: "MOVINGPNL", repId: "ACCOUNT_PNL" }
};

export const financialReducer = function financialReducer(state = initState, action,globalState){
  switch (action.type) {   
    case "login-logout":
      return initState;
      case "init-brand+login":{
          let accountFinancial = action.payload.loginTradingData.tradingData.accountData.accountFinancial;
          return handleAccountUpdate(state, accountFinancial);
        }
        case "update-trading-data":
        case "login-success":
        {
          let accountFinancial = action.payload.tradingData.accountData.accountFinancial;
          return handleAccountUpdate(state, accountFinancial);
        }
        default:
          return state;
      }
    }
    
function handleAccountUpdate(state, accountFinancial) {
  return produce(state, draf => {
    draf.credit = accountFinancial.credit;
    draf.balance = accountFinancial.balance;
    draf.margin = accountFinancial.margin;
    draf.marginStatus = accountFinancial.marginStatus;
  });
}

