/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-expressions */
import React, { Component } from 'react';
// import PropTypes from 'prop-types';

import './Modal.css';
import Button from './Button';

class Modal extends Component {
    constructor(props) {
        super(props);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    onClose = (e) => {
        this.props.onClose && this.props.onClose(e);
    };

    onCallToAction = (e) => {
        this.props.callToAction && this.props.callToAction(e);
    };

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    isOutsideClick(element,target)
    {
        if (element)
        {
            let currTarget = target;
            while (currTarget)
            {
                if (element === currTarget)
                {
                    return false;
                }
    
                if (currTarget === document.body)
                {
                    return true;
                }
                    
                currTarget = currTarget.parentElement;
            }
        }
        return false;
    }

    handleClickOutside = (event) => {
        if (this.wrapperRef && this.isOutsideClick(this.wrapperRef,event.target)) {
            //this.onClose(event);
        }
    }


    render() {
        const {
            show,
            className,
            heading,
            children
        } = this.props;
        if (!show) {
            return null;
        }
        const modalClass = className ? `modal ${className}` : 'modal';
        const CloseButtonProps = {
            onClickHandler: (e) => this.onClose(e),
            ariaLabel: 'Close',
            cssClass: 'btn',
            iconEnd: 'fa fa-times'
        };

        return (
            <div className='modal__overlay'>
                <div className={modalClass} id='modal' ref={this.setWrapperRef}>
                    {heading && <h2>{heading}</h2>}
                    <div className='content'>{children}</div>
                    <div className='actions'>
                        <Button {...CloseButtonProps} />
                    </div>
                </div>
            </div>
        );
    }
}

// Modal.defaultProps = {
//     heading: 'Modal Window',
//     callToAction: () => {},
//     callToActionText: 'Send'
// };

// Modal.propTypes = {
//     onClose: PropTypes.func.isRequired,
//     show: PropTypes.bool.isRequired,
//     heading: PropTypes.string,
//     callToAction: PropTypes.func,
//     callToActionText: PropTypes.string

// };

export default Modal;
