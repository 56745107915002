/* eslint-disable complexity */
import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { LeftBlockMemo as LeftBlock } from './LeftBlock';
import ResizePanelWrapper from '../ResizePanelWrapper/ResizePanelWrapper';
//import chartController from '../../background/Charts/chartController';
import chartController from '../../background/chart/chartController';
import ProfilePageWrapper from '../ProfilePage/ProfilePageWrapper';
import MarginAlert from '../disclaimers/MarginAlert';
import useWindowSize from '../utilsUI/useWindowSize';
import Questionnaire from '../Questionnaire/Questionnaire';

import CenterPanel from './CenterPanel';
import BottomPanel from './BottomPanel';
import TopPanel from './TopPanel';
import RightPanel from './RightPanel';
import { SendEventToFullStory } from '../../background/storeListener/fullStoryListener';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';
import { SendEventToLuckyOrange } from '../../background/storeListener/luckyOrangeListener';

import './Scrollbar.css';
import './MainLayout.css';

const mainChartController = new chartController();


const MainLayout = ({ lastRemeberMeCMD }) => {
    const { symbols, symbolsOrder } = useSelector((state) => (state.account.symbols), shallowEqual);
    const {
        accountInfo: {
            rightPanelSettings: {
                socialv1: { isVisible },
                t4proTradingroom
            },
            marketRatesSettings: {
                pin,
                lastUpdate,
                info
            },
            drawerSettings,
            featuresData
        },
        financial: { marginStatus },
        socialv1: { isEnabled, isJoined, hasInit }
    } = useSelector((state) => (state.account), shallowEqual);
    // const { MainColor: { ActiveText }, Text: { GeneralColor, SystemColor }, Social: { CopyBackground } } = useSelector((state) => (state.brand.theme));
    const { MainColor: { ActiveText }, Text: { SystemColor }, Social: { CopyBackground } } = useSelector((state) => (state.brand.theme));
    const { ActiveTheme } = useSelector((state) => (state.brand.themeSettings));
    const [selectedSymbol, setSelectedSymbol] = useState(null);
    const [toggleRight, setToggleRight] = useState(false);
    const [toggleLeft, setToggleLeft] = useState(false);
    const [toggleBottom, setToggleBottom] = useState(false);
    const [orderedSymbols, setOrderedSymbols] = useState([]);
    const [selectedTrader, setSelectedTrader] = useState(null);
    const [openCopyTab, setOpenCopyTab] = useState(null);
    const [openProfileModal, setOpenProfileModal] = useState(false);
    const [explorePage, setExplorePage] = useState(false);
    const [reloadApp, setReloadApp] = useState(false);
    const [joinPopup, setJoinPopup] = useState(false);
    const [questionnaire, setQuestionnaire] = useState(false);
    const [initialLeftWidth, setInitialLeftWidth] = useState(400);
    const [initialRightWidth, setInitialRightWidth] = useState(400);
    const [initialBottomHeight, setInitialBottomHeight] = useState(300);
    const [centerWidth, setCenterWidth] = useState(null);
    const [isBottomOpen, setIsBottomOpen] = useState(false);
    // const [leftScrollWidth, setLeftScrollWidth] = useState(0);
    const [width] = useWindowSize();
    const isSocialsEnabled = isEnabled && isVisible;
    // const isSocialsEnabled = true;
    const shouldDisplayRightPanel = isSocialsEnabled || t4proTradingroom.isVisible;
    const shouldShowMarginAlert = (marginStatus === 1 || marginStatus === 2) && featuresData.showMarginCallDisclaimer;
    // const shouldShowMarginAlert = true;
    // const [isSocialsEnabled,] = useState(isEnabled && isVisible);
    // const [shouldDisplayRightPanel] = useState(isSocialsEnabled || t4proTradingroom);

    mainChartController.setSymbolChangedCallback((symbolId) => {
        if (selectedSymbol?.id !== symbolId) {
            if (symbols[symbolId]) {
              handleItemSelect(symbols[symbolId]);
            }
        }
    });

    const handleItemSelect = (repObj, isAutomaticSelect) => {
        if (!isAutomaticSelect) {
            SendEventToGoogleAnalytics('SymbolSelected', { symbol: repObj.id });
            SendEventToFullStory('SymbolSelected', { symbol_str: repObj.id });
            SendEventToLuckyOrange(`SymbolSelected:${repObj.id}`);
        }
        setSelectedSymbol(repObj);
    };

    useEffect(() => {
        const temp = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const symbolsToDisplay in symbolsOrder) {
            const foundSymbol = symbols[symbolsOrder[symbolsToDisplay]];
            if (foundSymbol) {
                temp.push(foundSymbol);
            }
        }
        setOrderedSymbols(temp);
        if (!selectedSymbol) {
            handleItemSelect(temp[0], true);
        }
    }, [symbols, symbolsOrder]);

    useEffect(() => {
        document.documentElement.style.setProperty('--line-color', ActiveText);
        document.documentElement.style.setProperty('--copy-button', CopyBackground);
        if (ActiveTheme === 'dark') {
            document.body.setAttribute('class', 'theme--dark');
        } else {
            document.body.setAttribute('class', 'theme--light');
        }
        // document.documentElement.style.setProperty('--main-text-color', GeneralColor);
    }, [ActiveTheme]);

    useEffect(() => {
        // let totalLeftWidth = 350;
        let totalLeftWidth = 400;
        if (pin.isVisible) { totalLeftWidth += 50; }
        if (lastUpdate.isVisible) { totalLeftWidth += 70; }
        if (info.isVisible) { totalLeftWidth += 23; }
        // if (!pin.isVisible && !lastUpdate.isVisible && !info.isVisible) { totalLeftWidth = 400; }
        setInitialLeftWidth(totalLeftWidth);
    }, [pin, lastUpdate, info, width]);

    const handleRightClick = () => {
        setToggleRight((prevState) => { return !prevState; });
    };

    const handleLeftClick = () => {
        setToggleLeft((prevState) => { return !prevState; });
    };

    const handleBottomClick = () => {
        setToggleBottom((prevState) => { return !prevState; });
    };

    const handleUserSelect = (item) => {
        setSelectedTrader(item);
        if (item?.shouldOpenCopy) {
            setOpenCopyTab(true);
        } else {
            setOpenCopyTab(false);
        }
        setOpenProfileModal(true);
    };

    const handleProfileClose = () => {
        setOpenProfileModal(false);
    };

    const handleExplorePage = () => {
        setExplorePage((prevState) => !prevState);
    };

    const handleAppReload = () => {
        setReloadApp((prevState) => !prevState);
    };

    if (!width) {
        return null;
    }

    const handleResize = (value) => {
        setInitialLeftWidth(value);
    };

    const handleRightResize = (value) => {
        setInitialRightWidth(value);
    };

    const handleHeightResize = (value) => {
        setInitialBottomHeight(value);
    };

    const shouldDisplayDrawerPin = drawerSettings.pinData.isPinVisible;
    const isBottomPanelPinned = drawerSettings.pinData.isPin;
    const setIsBottomPanelPinned = drawerSettings.pinData.togglePinDrawerCommand;

    const containerClass = isBottomPanelPinned ? 'content-with-left content-with-left--pinned' : 'content-with-left content-with-left--unpinned';
    let pageHeight = '100vh';
    if (shouldShowMarginAlert) {
        pageHeight = 'calc(100vh - 38px)';
    }


    return (
        <div>
            {shouldShowMarginAlert && <MarginAlert />}
            <div className='page' style={{ color: SystemColor, height: pageHeight }}>
                <TopPanel
                    handleUserSelect={handleUserSelect}
                    setJoinPopup={setJoinPopup}
                    shouldShowTriangle={shouldShowMarginAlert}
                />
                <div className='container splitted-layout'>
                    <div className={containerClass}>
                        <div className='content-and-center'>
                            <ResizePanelWrapper
                                handleClick={handleLeftClick}
                                direction='e'
                                isClosed={toggleLeft}
                                initialWidth={width < 1200 ? 230 : initialLeftWidth}
                                handleClass='customHandle'
                                borderClass='customResizeBorder'
                                handleResize={handleResize}
                                className='left-panel'
                                id='left-resize'
                            >
                                <LeftBlock
                                    onSymbolSelect={handleItemSelect}
                                    selectedSymbol={selectedSymbol?.id}
                                    width={width}
                                    initialLeftWidth={initialLeftWidth}
                                />
                            </ResizePanelWrapper>
                            <CenterPanel
                                lastRemeberMeCMD={lastRemeberMeCMD}
                                selectedSymbol={selectedSymbol}
                                orderedSymbols={orderedSymbols}
                                onSymbolSelect={handleItemSelect}
                                mainChartController={mainChartController}
                                shouldDisplayRightPanel={shouldDisplayRightPanel}
                                initialLeftWidth={initialLeftWidth}
                                initialRightWidth={initialRightWidth}
                                centerWidth={centerWidth}
                                setCenterWidth={setCenterWidth}
                            />
                        </div>
                        <ResizePanelWrapper
                            handleClick={handleBottomClick}
                            direction='n'
                            isClosed={toggleBottom}
                            initialHeight={isBottomPanelPinned ? '300px' : '73px'}
                            handleClass='customHandle'
                            borderClass='customResizeBorder ResizeBarVertical'
                            handleResize={isBottomPanelPinned ? handleHeightResize : null}
                            id='bottom-resize'
                            openPin={isBottomPanelPinned}
                        >
                            <BottomPanel
                                setSelectedTrader={handleUserSelect}
                                isBottomPanelPinned={isBottomPanelPinned}
                                initialBottomHeight={initialBottomHeight}
                                setIsBottomPanelPinned={setIsBottomPanelPinned}
                                shouldDisplayDrawerPin={shouldDisplayDrawerPin}
                                handleHeightResize={handleHeightResize}
                                isBottomOpen={isBottomOpen}
                                setIsBottomOpen={setIsBottomOpen}
                                width={width}
                            />
                        </ResizePanelWrapper>
                    </div>
                    {shouldDisplayRightPanel && (
                        <ResizePanelWrapper
                            handleClick={handleRightClick}
                            direction='w'
                            handleClass='customHandle'
                            borderClass='customResizeBorder right-resize-panel'
                            isClosed={toggleRight}
                            initialWidth={width < 1200 ? 270 : 400}
                            handleResize={handleRightResize}
                            id='right-panel'
                        >
                            <div className='sidebar__inner'>
                                <RightPanel
                                    isSocialsEnabled={isSocialsEnabled}
                                    isTradingRoom={t4proTradingroom.isVisible}
                                    selectedTrader={selectedTrader}
                                    setSelectedTrader={handleUserSelect}
                                    openCopyTab={openCopyTab}
                                    setOpenCopyTab={setOpenCopyTab}
                                    explorePage={explorePage}
                                    toggleExplorePage={handleExplorePage}
                                    setReloadApp={handleAppReload}
                                    isJoined={isJoined}
                                    hasInit={hasInit}
                                    joinPopup={joinPopup}
                                    setJoinPopup={setJoinPopup}
                                    setQuestionnaire={setQuestionnaire}
                                />
                            </div>
                        </ResizePanelWrapper>
                    )}
                </div>
            </div>
            {openProfileModal && (
                <ProfilePageWrapper
                    selectedTrader={selectedTrader}
                    setSelectedTrader={handleUserSelect}
                    openCopyTab={openCopyTab}
                    setOpenCopyTab={setOpenCopyTab}
                    setCloseProfileModal={handleProfileClose}
                    toggleExplorePage={handleExplorePage}
                    setQuestionnaire={setQuestionnaire}
                    setJoinPopup={setJoinPopup}
                    open={openProfileModal}
                />
            )}
            {questionnaire && (
                <Questionnaire setQuestionnaire={setQuestionnaire} open={questionnaire} />
            )}
        </div>
    );
};

export default MainLayout;
