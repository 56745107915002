import React from 'react';
// import PropTypes from 'prop-types';

const SearchIcon = ({ fill }) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='19' height='19' viewBox='0 0 19 19' fill='none'>
            <g opacity='0.5'>
                <path d='M14.8495 7.79997C14.8495 11.6936 11.6932 14.8499 7.79977 14.8499C3.90631 14.8499 0.75 11.6936 0.75 7.79997C0.75 3.90636 3.90631 0.75 7.79977 0.75C11.6932 0.75 14.8495 3.90636 14.8495 7.79997Z' stroke={fill} strokeWidth='1.5' />
                <path d='M13.2002 13.1992L18.0001 17.9992' stroke={fill} strokeWidth='1.5' strokeLinecap='round' />
            </g>
        </svg>
    );
};

// SearchIcon.propTypes = {};

export default SearchIcon;
