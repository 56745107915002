import React, { useRef } from 'react';
// import PropTypes from 'prop-types';

import './Button.css';


const Button = ({
    text,
    onClickHandler,
    onFocusAction,
    onBlurAction,
    className,
    iconStart,
    iconStartSVG,
    iconEnd,
    isFaded,
    ariaHaspopup,
    ariaExpanded,
    ariaControls,
    tabIndex,
    style,
    ...rest
}) => {
    const { dataName } = rest;
    const styledButton = useRef();
    const handleOnFocus = () => {
        styledButton.current.style.backgroundColor = onFocusAction;
    };
    const handleOnBlur = () => {
        styledButton.current.style.backgroundColor = onBlurAction;
    };

    return (
        <button
            className={className}
            type='button'
            onClick={onClickHandler}
            onFocus={() => undefined}
            onBlur={() => undefined}
            onMouseOver={onFocusAction ? handleOnFocus : null}
            onMouseOut={onBlurAction ? handleOnBlur : null}
            data-name={dataName}
            tabIndex={tabIndex || 0}
            aria-haspopup={ariaHaspopup}
            aria-expanded={ariaExpanded}
            aria-controls={ariaControls}
            style={style}
            ref={styledButton}
        >
            {iconStart && <i className={`${iconStart} icon-start`} />}
            {iconStartSVG && iconStartSVG}
            <span className={isFaded && 'faded-text'} data-name={dataName}>
                {text && text}
                {iconEnd && <i className={`${iconEnd} icon-end`} />}
            </span>
        </button>
    );
};

// Button.propTypes = {};

export default Button;
