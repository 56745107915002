import { getStore } from '../storeContainer'
import { StatusModes } from '../reducers/generalStatusReducer';
let isLuckyOrnageRunning = false;

let initLuckyOrnage = function (luckyOrnageData) {
  isLuckyOrnageRunning = true;
  window.__lo_site_id = luckyOrnageData.siteId;
  var head = document.getElementsByTagName('head')[0];
  var wa = document.createElement('script');
  wa.type = 'text/javascript';
  wa.async = true;
  wa.src = 'https://d10lpsik1i8c69.cloudfront.net/w.js';
  head.appendChild(wa);
}

let setUserVars = function (userDetails, envName) {
  window._loq = window._loq || [];
  window._loq.push(['custom', {
    'brandName': userDetails.brandName,
    'brokerName': userDetails.brokerName,
    'tpUserId': userDetails.tpUserId,
    'userId': userDetails.userId,
    'name': userDetails.name,
    'envId': userDetails.envId,
    'envName': envName,
  }]);
}

export function SendEventToLuckyOrange(params) {
  window._loq = window._loq || [];
  if(Array.isArray(params)){
    params.forEach(tagName => {
      if(typeof tagName === 'string'){
        window._loq.push(["tag", tagName]);
      }
    });
  }
  if(typeof params === 'string'){
    window._loq.push(["tag", params]);
  }
}

export default function Init() {
  let store = getStore();
  function selectUserGuid(state) {
    return state.account.accountInfo.userDetails.userId;
  }

  let currentUserGuid = selectUserGuid(store.getState());

  store.subscribe(() => {
    let state = store.getState();
    let loginStatus = state.generalStatus.status;
    let luckyOrangeData = state.account.accountInfo.analyticData.luckyOrangeData;
    let userDetails = state.account.accountInfo.userDetails;

    if (loginStatus === StatusModes.accountLogin) {
      if (!isLuckyOrnageRunning && luckyOrangeData.isEnabled && luckyOrangeData.siteId){
        initLuckyOrnage(luckyOrangeData);
      }

      if (isLuckyOrnageRunning)
      {
        let previousUserGuid = currentUserGuid;
        currentUserGuid = selectUserGuid(state);
        if (previousUserGuid !== currentUserGuid){
          let envName = state.environments.environments[userDetails.envId]?.displayName ?? "";
          setUserVars(userDetails, envName);
        }
      }
    }
  });
}
