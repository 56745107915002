import React from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';

import Button from '../primitives/Button';


const Terms = ({ onConfirm }) => {
    const { t } = useTranslation();
    return (
        <div>
            <p align='center' dir='LTR'>
                <strong><u>Sirix Social Trading Network: Terms and Conditions</u></strong>
            </p>
            <p dir='LTR'>
                AS PART OF THE ONLINE TRADING PLATFORM THAT WE (THROUGH OUR LICENSOR) PROVIDE TO YOU (THE &quot;
                <strong>SYSTEM</strong>
                &quot;), WE MAKE AVAILABLE A SOCIAL TRADING
                FEATURE THAT ALLOWS YOU TO (I) SHARE TRADING INFORMATION AND RESULTS, AND (II) TRADE ACCORDING TO OTHER TRADERS' TRADING STRATEGIES AND PERFORMANCES IN
                REAL-TIME (COLLECTIVELY, THE &quot;
                <strong>SOCIAL TRADING SERVICE</strong>
                &quot;). BY CLICKING &quot;I AGREE&quot; BELOW, OR BY OTHERWISE USING THE SOCIAL TRADING SERVICE, YOU
                ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD AND CONSENTED TO THESE TERMS AND CONDITIONS (THE &quot;
                <strong>TERMS</strong>
                &quot;).
            </p>
            <p dir='LTR'>
                You acknowledge that the Social Trading Service will generate a public trading profile of you and other traders, based on information you (and they)
                specifically provide and your (and their) past and current trading activities, such as opening and closing of trades, margin data, profits and losses, and
                other factors (the &quot;
                <strong>Profile</strong>
                &quot;). Your Profile will be available to other traders, who will have the opportunity to learn about your
                strategies and/or copy your trades; in addition, the Profiles of other traders will be available to you, and you will have a chance to learn about the
                strategies and/or copy the trades of such other traders, all as more specifically described in these Terms.
            </p>
            <p dir='LTR'>
                A copied trader&apos;s positions (with respect to any financial instruments that are included on the Social Trading Service and available for trading by you)
                shall be copied based on a number of units from which you can choose prior to copying another trader. Accordingly, you may not be able to purchase the
                financial instruments in the same proportional amounts and with the same leverage as the copied trader. All such positions shall be closed automatically if
                and when closed by the copied trader and/or if the copied trader's positions were automatically closed by Licensor or us, for whatever reason, without
                providing any further notice and without any action on your part. We cannot guarantee that trades based on another trader&apos;s trades will be able to be
                executed, due to limitations relating to your account or the market generally. You acknowledge that trades based on another trader’s trades are likely to
                execute at different prices and/or volumes than those of such other trader. You should be able and prepared to bear the loss of the entire investment you
                made based on your copying another trader. You are fully responsible for any losses you may sustain as a result of our automatic execution of instructions
                generated as a result of utilizing the Social Trading Service. You further acknowledge that if you place additional trades in your account or you modify or
                cancel an order generated by the Social Trading Service, you may achieve a materially different result than the trader that you copied. Changing a copied
                position shall cause its disconnection from the original position. Such disconnected position shall not be automatically closed or changed when the
                original position is changed or closed.
            </p>
            <p dir='LTR'>
                Past performance of another trader indicated as part of the Social Trading Service is not indicative of future results. When reviewing the portfolio,
                financial performance information, opinions or advice of another trader, including User Submissions (defined below), you should not assume that such trader
                is unbiased, independent or qualified to provide financial information or advice. IN SOME CASES, ANOTHER TRADER’S PROFILE AND/OR TRADING HISTORY MAY NOT
                REFLECT THE ACTUAL RESULTS OF HIS/HER TRADING. This may be because the trades reflected in that Profile include, in whole or in part, trades made by the
                trader acting in “test” mode; or because your broker has modified retroactively the actual positions taken by the trader, and consequently the actual
                profit and loss history of that trader.
            </p>
            <p dir='LTR'>
                The Social Trading Service is provided on an &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; basis without warranties, express, implied or statutory, of any kind; it is made
                available to you for information purposes only and you alone bear all risk for your reliance upon, or use of, any information or functionalities contained
                therein. We and our licensor (the &quot;
                <strong>Licensor</strong>
                &quot;) cannot guarantee that the information presented on the Social Trading Service is complete,
                accurate, current, or error-free. You acknowledge that neither we nor our Licensor represent or guaranty that your use of the Social Trading Service will,
                or is likely to, result in profits or losses similar to those achieved/incurred by copied third party traders. Moreover, since the Social Trading Service
                is part of the System, your use of, or inability to use, the Social Trading Service is also subject to the usage rights, restrictions, disclaimers, and
                limitations on liability set forth in the terms and conditions that govern your use of the System. In the event of any conflict between these Terms and
                those terms and conditions governing use of the System (including the Social Trading Service), the terms and conditions governing use of the System shall
                prevail.
            </p>
            <p dir='LTR'>
                The Social Trading Service may also allow for the hosting, sharing, transmitting and publishing of information regarding your trading and the trading of
                other users or brokers (&quot;
                <strong>User Submissions</strong>
                &quot;). Even though Licensor has complete discretion as to whether to allow User Submissions to be or
                remain published, you acknowledge that Licensor does not edit or contribute in any way to your User Submissions or those of other traders, and you agree
                that you alone are responsible for your User Submissions. You should not assume that the User Submissions of any other trader are unbiased, independent or
                accurate. You undertake not to violate any applicable laws (including, but not limited to, privacy and libel laws) and any third party rights (including,
                but not limited to, intellectual property and publicity rights) in relation thereto. You will be solely liable for any such violations, and you agree to
                indemnify Licensor for any losses, damages, fines, costs, expenses (including reasonable attorneys' fees) incurred by Licensor as a result of such
                violations.
            </p>
            <p dir='LTR'>
                For each User Submission you hereby grant Licensor a worldwide, irrevocable, non-exclusive, royalty-free, perpetual, sub-licensable and transferable
                license to use, reproduce, distribute, make derivative works of, display, and perform the User Submissions in connection with the Social Trading Service;
                and, to the extent permitted by the law of your jurisdiction, you hereby waive all moral rights in your User Submissions.
            </p>
            <p dir='LTR'>
                Licensor reserves the right to access, read, preserve, and disclose any information or data on, in, or related to, the Social Trading Service (including,
                without limitation, any User Submissions) as the Licensor reasonably believes is necessary to (i) satisfy any applicable law, regulation, legal process,
                subpoena or governmental request: (ii) enforce these terms, including investigation of potential violations of it, or other abuses of the System; (iii)
                detect, prevent, or otherwise address fraud, security or technical issues, (iv) respond to user support requests; and/or (v) protect the rights, property
                or safety of the Licensor, its customers or the public.
            </p>
            <p dir='LTR'>
                You further agree not to use, endorse, and/or promote content
                <a name='_DV_C42'> </a>
                in connection with your use of the Social Trading Service, including
                without limitation any User Submission, which use is, promotes or advocates pornographic, obscene, excessively profane,
                <a name='_DV_C44'>racist, ethnically offensive, threatening, infringing, excessively violent</a>
                <a name='_DV_M57' />
                , libelous, gambling-related
                <a name='_DV_C45'>,</a>
                <a name='_DV_C46'>
                    or activity that discriminates on the basis of gender, race, national origin, religion, age, marital status, or disability, promotes illegal drugs or
                    arms trafficking, violates export control laws, creates a risk to a person’s safety or health, compromises national security or interferes with an
                    investigation by law enforcement officials, or is
                </a>
                <a name='_DV_M58' />
                otherwise offensive, misleading, or deceptive.
            </p>
            <p dir='LTR'>
                Licensor reserves the right in its sole discretion to update, restrict, modify, de-activate, suspend and/or cancel the Social Trading Service or any
                portion thereof and/or your right and ability to use, access and/or interact with the Social Trading Service or any portion thereof (&quot;
                {' '}
                <strong>Social Trading Change(s)</strong>
                &quot;), at any time and for any reason, and Licensor shall be neither responsible nor liable for any damages, costs or
                losses suffered or incurred by you as a result of any Social Trading Change.
            </p>
            <p dir='LTR'>
                Licensor reserves the right to temporarily or permanently block you or us from using the Social Trading Service, or certain portions thereof, or make your interaction with the Social Trading Service invisible
            </p>
            <p dir='LTR'>
                Licensor collects and stores Information from you, including without limitation, your name, home or other physical address, email address, phone number, other contact information and User Submissions (&quot;
                <strong>Your Information</strong>
                &quot;). Licensor also collects and stores an activity log unique to you that collects certain administrative and traffic information including without limitation source IP address, time of access, date of access, web page(s) visited, language use, type of browser used, the technical operation of the Social Trading Service, and any information required to ensure your use of the Social Trading Service is compliant with the Terms. Licensor collects and stores the aforesaid information when you visit or use the Social Trading Service and when you provide it to us and/or the Licensor, and also when you use the Social Trading Service, to conduct statistical analysis, provide customer support, to undertake necessary security and identify verification checks, to meet certain business requirements and for any other purpose related to the operation of the Social Trading Service. Your Information and User Submissions may also be used by Licensor to improve the Social Trading Service and/or customer service. Licensor may also use the Information to track your use of the Social Trading Service and/or for other internal purposes, such as evaluating, providing, and improving the Social Trading Service.
            </p>
            <p dir='LTR'>
                The Licensor may disclose Your Information to associated entities and/or to third parties, at the Licensor's sole discretion, where the purpose of such disclosure is to provide you with the Social Trading Service. In addition, Licensor may disclose Your Information if required to do so by law, or if Licensor believes in good faith that such action is necessary to comply with applicable legislation, a current judicial proceeding, a court order or legal process, or to protect and defend the Licensor's (or its licensor's) rights or property, the personal safety of other users of the Social Trading Service or the public at large. Licensor reserves the right to share the Information with appropriate authorities and financial institutions, if the Licensor determines in its sole you are attempted to defraud Licensor, or if Licensor suspects you are committing any fraudulent activity, or any other prohibited transaction, or if you breach these Terms.
            </p>
            <p dir='LTR'>
                Licensor may transfer Your Information to jurisdictions outside your jurisdiction of residence and/or outside the European Union. The data protection and privacy laws of the jurisdictions to which the Information will be transferred may not be as comprehensive as those in the European Union (if applicable to you); in which case  Licensor  may take measures to ensure a similar level of protection is provided to your Information.
            </p>

            <p dir='LTR'>
                Licensor further reserves the right, at its discretion, to change these Terms at any time, which change will be effective ten (10) days following the
                posting of the revised Terms on the Social Trading Service. Your continued use of the Social Trading Service ten (10) days following such posting means
                that you accept those changes.
            </p>
            <p dir='LTR'>
                You should not engage in any investment directly or indirectly in financial instruments unless you know and understand the features and risks involved.
                Trading based solely on copying other traders is not recommended.
            </p>
            <p dir='LTR'>
                <strong>Last Updated: April 30, 2014</strong>
            </p>
            <div>
                <Button text={t('General.Confirm')} className='btn btn-copy' onClickHandler={onConfirm} />
            </div>
        </div>
    );
};

// Terms.propTypes = {};

export default Terms;
