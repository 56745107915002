import PubsubRepository from "./PubsubRepository" ;

class Tick
{
  symbol = "";
  bid = 0;
  ask = 0;
  bidProfitConversion = null;
  askProfitConversion = null;
  marginConversion = null;
  timestamp = 0;
  timestampUTC = 0;
  isOffline = true;
}

export const TicksRepository = new PubsubRepository({
  createInstance(){
    return new Tick();
  },
  
  transform(traget,source)
  {
    traget.symbol = source.sm;
    traget.bid = source.b;
    traget.ask = source.a;
    traget.bidProfitConversion = source.bpc;
    traget.askProfitConversion = source.apc;
    traget.marginConversion = source.mc;
    traget.timestamp = source.t; 
    traget.timestampUTC = source.tu; 
    traget.isOffline = source.o === 0? false:true;
  },
  deepClone(traget,source)
  {
    traget.symbol = source.symbol;
    traget.bid = source.bid;
    traget.ask = source.ask;
    traget.bidProfitConversion = source.bidProfitConversion;
    traget.askProfitConversion = source.askProfitConversion;
    traget.marginConversion = source.marginConversion;
    traget.timestamp = source.timestamp; 
    traget.timestampUTC = source.timestampUTC; 
    traget.isOffline = source.isOffline;
  },
  
  getKey(item)
  {
    return item.sm;
  }
});