import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// import PropTypes from 'prop-types';

import depositButtonClickHandler from '../utilsUI/depositButtonClickHandler';


const MarginAlert = () => {
    const { t } = useTranslation();
    const { deposit: { depositUrl, getDepositUrlCommand } } = useSelector((state) => (state.account.accountInfo.featuresData));
    const { Text: { GeneralColor } } = useSelector((state) => (state.brand.theme));
    const handleDepositClick = () => {
        depositButtonClickHandler(depositUrl, getDepositUrlCommand);
    };

    return (
        <div
            className='disclaimer disclaimer--top cursor--pointer'
            style={{ color: GeneralColor }}
            onClick={handleDepositClick}
        >
            <span>{t('Margin.Alert.Deposit')}</span>
        </div>
    );
};

// GeneralDisclaimer.propTypes = {};

export default MarginAlert;
