import {getStore} from '../storeContainer'
import {CommandFactory} from '../commands/CommandFactory'

let firstCallUpdate = async function(retryAttempt)
{
  let social = getStore().getState().account.socialv1;
  if (social.isEnabled)
  {
    if (social.initSocialDataCommand)
    {
      let res = await CommandFactory.createCommand(social.initSocialDataCommand).execute();
      if (!(res && res.isSuccessful))
      {
        if (retryAttempt !== 9)
        {
          console.log("Social init request return unsuccessful")
          setTimeout(()=>{
            firstCallUpdate(retryAttempt +1);
          },3000);
        }
        else{
          console.log("Social init request return unsuccessful, will not retry")
        }
      }
    }
  }
  else
  {
    // clear
  }
}

let secondCallUpdate = function()
{
  let social = getStore().getState().account.socialv1;
  if (social.isJoined)
  {
    if (social.moreSocialDataCommand)
    {
      CommandFactory.createCommand(social.moreSocialDataCommand).execute();
    }
  }
  else
  {
    // clear
  }
}

export default function Init()
{
  firstSocialCall();
  secondSocialCall();
}

function firstSocialCall() {
  let store = getStore();
  function select(state) {
    return state.account.socialv1.isEnabled;
  }

  let currentValue = select(store.getState());

  store.subscribe(() => {
    let previousValue = currentValue;
    currentValue = select(store.getState());
    if (previousValue !== currentValue) {
      firstCallUpdate(0);
    }
  });
}

function secondSocialCall() {
  let store = getStore();
  function select(state) {
    return state.account.socialv1.isJoined;
  }

  let currentValue = select(store.getState());

  store.subscribe(() => {
    let previousValue = currentValue;
    currentValue = select(store.getState());
    if (previousValue !== currentValue) {
      secondCallUpdate();
    }
  });
}
