export const INDICATORS = [
    {
        name: 'Aroon',
        settings: { n: 15 },
        styles: {
            colorUp: '#ee7d00', boldUp: false, colorDown: '#00b0ea', boldDown: false
        }
    },
    {
        name: 'BollingerBands',
        settings: { n: 15, k: 1 },
        styles: {
            colorBasis: '#0e73b9', boldBasis: false, colorBorder: '#00b0ea', boldBorder: false, colorBg: '#00b0ea'
        }
    },
    {
        name: 'DonchianChannel',
        settings: { n: 15 },
        styles: {
            colorBasis: '#ee7d00', boldBasis: false, colorBorder: '#ffcb00', boldBorder: false, colorBg: 'rgba(255,202,0,0.2)'
        }
    },
    { name: 'ExponentialMovingAverage', settings: { n: 15 }, styles: { color: '#eb609e', bold: false } },
    {
        name: 'IchimokuCloud',
        settings: {
            nConv: 9, nBase: 26, nSpan: 52, nDisp: 26
        },
        styles: {
            boldALine: false,
            boldBLine: false,
            boldBase: false,
            boldConv: false,
            boldLag: false,
            colorALine: '#934b95',
            colorBLine: '#ef7d00',
            colorBase: '#65b22e',
            colorBg1: 'rgba(226,6,18,0.2)',
            colorBg2: 'rgba(101,178,46,0.3)',
            colorConv: '#e20612',
            colorLag: '#006ab4'
        }
    },
    { name: 'Momentum', settings: { n: 15 }, styles: { color: '#eb609e', bold: false } },
    {
        name: 'MovingAverageConvergenceDivergence',
        settings: { nFast: 12, nSlow: 26, nSignal: 9 },
        styles: {
            boldMACD: false,
            boldSignal: false,
            colorHistogram: '#944b96',
            colorMACD: '#65b22e',
            colorSignal: '#40c4ef'
        }
    },
    { name: 'PivotPoints', styles: { color: '#016ab3', bold: false } },
    {
        name: 'RelativeStrengthIndex',
        settings: { n: 15, overbought: 70, oversold: 30 },
        styles: {
            color: '#65b22e',
            bold: false,
            colorBg: 'rgba(174,208,131,0.2)'
        }
    },
    { name: 'SimpleMovingAverage', settings: { n: 15 }, styles: { color: '#ee7d00', bold: false } },
    {
        name: 'StochasticOscillator',
        settings: {
            n: 15, upper: 80, d: 3, lower: 20, k: 3
        },
        styles: {
            colorK: '#e20613',
            boldK: false,
            colorD: '#ee7d00',
            boldD: false,
            colorBg: 'rgba(255,202,0,0.2)'
        }
    },
    {
        name: 'WilliamsAlligator',
        settings: {
            nJaw: 21, falsesetJaw: 8, nTeeth: 13, falsesetTeeth: 5, nLips: 8, falsesetLips: 3
        },
        styles: {
            colorJaw: '#aed083',
            boldJaw: false,
            colorTeeth: '#e20613',
            boldTeeth: false,
            colorLips: '#006ab4',
            boldLips: false
        }
    }
];
