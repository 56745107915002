import React from 'react';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';

import Button from './Button';
import './ModalWrapper.css';


const ModalWrapper = ({ open, handleClose, onBackdropClick, children, disableBackdropClick, childClassName, heading, contentClassName, hideCloseButton, ...rest }) => {
    const CloseButtonProps = {
        onClickHandler: handleClose,
        ariaLabel: 'Close',
        className: 'btn btn--close',
        iconEnd: 'fa fa-times'
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            onBackdropClick={onBackdropClick}
            disableBackdropClick={disableBackdropClick}
            {...rest}
        >
            <Fade in={open}>
                <div className={`modal-window ${childClassName}`}>
                    {heading && <h2>{heading}</h2>}
                    {!hideCloseButton && (
                        <div className='actions'>
                            <Button {...CloseButtonProps} />
                        </div>
                    )}
                    <div className={contentClassName}>
                        {children}
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

export default ModalWrapper;