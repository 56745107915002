import React, {useCallback} from 'react';
import {shallowEqual, useSelector} from 'react-redux';

import useTheme from '../../context/contextHook/useTheme';

const MobileLogo = () => {

	const {logo, hideLogo} = useTheme();

	const {logoMainUrl} = useSelector(state => state.brand.generalInfo,
			shallowEqual);

	const handleClickLogo = useCallback(() => {
		if (!logoMainUrl) return null;
		return window.open(logoMainUrl);
	}, [logoMainUrl]);

	return (
			<>
				{hideLogo && (<></>)}
				{!hideLogo && (
						<div className="mobile-logo">
							<img src={logo || ''} alt='sirix' onClick={handleClickLogo}/>
							{/*<a href={logoMainUrl || ''} target={logoMainUrl ? '_blank' : ''}>*/}
							{/*	<img*/}
							{/*			src={logo || ''}*/}
							{/*			alt="sirix"*/}
							{/*			className="mobile-header__logo"*/}
							{/*	/>*/}
							{/*</a>*/}
						</div>
				)}
			</>
	);
};

export default MobileLogo;
