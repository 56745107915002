import LimitedSizeList from '../socialv1/limitedSizeList'
import {getStore} from '../storeContainer'

let allTrades = new LimitedSizeList(trade=>trade.TradeHash,100);
let topRankedTrades = new LimitedSizeList(trade=>trade.TradeHash,100);
let watchTraders = new LimitedSizeList(trade=>trade.TradeHash,100);

export const isSupportedSymbol = function isSupportedSymbol(trade,symbols){
  return !(!symbols[trade.InstrumentName]);
}

function iamWatching(storeSocial, trade) {
  return storeSocial.copying[trade.Nickname] ||
    storeSocial.following[trade.Nickname];
}

export const onSocialV1TradeArrived = function(trades)
{
  let state =  getStore().getState();
  let storeSocial = state.account.socialv1
  let storeSymbols = state.account.symbols.symbols;

  for(let trade of trades)
  {
    if (trade.Nickname !== storeSocial.userDetails.nickname)
    {
      if (!trade.IsCopied)
      {
        if (isSupportedSymbol(trade,storeSymbols))
        {
          allTrades.pushItem(trade);
    
          if (iamWatching(storeSocial, trade))
          {
            watchTraders.pushItem(trade);
          }
        
          if (storeSocial.topTraders[trade.Nickname])
          {
            topRankedTrades.pushItem(trade);
          }
        }
      }
    }
  }
}

export const allTradesStream = allTrades;
export const topRankedTradeStream = topRankedTrades;
export const watchTradersStream = watchTraders;
