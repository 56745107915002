import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import Input from '@material-ui/core/Input';
import OuterClick from '../../../UI/primitives/OuterClick';
import Button from '../../../UI/primitives/Button';
import CheckBox from '../../../UI/primitives/CheckBox';

const MobileCurrencySelect = ({
	                              portfolio,
	                              instruments,
	                              setInstruments,
	                              handleClick,
	                              systemColor,
                              }) => {
	const [currencies, setCurrencies] = useState(null);
	const [placeholder, setPlaceholder] = useState('');
	const [initialCurrencies, setInitialCurrencies] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [all, setAll] = useState(true);
	const {t} = useTranslation();

	useEffect(() => {
		const result = {};
		if (instruments?.length) {
			if (portfolio?.TopInstruments?.length) {
				portfolio.TopInstruments.forEach((item) => {
					result[item?.Symbol] = instruments.includes(item?.Symbol);
				});
			} else if (portfolio?.AllowedInstruments?.length) {
				portfolio.AllowedInstruments.forEach((item) => {
					result[item?.Symbol] = instruments.includes(item?.Symbol);
				});
			}
		} else if (portfolio?.TopInstruments?.length) {
			portfolio.TopInstruments.forEach((item) => {
				result[item?.Symbol] = true;
			});
		} else if (portfolio?.AllowedInstruments?.length) {
			portfolio.AllowedInstruments.forEach((item) => {
				result[item?.Symbol] = true;
			});
		}
		setCurrencies(result);
		setInitialCurrencies(result);
	}, [instruments]);

	useEffect(() => {
		const searchResult = {};
		if (searchValue) {
			Object.entries(currencies).forEach(([key, value]) => {
				if (key.toLowerCase().includes(searchValue.toLowerCase())) {
					searchResult[key] = value;
				}
			});
			setCurrencies(searchResult);
		} else {
			initialCurrencies && setCurrencies(initialCurrencies);
		}
	}, [searchValue]);

	const selectCurrency = (e) => {
		setCurrencies(
				(prevState) => ({...prevState, [e.target.name]: e.target.value}));
	};

	const toggleAll = () => {
		const changedCurrencies = {};
		Object.keys(currencies).forEach((key) => {
			if (!all) {
				changedCurrencies[key] = true;
			} else {
				changedCurrencies[key] = false;
			}
		});
		setCurrencies(changedCurrencies);
	};

	useEffect(() => {
		if (currencies) {
			const allTrue = Object.values(currencies).every((item) => item);
			setAll(allTrue);
		}
	}, [currencies]);

	const handleCurrencies = () => {
		const arrayToUse = [];

		Object.entries(currencies).forEach(([key, value]) => {
			if (value) {
				arrayToUse.push(key);
			}
		});
		if (!all) {
			setInstruments(arrayToUse);
		} else {
			setInstruments([]);
		}
		handleClick();
	};

	const handleSearch = (e) => {
		setSearchValue(e.target.value);
	};

	const resetSearch = () => {
		setSearchValue('');
	};

	const icon = searchValue ? (
			<CloseIcon/>
	) : (
			<SearchIcon/>
	);

	useEffect(() => {
		const result = currencies &&
				Object.entries(currencies).map(([key, value]) => {
					if (value) return key;
				});
		if (result && result.length) {
			setPlaceholder(result.join(' , '));
		}
	}, [currencies]);

	return (
			<div className='mobile-currency-select currency-expanded__container'>
				<div className='currency-expanded__search-container'>
					<Input
							id='symbol-search'
							// placeholder={placeholder || t('MarketRates.Search')}
							placeholder={t('MarketRates.Search')}
							name='symbol-search'
							value={searchValue}
							onChange={handleSearch}
							fullWidth
							endAdornment={icon}
							autoComplete='off'
					/>

				</div>
				<div className='currency__list'>
					<CheckBox
							value={all}
							name='all'
							label='All'
							labelPlacement
							color={systemColor}
							handleCheckBoxClick={toggleAll}
							key={all}
					/>
					{currencies && Object.entries(currencies).map(([key, value]) => {
						return (
								<CheckBox
										value={value}
										name={key}
										label={key}
										labelPlacement
										color={systemColor}
										handleCheckBoxClick={selectCurrency}
										key={key}
								/>
						);
					})}
				</div>
				<div className='currency-expanded__buttons'>
					{/*TODO: locale this*/}
					<Button
							text='Cancel'
							className='btn btn-cancel btn--general'
							onClickHandler={handleClick}
					/>
					<Button
							text='Apply'
							className='btn btn-update btn--general'
							onClickHandler={handleCurrencies}
					/>
				</div>
			</div>
	);
};

export default OuterClick(MobileCurrencySelect);
