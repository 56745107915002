import React, { useEffect, useState } from 'react';
import { List, AutoSizer } from 'react-virtualized';
import { useSelector } from 'react-redux';

import TickItem from '../TickItem/TickItemWrapper';
// import LoadSpinner from '../primitives/LoadSpinner';

import '../ResizableLayout/LeftPanel.css';


const TicksList = ({
    orderedSymbols,
    handleItemSelect,
    selectedSymbol,
    shouldDisplayPin,
    shouldDisplayInfo,
    // shouldDisplayFlags,
    shouldDisplayTime
    // leftScrollWidth
}) => {
    const [listSize, setListSize] = useState(0);
    const { SubHeader: { Text }, Tick: { TickUp, TickDown, TickNeutral } } = useSelector((state) => (state.brand.theme));
    // eslint-disable-next-line consistent-return
    const selectItem = (e) => {
        const current = e.target.closest('.bid-item__container');
        if (!current) { return null; }
        handleItemSelect(orderedSymbols.find((item) => { return item.id === current.getAttribute('data-symbol'); }));
    };

    const renderRow = ({ index, key, style }) => {
        return (
            <div key={key} style={style}>
                <TickItem
                    name={orderedSymbols[index].id}
                    repObj={orderedSymbols[index].tick}
                    tickData={orderedSymbols[index]}
                    pipDigit={orderedSymbols[index].displayDigit}
                    isActive={orderedSymbols[index].id === selectedSymbol}
                    shouldDisplayPin={shouldDisplayPin}
                    shouldDisplayInfo={shouldDisplayInfo}
                    // shouldDisplayFlags={shouldDisplayFlags}
                    shouldDisplayTime={shouldDisplayTime}
                    subHeaderColor={{ color: Text }}
                    tickUp={TickUp}
                    tickDown={TickDown}
                    tickNeutral={TickNeutral}
                />
            </div>
        );
    };

    useEffect(() => {
        const element = document.getElementsByClassName('left-panel__inner').item(0);
        setListSize({ height: element.offsetHeight });
    }, []);


    return (
        <div className='left-panel__inner'>
            {/* <div className='left-panel__full-height' onClick={selectItem} style={{ width: leftScrollWidth }}> */}
            <div className='left-panel__full-height' onClick={selectItem}>
                {/* {(isLoading || orderedSymbols.length === 0) && <LoadSpinner />} */}
                <AutoSizer className='autosizer'>
                    {
                        // eslint-disable-next-line no-unused-vars
                        ({ width, height }) => {
                            return (
                                <List
                                    width={width}
                                    height={listSize.height || 0}
                                    rowHeight={60}
                                    rowRenderer={renderRow}
                                    rowCount={orderedSymbols.length}
                                    overscanRowCount={10}
                                />
                            );
                        }
                    }
                </AutoSizer>
            </div>
        </div>
    );
};


export default TicksList;
