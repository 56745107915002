import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import MobilePopUpHeader from '../../MobilePopUp/MobilePopUpHeader';
import MobileButtonClose from '../../Buttons/MobileButtonClose';

const MobileClosePositionHeader = ({onClose, id, reference}) => {
	const {t} = useTranslation();

	const handleClose = useCallback(() => {
		onClose && onClose();
	}, [onClose]);

	return (
			<MobilePopUpHeader>
				<div
						ref={reference}
						className="mobile-close-position-popup__header"
				>
					<div className="mobile-close-position-popup__header__title">
						{t('Close.Order.Title')}
						<div className="mobile-close-position-popup__header__title__id">
							:&nbsp;
							{`ID ${id}`}
						</div>
					</div>
					<div className="mobile-close-position-popup__header__close">
						<MobileButtonClose onClick={handleClose}/>
					</div>
				</div>
			</MobilePopUpHeader>
	);
};

export default MobileClosePositionHeader;
