import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';


const QuestionStepThree = ({ steps, setSteps, SystemColor, TextColor }) => {
    const { t } = useTranslation();
    useEffect(() => {
        const isFulfilled = Object.keys(steps.stepData[2]).every((k) => { return steps.stepData[2][k]; });
        if (isFulfilled && steps.stepData[2].isFulfilled !== true) {
            const nextStepData = steps.stepData;
            nextStepData[2] = { ...nextStepData[2], isFulfilled: true };
            setSteps((prevState) => ({
                ...prevState, stepData: nextStepData
            }));
        }
    }, [steps]);
    const {
        third,
        fourth
    } = steps.stepData[2];
    const options = {
        third: [
            { id: 1, value: t('Socialv1.Suitability.Q5.Part1.Answer1') },
            { id: 2, value: t('Socialv1.Suitability.Q5.Part1.Answer2') }
        ],
        fourth: [
            { id: 1, value: t('Socialv1.Suitability.Q5.Part2.Answer1') },
            { id: 2, value: t('Socialv1.Suitability.Q5.Part2.Answer2') },
            { id: 3, value: t('Socialv1.Suitability.Q5.Part2.Answer3') },
            { id: 4, value: t('Socialv1.Suitability.Q5.Part2.Answer4') }
        ]
    };

    const selectOption = (value, question) => {
        const nextStepData = steps.stepData;
        nextStepData[2] = { ...nextStepData[2], [question]: value };
        setSteps((prevState) => ({
            ...prevState, stepData: nextStepData
        }));
    };

    const RadioButtonsGroup = options.third.map((option) => {
        return (
            <div className={option.id === third ? 'radio radio--selected' : 'radio'} key={option.id} onClick={() => selectOption(option.id, 'third')}>
                {option.value}
            </div>
        );
    });

    const RadioButtonsGroupFourth = options.fourth.map((option) => {
        return (
            <div className={option.id === fourth ? 'radio radio--selected' : 'radio'} key={option.id} onClick={() => selectOption(option.id, 'fourth')}>
                {option.value}
            </div>
        );
    });

    return (
        <div className='step step-three'>
            <p>
                {t('Socialv1.Suitability.Q5.Title')}
            </p>
            <div className='p--inner'>
                <p>
                    {t('Socialv1.Suitability.Q5.Part1.Title')}
                </p>
                <div className='steps__radio-group'>
                    {RadioButtonsGroup}
                </div>
                <p>
                    {t('Socialv1.Suitability.Q5.Part2.Title')}
                </p>
                <div className='steps__radio-group'>
                    {RadioButtonsGroupFourth}
                </div>
            </div>
        </div>
    );
};

// QuestionStepThree.propTypes = {};

export default QuestionStepThree;
