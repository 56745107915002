/* eslint-disable complexity */
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { shallowEqual, useSelector } from 'react-redux';
import { CommandFactory } from '../../../../background/commands/CommandFactory'

import { renderType } from '../../../helpers/position.helpers';
import { formatDate } from '../../../../UI/utilsUI/formatTime';
import pubsubSubscription from '../../../../background/repositories/PubsubSubscription';

import MobileInputStepper from '../../MobileInputStepper/MobileInputStepper';
import MobileButton from '../../Buttons/MobileButton';
import FormatProfitSwapCommision
    from '../../../../UI/utilsUI/FormatProfitSwapCommision';
import MobileLoader from '../../MobileLoader/MobileLoader';
import MobileModalSuccess from '../../MobileModal/MobileModalSuccess';
import MobileModalError from '../../MobileModal/MobileModalError';

import { maskNumericalInput } from '../../../helpers/input.helpers';
import {normalizedEditNumber} from '../../../../UI/OpenPositions/updateNumericHelper';
import { ProfitCalculationService } from '../../../../background/calculations/profitCalculationService';

const MobileClosePositionMain = (props) => {
    const {
        symbolName,
        openTimeS,
        actionType,
        openRate,
        profit,
        tradeLink,
        amount,
        closePositionCMD,
        handleClose,
        stopLoss,
        takeProfit
    } = props;

    const { t } = useTranslation();

    const [selectedUnits, setSelectedUnits] = useState(amount);
    const [responseErrorData, setResponseErrorData] = useState(null);
    const [responseSuccessData, setResponseSuccessData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const closeValue = pubsubSubscription.subscribeObj({ key: symbolName, repId: 'ticks' });
    const { symbols } = useSelector((state) => { return (state.account.symbols); }, shallowEqual);
    
    const isBid = actionType === 0;
    const closeRate = isBid ? closeValue?.bid : closeValue?.ask;
    const closeConvertion = isBid ? closeValue?.bidProfitConversion : closeValue?.askProfitConversion;

    const profitValue = ProfitCalculationService.calculateProfit(symbols[symbolName], actionType, normalizedEditNumber(selectedUnits,parseFloat(selectedUnits)), openRate, closeRate, closeConvertion);

    const handleValueChange = useCallback((value) => {
        console.log('handleValueChange');
        const units = maskNumericalInput(value);
        setSelectedUnits && setSelectedUnits(units);
    }, [setSelectedUnits]);

    const handleCloseOrder = useCallback(async (e) => {
        e && e.preventDefault();
        if (!closePositionCMD || !selectedUnits) return null;

        const command = CommandFactory.createCommand(closePositionCMD);
        setIsLoading(true);
        try {
            const res = await command.execute({
                amount: Number(normalizedEditNumber(selectedUnits,selectedUnits))
            });
            if (!res.isSuccessful) {
                const err = t(res.result);
                setResponseErrorData(err);
            }
            if (res.isSuccessful) {
                setResponseSuccessData({ profitValue: res.profit, price: res.price });
            }
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            setResponseErrorData(err);
        }
    }, [
        selectedUnits,
        closePositionCMD,
        setIsLoading,
        setResponseSuccessData,
        setResponseErrorData
    ]);

    const handleCloseError = useCallback(() => {
        setResponseErrorData(null);
        handleClose && handleClose();
    }, [setResponseErrorData, handleClose]);

    const handleOnCloseOrderPopup = useCallback(() => {
        setResponseSuccessData(null);
        handleClose && handleClose();
    }, [setResponseSuccessData, handleClose]);

    const buttonText = useMemo(() => {
        const renderAction = renderType(actionType);
        const action = renderAction.transition || renderAction.type;
        return `${action} ${symbolName} ${selectedUnits}`;
    }, [actionType, symbolName, selectedUnits]);

    const currentProfit = useMemo(() => {
        if (!profitValue) return '--';
        return (
            <strong>
                <FormatProfitSwapCommision tickValue={profitValue} />
            </strong>
        );
    }, [profitValue]);

    return (
        <div
            className={clsx('mobile-trade-popup',
                'mobile-close-position-popup__main')}
        >
            <MobileLoader
                overlay
                isLoading={isLoading}
            />
            {!!responseSuccessData && (
                <MobileModalSuccess
                    onClose={handleOnCloseOrderPopup}
                    title={t('Close.Order.Success')}
                >
                    <div>
                        <span>
                            {t('InstrumetInfo.Details.CloseRate')}
                            {' '}
                            :&nbsp;
                        </span>
                        <span>{responseSuccessData?.price || 0}</span>
                    </div>
                    <div>
                        <span>
                            {t('InstrumetInfo.Details.Profit')}
                            :&nbsp;
                        </span>
                        <span>
                            <FormatProfitSwapCommision
                                tickValue={responseSuccessData?.profitValue || 0}
                            />
                        </span>
                    </div>
                </MobileModalSuccess>
            )}

            {!!responseErrorData && (
                <MobileModalError
                    title={responseErrorData}
                    onClose={handleCloseError}
                />
            )}

            {!responseSuccessData && !responseErrorData && (
                <>
                    <div className='mobile-close-position-popup__main__section'>

                        {/* TIME */}
                        <div className='mobile-close-position-popup__main__time'>
                            <span>
                                {t('InstrumetInfo.Details.OpenTime')}
                                :&nbsp;
                            </span>
                            <span>
								&nbsp;
                                {formatDate(openTimeS)}
                            </span>
                        </div>

                        {tradeLink && tradeLink?.linkType === 0 && (
                            <div className='order-update__attantion'>
                                <div><i className='far fa-copy icon-start' /></div>
                                <div className='order-update__attantion-text'>
                                    {t('Social.Close.Attention')}
                                </div>
                            </div>
                        )}

                        {/* INPUT */}
                        <div className='mobile-close-position-popup__main__input'>
                            <MobileInputStepper
                                id='units-stepper'
                                selectedUnits={selectedUnits}
                                stepValue={0.01}
                                setValue={handleValueChange}
                                pipDigit={2}
                                max={amount}
                                checkDivider
                                errorMessage='Input.Error.Amount.Incorrect'
                                pattern='\d*'
                                inputmode='numeric'
                            />
                        </div>

                        <div className='mobile-close-position-popup__data-block'>
                            {/* Column 1 */}
                            <div
                                className='mobile-close-position-popup__main__section__line'
                            >
                                <div>
                                    <span>
                                        {t('InstrumetInfo.Details.OpenRate')}
                                        :&nbsp;
                                    </span>
                                    <span>{openRate}</span>
                                </div>
                                <div>
                                    <span>
                                        {t('InstrumetInfo.Details.CloseRate')}
                                        :&nbsp;
                                    </span>
                                    <span>
                                        {actionType === 0
                                            ? closeValue?.bid
                                            : closeValue?.ask}
                                    </span>
                                </div>
                            </div>

                            {/* Column 2 */}
                            <div
                                className='mobile-close-position-popup__main__section__line'
                            >
                                {!!stopLoss && (
                                    <div className='reverse'>
                                        <span>
                                            {t('InstrumetInfo.Details.StopLoss')}
                                            :&nbsp;
                                        </span>
                                        <span>{stopLoss}</span>
                                    </div>
                                )}
                                {!!takeProfit && (
                                    <div className='reverse'>
                                        <span>
                                            {t('InstrumetInfo.Details.TakeProfit')}
                                            :&nbsp;
                                        </span>
                                        <span>{takeProfit}</span>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* PROFIT */}
                        <div
                            className='mobile-close-position-popup__main__section__profit'
                        >
                            <span>
                                {t('InstrumetInfo.Details.Profit')}
                                :&nbsp;
                            </span>
                            {currentProfit}
                        </div>

                        {responseErrorData
                        && <div className='red error'>{t(responseErrorData?.result)}</div>}
                    </div>

                    <div className='mobile-close-position-popup__main__btn__wrapper'>
                        <MobileButton
                            className='mobile-close-position-popup__main__btn'
                            onClick={handleCloseOrder}
                        >
                            <span className='mobile-close-position-popup__main__btn-close'>
                                {t('InstrumetInfo.Details.Close')}
						&nbsp;
                            </span>

                            <span className='mobile-close-position-popup__main__btn-text'>
                                {buttonText}
										&nbsp;
                            </span>
                        </MobileButton>
                    </div>
                </>
            )}

        </div>
    );
};

export default MobileClosePositionMain;
