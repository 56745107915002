import React from 'react';
// import PropTypes from 'prop-types';

import CountryFlagByCode from '../Flag/CountryFlagByCode';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';

const TopRankedItem = ({ trader, handleItemSelect, index, isSmallDesktop }) => {
    const openProfile = () => {
        handleItemSelect(trader);
        SendEventToGoogleAnalytics("SocialPanel:TopRanked:ProfileClick", {nickname: trader.Nickname})
    };


    return (
        <tr key={trader?.Nickname} onClick={openProfile} role='button' tabIndex={-1}>
            {!isSmallDesktop && <td>{index + 1}</td>}
            <td className='right-panel__name'>
                <div className={`avatar ${trader?.Avatar.toLowerCase()}`}>
                    <div className='country__flag--small'>
                        {trader?.Country && CountryFlagByCode(trader?.Country)}
                    </div>
                </div>
                {trader?.Nickname}
            </td>
            <td>
                <strong className='green'>
                    {Math.round((trader?.Profit + Number.EPSILON) * 100)}
                    %
                </strong>
            </td>
            <td>{trader?.Copiers}</td>
        </tr>
    );
};

// TopRankedItem.propTypes = {};

export default TopRankedItem;
