import React, { useCallback, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { CommandFactory } from '../../../background/commands/CommandFactory'

import MobileHeaderButton from '../../components/Buttons/MobileHeaderButton';

const MobileMargin = () => {
    const { marginStatus } = useSelector((state) => state.account.financial,
        shallowEqual);

    const { deposit } = useSelector((state) => state.account.accountInfo.featuresData,
        shallowEqual);

    const shouldShowMarginAlert = useMemo(() => {
        return marginStatus && marginStatus !== 0;
    }, [marginStatus]);

    const shouldShowDepositButton = useMemo(() => {
        return deposit.isEnabled || false;
    }, [deposit, deposit.isEnabled]);

    const handleClick = useCallback(async () => {
        const { getDepositUrlCommand, depositUrl } = deposit;
        if (!CommandFactory.isCommand(getDepositUrlCommand)) {
            if (!depositUrl) return null;
            return window.open(depositUrl, '_blank', 'noopener').focus();
        }

        const command = CommandFactory.createCommand(getDepositUrlCommand);
        const tab = window.open();
        try {
            const res = await command.execute();
            if (!res.isSuccessful) {
                if (depositUrl) {
                    return tab.location.href = depositUrl;
                }
                return tab.close();
            }
            if (res.isSuccessful) return tab.location.href = res.depositUrl;
        } catch (err) {
            console.error(err); // TODO: remove log
        }
    }, [deposit]);

    return (
        <div className='mobile-margin'>
            {shouldShowDepositButton && (
                <MobileHeaderButton
                    type='money'
                    isAlert={shouldShowMarginAlert}
                    isAdd={shouldShowMarginAlert}
                    onClick={handleClick}
                />
            )}
        </div>
    );
};

export default MobileMargin;
