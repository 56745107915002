function debounce(fn, ms) {
    let timer;
    return () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            timer = null;
            // eslint-disable-next-line prefer-rest-params
            fn.apply(this, arguments);
        }, ms);
    };
}

export default debounce;
