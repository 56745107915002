import {STDCMDServerCmdAuthID} from "../commands/authServerCommand"
import produce from "immer"

export const StatusModes = {
  requireServerInit : "requireServerInit",
  requireLogin  :"requireLogin",
  accountLogin : "accountLogin",
};

function inIframe () {
  try {
      return window.self !== window.top;
  } catch (e) {
      return true;
  }
}

const  initState = {

  isIframe:inIframe(),
  ticksLoaded:false,
  status: StatusModes.requireServerInit,
  serverInitCommand: {cmdId:STDCMDServerCmdAuthID,staticParams:{postUrl : "api/getInitData",
                                                                rememberMeToken:window.localStorage.getItem('superweb-rememberme-latest')??''}
                                                 ,storeRef : { urlParameters:["urlParameters"],isIframe:["generalStatus","isIframe"] }},
};

export const generalStatusReducer = function generalStatusReducer(state = initState, action,globalState){
  switch (action.type) {
    case "ticks-init-bulk-ended":
    {
      return produce(state,draf=>{
        draf.ticksLoaded = true;
      });
    }
    case "login-logout":
      return produce(state,draf=>{
        draf.status = StatusModes.requireLogin;
        draf.ticksLoaded = false;
      });
    case "init-brand":
      return produce(state,draf=>{
        draf.status = StatusModes.requireLogin;
      });
    case "init-brand+login":
      {
      return produce(state,draf=>{
        draf.status = StatusModes.accountLogin;
      });
      }
    case "login-success":
      {
      return produce(state,draf=>{
        draf.status = StatusModes.accountLogin;
      });
      }
      
    default:
      return state;
  }
}