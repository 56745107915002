/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';

import TopRankedTopItem from '../SocialsPanel/TopRankedTopItem';
import ExplorePageItem from './ExplorePageItem';


const ExplorePageUsersList = ({ location, users, topThreeUsers, handleItemSelect, copyColor, positiveColor }) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            {topThreeUsers.length > 0 && topThreeUsers.map(
                (trader, index) => {
                    return (
                        <TopRankedTopItem
                            location={location}
                            trader={trader}
                            handleItemSelect={handleItemSelect}
                            key={trader?.Nickname}
                            index={index}
                            copyColor={copyColor}
                            positiveColor={positiveColor}
                        />
                    );
                }
            )}
            <table className='right-panel__table'>
                <thead className='top-ranked__heading'>
                    <tr>
                        <th />
                        <th>{t('Socials.Name')}</th>
                        <th>{t('Socials.Copiers')}</th>
                        <th>{t('Socials.PNL')}</th>
                        <th>{t('Socials.Days.In.System')}</th>
                        <th>{t('Socials.SuccessRate')}</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {users && users.map(
                        (trader, index) => {
                            return (
                                <ExplorePageItem location={location} trader={trader} handleItemSelect={handleItemSelect} key={trader?.Nickname} index={index} copyColor={copyColor} />
                            );
                        }
                    )}
                </tbody>
            </table>
        </Fragment>
    );
};

// ExplorePageUsersList.propTypes = {};

export default ExplorePageUsersList;
