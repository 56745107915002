import React from 'react';

const MobileFooterHeaderData = ({label, data}) => {
	return (
			<div className="mobile-footer__content__group">
				<p className="mobile-footer__content__title">
					{label}
				</p>
				<p className="mobile-footer__content__desc">
					{data}
				</p>
			</div>
	);
};

export default MobileFooterHeaderData;
