import React from 'react';
import clsx from 'clsx';
import {useSelector} from 'react-redux';
import depositButtonClickHandler from '../../../UI/utilsUI/depositButtonClickHandler';

const MobileButtonLink = props => {
	const {url, title, blank, type} = props;
  const { deposit: { depositUrl, getDepositUrlCommand } } = useSelector((state) => (state.account.accountInfo.featuresData));
  const handleDepositClick = () => {
    depositButtonClickHandler(depositUrl, getDepositUrlCommand);
  };

	return (
			<div className={clsx('mobile-button-link', type)}>
				<a
						onClick={handleDepositClick}
						className='mobile-button-link__a'
				>
					<p className="mobile-button-link__title">
						{title}
					</p>
				</a>
			</div>
	);
};

MobileButtonLink.defaultProps = {
	url: '',
	title: '',
	type: 'primary',
	blank: false,
};

export default MobileButtonLink;
