import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CommandFactory } from '../../background/commands/CommandFactory';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import OuterClick from '../primitives/OuterClick';
import Button from '../primitives/Button';
import Switch from '../primitives/Switch';


const ExpandedSettings = ({ name, handleClick }) => {
    const { t } = useTranslation();
    const { logoutCommand } = useSelector((state) => { return (state.account.accountInfo); }, shallowEqual);
    const {
        enableChangeLanguage,
        themeSettings,
        theme: { MainColor: { ActiveText } }
    } = useSelector((state) => { return (state.brand); }, shallowEqual);
    const userDetails = useSelector((state) => state.account.accountInfo.userDetails, shallowEqual);
    const environments = useSelector((state) => state.environments.environments, shallowEqual);
    const envName = environments[userDetails.envId]?.displayName ?? '';

    const handleThemeChange = () => {
        const command = CommandFactory.createCommand(themeSettings.ToggleThemeCommand);
        command.execute();
    };

    const changePassword= async ()=>{
        const command = CommandFactory.createCommand({
            cmdId:'STD.CMD.AsyncServer',
            storeRef:{},staticParams:{postUrl : 'api/ChangePassword'},userInput:{oldPassword:"ab12345",newPassword:"ab1234"}
        });

        const res = await command.execute();
    }
    const logout = async () => {
        const command = CommandFactory.createCommand(logoutCommand);
        // setIsLoading(true);

        try {
            // setResponse(null);
            // const res = await command.execute();
            const res = await command.execute();

            if (res.isSuccessful === true) {
                // setOrderInfo((prevState) => {
                //     return { ...prevState, status: 'success', price: pendingPrice };
                // });
            } else if (res.isSuccessful === false) {
                // setResponse(res);
            }
        } catch (err) {
            // setResponse(err);
        }

        // setIsLoading(false);
    };


    return (
        <div className='header__menu-container--expanded'>
            <div className='header__account-nickname'>
                <div className='header__account-nickname-item'>
                    {name}
                </div>
                {userDetails.showEnvironment && (
                    <div className='header__account-nickname-item'>
                        {envName}
                    </div>
                )}
            </div>

            {themeSettings.AllowChangeTheme && (
                <div className='header__theme-switch'>
                    <div>{t('Settings.Theme.Dark')}</div>
                    <Switch
                        isSelected={themeSettings.ActiveTheme === 'dark'}
                        handleSwitch={handleThemeChange}
                        name='toggleTheme'
                        // offText={t('Settings.Theme.Dark')}
                        // onText={t('Settings.Theme.Dark')}
                        // generalColor={generalColor}
                        activeColor={ActiveText}
                    />
                </div>
            )}
            {false && <Button text={'Change Password'} onClickHandler={changePassword}/>}
            {!!enableChangeLanguage && (
                <div>
                    <LanguageSelector additionalAction={handleClick} />
                </div>
            )}
            <div className='logout-container'>
                <Button
                    className='logout-button'
                    onClickHandler={logout}
                    text={t('Settings.Logout')}
                    iconStart='fas fa-sign-out-alt'
                />
            </div>
        </div>
    );
};

export default OuterClick(ExpandedSettings, '#menu-language');
