import React, {useCallback, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import useClickOutside from '../../hook/useClickOutside';

import MobileTransition from '../MobileTransition';
import MobileButton from '../Buttons/MobileButton';

const MobileWatchModal = ({
	                          isOpen,
	                          onClose,
	                          onSuccess,
	                          userName,
	                          position,
	                          className,
                          }) => {

	const {t} = useTranslation();

	const modalRef = useRef(null);

	useClickOutside(modalRef, () => {
		handleButtonOk();
	});

	const handleButtonOk = useCallback(() => {
		onClose && onClose();
	}, [onClose]);

	const handleButtonCopy = useCallback(() => {
		onSuccess && onSuccess()
	}, [onSuccess]);

	return (
			<MobileTransition
					isOpen={isOpen}
					type="modal"
			>
				<div
						className={clsx('mobile-modal__backdrop', position, className)}
				>
					<div
							ref={modalRef}
							className="mobile-modal__modal"
					>
						<div className="mobile-modal__watch">
							<p className="mobile-modal__watch__text">
								{t('Social.Popup.Watch.BodyText', {
									nickname: userName,
									copy: t('Socials.Button.Copy'),
								})}
							</p>
							<div className="mobile-modal__watch__btn">
								<div className="mobile-modal__btn-wrapper">
									<MobileButton
											title={t('Button.Ok')}
											type="secondary"
											onClick={handleButtonOk}
									/>
								</div>
								<div className="mobile-modal__btn-wrapper">
									<MobileButton
											type="success"
											title={t('Socials.Button.Copy')}
											onClick={handleButtonCopy}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</MobileTransition>
	);
};

MobileWatchModal.defaultProps = {
	isOpen: false,
	userName: '',
	position: 'top',
	className: '',
};

export default MobileWatchModal;
