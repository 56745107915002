import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';

import CountryFlagByCode from '../Flag/CountryFlagByCode';
import Button from '../primitives/Button';
// import LinearProgressBarWithLabel from '../primitives/LinearProgressBarWithLabel';
import Graph from '../primitives/Graph';
import formatDaysFromNow from '../utilsUI/formatDaysFromNow';
import { FormatPercentFloat } from '../ProfilePage/ProfileTabDetails';
import StarIcon from '../icons/StarIcon';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';


const TopRankedTopItem = ({ location, trader, handleItemSelect, index, copyColor, positiveColor }) => {
    const [data, setData] = useState(null);
    const { t } = useTranslation();
    const openProfile = () => {
        handleItemSelect({ ...trader, shouldOpenCopy: false });
        SendEventToGoogleAnalytics(`Explore:${location}:ProfileClick`, { nickname: trader.Nickname });
    };

    const openProfileCopy = (e) => {
        e.stopPropagation();
        handleItemSelect({ ...trader, shouldOpenCopy: true });
        SendEventToGoogleAnalytics(`Explore:${location}:CopyClick`, {nickname: trader.Nickname});
    };

    useEffect(() => {
        const labels = [];
        const values = [];
        // const pnl = trader?.PnLGraph.length && trader?.PnLGraph.slice(0, 30);
        const pnl = trader?.PnLGraph.length && trader?.PnLGraph;
        let total = 0;
        if (pnl?.length) {
            pnl.forEach((item) => {
                labels.push(new Date(item.Date).getDate());
                total += (Math.round(item.NormalizedPL * 100000) / 100000);
                values.push(total);
            });
        }
        setData({ labels, values });
    }, [trader]);

    let successRate = 0;
    // successRate = trader.WinRatio ? Math.round((trader.WinRatio + Number.EPSILON) * 100) : trader?.SuccessRate && Math.round((trader.SuccessRate + Number.EPSILON) * 100);
    successRate = trader.WinRatio ? FormatPercentFloat({ value: trader.WinRatio }) : trader?.SuccessRate && FormatPercentFloat({ value: trader.SuccessRate });
    const pnl = FormatPercentFloat({ value: trader?.CumulativePnl });
    const daysFromNow = formatDaysFromNow(new Date(trader?.JoinDateTime));


    return (
        <div className='top-three__item' onClick={openProfile} role='button' tabIndex={-1} aria-label='Open profile'>
            <div className='top-three__index'>{index + 1}</div>
            <div className='top-three__name'>
                {/* <div className={`avatar ${trader?.Avatar}`} onClick={openProfile} role='button' tabIndex={-1} aria-label='Open profile' /> */}
                <div className={`avatar ${trader?.Avatar.toLowerCase()}`}>
                    <div className='country__flag--small'>
                        {trader?.Country && CountryFlagByCode(trader?.Country)}
                    </div>
                </div>
                <div className='top-three__text'>
                    <strong onClick={openProfile} role='button' tabIndex={-1} aria-label='Open profile'>{trader?.Nickname}</strong>
                    <p>
                        <span>
                            {t('Socials.Copiers')}
                            :&nbsp;
                        </span>
                        {trader?.CopiersCount}
                    </p>
                    <p>
                        <span>
                            {t('Socials.Days.In.System')}
                            :&nbsp;
                        </span>
                        {daysFromNow}
                    </p>
                </div>
                <div className='top-three__copy'>
                    <Button
                        className='btn btn-stream-copy'
                        iconStart='far fa-copy'
                        onClickHandler={openProfileCopy}
                    />
                </div>
            </div>
            <div className='top-three__data'>
                <div
                    className='top-three__pnl'
                    style={{ color: pnl >= 0 ? 'orange' : 'purple' }}
                >
                    {pnl}
                </div>
                <div className='spotlight__chart'>
                    {(data && data.labels.length > 0) && (
                        <Graph
                            type='line'
                            data={{
                                labels: data?.labels,
                                datasets: [{
                                    values: data?.values
                                }]
                            }}
                            // height={150}
                            width={120}
                            height={80}
                            onSelect={false}
                            isNavigable={false}
                            lineOptions={{
                                hideDots: 1,
                                regionFill: 1,
                                areaFill: 1,
                                spline: 1
                            }}
                            resetPadding
                        />
                    )}
                    {/* <div className='top-three__pnl'>{pnl}</div> */}
                </div>
                <div className='top-three__success'>
                    <div className='top-three__success-icon'>
                        <StarIcon fill={positiveColor} />
                        {successRate}
                    </div>
                    {/* <LinearProgressBarWithLabel value={successRate / 10} hideLabel /> */}
                    <p>{t('Socials.SuccessRate')}</p>
                </div>
            </div>
        </div>
    );
};

// TopRankedTopItem.propTypes = {};

export default TopRankedTopItem;
