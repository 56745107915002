export function addOrUpdateState(state, override) {
  if (state === override) {
    return undefined;
  }
  else if (typeof override === 'object') {
    if (state === null || state === undefined) {
      return override;
    }
    if (typeof state !== 'object') {
      return override;
    }

    if (override === null || override === undefined)
    {
      return override;
    }
    
    let hasObjectChanged = false;
    let returnObject = state;
    let keys = Object.keys(override);
    for (let i = 0; i < keys.length; i++) {
      let overridedeepState = addOrUpdateState(state[keys[i]], override[keys[i]]);
      if (overridedeepState !== undefined) {
        if (!hasObjectChanged) {
          hasObjectChanged = true;
          if (Array.isArray(override)) {
            returnObject = Object.assign([], state);
          }
          else {
            returnObject = Object.assign({}, state);
          }
        }
        returnObject[keys[i]] = overridedeepState;
      }
    }
    return returnObject;
  }
  else {
    return override;
  }
}
