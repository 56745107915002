import React, {
    useState, useEffect, Fragment, useRef
} from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Column, Table, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css';
import Button from '../primitives/Button';

import OpenPositionsVirtualCloseRate from '../OpenPositions/OpenPositionsVirtualCloseRate';
import OrderUpdate from '../OpenPositions/OrderUpdate';
import { CommandFactory } from '../../background/commands/CommandFactory';
import {refreshStateWorkaround} from '../../background/refreshStateWorkaround';
import { normalizedEditNumber } from '../OpenPositions/updateNumericHelper';

const EditPositionMode = 1;
const DeletePositionMode = 2;
const PendingOrdersVirtualList = ({ isBottomPanelPinned, subHeaderColor, systemColor, linkColor, generalColor }) => {
    const { t } = useTranslation();
    const tableRef = useRef();
    const [commandError, setCommandError] = useState(null);
    const statePositions = useSelector((state) => state.account.positions.pending.positions, shallowEqual);
    const [editMode, setEditMode] = useState({
        rowId: null,
        mode: 0,
        editSLValue: null,
        editTPValue: null
    });

    const positionOrdered = [];
    for (const currPosKey in statePositions) {
        const currPos = statePositions[currPosKey];
        positionOrdered.push(currPos);
    }
    positionOrdered.sort((a,b) => b.openTimeS-a.openTimeS);

    const renderType = ({ cellData }) => {
        if (cellData === 0) return 'Buy Limit';
        if (cellData === 1) return 'Sell Limit';
        if (cellData === 2) return 'Buy Stop';
        if (cellData === 3) return 'Sell Stop';

        return '';
    };

    const removePendingCmd = async (e) => {
        e.preventDefault();

        const currState = statePositions[editMode.rowId];
        if (currState) {
            setCommandError(null);
            try {
                var refreshWorkaround = refreshStateWorkaround();
                const response = await CommandFactory.createCommand(currState.deletePedingCMD).execute();

                if (!response.isSuccessful) {
                    setCommandError(response.result ?? 'trading.error.GeneralError');
                } else {
                    refreshWorkaround.completed();
                    exitEditMode();
                }
            } catch (ex) {
                setCommandError('trading.error.GeneralError');
            }
        }
    };
    const saveEditData = async (e) => {
        e.preventDefault();

        const currState = statePositions[editMode.rowId];
        if (currState) {
            setCommandError(null);
            try {

                const response = await CommandFactory.createCommand(currState.editPendingCMD)
                    .execute({
                        takeProfit: normalizedEditNumber(editMode.editTPValue, currState.takeProfit),
                        stopLoss: normalizedEditNumber(editMode.editSLValue, currState.stopLoss)
                    });

                if (!response.isSuccessful) {
                    setCommandError(response.result ?? 'trading.error.GeneralError');
                } else {
                    exitEditMode();
                }
            } catch (ex) {
                setCommandError('trading.error.GeneralError');
            }
        }
    };

    const exitEditMode = () => {
        setEditMode({
            rowId: null, editSLValue: null, editTPValue: null, mode: 0
        });
        setCommandError(null);
    };

    const enterEditPosition = (id) => {
        const statePosition = statePositions[id];
        if (statePosition) {
            setEditMode({
                rowId: statePosition.id, mode: EditPositionMode, editSLValue: statePosition.stopLoss, editTPValue: statePosition.takeProfit
            });
        } else {
            exitEditMode();
        }
    };

    const enterDeletePosition = (id) => {
        const statePosition = statePositions[id];
        if (statePosition) {
            setEditMode({
                rowId: statePosition.id, mode: DeletePositionMode, editSLValue: null, editTPValue: null
            });
        } else {
            exitEditMode();
        }
    };

    const renderCloseRate = ({ rowData }) => {
        let actionType = 1;
        if (rowData.actionType === 0 || rowData.actionType === 2) actionType = 1;
        if (rowData.actionType === 1 || rowData.actionType === 3) actionType = 0;
        return <OpenPositionsVirtualCloseRate symbolName={rowData?.symbolName} actionType={actionType} />;
    };

    const renderStopLoss = ({ cellData, rowData, rowIndex }) => {
        if (rowData.id === editMode.rowId && editMode.mode === EditPositionMode) {
            return (
                <Fragment>
                    <OrderUpdate
                        symbolId={rowData?.symbolName}
                        orderId={rowData?.tpId}
                        selectedUnits={editMode.editSLValue}
                        setSelectedUnits={(value) => {
                            setEditMode({ ...editMode, editSLValue: value });
                        }}
                        shouldFocus={false}
                    />
                </Fragment>
            );
        }
        return <Fragment><button className='stop-loss__button outer-exclude' type='button' onClick={() => enterEditPosition(rowData.id)}>{rowData.stopLoss || '-'}</button></Fragment>;
    };

    const renderTakeProfit = ({ cellData, rowData, rowIndex }) => {
        if (rowData.id === editMode.rowId && editMode.mode === EditPositionMode) {
            return (
                <Fragment>
                    <OrderUpdate
                        symbolId={rowData?.symbolName}
                        orderId={rowData?.tpId}
                        selectedUnits={editMode.editTPValue}
                        setSelectedUnits={(value) => setEditMode({ ...editMode, editTPValue: value })}
                        shouldFocus={false}
                    />
                </Fragment>
            );
        }
        return <Fragment><button className='stop-loss__button outer-exclude' type='button' onClick={() => enterEditPosition(rowData.id)}>{rowData.takeProfit || '-'}</button></Fragment>;
    };

    const renderCloseButton = ({ rowData, rowIndex }) => {
        if (rowData.id === editMode.rowId && editMode.mode === DeletePositionMode) {
            return (
                <div className='order-update__container'>
                    <p style={generalColor}>{t('InstrumetInfo.Details.SureDelete')}</p>
                    <Button className='btn btn--general' onClickHandler={removePendingCmd} text={t('InstrumetInfo.Details.Delete')} />
                    <Button className='btn btn--general outer-exclude' onClickHandler={exitEditMode} text={t('General.Cancel')} />
                    {commandError && (
                        <div className='red error'>
                            {t(commandError)}
                        </div>
                    )}
                </div>
            );
        }

        return (
            <Button
                text={t('InstrumetInfo.Details.Delete')}
                onClickHandler={() => enterDeletePosition(rowData.id)}
                className='close-position outer-exclude'
                iconEnd='fa fa-times'
                style={linkColor}
            />
        );
    };

    const renderEditUpdate = ({ rowData }) => {
        if (rowData.id === editMode.rowId && editMode.mode === EditPositionMode) {
            return (
                <Fragment>
                    <div className='actions--expanded'>
                        <div style={{ display: 'flex' }}>
                            <Button className='btn btn--general' onClickHandler={saveEditData} text={t('InstrumetInfo.Details.Update')} />
                            <Button className='btn btn--general outer-exclude' onClickHandler={exitEditMode} text={t('General.Cancel')} />
                        </div>
                        {commandError && (
                            <div className='red error'>
                                {t(commandError)}
                            </div>
                        )}
                    </div>
                </Fragment>
            );
        }
        return <Fragment />;
    };

    const getRowHeight = ({ index }) => {
        const currRow = positionOrdered[index];
        if (currRow) {
            if (currRow.id == editMode.rowId) {
                return 100;
            }
        }
        return 35;
    };

    useEffect(
        () => {
            tableRef.current.recomputeRowHeights();
        },
        [editMode.rowId]
    );

    return (
        <Fragment>
            <div className='open-positions__scroller'>
                <div className='open-positions__wrapper' style={systemColor}>
                    <AutoSizer disableHeight={!isBottomPanelPinned}>
                        {({ height, width }) => (
                            <Table
                                width={width}
                                height={isBottomPanelPinned ? height : 225}
                                headerHeight={33}
                                headerStyle={subHeaderColor}
                                rowHeight={getRowHeight}
                                rowCount={positionOrdered.length}
                                rowGetter={({ index }) => positionOrdered[index]}
                                className='open-positions__container'
                                ref={tableRef}
                            >

                                <Column label={t('InstrumetInfo.Details.Instrument')} dataKey='symbolName' width={106} minWidth={106} className='' />
                                <Column label={t('InstrumetInfo.Details.Type')} dataKey='actionType' width={60} minWidth={60} cellRenderer={renderType} className='' />
                                <Column label={t('InstrumetInfo.Details.Amount')} dataKey='amount' width={80} minWidth={80} className='' />
                                <Column label={t('InstrumetInfo.Details.Price')} dataKey='openRate' width={100} minWidth={100} className='' />
                                <Column label={t('InstrumetInfo.Details.StopLoss')} dataKey='stopLoss' width={150} minWidth={150} cellRenderer={renderStopLoss} className='' />
                                <Column label={t('InstrumetInfo.Details.TakeProfit')} dataKey='takeProfit' width={150} minWidth={150} cellRenderer={renderTakeProfit} className='' />
                                <Column label={t('Pending.CurrentPrice')} dataKey='closeRate' width={80} minWidth={80} cellRenderer={renderCloseRate} className='' />
                                <Column width={200} minWidth={200} cellRenderer={renderCloseButton} dataKey='tpId' className='' />
                                <Column width={150} minWidth={150} cellRenderer={renderEditUpdate} dataKey='tpId' className='responsive-table__cell--actions' />
                            </Table>
                        )}
                    </AutoSizer>

                </div>
            </div>
        </Fragment>
    );
};

export default PendingOrdersVirtualList;
