export const UNITS_MULTIPLIER = [
	0.001,
	0.01,
	0.1,
	0.125,
	0.15,
	0.175,
	0.2,
	0.225,
	0.25,
	0.275,
	0.3,
	0.325,
	0.35,
	0.375,
	0.4,
	0.425,
	0.45,
	0.475,
	0.5,
	0.525,
	0.55,
	0.575,
	0.6,
	0.625,
	0.65,
	0.675,
	0.7,
	0.725,
	0.75,
	0.775,
	0.8,
	0.825,
	0.85,
	0.875,
	0.9,
	0.925,
	0.95,
	0.975,
	1,
	1.25,
	1.5,
	1.75,
	1.1,
	1.125,
	1.15,
	1.175,
	1.2,
	1.225,
	1.25,
	1.275,
	1.3,
	1.35,
	1.4,
	1.45,
	1.5,
	1.6,
	1.7,
	1.8,
	1.9,
	2,
	2.1,
	2.2,
	2.3,
	2.4,
	2.5,
	2.6,
	2.7,
	2.8,
	2.9,
	3,
	4,
	5,
	6,
	7,
	8,
	9,
	10,
	12,
	14,
	16,
	18,
	20,
	25,
	30,
	35,
	40,
	45,
	50,
	60,
	70,
	80,
	90,
	100,
	200,
	300,
	400,
	500,
	600,
	700,
	800,
	900,
	1000,
	2000,
	3000,
	4000,
	5000,
	6000,
	7000,
	8000,
	9000,
	10000,
	20000,
	30000,
	40000,
	50000,
	60000,
	70000,
	80000,
	90000,
	100000,
	1000000,
	10000000,
];
