import React, {
    useCallback, useEffect, useMemo, useState
} from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import MobileSectionModal from '../MobileModal/MobileSectionModal';
import MobileButton from '../Buttons/MobileButton';
import { CommandFactory } from '../../../background/commands/CommandFactory'
import MobileLoader from '../MobileLoader/MobileLoader';

const MobileLanguageSelector = ({ isOpen, onClose }) => {
    const { t } = useTranslation();

    const { languages, languageCode } = useSelector((state) => state.brand, shallowEqual);

    const [selectedCode, setSelectedCode] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const languagesToRender = useMemo(() => {
        if (!languages) return null;
        const languagesToRender = [];
        for (const key in languages) {
            languagesToRender.push(languages[key]);
        }
        return languagesToRender;
    }, [languages]);

    const getInitCode = useCallback(() => {
        setSelectedCode(languageCode);
    }, [languageCode, setSelectedCode]);

    const handleClose = useCallback(() => {
        onClose && onClose();
    }, [onClose]);

    const handleChange = useCallback((e, item) => {
        e && e.preventDefault();
        setSelectedCode(item.code);
    }, [setSelectedCode]);

    const handleSelect = useCallback(async () => {
        if (!languagesToRender || !selectedCode) return null;
        const { selectLanguageCommand } = languagesToRender.find(
            (el) => el.code === selectedCode
        );
        if (!selectLanguageCommand) return null;
        const command = CommandFactory.createCommand(selectLanguageCommand);
        setIsLoading(true);
        try {
            const res = await command.execute();
            if (!res) {
                console.error('Error change language'); // TODO: remove log add err
            }
            setIsLoading(false);
            handleClose();
        } catch (err) {
            setIsLoading(false);
            console.error(err); // TODO: remove log add err
        }
    }, [selectedCode, languagesToRender, setIsLoading, handleClose]);

    const isDisabled = useMemo(() => {
        if (!languageCode || !selectedCode) return true;
        return languageCode === selectedCode;
    }, [languageCode, selectedCode]);

    useEffect(() => {
        getInitCode();
    }, [getInitCode]);

    return (
        <MobileSectionModal
            title='Selector Language' // TODO: locale this
            isOpen={isOpen}
            onClose={handleClose}
            overlay
        >
            {isOpen && (
                <div className='mobile-language-selector'>
                    {languagesToRender && !!languagesToRender.length && (
                        <>
                            <MobileLoader isLoading={isLoading} />

                            <ul className='mobile-language-selector__list'>
                                {languagesToRender.map((item) => (
                                    <li
                                        key={item.code}
                                        onClick={(e) => handleChange(e, item)}
                                        className={clsx('mobile-language-selector__item',
                                            item.code === selectedCode && 'active')}
                                    >
                                        <div
                                            className='mobile-language-selector__item__inner'
                                        >
                                            {item.displayName}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            <div className='mobile-language-selector__button'>

                                <MobileButton
                                    disabled={isDisabled}
                                    onClick={handleSelect}
                                    title={t('General.Save')}
                                />
                            </div>
                        </>
                    )}
                </div>
            )}
        </MobileSectionModal>
    );
};

export default MobileLanguageSelector;
