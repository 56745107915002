import React from 'react';
import {CSSTransition} from 'react-transition-group';

const MobileTransition = props => {
  const {
    style,
    children,
    isOpen,
    type,
    timeout,
    appear,
  } = props;

  const timeouts = {
    'slide-up': 300,
    slide: 300,
    modal: 400,
    burger: 300,
    scale: 300,
    fade: 200,
    footer: 400,
    loader: 100,
    default: 1000,
    action: 300,
  };
  return (
      <CSSTransition
          in={!!isOpen}
          timeout={timeout || timeouts[type]}
          classNames={`transition-group-${type}`}
          unmountOnExit
          mountOnEnter
          appear={appear}
          style={{...style}}
      >
        <>
          {children}
        </>
      </CSSTransition>
  );
};

MobileTransition.defaultProps = {
  type: 'slide',
  timeout: null,
  appear: false,
};

export default MobileTransition;
