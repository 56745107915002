import React from 'react';

const MobilePopUpHeader = ({style, children}) => {
	return (
			<div className="mobile-popup__header" style={{...style}}>
				{children}
			</div>
	);
};

export default MobilePopUpHeader;
