/* eslint-disable complexity */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';

import TradingRoomPanel from '../../shared/Charts/TradingRoom';
// import Tabs from '../primitives/Tabs/Tabs';
import Button from '../primitives/Button';
import SocialsPanel from '../SocialsPanel/SocialsPanel';
import SignalsIcon from '../icons/SignalsIcon';
import SocialsIcon from '../icons/SocialsIcon';

import './RightPanel.css';


const RightPanel = ({
    selectedTrader,
    setSelectedTrader,
    openCopyTab,
    setOpenCopyTab,
    explorePage,
    toggleExplorePage,
    isSocialsEnabled,
    isTradingRoom,
    setReloadApp,
    isJoined,
    hasInit,
    joinPopup,
    setJoinPopup,
    setQuestionnaire
}) => {
    let initActiveTab = 0;
    if (isSocialsEnabled)
    {
        initActiveTab = 1;
    }
    else if (isTradingRoom)
    {
        initActiveTab = 2;
    }
    const [activeTab, setActiveTab] = useState(initActiveTab);
    const { t } = useTranslation();
    const { Text: { GeneralColor }, MainColor: { ActiveText, InactiveText }, Buttons: { TextColor } } = useSelector((state) => (state.brand.theme), shallowEqual);

    const socialStyle = activeTab === 1
        ? {
            className: 'btn btn-tab btn-tab--active',
            style: { color: ActiveText }
        }
        : {
            className: 'btn btn-tab',
            style: { color: InactiveText }
        };
    const tradeStyle = activeTab === 2
        ? {
            className: 'btn btn-tab btn-tab--active',
            style: { color: ActiveText }
        }
        : {
            className: 'btn btn-tab',
            style: { color: InactiveText }
        };


    return (
        <div className='right-panel__container'>
            {/* {tabsTorender.length > 0 && (
                <Tabs className='bottom-panel__tabs' hideName>
                    {tabsTorender}
                </Tabs>
            )} */}
            {(activeTab !== 0) && (
                <div className='tab-content'>
                    <div className='right-panel__tabs-container'>
                        {isSocialsEnabled && (
                            <Button
                                {...socialStyle}
                                text={activeTab === 1 ? t('Social.Title'): ''}
                                iconStartSVG={<SocialsIcon fill={activeTab === 1 ? ActiveText : InactiveText} />}
                                onClickHandler={()=>setActiveTab(1)}
                            />
                        )}
                        {isTradingRoom && <Button
                            {...tradeStyle}
                            text={activeTab === 2 ? t('Lev.Signals.Title') : ''}
                            iconStartSVG={<SignalsIcon fill={activeTab === 2 ? ActiveText : InactiveText} />}
                            onClickHandler={()=>setActiveTab(2)}
                        />}
                    </div>
                    {activeTab === 1 && (
                        <SocialsPanel
                            selectedTrader={selectedTrader}
                            setSelectedTrader={setSelectedTrader}
                            openCopyTab={openCopyTab}
                            setOpenCopyTab={setOpenCopyTab}
                            explorePage={explorePage}
                            toggleExplorePage={toggleExplorePage}
                            setReloadApp={setReloadApp}
                            isJoined={isJoined}
                            hasInit={hasInit}
                            joinPopup={joinPopup}
                            setJoinPopup={setJoinPopup}
                            setQuestionnaire={setQuestionnaire}
                            generalColor={{ color: GeneralColor }}
                            generalButtonColor={{ color: TextColor }}
                        />
                    )}
                    {activeTab === 2 && (
                        <div className='trading-room__container'>
                            <TradingRoomPanel />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

// RightPanel.propTypes = {};

export default RightPanel;
