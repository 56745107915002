import React, {useCallback, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import MobileCurrencySelect from '../../MobileAction/MobileCurrencySelect';

const MobileProfileCopyCurrency = ({userData, handleChange, copyData}) => {
	const {t} = useTranslation();
	const [currencySelect, setCurrencySelect] = useState(false);

	const wrapperRef = useRef();

	const handleOpen = useCallback(e => {
		e && e.preventDefault();
		if (currencySelect) return null;
		setCurrencySelect(true);
		if (wrapperRef && wrapperRef.current) {
			const scrollable = document.querySelector(
					'.mobile-profile-copy__main__scrollable');
			const rect = wrapperRef.current.getBoundingClientRect();
			const t = setTimeout(() => {
				scrollable && scrollable.scrollTo({
					top: rect.top,
					behavior: 'smooth',
				});
				clearTimeout(t);
			}, 100);
		}
	}, [currencySelect, wrapperRef]);

	const handleClose = useCallback(() => {
		setCurrencySelect(false);
	}, [setCurrencySelect]);

	const handleSetInstruments = useCallback(value => {
		handleChange && handleChange(value);
	}, [handleChange]);

	const renderAllInstrument = useMemo(() => {
		return (
				<p className="mobile-profile-copy__main__currency-list--inline__text">
							<span>
								{t('Social.CopyTab.SelectedInstrumentP1')}
								&nbsp;
							</span>
					<strong>{t('Social.CopyTab.SelectedInstrumentAll')}</strong>
					<span>
								&nbsp;
						{t('Social.CopyTab.SelectedInstrumentP2')}
							</span>
				</p>
		);
	}, []);

	const renderInstruments = useMemo(() => {
		const instruments = userData?.instruments;
		let text = instruments.join(', ');
		if (instruments && instruments.length >= 3) {
			text = `${instruments.slice(0, 3).join(', ')} ... (${instruments.length})`;
		}

		//TODO: locale this
		return (
				<p className="mobile-profile-copy__main__currency-list--inline__text">
					Copying {text}
				</p>
		);
	}, [userData, userData?.instruments]);

	const currencyList = useMemo(() => {
		const isInstruments = userData?.instruments?.length;
		return isInstruments ? renderInstruments : renderAllInstrument;
	}, [userData, renderAllInstrument]);

	return (
			<div className="mobile-profile-copy__main__currency-select">

				{/*<div className='mobile-profile-copy__main__currency-list--inline'>*/}
				{/*	<p className="mobile-profile-copy__main__currency-list--inline__text">*/}
				{/*			<span>*/}
				{/*				{t('Social.CopyTab.SelectedInstrumentP1')}*/}
				{/*				&nbsp;*/}
				{/*			</span>*/}
				{/*		{!userData?.instruments?.length && (*/}
				{/*				<strong>{t('Social.CopyTab.SelectedInstrumentAll')}</strong>*/}
				{/*		)}*/}
				{/*		<span>*/}
				{/*				&nbsp;*/}
				{/*			{t('Social.CopyTab.SelectedInstrumentP2')}*/}
				{/*			</span>*/}
				{/*	</p>*/}
				{/*	<div className="mobile-profile-copy__main__currency-list--button">*/}
				{/*		<MobileButton*/}
				{/*				title={t('Social.CopyTab.SelectSymbols')}*/}
				{/*				onClick={handleOpen}*/}
				{/*		/>*/}
				{/*	</div>*/}
				{/*</div>*/}

				{/*<ul className="mobile-profile-copy__main__currency-list__selected-list">*/}
				{/*	{!!userData?.instruments?.length &&*/}
				{/*	userData?.instruments.map(item => (*/}
				{/*			<li*/}
				{/*					key={item}*/}
				{/*					className="mobile-profile-copy__main__currency-list__selected-item"*/}
				{/*			>*/}
				{/*				{item}*/}
				{/*			</li>*/}
				{/*	))}*/}
				{/*</ul>*/}


				<div
						className="mobile-profile-copy__currency"
						ref={wrapperRef}
				>
					<div
							className="mobile-profile-copy__currency__wrapper"
							onClick={handleOpen}
					>
						<div className="mobile-profile-copy__currency__wrapper__title">
							{currencyList}
						</div>
						<ArrowDropDownIcon/>
					</div>
					{currencySelect && (
							<MobileCurrencySelect
									portfolio={copyData}
									handleClick={handleClose}
									instruments={userData.instruments}
									setInstruments={handleSetInstruments}
							/>
					)}

				</div>

			</div>
	);
};

export default MobileProfileCopyCurrency;
