import React, {useCallback} from 'react';
import MobileAutosizer from '../../MobileAutosizer/MobileAutosizer';
import MobileProfileCommunityItem from './MobileProfileCommunityItem';

const MobileProfileCommunityList = ({UserFriends, TraderFollowingDetails}) => {

	const renderRow = useCallback(data => {
		const {item, props} = data;
		return (
				<MobileProfileCommunityItem
						{...item}
						{...props}
				/>
		);
	}, [UserFriends, TraderFollowingDetails]);
	return (
			<div className="mobile-profile-community-list">
				<MobileAutosizer
						options={UserFriends}
						renderRow={renderRow}
						rowHeight={40}
				/>
			</div>
	);
};

export default MobileProfileCommunityList;
