import React from 'react';
// import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';


const ClosedPositionsCounter = () => {
    const { positions, isGetAllRequsted } = useSelector((state) => (state.account.positions.closed), shallowEqual);
    const { MainColor: { ActiveText } } = useSelector((state) => (state.brand.theme));
    const openPositionsQuantity = Object.keys(positions).length;

    if (!isGetAllRequsted) {
        return null;
    }

    return (
        <div className='quantity-mark large' style={{ backgroundColor: ActiveText }}>
            {openPositionsQuantity}
        </div>
    );
};

// ClosedPositionsCounter.propTypes = {};

export default ClosedPositionsCounter;
