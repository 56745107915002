import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {AutoSizer, List} from 'react-virtualized';
import clsx from 'clsx';
import useForceUpdate from '../../hook/useForceUpdate';
import MobileNothingFound from '../MobileEmpty/MobileNothingFound';

const MobileAutosizer = props => {
	const {
		minHeight,
		maxHeight,
		rowHeight,
		rowCount,
		overscanRowCount,
		options,
		tradeLinks,
		renderRow,
		className,
		closeTradeLinks,
		currentItem,
		scrollToIndex,
		type,
		positionType,
		reference = null,
		onScroll,
	} = props;

	const forceUpdate = useForceUpdate();

	const listRef = useRef(null);
	const sizerRef = useRef(null);

	const propsDep = useMemo(() => {
		return Object.keys(props).map(prop => props[prop]);
	}, [props]);

	const currentRef = useMemo(() => {
		return reference || listRef;
	}, [reference, listRef]);

	const currentRowHeight = useCallback(({index}) => {
		const minH = minHeight || rowHeight || 40;
		const maxH = maxHeight || rowHeight || 40;

		if (!options) return minH;
		const currRowId = options[index].id;
		if (currRowId === currentItem?.id) return maxH;
		return minH;
	}, [currentItem, options, rowHeight, minHeight, maxHeight]);

	const currentRowCount = useMemo(() => {
		return rowCount || options?.length;
	}, [rowCount, options]);

	const currentOversSanRowCount = useMemo(() => {
		return overscanRowCount || 10;
	}, [overscanRowCount]);

	const currentScrollToIndex = useMemo(() => {
		const dR = currentRowCount - scrollToIndex;
		// return dR >= 10 ? scrollToIndex + 10 : scrollToIndex;
		return scrollToIndex;
	}, [scrollToIndex, currentRowCount]);

	const handleRemoveItem = useCallback(index => {
		if (typeof index === 'undefined') return null;
		options.splice(index, 1);
		const ref = currentRef?.current;
		if (!ref) return null;
		ref.forceUpdateGrid();
		ref.forceUpdate();
	}, [currentRef]);

	const handleOnScroll = useCallback(scroll => {
		onScroll && onScroll(scroll);
	}, [onScroll]);

	const currentRenderRow = useCallback(({index, key, style}) => {
		const isActive = type === 'social'
				? currentItem?.TradeHash === options[index].TradeHash
				: currentItem?.id === options[index]?.id;

		const cls = () => {
			if (positionType === 'chart') return null;
			if (index < 0) {
				return 'odd';
			} else {
				return index % 2 === 0 ? 'even' : 'odd';
			}
		};

		const removeRow = () => {
			handleRemoveItem(index);
		};

		if (options && !options.length || options && !options[index]) return (
				<div key={`nothing_key_${index}_${Date.now()}`} style={style}/>
		);

		return (
				<div
						key={key}
						style={style}
						className={clsx('autosizer__item',
								scrollToIndex === index && 'active', cls(), className)}
				>
					{renderRow({
						item: options[index],
						props: {
							...props,
							index,
							isActive,
							removeRow,
						},
					})}
				</div>
		);
	}, [
		...propsDep,
		options,
		tradeLinks,
		closeTradeLinks,
		currentItem,
		handleRemoveItem,
		currentRef,
	]);

	useEffect(() => {
		if (currentRef && currentRef.current) {
			currentRef.current.recomputeRowHeights();
		}
	// }, [currentItem, currentRef, forceUpdate]);
	}, [currentItem, currentRef]);

	return (
			<AutoSizer
					ref={sizerRef}
					className='autosizer'
			>
				{props => (
						<List
								{...props}
								ref={currentRef}
								rowHeight={currentRowHeight}
								rowRenderer={currentRenderRow}
								rowCount={currentRowCount}
								scrollToRow={currentScrollToIndex || 0}
								scrollToIndex={currentScrollToIndex || -1}
								overscanRowCount={currentOversSanRowCount}
								onScroll={handleOnScroll}
								noRowsRenderer={() => <MobileNothingFound/>}
						/>
				)}
			</AutoSizer>
	);
};

export default MobileAutosizer;
