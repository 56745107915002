import React, {useCallback} from 'react';
import clsx from 'clsx';

import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Pagination, Virtual} from 'swiper';
import 'swiper/swiper-bundle.css';

import {getAvatarImage} from '../../helpers/options.helpers';
// import DefaultAvatar from '../../assets/avatar.svg';

SwiperCore.use([Virtual, Pagination]);

const MobileCarouselAvatar = ({options, value, onChange}) => {

	const handleChange = useCallback(swiper => {
		const index = swiper.activeIndex;
		const option = {
			index,
			value: options[index],
		};
		onChange && onChange(option);
	}, []);

	return (
			<div className={clsx('mobile-carousel-avatar',
					'gradient-carousel-avatar')}>
				<Swiper
						tag="div"
						wrapperTag="ul"
						slidesPerView={2}
						spaceBetween={0}
						centeredSlides={true}
						lazy={true}
						initialSlide={value}
						slideToClickedSlide={true}
						onSlideChange={handleChange}
						virtual
						pagination={{
							clickable: true,
							dynamicBullets: true,
						}}
				>
					{options.map((option, index) => (
							<SwiperSlide
									tag="li"
									key={`slide-${index}`}
									virtualIndex={index}
							>
								{({isActive}) => (
										<div className={clsx('mobile-carousel-avatar__wrap',
												isActive && 'active')}>
											<div className="mobile-carousel-avatar__wrap__avatar">
												<img src={getAvatarImage(index).icon.type} alt="avatar"/>
											</div>
										</div>
								)}
							</SwiperSlide>
					))}
				</Swiper>
			</div>
	);
};

export default MobileCarouselAvatar;
