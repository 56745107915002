import React, {
    useCallback, useMemo, useRef, useState
} from 'react';

import { shallowEqual, useSelector } from 'react-redux';
import useProfile from '../../context/contextHook/useProfile';

import MobilePopUp from '../../components/MobilePopUp/MobilePopUp';
import MobileProfileHeader from './MobileProfileHeader';
import useProfileUserData from '../../hook/useFetchData/useProfileUserData';
import MobileSwiper from '../../components/MobileSwiper';
import MobileProfileMain from './MobileProfileMain';
import MobileProfileCopy
    from '../../components/MobileProfile/MobileProfileCopy/MobileProfileCopy';
import useDetailedProfileUserData
    from '../../hook/useFetchData/useDetailedProfileUserData';
import MobileWatchModal from '../../components/MobileModal/MobileWatchModal';
import MobileLoader from '../../components/MobileLoader/MobileLoader';

const MobileProfile = () => {
    const {
        profileUser: { profileUserName, openProfileUser },
        copiedUser: { isCopied }
    } = useProfile();

    const currentUser = useSelector((state) => state.account.socialv1.userDetails, shallowEqual);

    const Nickname = useMemo(() => profileUserName, [profileUserName]);

    const { userProfile, isLoading, requestData } = useProfileUserData(Nickname);

    const { userDetailProfile } = useDetailedProfileUserData(Nickname);

    const { UserDetails, CopiersCount, SocialFeed } = useMemo(
        () => ({ ...userProfile }),
        [userProfile]
    );

    const isCurrentUserProfile = useMemo(() => {
        const pUser = UserDetails?.Nickname;
        const cUser = currentUser?.nickname;
        return pUser === cUser;
    }, [currentUser, UserDetails]);

    const [watchModalName, setWatchModalName] = useState(null);

    const handleOpenWatchModal = useCallback((name) => {
        setWatchModalName(name);
    }, [setWatchModalName]);

    const handleCloseWatchModal = useCallback(() => {
        setWatchModalName(null);
    }, [setWatchModalName]);

    const handleOpenProfile = useCallback(() => {
        handleCloseWatchModal && handleCloseWatchModal();
        openProfileUser && openProfileUser(watchModalName, true);
    }, [openProfileUser, watchModalName]);

    const touchRef = useRef(null);

    return (
        <MobilePopUp
            type='full'
            isOpen={!!Nickname}
        >
            <MobileSwiper
                touchRef={touchRef}
                type='profile'
                className='mobile-profile'
            >
                <>
                    <MobileProfileHeader
                        reference={touchRef}
                        avatar={UserDetails?.Avatar}
                        nickname={Nickname}
                        copiersCount={CopiersCount || 0}
                    />

                    <div className='mobile-profile__wrapper'>
                        {isLoading && (
                            <MobileLoader isLoading={isLoading} />
                        )}
                        {!isLoading && !!userProfile && (
                            <>
                                <MobileWatchModal
                                    isOpen={!!watchModalName}
                                    onClose={handleCloseWatchModal}
                                    onSuccess={handleOpenProfile}
                                    userName={watchModalName}
                                    position='center'
                                />
                                {!isCopied && (
                                    <MobileProfileMain
                                        UserDetails={UserDetails}
                                        CumulativePnl={userDetailProfile?.UserMeasures?.CumulativePnl}
                                        WinRatio={userDetailProfile?.UserMeasures?.WinRatio}
                                        yearPnl={userDetailProfile}
                                        SuccessRate={0}
                                        nickname={Nickname}
                                        SocialFeed={SocialFeed}
                                        userProfil={userProfile}
                                        handleOpenWatchModal={handleOpenWatchModal}
                                        isCurrentUserProfile={isCurrentUserProfile}
                                    />
                                )}
                                {isCopied && !isCurrentUserProfile && (
                                    <MobileProfileCopy
                                        yearPnl={userDetailProfile}
                                        requestData={requestData}
                                        profile={userProfile}
                                        nickname={Nickname}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </>
            </MobileSwiper>
        </MobilePopUp>
    );
};

export default MobileProfile;
