import React from 'react';
// import PropTypes from 'prop-types';

const PlusRounded = ({ fill }) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10' fill='none'>
            <circle cx='5' cy='5' r='4.5' fill='#13182C' stroke={fill} />
            <path d='M5 2V8' stroke={fill} />
            <path d='M2 5L8 5' stroke={fill} />
        </svg>
    );
};

// PlusRounded.propTypes = {};

export default PlusRounded;
