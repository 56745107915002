import React, { useState, useEffect, Fragment } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import PropTypes from 'prop-types';

import { CommandFactory } from '../../background/commands/CommandFactory';
import LoadSpinner from '../primitives/LoadSpinner';
import Button from '../primitives/Button';
import ProfilePageOpenPositions from './ProfilePageOpenPositions';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';

import { TIMEFRAMES } from './PortfolioTab';


const PositionsTab = ({ nickname, setSelectedTrader, generalColor }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [requestResult, setRequestResult] = useState(null);
    const [timeframe, setTimeframe] = useState(1);
    const [expanded, setExpanded] = React.useState('Open');
    const { profileCommands: { getPositions } } = useSelector((state) => { return (state.account.socialv1); }, shallowEqual);
    const { t } = useTranslation();
    const requestPositions = async (time) => {
        setIsLoading(true);
        const userInput = { nickname, timeframe: time };

        const command = CommandFactory.createCommand(getPositions);

        try {
            setRequestResult(null);
            const res = await command.execute(userInput);

            if (res.ok === false) {
                setRequestResult({ result: res.statusText });
            }
            if (res.isSuccessful === true) {
                setRequestResult(res.payload);
            } else if (res.isSuccessful === false) {
                setRequestResult(res);
            }
        } catch (err) {
            setRequestResult(err);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        requestPositions(1);
        SendEventToGoogleAnalytics("Profile:PositionsTabOpened");
    }, []);

    const setTimeframeValue = (e) => {
        const value = e.target.getAttribute('data-name');
        setTimeframe(parseInt(value, 10));
        requestPositions(parseInt(value, 10));
    };

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
        SendEventToGoogleAnalytics(`Profile:Positions:${panel}Click`);
    };

    return (
        <div className='positions-tab'>
            {/* <div className='positions-tab__type-buttons'>
                {RadioButtonsGroup}
            </div> */}
            {/* {requestResult && !isLoading && ( */}
            <Fragment>
                <Accordion square expanded={expanded === 'Open'} onChange={handleChange('Open')} TransitionProps={{ unmountOnExit: true }}>
                    <AccordionSummary aria-controls='panel1d-content' id='panel1d-header' expandIcon={<ExpandMoreIcon />}>
                        <p style={generalColor}>{t('Positions.Open.Title')}</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        {isLoading ? (
                            <Fragment>
                                {isLoading && <LoadSpinner />}
                            </Fragment>
                        ) : (
                            <ProfilePageOpenPositions openedTab={expanded} positions={requestResult?.ProfileOpenTrades} setSelectedTrader={setSelectedTrader} />
                        )}
                    </AccordionDetails>
                </Accordion>
                <Accordion square expanded={expanded === 'Pending'} onChange={handleChange('Pending')}>
                    <AccordionSummary aria-controls='panel2d-content' id='panel2d-header' expandIcon={<ExpandMoreIcon />}>
                        <p style={generalColor}>{t('Positions.Pending.Title')}</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        {isLoading ? (
                            <Fragment>
                                {isLoading && <LoadSpinner />}
                            </Fragment>
                        ) : (
                            <ProfilePageOpenPositions openedTab={expanded} positions={requestResult?.ProfilePendingTrades} setSelectedTrader={setSelectedTrader} pendingOrders />
                        )}
                    </AccordionDetails>
                </Accordion>
                <Accordion square expanded={expanded === 'Closed'} onChange={handleChange('Closed')}>
                    <AccordionSummary aria-controls='panel3d-content' id='panel3d-header' expandIcon={<ExpandMoreIcon />}>
                        <p style={generalColor}>{t('Positions.Closed.Title')}</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        {isLoading ? (
                            <Fragment>
                                {isLoading && <LoadSpinner />}
                            </Fragment>
                        ) : (
                            <ProfilePageOpenPositions openedTab={expanded} positions={requestResult?.ProfileClosedTrades} setSelectedTrader={setSelectedTrader} closedOrders />
                        )}
                    </AccordionDetails>
                </Accordion>
            </Fragment>
            {/* )} */}
            {/* {isLoading && <LoadSpinner />} */}
        </div>
    );
};

// PositionsTab.propTypes = {};

export default PositionsTab;
