import React from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import LinearProgress from '@material-ui/core/LinearProgress'

//import { JsonEditor as Editor } from 'jsoneditor-react';
//import 'jsoneditor-react/es/editor.min.css';

export default function CommandDialog(props) {
  const { setCmdDialog,cmd, value: valueProp, open,title, ...other } = props;

  const [userInput, setUserInput] = React.useState(cmd ? cmd.getUserInput():{});
  const [isLoading, setLoading] = React.useState(false);
  const [res, setRes] = React.useState(null);

  const handleEntering = () => {
  };

  const handleCancel = () => {
    setCmdDialog({open:false,cmd:null,title:""});
  };

  const handleOk = async  () => {
    setLoading(true);
    try
    {
      setRes(null);
      let res = await cmd.execute(userInput);
      if (res.isSuccessful === false)
      {
        setLoading(false);
        setRes(res);
      }
      else
      {
        setCmdDialog({open:false,cmd:null,title:""});
      }
    }
    catch (err)
    {
      console.log(err);
      setLoading(false);
      setRes(err);
    }
  };

  const onChanged = (v1,v2,v3) => {
    setUserInput(v1);
  };


  return (

    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth={true}
      maxWidth="md"
      scroll={'paper'}
      onEntering={handleEntering}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent dividers={true}>
        {/* {cmd && 
      <div>
        Static Data:
      <Editor statusBar={false} 
      search={false}
            value={ cmd.getStatic()}
            mode={"view"}
        />
        Store Data:
        <Editor statusBar={false}
            value={ cmd.getStoreData()}
            mode={"view"}
        />
        User Input:
        <Editor
            onChange={onChanged}
            value={ userInput}
        />
        {res &&
        <div>
          Error:
          <Editor mode={"view"} value={res}/>
        </div>}
        </div>
        } */}
      </DialogContent>
      <DialogActions>
      <LinearProgress />
        {isLoading && <LinearProgress style={{width:'100%'}} />}
        <Button autoFocus disabled={isLoading} onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleOk} disabled={isLoading} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}