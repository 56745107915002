import React, {useCallback} from 'react';
import {Sector} from 'recharts';

const MobilePieChartLabelActive = props => {
	const {
		cx,
		cy,
		midAngle,
		innerRadius,
		outerRadius,
		startAngle,
		endAngle,
		fill,
		payload,
		label,
		isDarkTheme,
	} = props;
	const RADIAN = Math.PI / 180;
	const sin = Math.sin(-RADIAN * midAngle);
	const cos = Math.cos(-RADIAN * midAngle);
	const sx = cx + (outerRadius + 10) * cos;
	const sy = cy + (outerRadius + 10) * sin;
	const mx = cx + (outerRadius + 20) * cos;
	const my = cy + (outerRadius + 20) * sin;
	const ex = mx + (cos >= 0 ? 1 : -1) * 22;
	const ey = my;
	const textAnchor = cos >= 0 ? 'start' : 'end';

	return (
			<g>
				<text
						className="chart-pie__label-title"
						x={cx}
						y={cy}
						dy={8}
						textAnchor="middle"
						fill="#fff"
				>
					{payload.name}
				</text>
				<Sector
						cx={cx}
						cy={cy}
						innerRadius={innerRadius}
						outerRadius={outerRadius}
						startAngle={startAngle}
						endAngle={endAngle}
						fill={fill}
				/>
				<Sector
						cx={cx}
						cy={cy}
						startAngle={startAngle}
						endAngle={endAngle}
						innerRadius={outerRadius + 6}
						outerRadius={outerRadius + 10}
						fill={fill}
				/>
				<path
						d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
						stroke={fill}
						fill="none"
				/>
				<circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
				<text
						className="chart-pie__label-title"
						x={ex + (cos >= 0 ? 1 : -1) * 12}
						y={ey}
						dy={4}
						textAnchor={textAnchor}
						fill={isDarkTheme ? '#fff' : '#283B55'}
				>
					{label}
				</text>
			</g>
	);
};

export default MobilePieChartLabelActive;
