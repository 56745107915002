/* eslint-disable no-restricted-syntax */
import React from 'react';
import { connect } from 'react-redux';
import { getGlobalChart } from './ChartPanel';

const SupportedLanguages = ['en', 'de', 'ru', 'ar', 'es'];

let singleton_element = null;
let widget = null;
class TradingRoomPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.trading4ProTradingRoomRef = React.createRef();
        this.trading4ProTradingRoomContainerRef = React.createRef();
    }

    componentDidMount() {
        if (!singleton_element)
        {
            const scriptElement = window.document.createElement('script');
            scriptElement.async = true;
            scriptElement.type = 'text/javascript';
            scriptElement.id = 't4p-widget';
            scriptElement.src = `https://trading4pro.com/chart/js/loader.min.js?t=${Date.now()}`;
            window.document.head.appendChild(scriptElement);

            this.trading4ProTradingRoomRef.current.addEventListener('widget', (event) => this.widgetHandler(event));
        }
        else{
            this.trading4ProTradingRoomContainerRef.current.appendChild(singleton_element);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.languageCode !== this.props.languageCode) {
            this.updateLang(nextProps.languageCode);
        }
        if (nextProps.activeTheme !== this.props.activeTheme)
        {
            this.updateTheme(nextProps.activeTheme);
        }
        return false;
    }

    widgetHandler(event) {
        if (!singleton_element)
        {
            singleton_element =this.trading4ProTradingRoomRef.current;
        }
        const local = this;
        widget = event.detail;
        local.widget = widget;
        local.updateTheme(local.props.activeTheme);        
        local.updateLang(local.props.languageCode);

        if (local.props.rightPanelSettings.t4proTradingroom.vipEnabled) {
            widget.addEventHandler('connect', function () {
                widget.logIn('signalsUserToken');
            });
        }

        widget.addEventHandler('apply', (id) => {
            const chart = getGlobalChart();
            if (chart) {
                widget.getIdea(id, (idea) => {
                    const directSymbol = local.props.symbols.symbols[idea.panel.symbol];
                    if (directSymbol) {
                        if (directSymbol.isVisible && !directSymbol.t4Pro && local.props.symbols.symbolsOrder.includes(directSymbol.id)) {
                            chart.applyIdea(idea);
                            return;
                        }
                    }

                    // this is slow but it happends rarely and only on user click.
                    for (const symbolId of local.props.symbols.symbolsOrder) {
                        const stateSymbol = local.props.symbols.symbols[symbolId];
                        if (stateSymbol) {
                            if (stateSymbol.t4Pro === idea.panel.symbol) {
                                if (stateSymbol.isVisible && local.props.symbols.symbolsOrder.includes(stateSymbol.id)) {
                                    let convertIdea = JSON.parse(JSON.stringify(idea).replaceAll('\"'+ idea.panel.symbol + '\"', '\"' + stateSymbol.id + '\"'))
                                    chart.applyIdea(convertIdea);
                                    return;
                                }
                            }
                        }
                    }

                    for (const symbolId of [".","!","$",'#']) {
                        const stateSymbol = local.props.symbols.symbols[idea.panel.symbol + symbolId];
                        if (stateSymbol) {
                            if (stateSymbol.isVisible && local.props.symbols.symbolsOrder.includes(stateSymbol.id)) {
                                let convertIdea = JSON.parse(JSON.stringify(idea).replaceAll('\"'+ idea.panel.symbol + '\"', '\"' + stateSymbol.id + '\"'))
                                chart.applyIdea(convertIdea);
                                return;
                            }
                        }
                    }
                });
            }
        });
    }

    updateTheme(theme)
    {
        if (this.widget) {
            if (theme == 'dark')
            {
                widget.setTheme('dark');
            }
            else{
                widget.setTheme('default');
            }
        }
    }
    updateLang(langCode) {
        if (this.widget) {
            if (langCode) {
                if (SupportedLanguages.includes(langCode)) {
                    this.widget.setLanguage(langCode);
                }
            }
        }
    }


    render() {
        if (singleton_element)
        {
            return (<div id='t4p-widget-ref-container' ref={this.trading4ProTradingRoomContainerRef} style={{ height: '100%' }}/>)
        }
        else
        {
            return (
                <div id='t4p-widget-ref-container' ref={this.trading4ProTradingRoomContainerRef} style={{ height: '100%' }}>
                    <div
                        id='t4p-widget-container'
                        data-widget='tr'
                        data-options={`{"l":{"langs":${JSON.stringify(SupportedLanguages)}},"ch":{"general.simpleForecast":false}}`}
                        style={{ height: '96%' }}
                        ref={this.trading4ProTradingRoomRef}
                    />
                </div>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        languageCode: state.brand?.languageCode,
        symbols: state.account?.symbols,
        rightPanelSettings: state.account?.accountInfo?.rightPanelSettings,
        activeTheme: state.brand.themeSettings.ActiveTheme
    };
}

export default connect(mapStateToProps)(TradingRoomPanel);
