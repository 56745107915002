import React from 'react';
import clsx from 'clsx';

const MobileSocialExploreStatisticBlock = ({
	                                           label,
	                                           number,
	                                           isActive,
	                                           className,
                                           }) => {
	return (
			<div
					className={clsx('mobile-social-statistic-block', isActive && 'active',
							className)}>
				<p className={clsx('mobile-social-statistic-block__number', className)}>
					{number || ''}
				</p>
				<p className="mobile-social-statistic-block__label">
					{label || ''}
				</p>
			</div>
	);
};

export default MobileSocialExploreStatisticBlock;
