import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';

import {StatusModes} from '../../background/reducers/generalStatusReducer';
import {CommandFactory} from '../../background/commands/CommandFactory';
import isMobile from 'is-mobile';
import LoginPage from '../LoginPage/LoginPage';
import LoadSpinner from '../primitives/LoadSpinner';
import MainLayout from '../ResizableLayout/MainLayout';
import MobileApp from '../../mobile/MobileApp';

const MainApp = () => {
	const [initResponseIsSuccessful, setInitResponseIsSuccessful] = useState(
			true);
	const [initError, setInitError] = useState({errCode: '', errText: ''});
	const {environments: {lastRemeberMeCMD}} = useSelector(
			({environments}) => ({environments}), shallowEqual);
	const {
		generalStatus: {
			status: stateStatus,
			ticksLoaded: ticksLoaded,
			serverInitCommand,
		},
	} = useSelector(({generalStatus}) => ({generalStatus}), shallowEqual);

	const waitForTicksOnStartup = useSelector(state => state.account.accountInfo.featuresData.waitForTicksOnStartup);
	const mobile = useSelector(state => state.brand.mobile);

	const shouldShowMobile = useMemo(() => {
		if (mobile.isEnabled) {
			return isMobile();
		}
		return false;
	}, [mobile]);

	useEffect(() => {
		const initApp = async () => {
			const command = CommandFactory.createCommand(serverInitCommand);

			try {
				const res = await command.execute({
					requestVer :3,
				});

				if (res.ok === false) {
					setInitError({errCode: res.status, errText: res.statusText});
					setInitResponseIsSuccessful(false);
				}
			} catch (err) {
				setInitError({errCode: err.name, errText: err.message});
				setInitResponseIsSuccessful(false);
			}
		};

		initApp();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!initResponseIsSuccessful) {
		return (
				<p>
					Oops&nbsp;
					{initError.errCode}
					&nbsp;
					{initError.errText}
				</p> // TODO: create error page component
		);
	}

	if (stateStatus == StatusModes.requireServerInit) {
		return <LoadSpinner overlay/>;
	}

	let ticksReady = true;
	if (waitForTicksOnStartup)
	{
		ticksReady = ticksLoaded;
	}

	if (stateStatus == StatusModes.accountLogin && !ticksReady)
	{
		return <LoadSpinner overlay/>;
	}

	if (stateStatus == StatusModes.accountLogin)
	{
		if (shouldShowMobile)
		{
			return (<Fragment><MobileApp/></Fragment>)
		}

		return (<Fragment><MainLayout lastRemeberMeCMD={lastRemeberMeCMD}/></Fragment>)
	}

	return <LoginPage/>;
};

export default MainApp;
