import React, {useCallback, useMemo} from 'react';
import {shallowEqual, useSelector} from 'react-redux';

import MobileButtonClose from '../../Buttons/MobileButtonClose';
import MobileInputStepper from '../../MobileInputStepper/MobileInputStepper';

const MobileUpdatePositionInput = props => {
	const {
		label,
		symbolId,
		tpId,
		types,
		onChange,
		selectedUnits,
		defaultValue,
		shouldClear,
		inputmode,
	} = props;

	const {symbols} = useSelector(state => state.account.symbols, shallowEqual);

	const currentSymbol = useMemo(() => {
		return symbols[symbolId];
	}, [symbols]);

	const pipNumber = useMemo(() => {
		let pipNumber = 1;
		for (let i = 0; i < currentSymbol?.displayDigit; i += 1) {
			pipNumber /= 10;
		}
		return pipNumber;
	}, [currentSymbol]);

	const handleValueChange = useCallback(value => {
		onChange && onChange({type: types, value});
	}, [onChange]);

	const handleClear = useCallback(e => {
		e && e.preventDefault();
		// onChange && onChange({type: types, value: defaultValue}); //TODO: reset
		onChange && onChange({type: types, value: ''}); //TODO: clear
	}, [defaultValue, selectedUnits, onChange]);

	return (
			<div className="mobile-input-update">
				<p className="mobile-input-update__label">
					{label}
				</p>
				<div className="mobile-input-update__wrapper">
					<MobileInputStepper
							id={`${types}-${tpId}`}
							shouldFocus={false}
							selectedUnits={selectedUnits}
							setValue={handleValueChange}
							stepValue={pipNumber}
							pipDigit={currentSymbol?.displayDigit}
							// pattern="\d*"
							pattern='^[ 0-9]+$'
							inputMode={inputmode}
					/>
					{/*TODO: reset*/}
					{/*{shouldClear && defaultValue !== selectedUnits && (*/}
					{/*TODO: clear*/}
					{shouldClear && selectedUnits && (
							<div className="mobile-input-update__btn">
								<MobileButtonClose onClick={handleClear}/>
							</div>
					)}
				</div>
			</div>
	);
};

MobileUpdatePositionInput.defaultProps = {
	defaultValue: '',
	shouldClear: false,
	inputmode: '',
};

export default MobileUpdatePositionInput;
