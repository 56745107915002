/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';

import { CommandFactory } from '../../background/commands/CommandFactory';
import LoadSpinner from '../primitives/LoadSpinner';

import ExplorePageUsersList from './ExplorePageUsersList';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';


const ExplorePageTopRanked = ({ location, handleItemSelect, limit, toggleOpenCopyTab, copyColor, positiveColor }) => {
    const { getTopRanked } = useSelector((state) => { return (state.account.socialv1.exploreCommands); }, shallowEqual);
    const [orderedTopTraders, setOrderedTopTraders] = useState([]);
    const [orderedTopThreeTraders, setOrderedTopThreeTraders] = useState([]);
    const [, setResponse] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleUserSelect = (data) => {
        handleItemSelect(data);
    };

    const getTopRankedUsers = async () => {

        const command = CommandFactory.createCommand(getTopRanked);
        setIsLoading(true);

        try {
            setResponse(null);
            const res = await command.execute({ limit});

            if (res.isSuccessful === true) {
                setOrderedTopThreeTraders(res.payload.slice(0, 3));
                setOrderedTopTraders(res.payload.slice(3, limit));
            } else if (res.isSuccessful === false) {
                setResponse(res);
            }
        } catch (err) {
            setResponse(err);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        getTopRankedUsers();
        SendEventToGoogleAnalytics('Explore:TopRankedTabOpened')
    }, []);


    return (
        isLoading ? (
            <LoadSpinner />
        ) : (
            <ExplorePageUsersList
                location={location}
                users={orderedTopTraders}
                topThreeUsers={orderedTopThreeTraders}
                handleItemSelect={handleUserSelect}
                toggleOpenCopyTab={toggleOpenCopyTab}
                copyColor={copyColor}
                positiveColor={positiveColor}
            />
        )
    );
};

// ExplorePageTopRanked.propTypes = {};

export default ExplorePageTopRanked;
