import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import './TopPanel.css';


const Logo = ({ }) => {
  const generalInfo = useSelector((state) => (state.brand.generalInfo), shallowEqual);
  const themeSettings = useSelector((state) => (state.brand.themeSettings));

  var logo = themeSettings.ActiveTheme === 'dark' ? themeSettings.DarkTheme.LogoIcon : themeSettings.LightTheme.LogoIcon;
  if (!logo) {
    logo = generalInfo.logoMain;
  }

  const onClickLogo = () => {
    if (generalInfo.logoMainUrl) {
      window.open(generalInfo.logoMainUrl);
    }
  };

  if(themeSettings.HideLogo || !logo){
    return null;
  }

  return (<div className='logo'>
    <img src={logo} alt='sirix' onClick={onClickLogo} />
  </div>);
};

export default Logo;
