import React, {useCallback} from 'react';

import useProfile from '../../../context/contextHook/useProfile';

import MobileAutosizer from '../../MobileAutosizer/MobileAutosizer';
import MobileSocialCopyItem from './MobileSocialCopyItem';
import useControls from '../../../context/contextHook/useControls';

const MobileSocialCopy = ({options}) => {

	const {profileUser: {openProfileUser}} = useProfile();
	const {footerControls: {handleClose}} = useControls();

	const handleOpen = useCallback(name => {
		if (!name || !openProfileUser) return null;
		openProfileUser && openProfileUser(name);
	}, [openProfileUser, handleClose]);

	const handleUncopy = useCallback(name => {
		if (!name || !openProfileUser) return null;
		openProfileUser && openProfileUser(name, true);
	}, [openProfileUser, handleClose]);

	const renderRow = useCallback(data => {
		const {item, props} = data;

		return (
				<MobileSocialCopyItem
						{...props}
						{...item}
				/>
		);
	}, []);
	return (
			<div className="mobile-social">
				<MobileAutosizer
						handleOpen={handleOpen}
						handleUncopy={handleUncopy}
						options={options}
						renderRow={renderRow}
						rowHeight={70}
						className="mobile-social-copy__item"
				/>
			</div>
	);
};

export default MobileSocialCopy;
