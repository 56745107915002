import React, {useCallback, useState} from 'react';

import MobileSocialExploreItem from './MobileSocialExploreItem';
import MobileNothingFound from '../../MobileEmpty/MobileNothingFound';

const MobileSocialExplore = ({options, handleOpenWatchModal}) => {
	const [editItemIndex, setEditItemItem] = useState(null);

	const handleItem = useCallback(index => {
		setEditItemItem(index);
	}, []);

	const isActiveItem = useCallback(index => {
		return index === editItemIndex;
	}, [editItemIndex]);

	return (
			<div className="mobile-social">
				{options && !options.length && (
						<MobileNothingFound/>
				)}

				{/*TODO: return/add autosizer*/}
				{options && options.length && (
						<div className="mobile-social__scrollable">
							{options.map((opt, index) => (
									<MobileSocialExploreItem
											{...opt}
											key={opt?.Nickname}
											index={index}
											isActive={isActiveItem(index)}
											handleOpen={handleItem}
											handleOpenWatchModal={handleOpenWatchModal}
									/>
							))}
						</div>
				)}
			</div>
	);
};

export default MobileSocialExplore;
