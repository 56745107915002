import React, {
    useCallback, useEffect, useMemo, useState
} from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import clsx from 'clsx';

import Context from './context/Context';
import { getMainMenu } from './helpers/options.helpers';
import { loaderDesktopInit, loaderMobileInit } from './helpers/loader.helpers';

import useScreenOrientation from './hook/useScreenOrientation';

import MobileHeader from './modules/MobileHeader/MobileHeader';
import MobileSection from './modules/MobileSection/MobileSection';
import MobileBurgerMenu from './modules/MobileBurgerMenu/MobileBurgerMenu';
import MobileProfile from './modules/MobileProfile/MobileProfile';
import MobileFooterOld from './modules/MobileFooter/MobileFooterOld';
import MobileMarginAlert from './modules/MobileMargin/MobileMarginAlert';

import MobileTradeAddButton from './components/Buttons/MobileTradeAddButton';
import MobileChangeAvatar from './components/MobileChangeAvatar';
import MobileCarouselMenu from './components/MobileCarousel/MobileCarouselMenu';
import CarouselTabList from './components/MobileCarousel/CarouselTabList';

import MobileClosePosition
    from './components/MobilePosition/MobileClosePosition/MobileClosePosition';
import MobileUpdatePosition
    from './components/MobilePosition/MobileUpdatePosition/MobileUpdatePosition';

import MobileTradingChartPage from './pages/MobileTradingChartPage';
import MobileTradingAllPage from './pages/MobileTradingAllPage';
import MobileSocialExplorePage from './pages/MobileSocialExplorePage';
import MobileSignalsPage from './pages/MobileSignalsPage';
import MobilePullToRefresh from './components/MobilePullToRefresh';

import './styles/index.css';

const MobileApp = () => {
    useScreenOrientation();

    const { ActiveTheme } = useSelector((state) => state.brand.themeSettings);

    const t4proTradingroom = useSelector((state) => state.account.accountInfo.rightPanelSettings.t4proTradingroom);
    const enableSignals = t4proTradingroom.isVisible && t4proTradingroom.vipEnabled;

    const {
        accountInfo: {
            rightPanelSettings: {
                socialv1: { isVisible }
            }
        },
        socialv1: { isEnabled }
    } = useSelector((state) => state.account);

    const [tabValue, setTabValue] = useState(0);

    const isSocialsEnabled = useMemo(() => {
        return isEnabled && isVisible;
    }, [isEnabled, isVisible]);

    const MAIN_MENU = useMemo(() => {
        return getMainMenu(isSocialsEnabled, enableSignals);
    }, [isSocialsEnabled]);

    const isLightTheme = useMemo(() => {
        return ActiveTheme === 'light';
    }, [ActiveTheme]);

    const handleSwitchTab = useCallback((option) => {
        if (!option) return null;
        setTabValue(option.index);
    }, []);

    const handleBackTab = useCallback(() => {
        setTabValue(0);
    }, []);

    useEffect(() => {
        loaderMobileInit();
        return () => {
            loaderDesktopInit();
        };
    }, [loaderMobileInit, loaderDesktopInit]);

    //= ===========================================================================
    // window.addEventListener('navigate', (e, data) => {
    // 	const direction = data.state.direction;
    // 	alert(`data, ${data}`);
    // 	console.log('e', e);
    // 	console.log('data', data);
    // 	console.log('direction', direction);
    // 	if (direction === 'back') {
    // 		console.log('direction back');
    // 	}
    // 	if (direction === 'forward') {
    // 		console.log('direction forward');
    // 	}
    // });
    //
    // window.onpopstate = function(event) {
    // 	alert(
    // 			`location: ${document.location}, state: ${JSON.stringify(
    // 					event)}`);
    // };
    //
    // window.addEventListener('popstate', e => {
    // 	console.log(e);
    // });
    //
    // const a = [0, 1, 2, 3, 4];
    //
    // a.map(el => {
    // 	const {history} = window;
    // 	setTimeout(() => {
    // 		// history.pushState({page: el}, `title ${el}`, `?page=${el}`);
    // 		history.pushState({page: el}, `title ${el}`);
    // 		window.dispatchEvent(new Event('popstate'));
    // 		// console.log('location: ${document.location}', document.location);
    // 	}, (el + 1) * 3000);
    // });
    //
    // const {history} = window;
    //
    // history.pushState({page: 1}, 'title 1', '?page=1');
    // history.pushState({page: 2}, 'title 2', '?page=2');
    // history.replaceState({page: 3}, 'title 3', '?page=3');
    // history.back(); // alerts "location: http://example.com/example.html?page=1, state: {"page":1}"
    // history.back(); // alerts "location: http://example.com/example.html, state: null"
    // history.go(2);  // alerts "location: http://example.com/example.html?page=3, state: {"page":3}"
    //
    // window.addEventListener('hashchange', function() {
    // 	console.log('Hash changed to', window.location.hash);
    // });
    //
    // document.addEventListener('backbutton', function(e) {
    // 	console.log('e', e);
    // 		if(menu is visible) {
    // 			//Hide the menu
    // 			//This is also working fine
    // 			return false;
    // 		}
    // 	else //nothing is visible, exit the app
    // 		{
    // 			navigator.app.exitApp();
    // 		}
    // });
    //
    // document.addEventListener('deviceready', onDeviceReady, false);
    //
    // function onDeviceReady() {
    // 	console.log('PhoneGap Ready!');
    //
    // 	document.addEventListener('backbutton', handleBackButton, false);
    // }
    //
    // function handleBackButton() {
    // 	console.log('Back Button Pressed!');
    // 	navigator.app.exitApp();
    // }
    //
    // console.log('window.location', window.location);
    //= ===========================================================================

    return (
        <MobilePullToRefresh>
            <div id='mobile' className='mobile'>
                <Context>
                    <div className={clsx('mobile-app', isLightTheme && 'light')}>

                        <MobileBurgerMenu />
                        <MobileHeader />

                        <MobileSection>
                            <MobileCarouselMenu
                                options={MAIN_MENU}
                                onChange={handleSwitchTab}
                                value={tabValue}
                            />

                            <CarouselTabList
                                index={tabValue}
                                classname='hT50'
                            >
                                <MobileTradingChartPage />
                                <MobileTradingAllPage handleBackTab={handleBackTab} />
                                <MobileSocialExplorePage isVisible={isSocialsEnabled} />
                                <MobileSignalsPage />
                                {/* <MobileSocialWatchPage isVisible={isSocialsEnabled}/> */}
                            </CarouselTabList>

                        </MobileSection>

                        {/* <MobileFooter/> */}
                        <MobileFooterOld />

                        <MobileTradeAddButton />

                        <MobileChangeAvatar />

                        <MobileProfile />

                        <MobileClosePosition />

                        <MobileUpdatePosition />

                        <MobileMarginAlert />
                    </div>
                </Context>
            </div>
        </MobilePullToRefresh>
    );
};

export default MobileApp;
