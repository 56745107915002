import produce from "immer"
import Cookies from "js-cookie";

const  initState = {
  environments:{},
  environmentsDisplayOrder:[],
  lastRemeberMeCMD:null,
};

export const environmentsReducer = function environmentsReducer(state = initState, action,globalState){
  switch (action.type) {
    case "login-logout-rememberme":
      {
        updateLocalStorageLogout({userId:action.staticData.userId,envId:action.staticData.envId})
        return produce(state,draf=>{
          updateEnvirmentFromLocalStorage(draf);
        });
      }
    case "login-logout":
    {
      Cookies.remove('superweb-session-id',{ domain: `${window.location.host}` } );
      Cookies.remove('superweb-session-id');
      window.sessionStorage.removeItem('superweb-session');
      window.localStorage.removeItem('superweb-rememberme-latest');

      updateLocalStorageLogout({userId:action.staticData.userId,envId:action.staticData.envId})
        return produce(state,draf=>{
          updateEnvirmentFromLocalStorage(draf);
        });
    }
    case "init-brand":
      {
      let o = Object.assign({},state);
      let environments = action.payload.environments ?? [];
      let sortedEnvironments = environments.environments.sort(compareDisplayOrder);
      o.environments = {};
      o.environmentsDisplayOrder = [];

      for(let env of sortedEnvironments)
      {
        o.environments[env.id] = {
         id:  env.id,
         displayName: env.displayName,
         translatedName: env.translatedName,
         loginWithTPId: env.loginWithTPId,
         prevLogins:[]
        }

        o.environmentsDisplayOrder.push(env.id);
      }

      updateEnvirmentFromLocalStorage(o);
      return o;
      }
    case "init-brand+login":
      {
      let o = Object.assign({},state);
      let environments = action.payload.environments ?? [];
      let sortedEnvironments = environments.environments.sort(compareDisplayOrder);
      o.environments = {};
      o.environmentsDisplayOrder = [];

      for(let env of sortedEnvironments)
      {
        o.environments[env.id] = {
         id: env.id,
         displayName: env.displayName,
         translatedName: env.translatedName,
         loginWithTPId: env.loginWithTPId,
         prevLogins:[]
        }

        o.environmentsDisplayOrder.push(env.id);
      }

      updateLocalStorageWithLogin({
        rememberMeToken:action.payload.loginTradingData.generalData.rememberMe,
        userId:action.payload.loginTradingData.generalData.userId,
        tpId:action.payload.loginTradingData.generalData.tpUserId,
        userName:action.payload.loginTradingData.generalData.userDetails.name,
        envId:action.payload.loginTradingData.generalData.envId,
        avatarColor:action.payload.loginTradingData.generalData.avatarColor});

      updateEnvirmentFromLocalStorage(o);

      return o;
    }
    case "login-success":
      {
      updateLocalStorageWithLogin({
        rememberMeToken:action.payload.generalData.rememberMe,
        userId:action.payload.generalData.userId,
        tpId:action.payload.generalData.tpUserId,
        userName:action.payload.generalData.userDetails.name,
        envId:action.payload.generalData.envId,
        avatarColor:action.payload.generalData.avatarColor});

        return produce(state,draf=>{
          updateEnvirmentFromLocalStorage(draf);
        });
      }
      default:
        return state;
  }
}

function compareDisplayOrder( a, b ) {
  if(a.displayOrder && b.displayOrder){
    return a.displayOrder - b.displayOrder;
  }
  return 0;
}


function updateLocalStorageLogout(loginData)
{
  let {userId,envId} = loginData;
  let lastRememberMe = window.localStorage.getItem('superweb-rememberme-latest');
  if (lastRememberMe)
  {
    try
    {
       lastRememberMe = JSON.parse(lastRememberMe)
      if (lastRememberMe.userId === userId)
      {
        window.localStorage.removeItem('superweb-rememberme-latest');
      }
    }
    catch(ex)
    {
      console.log(ex);
      window.localStorage.removeItem('superweb-rememberme-latest');
    }
  }

  let loginLocalStorage = window.localStorage.getItem('superweb-env-logins');

  if (loginLocalStorage)
  {
    try{
      loginLocalStorage = JSON.parse(loginLocalStorage);
      let env =  loginLocalStorage.environments[envId]
      let userLogin = env[userId];
      if (userLogin)
      {
        userLogin.rememberMe = null;
        window.localStorage.setItem('superweb-env-logins',JSON.stringify(loginLocalStorage));
      }
    }
    catch(ex)
    {
      console.log(ex);
    }
  }
}

function updateLocalStorageWithLogin(loginData)
{
  let {rememberMeToken,userId,tpId,userName,envId,avatarColor} = loginData;
  let loginLocalStorage = window.localStorage.getItem('superweb-env-logins');
  if (!loginLocalStorage)
  {
    loginLocalStorage = {environments:{}}
  }
  else
  {
    try
    {
      loginLocalStorage = JSON.parse(loginLocalStorage);
    }
    catch (ex){
      loginLocalStorage = {};
      console.log(ex);
    }
  }

  let env = loginLocalStorage.environments[envId];
  if (!env)
  {
    env = {
    };
    loginLocalStorage.environments[envId] = env;
  }

  let login = env[userId];
  if (!login)
  {
    login = {
      loginCounter:0
    };
    env[userId] = login;
  }

  login.loginCounter = login.loginCounter + 1;
  login.lastLogin = Date.now();
  login.userId = userId;
  login.tpId = tpId;
  login.userName = userName;
  login.envId = envId;
  login.rememberMe = rememberMeToken ? rememberMeToken : login.rememberMe;
  login.avatarColor = avatarColor;

  window.localStorage.setItem('superweb-env-logins',JSON.stringify(loginLocalStorage));

  if (rememberMeToken)
  {
    window.localStorage.setItem('superweb-rememberme-latest', rememberMeToken);
  }
}

function updateEnvirmentFromLocalStorage(state)
{
  let loginLocalStorage = window.localStorage.getItem('superweb-env-logins');

  if (loginLocalStorage)
  {
    try
    {
      loginLocalStorage = JSON.parse(loginLocalStorage);
    }
    catch (ex){
      loginLocalStorage = null;
      console.log(ex);
    }
  }

  if (loginLocalStorage && loginLocalStorage.environments)
  {
    for(let storageEnvKey in loginLocalStorage.environments)
    {
      let storageEnv = loginLocalStorage.environments[storageEnvKey];
      let stateEnv = state.environments[storageEnvKey];
      if (stateEnv){
        for(let userId in storageEnv)
        {
          var storageLogin = storageEnv[userId];
          if (storageLogin && storageLogin.envId === storageEnvKey && storageLogin.tpId && storageLogin.userId)
          {
            let statePrevLogin = stateEnv.prevLogins.find(element=>element.userId === userId);
            if (!statePrevLogin)
            {
              statePrevLogin = {
                passwordRequiredCommand: null,
                remembermeLoginCommand: null
              };
              stateEnv.prevLogins.push(statePrevLogin);
            }

            statePrevLogin.userName = storageLogin.userName;
            statePrevLogin.userId = storageLogin.userId;
            statePrevLogin.tpId = storageLogin.tpId;
            statePrevLogin.envId = storageLogin.envId;
            statePrevLogin.avatarColor = storageLogin.avatarColor;
            statePrevLogin.logoutCommand = null;

            if (!storageLogin.rememberMe)
            {
              statePrevLogin.remembermeLoginCommand = null;
            }
            else
            {
              if (!statePrevLogin.remembermeLoginCommand)
              {
                statePrevLogin.remembermeLoginCommand = { cmdId: 'STD.CMD.Server.Auth',
                                                          staticParams: { postUrl: 'api/auth/loginRememberMe' },
                                                          userInput: { encryptedKey: null },
                                                          storeRef : { urlParameters:["urlParameters"], isIframe:["generalStatus","isIframe"] } }
              }
              statePrevLogin.remembermeLoginCommand.userInput.encryptedKey = storageLogin.rememberMe;

              statePrevLogin.logoutCommand = { cmdId: 'STD.CMD.AsyncServer',
                                                staticParams: { postUrl: 'api/userActions/logout',
                                                                type: "login-logout-rememberme",
                                                                envId:storageLogin.envId,
                                                                userId:storageLogin.userId
                                                              },
                                                userInput: {  } }
            }

            statePrevLogin.passwordRequiredCommand = { cmdId: 'STD.CMD.Server.Auth',
                                                       staticParams: { postUrl: 'api/auth/loginByTpId', envId: storageLogin.envId },
                                                        userInput: { tpNumber: storageLogin.tpId, tpPassword: "", rememberMe: false,requestVer:3 },
                                                        storeRef : { urlParameters:["urlParameters"], isIframe:["generalStatus","isIframe"] } }
          }
        }
      }
    }
  }
}
