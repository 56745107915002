import React from 'react';
import { useTranslation } from 'react-i18next';

import { roundNumberPipDigit } from '../utilsUI/formatWSTick';
import formatDaysFromNow from '../utilsUI/formatDaysFromNow';

export const FormatPercentFloat = ({ value }) => {
    const isNegative = value < 0;
    const pnlClass = isNegative ? 'red' : 'green';
    if (isNegative) {
        value = Math.abs(value);
    }
    return (
        <strong className={pnlClass}>
            {isNegative && '-'}
            {roundNumberPipDigit(value * 100, 2)}
            %
        </strong>
    );
};


const ProfileTabDetails = ({ detailedData, joinDateValue }) => {
    const { t } = useTranslation();
    const {
        AverageCopySlippage: copySlippage,
        AvgDailyReturn: dailyPL,
        AvgMonthlyReturn: monthlyPL,
        CopySlippageRank: copySlippageRank,
        CumulativePnl: cumulativePL,
        MaxDrawDown: maxDrawDown,
        MaxSingleTradeLoss: maxTradeLoss,
        MaxSingleTradeProfit: maxTradeProfit,
        WinRatio: winRatio
    } = detailedData;

    return (
        <div className='profile-tab__details-block'>
            <div className='details__item'>
                <div className='details__label'>
                    {t('Profile.Days')}
                </div>
                <div className='details__data'>
                    <strong>{joinDateValue && formatDaysFromNow(joinDateValue)}</strong>
                </div>
            </div>
            <div className='details__item'>
                <div className='details__label'>
                    {t('Profile.Success.Rate')}
                </div>
                <div className='details__data'>
                    <FormatPercentFloat value={winRatio} />
                </div>
            </div>
            <div className='details__item'>
                <div className='details__label'>
                    {t('Profile.Average.Daily')}
                </div>
                <div className='details__data'>
                    <FormatPercentFloat value={dailyPL} />
                </div>
            </div>
            <div className='details__item'>
                <div className='details__label'>
                    {t('Profile.Max.Drawdown')}
                </div>
                <div className='details__data'>
                    <FormatPercentFloat value={maxDrawDown} />
                </div>
            </div>
            <div className='details__item'>
                <div className='details__label'>
                    {t('Profile.Best.Position')}
                </div>
                <div className='details__data'>
                    <FormatPercentFloat value={maxTradeProfit} />
                </div>
            </div>
            <div className='details__item'>
                <div className='details__label'>
                    {t('Profile.Worst.Position')}
                </div>
                <div className='details__data'>
                    <FormatPercentFloat value={maxTradeLoss} />
                </div>
            </div>
        </div>
    );
};

export default ProfileTabDetails;