import React from 'react';
// import PropTypes from 'prop-types';
import ReactResizePanel from '../primitives/ReactResizePanel/ReactResizePanel';

import './ResizePanelWrapper.css';


const ResizePanelWrapper = ({
    handleClick,
    // style,
    direction,
    handleClass,
    borderClass,
    children,
    isClosed,
    initialWidth,
    initialHeight,
    id,
    handleResize,
    openPin
}) => {
    const chevronLeft = <i className='fas fa-chevron-left' />;
    const chevronRight = <i className='fas fa-chevron-right' />;
    const chevronUp = <i className='fas fa-chevron-up' />;
    const chevronDown = <i className='fas fa-chevron-down' />;


    return (
        // <div style={{ width: `${initialWidth}px` }}>
        <ReactResizePanel
            direction={direction}
            // style={{ width: `${initialWidth}px`, height: initialHeight }}
            style={{ width: initialWidth, height: initialHeight }}
            containerClass={isClosed && 'closed'}
            borderClass={borderClass}
            handleClass={handleClass}
            handleResize={handleResize}
            id={id}
            openPin={openPin}
        >
            {/* <div className='sidebar sidebar-left panel' id={id}> */}
            <div className='sidebar panel' id={id}>
                {direction === 'e' && (
                    <div className='toggle-button east' onClick={handleClick}>
                        {isClosed ? chevronRight : chevronLeft}
                    </div>
                )}
                {children}
                {direction === 'w' && (
                    <div className='toggle-button west' onClick={handleClick}>
                        {isClosed ? chevronLeft : chevronRight}
                    </div>
                )}
                {direction === 'n' && (
                    <div className='toggle-button north' onClick={handleClick}>
                        {isClosed ? chevronUp : chevronDown}
                    </div>
                )}
            </div>
        </ReactResizePanel>
        // </div>
    );
};

// ResizePanel.propTypes = {};

export default ResizePanelWrapper;
