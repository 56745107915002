
let _store = null;

export const setStore = function(store)
{
  _store = store;
}

export const getStore = function(store)
{
  return _store;
}

