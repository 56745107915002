/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {useEffect} from 'react';// import PropTypes from 'prop-types';


import '../OpenPositions/OpenPositions.css';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';

import PendingOrdersVirtualList from './PendingOrdersVirtualList';


const PendingOrders = ({ isBottomPanelPinned, subHeaderColor, systemColor, linkColor, generalColor }) => {
  useEffect(() => {
    SendEventToGoogleAnalytics("Drawer:PendingOrdersTabOpened");
}, []);
    return (
        <PendingOrdersVirtualList
            isBottomPanelPinned={isBottomPanelPinned}
            subHeaderColor={subHeaderColor}
            systemColor={systemColor}
            linkColor={linkColor}
            generalColor={generalColor}
        />
    );
};

// PendingOrders.propTypes = {};

export default PendingOrders;
