import React, {useCallback, useMemo} from 'react';
import useControls from '../../context/contextHook/useControls';
import MobileTransition from '../MobileTransition';
import usePosition from '../../context/contextHook/usePosition';
import useProfile from '../../context/contextHook/useProfile';

const MobileTradeAddButton = () => {
	const {tradeControls: {handleOpen, isVisible}} = useControls();
	const {positionUpdate: {updateData}} = usePosition();
	const {positionClose: {closeData}} = usePosition();
	const {profileUser: {profileUserName}} = useProfile();

	const handleButtonClick = useCallback(e => {
		e.preventDefault();
		handleOpen && handleOpen();
	}, [handleOpen]);

	const isVisibleButton = useMemo(() => {
		return isVisible && !updateData && !profileUserName && !closeData;
	}, [isVisible, updateData, profileUserName, closeData]);

	return (
			<MobileTransition
					isOpen={isVisibleButton}
					type="fade">
				<button
						className="mobile-button__trade-add"
						onClick={handleButtonClick}
				>
					<div
							className="mobile-button__trade-add__inner"
					>
						<div className="mobile-button__trade-add__inner__line"/>
						<div className="mobile-button__trade-add__inner__line"/>
					</div>
				</button>
			</MobileTransition>

	);
};

export default MobileTradeAddButton;
