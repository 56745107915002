import React, { useLayoutEffect, useState } from 'react';

const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    // if (size >= 1200 && size < 1366) {
    //     return 'midDesktop';
    // }

    // if (size > 1023 && size < 1200) {
    //     return 'smallDesktop';
    // }
    return size;
};

export default useWindowSize;
