import React from 'react';

import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {allTradesStream,topRankedTradeStream,watchTradersStream} from '../background/socialv1/tradesStreams'
import { useTranslation } from 'react-i18next';

import './socialStreamDialog.css';

export default ({setCmdDialog, open,...other}) => {
    const { t } = useTranslation();
    let [state,setState] = React.useState({
      streamSelection:"ALL",
    });

    const handleChange = (event) =>{
      setState({streamSelection: event.target.value});
    }    
    const handleCancel = () => {
      setCmdDialog({open:false});
    };

    let listItems =[] ;
    let streamToUse = allTradesStream;
    if (state.streamSelection === "ALL")
    {
      streamToUse = allTradesStream;
    }
    else if (state.streamSelection === "IWATCH")
    {
      streamToUse = watchTradersStream;
    }
    else if (state.streamSelection === "TOPTRADERS")
    {
      streamToUse = topRankedTradeStream;
    }
    
    for(let item of streamToUse.getStackOrder())
    {
      listItems.push(<div key={item.TradeHash}>{`${item.Nickname} ${item.InstrumentName} ${item.OpenPrice} ${item.ClosePrice} ${item.TradeActionType ===0?"BUY":"SELL"}`}</div>)
    }

    streamToUse.subscribeReact(()=>{
      setState({rerender:Date.now(),
                streamSelection:state.streamSelection
              });
    });

    return (<Dialog
      fullWidth={true}
      onClose={handleCancel}
      maxWidth="lg"
      scroll={'paper'}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}>
      <DialogTitle id="confirmation-dialog-title">{"Social Stream" ?? ""}</DialogTitle>
      <DialogContent dividers={true}>
      <select value={state.streamSelection} onChange={handleChange}>
            <option value={"ALL"}>{t('Social.Stream.Filter.ALL')}</option>
            <option value={"IWATCH"}>{t('Social.Stream.Filter.IWatch')}</option>
            <option value={"TOPTRADERS"}>{t('Social.Stream.Filter.TopPerformers')}</option>
          </select>
        <div>
          {(open) && 
          <div style={{height:600}}>
            <ReactCSSTransitionGroup
          transitionName="fade"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}>
            {listItems}
          </ReactCSSTransitionGroup>
          </div>}
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>);
};

