import React from 'react';
// import PropTypes from 'prop-types';

import pubsubSubscription from '../../background/repositories/PubsubSubscription';
import FormatProfitSwapCommision from '../utilsUI/FormatProfitSwapCommision';


const OpenPositionsVirtualProfit = ({ cellData, positive, negative, neutral }) => {
    const profitValueSubscription = pubsubSubscription.subscribeObj({ key: cellData?.key, repId: 'PNL' });
    const profitValue = profitValueSubscription?.profit;

    return (
        <FormatProfitSwapCommision tickValue={profitValue} positive={positive} negative={negative} neutral={neutral} />
    );
};

// OpenPositionsVirtualProfit.propTypes = {};

export default OpenPositionsVirtualProfit;
