import React, {
    useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { CommandFactory } from '../../../../background/commands/CommandFactory';
import usePosition from '../../../context/contextHook/usePosition';
import { normalizedEditNumber } from '../../../../UI/OpenPositions/updateNumericHelper';
import MobileModal from '../../MobileModal/MobileModal';
import MobileButton from '../../Buttons/MobileButton';
import MobileSwiper from '../../MobileSwiper';
import MobileLoader from '../../MobileLoader/MobileLoader';
import MobilePopUp from '../../MobilePopUp/MobilePopUp';

import MobileUpdatePositionHeader from './MobileUpdatePositionHeader';
import MobileUpdatePositionMain from './MobileUpdatePositionMain';


const MobileUpdatePosition = () => {
    const touchRef = useRef(null);

    const { positionUpdate: { updateData, handleUpdateHide } } = usePosition();

    const { symbols } = useSelector((state) => state.account.symbols, shallowEqual);
    const { unlinkTradeCommand } = useSelector(
        (state) => state.account.socialv1.copyRelationsCommands, shallowEqual
    );

    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [unlinkId, setUnlinkId] = useState(false);
    const [responseErrorData, setResponseErrorData] = useState(null);
    const [responseSuccessData, setResponseSuccessData] = useState(null);
    const [isUpdate, setIsUpdate] = useState(true);

    const handleClose = useCallback(() => {
        handleUpdateHide && handleUpdateHide();
    }, [handleUpdateHide]);

    const handleCloseOrder = useCallback(async (amount) => {
        const closePositionCMD = updateData?.closePositionCMD;
        if (!amount || !closePositionCMD) return null;

        const userInput = { amount };

        const command = CommandFactory.createCommand(closePositionCMD);
        setIsLoading(true);
        try {
            const res = await command.execute(userInput);
            if (!res.isSuccessful) {
                const err = t(res?.result || 'trading.error.GeneralError');
                setResponseErrorData(err);
            }
            if (res.isSuccessful) {
                setResponseSuccessData(
                    { type: 'close', profitValue: res.profit, price: res.price }
                );
            }

            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            const e = t('trading.error.GeneralError');
            setResponseErrorData(e);
        }
    }, [updateData, setResponseErrorData, setResponseSuccessData]);

    const handleUpdateOrder = useCallback(async (data) => {
        const timeToShowBtn = 20000;
        const updatePositionCMD = updateData?.updatePositionCMD;
        if (!data || !updatePositionCMD) return null;

        const userInput = {
            takeProfit: normalizedEditNumber(data.takeProfit, updateData.takeProfit),
            stopLoss: normalizedEditNumber(data.stopLoss, updateData.stopLoss)
        };

        const command = CommandFactory.createCommand(updatePositionCMD);
        setIsLoading(true);
        try {
            const res = await command.execute(userInput);

            if (!res.isSuccessful) {
                const err = t(res?.result || 'trading.error.GeneralError');
                setResponseErrorData(err);
            }

            if (res.isSuccessful) {
                setResponseSuccessData(
                    { type: 'update', profitValue: res.profit, price: res.price }
                );
            }
            setIsUpdate(false);
            const t = setTimeout(() => {
                setIsUpdate(true);
                clearTimeout(t);
            }, timeToShowBtn);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            const e = t('trading.error.GeneralError');
            setResponseErrorData(e);
        }
    }, [updateData, setResponseErrorData]);

    const handleChangeTakeProfit = useCallback(() => {
        const t = setTimeout(() => {
            setIsUpdate(true);
            clearTimeout(t);
        }, 300);
    }, []);

    const handleOnClose = useCallback((e, type) => {
        e && e.preventDefault();
        setResponseErrorData(null);
        setResponseSuccessData(null);
        handleClose();
    }, [setResponseErrorData, setResponseSuccessData, handleClose]);

    const handleResetData = (e) => {
        e && e.preventDefault();
        // e && e.stopImmediatePropagation();
        setResponseErrorData(null);
        setResponseSuccessData(null);
    };

    const unlinkModal = useMemo(() => {
        return {
            open: (tpId) => setUnlinkId(tpId),
            close: () => setUnlinkId(null)
        };
    }, [setUnlinkId]);

    const handleUnlinkConfirm = useCallback(async () => {
        if (!unlinkId || !unlinkTradeCommand) return null;

        const reset = () => {
            setIsLoading(false);
            unlinkModal.close();
        };

        const userInput = { followerPositionID: Number(unlinkId) };

        const command = CommandFactory.createCommand(unlinkTradeCommand);

        try {
            const res = await command.execute(userInput);
            setIsLoading(false);
            unlinkModal.close();
            return res;
        } catch (err) {
            setIsLoading(false);
            unlinkModal.close();
            console.error(err); // TODO: remove log add error
        }
    }, [unlinkId, unlinkTradeCommand, setIsLoading]);

    const handleUnlinkOpen = useCallback((tpId) => {
        unlinkModal.open(tpId);
    }, [unlinkModal]);

    useEffect(() => {
        setIsUpdate(true);
        return () => {
            setIsUpdate(true);
        };
    }, [updateData]);

    return (
        <MobilePopUp
            isOpen={!!updateData}
            onClose={handleClose}
        >
            <MobileSwiper
                touchRef={touchRef}
                className='mobile-position-popup'
                type='positionUpdate'
            >
                {!!updateData && (
                    <>
                        <MobileLoader isLoading={isLoading} overlay />

                        <MobileModal
                            isOpen={!!unlinkId}
                            onClose={unlinkModal.close}
                            className='center'
                        >
                            <div className='mobile-modal-delete'>

                                <p>
                                    {t('Social.Unlink.ConfirmTitle')}
                                </p>
                                <p>
                                    {t('Unlink.Attention')}
                                </p>
                                <p>
                                    {t('Unlink.Text')}
                                </p>

                                <div className='mobile-modal-delete__section'>
                                    <div className='mobile-modal-delete__btn-wrapper'>
                                        <MobileButton
                                            type='success'
                                            className={clsx('mobile-modal-delete__btn',
                                                'success')}
                                            onClick={handleUnlinkConfirm}
                                            title={t('InstrumetInfo.Details.Unlink')}
                                        />
                                    </div>
                                    <div className='mobile-modal-delete__btn-wrapper'>
                                        <MobileButton
                                            type='cancel'
                                            className={clsx('mobile-modal-delete__btn',
                                                'cancel')}
                                            onClick={unlinkModal.close}
                                            title={t('InstrumetInfo.Details.Cancel')}
                                        />
                                    </div>
                                </div>
                            </div>

                        </MobileModal>

                        <MobileUpdatePositionHeader
                            reference={touchRef}
                            selectedSymbol={symbols[updateData?.symbolId]}
                            onClose={handleResetData}
                        />
                        <MobileUpdatePositionMain
                            {...updateData}
                            isLoading={isLoading}
                            handleCloseOrder={handleCloseOrder}
                            handleUpdateOrder={handleUpdateOrder}
                            handleUnlinkOpen={handleUnlinkOpen}
                            responseErrorData={responseErrorData}
                            handleOnClose={handleOnClose}
                            responseSuccessData={responseSuccessData}
                            handleChangeTakeProfit={handleChangeTakeProfit}
                            isUpdate={isUpdate}
                        />
                    </>
                )}
            </MobileSwiper>
        </MobilePopUp>
    );
};

export default MobileUpdatePosition;
