import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Switch from '../../../../UI/primitives/Switch';
import InputStepper from '../../../../UI/primitives/InputStepper';
import PendingDropDown from '../../../../UI/TradePopup/PendingDropDown';
import {ACTION_TYPE} from '../../../../UI/TradePopup/TradePopup';
import Button from '../../../../UI/primitives/Button';
import TickItemToolTipContent
	from '../../../../UI/TickItem/TickItemToolTipContent';

const MobileTradeTwo = props => {
	const {
		pendingOrder,
		handleSwitchChange,
		// cancelOrder,
		price,
		status,
		onClose,
		isPending,
		handleSetValue,
		pendingOrderPrice,
		type,
		selectedSymbol,
		response,
		tickObj,
		activeTextColor,
		inactiveTextColor,
		generalColor,
		systemColor,
		focusPendingOrder,
	} = props;

	const [pendingPrice, setPendingPrice] = useState(pendingOrderPrice);
	const [showError, setShowError] = useState('');
	const [showInfo, setShowInfo] = useState(false);
	const [pendingDropDown, setPendingDropDown] = useState(false);
	const {t} = useTranslation();
	let {pipDigit, displayDigit} = selectedSymbol;
	displayDigit = Math.max(displayDigit, pipDigit);

	let pipNumber = 1;
	for (let i = 0; i < pipDigit; i += 1) {
		pipNumber /= 10;
	}

	useEffect(() => {
		setPendingPrice(pendingOrderPrice);
	}, [pendingOrderPrice]);

	const handleShowError = (error) => {
		setShowError(error);
	};

	const toggleInfo = () => {
		setShowInfo((prevState) => !prevState);
	};

	const togglePendingDropDown = () => {
		setPendingDropDown((prevState) => !prevState);
	};

	const closePendingDropDown = () => {
		setPendingDropDown(false);
	};

	useEffect(() => {
		focusPendingOrder && focusPendingOrder(pendingDropDown);
	}, [pendingDropDown]);

	return (
		<>
			<div className='order-step2'>
				<Switch
					isSelected={pendingOrder}
					handleSwitch={handleSwitchChange}
					name='pendingOrder'
					// offText='Order'
					onText={t('Trade.PendingOrder.Title')}
					generalColor={generalColor}
					activeColor={activeTextColor.color}
				/>
				{isPending && (
					<>
						<InputStepper
							selectedUnits={pendingPrice}
							stepValue={pipNumber}
							setValue={handleSetValue}
							pipDigit={pipDigit}
							showError={handleShowError}
							errorMessage='Input.Error.PendingPrice.Incorrect'
							isError={showError}
							onFocusAction={togglePendingDropDown}
							id='pending-price-stepper'
							shouldSelect={pendingDropDown}
							onKeyUp={closePendingDropDown}
						/>
						{showError &&
						<span className='input-description error'>{t(showError)}</span>}
						{pendingDropDown && (
							<div className='trade-popup__units-list step-two'>
								<PendingDropDown
									selectPendingPrice={handleSetValue}
									togglePendingDropDown={togglePendingDropDown}
									handleClick={togglePendingDropDown}
									pendingPrice={pendingPrice}
									tickObj={tickObj}
									isBidActive={type === ACTION_TYPE.BID}
									pipDigit={pipDigit}
									displayDigit={displayDigit}
								/>
							</div>
						)}
					</>
				)}
				<Button
					className={showInfo ?
						'btn-open-close btn-open-close--active' :
						'btn-open-close'}
					style={showInfo ? activeTextColor : generalColor}
					text={t('Trade.Info.Title')}
					onClickHandler={toggleInfo}
					iconStart={showInfo ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
				/>
				{showInfo && <TickItemToolTipContent tickData={selectedSymbol}
				                                     generalColor={generalColor}
				                                     systemColor={systemColor}
				                                     hideHeadings/>}
			</div>
			{(response || status) && (
				<div className='status-container'>
					{response && (
						<>
							<p className='error__title'>{t('Trade.Execute.Failed')}</p>
							<span className='error__message'>{t(
								response.result ?? 'trading.error.GeneralError')}</span>
						</>
					)}
				</div>
			)}
		</>
	);
};

export default MobileTradeTwo;
