import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import roundToPrecision from '../utilsUI/roundToPrecision';
import Button from '../primitives/Button';
import useWindowSize from '../utilsUI/useWindowSize';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';

const Suggestions = ({
    users, getProfileData, toggleExplorePage, clodeUserModal, isLoadingProfile, generalColor, generalButtonColor
}) => {
    const { t } = useTranslation();
    const [randomUsers, setRandomUsers] = useState(null);
    const [width, height] = useWindowSize();
    const maxSuggestions = width > 1199 ? 6 : 5;
    useEffect(() => {
        if (users.length > maxSuggestions) {
            const shuffled = users.sort(() => 0.5 - Math.random());
            setRandomUsers(shuffled);
        } else {
            setRandomUsers(users);
        }
    }, [users, isLoadingProfile]);
    const handleItemSelect = (e) => {
        const userName = e.target.closest('.profile-page__suggestion-item').getAttribute('data');
        const avatar = e.target.closest('.profile-page__suggestion-item').getAttribute('data-avatar');
        const copiers = e.target.closest('.profile-page__suggestion-item').getAttribute('data-copiers');
        // eslint-disable-next-line no-unused-expressions
        userName && getProfileData({ nickname: userName, avatar, copiers, shouldOpenCopy: false });
        SendEventToGoogleAnalytics("Profile:Suggestions:ProfileClick", {nickname: userName});
    };

    const handleExplorePage = () => {
      SendEventToGoogleAnalytics("Profile:Suggestions:ExplorePageClick");
        clodeUserModal();
        toggleExplorePage();
    };

    const renderProfit = (value) => {
        if (value > 5) {
            return <div className='green'>&gt;500%</div>;
        } if (value >= 0.1 || value <= -0.1) {
            return (
                <div className='green'>
                    {roundToPrecision(value * 100, 0)}
                    %
                </div>
            );
        }
        return (
            <div className='green'>
                {roundToPrecision(value * 100, 1)}
                %
            </div>
        );
    };


    return (
        <div className='profile-page__suggestions'>
            <div className='profile-page__suggestions-title' style={generalColor}>{t('Social.Suggestions.Text')}:</div>
            <div className='profile-page__suggestions-items'>
                {!isLoadingProfile && randomUsers && randomUsers.slice(0, maxSuggestions).map((user) => {
                    return (
                        <div className='profile-page__suggestion-item' onClick={handleItemSelect} data={user?.Nickname} data-avatar={user?.Avatar} data-copiers={user?.Copiers} key={user?.Nickname}>
                            {/* <div className={`avatar ${user?.Avatar.toLowerCase()}`} /> */}
                            <div className='item-info'>
                                <span>{user?.Nickname}</span>
                                <div>{renderProfit(user?.Profit)}</div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className='profile-page__suggestions-more'>
                <Button text={t('Social.Suggestions.ShowMore')} className='btn btn-more btn--secondary' style={generalButtonColor} onClickHandler={handleExplorePage} />
            </div>
        </div>
    );
};

// Suggestions.propTypes = {};

export default Suggestions;
