import React, { useCallback, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CommandFactory } from '../../../../background/commands/CommandFactory';
import useProfile from '../../../context/contextHook/useProfile';

import MobileProfileCopySuccess from './MobileProfileCopySuccess';
import MobileProfileCopyMain from './MobileProfileCopyMain';
import MobileProfileCopyUncopy from './MobileProfileCopyUncopy';
import MobileLoader from '../../MobileLoader/MobileLoader';
import MobileCopyBlockDisclaimer from './MobileCopyBlockDisclaimer';

const MobileProfileCopy = ({
    profile, nickname = '', requestData, yearPnl
}) => {
    const {
        copiedUser: { handleCopiedClose }
    } = useProfile();

    const { addFollower } = useSelector(
        (state) => state.account.socialv1.copyRelationsCommands, shallowEqual
    );

    const { updateFollower, removeFollower } = useSelector(
        (state) => state.account.socialv1.copyRelationsCommands, shallowEqual
    );

    const { t } = useTranslation();

    const [copyStatus, setCopyStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [uncopyModal, setUncopyModal] = useState(false);
    const [showBlockDisclaimer, setShowBlockDisclaimer] = useState(false);

    const alreadyCopied = useMemo(() => {
        return profile?.CopyType === 1 || profile?.CopyType === 2;
    }, [profile]);

    const handleStatusClose = useCallback(() => {
        // handleCopiedClose && handleCopiedClose();
        setCopyStatus && setCopyStatus('');
    }, []);

    const handleRequestSuccess = useCallback(() => {
        const text = t('Social.CopyTab.CopyResponse', { nickName: nickname });
        setCopyStatus && setCopyStatus(text);
    }, [setCopyStatus]);

    const handleRequestError = useCallback((err) => {
        setCopyStatus && setCopyStatus(err);
    }, [setCopyStatus]);

    const handleAddFollower = useCallback(async (data) => {
        const { userData, tabValue } = data;
        if (!userData || !nickname) return null;
        const amount = tabValue === 0
            ? Number(userData?.equity)
            : Number(userData?.lotAmount);
        const copyType = tabValue === 0 ? 2 : 1;

        const userInput = {
            masterNickname: nickname,
            amount,
            copyType,
            copiedSourceType: 0,
            instruments: userData?.instruments
        };

        const command = CommandFactory.createCommand(addFollower);
        setIsLoading(true);
        try {
            const res = await command.execute(userInput);
            if (!res.isSuccessful) {
                setIsLoading(false);
                handleRequestError('Error copy fetch'); // TODO: add error
            }
            if (res.isSuccessful) {
                handleRequestSuccess();
            }
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            handleRequestError(err);
            console.error(err); // TODO: remove log add error
        }
    }, [handleRequestSuccess, handleRequestError, addFollower]);

    const handleUncopyFollower = useCallback(async () => {
        if (!nickname || !updateFollower) return null;
        const userInput = { guruNickname: nickname, amount: 1, copyType: 4 };

        const command = CommandFactory.createCommand(updateFollower);
        setIsLoading(true);
        try {
            const res = await command.execute(userInput);
            if (!res.isSuccessful) {
                handleRequestError('Error uncopy fetch'); // TODO: add error
            }
            if (res.isSuccessful) {
                requestData();
            }
            setIsLoading(false);
        } catch (err) {
            console.error(err); // TODO: remove log add err
            setIsLoading(false);
        }
    }, [updateFollower, nickname]);

    const handleUncopyOpen = useCallback(() => {
        setUncopyModal(true);
    }, [setUncopyModal]);

    const handleUncopyClose = useCallback(() => {
        setUncopyModal(false);
    }, [setUncopyModal]);

    const handleCloseBlockDiclaimer = () => {
        setShowBlockDisclaimer(false);
    };

    return (
        <div className='mobile-profile-copy'>
            {showBlockDisclaimer && <MobileCopyBlockDisclaimer handleClose={handleCloseBlockDiclaimer} masterNickname={nickname} />}
            {!!copyStatus && (
                <MobileProfileCopySuccess
                    requestData={requestData}
                    title={copyStatus}
                    onClose={handleStatusClose}
                />
            )}
            {!copyStatus && !showBlockDisclaimer && (
                <>
                    <MobileLoader isLoading={isLoading} size='large' />

                    {uncopyModal && (
                        <MobileProfileCopyUncopy
                            nickname={nickname}
                            onClose={handleUncopyClose}
                            onAction={handleUncopyFollower}
                        />
                    )}
                    {!uncopyModal && (
                        <MobileProfileCopyMain
                            {...profile}
                            alreadyCopied={alreadyCopied}
                            nickname={nickname}
                            yearPnl={yearPnl}
                            handleAddFollower={handleAddFollower}
                            handleUncopyFollower={handleUncopyOpen}
                            setShowBlockDisclaimer={setShowBlockDisclaimer}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default MobileProfileCopy;
