import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import {getFooterMenu} from '../../helpers/options.helpers';

import {
  CounterClosedPosition,
  CounterOpenPosition,
  CounterPendingOrders,
} from '../MobilePosition/MobilePositionCount/MobilePositionCount';

import MobileOpenPosition
  from '../MobilePosition/MobileOpenPosition/MobileOpenPosition';
import MobilePendingOrders from '../MobilePosition/MobilePendingOrders';
import MobileClosedPosition
  from '../MobilePosition/MobileClosedPosition/MobileClosedPosition';
import MobileCarouselMenu
  from '../../components/MobileCarousel/MobileCarouselMenu';
import CarouselTabList from '../../components/MobileCarousel/CarouselTabList';
import MobileSocialWatchPage from '../../pages/MobileSocialWatchPage';
import MobileSocialCopyPage from '../../pages/MobileSocialCopyPage';

const START_TAB_VALUE = 'open_position';

const MobileFooterSection = ({isOpen}) => {
  const {
    accountInfo: {
      rightPanelSettings: {
        socialv1: {isVisible},
      },
    },
    socialv1: {isEnabled},
  } = useSelector(state => state.account);

  //TODO: handleUnlink popup
  const [shouldShowUnlinkPopup, setShoudShowUnlinkPopup] = useState(
      {tpId: null, shouldShow: false});

  const isSocialsEnabled = useMemo(() => {
    // return false;
    return isEnabled && isVisible;
  }, [isEnabled, isVisible]);

  const carouselCounter = useMemo(() => {
    const counterChildren = [
      <CounterOpenPosition/>,
      <CounterPendingOrders/>,
      <CounterClosedPosition/>,
    ];
    if (!isSocialsEnabled) return counterChildren;
    return [
      <></>,
      <></>,
      ...counterChildren,
    ];
  }, [
    isSocialsEnabled,
    CounterOpenPosition,
    CounterPendingOrders,
    CounterClosedPosition,
  ]);

  //TODO: open position  handle unlink
  const handleUnlink = useCallback(tpId => {
    if (tpId) {
      setShoudShowUnlinkPopup(
          (prevState) => ({tpId, shouldShow: !prevState.shouldShow}));
    } else {
      setShoudShowUnlinkPopup(
          prevState => ({tpId: null, shouldShow: !prevState.shouldShow}));
    }
  }, []);

  const carouselList = useMemo(() => {
    const listChildren = [
      <MobileOpenPosition handleUnlink={handleUnlink}/>,
      <MobilePendingOrders/>,
      <MobileClosedPosition/>,
    ];
    if (!isSocialsEnabled) return listChildren;
    return [
      <MobileSocialWatchPage isVisible={isSocialsEnabled}/>,
      <MobileSocialCopyPage isVisible={isSocialsEnabled}/>,
      ...listChildren,
    ];
  }, [isSocialsEnabled, handleUnlink]);

  const POSITION_MENU = useMemo(() => {
    return getFooterMenu(isSocialsEnabled);
  }, [isSocialsEnabled]);

  const startTabPosition = useCallback(() => {
    const positionIndex = POSITION_MENU.findIndex(
        el => el.value === START_TAB_VALUE);
    return isSocialsEnabled
        ? positionIndex || 0
        : 0;
  }, [POSITION_MENU, isSocialsEnabled]);

  const [carouseTab, setCarouselTab] = useState(startTabPosition);

  const handleTabChange = useCallback(option => {
    if (!option) return null;
    setCarouselTab(option.index);
  }, []);

  useEffect(() => {
    return () => setCarouselTab(startTabPosition);
  }, [startTabPosition]);

  return (
      <div className="mobile-footer-content__section">
        <MobileCarouselMenu
            options={POSITION_MENU}
            onChange={handleTabChange}
            type="secondary"
            theme={isOpen ? 'black' : 'blue'}
            position
            value={carouseTab}
            tabChildComponents={carouselCounter}
        />

        <CarouselTabList
            index={carouseTab}
            classname="hT50"
            options={carouselList}
        >
          {/*<MobileSocialWatchPage isVisible={isSocialsEnabled}/>*/}
          {/*<MobileSocialCopyPage isVisible={isSocialsEnabled}/>*/}
          {/*<MobileOpenPosition handleUnlink={handleUnlink}/>*/}
          {/*<MobilePendingOrders/>*/}
          {/*<MobileClosedPosition/>*/}
        </CarouselTabList>

      </div>
  );
};

export default MobileFooterSection;
