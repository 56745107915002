import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import {PROFILE_POSITION_MENU} from '../../../constants/options';
import { CommandFactory } from '../../../../background/commands/CommandFactory';

import MobileChartSwitch from '../../MobileSwitch/MobileChartSwitch';
import CarouselTabList from '../../MobileCarousel/CarouselTabList';

import MobileProfilePositionOpen from './MobileProfilePositionOpen';
import MobileLoader from '../../MobileLoader/MobileLoader';

const MobileProfilePositions = ({nickname}) => {
	const {getPositions} = useSelector(
			state => state.account.socialv1.profileCommands);

	const [timeframe, setTimeframe] = useState(1);
	const [isLoading, setIsLoading] = useState(false);
	const [positions, setPositions] = useState(null);

	const {
		ProfileOpenTrades,
		ProfilePendingTrades,
		ProfileClosedTrades,
	} = useMemo(() => {
		const pos = {
			ProfileOpenTrades: [],
			ProfilePendingTrades: [],
			ProfileClosedTrades: [],
		};
		if (!positions) return pos;
		pos.ProfileOpenTrades = positions.ProfileOpenTrades || [];
		pos.ProfilePendingTrades = positions.ProfilePendingTrades || [];
		pos.ProfileClosedTrades = positions.ProfileClosedTrades || [];
		return pos;
	}, [positions]);

	const handleTimeChange = useCallback(({timeKey}) => {
		setTimeframe && setTimeframe(timeKey);
	}, [setTimeframe]);

	const requestPositions = useCallback(async () => {
		if (!nickname) return null;
		setIsLoading(true);
		const userInput = {nickname, timeframe: 1};

		const command = CommandFactory.createCommand(getPositions);

		try {
			const res = await command.execute(userInput);

			if (!res.isSuccessful) {
				console.error(`Get position for ${userInput.nickname} request error`); //TODO: remove log set error
			}

			if (res.isSuccessful) {
				setPositions(res.payload);
			}
		} catch (err) {
			console.error(err); //TODO: remove log set error
		}
		setIsLoading(false);
	}, [nickname]);

	useEffect(() => {
		requestPositions();
	}, [requestPositions]);

	return (
			<div className="mobile-profile-position">
				<MobileChartSwitch
						options={PROFILE_POSITION_MENU}
						onChange={handleTimeChange}
						value={timeframe}
						type='profile'
				/>

				<MobileLoader isLoading={isLoading}/>

				{!isLoading && (
						<CarouselTabList
								index={timeframe - 1}
								classname="hT50"
						>
							<MobileProfilePositionOpen positions={ProfileOpenTrades}/>
							<MobileProfilePositionOpen
									positions={ProfilePendingTrades}
									pendingOrders
							/>
							<MobileProfilePositionOpen
									positions={ProfileClosedTrades}
									closedOrders
							/>
						</CarouselTabList>
				)}
			</div>
	);
};

export default MobileProfilePositions;
