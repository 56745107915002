import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { RISK_LENGTH } from '../../constants/risk.options';
import { getLevelItemStyle, isActiveItem } from '../../helpers/risk.helpers';


const MobileRiskLevelOld = ({ level, title, label }) => {
    const { t } = useTranslation();
    const componentTitle = useMemo(() => {
        let titleText = '';
        if (typeof title !== 'string') {
            titleText = title;
        } else if (title.toLowerCase() === 'risk level') {
            titleText = t('Social.Profile.RiskLevel');
        } else {
            titleText = t(title);
        }

        return (
            <p className='mobile-risk-level__title'>
                {titleText}
            </p>
        );
    }, [title]);

    const componentBar = useMemo(() => {
        const arr = [...Array(RISK_LENGTH).keys()].map((i) => i + 1);
        return (
            <ul className='mobile-risk-level__bar'>
                {arr.map((item, index) => {
                    const isActive = isActiveItem(index, level);
                    const styles = getLevelItemStyle(index);
                    return (
                        <li
                            key={`risk-level-${item}`}
                            className={clsx('mobile-risk-mobile-risk-level__bar__item',
                                isActive && 'active')}
                        >
                            <div
                                className='mobile-risk-mobile-risk-level__bar__item__block'
                                style={{ ...styles }}
                            />
                        </li>
                    );
                })}
            </ul>
        );
    }, [level]);

    const componentLabel = useMemo(() => {
        return (
            <ul className='mobile-risk-level__label-list'>
                {label && label.length && label.map((el, i) => (
                    <li
                        key={`level-label-${i}`}
                        className='mobile-risk-level__label-list__item'
                    >
                        {el}
                    </li>
                ))}
            </ul>
        );
    }, [label]);

    return (
        <div className='mobile-risk-level'>
            {componentTitle}
            {componentBar}
            {componentLabel}
        </div>
    );
};

MobileRiskLevelOld.defaultProps = {
    level: 0,
    title: 'Risk Level',
    label: ['Low', 'Medium', 'High']
};

export default MobileRiskLevelOld;
