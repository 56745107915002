import React from 'react';
import PubsubSubscription from '../background/repositories/PubsubSubscription';


export default function RepositoryValueView(props) {
    const { repObj } = props;
    const obj = PubsubSubscription.subscribeObj(repObj);
    const disValue = JSON.stringify(obj);

    return (<div>{disValue}</div>);
}
