import { Capacitor } from '@capacitor/core';

export const adjustURL = (relative_url)=>
{
  return relative_url;  
}

export const toWsUrl = (relative_url)=>
{
  const protocolPrefix = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
  let { host } = window.location;
  return `${protocolPrefix}//${host}${relative_url}`;
}