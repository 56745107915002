import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import MobileSymbolIcon from '../../MobileSymbolIcon/MobileSymbolIcon';
import Format2Fixed from '../../../../UI/utilsUI/formatNumber';
import clsx from 'clsx';

const MobileProfileStreamItem = props => {
	const {
    symbols,
		InstrumentName,
		TradeType,
		TradeActionType,
		Price,
		PendingType,
		OpenPrice,
		ClosePrice,
		NumberOfCopiedPositions,
		PL,
	} = props;

	const {t} = useTranslation();

	const currentType = useMemo(() => {
		// TODO: locale this
		// ? t('Stream.User.Opened.Position')
		// : t('Stream.User.Closed.Position');
		// 'Stream.User.Pending.Position';
		let label = '';
		switch (TradeType) {
			case 0:
				label = 'Opened';
				break;
			case 1:
				label = 'Closed';
				break;
			case 2:
				label = 'Pending';
				break;
			default:
				label = 'Opened';
				break;
		}
		return label.toLowerCase();
	}, [TradeType]);

  let symbolSettings = symbols[InstrumentName];
  let flagSettings = symbolSettings?.flag;

	const currentAction = useMemo(() => {
		let label = '';
		if (TradeType === 2) {
			switch (PendingType) {
				case 0:
					label = 'PendingOrder.Buy.Limit';
					break;
				case 1:
					label = 'PendingOrder.Sell.Limit';
					break;
				case 2:
					label = 'PendingOrder.Buy.Stop';
					break;
				case 3:
					label = 'PendingOrder.Sell.Stop';
					break;
				default:
					label = 'PendingOrder.Buy.Limit';
			}
		} else {
			label = TradeActionType === 0 ? 'Stream.Buy' : 'Stream.Sell';
		}
		return t(label).toLowerCase();
	}, [TradeType, PendingType, TradeActionType]);

	const currentPrice = useMemo(() => {
		return TradeType === 2
				? Price
				: TradeType === 0 ? OpenPrice : ClosePrice;
	}, [OpenPrice, ClosePrice, TradeType, Price]);

	const currentPnl = useMemo(() => {
		return PL === undefined
				? '--'
				: !!PL
						? `${Format2Fixed(PL * 100)}%`
						: '0%';
	}, [PL]);

	return (
			<div className="mobile-profile-stream-item">
				<div className="mobile-profile-stream-item__row">

					{/*DESC*/}
					<div className="mobile-profile-stream-item__desc">

						<div className="mobile-profile-stream-item__desc__flag__wrapper">
							<div className="mobile-profile-stream-item__desc__flag">
              <MobileSymbolIcon
										name={InstrumentName}
										flag={flagSettings}
													/>
							</div>
						</div>

						<div className="mobile-profile-stream-item__desc__info">

							<div className="mobile-profile-stream-item__desc__info__row">
								<div className="mobile-profile-stream-item__desc__info__symbol">
									<span>{currentType} &nbsp;</span>
                  <span>{InstrumentName}</span>
								</div>
							</div>

							<div className="mobile-profile-stream-item__desc__info__row">
								<div className="mobile-profile-stream-item__desc__info__type">
									<span>
										{currentAction}
										&nbsp;
									</span>
									<span>
										{/*TODO: locale this*/}
										position at
										&nbsp;
									</span>
									<span>{currentPrice}</span>
								</div>
							</div>

						</div>

					</div>

					{/*STAT*/}
					<div className="mobile-profile-stream-item__stat">

						<div className="mobile-profile-stream-item__block">
							{/*{!!PL && (*/}
							<>
								<p className="mobile-profile-stream-item__block__title">
									{t('Stream.Earning')}
								</p>
								<div className={clsx(
										'mobile-profile-stream-item__block__count', 'pnl')}>
									{/*{Format2Fixed(PL * 100)}*/}
									{currentPnl}
								</div>
							</>
							{/*)}*/}
						</div>

						<div className="mobile-profile-stream-item__block">
							{/*{!!NumberOfCopiedPositions && (*/}
							<>
								<p className="mobile-profile-stream-item__block__title">
									{t('Socials.Copiers')}
								</p>
								<div className="mobile-profile-stream-item__block__count">
									{NumberOfCopiedPositions || 0}
								</div>
							</>
							{/*)}*/}
						</div>

					</div>

				</div>
			</div>
	);
};

export default MobileProfileStreamItem;

/*
* AvatarURL: "defaultAvatar2"
ClosePrice: "1.40239"
CloseTime: "2021-02-22T11:15:52Z"
CountryCode: "EU"
InstrumentName: "GBPUSD"
IsCopied: false
IsPartial: false
Nickname: "ninoroma"
NumberOfCopiedPositions: 0
OpenTime: "2021-02-22T10:28:34Z"
PL: 0.007268
TimeStamp: "2021-02-22T15:15:52Z"
TradeActionType: 1
TradeHash: 703717604
TradeType: 1
TraderRiskLevel: 5
TradingRiskLevel: 1
* */
