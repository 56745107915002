/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
// import PropTypes from 'prop-types';

const OuterClick = (WrappedComponent, additionalComponent) => class extends Component {
    constructor(props) {
        super(props);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    onClose = () => {
        // eslint-disable-next-line no-unused-expressions
        this.props.handleClick && this.props.handleClick();
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    // eslint-disable-next-line consistent-return
    handleClickOutside = (event) => {
        if (additionalComponent && !!event.target.closest(additionalComponent) === true) {
            return null;
        }

        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.onClose();
        }
    }

    render() {
        return (
            <div ref={this.setWrapperRef}>
                <WrappedComponent {...this.props}>
                    {this.props.children}
                </WrappedComponent>
            </div>
        );
    }
};

// OuterClick.propTypes = {
//     WrappedComponent: PropTypes.object.isRequired
// };

export default OuterClick;
