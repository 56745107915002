import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';

import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Controller, Pagination, Thumbs} from 'swiper';

import 'swiper/swiper-bundle.css';

SwiperCore.use([Pagination, Controller, Thumbs]);

const MobileCarouselMenu = props => {
	const {
		options = [],
		value = 0,
		onChange,
		type = 'primary',
		theme = 'black',
		position = false,
		profile = false,
		pagination = false,
		tabChildComponents = [],
	} = props;

	const {t} = useTranslation();

	const swiperRef = useRef(null);

	const handleChange = useCallback(swiper => {
		const index = swiper.activeIndex;
		const option = {
			index,
			value: options[index],
			options,
		};
		onChange && onChange(option);
	}, [onChange, options]);

	useEffect(() => {
		if (swiperRef && swiperRef.current) {
			const swiper = swiperRef.current.swiper;
			if (swiper.activeIndex !== value) {
				swiper.slideTo(value);
			}
		}
	}, [value]);

	const slidesPerView = useMemo(() => {
		return position
			? 2
			: profile
				? 3
				: type === 'primary'
					? 'auto'
					: 4;
	}, [position, type]);

	const spaceBetween = useMemo(() => {
		return type === 'primary' ? 4 : 0;
	}, [type]);

	return (
		<div
			className={clsx('mobile-carousel', 'gradient-carousel', type, theme)}>
			<Swiper
				ref={swiperRef}
				tag="div"
				wrapperTag="ul"
				slidesPerView={slidesPerView}
				spaceBetween={spaceBetween}
				centeredSlides
				initialSlide={value}
				slideToClickedSlide
				onSlideChange={handleChange}
				pagination={pagination}
			>
				{options.map((option, index) => (
					<SwiperSlide
						key={`slide-${index}`}
						tag="li"
					>
						<div className="mobile-carousel__item__inner">
							<div className="mobile-carousel__item">

								{!!option.translation
									? t(option.translation).toLowerCase()
									: option.label.toLowerCase()
								}
								{tabChildComponents && index <=
								tabChildComponents.length - 1
									? tabChildComponents[index]
									: ''
								}
							</div>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
};

export default MobileCarouselMenu;
