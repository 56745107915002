import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';

import { CommandFactory } from '../../../../background/commands/CommandFactory';

import useProfile from '../../../context/contextHook/useProfile';
import {FormatPercentFloat} from '../../../../UI/ProfilePage/ProfileTabDetails';
import MobileSocialExploreItemDraw from './MobileSocialExploreItemDraw';

const MobileSocialExploreItem = props => {
	const {
		Nickname,
		Avatar,
		CopiersCount,
		CumulativePnl,
		JoinDateTime,
		WinRatio,
		SuccessRate,
		isActive,
		index,
		handleOpen,
		handleOpenWatchModal,
	} = props;

	const {
		profileUser: {openProfileUser},
	} = useProfile();

	const {getUserProfilePage} = useSelector(
			state => state.account.socialv1.profileCommands, shallowEqual);

	const {updateFollower, removeFollower} = useSelector(
			state => state.account.socialv1.copyRelationsCommands, shallowEqual);

	const currentUser = useSelector(state => state.account.socialv1.userDetails,
			shallowEqual);

	const [isLoadingWatch, setIsLoadingWatch] = useState(false);
	const [isLoadingCopy, setIsLoadingCopy] = useState(false);

	const isCurrentUserProfile = useMemo(() => {
		const pUser = Nickname;
		const cUser = currentUser?.nickname;
		return pUser === cUser;
	}, [currentUser, Nickname]);

	const [userProfile, setUserProfile] = useState(null);

	const successRate = useMemo(() => {
		return WinRatio
				? FormatPercentFloat({value: WinRatio})
				: SuccessRate && FormatPercentFloat({value: SuccessRate});
	}, [WinRatio, SuccessRate]);

	const alreadyCopied = useMemo(() => {
		return userProfile?.CopyType === 1 || userProfile?.CopyType === 2;
	}, [userProfile]);

	const alreadyWatched = useMemo(() => {
		return userProfile?.CopyType === 4 || alreadyCopied;
	}, [userProfile, alreadyCopied]);

	const getUserData = useCallback(async () => {
		if (!Nickname || !getUserProfilePage) return;
		const userInput = {nickname: Nickname};
		
		const command = CommandFactory.createCommand(getUserProfilePage);
		try {
			const res = await command.execute(userInput);
			if (res.isSuccessful) setUserProfile(res.payload);
			return res;
		} catch (err) {
			console.error(err);
		}
	}, [getUserProfilePage, Nickname, isLoadingWatch, isLoadingCopy]);

	const handleToggleIsOpen = useCallback(e => {
		e && e.stopPropagation();
		if (isActive) return handleOpen && handleOpen(null);
		return handleOpen && handleOpen(index);
	}, [isActive, index]);

	const handleClickWatch = useCallback(async () => {
		if (!Nickname || isCurrentUserProfile) return null;
		const userInput = {
			guruNickname: Nickname,
			amount: alreadyWatched ? 0 : 1,
			copyType: alreadyWatched ? 0 : 4,
		};

		let commandObj = {};
		if (alreadyWatched) {
			commandObj = {
				...removeFollower,
				userInput,
			};
		}
		if (!alreadyWatched) {
			commandObj = {
				...updateFollower,
				userInput,
			};
		}

		const command = CommandFactory.createCommand(commandObj);
		setIsLoadingWatch(true);
		try {
			const res = await command.execute();
			if (res.isSuccessful) {
				try {
					await getUserData();
				} catch (err) {
					console.error(err); //TODO: remove log add err
				}
			}
			setIsLoadingWatch(false);
		} catch (err) {
			console.error(err); //TODO: remove log add err
			setIsLoadingWatch(false);
		}
		if (!alreadyWatched) {
			handleOpenWatchModal && handleOpenWatchModal(Nickname);
		}
	}, [
		alreadyWatched,
		Nickname,
		updateFollower,
		removeFollower,
		getUserData,
		isCurrentUserProfile]);

	const handleClickCopy = useCallback(e => {
		e && e.stopPropagation();
		if (isCurrentUserProfile) return null;
		openProfileUser && openProfileUser(Nickname, true);
	}, [Nickname, openProfileUser, isCurrentUserProfile]);

	const handleOpenProfile = useCallback(e => {
		e && e.preventDefault();
		e && e.stopPropagation();
		openProfileUser && openProfileUser(props.Nickname);
	}, [Nickname, openProfileUser]);

	useEffect(() => {
		getUserData();
	}, [getUserData]);

	return (
			<MobileSocialExploreItemDraw
					isActive={isActive}
					Avatar={Avatar}
					userProfile={userProfile}
					Nickname={Nickname}
					CopiersCount={CopiersCount}
					successRate={successRate || 0}
					alreadyCopied={alreadyCopied}
					alreadyWatched={alreadyWatched}
					isLoadingWatch={isLoadingWatch}
					isLoadingCopy={isLoadingCopy}
					JoinDateTime={JoinDateTime}
					CumulativePnl={CumulativePnl}
					handleToggleIsOpen={handleToggleIsOpen}
					handleClickCopy={handleClickCopy}
					handleClickWatch={handleClickWatch}
					handleOpenProfile={handleOpenProfile}
			/>
	);
};

export default MobileSocialExploreItem;
