import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';

import debounce from '../utilsUI/debounce';

import Button from '../primitives/Button';
import questionGraph from '../icons/questionGraph.png';


const QuestionStepOne = ({ steps, setSteps, handleNextChange }) => {
    const [shouldHideIcon, setShouldHideIcon] = useState(false);
    const { t } = useTranslation();
    const bottomDiv = useRef();
    const stepContainer = useRef();
    const options = [
        { id: 1, value: t('Socialv1.Suitability.Q1.Answer1') },
        { id: 2, value: t('Socialv1.Suitability.Q1.Answer2') },
        { id: 3, value: t('Socialv1.Suitability.Q1.Answer3') },
        { id: 4, value: t('Socialv1.Suitability.Q1.Answer4') }
    ];

    const selectOption = (value) => {
        const nextStepData = steps.stepData;
        nextStepData[0] = { selectedOption: value, isFulfilled: true };
        setSteps((prevState) => ({
            ...prevState, stepData: nextStepData
        }));
    };

    // const checkVisible = () => {
    //     const rect = bottomDiv.current.getBoundingClientRect();
    //     const container = stepContainer.current.getBoundingClientRect();
    //     return rect.bottom <= container.bottom;
    // };

    // const isBottomReached = useCallback(
    //     debounce(() => checkVisible, 200), []
    // );

    useEffect(() => {
        const checkVisible = () => {
            const rect = bottomDiv.current.getBoundingClientRect();
            const container = stepContainer.current.getBoundingClientRect();
            // return rect.bottom <= container.bottom;
            if (rect.bottom <= container.bottom) {
                setShouldHideIcon(true);
            }
        };
        // if (checkVisible) {
        //     setShouldHideIcon(true);
        // }
        // const handleIcon = () => {
        //     if (isBottomReached) {
        //         console.log(isBottomReached);
        //         // setShouldHideIcon(true);
        //     } else {
        //         // setShouldHideIcon(false);
        //         console.log(isBottomReached);
        //     }
        // };
        stepContainer.current.addEventListener('scroll', checkVisible);
        return () => window.removeEventListener('scroll', checkVisible);
    }, []);

    const RadioButtonsGroup = options.map((option) => {
        return (
            <div className={option.id === steps.stepData[0].selectedOption ? 'radio radio--selected' : 'radio'} key={option.id} onClick={() => selectOption(option.id)}>
                {option.value}
            </div>
        );
    });

    return (
        <div className='step step-one' ref={stepContainer}>
            <div>
                <h5>
                    {t('Socialv1.Suitability.QuestionHeader')}
                </h5>
                <p className='text--margin-equal'>
                    {t('Socialv1.Suitability.Q1.Paragraph1')}
                </p>
                <img src={questionGraph} alt='text' />
                <p className='text--dark'>
                    {t('Socialv1.Suitability.Q1.Paragraph2')}
                </p>
                <p className='text--dark text--small'>
                    {t('Socialv1.Suitability.Q1.Paragraph3')}
                </p>
                {!shouldHideIcon && (
                    <Button
                        className='btn btn--scroll'
                        iconStart='fas fa-chevron-down'
                        onClickHandler={() => {
                            bottomDiv.current.scrollIntoView({ behavior: 'smooth' });
                        }}
                    />
                )}
            </div>
            <div className='steps__radio-group'>
                {RadioButtonsGroup}
            </div>
            <Button className='btn btn--general btn--next' text={t('General.Next')} onClickHandler={handleNextChange} />
            <div ref={bottomDiv} />
        </div>
    );
};

// QuestionStepOne.propTypes = {};

export default QuestionStepOne;
