/* eslint-disable complexity */
import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';

import { formatDate } from '../utilsUI/formatTime';
import { FormatPercentFloat } from './ProfileTabDetails';
import { format2Digits } from '../utilsUI/formatNumber';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';

export const sortColumns = (data, sortName, sortType, direction) => {
    let sortResult = [];
    if (sortType === 'date') {
        if (direction) {
            sortResult = data.sort((a, b) => new Date(a[sortName]).getTime() - new Date(b[sortName]).getTime());
        } else {
            sortResult = data.sort((a, b) => new Date(b[sortName]).getTime() - new Date(a[sortName]).getTime());
        }
    } else if (sortType === 'number') {
        if (direction) {
            sortResult = data.sort((a, b) => a[sortName] - b[sortName]);
        } else {
            sortResult = data.sort((a, b) => b[sortName] - a[sortName]);
        }
    } else if (sortType === 'string') {
        if (!direction) {
            sortResult = data.sort((a, b) => {
                const nameA = a[sortName]?.toUpperCase() || '';
                const nameB = b[sortName]?.toUpperCase() || '';
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
        } else {
            sortResult = data.sort((a, b) => {
                const nameA = a[sortName]?.toUpperCase() || '';
                const nameB = b[sortName]?.toUpperCase() || '';
                if (nameA > nameB) {
                    return -1;
                }
                if (nameA < nameB) {
                    return 1;
                }
                return 0;
            });
        }
    }

    return sortResult;
};


const ProfilePageOpenPositions = ({
  openedTab, positions, closedOrders, setSelectedTrader, pendingOrders
}) => {
    const [sortedPositions, setSortedPositions] = useState(null);
    const [prevColumn, setPrevColumn] = useState({ columnName: closedOrders ? 'CloseTime' : 'OpenTime', direction: false });
    const { t } = useTranslation();

    const openProfilePage = (e) => {
      var nickname = e.target.getAttribute('data-name');
      setSelectedTrader({ nickname: nickname });
      SendEventToGoogleAnalytics(`Profile:Positions:${openedTab}:ProfileClick`, {nickname: nickname});
    };

    const sortPositions = () => {
        if (closedOrders) {
            setSortedPositions(positions?.sort((a, b) => new Date(b.CloseTime).getTime() - new Date(a.CloseTime).getTime()));
        } else {
            setSortedPositions(positions?.sort((a, b) => new Date(b.OpenTime).getTime() - new Date(a.OpenTime).getTime()));
        }
    };

    const sortAll = (e) => {
        const element = e.target;
        const sortName = element.getAttribute('data-sort-name');
        const sortType = element.getAttribute('data-sort-type');
        const classValue = element.getAttribute('data-sorted');
        const direction = prevColumn.columnName === sortName ? !prevColumn.direction : true;
        element.setAttribute('id', sortName);
        if (classValue) {
            if (classValue === 'down') {
                element.setAttribute('data-sorted', 'up');
            } else {
                element.setAttribute('data-sorted', 'down');
            }
        } else {
            element.setAttribute('data-sorted', 'up');
        }
        if (prevColumn && sortName !== prevColumn.columnName) {
            document.getElementById(prevColumn.columnName).setAttribute('data-sorted', '');
        }
        // Array.prototype.map.call(element.parentNode.children, el => console.log('el', el));

        setSortedPositions(sortColumns(positions, sortName, sortType, direction));

        if (sortName === prevColumn.columnName) {
            setPrevColumn((prevState) => ({ columnName: sortName, direction: !prevState.direction }));
        } else {
            setPrevColumn({ columnName: sortName, direction: true });
        }
    };

    useEffect(() => {
        sortPositions();
        closedOrders ? setPrevColumn({ columnName: 'CloseTime', direction: false }) : setPrevColumn({ columnName: 'OpenTime', direction: false });
    }, []);

    const formatPendingText = (position) => {
        let pendingOrderText = '';
        if (position.PendingType === 0) {
            pendingOrderText = t('PendingOrder.Buy.Limit');
        } else if (position.PendingType === 1) {
            pendingOrderText = t('PendingOrder.Sell.Limit');
        } else if (position.PendingType === 2) {
            pendingOrderText = t('PendingOrder.Buy.Stop');
        } else if (position.PendingType === 3) {
            pendingOrderText = t('PendingOrder.Sell.Stop');
        }
        return pendingOrderText;
    };


    return (
        <Fragment>
            {positions?.length ? (
                <table className='responsive-table'>
                    <thead>
                        <tr className='responsive-table__header'>
                            {/* <th className='responsive-table__cell--small' /> */}
                            <th onClick={sortAll} data-sort-name='InstrumentName' data-sort-type='string'>{t('InstrumetInfo.Details.Instrument')}</th>
                            <th className='responsive-table__cell--small' onClick={sortAll} data-sort-name={pendingOrders ? 'PendingType' : 'TradeActionType'} data-sort-type='number'>
                                {t('InstrumetInfo.Details.Type')}
                            </th>
                            {!pendingOrders ? (
                                <th className='responsive-table__cell--price' onClick={sortAll} data-sort-name='OpenPrice' data-sort-type='number'>
                                    {t('InstrumetInfo.Details.OpenRate')}
                                </th>
                            ) : (
                                <th className='responsive-table__cell--price' onClick={sortAll} data-sort-name='Price' data-sort-type='number'>
                                    {t('InstrumetInfo.Details.OpenRate')}
                                </th>
                            )}
                            {/* <th className='responsive-table__cell--price' onClick={sortAll} data-sort-name='OpenPrice' data-sort-type='number'>
                                {t('InstrumetInfo.Details.OpenRate')}
                            </th> */}
                            {!closedOrders ? (
                                // <th className='responsive-table__cell--large' onClick={handleSortPositions} data-sorted='down'>
                                <th className='responsive-table__cell--large' onClick={sortAll} data-sorted='down' data-sort-name='OpenTime' data-sort-type='date' id='OpenTime'>
                                    {t('InstrumetInfo.Details.OpenTime')}
                                    {/* {oldFirst ? <i className='fa fa-arrow-down' /> : <i className='fa fa-arrow-up' />} */}
                                </th>
                            ) : (
                                <th className='responsive-table__cell--large' onClick={sortAll} data-sort-name='OpenTime' data-sort-type='date'>
                                    {t('InstrumetInfo.Details.OpenTime')}
                                </th>
                            )}
                            {closedOrders && (
                                <th className='responsive-table__cell--price' onClick={sortAll} data-sort-name='ClosePrice' data-sort-type='number'>
                                    {t('InstrumetInfo.Details.CloseRate')}
                                </th>
                            )}
                            {closedOrders && (
                                // <th className='responsive-table__cell--price' onClick={handleSortPositions}>
                                <th className='responsive-table__cell--price' onClick={sortAll} data-sort-name='CloseTime' data-sort-type='date' data-sorted='down' id='CloseTime'>
                                    {t('InstrumetInfo.Details.CloseTime')}
                                    {/* {oldFirst ? <i className='fa fa-arrow-down' /> : <i className='fa fa-arrow-up' />} */}
                                </th>
                            )}
                            {!closedOrders && (
                                <th className='responsive-table__cell--price' onClick={sortAll} data-sort-name='StopLoss' data-sort-type='number'>
                                    {t('InstrumetInfo.Details.StopLoss')}
                                </th>
                            )}
                            {!closedOrders && (
                                <th className='responsive-table__cell--price' onClick={sortAll} data-sort-name='TakeProfit' data-sort-type='number'>
                                    {t('InstrumetInfo.Details.TakeProfit')}
                                </th>
                            )}
                            {!pendingOrders && (
                                <Fragment>
                                    <th className='responsive-table__cell--price' onClick={sortAll} data-sort-name='PL' data-sort-type='number'>
                                        {t('InstrumetInfo.Details.PL')}
                                    </th>
                                    <th onClick={sortAll} data-sort-name='OriginatingTraderNickname' data-sort-type='string'>{t('InstrumetInfo.Details.CopiedFrom')}</th>
                                </Fragment>
                            )}
                            {/* {pendingOrders && <th className='responsive-table__cell--large'>{t('InstrumetInfo.Details.Expiry')}</th>} */}
                        </tr>
                    </thead>
                    <tbody>
                        {sortedPositions && sortedPositions.map((item) => {
                            return (
                                <tr className='responsive-table__row' key={item?.OpenTime + item?.OpenPrice}>
                                    {/* <td className='responsive-table__cell--small'>
                                        <Flag name={symbolName} />
                                    </td> */}
                                    <td>{item?.InstrumentName}</td>
                                    {pendingOrders
                                        ? <td className='responsive-table__cell--small'>{item && formatPendingText(item)}</td>
                                        : <td className='responsive-table__cell--small'>{item?.TradeActionType === 0 ? 'Buy' : 'Sell'}</td>
                                    }
                                    <td className='responsive-table__cell--price'>{pendingOrders ? format2Digits(item?.Price, item?.DecimalPrecision) : item?.OpenPrice}</td>
                                    <td className='responsive-table__cell--large'>{formatDate(new Date(item?.OpenTime))}</td>
                                    {closedOrders && <td className='responsive-table__cell--price'>{item?.ClosePrice}</td>}
                                    {closedOrders && <td className='responsive-table__cell--large'>{item?.CloseTime}</td>}
                                    {!closedOrders && (
                                        <td className='responsive-table__cell--price'>
                                            <div className='stop-loss__button'>{item?.StopLoss || '-'}</div>
                                        </td>
                                    )}
                                    {!closedOrders && (
                                        <td className='responsive-table__cell--price'>
                                            <div className='stop-loss__button'>{item?.TakeProfit || '-'}</div>
                                        </td>
                                    )}
                                    {!pendingOrders && (
                                        <Fragment>
                                            <td><FormatPercentFloat value={item?.PL} /></td>
                                            <td className='responsive-table__cell--price'>
                                                <span className='copied-from' onClick={openProfilePage} data-name={item?.OriginatingTraderNickname}>
                                                    {item?.OriginatingTraderNickname}
                                                </span>
                                            </td>
                                        </Fragment>
                                    )}
                                    {/* {pendingOrders && (
                                        <td className='responsive-table__cell--large'>
                                            {(item?.Experation && item?.Experation !== '1970-01-01T00:00:00Z') ? formatDate(new Date(item?.Experation)) : '-'}
                                        </td>
                                    )} */}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            ) : (
                <div className='positions-tab__nothing-found'>{t('MarketRates.NothingFound')}</div>
            )}
        </Fragment>
    );
};

// ProfilePageOpenPositions.propTypes = {};

export default ProfilePageOpenPositions;
