import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
// import PropTypes from 'prop-types';

import { CommandFactory } from '../../background/commands/CommandFactory';
import roundToPrecision from '../utilsUI/roundToPrecision';
import CountryFlagByCode from '../Flag/CountryFlagByCode';
import Button from '../primitives/Button';
import LoadSpinner from '../primitives/LoadSpinner';

import { PROFICIENCY_LEVEL } from './ProfilePage';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';

export const COMMUNITY_TABS = {
    'WATCHERS': {index:'1',translation:'Socail.Profile.Watchers'},
    'WATCHING': {index:'0',translation:'Socail.Profile.Watching'},
    'COPIERS': {index:'3',translation:'Socail.Profile.Copiers'},
    'COPYING': {index:'2',translation:'Social.CopyingText'}
};


const CommunityTab = ({ nickname, getProfileData, generalColor }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [kind, setKind] = useState(1);
    const [requestResult, setRequestResult] = useState(null);
    const [userCount, setUserCount] = useState(null);
    const { t } = useTranslation();
    const { profileCommands: { getFriends } } = useSelector((state) => { return (state.account.socialv1); }, shallowEqual);
    const { MainColor: { ActiveText, InactiveText } } = useSelector((state) => (state.brand.theme), shallowEqual);
    const requestFriends = async (kindValue) => {
        setIsLoading(true);
        const userInput = { nickname, kind: kindValue, length: 100 };

        const command = CommandFactory.createCommand(getFriends);

        try {
            setRequestResult(null);
            const res = await command.execute(userInput);

            if (res.ok === false) {
                // setRequestResult({ result: res.statusText });
            }
            if (res.isSuccessful === true) {
                return res.payload;
            } else if (res.isSuccessful === false) {
                // setRequestResult(res);
            }
        } catch (err) {
            // setRequestResult(err);
        }

        // setIsLoading(false);

    };

    useEffect(() => {
        SendEventToGoogleAnalytics("Profile:CommunityTabOpened");
        let isSubscribed = true;
        setIsLoading(true);
        requestFriends(1).then((info) => {
            if (isSubscribed) {
                setRequestResult(info);
                if (!userCount) {
                    const quantity = {
                        '0': info?.TraderFollowingDetails?.TraderIsFollowing,
                        '1': info?.TraderFollowingDetails?.FollowTrader,
                        '3': info?.TraderFollowingDetails?.CopyTrader,
                        '2': info?.TraderFollowingDetails?.TraderIsCopying
                    };
                    setUserCount(quantity)
                }
                setIsLoading(false);
            }
        });
        return () => isSubscribed = false;
    }, []);

    const setKindValue = (e) => {
        e.stopPropagation();
        const value = e.target.getAttribute('data-name');
        setKind(parseInt(value, 10));
        requestFriends(parseInt(value, 10)).then((info) => {
                setRequestResult(info);
                setIsLoading(false);
        });
    };

    // const userCount = {
    //     '0': requestResult?.TraderFollowingDetails?.TraderIsFollowing,
    //     '1': requestResult?.TraderFollowingDetails?.FollowTrader,
    //     '3': requestResult?.TraderFollowingDetails?.CopyTrader,
    //     '2': requestResult?.TraderFollowingDetails?.TraderIsCopying
    // };

    const RadioButtonsGroup = Object.entries(COMMUNITY_TABS).map(([key, value]) => {
        const { index, translation } = value;
        const isActive = parseInt(index, 10) === kind;
        return (
            <Button
                text={`${t(translation).toUpperCase()} (${userCount ? userCount[index] : ''})`}
                dataName={index} key={key}
                className={isActive ? 'btn active btn-users' : 'btn btn-users'}
                // style={isActive ? { backgroundColor: ActiveText } : { backgroundColor: '#283B55', color: InactiveText }}
                onClickHandler={setKindValue}
            />
        );
    });

    const handleItemSelect = (e) => {
        const userName = e.target.closest('.profile-page__item').getAttribute('data');
        const avatar = e.target.closest('.profile-page__item').getAttribute('data-avatar');
        const copiers = e.target.closest('.profile-page__item').getAttribute('data-copiers');
        userName && getProfileData({ nickname: userName, avatar, copiers });
        SendEventToGoogleAnalytics("Profile:Community:ProfileClick", {nickname: userName});
        // eslint-disable-next-line no-unused-expressions
        // userName && getProfileData(userName);
    };


    return (
        <div className='community-tab'>
            {/* {!isLoading && ( */}
                <div className='community-tab__type-buttons'>
                    {RadioButtonsGroup}
                </div>
            {/* )} */}
            {requestResult && !isLoading && (
                <div className='community-tab__table-wrapper'>
                    <table className='portfolio-table'>
                        <thead>
                            <tr style={generalColor}>
                                <th>{t('Socials.Name')}</th>
                                {/* Master`s P/L */}
                                <th>{t('ProfilePage.Masters.PNL')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {requestResult?.UserFriends?.map((item) => {
                                return (
                                    <tr key={item?.Nickname}>
                                        <td>
                                            <div className='profile-page__item' onClick={handleItemSelect} data={item?.Nickname} data-avatar={item?.Avatar} data-copiers={item?.Copiers}>
                                                <div className={`avatar ${item?.Avatar.toLowerCase()}`}>
                                                    <div className='country__flag--small'>
                                                        {item?.Country && CountryFlagByCode(item?.Country)}
                                                    </div>
                                                    <div className={`proficiency proficiency--${PROFICIENCY_LEVEL[item?.ProficiencyLevel].toLowerCase()}`} />
                                                </div>
                                                {item?.Nickname}
                                            </div>
                                        </td>
                                        <td>
                                            <strong className={item.CumulativePnl >= 0 ? 'green' : 'red'}>
                                                {roundToPrecision(item.CumulativePnl * 100, 2)}
                                                %
                                            </strong>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}
            {isLoading && <LoadSpinner />}
        </div>
    );
};

// CommunityTab.propTypes = {};

export default CommunityTab;
