import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
// import PropTypes from 'prop-types';

import { CommandFactory } from '../../background/commands/CommandFactory';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';
import { QUESTIONER_MODE_EXTENRAL } from '../../background/socialv1/socialv1Reducer';
import ModalWrapper from '../primitives/ModalWrapper';

import ProfilePage from './ProfilePage';


const ProfilePageWrapper = ({
    selectedTrader, setSelectedTrader, toggleExplorePage, openCopyTab, setOpenCopyTab, setCloseProfileModal, setQuestionnaire, setJoinPopup, open
}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [userProfile, setUserProfile] = useState(null);
    const [portfolio, setPortfolio] = useState(null);
    const [yearPnl, setYearPnl] = useState(null);
    const [shouldOpenCopyTab, setShouldOpenCopyTab] = useState(false);
    const {
        socialAccount: { questionerMode, queryRiskScoreCMD, externalRiskScore },
        profileCommands: { getUserProfilePage, getPortfolioBreakdown, getGetPlAndStats }, userDetails
    } = useSelector((state) => { return (state.account.socialv1); }, shallowEqual);
    const avatar = selectedTrader?.avatar || '';
    const copiers = selectedTrader?.copiers || '';
    const nickname = selectedTrader?.nickname || '';
    const joinDate = selectedTrader?.joinDate || '';
    // const shouldOpenCopy = selectedTrader?.shouldOpenCopy || false;
    const { name } = useSelector((state) => { return (state.account.accountInfo.userDetails); }, shallowEqual);
    const isSameUser = userDetails?.nickname ? selectedTrader?.nickname?.toLowerCase() === userDetails?.nickname.replace(/\s/g, '').toLowerCase() : false;

    const getProfileData = async (nicknameValue) => {
        const userInput = { nickname: nicknameValue, FolowersMax: 4, followingMax: 4 };

        const command = CommandFactory.createCommand(getUserProfilePage);

        try {
            const res = await command.execute(userInput);
            if (res.ok === false) {
                setError({ result: res.statusText });
            }
            if (res.isSuccessful === true) {
                setUserProfile(res.payload);
            } else if (res.isSuccessful === false) {
                setError(res);
            }
        } catch (err) {
            setError(err);
        }
    };

    const requestPortfolio = async () => {
        // setIsLoading(true);
        const userInput = { timeframe: 3, nickname };

        const command = CommandFactory.createCommand(getPortfolioBreakdown);

        try {
            setPortfolio(null);
            const res = await command.execute(userInput);

            if (res.ok === false) {
                setPortfolio({ result: res.statusText });
            }
            if (res.isSuccessful === true) {
                setPortfolio(res.payload);
            } else if (res.isSuccessful === false) {
                setPortfolio(res);
            }
        } catch (err) {
            setPortfolio(err);
        }

        // setIsLoading(false);
    };

    useEffect(() => {
        if (selectedTrader) {
            setIsLoading(true);
            let pnlAndStats = async () => {};
            let queryRiskScorePromise = async () => {};
            if (questionerMode === QUESTIONER_MODE_EXTENRAL) {
                pnlAndStats = async () => {
                    try {
                        const cmd = CommandFactory.createCommand(getGetPlAndStats);
                        const resp = await cmd.execute({ nickname, timeframe: 4 });
                        if (resp.isSuccessful) {
                            setYearPnl(resp.payload);
                        }
                    } catch (exception) {}
                };

                if (!externalRiskScore) {
                    queryRiskScorePromise = async () => {
                        try {
                            await CommandFactory.createCommand(queryRiskScoreCMD).execute();
                        } catch (exception) {}
                    };
                }
            }
            Promise.allSettled([getProfileData(nickname), requestPortfolio(), pnlAndStats(), queryRiskScorePromise()])
                .then(setIsLoading(false));
        }
        // if (selectedTrader?.shouldOpenCopy) {
        if (openCopyTab) {
            setShouldOpenCopyTab(true);
        } else {
            setShouldOpenCopyTab(false);
        }
    }, [selectedTrader]);

    useEffect(() => {
        SendEventToGoogleAnalytics('ProfilePageOpened');
    }, []);

    const closeUserModal = () => {
        setSelectedTrader(null);
        setCloseProfileModal();
    };

    const handleCloseCopyTab = () => {
        setOpenCopyTab(false);
        // setShouldOpenCopyTab(false);
    };

    const handleItemSelect = (item) => {
        setSelectedTrader(item);
    };


    return (
        <ModalWrapper
            open={open}
            handleClose={closeUserModal}
            childClassName='user-page'
        >
            {selectedTrader && (
                <ProfilePage
                    user={userProfile}
                    yearPnl={yearPnl}
                    setSelectedTrader={handleItemSelect}
                    toggleExplorePage={toggleExplorePage}
                    clodeUserModal={closeUserModal}
                    openCopyTab={openCopyTab}
                    handleCloseCopyTab={handleCloseCopyTab}
                    isLoadingProfile={isLoading}
                    userName={selectedTrader?.nickname}
                    avatar={avatar}
                    copiers={copiers}
                    joinDate={joinDate}
                    portfolio={portfolio}
                    isSameUser={isSameUser}
                    getProfileData={getProfileData}
                    setOpenCopyTab={setOpenCopyTab}
                    setQuestionnaire={setQuestionnaire}
                    setJoinPopup={setJoinPopup}
                />
            )}
        </ModalWrapper>
    );
};

// ProfilePageWrapper.propTypes = {};

export default ProfilePageWrapper;
