/* eslint-disable complexity */
import React, { useState, Fragment } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import DepositButton from './DepositButton';
import Button from '../primitives/Button';
// import Clock from '../primitives/Clock';
import ModalWrapper from '../primitives/ModalWrapper';
import CalendarIcon from '../icons/CalendarIcon';
import EducationIcon from '../icons/EducationIcon';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';
import ExpandedSettings from './ExpandedSettings';
import CalendarFrame from './CalendarFrame';
import EducationFrame from './EducationFrame';


const TopPanelActions = ({ handleUserSelect, setJoinPopup, shouldShowTriangle }) => {
    const [isMenuExpanded, setIsMenuExpanded] = useState(false);
    const [isCalendarExpanded, setIsCalendarExpanded] = useState(false);
    const [isEduExpanded, setIsEduExpanded] = useState(false);
    const { featuresData, userDetails, rightPanelSettings: { socialv1: { isVisible } } } = useSelector((state) => { return (state.account.accountInfo); }, shallowEqual);
    const { userDetails: { nickname, avatar }, isEnabled, isJoined } = useSelector((state) => { return (state.account.socialv1); }, shallowEqual);
    const { Deposit: { ButtonFirst, ButtonSecond }, Text: { GeneralColor } } = useSelector((state) => state.brand.theme);
    // const { environments } = useSelector((state) => { return (state.environments); }, shallowEqual);
    const {
        deposit,
        calendar,
        education,
        trends
    } = featuresData;
    const { name, tpUserId } = userDetails;
    const displayName = `${name} (${tpUserId})`;
    // const envName = environments[Object.keys(environments)[0]].displayName;
    const toggleSettings = () => {
        setIsMenuExpanded((prevState) => { return !prevState; });
    };
    const toggleCalendar = () => {
        if (!isCalendarExpanded) {
            SendEventToGoogleAnalytics('CalendarClick');
        }
        setIsCalendarExpanded((prevState) => { return !prevState; });
    };
    const toggleEducation = () => {
        if (!isEduExpanded) {
            SendEventToGoogleAnalytics('EducationClick');
        }
        setIsEduExpanded((prevState) => { return !prevState; });
    };

    const openProfile = () => {
        handleUserSelect({ nickname, avatar, shoudOpenCopy: false });
    };

    const openJoin = () => {
        setJoinPopup((prevState) => !prevState);
    };


    return (
        <Fragment>
            <DepositButton />
            {calendar?.isEnabled && <Button className='btn btn-calendar' iconStartSVG={<CalendarIcon fill={GeneralColor} />} onClickHandler={toggleCalendar} />}
            {education?.isEnabled && <Button className='btn btn-education' iconStartSVG={<EducationIcon fill={GeneralColor} />} onClickHandler={toggleEducation} />}
            {isVisible && isEnabled && avatar && isJoined && (
                <div className={`avatar ${avatar.toLowerCase()}`} onClick={openProfile} role='button' tabIndex={-1} aria-label='open your profile' />
            )}
            {/* <div className='avatar' /> */}
            {/* <div>{envName}</div> */}
            {/* <Clock /> */}
            <div className='header__menu-container'>
                <Button className='fa fa-settings btn' onClickHandler={toggleSettings} />
                {isMenuExpanded && <ExpandedSettings name={displayName} onClose={toggleSettings} handleClick={toggleSettings} />}
            </div>
            {isCalendarExpanded && (
                <ModalWrapper
                    open={isCalendarExpanded}
                    heading='Economic Calendar'
                    handleClose={toggleCalendar}
                    childClassName='modal-window--info'
                    contentClassName='content'
                >
                    <CalendarFrame />
                </ModalWrapper>
            )}
            {isEduExpanded && (
                <ModalWrapper
                    open={isEduExpanded}
                    heading='Trader Tools & Education'
                    handleClose={toggleEducation}
                    childClassName='modal-window--info'
                    contentClassName='content'
                >
                    <EducationFrame />
                </ModalWrapper>
            )}
        </Fragment>
    );
};

export default TopPanelActions;
