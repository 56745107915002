import React, {useCallback} from 'react';
import PropTypes from 'prop-types';

import Input from '@material-ui/core/Input';

const MobileInputSearch = props => {
	const {
		value,
		onChange,
		endAdornment,
		style,
		placeholder,
		name,
		className,
		id,
		autoComplete,
	} = props;

	const handleOnChange = useCallback(props => {
		onChange && onChange(props);
	}, [onChange]);

	return (
			<Input
					lang='en'
					id={id}
					placeholder={placeholder}
					name={name}
					className={className}
					value={value}
					onChange={handleOnChange}
					endAdornment={endAdornment}
					style={{...style}}
					autoComplete={autoComplete ? 'on' : 'off'}
			/>
	);
};

MobileInputSearch.defaultProps = {
	endAdornment: <></>,
	style: {},
	autoComplete: false,
	placeholder: '',
	value: '',
	name: '',
	className: '',
	id: '',
};

MobileInputSearch.propTypes = {
	placeholder: PropTypes.string,
	value: PropTypes.string,
	name: PropTypes.string,
	className: PropTypes.string,
	id: PropTypes.string,
	autoComplete: PropTypes.bool,
	style: PropTypes.object,
	endAdornment: PropTypes.node,
};

export default MobileInputSearch;
