import React from 'react';
import { useTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
// import PropTypes from 'prop-types';

import OuterClick from '../primitives/OuterClick';
import { INDICATORS } from '../../shared/Charts/indicators';

import './IndicatorsList.css';


const IndicatorsList = ({ selectIndicator, clearIndicators, systemColor }) => {
    const { t } = useTranslation();
    return (
        <div className='indicators-list__container' id='indicators' role='listbox' tabIndex={-1}>
            <MenuItem key='clear-indicators' style={{ color: systemColor }} onClick={clearIndicators} role='option' tabIndex={0} aria-selected='true'>
                {t('Select.Indicator.Clear')}
            </MenuItem>
            {INDICATORS.map((option) => {
                return (
                    <MenuItem key={option.name} data-value={option.name} style={{ color: systemColor }} onClick={selectIndicator} role='option' tabIndex={0}>
                        {t(`Select.Indicator${option.name}`)}
                    </MenuItem>
                );
            })}
        </div>
    );
};

// IndicatorsList.propTypes = {};

export default OuterClick(IndicatorsList);
