import React, {useRef} from 'react';

import useSymbol from '../../context/contextHook/useSymbol';

import MobileSwiper from '../../components/MobileSwiper';
import MobilePopUpHeader from '../../components/MobilePopUp/MobilePopUpHeader';
import MobileTradeHeader from './MobileTradeHeader';
import MobileTradeSection from './MobileTradeSection/MobileTradeSection';

const MobileTrade = ({onClose}) => {

	const touchRef = useRef(null);

	const {selectedSymbol} = useSymbol();

	return (
		<MobileSwiper
			touchRef={touchRef}
			type='trade'
			className="mobile-trade"
		>
			{/*HEADER*/}
			<MobilePopUpHeader>
				<MobileTradeHeader
					reference={touchRef}
					selectedSymbol={selectedSymbol}
					// onClose={onClose}
				/>
			</MobilePopUpHeader>

			{/*MAIN SECTION*/}
			<div className="mobile-trade__main">
				<MobileTradeSection/>
				{/*	<MobileTradeMain*/}
				{/*		selectedSymbol={selectedSymbol}*/}
				{/*	/>*/}
			</div>
		</MobileSwiper>
	);
};

export default MobileTrade;
