import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
// import PropTypes from 'prop-types';

const FormatProfitSwapCommision = ({ tickValue, positive, negative, neutral }) => {
    const { symbol } = useSelector((state) => { return (state.account.accountInfo.currency); }, shallowEqual);
    let pnlClass;
    let style;
    const isNegative = tickValue < 0;
    if (tickValue === 0) {
        pnlClass = null;
        style = { color: neutral };
    } else if (isNegative) {
        pnlClass = 'red';
        style = { color: negative };
    } else {
        pnlClass = 'green';
        style = { color: positive };
    }
    const valueToRender = isNegative ? Math.abs(Math.round((tickValue + Number.EPSILON) * 100) / 100) : Math.round((tickValue + Number.EPSILON) * 100) / 100;

    return (
        <strong className={pnlClass} style={style}>
            {isNegative && '-'}
            {symbol}
            {valueToRender}
        </strong>
    );
};

// FormatProfitSwapCommision.propTypes = {};

export default FormatProfitSwapCommision;
