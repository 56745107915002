import React ,{ Component }from 'react';
import TreeItem from '@material-ui/lab/TreeItem';
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from 'react-redux';
import CommandFactory from "../background/commands/CommandFactory"
import PubsubSubscription from "../background/repositories/PubsubSubscription"
import Button from '@material-ui/core/Button';
import { getPathObject } from '../background/StoreUtils/getPathObject';
import RepositoryValueView from './RepositoryValueView'

class StoreTreeItemClass extends Component
{  
  isExpendable(value)
  {
    if (value !== null && value !== undefined)
    {
      if (typeof value === 'object' || Array.isArray(value))
      {
        return true;
      }

      return false;
    }
  }

  getCurrrentLevelNodes()
  {
    let expandableItemsArray = []
    let simpleItemsArray = []
    let commandsArray = []
    let nodeValue = this.props.nodeValue;
    
    if (typeof nodeValue === 'object' || Array.isArray(nodeValue))
    {
      let nodeProperties = Object.keys(nodeValue).sort();
      for (let i = 0;i<nodeProperties.length;i++)
      {
        let key = nodeProperties[i];
        let nodekey = this.props.parentNodeId + "/" + key;
        let value =nodeValue[key];
        if (this.isExpendable(value)){
          if (Array.isArray(value))
          {
            expandableItemsArray.push(<TreeItem key={nodekey} nodeId={nodekey} label={<Tooltip placement="bottom-start" enterDelay={1500} title={nodekey}><div style={{display:'flex' ,alignItems:'flex-start'}}><div style={{marginRight:10}}>{key}</div><div style={{fontWeight:'900'}}>[{value.length}]</div></div></Tooltip>}>
              <StoreTreeItem setCmdDialog={this.props.setCmdDialog} setSocialStream={this.props.setSocialStream} parentNodeId={nodekey} path={[...this.props.path,key]}></StoreTreeItem>
            </TreeItem>)
          }
          else
          {
            if (PubsubSubscription.isSubscriptionObj(value))
            {
              expandableItemsArray.push(<TreeItem key={nodekey} nodeId={nodekey} label={<div style={{display:'flex' ,alignItems:'flex-start'}}><div style={{marginRight:10}}>{key}</div><div style={{fontWeight:'900'}}>{<RepositoryValueView repObj={value}/>}</div></div>}></TreeItem>);
            }
            else if (CommandFactory.isCommand(value))
            {
              let local =this;
              expandableItemsArray.push(<TreeItem key={nodekey} nodeId={nodekey} label={
                <div style={{display:'flex' ,alignItems:'flex-start'}}><div style={{marginRight:10}}>{key}</div><Button onClick={()=>{
                  local.props.setCmdDialog({open:true,cmd:CommandFactory.createCommand(value),title:nodekey});
                }}
                size="small" variant="outlined">Open</Button></div>}>              
                </TreeItem>)
            }
            else if (value.cmd === "TradesStrem")
            {
              let local =this;
              expandableItemsArray.push(<TreeItem key={nodekey} nodeId={nodekey} label={
                <div style={{display:'flex' ,alignItems:'flex-start'}}><div style={{marginRight:10}}>{key}</div><Button onClick={()=>{
                  local.props.setSocialStream({open:true});
                }}
                size="small" variant="outlined">View</Button></div>}>              
                </TreeItem>)
            }
            else
            {
              expandableItemsArray.push(<TreeItem key={nodekey} nodeId={nodekey} label={(<Tooltip placement="bottom-start" enterDelay={1500} title={nodekey}><div>{key}</div></Tooltip>)}>
              <StoreTreeItem setCmdDialog={this.props.setCmdDialog} parentNodeId={nodekey} setSocialStream={this.props.setSocialStream} path={[...this.props.path,key]}></StoreTreeItem>
            </TreeItem>)
            }
          }
        }
        else{
          if (value !== null && value !== undefined)
          {
            simpleItemsArray.push(<TreeItem key={nodekey} nodeId={nodekey} label={<div style={{display:'flex' ,alignItems:'flex-start'}}><div style={{marginRight:10}}>{key}</div><div style={{fontWeight:'900'}}>{value.toString()}</div></div>}></TreeItem>)
          }
          else
          {
            simpleItemsArray.push(<TreeItem key={nodekey} nodeId={nodekey} label={<div style={{display:'flex' ,alignItems:'flex-start'}}><div style={{marginRight:10}}>{key}</div><div></div></div>}></TreeItem>)
          }
        }
      }
    }
    else
    {
      if (nodeValue !== null && nodeValue !== undefined)
      {
        let nodeKey = this.props.parentNodeId + "|" + nodeValue;
        simpleItemsArray.push(<TreeItem key={nodeKey} nodeId={nodeKey} label={nodeValue}></TreeItem>)
      }
    }

    return expandableItemsArray.concat(commandsArray).concat(simpleItemsArray);
  }

  render()
  {
    if (this.props.nodeValue)
    {
      return this.getCurrrentLevelNodes()
    }
  }
}

function mapStateToProps(state,ownProps) {
  if (!Array.isArray(ownProps.path))
  {
    return { }
  }
  else
  {
    let dataObject =getPathObject(state,ownProps.path);
    if (dataObject)
    {
      return {nodeValue: dataObject}
    }
  }
  return { }
}

let storeTreeItemInstance = connect(mapStateToProps)(StoreTreeItemClass);
export const StoreTreeItem = storeTreeItemInstance;