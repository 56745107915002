import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import 'frappe-charts/dist/frappe-charts.min.css';
// import PropTypes from 'prop-types';

import { CommandFactory } from '../../background/commands/CommandFactory';
import Graph from '../primitives/Graph';
import LoadSpinner from '../primitives/LoadSpinner';
import Switch from '../primitives/Switch';
import LinearProgressBarWithLabel from '../primitives/LinearProgressBarWithLabel';
import roundToPrecision from '../utilsUI/roundToPrecision';
import useWindowSize from '../utilsUI/useWindowSize';

import { FormatPercentFloat } from './ProfileTabDetails';
import ProfilePageRadioGroup from './ProfilePageRadioGroup';
import { sortColumns } from './ProfilePageOpenPositions';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';

export const TIMEFRAMES = {
    1: '1m',
    2: '3m',
    3: '6m',
    4: '1y'
};


const PortfolioTab = ({ nickname, generalColor, activeColor }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [showPie, setShowPie] = useState(true);
    const [requestResult, setRequestResult] = useState(null);
    const [prevColumn, setPrevColumn] = useState({ columnName: 'AllocationPercentage', direction: false });
    const [sortedPositions, setSortedPositions] = useState(null);
    const [timeframe, setTimeframe] = useState(1);
    const [width, height] = useWindowSize();
    const [data, setData] = useState(null);
    const { profileCommands: { getPortfolioBreakdown } } = useSelector((state) => { return (state.account.socialv1); }, shallowEqual);
    const { t } = useTranslation();
    const requestPortfolio = async (time) => {
        setIsLoading(true);
        const userInput = { timeframe: time || timeframe, nickname };

        const command = CommandFactory.createCommand(getPortfolioBreakdown);

        try {
            setRequestResult(null);
            const res = await command.execute(userInput);

            
            if (res.isSuccessful === true) {
                return res.payload;
            }
        } catch (err) {
            // setRequestResult(err);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        let isSubscribed = true;
        setIsLoading(true);
        requestPortfolio().then((portfolio) => {
            if (isSubscribed) {
                setRequestResult(portfolio);
                setIsLoading(false);
            }
        });
        return () => isSubscribed = false;
    }, [nickname]);

    useEffect(() => {
        const labels = [];
        const values = [];
        const pl = [];
        if (requestResult?.length) {
            requestResult.forEach((item) => {
                labels.push(item.InstrumentName);
                values.push(roundToPrecision(item.AllocationPercentage * 100, 2));
                pl.push(roundToPrecision(item.PLPercentage * 100, 2));
            });
        }
        setData({ labels, values, pl });
        requestResult?.length > 0 && setSortedPositions(sortColumns(requestResult, 'AllocationPercentage', 'number', false));
        setPrevColumn({ columnName: 'AllocationPercentage', direction: false });
    }, [requestResult]);


    useEffect(() => {
      SendEventToGoogleAnalytics("Profile:PortfolioTabOpened");
  }, []);

    const sortAll = (e) => {
        const element = e.target;
        const sortName = element.getAttribute('data-sort-name');
        const sortType = element.getAttribute('data-sort-type');
        const classValue = element.getAttribute('data-sorted');
        const direction = prevColumn.columnName === sortName ? !prevColumn.direction : true;
        element.setAttribute('id', sortName);
        if (classValue) {
            if (classValue === 'down') {
                element.setAttribute('data-sorted', 'up');
            } else {
                element.setAttribute('data-sorted', 'down');
            }
        } else {
            element.setAttribute('data-sorted', 'up');
        }
        if (prevColumn && sortName !== prevColumn.columnName) {
            document.getElementById(prevColumn.columnName).setAttribute('data-sorted', '');
        }
        // Array.prototype.map.call(element.parentNode.children, el => console.log('el', el));

        setSortedPositions(sortColumns(requestResult, sortName, sortType, direction));

        if (sortName === prevColumn.columnName) {
            setPrevColumn((prevState) => ({ columnName: sortName, direction: !prevState.direction }));
        } else {
            setPrevColumn({ columnName: sortName, direction: true });
        }
    };

    // useEffect(() => {
    //     setSortedPositions(sortColumns(requestResult, 'Percent', 'number', false));
    //     setPrevColumn({ columnName: 'Percent', direction: false });
    // }, []);

    const setTimeframeValue = (value) => {
        setTimeframe(parseInt(value, 10));
        requestPortfolio(parseInt(value, 10)).then((portfolio) => {
            setRequestResult(portfolio);
            setIsLoading(false);
        });
    };

    const togglePortfolioView = () => {
        setShowPie((prevState) => !prevState);
    };


    return (
        <div>
            <Switch
                isSelected={showPie}
                handleSwitch={togglePortfolioView}
                name='isShowPie'
                offText={t('ProfilePage.Table')}
                onText={t('ProfilePage.Pie')}
                generalColor={generalColor}
                activeColor={activeColor}
            />
            <div className='profile-page__buttons-group'>
                <ProfilePageRadioGroup groupList={TIMEFRAMES} selectedItem={timeframe} handleSelectItem={setTimeframeValue} />
            </div>
            {showPie ? (
                <div className='pie__chart'>
                    <div className='pie-chart__container'>
                        {/* {(data && data.labels.length > 0) && !isLoading && ( */}
                        {(data && data.labels.length > 0) && (
                            <Graph
                                type='pie'
                                data={{
                                    labels: data?.labels || [],
                                    datasets: [{
                                        values: data?.values || []
                                    }],
                                    pl: data?.pl || []
                                }}
                                height={width < 1200 ? 400 : 520}
                                onSelect={false}
                                heatline
                                region_fill
                                isNavigable={false}
                                valuesOverPoints
                                isLoading={isLoading}
                                maxSlices={30}
                                maxLegendPoints={30}
                                // truncateLegends={false}
                            />
                        )}
                    </div>
                </div>
            ) : (
                sortedPositions?.length && (
                    <div className='community-tab__table-wrapper'>
                        <table className='portfolio-table'>
                            <thead>
                                <tr style={generalColor}>
                                    <th onClick={sortAll} data-sort-name='InstrumentName' data-sort-type='string'>{t('InstrumetInfo.Details.Instrument')}</th>
                                    {/* % of portfolio */}
                                    <th onClick={sortAll} data-sort-name='AllocationPercentage' data-sort-type='number' data-sorted='down' id='AllocationPercentage'>
                                        {t('ProfilePage.Percent.Portfolio')}
                                    </th>
                                    <th onClick={sortAll} data-sort-name='PLPercentage' data-sort-type='number'>{t('Socials.PNL')}</th>
                                    {/* Winning Positions */}
                                    <th onClick={sortAll} data-sort-name='WinningCount' data-sort-type='number'>{t('ProfilePage.Winning.Positions')}</th>
                                    {/* Losing Positions */}
                                    <th onClick={sortAll} data-sort-name='LosingCount' data-sort-type='number'>{t('ProfilePage.Losing.Positions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedPositions.map((item) => {
                                    return (
                                        <tr key={item.InstrumentName}>
                                            <td>{item.InstrumentName}</td>
                                            <td>
                                                {roundToPrecision(item.AllocationPercentage * 100, 2)}
                                                %
                                                <LinearProgressBarWithLabel value={roundToPrecision(item.AllocationPercentage * 10, 2)} hideLabel />
                                            </td>
                                            {/* <td>{roundToPrecision(item.PLPercentage * 100, 2)}</td> */}
                                            <td>{FormatPercentFloat({ value: item.PLPercentage })}</td>
                                            <td>{item.WinningCount}</td>
                                            <td>{item.LosingCount}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                )
            )}
            {isLoading && <LoadSpinner />}
        </div>
    );
};

// PortfolioTab.propTypes = {};

export default PortfolioTab;
