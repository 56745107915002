import React from 'react';
// import PropTypes from 'prop-types';

const SignalsIcon = ({ fill }) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='29' height='20' viewBox='0 0 29 20' fill='none'>
            <g opacity='0.6'>
                <path d='M14.3987 0C16.8153 0 18.7812 2.00956 18.7812 4.47997C18.7812 6.95038 16.8154 8.95994 14.3987 8.95994C11.9819 8.95994 10.0161 6.95032 10.0161 4.47997C10.0161 2.00962 11.9819 0 14.3987 0ZM14.3987 7.67991C16.1241 7.67991 17.529 6.24377 17.529 4.47997C17.529 2.71617 16.1241 1.28002 14.3987 1.28002C12.6732 1.28002 11.2683 2.71617 11.2683 4.47997C11.2683 6.24377 12.6731 7.67991 14.3987 7.67991Z' fill={fill} />
                <path d='M5.63475 5.11914C7.36021 5.11914 8.76514 6.55528 8.76514 8.31908C8.76514 10.0829 7.36021 11.519 5.63475 11.519C3.90929 11.519 2.50436 10.0829 2.50436 8.31908C2.50436 6.55528 3.90929 5.11914 5.63475 5.11914ZM5.63475 10.2391C6.67031 10.2391 7.51299 9.37765 7.51299 8.31908C7.51299 7.26052 6.67031 6.39911 5.63475 6.39911C4.59919 6.39911 3.75651 7.26052 3.75651 8.31908C3.75651 9.37765 4.59925 10.2391 5.63475 10.2391Z' fill={fill} />
                <path d='M14.4 10.2402C18.8878 10.2402 22.5391 13.9727 22.5391 18.5602C22.5391 18.9134 22.2586 19.2002 21.913 19.2002C21.5674 19.2002 21.2869 18.9134 21.2869 18.5602C21.2869 14.678 18.1978 11.5203 14.4 11.5203C10.6022 11.5203 7.51311 14.678 7.51311 18.5602C7.51311 18.9134 7.2326 19.2002 6.88701 19.2002C6.54141 19.2002 6.26091 18.9134 6.26091 18.5602C6.26091 13.9727 9.91221 10.2402 14.4 10.2402Z' fill={fill} />
                <path d='M5.63581 12.8008C6.66632 12.8008 7.67559 13.0888 8.5546 13.6328C8.84884 13.8171 8.94405 14.21 8.76497 14.5121C8.58342 14.8142 8.20026 14.9102 7.90473 14.7272C7.22234 14.3035 6.43847 14.0807 5.63581 14.0807C3.21916 14.0807 1.25322 16.0903 1.25322 18.5607C1.25322 18.914 0.972718 19.2007 0.627121 19.2007C0.281524 19.2007 0.00102043 18.914 0.00102043 18.5607C0.00107861 15.3851 2.5292 12.8008 5.63581 12.8008Z' fill={fill} />
                <path d='M28.4888 10.6722L28.4885 10.6623C28.4829 10.5393 28.4792 10.4092 28.4769 10.2645C28.4665 9.55873 27.9054 8.97494 27.1996 8.93549C25.7279 8.85336 24.5894 8.37347 23.6166 7.42532L23.6083 7.41741C23.2911 7.12649 22.8124 7.12649 22.4951 7.41741L22.4868 7.42532C21.514 8.37347 20.3755 8.85336 18.9038 8.93558C18.198 8.97494 17.6369 9.55873 17.6265 10.2646C17.6243 10.4083 17.6205 10.5384 17.6149 10.6623L17.6144 10.6854C17.5857 12.1861 17.5502 14.0538 18.175 15.749C18.5186 16.6812 19.0389 17.4916 19.7214 18.1577C20.4987 18.9163 21.5169 19.5186 22.7476 19.9477C22.7876 19.9616 22.8292 19.973 22.8716 19.9815C22.9314 19.9934 22.9915 19.9993 23.0517 19.9993C23.1119 19.9993 23.1721 19.9934 23.2318 19.9815C23.2742 19.973 23.3161 19.9615 23.3563 19.9475C24.5855 19.5176 25.6026 18.9151 26.3793 18.1566C27.0615 17.4902 27.5818 16.6797 27.9257 15.7473C28.5528 14.0469 28.5174 12.1757 28.4888 10.6722Z' fill={fill} />
                <path fillRule='evenodd' clipRule='evenodd' d='M27.1929 11.2489L27.1932 11.2569C27.215 12.4597 27.242 13.9567 26.7633 15.3169C26.5008 16.0629 26.1037 16.7113 25.5829 17.2444C24.9901 17.8512 24.2137 18.3332 23.2755 18.6771C23.2447 18.6884 23.2128 18.6975 23.1804 18.7043C23.1349 18.7138 23.0889 18.7186 23.043 18.7186C22.997 18.7186 22.9511 18.7138 22.9055 18.7043C22.8732 18.6975 22.8414 18.6885 22.8108 18.6773C21.8714 18.334 21.0943 17.8522 20.5009 17.2453C19.9799 16.7124 19.5828 16.0641 19.3205 15.3184C18.9332 14.2169 18.8783 13.0244 18.8831 11.9721C18.8815 11.6664 18.8869 11.3714 18.892 11.0932L18.8924 11.0758C18.8964 10.9829 18.8991 10.8854 18.9006 10.7776C18.9081 10.2482 19.3097 9.81031 19.8147 9.78079C20.8679 9.71912 21.6826 9.3592 22.3787 8.64809L22.3846 8.64216C22.5324 8.5002 22.729 8.4506 22.9109 8.49336C23.1037 8.44953 23.3114 8.50276 23.4679 8.65307L23.4742 8.6594C24.011 9.20774 24.6138 9.56031 25.3311 9.73842C25.4669 9.75822 25.6068 9.77227 25.7512 9.78072C25.8636 9.7873 25.9709 9.81411 26.0698 9.85764C26.1158 9.8615 26.1622 9.86479 26.2091 9.86753C26.7479 9.89909 27.1761 10.3661 27.1841 10.9307C27.1858 11.0465 27.1886 11.1506 27.1929 11.2489Z' fill='#E9EBF3' />
            </g>
        </svg>
    );
};

// SignalsIcon.propTypes = {};

export default SignalsIcon;
