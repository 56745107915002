import React, { useEffect, useState, Fragment } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { CommandFactory } from '../../background/commands/CommandFactory';
import LoadSpinner from '../primitives/LoadSpinner';


const EducationFrame = () => {
    const [educationDynamicUrl, setEducationDynamicUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { education } = useSelector((state) => { return (state.account.accountInfo.featuresData); }, shallowEqual);
    const getEducationUrl = async () => {
        if (CommandFactory.isCommand(education.getEducationUrlCommand)) {
            try {
                const response = await CommandFactory.createCommand(education.getEducationUrlCommand).execute();
                if (!response?.isSuccessful) {
                    if (education?.educationUrl) {
                        setEducationDynamicUrl(education.educationUrl);
                    }
                } else {
                    setEducationDynamicUrl(response?.payload?.educationUrl);
                }
            } catch (err) {
                console.error('error', err);
            }
            setIsLoading(false);
        } else {
            if (education?.educationUrl) {
                setEducationDynamicUrl(education.educationUrl);
            }
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getEducationUrl();
    }, []);

    if (isLoading) {
        return <LoadSpinner />;
    }

    return (
        <Fragment>
            {educationDynamicUrl ? (
                <iframe
                    src={educationDynamicUrl}
                    frameBorder='0'
                    title='Education'
                />
            ) : (
                <div>Unable to reach server</div>
            )}
        </Fragment>
    );
};

export default EducationFrame;
