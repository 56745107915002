const countDecimals = (value) => {
    if ((value % 1) !== 0) {
        return value.toString().split('.')[1]?.length;
    }
    if (value.toString().indexOf('.') > 0) {
        return 1;
    }
    return 0;
};

export default countDecimals;
