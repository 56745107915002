import React, {useCallback, useState} from 'react';
import useSymbol from '../context/contextHook/useSymbol';
import MobileTradingAll
	from '../modules/MobileTrading/MobileTradingAll/MobileTradingAll';

const MobileTradingAllPage = ({handleBackTab}) => {
	const {
		selectedSymbol,
		setSelectedSymbol,
		orderedSymbols,
	} = useSymbol();

	const [searchValue, setSearchValue] = useState('');
	const [symbolGroup, setSymbolGroup] = useState('');

	const onChangeSearch = useCallback(value => {
		if (!value) return setSearchValue('');
		if (searchValue.length < value) return setSearchValue(value.trim());
		const t = setTimeout(() => {
			setSearchValue(value.trim());
			return clearTimeout(t);
		}, 300);
	}, [searchValue, setSearchValue]);

	const onChangeGroup = useCallback(value => {
		setSymbolGroup(value);
	}, [setSymbolGroup]);

	const handleItemSelect = useCallback(symbolObj => {
		if (!symbolObj) return;
		setSelectedSymbol && setSelectedSymbol(symbolObj);
	}, []);

	return (
		<>
			{selectedSymbol && (
				<MobileTradingAll
					searchValue={searchValue}
					symbolGroup={symbolGroup}
					onChangeSearch={onChangeSearch}
					onChangeGroup={onChangeGroup}
					selectedSymbol={selectedSymbol}
					orderedSymbols={orderedSymbols}
					handleItemSelect={handleItemSelect}
					handleBack={handleBackTab}
				/>
			)}
		</>
	);
};

export default MobileTradingAllPage;
