import React from 'react';
import { useTranslation } from 'react-i18next';

// import PropTypes from 'prop-types';

import PubsubSubscription from '../../background/repositories/PubsubSubscription';
import { roundNumberPipDigit } from '../utilsUI/formatWSTick';

import { PreparePNL } from './PNL';


const ExpandedPNL = ({ balance, credit, symbol, margin, subHeaderColor, systemColor }) => {
    const pnlTick = PubsubSubscription.subscribeObj({ key: 'MOVINGPNL', repId: 'ACCOUNT_PNL' });
    const { marginLevel, freeMargin, equity } = pnlTick;
    const { t } = useTranslation();
    const marginLevelData = margin === 0 ? <></> : <>{roundNumberPipDigit(equity/margin * 100, 2)}%</>;

    return (
        <div className='pnl__container pnl__container--expanded' style={systemColor}>
            {/* <div className='pnl-tick__container'> */}
            <div className='pnl__content'>
                <span className='small'>
                    {t('General.OpenPl')}
                    :&nbsp;
                </span>
                <span className='small small-price'>
                    <PreparePNL pnlTick={pnlTick} shouldFormat symbol={symbol} />
                </span>
            </div>
            <div className='pnl__seperator' />
            <div className='pnl__content'>
                <span className='small'>
                    {t('General.Balance')}
                    :&nbsp;
                </span>
                <span className='small small-price'>
                    {symbol}
                    {roundNumberPipDigit(balance, 2)}
                </span>
            </div>
            <div className='pnl__seperator' />
            <div className='pnl__content'>
                <span className='small'>
                    {t('General.Equity')}
                    :&nbsp;
                </span>
                <span className='small small-price'>
                    {symbol}
                    {roundNumberPipDigit(equity, 2)}
                </span>
            </div>
            <div className='pnl__seperator' />
            <div className='pnl__content'>
                <span className='small'>
                    {t('Drawer.UsedMargin')}
                    :&nbsp;
                </span>
                <span className='small small-price'>
                    {symbol}
                    {roundNumberPipDigit(margin, 2)}
                </span>
            </div>
            <div className='pnl__seperator' />
            <div className='pnl__content'>
                <span className='small'>
                    {t('General.FreeMargin')}
                    :&nbsp;
                </span>
                <span className='small small-price'>
                    {symbol}
                    {roundNumberPipDigit(freeMargin, 2)}
                </span>
            </div>
            <div className='pnl__seperator' />
            <div className='pnl__content'>
                <span className='small'>
                    {t('Drawer.MarginLevel')}
                    :&nbsp;
                </span>
                <span className='small small-price'>
                    {marginLevelData}
                </span>
            </div>
        </div>
    );
};

// ExpandedPNL.propTypes = {};

export default ExpandedPNL;
