import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import chartController from '../../../../background/chart/chartController';
import ChartPanel, {
	CHART_TYPES,
	trade4ProToInternal,
} from '../../../../shared/Charts/ChartPanel';

import MobileCarouselSymbol
	from '../../../components/MobileCarousel/MobileCarouselSymbol';
import useWindowSize from '../../../../UI/utilsUI/useWindowSize';
import MobileTradingChartPosition from './MobileTradingChartPosition';
import MobileCarouselSwitch
	from '../../../components/MobileCarousel/MobileCarouselSwitch';
import useTheme from '../../../context/contextHook/useTheme';
import useForceUpdate from '../../../hook/useForceUpdate';

const mainChartController = new chartController();

const MobileTradingChart = props => {
	const {
		selectedSymbol,
		orderedSymbols,
		handleChangeSymbol,
		symbolValue,
		timeFrames,
		timeSelected,
		setTimeSelected,
		handleTimeChange,
	} = props;

	const [width, height] = useWindowSize();
	const {isDarkTheme} = useTheme();
	const forceUpdate = useForceUpdate();

	const [chartType, setChartType] = useState(CHART_TYPES.CANDLES);
	const {Tick: {ChartUp, ChartDown}} = useSelector(state => state.brand.theme);

	mainChartController.setPeriodChangedCallback(period => {
		if (timeSelected.timeKey !== period) {
			Object.entries(trade4ProToInternal).forEach((item) => {
				if (item[1] === period) {
					setTimeSelected({timeKey: item[1], timeValue: item[0]});
				}
			});
		}
	});

	const chartHeight = useMemo(() => {
		return width * 0.7;
	}, [width, height]);

	const customChartOptions = useMemo(() => {
		return {
			// 'colors.background': isDarkTheme ? 'transparent' : '#F5F6FA',
			// 'colors.frameActive': isDarkTheme ? 'transparent' : '#979797',
			'colors.background': 'transparent',
			'colors.frameActive': 'transparent',
			'colors.axisLine': isDarkTheme ? '#13172D' : '#fafafa',
			'colors.candleRiseBorder': isDarkTheme ? ChartUp || '#4B8907' : '#3CC09B',
			'colors.candleFallBorder': isDarkTheme ? ChartDown || '#D0021B' : '#CE4B4B',
			'colors.candleRise': isDarkTheme ? ChartUp || '#4B8907' : '#3CC09B',
			'colors.candleFall': isDarkTheme ? ChartDown || '#D0021B' : '#CE4B4B',
			'colors.barRise': isDarkTheme ? ChartUp || '#4B8907' : '#3CC09B',
			'colors.barFall': isDarkTheme ? ChartDown || '#D0021B' : '#CE4B4B',
		};
	}, [ChartUp, ChartDown, isDarkTheme]);

	const handleUpdateChartStyle = useCallback(() => {
		const chart = mainChartController._chartInstance;
		if (isDarkTheme) {
			chart.setColor('background', 'transparent');
			chart.setColor('axisLine', '#fafafa');
		} else {
			chart.setColor('background', 'transparent');
			chart.setColor('axisLine', '#13172D');
		}
	}, [isDarkTheme]);

	useEffect(() => {
		forceUpdate();
	}, [isDarkTheme]);

	useEffect(() => {
		handleUpdateChartStyle();
	}, [handleUpdateChartStyle]);

	return (
			<div className="mobile-trading-chart">

				<MobileCarouselSymbol
						options={orderedSymbols}
						onChange={handleChangeSymbol}
						value={symbolValue}
				/>

				<div className="mobile-trading-chart__content">
					<div className="mobile-trading-chart__content__chart">
						<div className="mobile-trading-chart__switch">
							<MobileCarouselSwitch
									value={timeSelected.timeKey}
									onChange={handleTimeChange}
									options={timeFrames}
							/>
						</div>

						<div className="mobile-trading-chart__chart">
							<ChartPanel
									chartController={mainChartController}
									activeSymbolId={selectedSymbol?.id}
									period={timeSelected.timeKey}
									chartType={chartType}
									customChartOptions={customChartOptions}
							/>
						</div>
					</div>

					<div className="mobile-trading-chart__content__position">
						<MobileTradingChartPosition
								chartHeight={chartHeight}
						/>
					</div>

				</div>

			</div>
	);
};

export default MobileTradingChart;
