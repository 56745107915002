import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import MobileTransition from '../../../components/MobileTransition';

import {roundNumberPipDigit} from '../../../../UI/utilsUI/formatWSTick';

export const MobileTradeUnitsUp = props => {
	const {
		unitsDropDown = false,
		pipValue = null,
		marginRequirement = null,
	} = props;

	const {t} = useTranslation();

	const {symbol} = useSelector(state => state.account.accountInfo.currency,
		shallowEqual);

	return (
		<MobileTransition
			isOpen={unitsDropDown}
			type="fade"
		>
			<div className='mobile-trade-popup__units'>
					<span>
						{t('Trade.PipValue.Title')}
						:&nbsp;
						<strong>
							{symbol || ''}
							{pipValue
								? roundNumberPipDigit(pipValue, 2)
								: 0
							}
						</strong>
					</span>
				<span>
						{t('Trade.Margin.Title')}
					:&nbsp;
					<strong>
						{symbol || ''}
						{marginRequirement
							? roundNumberPipDigit(marginRequirement, 2)
							: 0
						}
						</strong>
					</span>
			</div>
		</MobileTransition>
	);
};

export const MobileTradeDown = props => {
	const {
		pipValue = null,
		marginRequirement = null,
	} = props;

	return (
		<MobileTradeUnitsUp
			unitsDropDown
			pipValue={pipValue}
			marginRequirement={marginRequirement}
		/>
	);
};
