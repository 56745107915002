import React from 'react';
import MobileProfileStreamItem from './MobileProfileStreamItem';
import MobileNothingFound from '../../MobileEmpty/MobileNothingFound';
import { shallowEqual, useSelector } from 'react-redux';

const MobileProfileStream = ({options = []}) => {
  const { symbols} = useSelector((state) => (state.account.symbols), shallowEqual);

	if (options && !options.length) {
		return <MobileNothingFound/>;
	}
	return (
			<ul className="mobile-profile-stream-list">
				{options && !options.length && (
						<MobileNothingFound/>
				)}
				{options && !!options.length && options.map(item => (
						<li
								key={item.TradeHash}
								className="mobile-profile-stream-list__item"
						>
							<MobileProfileStreamItem {...item} symbols={symbols}/>
						</li>
				))}
			</ul>
	);
};

export default MobileProfileStream;
