import React, { useCallback, useEffect, useRef } from 'react';
import { CreateListener } from '../../helpers/button.helpers';


const MobileButtonClose = ({ onClick, stop = true }) => {
    const ref = useRef(null);

    const handleClick = useCallback((e) => {
        onClick && onClick(e);
    }, [onClick]);

    useEffect(() => {
        const createListener = new CreateListener();
        if (stop) {
            createListener.init(ref, handleClick);
            createListener.add();
        }
        return () => {
            if (stop) {
                createListener.remove();
            }
        };
    }, [ref]);

    return (
        <button
            ref={ref}
            type='button'
            className='mobile-button__close'
        >
            <div className='mobile-button__close__line' />
            <div className='mobile-button__close__line' />
        </button>
    );
};

export default MobileButtonClose;
