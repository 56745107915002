/* eslint-disable no-else-return */
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import{ QUESTIONER_MODE_EXTENRAL } from '../../background/socialv1/socialv1Reducer'
// import PropTypes from 'prop-types';

import { CommandFactory } from '../../background/commands/CommandFactory';
import Button from '../primitives/Button';
import LoadSpinner from '../primitives/LoadSpinner';
import ModalWrapper from '../primitives/ModalWrapper';
import Terms from '../InfoPages/Terms';

import './JoinPage.css';


const JoinPage = ({ closeJoin, setReloadApp, setQuestionnaire }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [avatar, setAvatar] = useState(null);
    const [avatarsList, setAvatarsList] = useState(false);
    const [terms, setTerms] = useState(false);
    const [selectedName, setSelectedName] = useState('');
    const { t } = useTranslation();
    const {
        registrationCommands: { joinSocialCommand, generateNickNameCommand }, isJoined, userDetails, lfsRegulationsDetails,socialAccount
    } = useSelector((state) => { return (state.account.socialv1); }, shallowEqual);

    const getNickname = async () => {
        const command = CommandFactory.createCommand(generateNickNameCommand);

        try {
            const res = await command.execute();

            if (res.ok === false) {
                // setError({ result: res.statusText });
            }
            if (res.isSuccessful === true) {
                return res.generatedNickName;
            } else if (res.isSuccessful === false) {
                // setError(res);
            }
        } catch (err) {
            // setError(err);
        }
    };

    const joinSocialAction = async () => {
        const userInput = { nickname: selectedName, avatar };

        if (isJoined) {
            return; // We dont do leaveSocialCommand if bug
        }

        const command = CommandFactory.createCommand(joinSocialCommand);

        try {
            const res = await command.execute(userInput);

            if (res.ok === false) {
                // setError({ result: res.statusText });
            }
            if (res.isSuccessful === true) {
                if (socialAccount.questionerMode === QUESTIONER_MODE_EXTENRAL)
                {
                    // Do nothing
                }
                else if (lfsRegulationsDetails.isLfsRegulated) {
                    setQuestionnaire(true);
                }
                closeJoin();
                setReloadApp();
            } else if (res.isSuccessful === false) {
                // setError(res);
                setIsLoading(false);
            }
        } catch (err) {
            // setError(err);
            setIsLoading(false);
        }

        // setIsLoading(false);
    };

    useEffect(() => {
        let isSubscribed = true;
        if (userDetails?.nickname) {
            setSelectedName(userDetails?.nickname);
        } else {
            setIsLoading(true);
            getNickname().then((newName) => {
                if (isSubscribed) {
                    setSelectedName(newName);
                    // setIsJoined(true);
                    setIsLoading(false);
                }
            });
        }
        const avatars = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 16, 19, 21, 22, 23, 24, 25, 26, 27, 28, 29];
        const rndm = Math.floor(Math.random() * avatars.length);
        setAvatar(`defaultAvatar${avatars[rndm]}`);
        setAvatarsList(avatars);
        // eslint-disable-next-line no-return-assign
        return () => isSubscribed = false;
    }, []);

    const changeNickname = (e) => {
        setSelectedName(e.target.value);
    };

    const handleJoin = () => {
        setIsLoading(true);
        joinSocialAction();
    };

    const toggleTerms = () => {
        setTerms((prevState) => !prevState);
    };

    const selectAvatar = (e) => {
        setAvatar(e.target.getAttribute('data-name'));
    };

    const GenerateAvatarsList = () => {
        const avatarsToRender = [];
        const avatarNumber = avatar.match(/(\d+)/);
        avatarsList.forEach((item) => {
            if (item === Number(avatarNumber[0])) {
                avatarsToRender.push(<div className={`avatar defaultavatar${item} avatar--active`} data-name={`defaultAvatar${item}`} key={item} onClick={selectAvatar} />);
            } else {
                avatarsToRender.push(<div className={`avatar defaultavatar${item}`} data-name={`defaultAvatar${item}`} key={item} onClick={selectAvatar} />);
            }
        });

        return avatarsToRender;
    };

    const joinButtonClass = selectedName ? 'btn btn-copy' : 'btn btn-copy no-pointer';

    return (
        <div>
            {!isLoading ? (
                <div className='join-page__details'>
                    <div className='join-page__description'>{t('Socials.Join.Description')}</div>
                    <div className='join-page__avatar-form-group'>
                        <div className='join-page__field-group'>
                            <FormControl error={!selectedName} fullWidth>
                                <InputLabel htmlFor='join-nickname'>{t('Socials.Join.Input.Label')}</InputLabel>
                                <Input
                                    id='join-nickname'
                                    value={selectedName}
                                    name='join-nickname'
                                    onChange={changeNickname}
                                    fullWidth
                                />
                                {!selectedName && <div className='red join-input--error'>{t('Socials.Join.Input.Error')}</div>}
                            </FormControl>
                        </div>
                    </div>
                    <p>{t('Socials.Join.Select.Avatars')}</p>
                    <div className='join-page__avatars-list'>
                        {avatarsList && <GenerateAvatarsList />}
                    </div>
                    <div className='join-page__terms'>
                        {t('Socials.Join.Terms.Text')}
                        &nbsp;
                        <strong onClick={toggleTerms}>{t('Socials.Join.Terms.Link')}</strong>
                    </div>
                    {/* <div className='join-page__button-group'> */}
                    <div className='profile-watch-opened__actions'>
                        <Button text={t('Socials.Join.Button')} className={joinButtonClass} onClickHandler={handleJoin} />
                        <Button text={t('General.Cancel')} className='btn btn-cancel' onClickHandler={closeJoin} />
                    </div>
                </div>
            ) : (
                <LoadSpinner isBottom />
            )}
            {terms && (
                <ModalWrapper
                    heading={t('Socials.Join.Terms.Link')}
                    handleClose={toggleTerms}
                    open={terms}
                    childClassName='terms-page'
                    contentClassName='content'
                >
                    <Terms onConfirm={toggleTerms} />
                </ModalWrapper>
            )}
        </div>
    );
};

// JoinPage.propTypes = {};

export default JoinPage;
