export const renderType = (actionType, pending = false) => {
	let type = '';
	let transition = '';

	if (pending) {
		switch (actionType) {
			case 0:
				type = 'Buy Limit';
				transition = 'PendingOrder.Buy.Limit';
				break;
			case 1:
				type = 'Sell Limit';
				transition = 'PendingOrder.Sell.Limit';
				break;
			case 2:
				type = 'Buy Stop';
				transition = 'PendingOrder.Buy.Stop';
				break;
			case 3:
				type = 'Sell Stop';
				transition = 'PendingOrder.Sell.Stop';
				break;
			default:
				type = '';
				break;
		}
	}

	if (!pending) {
		switch (actionType) {
			case 0:
				type = 'Buy';
				transition = '';
				// transition = 'Stream.Buy'; //TODO: locale this
				break;
			case 1:
				type = 'Sell';
				transition = '';
				// transition = 'Stream.Sell'; //TODO: locale this
				break;
			default:
				type = '';
				break;
		}
	}

	return {type, transition};
};

export const renderTypeMulti = actionType => {
	if (actionType === 0 || actionType === 2) return 1;
	if (actionType === 1 || actionType === 3) return 0;
	return 1;
};

export const initColumns = [
	'instrument',
	'type',
	'amount',
	'profit',
	'price',
	'currentPrice',
	'stopLoss',
	'takeProfit',
	'user',
	'unlink',
	'close',
	'delete',
	'arrow',
];

export const getRenderColumn = (columns, type) => {
	if (!columns || !columns.length) return true;
	return columns.includes(type);
};

export const getPositionArrayFromObj = positions => {
	if (!positions) return [];
	return Object.keys(positions).map(pos => positions[pos]);
};

export const getCurrentPositionsAll = (positions, symbolId) => {
	if (!positions || !symbolId) return [];

	const positionsArray = getPositionArrayFromObj(positions);
	if (!positionsArray || !positionsArray.length) return [];

	return positionsArray.filter(position => position.symbolId === symbolId);
};
