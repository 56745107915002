export const STDCMDStoreCmdID = "STD.CMD.Store"; 

export async function STDCMDStoreHandler(cmd,userInput)
{
  let store = cmd.getStore();
  let staticData = cmd.getStatic();
  
  if (!userInput)
  {
    userInput = cmd.getUserInput();
  }

  var f = store.dispatch({
    type:staticData.type,
    payload:userInput
  });

  return f;
} 
