import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

const MobileModalSuccess = ({children, onClose, title = ''}) => {
	const {t} = useTranslation();

	const handleClose = useCallback(e => {
		onClose && onClose(e);
	}, [onClose]);
	return (
			<div className="mobile-success-modal">

				<div className="mobile-success-modal__container">
					<div className="mobile-success-modal__inner">

						<div className='mobile-success-modal__image'/>

						<h3 className="mobile-success-modal__title">
							{title}
						</h3>

						<div className="mobile-success-modal__children">
							{children}
						</div>

					</div>
				</div>

				<button
						className='close-order'
						onClick={handleClose}
				>
					{t('General.Done')}
				</button>

			</div>
	);
};

export default MobileModalSuccess;
