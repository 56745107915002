import i18n from "i18next";
import trans from "../../translations.json" 
import {getStore} from '../storeContainer'
import { initReactI18next } from 'react-i18next';

export function getLocalStoreLang()
{
  return window.localStorage?.getItem("superweb-lang");
}

export function setLocalStoreLang(langCode)
{
  if (window.localStorage)
  {
    window.localStorage.setItem("superweb-lang",langCode);
  }
}

export default function Init()
{
  let store = getStore();
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng:getLocalStoreLang(),
    resources: trans,
    fallbackLng: "en",
    react:{
      transSupportBasicHtmlNodes: true,
    },
    interpolation: {
      escapeValue: false
    }
  })

  function select(state) {
    return state.brand.languageCode;
  }
    
  let currentValue = i18n.language;
  store.subscribe(()=>{

    let previousValue = currentValue
    currentValue = select(store.getState())
  if (previousValue !== currentValue) {
    i18n.changeLanguage(currentValue);
  }});
}