import React, {useCallback, useRef} from 'react';
import clsx from 'clsx';
import MobileButtonClose from '../Buttons/MobileButtonClose';
import useClickOutside from '../../hook/useClickOutside';

const MobileSectionModal = props => {
	const {isOpen, full, overlay, title, onClose, children} = props;

	const backdropRef = useRef(null);

	const handleClick = useCallback(e => {
		e && e.preventDefault();
		e && e.stopPropagation();
		onClose && onClose(e);
	}, [onClose]);

	useClickOutside(backdropRef, handleClick);

	return (
			<>
				{isOpen && (
						<div className={clsx('mobile-section-modal', overlay && 'overlay')}>
							<div
									ref={backdropRef}
									className={clsx('mobile-section-modal__inner',
											full && 'full')}
							>
								<div className="mobile-section-modal__header">
									<h4 className="mobile-section-modal__header__title">{title}</h4>
									<div className="mobile-section-modal__header__close">
										<MobileButtonClose
												onClick={handleClick}
										/>
									</div>
								</div>
								<div className="mobile-section-modal__section">
									{children}
								</div>
							</div>
						</div>
				)}
			</>
	);
};

MobileSectionModal.defaultProps = {
	isOpen: false,
	overlay: false,
	full: false,
	title: 'Header title',
};

export default MobileSectionModal;
