import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RISK_LEVELS, RISK_LEVEL_COLORS, RISK_LEVEL_LABELS } from '../../../shared/Constants/RiskLevelConstants';

const RiskLevelItem = ({ riskLevel, type }) => {
    const { t } = useTranslation();
    const theme = useSelector((state)=>state.brand.theme);
    
    return (
        <div className={type === riskLevel ? 'risk-item risk-item--active' : 'risk-item'}>
            <div style={{ backgroundColor: RISK_LEVEL_COLORS[type] }}>
                &nbsp;
            </div>
            <span style={{color:theme?.Text?.SystemColor}}>{t(RISK_LEVEL_LABELS[type])}</span>
        </div>
    );
};

const MobileRiskLevelNew = ({ externalRiskScore }) => {
    const { t } = useTranslation();

    if (externalRiskScore?.UserMeasures?.MaxDrawDown > 0) {
        let score = 0;
        if (externalRiskScore.UserMeasures.MaxDrawDown * 100 > 50) {
            score = 2;
        } else if (externalRiskScore.UserMeasures.MaxDrawDown * 100 > 25) {
            score = 1;
        }

        return (
            <div className='risk-level__container'>
                <div className='risk-level__header'>{t('Social.Profile.RiskLevel')}</div>
                <div className='risk-level__text'>
                    <RiskLevelItem type={RISK_LEVELS.RISK_HIGH} riskLevel={score} />
                    <RiskLevelItem type={RISK_LEVELS.RISK_MEDIUM} riskLevel={score} />
                    <RiskLevelItem type={RISK_LEVELS.RISK_LOW} riskLevel={score} />
                </div>
            </div>
        );
    }

    return null;
};

export default MobileRiskLevelNew;
