import {ChartService} from './ChartService'
import {adjustURL} from '../commands/mobileUrlService'
import { wsChartSubscription } from '../WebSocket/websocketListener';

export const CMDChartCmdID = "STD.CMD.ChartBars"; 

export async function CMDChartsHandler(cmd,userInput)
{
  let staticData = cmd.getStatic();
  if (!userInput)
  {
    userInput = cmd.getUserInput();
  }

  let response = await fetch(adjustURL(staticData.postUrl),{
      method: 'POST',
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
    'superweb-session':window.sessionStorage.getItem('superweb-session') ?? ''
  },
  body: JSON.stringify({
    staticData: staticData,
    userInput: userInput})
  });

  if (!response.ok){
    // Server Error
    //return null;
  }
  else{
    let data = await response.json();
    if (data)
    {
      if (data.isSuccessful)
      {
        ChartService.updateData(staticData.symbolId,userInput.period, data.bars,data.hasMore);
        try
        {
          wsChartSubscription({Type:'ChartSubscription',Symbol:staticData.symbolId,Period:userInput.period});
        }
        catch
        {
          
        }
        return data.hasMore;
      }
    }
  }

  return null;
} 