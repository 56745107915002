import React from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';

import OuterClick from '../primitives/OuterClick';
import Button from '../primitives/Button';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';


const WatchPopup = ({
    setOpenCopyTab, handleClick, copiedUserName, generalButtonColor
}) => {
    const { t } = useTranslation();
    const handleOpenCopy = () => {
        SendEventToGoogleAnalytics('Profile:WatchPopup:CopyClick', { masterNickname: copiedUserName });
        setOpenCopyTab();
        handleClick();
    };

    return (
        <div className='profile-watch-opened'>
            <div>
                {t('Social.Popup.Watch.BodyText', {
                    nickname: copiedUserName,
                    copy: t('Socials.Button.Copy')
                })}
            </div>
            <div className='profile-watch-opened__actions'>
                <Button text={t('Socials.Button.Copy')} className='btn btn-copy' onClickHandler={handleOpenCopy} />
                <Button text={t('Button.Ok')} className='btn btn-cancel btn--general' style={generalButtonColor} onClickHandler={handleClick} />
            </div>
        </div>
    );
};

// WatchPopup.propTypes = {};

export default OuterClick(WatchPopup);
