import React, {useEffect, useRef} from 'react';
import clsx from 'clsx';

import useControls from '../../../context/contextHook/useControls';

const MobileTradeScrollable = ({children, unitsDropDown}) => {

	const {footerControls: {isVisible}} = useControls();

	const wrapperRef = useRef();

	useEffect(() => {
		if (wrapperRef && wrapperRef.current) {
			wrapperRef.current.scrollTo(0, 0);
		}
	}, [unitsDropDown]);

	return (
		<div
			ref={wrapperRef}
			className={clsx('mobile-trade-popup__scrollable',
				!isVisible && 'full')}
		>
			{children}
		</div>
	);
};

export default MobileTradeScrollable;
