import React from 'react';
import {createStore} from 'redux';
import {Provider} from 'react-redux';

import {mainReducer} from './background/reducers/mainReducer.js';
import registerAllCommands from './background/commands/allCommandRegistry';
import {setStore} from './background/storeContainer';
import StartListener from './background/storeListener/activateListeners';

import MainApp from './UI/MainApp/MainApp';
import './App.css';

registerAllCommands();

const store = createStore(mainReducer, window.__REDUX_DEVTOOLS_EXTENSION__ &&
		window.__REDUX_DEVTOOLS_EXTENSION__());
setStore(store);
StartListener();

if ('URLSearchParams' in window) {
	var searchParams = new URLSearchParams(window.location.search);
	if (searchParams.get('token') || searchParams.get('jwttoken')) {
		searchParams.delete('token');
		searchParams.delete('jwttoken');
		let ss = searchParams.toString();
		var newRelativePathQuery = window.location.pathname;
		if (ss) {
			newRelativePathQuery = window.location.pathname + '?' + ss;
		}

		window.history.replaceState(null, '', newRelativePathQuery);
	}
}

function App() {
	return <Provider store={store}><MainApp/></Provider>;
}

export default App;
