import React, {useCallback, useEffect, useMemo} from 'react';
import LoadSpinner from '../../../UI/primitives/LoadSpinner';
import {
	loaderChangeWidth,
	POSITION,
	SIZE,
	TYPE,
} from '../../helpers/loader.helpers';
import clsx from 'clsx';

const MobileLoader = ({isLoading, type, size, position, overlay}) => {

	const currentType = useMemo(() => {
		return TYPE.includes(type) ? type : 'section';
	}, [type]);

	const currentPosition = useMemo(() => {
		return POSITION.includes(position) ? position : 'top';
	}, [position]);

	const handleSize = useCallback(() => {
		const sizeStyle = SIZE.includes(size) ? size : 'middle';
		loaderChangeWidth(sizeStyle);
	}, [size, isLoading]);

	useEffect(() => {
		handleSize();
	}, [handleSize]);

	return (
			<>
				{isLoading && (
						<div className={clsx('mobile-loader', overlay && 'overlay',
								currentType, currentPosition)}>
							<div className="mobile-loader__inner">
								<LoadSpinner/>
							</div>
						</div>
				)}
			</>
	);
};

MobileLoader.defaultProps = {
	size: 'middle',
	position: 'center',
	loading: false,
	overlay: false,
};

export default MobileLoader;
