import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

const MobileModalError = ({isOpen, children, onClose, title, subtitle}) => {
	const {t} = useTranslation();

	const handleClose = useCallback(e => {
		onClose && onClose(e);
	}, [onClose]);
	return (
			<>
				{isOpen && (
						<div className="mobile-error-modal">

							<div className="mobile-success-modal__container">
								<div className="mobile-success-modal__inner">

									<h3 className="mobile-success-modal__title red error">
										{title}
									</h3>

									<h3 className="mobile-success-modal__title red error">
										{subtitle}
									</h3>

									<div className="mobile-success-modal__children">
										{children}
									</div>

								</div>
							</div>

							<button
									className='close-order'
									onClick={handleClose}
							>
								{t('General.Close')}
							</button>

						</div>
				)}
			</>
	);
};

MobileModalError.defaultProps = {
	title: '',
	subtitle: '',
	isOpen: true,

};

MobileModalError.propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	children: PropTypes.node,
	onClose: PropTypes.func.isRequired,
	isOpen: PropTypes.bool,
};

export default MobileModalError;
