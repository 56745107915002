export const maskNumericalInput = units => {
	const s = units
			.toString()
			.replace(/./, '')
			.replace(/^0/, '');
	const value = units.toString().replace(/^0\d+$/, `0.${s}`);
	return value;
};

export const searchSymbolValue = (symbol = '', group = '', arr = []) => {
	if (!arr && !arr.length) return [];
	if (!symbol && !group) return arr;

	let result = arr;

	if (group && group === 'MarketRates.Groups.All') result = arr;

	if (group && group !== 'MarketRates.Groups.All') result = arr.filter(
			item => item.group.toLowerCase().includes(group.toLowerCase()));

	return result.filter(
			item => item.id.toLowerCase().includes(symbol.toLowerCase())
					|| item.description.toLowerCase().includes(symbol.toLowerCase())
					|| item.group.toLowerCase().includes(symbol.toLowerCase()));
};

