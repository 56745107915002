/* eslint-disable complexity */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useRef } from 'react';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
// import PropTypes from 'prop-types';

import { roundNumberPipDigit } from '../utilsUI/formatWSTick';
import countDecimals from '../utilsUI/countDecimals';
import MinusIcon from '../icons/MinusIcon';
import PlusIcon from '../icons/PlusIcon';

import './InputStepper.css';

const InputStepper = ({
    min,
    max,
    selectedUnits,
    setValue,
    stepValue,
    shouldFocus,
    pipDigit = 1,
    showError,
    errorMessage,
    checkDivider,
    roundMax,
    isError,
    onFocusAction,
    id = null,
    onKeyUp,
    inputDescription
}) => {
    const inputEl = useRef(null);

    const incrementValue = () => {
        const newValue =
      typeof selectedUnits === 'string'
          ? Number(selectedUnits.replace(/[^0-9\.]/g, '')) + stepValue
          : selectedUnits + stepValue;
        if (newValue > max) return;

        setValue(newValue.toFixed(pipDigit));
    };

    const decrementValue = () => {
        const newValue =
      typeof selectedUnits === 'string'
          ? Number(selectedUnits.replace(/[^0-9\.]/g, '')) - stepValue
          : selectedUnits - stepValue;
        if (newValue < min || newValue <= 0) return;

        setValue(newValue.toFixed(pipDigit));
    };

    const handleInputChange = (e) => {
    // eslint-disable-next-line no-useless-escape
        const inputValue = e.target.value.replace(/[^0-9\.]/g, '');
        setValue(inputValue);
        if (showError) {
            showError('');
        }
    };

    const onLeaveInput = () => {
        let selectedUnitsNumber = selectedUnits;
        if (typeof selectedUnits === 'string') {
            selectedUnitsNumber = selectedUnits.replace(/[^0-9\.]/g, '');
            selectedUnitsNumber = roundNumberPipDigit(
                selectedUnitsNumber,
                countDecimals(selectedUnitsNumber)
            );
            setValue(selectedUnitsNumber);
        }
        if (showError) {
            if (checkDivider) {
                if (typeof selectedUnits === 'string') {
                    selectedUnitsNumber = selectedUnits?.replace(/[^0-9\.]/g, '');
                }
                if (selectedUnitsNumber < min
                    // || (selectedUnitsNumber * 10 ** pipDigit) % (stepValue * 10 ** pipDigit) !== 0
                    || selectedUnitsNumber > max
                ) {
                    showError(errorMessage);
                } else {
                    showError('');
                }
            } else if (selectedUnitsNumber === '0' || selectedUnitsNumber === '') {
                showError(errorMessage);
            } else {
                showError('');
            }
        }
        if (roundMax) {
            if (selectedUnitsNumber > max) {
                setValue(max.toFixed(pipDigit));
            }
        }
        // eslint-disable-next-line no-unused-expressions
        // onFocusAction && onFocusAction();
        document.getSelection().removeAllRanges();
    };

    const handleOnFocus = () => {
        if (inputEl.current) {
            inputEl.current.select();
            // eslint-disable-next-line no-unused-expressions
            onFocusAction && onFocusAction();
        }
    };

    const inputClass = isError
        ? 'input-stepper input-stepper--error'
        : 'input-stepper';

    return (
        <div className={inputClass}>
            <button type='button' onClick={decrementValue}>
                <RemoveIcon />
                {/* <MinusIcon fill='#000' /> */}
                {/* <i className='fas fa-minus' /> */}
            </button>
            <div>
                <input
                    type='text'
                    id={id || null}
                    onChange={handleInputChange}
                    onBlur={onLeaveInput}
                    onFocus={handleOnFocus}
                    value={selectedUnits || ''}
                    autoFocus={shouldFocus}
                    ref={inputEl}
                    onKeyUp={onKeyUp || null}
                    autoComplete='off'
                />
                {inputDescription && (
                    <div className='description'>{inputDescription}</div>
                )}
            </div>
            <button type='button' onClick={incrementValue}>
                <AddIcon />
                {/* <PlusIcon fill='#000' /> */}
                {/* <i className='fas fa-plus' /> */}
            </button>
        </div>
    );
};

// InputStepper.defaultProps = {
//     min: 0,
//     max: 1000000,
//     stepValue: 1
// };

// InputStepper.propTypes = {
//     min: PropTypes.number,
//     max: PropTypes.number,
//     selectedUnits: PropTypes.number.isRequired,
//     setValue: PropTypes.func.isRequired,
//     stepValue: PropTypes.number
// };

export default InputStepper;
