//import { combineReducers } from 'redux'
import combineReducers from 'combine-reducers-global-state';
import {brandReducer} from './brandReducer';
import {urlParametersReducer} from './urlParametersReducer';
import {environmentsReducer} from './environmentsReducer';
import {accountInfoReducer} from './accountInfoReducer';
import {generalStatusReducer} from "./generalStatusReducer";
import {symbolsReducer} from "./symbolsReducer";
import {openPositionsReducer} from "./openPositionsReducer";
import {pendingPositionsReducer} from "./pendingPositionReducer";
import {closedPositionsReducer} from "./closedPositionsReducer";
import {financialReducer} from "./financialReducer";
import {socialv1Reducer} from "../socialv1/socialv1Reducer";

export const mainReducer = combineReducers({urlParameters:urlParametersReducer,
                                            generalStatus: generalStatusReducer,
                                            brand:brandReducer,
                                            environments:environmentsReducer,
                                            account:combineReducers({accountInfo:accountInfoReducer,
                                                                    financial:financialReducer,
                                                                    socialv1:socialv1Reducer,
                                                                    symbols:symbolsReducer,
                                                                     positions:combineReducers({
                                                                       open:openPositionsReducer,
                                                                       pending:pendingPositionsReducer,
                                                                       closed:closedPositionsReducer
                                                                     })
                                                                    })
                                                                  });