import React, {useMemo} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import depositButtonClickHandler from '../../../UI/utilsUI/depositButtonClickHandler';

const MobileMarginAlert = () => {
	const {t} = useTranslation();
  const { deposit: { depositUrl, getDepositUrlCommand } } = useSelector((state) => (state.account.accountInfo.featuresData));
	const {marginStatus} = useSelector(state => state.account.financial,
			shallowEqual);

	const isAlert = useMemo(() => {
		return marginStatus && marginStatus !== 0;
	}, [marginStatus]);

  const handleDepositClick = () => {
    depositButtonClickHandler(depositUrl, getDepositUrlCommand);
  };

	return (
			<>
				{!!isAlert && (
						<div className="mobile-margin-alert">
							<div className="mobile-margin-alert__wrapper">
								<a className="mobile-margin-alert__link"
								  onClick={handleDepositClick}
								>
									<p className="mobile-margin-alert__text">
										{t('Margin.Alert.Deposit')}
									</p>
								</a>
							</div>
						</div>
				)}
			</>
	);
};

export default MobileMarginAlert;
