import {BLOCK_COST, DURATION_LEVEL, riskColor} from '../constants/risk.options';

export const isActiveItem = (index = 0, level = 0) => {
	return index < level / BLOCK_COST;
};

export const getLevelItemStyle = index => {
	return {
		backgroundColor: riskColor[index],
		animationDuration: `${DURATION_LEVEL}ms`,
		animationDelay: `${index * DURATION_LEVEL}ms`,
	};
};
