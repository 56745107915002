import produce from "immer"
import { replaceIfChanged } from '../../background/StoreUtils/overrideState';
const  initState = {
  positions:{},
  eventCounter:0,
  closeAllCommand:null
};

export const ActionType = {
  Buy : 0,
  Sell : 1
}

export const openPositionsReducer = function openPositionsReducer(state = initState, action){
  switch (action.type) {
    case "login-logout":
      return initState;
    case "init-brand+login":
    {
      let serverPositions = action.payload.loginTradingData.tradingData.openPositions;
      return produce(state,draf=>{
        HandleOpenPositions(draf, serverPositions);
      });
    }
    case "update-trading-data":
    case "login-success":
    {
      let serverPositions = action.payload.tradingData.openPositions;
      return produce(state,draf=>{
        HandleOpenPositions(draf, serverPositions);
      });
    }
      default:
        return state;
  }
}

function HandleOpenPositions(draf, openPositions) {
  let serverPositions = openPositions.positions;
  replaceIfChanged(draf,'closeAllCommand', openPositions.closeAllCommand);
  draf.eventCounter = draf.eventCounter+1;
  let positionsToRemove = new Set();
  let statePositions = draf.positions;
  for (let statePosition of Object.values(statePositions)) {
    positionsToRemove.add(statePosition.id);
  }

  for (let serverPositionKey in serverPositions) {
    let serverPosition = serverPositions[serverPositionKey];
    positionsToRemove.delete(serverPosition.id);
    let statePosition = statePositions[serverPosition.id];
    if (!statePosition) {
      statePosition = { id: serverPosition.id, profit: { key: serverPosition.id, repId: "PNL" } };
      statePositions[serverPosition.id] = statePosition;
    }
    statePosition.tpId = serverPosition.tpId;
    statePosition.actionType = serverPosition.actionType;
    statePosition.amount = serverPosition.amount;
    statePosition.symbolId = serverPosition.symbolId;
    statePosition.symbolName = serverPosition.symbolName;
    statePosition.openRate = serverPosition.openRate;
    statePosition.openTimeUtc = serverPosition.openTimeUtc;
    statePosition.openTimeS = serverPosition.openTimeS;
    statePosition.commission = serverPosition.commission;
    statePosition.taxes = serverPosition.taxes;
    statePosition.swaps = serverPosition.swaps;
    statePosition.stopLoss = serverPosition.stopLoss;
    statePosition.takeProfit = serverPosition.takeProfit;

    replaceIfChanged(statePosition,'closePositionCMD', serverPosition.closePositionCMD);
    replaceIfChanged(statePosition,'updatePositionCMD', serverPosition.updatePositionCMD);
  }

  for (let positionToRemove of positionsToRemove) {
    delete statePositions[positionToRemove];
  }
}
