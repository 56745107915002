import React, {useCallback} from 'react';
import clsx from 'clsx';

import useControls from '../../context/contextHook/useControls';

import MobileBurgerButton from '../../components/Buttons/MobileBurgerButton';

import MobileMargin from '../MobileMargin/MobileMargin';
import MobileLogo from '../MobileLogo/MobileLogo';

const MobileHeader = () => {

	const {
		headerControls: {headerRef},
		burgerControls: {isOpen, handleOpen},
	} = useControls();

	const handleOpenBurgerMenu = useCallback(() => {
		handleOpen && handleOpen();
	}, [handleOpen]);

	return (
			<header
					id="mobile-header"
					ref={headerRef}
					className={clsx('mobile-header', isOpen && 'open')}
			>
				<div
						className="mobile-header__content"
				>
					<div className="mobile-header__burger">
						<MobileBurgerButton onClick={handleOpenBurgerMenu}/>
					</div>

					<div className="mobile-header__logo">
						<MobileLogo/>
					</div>

					<div className="mobile-header__actions">
						<div className="mobile-header__actions__wrapper">
							{/*<MobileHeaderButton type="search"/>*/}
						</div>
						<div className="mobile-header__actions__wrapper">
							<MobileMargin/>
						</div>
					</div>
				</div>
				<div className="gradient_line"/>
			</header>
	);
};

export default MobileHeader;
