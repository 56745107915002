import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import PubsubSubscription from '../../background/repositories/PubsubSubscription';
import { useTranslation } from 'react-i18next';
import {normalizedEditNumber} from  '../OpenPositions/updateNumericHelper'

const InvalidAmountCopyComp = ({userData,radio}) => {
  const { t } = useTranslation();
  const pnlTick = PubsubSubscription.subscribeObj({ key: 'MOVINGPNL', repId: 'ACCOUNT_PNL' });
  const equityStepperAmount = normalizedEditNumber(userData?.equity,parseFloat(userData?.equity));
  const isInvalidEquityAmount = equityStepperAmount > pnlTick.equity && !radio;
  if (isInvalidEquityAmount)
  {
    return (<div className='warning'>
        {t('Social.CopyTab.Errors.InvalidAmountMaxEquity', { equity: Math.abs(pnlTick.equity - parseFloat(equityStepperAmount)) })}
    </div>);
  }
  return null;
}

const OverCopyComp = ({userData,radio,copyData}) => {
  const { balance } = useSelector((state) => (state.account.financial), shallowEqual);
  const { t } = useTranslation();
  const pnlTick = PubsubSubscription.subscribeObj({ key: 'MOVINGPNL', repId: 'ACCOUNT_PNL' });
  const equityStepperAmount = normalizedEditNumber(userData?.equity,parseFloat(userData?.equity));
    const isOverCopy = equityStepperAmount < pnlTick.equity && (((userData?.copyType === 1 || userData?.copyType === 2) && equityStepperAmount + copyData?.TotalAllocationMargin - userData?.amount > balance)
                                   || (!userData?.copyType === 1 && !userData?.copyType === 2 && equityStepperAmount + copyData?.TotalAllocationMargin > equityStepperAmount > balance)) && !radio;

  if (isOverCopy)
  {
    return (<div className='warning'>
        {t('Social.CopyTab.Errors.InvalidAmountOverCopy')}
    </div>);
  }
  return null;
}

export const InvalidAmountCopy = InvalidAmountCopyComp;
export const OverCopy = OverCopyComp;