import React, {useCallback} from 'react';
import PropTypes from 'prop-types';

const MobileButtonRow = ({title, onClick}) => {

	const handleClick = useCallback(e => {
		e && e.preventDefault();
		e && e.stopPropagation();
		onClick && onClick(e);
	}, [onClick]);

	return (
			<button
					className="mobile-button-row"
					onClick={handleClick}
			>
				{title}
			</button>
	);
};

MobileButtonRow.defaultProps = {
	title: '',
};

MobileButtonRow.propTypes = {
	title: PropTypes.string,
	onClick: PropTypes.func,
};

export default MobileButtonRow;
