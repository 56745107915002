import PubsubRepository from "./PubsubRepository" ;

export class ProfitClass
{
  id="";
  profitWithCommitions = 0;
  profit = 0;
  diff = 0;
  pipDiff = 0;
  isValidProfit = false;
}

export const ProfitsRepository = new PubsubRepository({
  createInstance(){
    return new ProfitClass();
  },
  
  transform(traget,source)
  {
    traget.id = source.id
    traget.profitWithCommitions = source.profitWithCommitions;
    traget.profit = source.profit;
    traget.diff = source.diff;
    traget.pipDiff = source.pipDiff;
    traget.isValidProfit = source.isValidProfit;
  },
  
  deepClone(traget,source)
  {
    traget.id = source.id
    traget.profitWithCommitions = source.profitWithCommitions;
    traget.profit = source.profit;
    traget.diff = source.diff;
    traget.pipDiff = source.pipDiff;
    traget.isValidProfit = source.isValidProfit;
  },
  
  getKey(item)
  {
    return item.id;
  }
});

export class AccountMovingPLClass
{
  id="";
  openPl = 0;
  openPlWitoutCommition = 0;
  equity = 0;
  freeMargin = 0;
  marginLevel = false;
}

export const AccountMovingPLRepository = new PubsubRepository({
  createInstance(){
    return new AccountMovingPLClass();
  },
  
  transform(traget,source)
  {
    traget.id = source.id
    traget.openPl = source.openPl;
    traget.openPlWitoutCommition = source.openPlWitoutCommition;
    traget.equity = source.equity;
    traget.freeMargin = source.freeMargin;
    traget.marginLevel = source.marginLevel;
  },
  
  deepClone(traget,source)
  {
    traget.id = source.id
    traget.openPl = source.openPl;
    traget.openPlWitoutCommition = source.openPlWitoutCommition;
    traget.equity = source.equity;
    traget.freeMargin = source.freeMargin;
    traget.marginLevel = source.marginLevel;
  },
  
  getKey(item)
  {
    return item.id;
  }
});