import React, {
    useState, useEffect, useRef, Fragment
} from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Column, Table, SortDirection, List, AutoSizer
} from 'react-virtualized';
import 'react-virtualized/styles.css';
// import PropTypes from 'prop-types';

import { formatDate } from '../utilsUI/formatTime';
import FormatProfitSwapCommision from '../utilsUI/FormatProfitSwapCommision';
import Button from '../primitives/Button';

import OrderUpdate from './OrderUpdate';

import { CommandFactory } from '../../background/commands/CommandFactory';
import { normalizedEditNumber } from './updateNumericHelper';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';
import OpenPositionsVirtualCloseRate from './OpenPositionsVirtualCloseRate';
import OpenPositionsVirtualProfit from './OpenPositionsVirtualProfit';


const OpenPositionsVirtualList = ({ setSelectedTrader, setCloseOrder, handleUnlink, isBottomPanelPinned, subHeaderColor, systemColor, linkColor }) => {
    const { t } = useTranslation();
    const tableRef = useRef();
    const [commandError, setCommandError] = useState(null);
    const statePositions = useSelector((state) => state.account.positions.open.positions);
    const [editMode, setEditMode] = useState({ rowId: null, editSLValue: null, editTPValue: null });

    const tradeLinks = useSelector((state) => state.account.socialv1.tradeLinks);
    const isSocialEnabled = useSelector((state) => state.account.socialv1.isEnabled);
    const isSocialVisible = useSelector((state) => state.account.accountInfo.rightPanelSettings.socialv1.isVisible);
    const { Text: { SystemColor, LinkColor }, SubHeader: { Text }, Profit: { Positive, Negative, Neutral }, Buttons: { TextColor } } = useSelector((state) => (state.brand.theme));
    const showCopiedFrom = isSocialEnabled && isSocialVisible;

    const positionOrdered = [];
    for (const currPosKey in statePositions) {
        const currPos = statePositions[currPosKey];
        positionOrdered.push(currPos);
    }
    positionOrdered.sort((a,b) => b.openTimeS-a.openTimeS);

    const exitEditMode = () => {
        setEditMode({
            rowId: null, editSLValue: null, editTPValue: null, mode: 0
        });
        setCommandError(null);
    };

    const enterEditPosition = (id) => {
        const statePosition = statePositions[id];
        if (statePosition) {
            setEditMode({ rowId: statePosition.id, editSLValue: statePosition.stopLoss, editTPValue: statePosition.takeProfit });
        } else {
            exitEditMode();
        }
    };

    const saveEditData = async (e) => {
        e.preventDefault();

        const currState = statePositions[editMode.rowId];
        if (currState) {
            setCommandError(null);
            try {
                const response = await CommandFactory.createCommand(currState.updatePositionCMD)
                    .execute({
                        takeProfit: normalizedEditNumber(editMode.editTPValue, currState.takeProfit),
                        stopLoss: normalizedEditNumber(editMode.editSLValue, currState.stopLoss)
                    });

                if (!response.isSuccessful) {
                    setCommandError(response.result ?? 'trading.error.GeneralError');
                } else {
                    exitEditMode();
                }
            } catch (ex) {
                setCommandError('trading.error.GeneralError');
            }
        }
    };

    const renderType = ({ cellData }) => {
        if (cellData === 0) return 'Buy';
        if (cellData === 1) return 'Sell';

        return '';
    };

    const renderTime = ({ cellData }) => {
        return formatDate(cellData);
    };

    const renderProfit = ({ cellData }) => {
        return <div><FormatProfitSwapCommision tickValue={cellData} positive={Positive} negative={Negative} neutral={Neutral} /></div>;
    };

    const renderCloseRate = ({ rowData }) => {
        return <OpenPositionsVirtualCloseRate symbolName={rowData.symbolName} actionType={rowData.actionType} />;
    };

    const renderProfitRate = ({ cellData }) => {
        return <OpenPositionsVirtualProfit cellData={cellData} positive={Positive} negative={Negative} neutral={Neutral} />;
    };

    const renderStopLoss = ({ cellData, rowData, rowIndex }) => {
        if (rowData.id === editMode.rowId) {
            return (
                <Fragment>
                    <OrderUpdate
                        symbolId={rowData?.symbolName}
                        selectedUnits={editMode.editSLValue}
                        setSelectedUnits={(value) => {
                            setEditMode({ ...editMode, editSLValue: value });
                        }}
                        shouldFocus={false}
                        id={`stopLoss-${rowData?.tpId}`}
                    />
                </Fragment>
            );
        }
        return <Fragment><button className='stop-loss__button outer-exclude' type='button' onClick={() => enterEditPosition(rowData.id)}>{rowData.stopLoss || '-'}</button></Fragment>;
    };

    const renderTakeProfit = ({ cellData, rowData, rowIndex }) => {
        if (rowData.id === editMode.rowId) {
            return (
                <Fragment>
                    <OrderUpdate
                        symbolId={rowData?.symbolName}
                        selectedUnits={editMode.editTPValue}
                        setSelectedUnits={(value) => setEditMode({ ...editMode, editTPValue: value })}
                        shouldFocus={false}
                    />
                </Fragment>
            );
        }
        return <Fragment><button className='stop-loss__button outer-exclude' type='button' onClick={() => enterEditPosition(rowData.id)}>{rowData.takeProfit || '-'}</button></Fragment>;
    };

    const renderCloseButton = ({ rowData }) => {
        const handleCancelOrder = () => {
            exitEditMode();
            const tradeLink = tradeLinks[rowData.tpId];
            const closeData = {
                closePositionCMD: rowData?.closePositionCMD,
                symbolName: rowData?.symbolName,
                type: rowData?.actionType,
                orderId: rowData?.tpId,
                openRate: rowData?.openRate,
                openTimeS: rowData?.openTimeS,
                amount: rowData?.amount,
                profitValue: rowData?.profit,
                stopLoss: rowData?.stopLoss,
                takeProfit: rowData?.takeProfit,
                tradeLink
            };
            setCloseOrder(closeData);
        };
        return <Button text={t('InstrumetInfo.Details.Close')} style={linkColor} onClickHandler={handleCancelOrder} className='close-position' iconEnd='fa fa-times' />;
    };

    const renderCopied = ({ cellData }) => {
        const tradeLink = tradeLinks[cellData];
        const openProfilePage = () => {
            setSelectedTrader({ nickname: tradeLink.sourceNickName });
            SendEventToGoogleAnalytics("Drawer:Open:ProfileClick", {nickname: tradeLink.sourceNickName});
        };
        const handleUnlinkOrder = () => {
            handleUnlink(cellData);
        };

        if (tradeLink) {
            return (
                <div className='copied-from__container'>
                    <span className='copied-from' style={linkColor} onClick={openProfilePage}>{tradeLink.sourceNickName}</span>
                    <Button text={t('InstrumetInfo.Details.Unlink')} style={linkColor} onClickHandler={handleUnlinkOrder} className={tradeLink.linkType === 0 ? 'close-unlink' : 'close-unlink no-pointers'} iconEnd='fas fa-unlink' />
                </div>
            );
        }
        return null;
    };

    const renderEditUpdate = ({ rowData }) => {
        if (rowData.id === editMode.rowId) {
            return (
                <Fragment>
                    <div className='actions--expanded'>
                        <div style={{ display: 'flex' }}>
                            <Button className='btn btn--general' style={{ color: TextColor }} onClickHandler={saveEditData} text={t('InstrumetInfo.Details.Update')} />
                            <Button className='btn outer-exclude btn--general' style={{ color: TextColor }} onClickHandler={exitEditMode} text={t('General.Cancel')} />
                        </div>
                        {commandError && (
                            <div className='red error'>
                                {t(commandError)}
                            </div>
                        )}
                    </div>
                </Fragment>
            );
        }
        return <Fragment />;
    };

    const getRowHeight = ({ index }) => {
        const currRow = positionOrdered[index];
        if (currRow) {
            if (currRow.id == editMode.rowId) {
                return 80;
            }
        }
        return 35;
    };

    useEffect(
        () => {
            tableRef.current.recomputeRowHeights();
        },
        [editMode.rowId]
    );

    return (
        <Fragment>
            <div className='open-positions__scroller'>
                <div className='open-positions__wrapper' style={systemColor}>
                    <AutoSizer disableHeight={!isBottomPanelPinned}>
                        {({ height, width }) => (
                            <Table
                                width={width}
                                height={isBottomPanelPinned ? height : 225}
                                // height={height}
                                headerHeight={33}
                                // headerStyle={subHeaderColor}
                                rowHeight={getRowHeight}
                                rowCount={positionOrdered.length}
                                rowGetter={({ index }) => positionOrdered[index]}
                                className='open-positions__container'
                                ref={tableRef}
                            >

                                <Column label={t('InstrumetInfo.Details.Instrument')} dataKey='symbolName' width={106} minWidth={106} className='' />
                                <Column label={t('InstrumetInfo.Details.Type')} dataKey='actionType' width={60} minWidth={60} className='' cellRenderer={renderType} />
                                <Column label={t('InstrumetInfo.Details.Amount')} dataKey='amount' width={80} minWidth={80} className='' />
                                <Column label={t('InstrumetInfo.Details.OpenRate')} dataKey='openRate' width={100} minWidth={100} className='' />
                                <Column label={t('InstrumetInfo.Details.OpenTime')} dataKey='openTimeS' width={167} minWidth={153} cellRenderer={renderTime} className='' />
                                <Column label={t('InstrumetInfo.Details.StopLoss')} dataKey='stopLoss' width={150} minWidth={150} cellRenderer={renderStopLoss} className='' />
                                <Column label={t('InstrumetInfo.Details.TakeProfit')} dataKey='takeProfit' width={150} minWidth={150} cellRenderer={renderTakeProfit} className='' />
                                <Column label={t('InstrumetInfo.Details.Swap')} dataKey='swaps' width={80} minWidth={80} cellRenderer={renderProfit} className='' />
                                <Column label={t('InstrumetInfo.Details.Commission')} dataKey='commission' width={80} minWidth={80} cellRenderer={renderProfit} className='' />
                                <Column label={t('InstrumetInfo.Details.Profit')} dataKey='profit' width={80} minWidth={80} cellRenderer={renderProfitRate} className='' />
                                <Column label={t('InstrumetInfo.Details.CloseRate')} dataKey='closeRate' width={80} minWidth={80} cellRenderer={renderCloseRate} className='' />
                                <Column width={100} minWidth={100} cellRenderer={renderCloseButton} dataKey='tpId' className='' />
                                {showCopiedFrom && (
                                    <Column label={t('InstrumetInfo.Details.CopiedFrom')} dataKey='tpId' width={180} minWidth={180} cellRenderer={renderCopied} className='' />
                                )}
                                <Column width={150} minWidth={150} cellRenderer={renderEditUpdate} dataKey='tpId' className='responsive-table__cell--actions' />
                            </Table>
                        )}
                    </AutoSizer>
                </div>
            </div>
        </Fragment>
    );
};

// OpenPositionsVirtualList.propTypes = {};

export default OpenPositionsVirtualList;
