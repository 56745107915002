import React from 'react';

import ThemeContextMobile from './contextCreate/ThemeContextMobile';
import ControlsContextMobile from './contextCreate/ControlsContextMobile';
import SymbolContextMobile from './contextCreate/SymbolContextMobile';
import ProfileContextMobile from './contextCreate/ProfileContextMobile';
import PositionContextMobile from './contextCreate/PositionContextMobile';

import useProviderTheme from './contextHook/useProviderTheme';
import useProviderControls from './contextHook/useProviderControls';
import useProviderSymbol from './contextHook/useProviderSymbol';
import useProviderProfile from './contextHook/useProviderProfile';
import useProviderPosition from './contextHook/useProviderPosition';

const Context = ({ children }) => {
    const theme = useProviderTheme();
    const controls = useProviderControls();
    const symbol = useProviderSymbol();
    const profile = useProviderProfile();
    const position = useProviderPosition();

    return (
        <>
            <ThemeContextMobile.Provider value={theme}>
                <ControlsContextMobile.Provider value={controls}>
                    <SymbolContextMobile.Provider value={symbol}>
                        <PositionContextMobile.Provider value={position}>
                            <ProfileContextMobile.Provider value={profile}>
                                {children}
                            </ProfileContextMobile.Provider>
                        </PositionContextMobile.Provider>
                    </SymbolContextMobile.Provider>
                </ControlsContextMobile.Provider>
            </ThemeContextMobile.Provider>
        </>
    );
};

export default Context;
