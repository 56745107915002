import { getStore } from '../storeContainer'
import { StatusModes } from '../reducers/generalStatusReducer';
import * as FullStory from '@fullstory/browser';
let isFullStoryRunning = false;

let initFullStory = function (fullStoryData) {
  isFullStoryRunning = true;
  FullStory.init({ orgId: fullStoryData.organizationId });
}

let setUserVars = function (userDetails, envName) {
  FullStory.identify(userDetails.userId, {
    "displayName": userDetails.name,
    "brandName_str": userDetails.brandName,
    "brokerName_str": userDetails.brokerName,
    'tpUserId_str': userDetails.tpUserId,
    'displayName_str': userDetails.name,
    'envId_str': userDetails.envId,
    'envName_str': envName
  });
}

export function SendEventToFullStory(eventName, eventProperties) {
  if (isFullStoryRunning) {
    FullStory.event(eventName, eventProperties);
  }
}

export default function Init() {
  let store = getStore();
  function selectUserGuid(state) {
    return state.account.accountInfo.userDetails.userId;
  }

  let currentUserGuid = selectUserGuid(store.getState());

  store.subscribe(() => {
    var state = store.getState();
    let fullStoryData = state.account.accountInfo.analyticData.fullStoryData;
    let userDetails = state.account.accountInfo.userDetails;
    let loginStatus = state.generalStatus.status;

    if (loginStatus === StatusModes.accountLogin) {
      if (!isFullStoryRunning && fullStoryData.isEnabled && fullStoryData.organizationId){
        initFullStory(fullStoryData);
    }

    if (isFullStoryRunning)
      {
        let previousUserGuid = currentUserGuid;
        currentUserGuid = selectUserGuid(state);
        if (previousUserGuid !== currentUserGuid){
          let envName = state.environments.environments[userDetails.envId]?.displayName ?? "";
          setUserVars(userDetails, envName);
        }
      }
    }
  });
}