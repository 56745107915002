import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import clsx from 'clsx';

import MobileAutosizer
	from '../../../components/MobileAutosizer/MobileAutosizer';
import MobileTradingAllItem from './MobileTradingAllItem';
import MobileNothingFound
	from '../../../components/MobileEmpty/MobileNothingFound';
import {searchSymbolValue} from '../../../helpers/input.helpers';
import MobileTradingAllActionBlock from './MobileTradingAllActionBlock';

const MobileTradingAll = props => {

	const {
		searchValue,
		symbolGroup,
		onChangeSearch,
		onChangeGroup,
		selectedSymbol,
		orderedSymbols,
		handleItemSelect,
		handleBack,
	} = props;

	const [isActionBar, setIsActionBar] = useState(false);
	const [scrollIndex, setScrollIndex] = useState(0);

	const scrollToTop = useRef(0);

	const handleSetIndex = useCallback(() => {
		const index = orderedSymbols.findIndex(el => el.id === selectedSymbol.id);
		const max = orderedSymbols.length - 1;
		setScrollIndex(index === -1
				? 0
				: index <= 9
						? 0
						: (max - 9) <= index ? index : index + 9,
		);
	}, [selectedSymbol, orderedSymbols]);

	const handleScroll = useCallback(scroll => {
		return setIsActionBar(true); //TODO: visible action bar rates

		if (scrollToTop.current >= scroll.scrollTop) {
			setIsActionBar(true);
			scrollToTop.current = scroll.scrollTop;
			return scroll.scrollTop;
		} else {
			setIsActionBar(false);
			scrollToTop.current = scroll.scrollTop;
		}

		scrollToTop.current = scroll.scrollTop;
		if (scroll.scrollTop < 80) return setIsActionBar(true);
		return setIsActionBar(false);
	}, [setIsActionBar, scrollToTop]);

	const handleBackClick = useCallback(() => {
		handleBack && handleBack();
	}, [handleBack]);

	const handleItemClick = useCallback(item => {
		handleItemSelect && handleItemSelect(item);
	}, [handleItemSelect]);

	const findOrderedSymbols = useMemo(() => {
		return searchSymbolValue(searchValue, symbolGroup, orderedSymbols);
	}, [symbolGroup, searchValue, orderedSymbols]);

	const handleIndex = useCallback((index, item) => {
		handleItemClick && handleItemClick(item);
	}, [findOrderedSymbols, handleItemClick]);

	const renderRow = useCallback(data => {
		const {item, props} = data;
		return (
				<MobileTradingAllItem
						{...item}
						{...props}
						handleIndex={index => handleIndex(index, findOrderedSymbols[index])}
				/>
		);
	}, [findOrderedSymbols]);

	useEffect(() => {
		handleSetIndex();
	}, []);

	return (
			<div className="mobile-trading-all">

				<MobileTradingAllActionBlock
						isVisible={isActionBar}
						onChangeSearch={onChangeSearch}
						onChangeGroup={onChangeGroup}
				/>

				{!findOrderedSymbols.length && (
						<MobileNothingFound/>
				)}

				<div
						className={clsx('mobile-trading-all__autosizer',
								isActionBar && 'action-show')}
				>
					{!!findOrderedSymbols.length && (
							<MobileAutosizer
									handleBackClick={handleBackClick}
									onClick={handleItemClick}
									currentItem={selectedSymbol}
									options={findOrderedSymbols}
									renderRow={renderRow}
									rowHeight={50}
									onScroll={handleScroll}
									scrollToIndex={scrollIndex}
									className="mobile-trading-all__item"
							/>
					)}
				</div>
			</div>
	);
};

export default MobileTradingAll;
