import {getStore} from './storeContainer'
import CommandFactory from "./commands/CommandFactory"

export const refreshStateWorkaround = () =>
{
  var oldCounter = getStore().getState().account.positions.open.eventCounter;
  return {
    completed: ()=>{
      setTimeout(()=>{
        var newCounter = getStore().getState().account.positions.open.eventCounter;
        if (oldCounter == newCounter)
        {
          if (getStore().getState().account.accountInfo.refreshStateCommand)
          {
            console.log("Refresh workaround Activated")
            CommandFactory.createCommand(getStore().getState().account.accountInfo.refreshStateCommand).execute({mode:'refresh_workaround'})
          }
        }
        else{
          console.log("Refresh workaround Skip")
        }
      },3000)
    }
  }
}