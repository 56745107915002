import React, {useCallback} from 'react';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';

const MobileHeaderButton = ({type, isAlert, isAdd, onClick}) => {

	const icon = () => {
		if (type === 'money') return <AttachMoneyIcon/>;
		if (type === 'search') return <SearchIcon/>;
	};

	const handleClick = useCallback(e => {
		e && e.preventDefault();
		onClick && onClick();
	}, [onClick]);
	return (
			<div
					onClick={handleClick}
					className={clsx('mobile-button__header',
							isAlert && 'alert',
							isAdd && 'add',
					)}
			>
				{icon()}
			</div>
	);
};

MobileHeaderButton.defaultProps = {
	type: 'search',
	isAlert: false,
	isAdd: false,
};

export default MobileHeaderButton;
