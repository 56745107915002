import React, { useState, useEffect } from 'react';
import Slider from '@material-ui/core/Slider';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';

import { CommandFactory } from '../../background/commands/CommandFactory';
import Button from '../primitives/Button';
import LoadSpinner from '../primitives/LoadSpinner';

import ExplorePageUsersList from './ExplorePageUsersList';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';


const ExplorerPageAdvancedSearch = ({ location, limit, handleItemSelect, toggleOpenCopyTab, buttonColor, copyColor }) => {
    const { advanceSearch } = useSelector((state) => { return (state.account.socialv1.exploreCommands); }, shallowEqual);
    const [pnlValue, setPnlValue] = useState(0);
    const [success, setSuccess] = useState(0);
    const [drawDown, setDrawDown] = useState(0);
    const [orderedTopTraders, setOrderedTopTraders] = useState([]);
    const [orderedTopThreeTraders, setOrderedTopThreeTraders] = useState([]);
    const [, setResponse] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
      SendEventToGoogleAnalytics("Explore:AdvancedSearchTabOpened")
  }, []);

    const handlePnlChange = (event, newValue) => {
        setPnlValue(newValue);
    };
    const handleSuccessChange = (event, newValue) => {
        setSuccess(newValue);
    };
    const handleDrawDownChange = (event, newValue) => {
        setDrawDown(newValue);
    };

    const getTopRankedUsers = async () => {
        const command = CommandFactory.createCommand(advanceSearch);
        setIsLoading(true);

        try {
            setResponse(null);
            const res = await command.execute({
                plPercent: pnlValue || 0,
                successRatePercent: success || 0,
                maxDrawDownPercent: drawDown || 0
            });

            if (res.isSuccessful === true) {
                setOrderedTopThreeTraders(res.payload.slice(0, 3));
                setOrderedTopTraders(res.payload.slice(3, limit));
            } else if (res.isSuccessful === false) {
                setResponse(res);
            }
        } catch (err) {
            setResponse(err);
        }
        setIsLoading(false);
    };

    const searchUsers = () => {
        getTopRankedUsers();
    };

    const handleUserSelect = (data)=>{
      handleItemSelect(data, "AdvancedSearch");
    }


    return (
        <div>
            <div className='explorer-advanced-search__wrapper'>
                <div className='explorer-advanced-search__container'>
                    <div>
                        <div className='explorer-advanced-search__description'>
                            <span>{t('Socials.PNL')}</span>
                        </div>
                        <Slider
                            value={typeof pnlValue === 'number' ? pnlValue : 0}
                            onChange={handlePnlChange}
                            aria-labelledby='input-slider'
                        />
                        <div className='explorer-advanced-search__value'>
                            {pnlValue}
                            %
                        </div>
                    </div>
                    <div>
                        <div className='explorer-advanced-search__description'>
                            <span>{t('Socials.SuccessRate')}</span>
                        </div>
                        <Slider
                            value={typeof success === 'number' ? success : 0}
                            onChange={handleSuccessChange}
                            aria-labelledby='input-slider'
                        />
                        <div className='explorer-advanced-search__value'>
                            {success}
                            %
                        </div>
                    </div>
                    <div>
                        <div className='explorer-advanced-search__description'>
                            <span>{t('Socials.DrawDown')}</span>
                        </div>
                        <Slider
                            value={typeof drawDown === 'number' ? drawDown : 0}
                            onChange={handleDrawDownChange}
                            aria-labelledby='input-slider'
                        />
                        <div className='explorer-advanced-search__value'>
                            {drawDown}
                            %
                        </div>
                    </div>
                    <div>
                        <Button className='btn btn--general' style={buttonColor} text={t('Socials.Button.Search')} iconEnd='fa fa-search' onClickHandler={searchUsers} />
                    </div>
                </div>
            </div>
            {!isLoading && orderedTopTraders.length > 0 && (
                <div className='explorer-advanced-search__result-container'>
                    <ExplorePageUsersList location={location} copyColor={copyColor} users={orderedTopTraders} topThreeUsers={orderedTopThreeTraders} handleItemSelect={handleUserSelect} toggleOpenCopyTab={toggleOpenCopyTab} />
                </div>
            )}
            {isLoading && <LoadSpinner />}
        </div>
    );
};

// ExplorerPageAdvancedSearch.propTypes = {};

export default ExplorerPageAdvancedSearch;
