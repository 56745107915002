import React, { Fragment,  memo } from 'react';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { StoreTreeItem } from "./StoreTreeItem"
import CommandDialog from './CommandDialog';
import { useTranslation } from 'react-i18next';
import SocialStreamDialog from './socialStreamDialog'

function MainTreeView(props){

  const [dialog, setDialogValue] = React.useState({open:false,cmd:null,title:""})
  const [socialStream, setSocialStream] = React.useState({open:false})
  const { t } = useTranslation();

  return ( 
    <div>
        <Fragment>
          <p>{t('Branding.Language.Name')}</p>
          {dialog.open && <CommandDialog open={dialog.open} title={dialog.title} cmd={dialog.cmd} setCmdDialog={setDialogValue}></CommandDialog>}
          {socialStream.open && <SocialStreamDialog open={socialStream.open} setCmdDialog={setSocialStream}></SocialStreamDialog>}

          <TreeView
            defaultExpanded={['root/account','root/brand','root/environments','root/generalStatus','root/urlParameters',
                              'root/account/positions','root/account/positions/open','root/account/positions/open/positions'   ]}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
          >
            <StoreTreeItem parentNodeId={"root"} setCmdDialog={setDialogValue} setSocialStream={setSocialStream} path={[]}></StoreTreeItem>
          </TreeView>
        </Fragment>
    </div>);
};

export default memo(MainTreeView);
