import React, { useState, Fragment } from 'react';
// import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CommandFactory } from '../../background/commands/CommandFactory';
import {refreshStateWorkaround} from '../../background/refreshStateWorkaround';
import pubsubSubscription from '../../background/repositories/PubsubSubscription';
import { ProfitCalculationService } from '../../background/calculations/profitCalculationService';

// import { ACTION_TYPE } from '../TradePopup/TradePopup';
import InputStepper from '../primitives/InputStepper';
import FormatProfitSwapCommision from '../utilsUI/FormatProfitSwapCommision';
import Button from '../primitives/Button';
import LoadSpinner from '../primitives/LoadSpinner';
import { formatDate } from '../utilsUI/formatTime';
import {normalizedEditNumber} from '../OpenPositions/updateNumericHelper';

export const ACTION_TYPE_SB = {
    SELL: 'Sell',
    BUY: 'Buy'
};


const ClosePosition = ({
    closePositionCMD,
    // handleCancelOrderClose,
    symbolName,
    type,
    orderId,
    openRate,
    openTimeS,
    amount,
    callModalOfClosedOrder,
    stopLoss,
    takeProfit,
    tradeLink
}) => {
    const [selectedUnits, setSelectedUnits] = useState(amount);
    const [response, setResponse] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { symbols } = useSelector((state) => { return (state.account.symbols); }, shallowEqual);
    const closeValue = pubsubSubscription.subscribeObj({ key: symbolName, repId: 'ticks' });
    const isBid = type === 0;
    const closeRate = isBid ? closeValue?.bid : closeValue?.ask;
    const closeConvertion = isBid ? closeValue?.bidProfitConversion : closeValue?.askProfitConversion;
    const { t } = useTranslation();
    const profitBasedOnAmount = ProfitCalculationService.calculateProfit(symbols[symbolName], type, normalizedEditNumber(selectedUnits,parseFloat(selectedUnits)), openRate, closeRate, closeConvertion);

    const handleValueChange = (value) => {
        setSelectedUnits(value);
    };

    const handleUpdateOrder = async (e) => {
        e.preventDefault();

        const command = CommandFactory.createCommand(closePositionCMD);
        setIsLoading(true);

        try {
            setResponse(null);
            let refreshWorkaround = refreshStateWorkaround()
            const res = await command.execute({ amount: Number(normalizedEditNumber(selectedUnits,parseFloat(selectedUnits))) });

            if (res.isSuccessful === true) {
                refreshWorkaround.completed();
                // eslint-disable-next-line object-curly-newline
                callModalOfClosedOrder({ symbolName, orderId, openTimeS, type, openRate, amount, profitValue: res.profit, price: res.price });
            } else if (res.isSuccessful === false) {
                setResponse(res);
            }
        } catch (err) {
            setResponse(err);
        }
        setIsLoading(false);
    };


    return (
        <Fragment>
            {!isLoading ? (
                <Fragment>
                    <div className='order-update__container'>
                        <div className='order-update__data'>
                            {t('InstrumetInfo.Details.OpenTime')}
                            :
                            &nbsp;
                            {formatDate(openTimeS)}
                        </div>
                        <InputStepper selectedUnits={selectedUnits} stepValue={0.01} setValue={handleValueChange} pipDigit={2} max={amount} />
                        <div className='highlight'>
                            <div className='data__block'>
                                <div className='data__column'>
                                    {!!stopLoss && (
                                        <span>
                                            {t('InstrumetInfo.Details.StopLoss')}
                                            :
                                            &nbsp;
                                            {stopLoss}
                                        </span>
                                    )}
                                </div>
                                <div className='data__column'>
                                    {!!takeProfit && (
                                        <span>
                                            {t('InstrumetInfo.Details.TakeProfit')}
                                            :
                                            &nbsp;
                                            {takeProfit}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className='data__block'>
                                <div className='data__column'>
                                    <span>
                                        {t('InstrumetInfo.Details.OpenRate')}
                                        :
                                        &nbsp;
                                        {openRate}
                                    </span>
                                </div>
                                <div className='data__column'>
                                    <span>
                                        {t('InstrumetInfo.Details.CloseRate')}
                                        :
                                        &nbsp;
                                        {closeRate}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='highlight__profit'>
                            {t('InstrumetInfo.Details.Profit')}
                            :&nbsp;
                            <strong>
                                <FormatProfitSwapCommision tickValue={profitBasedOnAmount} />
                            </strong>
                        </div>
                        {tradeLink && tradeLink?.linkType === 0 && (
                            <div className='order-update__attantion'>
                                <div><i className='far fa-copy icon-start' /></div>
                                <div className='order-update__attantion-text'>
                                    {t('Social.Close.Attention')}
                                </div>
                            </div>
                        )}
                        {/* {response && <div className='red error'>{t(response?.result)}</div>}
                        <Button className='btn btn--general' onClickHandler={handleUpdateOrder} text={t('InstrumetInfo.Details.Close')} />
                        <Button className='btn btn--general' onClickHandler={handleCancelOrderClose} text={t('General.Cancel')} /> */}
                    </div>
                    <div className='order-update__button-and-error'>
                        <div className='red error'>{response && t(response?.result)}</div>
                        <Button
                            className='btn btn--general'
                            onClickHandler={handleUpdateOrder}
                            text={`${t('InstrumetInfo.Details.Close')} ${symbolName} ${closeRate}`}
                        />
                    </div>
                </Fragment>
            ) : (
                <div className='order-update__container'>
                    <LoadSpinner />
                </div>
            )}
        </Fragment>
    );
};

// ClosePosition.propTypes = {};

export default ClosePosition;
