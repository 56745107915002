import React from 'react';

const MobileBurgerButton = ({onClick}) => {
	const handleClick = e => {
		e.preventDefault();
		onClick && onClick()
	};

	return (
			<button
					onClick={handleClick}
					className="mobile-button__menu"
			>
				<div className="mobile-button__menu__line"/>
				<div className="mobile-button__menu__line"/>
				<div className="mobile-button__menu__line"/>
			</button>
	);
};

export default MobileBurgerButton;
