import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { CommandFactory } from '../../../background/commands/CommandFactory';
import { QUESTIONER_MODE_EXTENRAL } from '../../../background/socialv1/socialv1Reducer';


const useDetailedProfileUserData = (nickname, period = 4) => {
    const {
        profileCommands: { getGetPlAndStats },
        socialAccount: { questionerMode, queryRiskScoreCMD, externalRiskScore }
    } = useSelector(
        (state) => state.account.socialv1, shallowEqual
    );

    const [userDetailProfile, setUserDetailProfile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getDetailedProfileData = useCallback(async () => {
        if (!nickname || !period) return null;
        const userInput = { nickname, timeframe: period || 4 };

        const command = CommandFactory.createCommand(getGetPlAndStats);
        setIsLoading(true);
        try {
            const res = await command.execute(userInput);
            if (res.isSuccessful) {
                setUserDetailProfile(res.payload);
            }
            setIsLoading(false);
        } catch (err) {
            console.error(err);
            setIsLoading(false);
        }
    }, [setIsLoading, getGetPlAndStats, nickname, period, setUserDetailProfile]);

    const getExternalRiskData = useCallback(async () => {
        if (!nickname) return null;
        setIsLoading(true);
        try {
            await CommandFactory.createCommand(queryRiskScoreCMD).execute();
            setIsLoading(false);
        } catch (err) {
            console.error(err);
            setIsLoading(false);
        }
    }, [nickname, queryRiskScoreCMD]);

    useEffect(() => {
        getDetailedProfileData().then(() => {
            if (questionerMode === QUESTIONER_MODE_EXTENRAL && !externalRiskScore) {
                getExternalRiskData();
            }
        }).then(() => setIsLoading(false));
    }, [getDetailedProfileData]);

    return {
        userDetailProfile,
        isLoading,
        requestData: () => getDetailedProfileData()
    };
};

export default useDetailedProfileUserData;
