export const unpack = (dataObj) =>
{
  if (!dataObj)
  {
    return undefined;
  }

  const payload = [];
  
  let iterators = Object.keys(dataObj).map(x=>propertyFactory(x,dataObj));

  if (iterators.length > 0)
  {
    while(listMoveNext(iterators))
    {
      let obj = {};
      for (let i = 0; i < iterators.length; i++) {
        iterators[i].setCurrent(obj);
      }
      payload.push(obj);
    }
  }

  return payload;
}

const propertyFactory = (key,obj)=>{
  
  const Mode_Iterate =0;
  const Mode_Duplicate =1;

  let currPackProp = undefined;
  if (key)
  {
    currPackProp = obj[key];
  } 
  else
  {
    // Empty key will force a simple prop, this is a desgin bug.
    currPackProp = obj;
  }
  
  if (!currPackProp)
  {
    return {
      moveNext: ()=>{ return false; },
      setCurrent:(obj)=>{},
      getCurrent:()=>{return undefined},
    }
  }

  if (key && currPackProp.o)
  {
    // This is an obj pack
    let objectIterators = Object.keys(currPackProp.o).map(x=>propertyFactory(x,currPackProp.o));
    let isValid = false;
    let headProp = propertyFactory("",currPackProp);
    return {
      moveNext:()=>{
        isValid = false;
        if (headProp.moveNext())
        {
          if (headProp.getCurrent() === 1)
          {
            for (let i = 0; i < objectIterators.length; i++) {
              objectIterators[i].moveNext();
            }
          }
          isValid = true;
          return true;
        }
        return false;
      },
      getCurrent:()=>{
        return headProp.getCurrent();
      },
      setCurrent:(obj)=>{
        if (isValid)
        {
          if (headProp.getCurrent() === 1)
          {
            let newObj = {};
            for (let i = 0; i < objectIterators.length; i++) {
              objectIterators[i].setCurrent(newObj);
            }
            obj[key] = newObj;  
          }
          else
          {
            obj[key] = null;
          }
        }
      }
    }
  }
  else
  {
    let currIndex = -1;
    let dupCounter = 0;
    let currMode = Mode_Iterate;
    let isValid = false;
    const reset = ()=>{
      currIndex = -1;
      dupCounter = 0;
      currMode = Mode_Iterate;
      isValid = false;
    };

    const moveNext = ()=>{
      if (!currPackProp)
      {
        isValid =false;
        return false;
      }

      if (currMode === Mode_Iterate)
      {
        if (currPackProp?.v)
        {
          if (currPackProp.v.length === (currIndex +1))
          {
            if (currIndex >= 0 && currPackProp?.c > 0)
            {
              currMode = Mode_Duplicate;
              dupCounter = 1;
              isValid =true;
              return true;
            }
          }
          else if (currPackProp.v.length > (currIndex +1))
          {
            currIndex++;
            isValid =true;
            return true;
          }
        }
      }
      else if (currMode === Mode_Duplicate)
      {
        if (currPackProp?.c === dupCounter)
        {
          currPackProp = currPackProp?.n;
          reset();
          return moveNext();
        }
        else if (currPackProp?.c > dupCounter)
        {
          dupCounter++;
          isValid =true;
          return true;
        }
      }

      isValid =false;
      return false;
    };
    return {
      getCurrent:()=>{
        return currPackProp.v[currIndex];
      },
      moveNext : moveNext,
      setCurrent:(obj)=>{
        if (isValid)
        {
          obj[key] = currPackProp.v[currIndex];
        }
      }
    }
  }
}

const listMoveNext = (array)=>
{
  let hasMore = false;
  for (let i = 0; i < array.length; i++) {
    if (array[i].moveNext())
    {
      hasMore = true;  
    }
  }
  return hasMore;
}