import React from 'react';
import PropTypes from 'prop-types';

const MobileClosedPositionActiveBlock = ({title, data}) => {
	return (
			<div className="mobile-closed-position__active__block">
				<div className="mobile-closed-position__active__block__title">
					{title}
				</div>
				<div className="mobile-closed-position__active__block__data">
					{data}
				</div>
			</div>
	);
};

MobileClosedPositionActiveBlock.defaultProps = {
	title: '',
	data: '',
};

MobileClosedPositionActiveBlock.propTypes = {
	title: PropTypes.string,
	data: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.node,
	]),
};

export default MobileClosedPositionActiveBlock;
