import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';

const MobileTab = ({options, value, onClick}) => {
	const {t} = useTranslation();

	const handleClick = useCallback((e, props) => {
		onClick && onClick(props);
	}, [onClick]);

	return (
			<div className="mobile-tab">
				<ul className="mobile-tab__list">
					{options && !!options.length && (
							<>
								{options.map((opt, index, array) => (
										<li
												key={`tab-${index}-${opt.label}`}
												onClick={e => handleClick(e, {index, ...opt})}
												className="mobile-tab__item"
												style={{width: `${100 / array.length - 1}%`}}
										>
											<div className={clsx('mobile-tab__item__inner',
													value === index && 'active')}
											>
												{opt.translate ? t(opt.translate) : opt.label}
											</div>
										</li>
								))}
							</>
					)}
				</ul>
			</div>
	);
};

MobileTab.defaultProps = {
	options: [],
	value: 0,
};

export default MobileTab;
