import React from 'react';
// import PropTypes from 'prop-types';

const LoadingIcon = ({ fill }) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50' fill='none'>
            <g clipPath='url(#clip0)'>
                <path d='M1.77787 34.2597C-3.32762 21.4547 2.93616 6.88396 15.7412 1.77848C28.5461 -3.32701 43.1168 2.93677 48.2223 15.7418C49.9518 20.0795 50.435 24.7906 49.6192 29.3666C49.477 30.1662 48.713 30.6984 47.9134 30.5563C47.1135 30.4134 46.5798 29.65 46.7237 28.8504C47.4433 24.815 47.0164 20.6588 45.4902 16.8311C40.9854 5.53267 28.1284 0.00585149 16.8303 4.51052C5.53223 9.01519 0.00507452 21.872 4.50987 33.1704C9.01454 44.4685 21.8717 49.9956 33.1698 45.4909C35.4407 44.5855 37.5236 43.3279 39.3602 41.753C39.9765 41.2244 40.9055 41.296 41.434 41.9123C41.9618 42.5306 41.891 43.4576 41.2747 43.9862C39.1913 45.7717 36.831 47.1976 34.259 48.2231C21.454 53.3284 6.88335 47.0646 1.77787 34.2597Z' fill={fill} />
                <path d='M23 14.5V27.5H34.5' stroke={fill} strokeWidth='3' strokeLinecap='round' />
            </g>
            <defs>
                <clipPath id='clip0'>
                    <rect width='50' height='50' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

// LoadingIcon.propTypes = {};

export default LoadingIcon;
