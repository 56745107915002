import React, {useCallback} from 'react';
import clsx from 'clsx';

//TODO design data
// import {TIME_SWITCH} from '../../constants/options'

const isActive = (value, option) => {
	return value === option.timeKey;
};

const MobileChartSwitch = ({type, options, value, onChange}) => {

	const handleClick = useCallback((e, option) => {
		e.preventDefault();
		onChange && onChange(option);
	}, []);

	return (
			<div className={clsx('mobile-chart-switch', type)}>
				<ul className="mobile-chart-switch__list">
					{options && options.slice(0, 4).map((option, index) => (
							<li
									key={index}
									className={clsx('mobile-chart-switch__item',
											isActive(value, option) && 'active')}
									onClick={e => handleClick(e, option)}
							>
								{option.timeValue}
							</li>
					))}
				</ul>
			</div>
	);
};

export default MobileChartSwitch;
