export const RISK_LEVELS = {
    RISK_LOW: 0,
    RISK_MEDIUM: 1,
    RISK_HIGH: 2
};

export const RISK_LEVEL_LABELS = [
    'General.Low',
    'General.Medium',
    'General.High'
];

export const RISK_LEVEL_COLORS = [
    '#3CC09B',
    '#F2994A',
    '#EB5757'
];
