import React from 'react';
// import PropTypes from 'prop-types';

const LineIcon = ({ fill }) => {
    return (
        <svg width='90' height='80' viewBox='0 0 90 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M5.5 6C6.32843 6 7 6.67157 7 7.5V70H85C85.8284 70 86.5 70.6716 86.5 71.5C86.5 72.3284 85.8284 73 85 73H6.5C5.11929 73 4 71.8807 4 70.5V7.5C4 6.67157 4.67157 6 5.5 6Z' fill={fill} />
            <path fillRule='evenodd' clipRule='evenodd' d='M11.6888 64.2405C11.9162 64.3846 12.1801 64.4611 12.4495 64.4608C12.6559 64.46 12.8597 64.4141 13.0465 64.3264C13.2332 64.2387 13.3985 64.1113 13.5308 63.9532L31.3179 42.6953L40.6229 50.4534C40.7657 50.5724 40.9307 50.6621 41.1083 50.7175C41.2859 50.7728 41.4726 50.7927 41.6579 50.776C41.8432 50.7593 42.0234 50.7063 42.1882 50.6201C42.353 50.5339 42.4991 50.4162 42.6183 50.2736L55.7317 34.6083L65.0382 42.3664C65.3264 42.6064 65.6983 42.7224 66.0721 42.6889C66.446 42.6555 66.7913 42.4753 67.0321 42.188L81.3246 25.1111L81.7952 30.44C81.8267 30.7974 81.9885 31.1299 82.2486 31.3719C82.5087 31.614 82.8484 31.7482 83.2007 31.748L83.3291 31.7478C83.7016 31.7136 84.0456 31.5302 84.2853 31.2377C84.525 30.9453 84.6409 30.5679 84.6074 30.1884L83.884 21.9968C84.0049 21.7586 84.0566 21.489 84.0299 21.2193C83.9933 20.8506 83.8132 20.511 83.5281 20.2736C83.2452 20.0379 82.8814 19.9216 82.5141 19.9492C82.4379 19.9436 82.3613 19.9444 82.2849 19.9515L73.5197 20.7526C73.1469 20.7866 72.8027 20.9699 72.5627 21.2624C72.3227 21.5548 72.2066 21.9324 72.24 22.312C72.2733 22.6917 72.4533 23.0423 72.7405 23.2867C73.0276 23.5312 73.3983 23.6495 73.7711 23.6155L79.3108 23.1084L65.7641 39.2943L56.4591 31.5362C56.3163 31.4172 56.1513 31.3275 55.9737 31.2721C55.7961 31.2168 55.6094 31.1969 55.4241 31.2136C55.2388 31.2303 55.0586 31.2833 54.8938 31.3695C54.729 31.4557 54.5829 31.5734 54.4637 31.716L41.3517 47.3855L32.0453 39.6275C31.7569 39.3876 31.3849 39.2719 31.011 39.3056C30.6371 39.3393 30.2919 39.5198 30.0513 39.8073L11.3609 62.142C11.1883 62.3484 11.0782 62.5996 11.0436 62.8662C11.0091 63.1328 11.0514 63.4037 11.1657 63.6471C11.2799 63.8905 11.4614 64.0964 11.6888 64.2405Z' fill={fill} />
        </svg>
    );
};

// LineIcon.propTypes = {};

export default LineIcon;
