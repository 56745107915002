/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// import PropTypes from 'prop-types';

import useWindowSize from '../utilsUI/useWindowSize';

import TopRankedItem from './TopRankedItem';


const UsersList = ({ users, handleItemSelect }) => {
    const { t } = useTranslation();
    const { SubHeader: { Text } } = useSelector((state) => (state.brand.theme));
    const [width] = useWindowSize();
    const isSmallDesktop = width < 1200;

    return (
        <div className='socials-top-ranked'>
            <table className='right-panel__table'>
                <thead className='top-ranked__heading'>
                    <tr style={{ color: Text }}>
                        {!isSmallDesktop && <th />}
                        <th>{t('Socials.Name')}</th>
                        <th>{t('Socials.PNL')}</th>
                        <th>{t('Socials.Copiers')}</th>
                    </tr>
                </thead>
                <tbody>
                    {users && users.map(
                        (trader, index) => {
                            return (
                                <TopRankedItem trader={trader} handleItemSelect={handleItemSelect} key={trader.Nickname} index={index} isSmallDesktop={isSmallDesktop} />
                            );
                        }
                    )}
                </tbody>
            </table>
        </div>
    );
};

// UsersList.propTypes = {};

export default UsersList;