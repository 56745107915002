import produce from "immer"
import { replaceIfChanged } from '../StoreUtils/overrideState';
const  initState = {
  positions:{},
  getAllCommand:null,
  isGetAllRequsted:false
};

export const closedPositionsReducer = function closedPositionsReducer(state = initState, action){
  switch (action.type) {
    case "login-logout":
      return initState;
    case "closed-possitions-arrived":
    {
      let serverClosed = action.payload.closedPositions;
      return produce(state,draf=>{
        HandleClosedPositions(draf, serverClosed);
        draf.isGetAllRequsted = true;
      });  
    }
    case "update-trading-data":
    case "login-success":
    {
      let serverClosed = action.payload.tradingData.closedPositions;
      return produce(state,draf=>{
        HandleClosedPositions(draf, serverClosed);
      });
    }
    case "init-brand+login":
    {
      let serverClosed = action.payload.loginTradingData.tradingData.closedPositions;
      return produce(state,draf=>{
        HandleClosedPositions(draf, serverClosed);
      });
    }
      default:
        return state; 
  }
}

function HandleClosedPositions(draf, serverClosed) {
  if (draf.isGetAllRequsted)
  {
    draf.getAllCommand = null;
  }
  else
  {
    replaceIfChanged(draf,'getAllCommand', serverClosed.getAllCommand);
  }
  
  let statePositions = draf.positions;

  let serverPositions = serverClosed.positions;
  for (let serverPositionKey in serverPositions) {
    let serverPosition = serverPositions[serverPositionKey];
    let statePosition = statePositions[serverPosition.id];
    if (!statePosition) {
      statePosition = { id: serverPosition.id };
      statePositions[serverPosition.id] = statePosition;
    }
    statePosition.tpId = serverPosition.tpId;
    statePosition.actionType = serverPosition.actionType;
    statePosition.amount = serverPosition.amount;
    statePosition.symbolId = serverPosition.symbolId;
    statePosition.symbolName = serverPosition.symbolName;
    statePosition.openRate = serverPosition.openRate;
    statePosition.closeRate = serverPosition.closeRate;
    statePosition.openTimeS = serverPosition.openTimeS;
    statePosition.profit = serverPosition.profit;
    statePosition.takeprofit = serverPosition.takeprofit;
    statePosition.stoploss = serverPosition.stoploss;
    statePosition.closeTimeS = serverPosition.closeTimeS;
    statePosition.closeTimeUTC = serverPosition.closeTimeUTC;
    statePosition.commission = serverPosition.commission;
    statePosition.taxes = serverPosition.taxes;
    statePosition.swaps = serverPosition.swaps;
    statePosition.marginRate = serverPosition.marginRate;
    statePosition.closedMargin = serverPosition.closedMargin;

    statePosition.profitWithCommission = statePosition.profit + statePosition.commission + statePosition.taxes + statePosition.swaps;
  }

  return draf;
}
