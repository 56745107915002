import React, {
    useEffect, useState, useRef, Fragment
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { CommandFactory } from '../../background/commands/CommandFactory';
import Button from '../primitives/Button';
import SearchIcon from '../icons/SearchIcon';

import ColumnsSelectExpanded from './ColumnsSelectExpanded';


// eslint-disable-next-line no-unused-vars
const LeftPanelActionsBlock = ({
    searchSubmit,
    symbolGroups,
    // selectSubmit,
    shouldDisplayPin,
    setShouldDisplayPin,
    shouldDisplayInfo,
    setShouldDisplayInfo,
    // shouldDisplayFlags,
    // setShouldDisplayFlags,
    shouldDisplayTime,
    setShouldDisplayTime,
    width,
    initialLeftWidth
}) => {
    const [stateObject, setStateObject] = useState({ symbol: '', symbolGroup: symbolGroups[0], checkboxes: [] });
    const [isColumnsSelectOpen, setIsColumnsSelectOpen] = useState(false);
    const timeoutRef = useRef(null);

    const { t } = useTranslation();
    const { Buttons: { TextColor }, Text: { GeneralColor, SystemColor } } = useSelector((state) => (state.brand.theme));

    const { symbol, symbolGroup } = stateObject;

    useEffect(() => {
        if (timeoutRef.current !== null) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            timeoutRef.current = null;
            // eslint-disable-next-line no-use-before-define
            handleSearchSubmit();
        }, 333);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [symbol]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setStateObject((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleSearchSubmit = (s = symbol, sGroup = symbolGroup) => {
        // setStateObject((prevState) => ({ ...prevState, symbolGroup: symbolGroups[0] }));
        searchSubmit(s, sGroup);
    };

    const handleSelectSubmit = (e) => {
        setStateObject((prevState) => ({ ...prevState, symbolGroup: e.target.value }));
        // handleChange(e);
        handleSearchSubmit(undefined, e.target.value);
        // setStateObject((prevState) => ({ ...prevState, symbol: ''}));
        // selectSubmit(e.target.value);
    };

    const toggleColumnsSelect = () => {
        setIsColumnsSelectOpen((prevState) => !prevState);
    };

    const togglePin = () => {
        const command = CommandFactory.createCommand(setShouldDisplayPin);
        command.execute();
    };

    const toggleInfo = () => {
        const command = CommandFactory.createCommand(setShouldDisplayInfo);
        command.execute();
    };

    // const toggleFlags = () => {
    //     setShouldDisplayFlags((prevState) => !prevState);
    // };

    const toggleTime = () => {
        const command = CommandFactory.createCommand(setShouldDisplayTime);
        command.execute();
    };

    const resetSearch = () => {
        setStateObject((prevState) => ({ ...prevState, symbol: '' }));
        searchSubmit(null, 'MarketRates.Groups.All');
    };

    const icon = symbol ? (
        <InputAdornment position='end'>
            <Icon className='fas fa-times' onClick={resetSearch} />
        </InputAdornment>
    ) : (
        <InputAdornment position='end'>
            {/* <Icon className='fas fa-search' /> */}
            <SearchIcon fill={GeneralColor} />
        </InputAdornment>
    );

    const handleCloseColumnsSelect = (evt) => {
        // eslint-disable-next-line no-unused-expressions
        evt && evt.stopPropagation();
        toggleColumnsSelect();
    };

    const handleOpenColumnsSelect = (evt) => {
        evt.stopPropagation();
        toggleColumnsSelect();
    };

    const symbolSearch = (
        <Input
            id='symbol-search'
            placeholder={t('MarketRates.Search')}
            name='symbol'
            // className={initialLeftWidth > 300 ? 'left-panel__symbol-search left-panel__symbol-search--large' : 'left-panel__symbol-search'}
            className='left-panel__symbol-search left-panel__symbol-search--large'
            value={symbol}
            onChange={handleChange}
            endAdornment={icon}
            style={{ color: TextColor }}
            autoComplete='off'
        />
    );
    const isSmallDesktop = width < 1200;


    return (
        <Fragment>
            <div className='left-panel__actions'>
                {/* {!isSmallDesktop && <div>{symbolSearch}</div>} */}
                {!isSmallDesktop && symbolSearch}
                <div className='left-panel__actions-grow'>
                    <FormControl className='left-panel__symbol-select'>
                        <Select
                            id='symbol-select'
                            placeholder={t('MarketRates.All')}
                            IconComponent={KeyboardArrowDownIcon}
                            name='symbolGroup'
                            value={symbolGroup}
                            onChange={handleSelectSubmit}
                            className={initialLeftWidth < 100 ? 'no-min-width' : ''}
                            style={{ color: TextColor }}
                        >
                            {symbolGroups.map((group) => (
                                <MenuItem key={group} value={group} style={{ color: SystemColor }}>
                                    {t(group)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div className='left-panel__actions__columns-settings'>
                        <Button
                            className='btn btn--secondary'
                            style={{ color: TextColor }}
                            onClickHandler={!isColumnsSelectOpen ? handleOpenColumnsSelect : null}
                            iconStart='fas fa-sliders-h'
                        />
                        {isColumnsSelectOpen && (
                            <ColumnsSelectExpanded
                                shouldDisplayPin={shouldDisplayPin}
                                shouldDisplayInfo={shouldDisplayInfo}
                                // shouldDisplayFlags={shouldDisplayFlags}
                                shouldDisplayTime={shouldDisplayTime}
                                setShouldDisplayPin={togglePin}
                                setShouldDisplayInfo={toggleInfo}
                                // setShouldDisplayFlags={toggleFlags}
                                setShouldDisplayTime={toggleTime}
                                handleClick={handleCloseColumnsSelect}
                                generalColor={GeneralColor}
                            />
                        )}
                    </div>
                </div>
            </div>
            {isSmallDesktop && (
                <div className='symbol-search__container'>{symbolSearch}</div>
            )}
        </Fragment>
    );
};


export default LeftPanelActionsBlock;
