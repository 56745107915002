import React, {useCallback, useMemo} from 'react';
import clsx from 'clsx';
import MobileLoader from '../MobileLoader/MobileLoader';

const propsVariables = {
	size: ['lg', 'md'],
	type: ['primary', 'secondary'],
};

const MobileButton = props => {
	const {
		name,
		onClick,
		size,
		type,
		title,
		children,
		style,
		className,
		disabled,
		isLoading,
	} = props;

	const isDisabled = useMemo(() => {
		return isLoading
				? true
				: !!disabled;
	}, [disabled, isLoading, onClick]);

	const handleButtonClick = useCallback(e => {
		e && e.preventDefault();
		e && e.stopPropagation();
		if (isDisabled) return;
		return onClick && onClick();
	}, [onClick, isLoading, isDisabled]);

	const renderButton = useMemo(() => {
		return (
				<>
					<MobileLoader
							isLoading={isLoading}
							size="small"
							overlay
					/>
					{title && (
							<p className="mobile-button__main__title">
								{title.toLowerCase()}
							</p>
					)}
					{children && (
							<>
								{children}
							</>
					)}
				</>
		);
	}, [isLoading, title, children]);

	return (
			<button
					type="button"
					name={name}
					className={clsx('mobile-button__main',
							type,
							size,
							className,
							isDisabled && 'disabled',
					)}
					style={{...style}}
					onClick={e => handleButtonClick(e)}
			>
				{renderButton}
			</button>
	);
};

MobileButton.defaultProps = {
	name: '',
	size: 'lg',
	type: 'primary',
	title: '',
	style: {},
	children: null,
	onClick: null,
	isLoading: false,
};

export default MobileButton;
