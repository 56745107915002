import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {formatDateOnly} from '../../../../UI/utilsUI/formatTime';
import {useSelector} from 'react-redux';
import { CommandFactory } from '../../../../background/commands/CommandFactory';
import {createChartData} from '../../../helpers/chart.helpers';
import MobileAreaChart from '../../MobileChart/MobileAreaChart/MobileAreaChart';

const MobileSocialExploreChart = ({userName}) => {
	const [chartData,setChartData] = useState([]);
	const {getGetPlAndStats} = useSelector(state => state.account.socialv1.profileCommands);	
	const [error, setError] = useState(false);
	const [isLoading, setIsLoading] = useState(true)

	const fetchDataAsync = async ()=>
	{
		try
		{
			const res =await  CommandFactory.createCommand(getGetPlAndStats).execute({nickname: userName, timeframe: 1});
			if (res.isSuccessful) {
				let DailyPLs = res.payload?.DailyPLs;
				if (DailyPLs)
				{
					const pnl = DailyPLs;
					let total = 0;
					if (!pnl && !pnl.length) return null;
					const localLabels = pnl.map(item => {
						const dOnly = `${formatDateOnly(new Date(item.Date))}`;
						const sep = item.NormalizedPL > 0 ? '+' : '';
						const pnl = ((Math.round(item.NormalizedPL * 100000) / 100000) *
								100).toFixed(2);
						return `${dOnly} / ${sep}${pnl}%`;
					});
					const localValues = pnl.map(item => {
						total += (Math.round(item.NormalizedPL * 100000) / 100000);
						return total;
					});

					setChartData(createChartData(localValues,localLabels));
				}
			} else {
				setError(res);
			}
			setIsLoading(false);
		}
		catch(e)
		{
			setIsLoading(false);
		}
	}

	useEffect(()=>{
		
		fetchDataAsync();
		//return function cleanup() {		};
	},[userName]);

	/*const DailyPLs = useMemo(() => {
		return userDetailedProfile?.DailyPLs;
	}, [userDetailedProfile]);

	const getDetailedProfileData = useCallback(async () => {
		if (!userName || !timeframe || !getGetPlAndStats) return null;
		const userInput = {nickname: userName, timeframe: timeframe};

		
		setIsLoading(true);
		try {
			console.log("DDDDDDD");
			const res = await command.execute(userInput);

			if (!res.ok) {
				setError({result: res.statusText});
				setIsLoading(false);
			}
			if (res.isSuccessful) {
				setIsLoading(false);
				setDetailedUserProfile(res.payload);
			} else if (!res.isSuccessful) {
				setIsLoading(false);
				setError(res);
			}
		} catch (err) {
			setIsLoading(false);
			setError(err);
		}
	}, [getGetPlAndStats, userName, timeframe]);

	const getlocalData = useCallback(() => {
		if (!DailyPLs || !userName) return null;
		const pnl = DailyPLs;
		let total = 0;
		if (!pnl && !pnl.length) return null;
		const localLabels = pnl.map(item => {
			const dOnly = `${formatDateOnly(new Date(item.Date))}`;
			const sep = item.NormalizedPL > 0 ? '+' : '';
			const pnl = ((Math.round(item.NormalizedPL * 100000) / 100000) *
					100).toFixed(2);
			return `${dOnly} / ${sep}${pnl}%`;
		});
		const localValues = pnl.map(item => {
			total += (Math.round(item.NormalizedPL * 100000) / 100000);
			return total;
		});
		setValues(localValues);
		setLabels(localLabels);
	}, [DailyPLs, setValues, setLabels, userName]);

	useEffect(() => {
		if (!userName) return null;
		getDetailedProfileData();
		getlocalData();
	}, [getDetailedProfileData, getlocalData, userName]);

	const newData = useMemo(() => {
		return createChartData(values, labels);
	}, [values, labels]);*/

	return (
			<>
				<MobileAreaChart
						data={chartData}
						height={82}
				/>
			</>
	);
};

export default MobileSocialExploreChart;
