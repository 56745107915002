import React from 'react';
import { useTranslation } from 'react-i18next';

import OuterClick from '../primitives/OuterClick';

import './TickItemToolTipContent.css';


const TickItemToolTipContent = ({ tickData, onCloseTooltip, generalColor, systemColor, hideHeadings }) => {
    const { t } = useTranslation();
    const {
        description,
        id,
        pipDigit,
        contractSize,
        group,
        leverage,
        swapLong,
        swapShort,
        swapType,
        minimumLot,
        maximumLot,
        lotStep,
        stopLevel
    } = tickData;


    return (
        <div className='tick-item__tooltip__content'>
            {!hideHeadings && (
                <h2>
                    {id}
                    <i className='fas fa-times' onClick={onCloseTooltip} />
                </h2>
            )}
            <div className='tick-item__tooltip__content__info'>
                {!hideHeadings && <h3>{description}</h3>}
                <div className='grid-table'>
                    <div className='grid-table__row'>
                        <span style={generalColor}>{t('InstrumetInfo.Details.Instrument')}</span>
                        <span style={systemColor}>{id}</span>
                    </div>
                    <div className='grid-table__row'>
                        <span style={generalColor}>{t('InstrumetInfo.Details.SecurityGroupName')}</span>
                        <span style={systemColor}>{group}</span>
                    </div>
                    <div className='grid-table__row'>
                        <span style={generalColor}>{t('InstrumetInfo.Details.Digits')}</span>
                        <span style={systemColor}>{pipDigit}</span>
                    </div>
                    <div className='grid-table__row'>
                        <span style={generalColor}>{t('InstrumetInfo.Details.StopLossLevel')}</span>
                        <span style={systemColor}>{stopLevel}</span>
                    </div>
                    <div className='grid-table__row'>
                        <span style={generalColor}>{t('InstrumetInfo.Details.Leverage')}</span>
                        <span style={systemColor}>
                            1:
                            {leverage.toFixed()}
                        </span>
                    </div>
                    <div className='grid-table__row'>
                        <span style={generalColor}>{t('InstrumetInfo.Details.LotSize')}</span>
                        <span style={systemColor}>{contractSize}</span>
                    </div>
                    <div className='grid-table__row'>
                        <span style={generalColor}>{t('InstrumetInfo.Details.TradingAmountMinimum')}</span>
                        <span style={systemColor}>{minimumLot}</span>
                    </div>
                    <div className='grid-table__row'>
                        <span style={generalColor}>{t('InstrumetInfo.Details.TradingAmountMaximum')}</span>
                        <span style={systemColor}>{maximumLot}</span>
                    </div>
                    <div className='grid-table__row'>
                        <span style={generalColor}>{t('InstrumetInfo.Details.TradingAmountStep')}</span>
                        <span style={systemColor}>{lotStep}</span>
                    </div>
                    <div className='grid-table__row'>
                        <span style={generalColor}>{t('InstrumetInfo.Details.SwapLong')}</span>
                        <span style={systemColor}>{swapLong}</span>
                    </div>
                    <div className='grid-table__row'>
                        <span style={generalColor}>{t('InstrumetInfo.Details.SwapShort')}</span>
                        <span style={systemColor}>{swapShort}</span>
                    </div>
                    <div className='grid-table__row'>
                        <span style={generalColor}>{t('InstrumetInfo.Details.SwapType')}</span>
                        <span style={systemColor}>{swapType}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default OuterClick(TickItemToolTipContent);
