import React, {useCallback, useMemo} from 'react';
// import PullToRefresh from 'react-simple-pull-to-refresh'; //TODO: remove pack
import {
	PullDownContent,
	PullToRefresh,
	RefreshContent,
	ReleaseContent,
} from 'react-js-pull-to-refresh';

import {StatusModes} from '../../background/reducers/generalStatusReducer';
import {shallowEqual, useSelector} from 'react-redux';

const MobilePullToRefresh = ({children}) => {

	const {
		generalStatus: {
			status: isNotLoggedIn,
		},
	} = useSelector(({generalStatus}) => ({generalStatus}), shallowEqual);

	const handleOnRefresh = useCallback(() => {
		document.location.reload();
	}, []);

	const {requireServerInit, requireLogin} = useMemo(() => {
		return StatusModes;
	}, [StatusModes]);

	const shouldRenderLoginPage = useMemo(() => {
		return isNotLoggedIn === requireServerInit
				|| isNotLoggedIn === requireLogin;
	}, [isNotLoggedIn, requireServerInit, requireLogin]);

	const bgColor = useMemo(() => {
		return shouldRenderLoginPage ? 'transparent' : '#13182C';
	}, [shouldRenderLoginPage]);

	const options = useMemo(() => {
		return {
			pullDownContent: <PullDownContent/>,
			releaseContent: <ReleaseContent/>,
			refreshContent: <RefreshContent/>,
			onRefresh: handleOnRefresh,
			pullDownThreshold: 70,
			triggerHeight: 50,
			backgroundColor: bgColor,
			startInvisible: true,
		};
	}, [
		PullDownContent,
		ReleaseContent,
		RefreshContent,
		handleOnRefresh,
		bgColor,
	]);

	return (
			<PullToRefresh {...options}>
				{children}
			</PullToRefresh>

	);
};

export default MobilePullToRefresh;
