import React from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';

import Button from '../primitives/Button';


const UnlinkPopup = ({ handleUnlinkOrder, handleClick, orderId }) => {
    const { t } = useTranslation();
    const handleUnlinkAction = () => {
        handleUnlinkOrder(orderId);
    };

    return (
        <div className='profile-watch-opened unlink-order--opened'>
            <div>
                {t('Unlink.Attention')}
                <p>{t('Unlink.Text')}</p>
            </div>
            <div className='profile-watch-opened__actions'>
                <Button text={t('InstrumetInfo.Details.Unlink')} className='btn btn-copy' onClickHandler={handleUnlinkAction} />
                <Button text={t('General.Cancel')} className='btn btn-cancel btn--general' onClickHandler={handleClick} />
            </div>
        </div>
    );
};

// UnlinkPopup.propTypes = {};

export default UnlinkPopup;
