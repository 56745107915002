import React from 'react';
// import PropTypes from 'prop-types';

const AreaIcon = ({ fill }) => {
    return (
        <svg width='90' height='80' viewBox='0 0 90 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M5.5 6C6.32843 6 7 6.67157 7 7.5V70H85C85.8284 70 86.5 70.6716 86.5 71.5C86.5 72.3284 85.8284 73 85 73H6.5C5.11929 73 4 71.8807 4 70.5V7.5C4 6.67157 4.67157 6 5.5 6Z' fill={fill} />
            <path d='M10 60.8757V64.5C10 65.6046 10.8954 66.5 12 66.5H82.5C83.6046 66.5 84.5 65.6046 84.5 64.5V18.4021C84.5 17.0013 83.0971 16.0344 81.788 16.5331L74 19.5L61.1589 24.256C60.7324 24.4139 60.3725 24.7125 60.1385 25.1025L52.0826 38.529C51.7212 39.1314 51.0701 39.5 50.3676 39.5H39.9664C39.3559 39.5 38.7788 39.7789 38.3995 40.2572L28.1005 53.2428C27.7212 53.7211 27.1441 54 26.5336 54H17.282C16.7792 54 16.2949 54.1894 15.9254 54.5304L10.6434 59.4061C10.2333 59.7847 10 60.3175 10 60.8757Z' fill={fill} />
            <path d='M12 50.5L14.7071 47.7929C14.8946 47.6054 15.149 47.5 15.4142 47.5H24.5194C24.8232 47.5 25.1105 47.3619 25.3002 47.1247L36.6998 32.8753C36.8895 32.6381 37.1768 32.5 37.4806 32.5H48.4271C48.782 32.5 49.1103 32.3119 49.2898 32.0057L57.3218 18.3039C57.4371 18.1072 57.6162 17.9558 57.8293 17.8749L82.5 8.5' stroke={fill} strokeWidth='4' strokeLinecap='round' />
        </svg>
    );
};

// AreaIcon.propTypes = {};

export default AreaIcon;
