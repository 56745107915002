import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import { CommandFactory } from '../../background/commands/CommandFactory';


const LanguageSelector = ({ languages, languageCode, t, additionalAction }) => {
    const [, setResponse] = useState('');
    const languagesToRender = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const k in languages) {
        languagesToRender.push(languages[k]);
    }
    const handleLanguageChange = async (e) => {
        const command = languagesToRender.find((item) => item.code === e.target.value);

        try {
            setResponse(null);
            const res = await CommandFactory.createCommand(command.selectLanguageCommand).execute();
            // eslint-disable-next-line no-unused-expressions
            additionalAction && additionalAction();
            if (res.isSuccessful === false) {
                setResponse(res);
            }
        } catch (err) {
            setResponse(err);
        }
    };


    return (
        <FormControl fullWidth>
            <TextField
                id='standard-select-language'
                select
                placeholder='language'
                autoComplete='language'
                name='language'
                value={languageCode}
                onChange={handleLanguageChange}
            >
                {languagesToRender.map((option) => (
                    <MenuItem key={option.code} value={option.code} className='language-selector'>
                        {t(option.displayName)}
                    </MenuItem>
                ))}
            </TextField>
        </FormControl>
    );
};

// LanguageSelector.propTypes = {};

const mapStateToProps = (state) => {
    const { languages, languageCode } = state.brand;

    return {
        languages,
        languageCode
    };
};

export default connect(mapStateToProps)(withTranslation()(LanguageSelector));
