import React, {useCallback, useEffect, useState} from 'react';
import { CommandFactory } from '../../../background/commands/CommandFactory'
import {shallowEqual, useSelector} from 'react-redux';

const usePortfolioData = (nickname, timeframe = 3) => {
	const {getPortfolioBreakdown} = useSelector(
			state => state.account.socialv1.profileCommands, shallowEqual);

	const [portfolioData, setPortfolioData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const requestPortfolio = useCallback(async () => {
		if (!nickname) return null;
		const userInput = {timeframe: timeframe, nickname};

		const command = CommandFactory.createCommand(getPortfolioBreakdown);
		setIsLoading(true);
		try {
			const res = await command.execute(userInput);
			if (res.isSuccessful) {
				setPortfolioData(res.payload);
			}
			setIsLoading(false);
		} catch (err) {
			console.error(err);
			setIsLoading(false);
		}

	}, [setIsLoading, getPortfolioBreakdown, nickname, timeframe]);

	useEffect(() => {
		requestPortfolio().then(() => setIsLoading(false));
	}, [requestPortfolio]);

	return {
		portfolioData,
		isLoading,
		requestData: () => requestPortfolio(),
	};

};

export default usePortfolioData;
