import React, { Component } from 'react';

class ChartDealElemenet extends React.Component {

  constructor(props) {
    super(props);

    this.beginEditSl = false;
    this.beginEditTp = false;
  }

  componentDidMount() {
    if (!this.deal) {
      this.addDealToChart();
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.deal) {
      this.addDealToChart();
    } else {

      if(prevProps.title !== this.props.title){
        this.deal.title(this.props.title ?? '');
      }

      if(prevProps.color !== this.props.color){
        this.deal.color(this.props.color ?? 'green');
      }

      if (prevProps.tpValue !== this.props.tpValue) {
        this.deal.takeProfit(this.props.tpValue === 0 ? null : this.props.tpValue);
      }

      if (prevProps.slValue !== this.props.slValue) {
        this.deal.stopLoss(this.props.slValue === 0 ? null : this.props.slValue);
      }

      if (prevProps.tpDisplay !== this.props.tpDisplay) {
        this.deal.profit(this.props.tpDisplay ?? '');
      }

      if (prevProps.slDisplay !== this.props.slDisplay) {
        this.deal.loss(this.props.slDisplay ?? '');
      }

      if (prevProps.display !== this.props.display) {
        this.deal.net(this.props.display ?? '');
      }
    }
  }

  addDealToChart() {
    if(!this.props.chartController || !this.props.symbol || !this.props.openTime || !this.props.openRate){
      return;
    }

    var deal = this.props.chartController.addDeal(this.props.symbol, Math.trunc(this.props.openTime / 1000), this.props.openRate);
    let thisScope = this;
    if (deal) {
      setTimeout(() => {
        if (thisScope.deal?.plt)
        {
          thisScope.deal.plt.panel().select(deal);
        }
      }, 0);
      this.deal = deal;
      this.deal.type(this.props.actionType === 0 ? 'buy' : 'sell');
      this.deal.title(this.props.title ?? '');
      this.deal.loss(this.props.slDisplay ?? '');
      this.deal.profit(this.props.tpDisplay ?? '');
      this.deal.net(this.props.display ?? '');
      this.deal.color(this.props.color ?? 'green');
      this.deal.stopLoss(this.props.slValue === 0 ? null : this.props.slValue);
      this.deal.takeProfit(this.props.tpValue === 0 ? null : this.props.tpValue);

      this.deal.on('changeTakeProfit', function () {
        if(!thisScope.beginEditTp){
          thisScope.beginEditTp = true;;
          thisScope.props.onTPValueBeginUpdate && thisScope.props.onTPValueBeginUpdate();
         }
         else{
          thisScope.props.onTPValueUpdated && thisScope.props.onTPValueUpdated();
         }
      });

      this.deal.on('setTakeProfit', function () {
        thisScope.beginEditTp = false;
        thisScope.props.onTPValueEndUpdate && thisScope.props.onTPValueEndUpdate();
      });

      deal.on('closeTakeProfit', async function () {
        thisScope.props.onTPClear && thisScope.props.onTPClear();
      });

      this.deal.on('changeStopLoss', function () {
        if(!thisScope.beginEditSl){
          thisScope.beginEditSl = true;
          thisScope.props.onSLValueBeginUpdate && thisScope.props.onSLValueBeginUpdate();
         }
         else{
          thisScope.props.onSLValueUpdated && thisScope.props.onSLValueUpdated();
         }
      });

      this.deal.on('setStopLoss', async function () {
        thisScope.beginEditSl = false;
        thisScope.props.onSLValueEndUpdate && thisScope.props.onSLValueEndUpdate();
      });


      deal.on('closeStopLoss', async function () {
        thisScope.props.onSLClear && thisScope.props.onSLClear();
      });
    }

  }

  componentWillUnmount() {
    if (this.deal) {
      this.deal.drop();
      this.deal = null;
    }
  }

  render() {
    return null;
  }
}

export default ChartDealElemenet;

