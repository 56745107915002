import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import MobileTransition from '../../../components/MobileTransition';

const MobileTradeError = ({showError = ''}) => {
	const {t} = useTranslation();

	const isOpen = useMemo(() => {
		return !!showError || false;
	}, [showError]);

	return (
		<MobileTransition
			isOpen={isOpen}
			type="fade"
		>
			<span className='input-description error'>
				{t(showError)}
			</span>
		</MobileTransition>
	);
};

export default MobileTradeError;
