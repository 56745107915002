import React from 'react';

import DefaultAvatar1 from '../assets/avatars/defaultAvatar1.svg';
import DefaultAvatar2 from '../assets/avatars/defaultAvatar2.svg';
import DefaultAvatar3 from '../assets/avatars/defaultAvatar3.svg';
import DefaultAvatar4 from '../assets/avatars/defaultAvatar4.svg';
import DefaultAvatar5 from '../assets/avatars/defaultAvatar5.svg';
import DefaultAvatar6 from '../assets/avatars/defaultAvatar6.svg';
import DefaultAvatar7 from '../assets/avatars/defaultAvatar7.svg';
import DefaultAvatar8 from '../assets/avatars/defaultAvatar8.svg';
import DefaultAvatar9 from '../assets/avatars/defaultAvatar9.svg';
import DefaultAvatar10 from '../assets/avatars/defaultAvatar10.svg';
import DefaultAvatar12 from '../assets/avatars/defaultAvatar12.svg';
import DefaultAvatar14 from '../assets/avatars/defaultAvatar14.svg';
import DefaultAvatar16 from '../assets/avatars/defaultAvatar16.svg';
import DefaultAvatar19 from '../assets/avatars/defaultAvatar19.svg';
import DefaultAvatar20 from '../assets/avatars/defaultAvatar20.svg';
import DefaultAvatar21 from '../assets/avatars/defaultAvatar21.svg';
import DefaultAvatar22 from '../assets/avatars/defaultAvatar22.svg';
import DefaultAvatar23 from '../assets/avatars/defaultAvatar23.svg';
import DefaultAvatar24 from '../assets/avatars/defaultAvatar24.svg';
import DefaultAvatar25 from '../assets/avatars/defaultAvatar25.svg';
import DefaultAvatar26 from '../assets/avatars/defaultAvatar26.svg';
import DefaultAvatar27 from '../assets/avatars/defaultAvatar27.svg';
import DefaultAvatar28 from '../assets/avatars/defaultAvatar28.svg';
import DefaultAvatar29 from '../assets/avatars/defaultAvatar29.svg';

export const AVATARS = [
  {id: 1, icon: <DefaultAvatar1/>, avatar: 'defaultAvatar1'},
  {id: 2, icon: <DefaultAvatar2/>, avatar: 'defaultAvatar2'},
  {id: 3, icon: <DefaultAvatar3/>, avatar: 'defaultAvatar3'},
  {id: 4, icon: <DefaultAvatar4/>, avatar: 'defaultAvatar4'},
  {id: 5, icon: <DefaultAvatar5/>, avatar: 'defaultAvatar5'},

  {id: 6, icon: <DefaultAvatar6/>, avatar: 'defaultAvatar6'},
  {id: 7, icon: <DefaultAvatar7/>, avatar: 'defaultAvatar7'},
  {id: 8, icon: <DefaultAvatar8/>, avatar: 'defaultAvatar8'},
  {id: 9, icon: <DefaultAvatar9/>, avatar: 'defaultAvatar9'},
  {id: 10, icon: <DefaultAvatar10/>, avatar: 'defaultAvatar10'},

  {id: 12, icon: <DefaultAvatar12/>, avatar: 'defaultAvatar12'},
  {id: 14, icon: <DefaultAvatar14/>, avatar: 'defaultAvatar14'},
  {id: 16, icon: <DefaultAvatar16/>, avatar: 'defaultAvatar16'},
  {id: 19, icon: <DefaultAvatar19/>, avatar: 'defaultAvatar19'},
  {id: 20, icon: <DefaultAvatar20/>, avatar: 'defaultAvatar20'},

  {id: 21, icon: <DefaultAvatar21/>, avatar: 'defaultAvatar21'},
  {id: 22, icon: <DefaultAvatar22/>, avatar: 'defaultAvatar22'},
  {id: 23, icon: <DefaultAvatar23/>, avatar: 'defaultAvatar23'},
  {id: 24, icon: <DefaultAvatar24/>, avatar: 'defaultAvatar24'},
  {id: 25, icon: <DefaultAvatar25/>, avatar: 'defaultAvatar25'},

  {id: 26, icon: <DefaultAvatar26/>, avatar: 'defaultAvatar26'},
  {id: 27, icon: <DefaultAvatar27/>, avatar: 'defaultAvatar27'},
  {id: 28, icon: <DefaultAvatar28/>, avatar: 'defaultAvatar28'},
  {id: 29, icon: <DefaultAvatar29/>, avatar: 'defaultAvatar29'},
];

// v1
export const MAIN_MENU_v1 = [
  {id: 0, label: 'Trading', value: 'trading'},
  {id: 1, label: 'Social', value: 'social'},
];

export const TRADING_MENU = [
  {id: 0, label: 'Chart', value: 'chart'},
  {id: 1, label: 'All', value: 'all'},
  {id: 2, label: 'Favorites', value: 'favorites'},
];

export const POSITION_MENU = [
  // TODO: Locale this
  {
    id: 0,
    label: 'Traders I Watch',
    translation: 'Social.Stream.Filter.IWatch',
    value: 'traders_i_watch',
    type: 'social',
  },
  {
    id: 1,
    label: 'Traders I Copy',
    translation: '',
    value: 'traders_i_copy',
    type: 'social',
  },
  {
    id: 2,
    label: 'Open Position',
    value: 'open_position',
    type: 'trading',
  },
  {
    id: 3,
    label: 'Pending Orders',
    value: 'pending_orders',
    type: 'trading',
  },
  {
    id: 4,
    label: 'Closed Position',
    value: 'closed_position',
    type: 'trading',
  },
];

export const TIME_SWITCH__CHART = [
  {timeKey: 10080, timeValue: '7 Days'},
  {timeKey: 20160, timeValue: '14 Days'},
  {timeKey: 43200, timeValue: '1 Month'},
  {timeKey: 2524608000000, timeValue: 'All'},
];

export const TIME_SWITCH__POSITION = [
  {timeKey: 700, timeValue: '7 Days'},
  {timeKey: 1400, timeValue: '14 Days'},
  {timeKey: 3000, timeValue: '1 Month'},
  {timeKey: 36500, timeValue: 'All'},
];

export const TIMEFRAMES_CHART_PIE = [
  {index: 0, timeKey: 1, timeValue: '1 month'},
  {index: 1, timeKey: 2, timeValue: '3 month'},
  {index: 2, timeKey: 3, timeValue: '6 month'},
  {index: 3, timeKey: 4, timeValue: '1 year'},
];
// v2
export const MAIN_MENU = [
  {
    id: 0,
    label: 'Chart',
    translation: '', //TODO: locale this
    value: 'chart',
    type: 'trading',
  },
  {
    id: 1,
    label: 'Rates',
    translation: 'MarketRates.Heading',
    value: 'all',
    type: 'trading',
  },
  {
    id: 2,
    label: 'Explore',
    translation: 'Socials.Button.Explore',
    value: 'explore',
    type: 'social',
  },
  {
    id: 3,
    label: 'Signals',
    translation: 'Lev.Signals.Title',
    value: 'signals',
    type: 'trading',
  },
  // {
  // 	id: 4,
  // 	label: 'Traders I watch',
  // 	translation: 'Social.Stream.Filter.IWatch',
  // 	value: 'traders_i_watch',
  // 	type: 'social',
  // },
];

export const PROFILE_POSITION_MENU = [
  {
    index: 0,
    timeKey: 1,
    timeValue: 'Open',
    label: 'Open',
    translation: '', //TODO: locale this
    value: 'open',
  },
  {
    index: 1,
    timeKey: 2,
    timeValue: 'Pending',
    label: 'Pending',
    translation: '', //TODO: locale this
    value: 'pending',
  },
  {
    index: 2,
    timeKey: 3,
    timeValue: 'Closed',
    label: 'Closed',
    translation: '', //TODO: locale this
    value: 'closed',
  },
];

export const PROFILE_COMMUNITY_MENU = [
  {
    index: 0,
    timeKey: 1,
    timeValue: 'Watchers',
    label: 'Watchers',
    translation: 'Socail.Profile.Watchers',
    value: 'watchers',
  },
  {
    index: 1,
    timeKey: 2,
    timeValue: 'Watching',
    label: 'Watching',
    translation: 'Socail.Profile.Watching',
    value: 'watching',
  },
  {
    index: 2,
    timeKey: 3,
    timeValue: 'Copiers',
    label: 'Copiers',
    translation: 'Socail.Profile.Copiers',
    value: 'copiers',
  },
  {
    index: 3,
    timeKey: 4,
    timeValue: 'Copying',
    label: 'Copying',
    translation: 'Social.CopyingText',
    value: 'copying',
  },
];

export const PROFILE_MENU = [
  {
    id: 0,
    label: 'Stream',
    translation: '', //TODO: locale this
    value: 'stream',
    type: 'social',
  },
  {
    id: 1,
    label: 'Positions',
    translation: 'ProfilePage.Positions.Title',
    value: 'positions',
    type: 'social',
  },
  // {
  // 	id: 2,
  // 	label: 'Traders I Copy',
  // 	translation: '', //TODO: locale this
  // 	value: 'traders_i_copy',
  // 	type: 'social',
  // },
  {
    id: 3,
    label: 'Portfolio',
    translation: 'ProfilePage.Portfolio.Title',
    value: 'portfolio',
    type: 'social',
  },
  {
    id: 4,
    label: 'Community',
    translation: 'ProfilePage.Community.Title',
    value: 'community',
    type: 'social',
  },
  // {
  // 	id: 5,
  // 	label: 'Copy',
  // 	translation: 'ProfilePage.Copy.Title',
  // 	value: 'copy',
  // 	type: 'social',
  // },
];

export const PROFILE_COPY = [
  {
    id: 0,
    key: 'equity',
    label: 'Allocate equity',
    translate: 'Social.CopyTab.ByEquity',
  },
  {id: 1, key: 'fixed', label: 'Copy fixed-size', translate: ''}, //TODO: locale this 'Social.CopyTab.ByFixed' to long,
];
