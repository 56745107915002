export const riskColor = [
	'#3CC09B',
	'#72B893',
	'#8AB786',
	'#D7B152',
	'#ECA244',
	'#E68847',
	'#E37A47',
	'#e96e28',
];

export const MAX_LEVEL = 10;
export const RISK_LENGTH = 8;
export const DURATION = 3000;
export const DURATION_LEVEL = DURATION / RISK_LENGTH;
export const BLOCK_COST = MAX_LEVEL / RISK_LENGTH;
