import React from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';

import OuterClick from '../primitives/OuterClick';
import Button from '../primitives/Button';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';


const UncopyPopup = ({
    handleUncopy, handleClick, copiedUserName, generalButtonColor
}) => {
    const { t } = useTranslation();
    const handleUncopyAction = () => {
        SendEventToGoogleAnalytics('Profile:UnCopyPopup:UnCopyClick', { masterNickname: copiedUserName });
        handleUncopy();
    };

    return (
        <div className='profile-watch-opened'>
            <div>
                {t('Social.Uncopy.DisclaimerHeader')}
                &nbsp;
                {copiedUserName}
                <p>{t('Social.Uncopy.Disclaimer')}</p>
            </div>
            <div className='profile-watch-opened__actions'>
                <Button text={t('Button.Uncopy')} className='btn btn-copy' onClickHandler={handleUncopyAction} />
                <Button text={t('General.Cancel')} className='btn btn-cancel btn--general' style={generalButtonColor} onClickHandler={handleClick} />
            </div>
        </div>
    );
};

// UncopyPopup.propTypes = {};

export default OuterClick(UncopyPopup);
