import { useMemo, useState } from 'react';

const useProviderPosition = () => {
    const [closeOrderData, setCloseOrderData] = useState(null);
    const [updateOrderData, setUpdateOrderData] = useState(null);

    const positionClose = useMemo(() => {
        return {
            closeData: closeOrderData,
            handleCloseOpen: (data) => setCloseOrderData(data),
            handleCloseHide: () => setCloseOrderData(null)
        };
    }, [closeOrderData, setCloseOrderData]);

    const positionUpdate = {
        updateData: updateOrderData,
        handleUpdateOpen: (data) => setUpdateOrderData(data),
        handleUpdateHide: () => setUpdateOrderData(null)
    };

    return {
        positionClose,
        positionUpdate
    };
};

export default useProviderPosition;
