import React from 'react';
import { withTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import PropTypes from 'prop-types';

const CheckBoxField = ({ isRememberMe, handleClickRemeberme, t }) => {
    const handleClick = (e) => {
        const request = {
            target: {
                name: e.target.name,
                value: !isRememberMe
            }
        };
        handleClickRemeberme(request);
    };

    return (
        <FormControlLabel
            value='end'
            control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Checkbox
                    checked={isRememberMe}
                    name='rememberMe'
                    onChange={handleClick}
                    color='default'
                />
            }
            // label='Remember Me'
            label={t('Login.Form.RememberMe')}
            labelPlacement='end'
        />
    );
};

// CheckBoxField.propTypes = {};

export default withTranslation()(CheckBoxField);
