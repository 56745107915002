import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
// import PropTypes from 'prop-types';

import PasswordField from '../primitives/PasswordField';
import CheckBoxField from '../primitives/CheckBoxField';
import LoadSpinner from '../primitives/LoadSpinner';


const QuickPasswordLoginItem = ({
    cmd,
    displayName,
    backAction,
    id
}) => {
    const [userInput, setUserInput] = useState(cmd ? cmd.getUserInput() : {requestVer:3});
    const [response, setResponse] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            setResponse(null);
            const res = await cmd.execute(userInput);
            if (res.isSuccessful === false) {
                setResponse(res);
                setIsLoading(false);
            }
        } catch (err) {
            setResponse(err);
            setIsLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserInput((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleBackAction = () => {
        backAction(null, null, null);
    };


    return (
        <div>
            {isLoading && <LoadSpinner overlay />}
            <h2>
                {id}
                @
                {displayName}
            </h2>
            {displayName}
            <PasswordField password={userInput.password} handlePasswordChange={handleChange} errorMessage={response?.errorDescription ?? ''} />
            <CheckBoxField isRememberMe={userInput.rememberMe} handleClickRemeberme={handleChange} />
            <Button fullWidth className='button--primary' onClick={handleSubmit} type='submit'>
                {t('Login.Button.Login')}
            </Button>
            <div className='backward-block' onClick={handleBackAction}>
                <IconButton aria-label='back to previous environments'>
                    <ArrowBackIosIcon />
                </IconButton>
            </div>
        </div>
    );
};

// QuickPasswordLoginItem.propTypes = {};

export default QuickPasswordLoginItem;
