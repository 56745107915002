/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {useEffect} from 'react';// import PropTypes from 'prop-types';

import OpenPositionsVirtualList from './OpenPositionsVirtualList';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';
import './OpenPositions.css';


const OpenPositions = ({ setShouldCloseOrder, setSelectedTrader, handleUnlink, isBottomPanelPinned, subHeaderColor, systemColor, linkColor }) => {
  useEffect(() => {
    SendEventToGoogleAnalytics("Drawer:OpenOrdersTabOpened");
}, []);

    return (
        <OpenPositionsVirtualList
            setSelectedTrader={setSelectedTrader}
            setCloseOrder={setShouldCloseOrder}
            handleUnlink={handleUnlink}
            isBottomPanelPinned={isBottomPanelPinned}
            subHeaderColor={subHeaderColor}
            systemColor={systemColor}
            linkColor={linkColor}
        />
    );
};

// OpenPositions.propTypes = {};

export default OpenPositions;
