import { CommandFactory } from '../../background/commands/CommandFactory';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';
// import { getStore } from '../../storeContainer';

const depositButtonClickHandler = (depositURL, getDepositURLCommand) => {
    // console.log('store', getStore().getState()?.account?.accountInfo?.featuresData?.deposit);
    const onClick = async () => {
        SendEventToGoogleAnalytics('DepositClick');
        if (!CommandFactory.isCommand(getDepositURLCommand)) {
            if (depositURL) {
                window.open(depositURL, '_blank').focus();
            }
        } else {
            try {
                const tab = window.open();
                if (tab) {
                    const response = await CommandFactory.createCommand(getDepositURLCommand).execute();
                    if (!response.isSuccessful) {
                        if (depositURL) {
                            tab.location.href = depositURL;
                        } else {
                            tab.close();
                        }
                    } else {
                        tab.location.href = response.depositUrl;
                    }
                }
            } catch (exception) {
                console.error(exception);
            }
        }
    };
    onClick();
};

export default depositButtonClickHandler;
