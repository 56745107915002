import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';

import { CommandFactory } from '../../background/commands/CommandFactory';

import './QuickLogin.css';


const QuickLoginItem = ({ envData, selectEnv, toggleIsLoading }) => {
    const { displayName, dataToDisplay: { tpId, passwordRequiredCommand, remembermeLoginCommand, userName, avatarColor } } = envData;
    const { t } = useTranslation();
    const commandPassword = CommandFactory.createCommand(passwordRequiredCommand);
    const [response, setResponse] = useState('');

    const selectEnvPass = () => {
        selectEnv(commandPassword, displayName, tpId);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const command = CommandFactory.createCommand(remembermeLoginCommand);

        toggleIsLoading();

        try {
            setResponse(null);
            const res = await command.execute();

            if (res.isSuccessful === undefined) {
                setResponse(res);
                toggleIsLoading();
            } else if (res.isSuccessful === false && res.errorDescription === 'Login.Error.InvalidUserNameOrPassword') {
                selectEnvPass();
                toggleIsLoading();
            } else if (res.isSuccessful === false) {
                selectEnvPass();
                toggleIsLoading();
            }
        } catch (err) {
            setResponse(err);
            toggleIsLoading();
        }
    };


    return (
        <Fragment>
            <div className='login__env-item' onClick={remembermeLoginCommand ? handleSubmit : selectEnvPass}>
                <div className='login__env-item__user-icon' style={{ backgroundColor: avatarColor }}>
                    {userName.slice(0, 1)}
                </div>
                <div className='login__env-item__content'>
                    <strong>{userName}</strong>
                    <span>
                        {tpId}
                        @
                        {displayName}
                    </span>
                </div>
            </div>
            { response && remembermeLoginCommand && (
                response.status
                    ? (
                        <p className='error__message error__message--fixed-bottom'>
                            {response.status}
                            {' '}
                            {response.statusText}
                        </p>
                    )
                    : (
                        <p className='error__message error__message--fixed-bottom'>
                            {response.errorDescription ? t(response.errorDescription) : t('Login.Thenical.Issue')}
                        </p>
                    )
            )}
        </Fragment>
    );
};

// QuickLoginItem.propTypes = {};

export default QuickLoginItem;
