import React from 'react';
import TradingRoomPanel from '../../shared/Charts/TradingRoom';

const MobileSignalsPage = () => {
	return (
		<div className="mobile-signals-page">
			<div className='trading-room__container'>
				<TradingRoomPanel/>
			</div>
		</div>
	);
};

export default MobileSignalsPage;
