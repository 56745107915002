import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';

import MobileSocialCopy
	from '../components/MobileSocial/MobileSocialCopy/MobileSocialCopy';
import useCommunityFetch from '../hook/useFetchData/useCommunityFetch';
import MobileLoader from '../components/MobileLoader/MobileLoader';
import MobileNothingFound from '../components/MobileEmpty/MobileNothingFound';

const MobileSocialCopyPage = ({isVisible}) => {

	const {userDetails} = useSelector(state => state.account.socialv1,
			shallowEqual);

	const {data, isLoading} = useCommunityFetch(userDetails?.nickname, 2);

	return (
			<>
				{isVisible && (
						<>
							<MobileLoader isLoading={isLoading} size="large"/>

							{data?.UserFriends && !data?.UserFriends.length && !isLoading && (
									<MobileNothingFound/>
							)}

							{data?.UserFriends && !!data?.UserFriends.length && (
									<MobileSocialCopy
											options={data?.UserFriends}
									/>
							)}
						</>
				)}
			</>
	);
};

export default MobileSocialCopyPage;
