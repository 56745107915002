import React from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';

import CountryFlagByCode from '../Flag/CountryFlagByCode';
import formatDaysFromNow from '../utilsUI/formatDaysFromNow';
import { FormatPercentFloat } from '../ProfilePage/ProfileTabDetails';
import Button from '../primitives/Button';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';

const ExplorePageItem = ({ location, trader, handleItemSelect, index, copyColor }) => {
    const { t } = useTranslation();
    const openProfile = () => {
        handleItemSelect({ ...trader, shouldOpenCopy: false });
        SendEventToGoogleAnalytics(`Explore:${location}:ProfileClick`, {nickname: trader.Nickname});
    };

    const openProfileCopy = (e) => {
        e.stopPropagation();
        handleItemSelect({ ...trader, shouldOpenCopy: true });
        SendEventToGoogleAnalytics(`Explore:${location}:CopyClick`, {nickname: trader.Nickname});
    };

    let successRate = 0;
    successRate = trader.WinRatio ? FormatPercentFloat({ value: trader.WinRatio }) : trader?.SuccessRate && FormatPercentFloat({ value: trader.SuccessRate });
    const daysFromNow = formatDaysFromNow(new Date(trader?.JoinDateTime));


    return (
        <tr key={trader?.Nickname} onClick={openProfile} role='button' tabIndex={-1} aria-label='Open profile'>
            <td className='top-three__index-cell'>{index + 4}</td>
            <td className='right-panel__name'>
                <div className='explore__avatar-container'>
                    <div className={`avatar ${trader?.Avatar.toLowerCase()}`}>
                        <div className='country__flag--small'>
                            {trader?.Country && CountryFlagByCode(trader?.Country)}
                        </div>
                    </div>
                    <div>
                        <span onClick={openProfile} role='button' tabIndex={-1} aria-label='Open profile'>
                            {trader?.Nickname}
                        </span>
                    </div>
                </div>
            </td>
            <td>{trader?.CopiersCount}</td>
            <td>
                <strong className='green'>
                    {Math.round((trader?.CumulativePnl + Number.EPSILON) * 10000) / 100}
                    %
                </strong>
            </td>
            <td>{daysFromNow}</td>
            <td>
                {successRate}
            </td>
            <td>
                <Button
                    className='btn btn-stream-copy'
                    // style={copyColor}
                    // text={t('Socials.Button.Copy')}
                    iconStart='far fa-copy'
                    onClickHandler={openProfileCopy}
                />
            </td>
        </tr>
    );
};

// ExplorePageItem.propTypes = {};

export default ExplorePageItem;
