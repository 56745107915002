import React, {useCallback, useMemo} from 'react';
import MobileAvatarIcon from '../../MobileAvatarIcon';
import roundToPrecision from '../../../../UI/utilsUI/roundToPrecision';
import clsx from 'clsx';
import useProfile from '../../../context/contextHook/useProfile';

const MobileProfileCommunityItem = props => {
	const {Avatar, Nickname, CumulativePnl} = props;

	const {profileUser: {openProfileUser}} = useProfile();

	const currentPnl = useMemo(() => {
		const pnl = roundToPrecision(CumulativePnl * 100, 2);
		return pnl !== 0 ? `${pnl}%` : pnl;
	}, [CumulativePnl]);

	const handleClick = useCallback(e => {
		e && e.preventDefault();
		openProfileUser && openProfileUser(Nickname);
	}, [openProfileUser]);

	return (
			<div
					className="mobile-profile-community-item"
					onClick={handleClick}
			>

				<div className="mobile-profile-community-item__desc">
					<div className="mobile-profile-community-item__avatar">
						<MobileAvatarIcon avatar={Avatar}/>
					</div>
					<div className="mobile-profile-community-item__name">
						{Nickname}
					</div>
				</div>

				<div className="mobile-profile-community-item__stat">
					<p className="mobile-profile-community-item__title">
						{/*TODO: locale this*/}
						P/L
					</p>
					<div className={clsx('mobile-profile-community-item__count',
							CumulativePnl >= 0 && 'green',
							CumulativePnl < 0 && 'red',
					)}>
						{currentPnl}
					</div>
				</div>

			</div>
	);
};

export default MobileProfileCommunityItem;
