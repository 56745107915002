import React, {useCallback, useEffect, useState} from 'react';
import { CommandFactory } from '../../../background/commands/CommandFactory'
import {shallowEqual, useSelector} from 'react-redux';

const useTopRankedData = () => {
	const {getTopRanked} = useSelector(
			state => state.account.socialv1.exploreCommands, shallowEqual);

	const [topTraders, setTopTraders] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const getTraders = useCallback(async () => {
		if (!getTopRanked) return;

		const limit = 100; //TODO: dynamic limit ?
		const commandObj = {
			...getTopRanked,
			userInput: {limit},
		};

		const command = CommandFactory.createCommand(commandObj);
		try {
			setIsLoading(true);
			const res = await command.execute();
			if (res.isSuccessful) setTopTraders(res.payload.slice(0, limit));
			setIsLoading(false);
		} catch (err) {
			console.error(err); //TODO: remove log add errors
			setIsLoading(false);
		}
	}, [getTopRanked]);

	useEffect(() => {
		getTraders().then(() => setIsLoading(false));
	}, [getTraders]);

	return {
		topTraders,
		isLoading,
		requestData: () => getTraders(),
	};
};

export default useTopRankedData;
