export const nowDate = new Date();

const options = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZone:'UTC',
};
const dateOptions = { day: 'numeric', month: 'numeric', year: 'numeric',timeZone:'UTC' };
const timeOptions = { hour: 'numeric', minute: 'numeric', second: 'numeric',timeZone:'UTC' };

let formatDateCache = new Intl.DateTimeFormat('en-GB', options)
export const formatDate = (date) => {
    const formattedDate = formatDateCache.format(date).replace(',', '');
    return formattedDate;
};

let formatDateOnlyCache = new Intl.DateTimeFormat('en-GB', dateOptions)
export const formatDateOnly = (date) => {
    const formattedDate = formatDateOnlyCache.format(date).replace(',', '');
    return formattedDate;
};

let formatTimeCache = new Intl.DateTimeFormat('en-GB', timeOptions)
export const formatTime = (date) => {
    const formattedDate = formatTimeCache.format(date).replace(',', '');
    return formattedDate;
};
