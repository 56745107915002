import { replaceIfChanged } from '../StoreUtils/overrideState';
import produce from "immer"

const initState = {
    currency: { code: "", digits: 0, sign: "", symbol: "", name: "" },
    userDetails: {
     avatarColor: "",
     name: "",
     email: "",
     tpUserId: "",
     brandName: "",
     brokerName: "",
     userId: "",
     envId: "",
     showEnvironment: false,
     hideTopPanel: false,
     wshost:"",
     showPositionsOnChart: false
    },
    logoutCommand: null,
    renewSessionCommand: null,
    refreshStateCommand: null,
    guestModeData: {
        isLoggedInAsGuest: false,
        transferLink: ""
    },
    analyticData: {
        fullStoryData:{isEnabled: false, organizationId: ""},
        googleAnalyticsData: {measurementId: "", isEnabled: false,},
        luckyOrangeData: {siteId: "", isEnabled: false,}
    },
    featuresData: {
        execution: {enableTpSl: false},
        deposit: { isEnabled: false, depositUrl: "", getDepositUrlCommand:null},
        trends: { isEnabled: false, trendsUrl: "" },
        calendar: { isEnabled: false, calendarUrl: "", getCalendarUrlCommand: null },
        education: { isEnabled: false, educationUrl: "", getEducationUrlCommand: null },
        showMarginCallDisclaimer: false,
        waitForTicksOnStartup:false,
    },
    serverTimeZone: "",
    marketRatesSettings: {
        pin: { isVisible: false, allowFilter: false, translation: "", sort: 0 },
        symbol: { isVisible: false, allowFilter: false, translation: "", sort: 0 },
        flag: { isVisible: false, allowFilter: false, translation: "", sort: 0 },
        bid: { isVisible: false, allowFilter: false, translation: "", sort: 0 },
        ask: { isVisible: false, allowFilter: false, translation: "", sort: 0 },
        mid: { isVisible: false, allowFilter: false, translation: "", sort: 0 },
        spread: { isVisible: false, allowFilter: false, translation: "", sort: 0 },
        lastUpdate: { isVisible: false, allowFilter: false, translation: "", sort: 0 },
        high: { isVisible: false, allowFilter: false, translation: "", sort: 0 },
        low: { isVisible: false, allowFilter: false, translation: "", sort: 0 },
        info: { isVisible: false, allowFilter: false, translation: "", sort: 0 },
        infoData: {
            description: { visible: false, translation: "", sortOrder: 0 },
            security: { visible: false, translation: "", sortOrder: 0 },
            digits: { visible: false, translation: "", sortOrder: 0 },
            stopLevel: { visible: false, translation: "", sortOrder: 0 },
            leverage: { visible: false, translation: "", sortOrder: 0 },
            contractSize: { visible: false, translation: "", sortOrder: 0 },
            minimum: { visible: false, translation: "", sortOrder: 0 },
            maximum: { visible: false, translation: "", sortOrder: 0 },
            step: { visible: false, translation: "", sortOrder: 0 },
            swapLong: { visible: false, translation: "", sortOrder: 0 },
            swapShort: { visible: false, translation: "", sortOrder: 0 },
            swapType: { visible: false, translation: "", sortOrder: 0 },
            instrument: { visible: false, translation: "", sortOrder: 0 },
        },
        groupsOrder: []
    },
    drawerSettings: {
        pinData:{
          isPin : false,
          isPinVisible : false,
          togglePinDrawerCommand : null
        },
        openPositionsTab: {
        },
        pendingOrdersTab: {
        },
        closePositionsTab: {
            totalProfitType:"hide",
        }
    },
    rightPanelSettings: {
        socialv1: { isVisible: false, sort: 0 },
        t4proTradingroom: { isVisible: false, sort: 0, vipEnabled: false }
    }
};

export const accountInfoReducer = function accountInfoReducer(state = initState, action, globalState) {
    switch (action.type) {
        case "login-logout":
            return initState;
        case "init-brand+login":
            {
                return produce(state, draf => {
                    handleState(draf, action.payload.loginTradingData);
                    draf.serverTimeZone = action.payload.loginTradingData.sharedTradingData.ServerTimeZone;
                });
            }
        case "login-success":
            {
                return produce(state, draf => {
                    handleState(draf, action.payload);
                    draf.serverTimeZone = action.payload.sharedTradingData.ServerTimeZone;
                });
            }
        case "marketRatesColumn-toggle":
            {
                return produce(state, draf => {
                    var columnState = draf.marketRatesSettings[action.staticData.column];
                    if (columnState) {
                        columnState.isVisible = !action.storeData.isVisible;
                    }
                });
            }
            case "drawer-toggle":
              {
                return produce(state, draf => {
                  draf.drawerSettings.pinData.isPin = !action.storeData.isPin;
              });
              }
        default:
            return state;
    }
}

function handleState(draf, payload) {
    draf.currency.code = payload.generalData.currency.code;
    draf.currency.digits = payload.generalData.currency.digits;
    draf.currency.sign = payload.generalData.currency.sign;
    draf.currency.symbol = payload.generalData.currency.symbol;
    draf.currency.name = payload.generalData.currency.name;

    draf.userDetails.avatarColor = payload.generalData.userDetails.avatarColor;
    draf.userDetails.name = payload.generalData.userDetails.name;
    draf.userDetails.email = payload.generalData.userDetails.email;
    draf.userDetails.tpUserId = payload.generalData.tpUserId;
    draf.userDetails.brandName = payload.generalData.brandName;
    draf.userDetails.brokerName = payload.generalData.brokerName;
    draf.userDetails.userId = payload.generalData.userId;
    draf.userDetails.envId = payload.generalData.envId;
    draf.userDetails.showEnvironment = payload.generalData.showEnvironment;
    draf.userDetails.hideTopPanel = payload.generalData.hideTopPanel;
    draf.userDetails.wshost = payload.generalData.wshost;
    draf.userDetails.showPositionsOnChart = payload.generalData.showPositionsOnChart;

    draf.logoutCommand = payload.generalData.logoutCommand;
    replaceIfChanged(draf,'renewSessionCommand',payload.generalData.renewSessionCommand);
    replaceIfChanged(draf,'refreshStateCommand',payload.generalData.refreshStateCommand);

    draf.guestModeData.isLoggedInAsGuest = payload.guestModeData.isLoggedInAsGuest;
    draf.guestModeData.transferLink = payload.guestModeData.transferLink;

    draf.featuresData.waitForTicksOnStartup = payload.featuresData.waitForTicksOnStartup;
    draf.featuresData.deposit.isEnabled = payload.featuresData.deposit.isEnabled;
    draf.featuresData.deposit.depositUrl = payload.featuresData.deposit.depositUrl;
    replaceIfChanged(draf.featuresData.deposit,'getDepositUrlCommand',payload.featuresData.deposit.getDepositUrlCommand);
    draf.featuresData.trends.isEnabled = payload.featuresData.trends.isEnabled;
    draf.featuresData.trends.trendsUrl = payload.featuresData.trends.trendsUrl;
    draf.featuresData.calendar.isEnabled = payload.featuresData.calendar.isEnabled;
    draf.featuresData.calendar.calendarUrl = payload.featuresData.calendar.calendarUrl;
    replaceIfChanged(draf.featuresData.calendar,'getCalendarUrlCommand', payload.featuresData.calendar.getCalendarUrlCommand);
    draf.featuresData.education.isEnabled = payload.featuresData.education.isEnabled;
    draf.featuresData.education.educationUrl = payload.featuresData.education.educationUrl;
    replaceIfChanged(draf.featuresData.education,'getEducationUrlCommand', payload.featuresData.education.getEducationUrlCommand);
    draf.featuresData.showMarginCallDisclaimer = payload.featuresData.showMarginCallDisclaimer;
    if (payload.featuresData.execution)
    {
        draf.featuresData.execution.enableTpSl = payload.featuresData.execution.enableTpSl;
    }

    if(payload.analyticData){
      if(payload.analyticData.fullStoryData){
        draf.analyticData.fullStoryData.isEnabled = payload.analyticData.fullStoryData.isEnabled;
        draf.analyticData.fullStoryData.organizationId = payload.analyticData.fullStoryData.organizationId;
      }
      if(payload.analyticData.googleAnalyticsData){
        draf.analyticData.googleAnalyticsData.measurementId = payload.analyticData.googleAnalyticsData.measurementId;
        draf.analyticData.googleAnalyticsData.isEnabled = payload.analyticData.googleAnalyticsData.isEnabled;
      }
      if(payload.analyticData.luckyOrangeData){
        draf.analyticData.luckyOrangeData.siteId = payload.analyticData.luckyOrangeData.siteId;
        draf.analyticData.luckyOrangeData.isEnabled = payload.analyticData.luckyOrangeData.isEnabled;
      }
    }

    if (payload.marketRatesData)
    {
        replaceIfChanged(draf.marketRatesSettings,'groupsOrder', payload.marketRatesData.groupsOrder);
        updateMarketSettingsColumn(draf.marketRatesSettings.pin, payload.marketRatesData, "pin");
        updateMarketSettingsColumn(draf.marketRatesSettings.symbol, payload.marketRatesData, "symbol");
        updateMarketSettingsColumn(draf.marketRatesSettings.flag, payload.marketRatesData, "flag");
        updateMarketSettingsColumn(draf.marketRatesSettings.bid, payload.marketRatesData, "bid");
        updateMarketSettingsColumn(draf.marketRatesSettings.ask, payload.marketRatesData, "ask");
        updateMarketSettingsColumn(draf.marketRatesSettings.mid, payload.marketRatesData, "mid");
        updateMarketSettingsColumn(draf.marketRatesSettings.spread, payload.marketRatesData, "spread");
        updateMarketSettingsColumn(draf.marketRatesSettings.lastUpdate, payload.marketRatesData, "lastUpdate");
        updateMarketSettingsColumn(draf.marketRatesSettings.high, payload.marketRatesData, "high");
        updateMarketSettingsColumn(draf.marketRatesSettings.low, payload.marketRatesData, "low");
        updateMarketSettingsColumn(draf.marketRatesSettings.info, payload.marketRatesData, "info");

        updateInfoColumn(draf.marketRatesSettings.infoData.description, payload.marketRatesData.marketRatesColumnSettings.infoData.description)
        updateInfoColumn(draf.marketRatesSettings.infoData.security, payload.marketRatesData.marketRatesColumnSettings.infoData.security)
        updateInfoColumn(draf.marketRatesSettings.infoData.digits, payload.marketRatesData.marketRatesColumnSettings.infoData.digits)
        updateInfoColumn(draf.marketRatesSettings.infoData.stopLevel, payload.marketRatesData.marketRatesColumnSettings.infoData.stopLevel)
        updateInfoColumn(draf.marketRatesSettings.infoData.leverage, payload.marketRatesData.marketRatesColumnSettings.infoData.leverage)
        updateInfoColumn(draf.marketRatesSettings.infoData.contractSize, payload.marketRatesData.marketRatesColumnSettings.infoData.contractSize)
        updateInfoColumn(draf.marketRatesSettings.infoData.minimum, payload.marketRatesData.marketRatesColumnSettings.infoData.minimum)
        updateInfoColumn(draf.marketRatesSettings.infoData.maximum, payload.marketRatesData.marketRatesColumnSettings.infoData.maximum)
        updateInfoColumn(draf.marketRatesSettings.infoData.step, payload.marketRatesData.marketRatesColumnSettings.infoData.step)
        updateInfoColumn(draf.marketRatesSettings.infoData.swapLong, payload.marketRatesData.marketRatesColumnSettings.infoData.swapLong)
        updateInfoColumn(draf.marketRatesSettings.infoData.swapShort, payload.marketRatesData.marketRatesColumnSettings.infoData.swapShort)
        updateInfoColumn(draf.marketRatesSettings.infoData.swapType, payload.marketRatesData.marketRatesColumnSettings.infoData.swapType)
        updateInfoColumn(draf.marketRatesSettings.infoData.instrument, payload.marketRatesData.marketRatesColumnSettings.infoData.instrument)
    }

    if (payload.drawerData)
    {
        draf.drawerSettings.closePositionsTab.totalProfitType = payload.drawerData.closePositionsTab.totalProfitType;
        if (payload.drawerData.pinData)
        {
            draf.drawerSettings.pinData.isPin = payload.drawerData.pinData.isPin;
            draf.drawerSettings.pinData.isPinVisible = payload.drawerData.pinData.isPinVisible;
            replaceIfChanged(draf.drawerSettings.pinData,'togglePinDrawerCommand',payload.drawerData.pinData.togglePinDrawerCommand);
        }
        UpdateRightPanelTab(draf.rightPanelSettings.socialv1, payload.rightPanelData.socialv1);
        UpdateTradingRoomTab(draf.rightPanelSettings.t4proTradingroom, payload.rightPanelData.t4proTradingroom);
    }

}

function updateMarketSettingsColumn(drafColumn, payload, columnName) {
    var payloadColumn = payload.marketRatesColumnSettings[columnName];
    var commandTemplate = payload.toggleMarketRatesColumnCommandTemplate;
    drafColumn.isVisible = payloadColumn.isVisible;
    drafColumn.allowFilter = payloadColumn.allowFilter;
    drafColumn.translation = payloadColumn.translation;
    drafColumn.sort = payloadColumn.sort;
    if (!drafColumn.toggleMarketRatesCommand) {
        drafColumn.toggleMarketRatesCommand = {
            cmdId: commandTemplate.cmdId,
            storeRef: { isVisible: ["account", "accountInfo", "marketRatesSettings", columnName, "isVisible"] },
            userInput: null,
            staticParams: {
                type: commandTemplate.staticParams.type,
                column: columnName,
                postUrl: commandTemplate.staticParams.postUrl
            }
        }
    }
}

function updateInfoColumn(drafInfo, payloadInfo) {
    drafInfo.visible = payloadInfo.visible;
    drafInfo.sortOrder = payloadInfo.sortOrder;
    drafInfo.translation = payloadInfo.translation;
}

function UpdateRightPanelTab(drafTab, payloadTab) {
    drafTab.isVisible = payloadTab.isVisible;
    drafTab.sort = payloadTab.sort;
}

function UpdateTradingRoomTab(drafTab, payloadTab) {
    drafTab.isVisible = payloadTab.isVisible;
    drafTab.sort = payloadTab.sort;
    drafTab.vipEnabled = payloadTab.vipEnabled;
}


