/* eslint-disable react/no-did-update-set-state */
/* eslint-disable complexity */

import React from 'react';
import { DraggableCore } from 'react-draggable';
import debounce from 'lodash.debounce';

import './ReactResizePanel.css';


class ReactResizePanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = { size: 0 };

        this.contentRef = React.createRef();
        this.wrapperRef = React.createRef();
        this.validateSize = debounce(this.validateSize, 100).bind(this);
    }

    isHorizontal = () => this.props.direction === 'w' || this.props.direction === 'e';

    componentDidMount() {
        const content = this.contentRef.current;
        const actualContent = content.children[0];
        const initialSize = this.isHorizontal()
            ? actualContent.getBoundingClientRect().width
            : actualContent.getBoundingClientRect().height;

        // Initialize the size value based on the content's current size
        this.setState({ size: initialSize });
        this.validateSize();
    }

    componentDidUpdate(prevProps, prevState) {
        const { openPin, id, style } = this.props;
        if (prevProps.openPin !== openPin && id === 'bottom-resize' && openPin) {
            this.setState({ size: 300 });
        }

        if (prevProps.openPin !== openPin && id === 'bottom-resize' && !openPin) {
            this.setState({ size: 73 });
        }
        if (prevProps?.style?.width && style?.width && prevProps.style.width !== style.width && this.isHorizontal()) {
            this.setState({ size: style.width });
        }
    }

    validateSize() {
        const isHorizontal = this.isHorizontal();
        const content = this.contentRef.current;
        const wrapper = this.wrapperRef.current;
        const actualContent = content.children[0];
        const containerParent = wrapper.parentElement;

        //
        // Or if our size doesn't equal the actual content size, then we
        // must have pushed past the min size of the content, so resize back
        // let minSize = isHorizontal ? $(actualContent).outerWidth(true) : $(actualContent).outerHeight(true);
        let minSize = isHorizontal
            ? actualContent.scrollWidth
            : actualContent.scrollHeight;

        const margins = isHorizontal
            ? actualContent.getBoundingClientRect().width - actualContent.offsetWidth
            : actualContent.getBoundingClientRect().height - actualContent.offsetHeight;
        minSize += margins;

        if (this.state.size !== minSize) {
            this.setState({
                ...this.state,
                size: minSize
            });
        } else {
        // If our resizing has left the parent container's content overflowing
        // then we need to shrink back down to fit
            const overflow = isHorizontal
                ? containerParent.scrollWidth - containerParent.clientWidth
                : containerParent.scrollHeight - containerParent.clientHeight;

            if (overflow) {
                console.log('overflow', overflow);
                this.setState({
                    ...this.state,
                    size: isHorizontal
                        ? actualContent.clientWidth - overflow
                        : actualContent.clientHeight - overflow
                });
            }
        }
    }

    handleDrag = (e, ui) => {
        const { direction } = this.props;
        const factor = direction === 'e' || direction === 's' ? -1 : 1;

        // modify the size based on the drag delta
        const delta = this.isHorizontal() ? ui.deltaX : ui.deltaY;
        this.setState((s, p) => ({ size: Math.max(10, s.size - delta * factor) }));
        //   this.props.handleResize((s, p) => ({ size: Math.max(10, s.size - delta * factor) }));
    };

    handleDragEnd = (e, ui) => {
        if (this.props.id === 'left-resize') {
            const parentElement = document.getElementById('symbol-search').parentNode;
            const symbolSelect = document.getElementsByClassName('left-panel__actions-grow').item(0);
            if (this.state.size < 320) {
                parentElement.style.maxWidth = 'unset';
                parentElement.style.width = '100%';
                parentElement.style.marginRight = '0px';
                symbolSelect.style.maxWidth = 'unset';
            } else if (parentElement.parentNode.className === 'symbol-search__container') {
                parentElement.parentNode.style.maxWidth = '100%';
                parentElement.style.marginRight = '0px';
                symbolSelect.style.maxWidth = 'unset';
            } else {
                parentElement.style.maxWidth = 'calc(100% - 200px)';
                parentElement.style.marginRight = '8px';
                symbolSelect.style.maxWidth = '200px';
            }
        }
        if (this.props.id === 'bottom-resize') {
            const height = document.getElementById('bottom-panel__content').offsetHeight;
            this.props.handleResize(height);
        }
        if (this.props.handleResize) {
            this.props.handleResize(this.state.size);
        } else {
            this.validateSize();
        }
    };

    render() {
        const { size } = this.state;
        const {
            handleClass, borderClass, children, style, containerClass
        } = this.props;
        const dragHandlers = {
            onDrag: this.handleDrag,
            onStop: this.handleDragEnd
        };
        const { direction } = this.props;
        const isHorizontal = this.isHorizontal();

        let containerClasses = isHorizontal ? 'ContainerHorizontal Container' : 'ContainerVertical Container';

        if (containerClass) {
            containerClasses += ` ${containerClass}`;
        }

        const containerStyle = { ...style } || {};
        if (size !== 0) {
            containerStyle.flexGrow = 0;
            containerStyle[isHorizontal ? 'width' : 'height'] = 'auto';
        }

        let handleClasses;
        if (handleClass) {
            handleClasses = handleClass;
        } else {
            handleClasses = isHorizontal ? 'ResizeHandleHorizontal' : 'ResizeHandleVertical';
        }
        let resizeBarClasses;
        if (borderClass) {
            resizeBarClasses = borderClass;
        } else {
            resizeBarClasses = isHorizontal ? 'ResizeBarHorizontal' : 'ResizeBarVertical';
        }
        const contentStyle = isHorizontal ? { width: `${size}px` } : { height: `${size}px` };
        const contentClassName = isHorizontal ? 'ResizeContentHorizontal ResizeContent' : 'ResizeContentVertical ResizeContent';

        const content = [
            <div
                key='content'
                ref={this.contentRef}
                className={contentClassName}
                style={contentStyle}
            >
                {React.Children.only(children)}
            </div>
        ];

        const handle = (
            <DraggableCore key='handle' {...dragHandlers}>
                <div className={resizeBarClasses}>
                    <div className={handleClasses}>
                        <span />
                    </div>
                </div>
            </DraggableCore>
        );

        // Insert the handle at the beginning of the content if our directio is west or north
        if (direction === 'w' || direction === 'n') {
            content.unshift(handle);
        } else {
            content.push(handle);
        }

        return (
            <div
                ref={this.wrapperRef}
                className={containerClasses}
                style={containerStyle}
            >
                {content}
            </div>
        );
    }
}

export default ReactResizePanel;
