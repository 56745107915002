import React, { Fragment } from 'react';
// import PropTypes from 'prop-types';

import OuterClick from './OuterClick';


const AutoCompliteExpanded = ({ autoCompliteResult, isLoading, handleSelect, shouldClose }) => {
    return (
        <Fragment>
            {autoCompliteResult?.length > 0 && !isLoading && !shouldClose && (
                <div className='autocomplete__expanded'>
                    {autoCompliteResult.map((item) => {
                        return (
                            <div className='autocomplete__item' name={item?.Nickname} data-avatar={item?.Avatar} key={item?.Nickname} onClick={handleSelect}>
                                <div className={`avatar ${item?.Avatar.toLowerCase()}`} />
                                {item?.Nickname}
                            </div>
                        );
                    })}
                </div>
            )}
        </Fragment>
    );
};

// AutoCompliteExpanded.propTypes = {};

export default OuterClick(AutoCompliteExpanded);
