import {TicksRepository} from '../../background/repositories/TicksRepository'
import {ProfitsRepository,AccountMovingPLRepository} from '../../background/repositories/ProfitsRepository'
import {ProfitCalculationModes} from '../../background/reducers/symbolsReducer'
import {ActionType} from '../reducers/openPositionsReducer'
import {getStore} from '../storeContainer';

export const round = (value, decimals) => {
  return Math.abs(value) < 0.000001 ? Math.round(value * 10 ** decimals) / 10 ** decimals
    : Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
};

const calculateMovingProfits = function(profitObj,position,tick,symbol)
{
  let currentPrice = tick.ask;
  let convertion = tick.askProfitConversion;
  if (position.actionType === ActionType.Buy){
    currentPrice = tick.bid;
    convertion = tick.bidProfitConversion;
  }

  profitObj.diff = round((position.actionType === ActionType.Buy ? (currentPrice - position.openRate):(position.openRate - currentPrice)),12);
  profitObj.pipDiff = round(profitObj.diff * Math.pow(10,symbol.pipDigit),6);

  let profit = ProfitCalculationService.calculateProfit(symbol,position.actionType,position.amount,position.openRate,currentPrice,convertion);
  if (profit === null)
  {
    profitObj.isValidProfit = false;
  }
  else
  {
    profitObj.profit = profit;
    profitObj.profitWithCommitions = profit + position.swaps + position.taxes + position.commission;
    profitObj.isValidProfit = true;
  }
}

TicksRepository.subscribeEventsNative((changes)=>{
  let changed = false;
  for (let i=0 ;i<changes.deleted.length;i++){
     let symbol  =changes.deleted[i];
     let positions = ProfitCalculationService.getPositionsBySymbolId(symbol.key);
     for (let position of positions)
     {
       position.isValidProfit = false;
       ProfitsRepository.publish(position);
       changed = true;
     }
  }

  let openPositions = getStore().getState().account.positions.open.positions;
  let symbols =  getStore().getState().account.symbols.symbols;
  
  for (let i=0 ;i<changes.created.length;i++){
    if (_handleTick(changes.created[i], openPositions, symbols))
    {
      changed = true;
    }
  }

  for (let i=0 ;i<changes.updated.length;i++){
    if (_handleTick(changes.updated[i], openPositions, symbols))
    {
      changed = true;
    }
  }

  if (changed)
  {
    ProfitCalculationService._updateMovingPL();
  }
});

function _handleTick(tick, openPositions, symbols) {
  let changed = false;
  let profits = ProfitCalculationService.getPositionsBySymbolId(tick.key);
  if (profits)
  {
    for (let profitPositionid of profits) {
      let statePosition = openPositions[profitPositionid.id];
      profitPositionid.isValidProfit = false;
      if (statePosition) {
        let stateSymbol = symbols[statePosition.symbolId];
        if (stateSymbol) {
          calculateMovingProfits(profitPositionid, statePosition, tick.data, stateSymbol);
        }
      }
  
      changed = true;
      ProfitsRepository.publish(profitPositionid);
    }
  }

  return changed;
}


class profitCalculationService
{
  #positionIdsBySymbolId = new Map()
  #positionsById = new Map()
  
  calculateProfit(symbol,actionType,amount,openPrice,closedPrice,convertion)
  {
    let currentPrice = closedPrice;
    if (!convertion)
    {
      return null;
    }
    else if (symbol.profitCalcMode === ProfitCalculationModes.Forex ||
              symbol.profitCalcMode === ProfitCalculationModes.CFD ||
              symbol.profitCalcMode === ProfitCalculationModes.MT5Forex ||
              symbol.profitCalcMode === ProfitCalculationModes.MT5CFDLeverage ||
              symbol.profitCalcMode === ProfitCalculationModes.MT5CFD ||
              symbol.profitCalcMode === ProfitCalculationModes.MT5CFDIndex)
    {
      const closeVolume = round(currentPrice * amount * symbol.contractSize / convertion,2);
      const openVolume = round(openPrice * amount * symbol.contractSize / convertion,2);
      return round(actionType === ActionType.Buy ? closeVolume - openVolume : openVolume - closeVolume,2);
    }
    else if (symbol.profitCalcMode === ProfitCalculationModes.MT5Futures ||
            symbol.profitCalcMode === ProfitCalculationModes.Future)
    {
      const closeVolume = round(currentPrice * amount * symbol.tickValue / symbol.tickSize / convertion,2);
      const openVolume = round(openPrice * amount * symbol.tickValue / symbol.tickSize / convertion,2);
      return actionType === ActionType.Buy ? closeVolume - openVolume : openVolume - closeVolume;
    }
    else if (symbol.profitCalcMode === ProfitCalculationModes.Sirix)
    {
      return symbol.contractSize * amount * convertion * (actionType === ActionType.Buy ? (currentPrice - openPrice):(openPrice - currentPrice));
    } 
  }

  _recalAll()
  {
    let positionsToRemove = new Map();
    for(let profitPosition of this.#positionsById.values()){      
      positionsToRemove.set(profitPosition.id,profitPosition);
    }

    let openPositions = getStore().getState().account.positions.open.positions;
    let symbols = getStore().getState().account.symbols.symbols;
    for(let positionKey in openPositions){
      let statePosition = openPositions[positionKey];
      let profitPosition = positionsToRemove.get(positionKey);
      positionsToRemove.delete(positionKey);
      if (!profitPosition){
        profitPosition = {
          id:statePosition.id,
          symbolId:null,
          openPlWitoutCommition : 0,
          profit : 0,
          diff : 0,
          pipDiff : 0,
          isValidProfit : false
        }
        this.#positionsById.set(profitPosition.id,profitPosition);
      }

      if (profitPosition.symbolId !== statePosition.symbolId)
      {
        let bySymbol =  this.#positionIdsBySymbolId.get(profitPosition.symbolId);
        if (bySymbol){
          bySymbol.delete(profitPosition.id);
        }

        profitPosition.symbolId = statePosition.symbolId;
        bySymbol =  this.#positionIdsBySymbolId.get(profitPosition.symbolId);
        if (!bySymbol){
          bySymbol = new Set();
          this.#positionIdsBySymbolId.set(profitPosition.symbolId,bySymbol);
        }
        bySymbol.add(profitPosition.id);
      }

      profitPosition.isValidProfit = false;
      let tick = TicksRepository.getNative(statePosition.symbolId);
      if (tick){
        let stateSymbol = symbols[statePosition.symbolId];
        if (stateSymbol){
          calculateMovingProfits(profitPosition, statePosition, tick, stateSymbol);
        }
      }

      ProfitsRepository.publish(profitPosition);
    }

    for (let positionToRemove of positionsToRemove.values()){
      this.#positionsById.delete(positionToRemove.id);
      let bySymbol = this.#positionIdsBySymbolId.get(positionToRemove.symbolId);
      if (bySymbol){
        bySymbol.delete(positionToRemove.id);
      }

      ProfitsRepository.publishDelete(positionToRemove);
    }

    this._updateMovingPL();
  }

  _updateMovingPL()
  {
    let store = getStore().getState();
    let financials = store.account.financial;
    if (financials)
    {

      let totalOpen = 0;
     let totalCommision = 0;
     for (const pos of this.#positionsById.values()) {
        if (pos.isValidProfit)
        {
          totalOpen += pos.profit;
          totalCommision += (pos.profitWithCommitions - pos.profit);
        }
      }

      totalOpen = round(totalOpen,2);
      totalCommision = round(totalCommision,2);
  
      let equity = round(totalOpen + totalCommision + financials.balance + financials.credit,2);
      AccountMovingPLRepository.publish({
        id: "MOVINGPNL",
        openPl : round(totalOpen + totalCommision,2) ,
        openPlWitoutCommition : totalOpen,
        equity : equity,
        freeMargin : round(equity - financials.margin,2),
        marginLevel :  financials.margin == 0 ? 0 : round((equity / financials.margin)*100,2),
      });
    }
  }

  getPositionsBySymbolId(symbolId)
  {
    let ids = this.#positionIdsBySymbolId.get(symbolId);
    if (ids)
    {
      let positions = [];
      for(let id of ids)
      {
        let pos = this.#positionsById.get(id);
        if (pos){
          positions.push(pos);
        }
      }
      return positions;
    }
  }
}

export const ProfitCalculationService = new profitCalculationService();

