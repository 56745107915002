import React, {useCallback, useEffect, useMemo, useState} from 'react';

import useSymbol from '../context/contextHook/useSymbol';
import useControls from '../context/contextHook/useControls';
import {trade4ProToInternal} from '../../shared/Charts/ChartPanel';

import MobileTradingChart
	from '../modules/MobileTrading/MobileTradingChart/MobileTradingChart';
import MobileTrade from '../modules/MobileTrade/MobileTrade';
import MobilePopUp from '../components/MobilePopUp/MobilePopUp';

const MobileTradingChartPage = () => {
	const {
		selectedSymbol,
		setSelectedSymbol,
		orderedSymbols,
	} = useSymbol();
	const {tradingChartControls, tradeControls} = useControls();

	const timeFrames = useMemo(() => {
		const timeFrames = [];
		Object.entries(trade4ProToInternal).forEach((item) => {
			timeFrames.push({timeKey: item[1], timeValue: item[0]});
		});
		return timeFrames;
	}, [trade4ProToInternal]);

	const [timeSelected, setTimeSelected] = useState(timeFrames[1]);

	const symbolValue = useMemo(() => {
		return orderedSymbols?.findIndex(el => el.id === selectedSymbol.id);
	}, [selectedSymbol, orderedSymbols]);

	const handleChangeSymbol = useCallback(option => {
		setSelectedSymbol && setSelectedSymbol(option.value);
	}, []);

	const handleTimeChange = useCallback(option => {
		if (!option) return null;
		setTimeSelected && setTimeSelected(option);
	}, [setTimeSelected]);

	//trade pop up
	const handleTradeClose = useCallback(() => {
		if (!tradeControls) return null;
		const {handleClose} = tradeControls;
		handleClose && handleClose();
	}, []);

	useEffect(() => {
		const {handleOpen, handleClose} = tradingChartControls;
		handleOpen();
		return () => {
			handleClose();
		};
	}, [tradingChartControls]);

	return (
		<>
			{selectedSymbol && (
				<MobileTradingChart
					selectedSymbol={selectedSymbol}
					orderedSymbols={orderedSymbols}
					handleChangeSymbol={handleChangeSymbol}
					symbolValue={symbolValue}
					timeFrames={timeFrames}
					timeSelected={timeSelected}
					setTimeSelected={setTimeSelected}
					handleTimeChange={handleTimeChange}
				/>
			)}

			{/*TRADE POP UP*/}
			<MobilePopUp
				type="section"
				isOpen={tradeControls.isOpen}
				onClose={handleTradeClose}
			>
				<MobileTrade
					onClose={handleTradeClose}
					selectedSymbol={selectedSymbol}
				/>
			</MobilePopUp>
		</>
	);
};

export default MobileTradingChartPage;
