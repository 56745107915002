export default class chartController {
    constructor() {
        this._chartInstance = null;
        this._symbolChangeCallback = null;
        this._periodChangeCallback = null;
    }

    setChartInstance(chart) {
        this._chartInstance = chart;
    }

    clearIndicators() {
        if (this._chartInstance) {
            this._chartInstance.clearIndicators();
            this._chartInstance.clearDrawings();
        }
    }

    addIndicator(indicator) {
        if (this._chartInstance) {
            this._chartInstance.addIndicator(indicator.name, { ...indicator?.settings, ...indicator?.styles });
        }
    }

    getIndicators(){
      if (this._chartInstance) {
        return this._chartInstance.getIndicators();
      }
      return [];
    }

    addDeal(symbol, openTime, action, tp, sl){
      if (this._chartInstance) {
        return this._chartInstance.addDeal(symbol, openTime, action, tp, sl);
      }
      return null;
    }

    getChartPeriod(){
      if (this._chartInstance) {
        return this._chartInstance.getTimeframe();
      }
      return "";
    }

    callChartUpdated(symbolId, period) {
        if (this._symbolChangeCallback) {
            this._symbolChangeCallback(symbolId);
        }

        if (this._periodChangeCallback) {
            this._periodChangeCallback(period);
        }
    }

    setSymbolChangedCallback(callback) {
        this._symbolChangeCallback = callback;
    }

    setPeriodChangedCallback(callback) {
        this._periodChangeCallback = callback;
    }
}
