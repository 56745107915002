import React from 'react';
import {useTranslation} from 'react-i18next';

import MobileTransition from '../../../components/MobileTransition';

const MobileTradeProcessing = ({isOpen}) => {

	const {t} = useTranslation();

	return (
		<MobileTransition
			isOpen={isOpen}
			type="fade"
		>
			<div
				className='loading-spinner__overlay loading-spinner__overlay--local overlay--dark'>
				<div className='mobile-order-processing'>
					<p><strong>{t('Trade.Execute.PleaseWait')}</strong></p>
					<p>{t('Trade.Execute.Processing')}</p>
				</div>
			</div>
		</MobileTransition>
	);
};

export default MobileTradeProcessing;
