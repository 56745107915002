import React from 'react';

const LOCK_ORIENTATIONS = [
	'portrait',
	'portrait-primary',
	'portrait-secondary',
	'landscape',
	'landscape-primary',
	'landscape-secondary',
	'default',
];

const useScreenOrientation = () => {

	const lockScreenOrientation = () => {
		const screen = new Screen();
		screen.lockOrientationUniversal = screen.lockOrientation
				|| screen.mozLockOrientation
				|| screen.msLockOrientation;

		if (screen.lockOrientationUniversal('portrait-primary')) {
			// Orientation was locked
		} else {
			// Orientation lock failed
		}
	};

	// const elem = document.getElementById('root');
	//
	// if (elem.requestFullscreen) {
	// 	elem.requestFullscreen();
	// } else if (elem.webkitRequestFullscreen) {
	// 	elem.webkitRequestFullscreen();
	// } else if (elem.msRequestFullscreen) {
	// 	elem.msRequestFullscreen();
	// }
	//
	// console.log('Screen', Screen);
	// Screen.orientation.lock('portrait-primary');
	//
	// document.body.requestFullscreen()
	// 		.then(res => console.log('then', res))
	// 		.catch(err => console.log('err', err));

// 	const {screen} = window;
//
// 	window.addEventListener('orientationchange', checkOrientationChange);
//
// 	function checkOrientationChange() {
// 		let screenOrientation = screen.orientation;
// 		switch (screenOrientation) {
// 			case 0:
// 				console.log('you are in portrait-primary mode');
// 				break;
// 			case 90:
// 				goFullScreen();
// 				break;
// 			case 180:
// 				goFullScreen();
// 				break;
// 			case 270:
// 				goFullScreen();
// 				break;
// 			default:
// 				console.log('implementation of screen orientation');
// 		}
// 	}
//
// // function to request full screen of device browser
//
// 	function goFullScreen() {
// 		var elem = document.getElementById('myContent');
// 		if (elem.requestFullscreen) {
// 			elem.requestFullscreen().then(data => {
// 				lockScreenOrientation();
// 			}, err => {
// 				console.log('no');
// 			});
// 		} else if (elem.mozRequestFullScreen) { /* Firefox */
// 			elem.mozRequestFullScreen().then(data => {
// 				lockScreenOrientation();
// 			}, err => {
// 				console.log('Full Screen request failed');
// 			});
// 		} else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
// 			elem.webkitRequestFullscreen().then(data => {
// 				lockScreenOrientation();
// 			}, err => {
// 				console.log('Full Screen request failed');
// 			});
// 		} else if (elem.msRequestFullscreen) { /* IE/Edge */
// 			elem.msRequestFullscreen().then(data => {
// 				lockScreenOrientation();
// 			}, err => {
// 				console.log('Full Screen request failed');
// 			});
// 		}
// 	}
//
// //function to lock the screen. in this case the screen will be locked in portrait-primary mode.
//
// 	function lockScreenOrientation() {
// 		screen.lockOrientationUniversal = screen.lockOrientation ||
// 				screen.mozLockOrientation || screen.msLockOrientation;
//
// 		if (screen.lockOrientationUniversal('landscape-primary')) {
// 			// Orientation was locked
// 		} else {
// 			// Orientation lock failed
// 		}
// 	}

	// const [orientation, setOrientation] = useState();

	// const isPortrait = useMemo(() => {
	// 	return orientation === LOCK_ORIENTATIONS[0]
	// 			|| orientation === LOCK_ORIENTATIONS[1]
	// 			|| orientation === LOCK_ORIENTATIONS[2]
	// }, [orientation, LOCK_ORIENTATIONS])
	//
	// const {screen} = window;

	// const handleLock = useCallback(() => {
	// 	screen.lock = function(a) {
	// 		if (screen.lockOrientation) {
	// 			screen.lockOrientation(a);
	// 			return true;
	// 		} else {
	// 			if (screen.orientation.lock) {
	// 				screen.orientation.lock(a);
	// 				return true;
	// 			} else return false;
	// 		}
	// 	};

	// 	if (screen.lock('portrait'))
	// 		alert(1);
	// 	else
	// 		alert(0);
	// }, []);

	// // window.addEventListener('orientationchange', handleLock);
	// window.addEventListener('orientationchange', () => {
	// 	setOrientation(window.screen.orientation.type);
	// 	// screen.lockOrientation('portrait-primary');
	// 	// window.screen.orientation.lock('portrait-primary');
	// });

	// return {
	// 	orientation,
	// 	isPortrait,
	// }

	// screen.lockOrientationUniversal = screen.lockOrientation
	// 		|| screen.mozLockOrientation
	// 		|| screen.msLockOrientation;
	//
	// const lock = orientation => {
	// 	const or = orientation || LOCK_ORIENTATIONS[0];
	// 	if (screen.orientation && typeof screen.orientation.lock === 'function') {
	// 		return window.screen.orientation.lock(or);
	// 	} else if (screen.lockOrientationUniversal) {
	// 		return new Promise((resolve, reject) => {
	// 			if (screen.lockOrientationUniversal(or)) {
	// 				resolve();
	// 			} else {
	// 				reject();
	// 			}
	// 		});
	// 	} else {
	// 		return new Promise((resolve, reject) => reject());
	// 	}
	// };

	// useEffect(() => {
	// 	lock().then(console.log);
	// }, [lock]);
	//
	// return {
	// 	lock,
	// };

	// const ready = useCallback(() => {
	// 	const {type} = window.screen.orientation;
	// 	console.log(`Fullscreen and locked to ${type}. Ready!`);
	// }, []);

	// const start = useCallback(async () => {
	// 	await document.body.requestFullscreen();
	// 	await window.screen.orientation.lock('portrait-primary');
	// 	ready();
	// }, [ready]);

	// useEffect(() => {
	// 	start()
	// 			.then(console.log)
	// 			.catch(console.log);
	// }, [start()]);
};

export default useScreenOrientation;
