import React, { useMemo } from 'react';

import MobilePopUpHeader from '../../MobilePopUp/MobilePopUpHeader';
import MobileButtonClose from '../../Buttons/MobileButtonClose';

import PubsubSubscription from '../../../../background/repositories/PubsubSubscription';
import {prepareBidAsk} from '../../../../UI/utilsUI/formatWSTick';
import MobileSymbolIcon from '../../MobileSymbolIcon/MobileSymbolIcon';

const MobileUpdatePositionHeader = (props) => {
    const { onClose, selectedSymbol, reference } = props;

    const [symbolTick, previousTick] = PubsubSubscription.subscribeObjAndPrev(selectedSymbol?.tick);

    const pipDigit = useMemo(() => {
        const { displayDigit } = selectedSymbol;
        return displayDigit;
    }, [selectedSymbol]);

    const { bidOutput, askOutput } = useMemo(() => {
        return prepareBidAsk(symbolTick, previousTick, pipDigit);
    }, [symbolTick, previousTick, pipDigit]);

    const title = useMemo(() => {
        const { displayName } = selectedSymbol;
        return displayName;
    }, [selectedSymbol]);

    return (
        <MobilePopUpHeader>
            <div
                ref={reference}
                className='mobile-update-position-popup__header'
            >
                <div className='mobile-trade__header__desc'>
                    <div className='mobile-trade__header__desc__flag'>
                        <MobileSymbolIcon
                            name={title}
                            flag={selectedSymbol.flag}
                        />
                    </div>
                    <p className='mobile-trade__header__desc__title'>
                        {title}
                    </p>
                </div>
                <div className='mobile-trade__header__desc__tick'>
                    {askOutput}
                </div>
                <div className='mobile-trade__header__desc__tick'>
                    {bidOutput}
                </div>
                <div className='mobile-update-position-popup__header__btn'>
                    <MobileButtonClose onClick={onClose} />
                </div>
            </div>

        </MobilePopUpHeader>
    );
};

export default MobileUpdatePositionHeader;
