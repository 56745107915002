import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import MobileTransition from '../../../components/MobileTransition';
import MobileModalSuccess
	from '../../../components/MobileModal/MobileModalSuccess';

const MobileTradeSuccess = props => {
	const {
		isOpen = false,
		onClose,
		price,
		pendingOrder = false,
	} = props;
	const {t} = useTranslation();

	const handleClose = useCallback(e => {
		return onClose && onClose(e);
	}, [onClose]);

	const currentTitle = useMemo(() => {
		return pendingOrder
			? t('Trade.Execute.SuccessPending')
			: t('Trade.Execute.Success');
	}, [pendingOrder]);

	return (
		<MobileTransition
			isOpen={isOpen}
			type="fade"
		>
			<MobileModalSuccess
				onClose={handleClose}
				title={currentTitle}
			>
				{!pendingOrder && (
					<span>
					{t('Trade.Execute.SuccessPrice')}
						&nbsp;
						{price || ''}
				</span>
				)}
			</MobileModalSuccess>
		</MobileTransition>
	);
};

export default MobileTradeSuccess;
