import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';

import Button from '../primitives/Button';
import CheckBox from '../primitives/CheckBox';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';


const CopyDisclaimer = ({
    handleClose, copyColor, handleAddCopy, masterNickname
}) => {
    const [isDisclaimerCkecked, setIsDisclaimerCkecked] = useState(false);
    const { t } = useTranslation();

    const toggleCheckBox = () => {
        setIsDisclaimerCkecked((prevState) => !prevState);
    };

    const confirmAndCopy = () => {
        handleClose();
        handleAddCopy(false);
        SendEventToGoogleAnalytics('Profile:Copy:LFSDisclaimer:CopyClick', { masterNickname });
    };

    return (
        <div>
            <div>
                <p>
                    <b>Warning</b>
                    : The Strategy you have selected is NOT in line with your Investment and Risk Profile and LFS does not recommend this Strategy/Trader for you.
                    We recommend you consider a strategy in your Investment and Risk Profile or below."
                </p>
                <p>
                    Should you wish to proceed despite this warning please confirm that you are aware of all the risks associated with trading these markets,
                    and have sought advice from an independent financial advisor for any questions or doubts and understand the Risks involved by checking this box.
                </p>
                <CheckBox
                    value={isDisclaimerCkecked}
                    name='copyConfirm'
                    label='Confirm'
                    labelPlacement
                    handleCheckBoxClick={toggleCheckBox}
                />
                <p>
                    We would like you to note that past performance of all Trading strategies is not a guarantee, nor indicative of future returns.
                    There is a high level of risk involved with trading forex, cryptocurrencies, commodities, indices and other CFDs.
                    Funds placed under management should be risk capital funds that if lost will not significantly affect one’s personal financial well-being.
                </p>
            </div>
            <div className='question-page__cancel-buttons'>
                <Button className={isDisclaimerCkecked ? 'btn' : 'btn btn--no-pointer'} text={t('Socials.Button.Copy')} style={copyColor} onClickHandler={confirmAndCopy} />
                <Button className='btn btn--general btn--cancel' text={t('General.Cancel')} onClickHandler={handleClose} />
            </div>
        </div>
    );
};

// CopyDisclaimer.propTypes = {};

export default CopyDisclaimer;
