import React, { useState, useEffect, Fragment } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import 'frappe-charts/dist/frappe-charts.min.css';
// import PropTypes from 'prop-types';

import { CommandFactory } from '../../background/commands/CommandFactory';
import LinearProgressBarWithLabel from '../primitives/LinearProgressBarWithLabel';
import SocialStreamItem from '../SocialStream/SocialStreamItem';
import LoadSpinner from '../primitives/LoadSpinner';
import Graph from '../primitives/Graph';
import { formatDateOnly } from '../utilsUI/formatTime';
import useWindowSize from '../utilsUI/useWindowSize';

import { TIMEFRAMES } from './PortfolioTab';
import ProfileTabDetails from './ProfileTabDetails';
import ProfilePageRadioGroup from './ProfilePageRadioGroup';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';

import './ProfilePage.css';


const ProfileTab = ({ socialFeed, risklevel, userName, joinDateValue, generalColor }) => {
    const { t } = useTranslation();
    const [userDetailedProfile, setDetailedUserProfile] = useState(null);
    const [timeframe, setTimeframe] = useState(4);
    const [isLoading, setIsLoading] = useState(true);
    const [labels, setLabels] = useState([]);
    const [values, setValues] = useState([]);
    const [width, height] = useWindowSize();
    const [error, setError] = useState(false);
    const { profileCommands: { getGetPlAndStats } } = useSelector((state) => { return (state.account.socialv1); }, shallowEqual);

    const getDetailedProfileData = async (nickname, period) => {        
        const userInput = { nickname, timeframe: period };
        const command = CommandFactory.createCommand(getGetPlAndStats);
        try {
            const res = await command.execute(userInput);

            if (res.ok === false) {
                setError({ result: res.statusText });
            }
            if (res.isSuccessful === true) {
                return res.payload;
            } else if (res.isSuccessful === false) {
                setError(res);
            }
        } catch (err) {
            setError(err);
        }
    };

    useEffect(() => {
        let isSubscribed;
        isSubscribed = true;
        setIsLoading(true);
        // eslint-disable-next-line no-unused-expressions
        userName && getDetailedProfileData(userName, timeframe).then((info) => {
            if (isSubscribed) {
                setDetailedUserProfile(info);
                setIsLoading(false);
            }
        });

        return () => {
            return isSubscribed = false;
        }
    }, [userName]);

    useEffect(() => {
        if (userDetailedProfile) {
            getDetailedProfileData(userName, timeframe).then((info) => {
                setDetailedUserProfile(info);
            });
        }
    }, [timeframe]);

    useEffect(() => {
        if (userDetailedProfile) {
            const pnl = userDetailedProfile?.DailyPLs;
            const localLabels = [];
            const localValues = [];
            let total = 0;
            if (pnl?.length) {
                pnl.forEach((item) => {
                    localLabels.push(`${formatDateOnly(new Date(item.Date))} / ${item.NormalizedPL > 0 ? '+' : ''}${((Math.round(item.NormalizedPL * 100000) / 100000) * 100).toFixed(2)}%`);
                    // labels.push(`${formatDateOnly(new Date(item.Date))} / ${item.NormalizedPL > 0 ? '+' : ''}${((Math.round(item.NormalizedPL * 100000) / 100000) * 100).toFixed(2)}%`);
                    total += (Math.round(item.NormalizedPL * 100000) / 100000);
                    localValues.push(total);
                    // values.push(total);
                });
            }
            setValues(localValues);
            setLabels(localLabels);
            // setIsLoading(false);
        }
    }, [userDetailedProfile, timeframe]);

    useEffect(() => {
      SendEventToGoogleAnalytics("Profile:ProfileTabOpened");
  }, []);

    const setTimeframeValue = (value) => {
        setTimeframe(parseInt(value, 10));
    };


    return (
        <div className='profile-tab'>
            <div className='profile-tab__details-container' style={generalColor}>
                {!isLoading ? (
                    <Fragment>
                        <div className='profile-tab__chart'>
                            <div className='positions-tab__type-buttons'>
                                <ProfilePageRadioGroup groupList={TIMEFRAMES} selectedItem={timeframe} handleSelectItem={setTimeframeValue} />
                            </div>
                            <div className='profile-tab__chart-container'>
                                {(values && values.length > 0) && (
                                    <Graph
                                        type='line'
                                        data={{
                                            labels,
                                            datasets: [{
                                                values
                                            }]
                                        }}
                                        height={width < 1200 ? 250 : 370}
                                        onSelect={false}
                                        heatline
                                        region_fill
                                        isNavigable={false}
                                        tooltipOptions={{
                                            formatTooltipY: (d) => `P/L ${Math.round(d * 100000) / 1000}`
                                        }}
                                        lineOptions={{
                                            hideDots: 1,
                                            regionFill: 1,
                                            areaFill: 1
                                        }}
                                        resetPadding
                                    />
                                )}
                            </div>
                            {userDetailedProfile && <ProfileTabDetails detailedData={userDetailedProfile?.UserMeasures} joinDateValue={joinDateValue} />}
                            {/* <div className='details__label' style={{ width: '100%' }}>{t('Social.Profile.RiskLevel')}</div>
                            <LinearProgressBarWithLabel value={risklevel} /> */}
                        </div>
                        {/* <div className='profile-tab__social-feed'>
                            {socialFeed.map((item) => <SocialStreamItem key={item.Nickname + item.TradeHash} user={item} />)}
                        </div> */}
                    </Fragment>
                ) : (
                    <div className='profile-page__container1'>
                        <LoadSpinner />
                    </div>
                )}
            </div>
            <div className='profile-tab__social-feed'>
                {socialFeed.map((item) => <SocialStreamItem key={item.Nickname + item.TradeHash} user={item} />)}
            </div>
        </div>
    );
};

// ProfileTab.propTypes = {};

export default ProfileTab;
