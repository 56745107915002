import React from 'react';
import clsx from 'clsx';
import {ReactComponent as Business} from '../../assets/icons/business.svg';
import {shallowEqual, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import MobileFooterHeaderData from './MobileFooterHeaderData';
import {PreparePNL} from '../../../UI/PNL/PNL';
import {roundNumberPipDigit} from '../../../UI/utilsUI/formatWSTick';

const MobileFooterHeader = props => {
	const {
		isOpen,
		pnlTick,
		balance,
		symbol,
		margin,
		credit,
		reference,
	} = props;

	const {positions} = useSelector(state => state.account.positions.open,
			shallowEqual);
	const openPositionsQuantity = Object.keys(positions).length;

	const {t} = useTranslation();
  const marginLevelData = margin === 0 ? <></> : <>{roundNumberPipDigit(pnlTick.equity/margin * 100, 2)}%</>;
	return (
			<div
					ref={reference}
					className={clsx('mobile-footer-content__header', isOpen && 'open')}
			>
				<div className="gradient_line"/>
				{isOpen && (
						<div className="mobile-footer__header__close"/>
				)}
				<div className="mobile-footer-content__header__inner">

					{/*ROW 1*/}
					<div className="mobile-footer-content__header__row">

						{!isOpen && (
								<div className="mobile-footer__mini__content__icon">
									<Business/>
									<div
											className={clsx(
													'mobile-footer__mini__content__icon__count',
													openPositionsQuantity > 99 && 'small')}
									>
										{openPositionsQuantity <= 99
												? openPositionsQuantity
												: '+99'
										}
									</div>
								</div>
						)}

						<MobileFooterHeaderData
								label={t('General.OpenPl')}
								data={
									<PreparePNL
											pnlTick={pnlTick}
											symbol={symbol}
											shouldFormat
									/>
								}
						/>

						<MobileFooterHeaderData
								label={t('General.Balance')}
								data={<>
									{symbol}
									{roundNumberPipDigit(balance, 2)}
								</>}
						/>
						<MobileFooterHeaderData
								label={t('General.Equity')}
								data={<PreparePNL pnlTick={pnlTick} symbol={symbol}/>}
						/>

					</div>

					{/*ROW 2*/}
					<div className="mobile-footer-content__header__row">

          <MobileFooterHeaderData
								label={t('Drawer.MarginLevel')}
								data={marginLevelData}
						/>

						<MobileFooterHeaderData
								label={t('General.FreeMargin')}
								data={<>
									{symbol}
									{roundNumberPipDigit(pnlTick.freeMargin, 2)}
								</>}
						/>

						<MobileFooterHeaderData
								label={t('General.Credit')}
								data={<>
									{symbol}
									{roundNumberPipDigit(credit, 2)}
								</>}
						/>

					</div>

				</div>
			</div>
	);
};

export default MobileFooterHeader;
