/* eslint-disable prefer-template */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';

import OuterClick from '../primitives/OuterClick';
import calcMarginRequirement from '../utilsUI/calcMarginRequirement';
import { roundNumberPipDigit } from '../utilsUI/formatWSTick';
import scrollToElement from '../utilsUI/scrollToElement';
import { ProfitCalculationModes } from '../../background/reducers/symbolsReducer';

import { ACTION_TYPE } from './TradePopup';
import './UnitsDropDown.css';

const UNITS_MULTIPLIER = [
    0.001,
    0.01,
    0.1,
    0.125,
    0.15,
    0.175,
    0.2,
    0.225,
    0.25,
    0.275,
    0.3,
    0.325,
    0.35,
    0.375,
    0.4,
    0.425,
    0.45,
    0.475,
    0.5,
    0.525,
    0.55,
    0.575,
    0.6,
    0.625,
    0.65,
    0.675,
    0.7,
    0.725,
    0.75,
    0.775,
    0.8,
    0.825,
    0.85,
    0.875,
    0.9,
    0.925,
    0.95,
    0.975,
    1,
    1.25,
    1.5,
    1.75,
    1.1,
    1.125,
    1.15,
    1.175,
    1.2,
    1.225,
    1.25,
    1.275,
    1.3,
    1.35,
    1.4,
    1.45,
    1.5,
    1.6,
    1.7,
    1.8,
    1.9,
    2,
    2.1,
    2.2,
    2.3,
    2.4,
    2.5,
    2.6,
    2.7,
    2.8,
    2.9,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    12,
    14,
    16,
    18,
    20,
    25,
    30,
    35,
    40,
    45,
    50,
    60,
    70,
    80,
    90,
    100,
    200,
    300,
    400,
    500,
    600,
    700,
    800,
    900,
    1000,
    2000,
    3000,
    4000,
    5000,
    6000,
    7000,
    8000,
    9000,
    10000,
    20000,
    30000,
    40000,
    50000,
    60000,
    70000,
    80000,
    90000,
    100000,
    1000000,
    10000000
];

const prepareUnitsList = (currentUnits, stepValue, tickObj, type, selectedSymbol, pnlTick) => {
    const resultSet = new Set();
    const resultArray = [];
    let counter = 0;
    UNITS_MULTIPLIER.forEach((multiply) => {
        // eslint-disable-next-line prefer-template
        const unitValue = +(Math.round((Math.round((currentUnits * multiply) / stepValue) * stepValue) + 'e+2') + 'e-2');
        if (unitValue) {
            resultSet.add(unitValue);
        }
    });

    [...resultSet].forEach((item) => {
        if (Math.round(calcMarginRequirement(tickObj, type, selectedSymbol, item)) <= pnlTick?.equity) {
            resultArray.push(item);
        } else if (counter < 3 && Math.round(calcMarginRequirement(tickObj, type, selectedSymbol, item)) > pnlTick?.equity) {
            counter += 1;
            resultArray.push(item);
        }
    });

    return resultArray;
};


const UnitsDropDown = ({
    selectUnits, toggleUnitsDropDown, selectedUnits, contractSize, stepValue, tickObj, isBidActive, unitsName, selectedSymbol, pnlTick, currency, handleClick
}) => {
    const [initialUnitsList, setInitialUnitsList] = useState(null);
    const selectedUnitsNumber = typeof selectedUnits === 'string' ? selectedUnits.replace(/[^0-9\.]/g, '') : selectedUnits;
    const type = isBidActive ? ACTION_TYPE.BID : ACTION_TYPE.ASK;
    const { t } = useTranslation();
    const handleUnitsSelect = (e) => {
        e.stopPropagation();
        if (e.target.closest('.units-row')) {
            selectUnits(e.target.closest('.units-row').firstChild.innerHTML);
        }
        handleClick();
    };

    useEffect(() => {
        setInitialUnitsList(prepareUnitsList(selectedUnitsNumber, stepValue, tickObj, type, selectedSymbol, pnlTick));
    }, []);

    useEffect(() => {
        if (initialUnitsList) {
            const container = document.getElementById('trade-popup__units-list');
            const position = document.getElementById('units-scroll-pointer');
            if (container && position) {
                scrollToElement(container, position);
            }
        }
    }, [initialUnitsList]);

    const convertionRate = isBidActive ? (tickObj?.askProfitConversion || 1) : (tickObj?.bidProfitConversion || 1);
    const currentPrice = isBidActive ? (tickObj?.bid || 1) : (tickObj?.ask || 1);

    return (
        <div onClick={handleUnitsSelect}>
            <div className='units-header'>
                <div className='units-header__fixed'>
                    <div>{unitsName}</div>
                    <div>{t('Units.Value')}</div>
                    <div>{t('Trade.Margin.Title')}</div>
                </div>
            </div>
            <div className='units-list' id='trade-popup__units-list'>
                {initialUnitsList?.length > 0 && initialUnitsList.map((unit) => {
                    return (
                        <div id={unit.toString() === selectedUnitsNumber.toString() ? 'units-scroll-pointer' : ''} className='units-row' key={unit} data-disabled={Math.round(calcMarginRequirement(tickObj, type, selectedSymbol, unit)) > pnlTick?.equity}>
                            <div>{unit}</div>
                            <div>
                                {currency}
                                {selectedSymbol.profitCalcMode == ProfitCalculationModes.Sirix
                                    ? roundNumberPipDigit(unit * currentPrice * contractSize * convertionRate, 2)
                                    : roundNumberPipDigit(unit * currentPrice * contractSize / convertionRate, 2)}
                            </div>
                            <div>
                                {currency}
                                {roundNumberPipDigit(calcMarginRequirement(tickObj, type, selectedSymbol, unit), 2)}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

// UnitsDropDown.propTypes = {};

export default OuterClick(UnitsDropDown, '#units-stepper');
