import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';

import OuterClick from '../primitives/OuterClick';
import scrollToElement from '../utilsUI/scrollToElement';
import {roundNumberPipDigit} from '../utilsUI/formatWSTick'
import './UnitsDropDown.css';


const preparePendingPricesList = (currentPendingPrice, pipDigit,displayDigit, tickPrice) => {

    const resultList = [];
    let stepValue = (10 ** -pipDigit);
    if (pipDigit != displayDigit)
    {
        stepValue = (10 ** -displayDigit) * 5;
    }
    const pipDigitRounding = 10 ** pipDigit;
    const priceRounding =  10 ** displayDigit;
    
    if (!(currentPendingPrice > 0))
    {
        currentPendingPrice = tickPrice;
    }

    let currentRoundedPrice = Math.round(currentPendingPrice * pipDigitRounding) / pipDigitRounding;
    let count = 1;
    for (let i = -40; i <= 40; i += 1) {
        let price = currentRoundedPrice + (stepValue * i);
        if (i == 0)
        {
            price = currentPendingPrice;
        }
        
        if (price > 0)
        {
            resultList.push({
                key : i,
                index: count,
                price : Math.round(price * priceRounding )/ priceRounding,
                distance: (price- tickPrice) * pipDigitRounding
            });
            count += 1;
        }
    }

    return resultList;    
};


const PendingDropDown = ({ selectPendingPrice, togglePendingDropDown, pendingPrice, pipDigit,displayDigit, tickObj, isBidActive }) => {
    useEffect(() => {
        const container = document.getElementById('trade-popup__pending-list');
        const position = document.getElementById('scroll-pointer');
        scrollToElement(container, position);
    }, []);
    const { t } = useTranslation();
    const tickPrice = isBidActive ? tickObj?.bid : tickObj?.ask;

    const renderUnits = preparePendingPricesList(pendingPrice, pipDigit,displayDigit, tickPrice);

    const handleUnitsSelect = (e) => {
        e.stopPropagation();
        let selectedElement = e.target.closest('.units-row');
        if (selectedElement)
        {
            let index = parseInt(selectedElement.getAttribute('index'));
            if (index)
            {
                let value = renderUnits[index -1];
                if (value)
                {
                    selectPendingPrice(value.price);    
                    togglePendingDropDown();
                }
            }
        }
    };

    return (
        <div onClick={handleUnitsSelect}>
            <div className='units-header'>
                <div className='units-header__fixed'>
                    <div>{t('InstrumetInfo.Details.Price')}</div>
                    {/* <div>{t('PendingOrder.Pipdiff')}</div> */}
                    <div>{t('General.Pips')}</div>
                </div>
            </div>
            <div id='trade-popup__pending-list' className='units-list'>
                {renderUnits.map(item =>{
                    return (<div className='units-row' key={item.key} index={item.index} {...(item.key === 0 ? {id:'scroll-pointer'}:{})}>
                        <div> {roundNumberPipDigit(item.price,displayDigit)}</div>
                        <div>
                            {item.distance > 0 && '+'}
                            {roundNumberPipDigit(item.distance,displayDigit-pipDigit)}
                        </div>
                    </div>)
                })}
            </div>
        </div>
    );
};

// PendingDropDown.propTypes = {};

export default OuterClick(PendingDropDown, '#pending-price-stepper');
