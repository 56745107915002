import {useEffect} from 'react';

const useClickOutside = (ref, callback) => {

	useEffect(() => {
		const listener = event => {
			const el = ref?.current;
			if (!el || el.contains((event?.target) || null)) return null;
			callback(event);
		};
		document.addEventListener(`mousedown`, listener);
		document.addEventListener(`touchstart`, listener);
		return () => {
			document.removeEventListener(`mousedown`, listener);
			document.removeEventListener(`touchstart`, listener);
		};
	}, [ref, callback]);

};
export default useClickOutside;
