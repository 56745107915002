/* eslint-disable no-restricted-syntax */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
// import PropTypes from 'prop-types';

import { allTradesStream, topRankedTradeStream, watchTradersStream } from '../../background/socialv1/tradesStreams';
import useWindowSize from '../utilsUI/useWindowSize';
import SocialStreamItem from './SocialStreamItem';
import { SendEventToGoogleAnalytics } from '../../background/storeListener/googleAnalyticsListener';


// const STREAM_TYPES = {
//     ALL: 'ALL',
//     TOPTRADERS: 'TOPTRADERS',
//     IWATCH: 'IWATCH'
// };


const STREAM_TYPES = {
    ALL: {
        name: 'ALL',
        translation: 'Social.Stream.Filter.ALL',
        stream: allTradesStream
    },
    TOPTRADERS: {
        name: 'TOPTRADERS',
        translation: 'Social.Stream.Filter.TopPerformers',
        stream: topRankedTradeStream
    },
    IWATCH: {
        name: 'IWATCH',
        translation: 'Social.Stream.Filter.IWatch',
        stream: watchTradersStream
    }
};


const SocialStream = ({ handleItemSelect, toggleOpenCopyTab, isClickable = false, generalColor, generalButtonColor }) => {
    const [streamType, setStreamType] = useState(STREAM_TYPES.ALL.name);
    const [selectedStreamType, setSelectedStreamType] = useState(allTradesStream);
    const [, setRerenderTime] = useState(null);
    const streamItem = [];
    const { t } = useTranslation();
    const [width] = useWindowSize();
    const isSmallDesktop = width < 1200;

    for (const item of selectedStreamType.getStackOrder()) {
        streamItem.push(
            <div key={item.TradeHash}>
                <SocialStreamItem streamType={streamType} user={item} handleItemSelect={handleItemSelect} toggleOpenCopyTab={toggleOpenCopyTab} isClickable={isClickable} isSmallDesktop={isSmallDesktop} />
            </div>
        );
    }
    selectedStreamType.subscribeReact(() => {
        setRerenderTime(Date.now());
    });

    const handleChange = (e) => {
        const { value } = e.target;
        setStreamType(STREAM_TYPES[value].name);
        setSelectedStreamType(STREAM_TYPES[value].stream);
        SendEventToGoogleAnalytics(`SocialStream:${STREAM_TYPES[value].name}Click`)
    };

    return (
        <div className='social-stream__panel'>
            <div className='social-stream__heading'>
                <div style={generalColor}>
                    {t('Socials.Stream.Title')}
                </div>
                <div>
                    <TextField
                        id='select-stream-type'
                        select
                        name='streamType'
                        value={streamType}
                        onChange={handleChange}
                        style={generalButtonColor}
                    >
                        {Object.values(STREAM_TYPES).map((option) => (
                            <MenuItem key={option.name} value={option.name}>
                                {t(option.translation)}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
            </div>
            <div className='social-stream__container'>
                {streamItem}
            </div>
        </div>
    );
};

// SocialStream.propTypes = {};

export default SocialStream;
