import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';

import {getRenderColumn} from '../../../helpers/position.helpers';

const MobileOpenPositionHead = ({columns, type = 'open', sort}) => {
	const {t} = useTranslation();
	return (
			<div className={clsx('mobile-open-position__head', type)}>

				{/*instrument*/}
				{getRenderColumn(columns, 'instrument') && (
						<p
								className={clsx('mobile-open-position__head__item',
										'instrument')}
						>
							{t('InstrumetInfo.Details.Instrument')}
						</p>
				)}

				{/*action*/}
				{getRenderColumn(columns, 'type') && (
						<p
								className={clsx('mobile-open-position__head__item',
										'action')}
						>
							{t('InstrumetInfo.Details.Type')}
						</p>
				)}

				{/*amount*/}
				{getRenderColumn(columns, 'amount') && (
						<p
								className={clsx('mobile-open-position__head__item',
										'amount')}
						>
							{t('InstrumetInfo.Details.Amount')}
						</p>
				)}

				{/*price*/}
				{getRenderColumn(columns, 'price') && (
						<p
								className={clsx('mobile-open-position__head__item',
										'price')}
						>
							{t('InstrumetInfo.Details.Price')}
						</p>
				)}

				{/*current price*/}
				{getRenderColumn(columns, 'currentPrice') && (
						<p
								className={clsx('mobile-open-position__head__item',
										'price-current')}
						>
							{t('Pending.CurrentPrice')}
						</p>
				)}

				{/*profit*/}
				{getRenderColumn(columns, 'profit') && (
						<p
								className={clsx('mobile-open-position__head__item',
										'profit')}
						>
							{t('InstrumetInfo.Details.Profit')}
						</p>
				)}
				{/*copied*/}
				{getRenderColumn(columns, 'copied') && (
						<p
								className={clsx('mobile-open-position__head__item',
										'copied')}
						>
							{t('InstrumetInfo.Details.CopiedFrom')}
						</p>
				)}

			</div>
	);
};

MobileOpenPositionHead.defaultProps = {
	columns: [],
	type: 'open',
};

MobileOpenPositionHead.propType = {
	columns: PropTypes.array,
	type: PropTypes.string,
};

export default MobileOpenPositionHead;
