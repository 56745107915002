import React, {useCallback, useMemo} from 'react';
import clsx from 'clsx';

import IconHide from '@material-ui/icons/VisibilityOffOutlined';
import IconMoreVert from '@material-ui/icons/MoreVert';
import IconArrowRight from '@material-ui/icons/KeyboardArrowRight';
import IconArrowDown from '@material-ui/icons/KeyboardArrowDown';
import {ReactComponent as IconCopy} from '../../assets/icons/icon-copy.svg';
import {ReactComponent as IconUsers} from '../../assets/icons/icon-users.svg';

const MobileButtonIcon = props => {
	const {type, onClick, className, style, isActive, disabled, stop} = props;

	const handleClick = useCallback(e => {
		e && e.preventDefault();
		if (stop) {
			e && e.stopPropagation();
		}
		if (disabled) return null;
		onClick && onClick(e);
	}, [onClick, disabled, stop]);

	const renderIcon = useMemo(() => {
		switch (type) {
			case 'arrow' :
				return isActive ? <IconArrowDown/> : <IconArrowRight/>;
			case 'arrow-right' :
				return <IconArrowRight/>;
			case 'arrow-down' :
				return <IconArrowDown/>;
			case 'hide':
				return <IconHide/>;
			case 'copy':
				return <IconCopy/>;
			case 'more':
				return <IconMoreVert/>;
			case 'user':
				return <IconUsers/>;
			default:
				return '';
		}
	}, [type, isActive]);

	const classes = useMemo(() => {
		let typeClass = '';
		let typeStyle = {};
		switch (type) {
			case 'more':
				typeStyle = {width: '4px'};
				break;
			default:
				typeClass = '';
		}
		return {
			className: clsx('mobile-button-icon',
					disabled && 'disabled',
					isActive && 'active',
					typeClass,
					className,
			),
			style: {...style, ...typeStyle},
		};
	}, [isActive, type, className]);

	return (
			<button
					disabled={disabled}
					onClick={handleClick}
					className={classes.className}
					style={classes.style}
			>
				{renderIcon}
			</button>
	);
};

MobileButtonIcon.defaultProps = {
	type: 'default',
	className: '',
	style: {},
	isActive: false,
	disabled: false,
	stop: false,
};

export default MobileButtonIcon;
