/* eslint-disable complexity */
import React, {
    useCallback, useEffect, useMemo, useState
} from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CommandFactory } from '../../../../background/commands/CommandFactory';
import PubsubSubscription from '../../../../background/repositories/PubsubSubscription';
import { QUESTIONER_MODE_EXTENRAL } from '../../../../background/socialv1/socialv1Reducer';

import MobileTab from '../../MobileTab/MobileTab';
import MobileButton from '../../Buttons/MobileButton';
import MobileButtonLink from '../../Buttons/MobileButtonLink';

import MobileProfileCopyCurrency from './MobileProfileCopyCurrency';
import MobileInputStepper from '../../MobileInputStepper/MobileInputStepper';
import { getProfileCopyOptions } from '../../../helpers/options.helpers';

const MobileProfileCopyMain = (props) => {
    const {
        nickname,
        CopyType,
        Amount,
        Instruments,
        alreadyCopied,
        handleAddFollower,
        handleUncopyFollower,
        MinimumEquityAllocationAmount,
        MaximumCopyByFixedLotAmount,
        DefaultEquityAllocationAmount,
        DefaultCopyByFixedLotAmount,
        yearPnl,
        setShowBlockDisclaimer
    } = props;

    const { t } = useTranslation();

    const {
        socialAccount: {
            questionerMode,
            externalRiskScore,
            queryRiskScoreCMD
        },
        profileCommands: { getDataForCopy },
        hideCopyByFixedLot
    } = useSelector((state) => state.account.socialv1, shallowEqual);

    const { depositUrl } = useSelector(
        (state) => state.account.accountInfo.featuresData.deposit, shallowEqual
    );

    const { symbol } = useSelector((state) => state.account.accountInfo.currency,
        shallowEqual);

    const { balance } = useSelector((state) => state.account.financial, shallowEqual);

    const pnlTick = PubsubSubscription.subscribeObj(
        { key: 'MOVINGPNL', repId: 'ACCOUNT_PNL' }
    );

    const [persistCopyTab, setPersistCopyTab] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [copyData, setCopyData] = useState(null);
    const [userData, setUserData] = useState({
        amount: null,
        equity: '',
        lotAmount: '',
        instruments: [],
        copyType: 0
    });

    const handleClickTab = useCallback(({ index }) => {
        setTabValue(index);
    }, [setTabValue]);

    const hideCopyByFixedLotState = useMemo(() => {
        let hideCopyByFixedLotState = hideCopyByFixedLot;
        const alreadyCopied = CopyType === 1 || CopyType === 2;
        if (hideCopyByFixedLotState && alreadyCopied && userData?.copyType === 1) {
            hideCopyByFixedLotState = false;
        }
        return hideCopyByFixedLotState;
    }, [hideCopyByFixedLot, CopyType, userData]);

    const optionsTab = useMemo(() => {
        return getProfileCopyOptions(hideCopyByFixedLotState);
    }, [hideCopyByFixedLotState]);

    const equityStepperAmount = useMemo(() => {
        return parseFloat(userData.equity);
    }, [userData, userData.equity]);

    const isOverCopy = useMemo(() => {
        return equityStepperAmount < pnlTick.equity
				&& (((userData?.copyType === 1 || userData?.copyType === 2)
					&& equityStepperAmount + copyData?.TotalAllocationMargin
					- userData?.amount > balance)
					|| (!userData?.copyType === 1 && !userData?.copyType === 2
						&& equityStepperAmount + copyData?.TotalAllocationMargin
						> equityStepperAmount > balance)) && tabValue === 0;
    }, [equityStepperAmount, pnlTick, userData, copyData, balance, tabValue]);

    const isInvalidEquityAmount = useMemo(() => {
        return equityStepperAmount > pnlTick.equity && tabValue === 0;
    }, [equityStepperAmount, pnlTick, tabValue]);

    const currentDesc = useMemo(() => {
        return tabValue === 0
            ? t('Social.CopyTab.ByEquityDesc',
                { nickname, symbol, equity: userData.equity })
            : t('Social.CopyTab.ByEquityDesc',
                { nickname, symbol: userData?.lotAmount, equity: '(lots)' });
    }, [tabValue, nickname, userData, symbol]);

    const disableButton = useMemo(() => {
        return parseFloat(userData?.lotAmount) <= 0
				|| parseFloat(userData?.equity) <= 0 || userData?.instruments === null;
    }, [userData]);

    const buttonTitle = useMemo(() => {
        return userData.copyType === 1 || userData.copyType === 2
            ? t('General.Save')
            : t('Socials.Button.Copy');
    }, [userData]);

    const uncopyText = useMemo(() => {
        return userData.copyType === 1
            ? t('Social.CopyTab.CurrCopyStatus',
                { nickname, symbol: userData?.amount, equity: '(lots)' }) // TODO: locale this
            : t('Social.CopyTab.CurrCopyStatus',
                { nickname, symbol, equity: userData?.amount });
    }, [userData, symbol, nickname]);

    const canCopy = (riskLevel, maxDrawDown) => {
        if (maxDrawDown <= 0 || maxDrawDown === undefined || maxDrawDown === null) {
            return false;
        }

        let masterScore = 0;
        if (maxDrawDown * 100 > 50)
        {
            masterScore = 2;
        } else if (maxDrawDown * 100 > 25) {
            masterScore = 1;
        }

        if (riskLevel <= 0) {
            return false;
        }

        if (riskLevel >= 1 && riskLevel < 25) {
            return masterScore === 0;
        }
        if (riskLevel >= 25 && riskLevel < 75) {
            return masterScore === 0 || masterScore === 1;
        }
        if (riskLevel >= 75) {
            return true;
        }

        return false;
    };

    const externalQuestionerMode = (riskLevel, data) => {
        setIsLoading(false);
        if (yearPnl?.UserMeasures?.MaxDrawDown) {
            if (canCopy(riskLevel, yearPnl?.UserMeasures?.MaxDrawDown)) {
                handleAddFollower(data);
            } else {
                setShowBlockDisclaimer(true);
            }
        } else {
            setShowBlockDisclaimer(true);
        }
    };

    const handleCopy = useCallback(() => {
        const data = {
            ...props,
            ...copyData,
            userData,
            tabValue
        };
        if (questionerMode === QUESTIONER_MODE_EXTENRAL) {
            setIsLoading(true);
            if (!externalRiskScore) {
                try {
                    CommandFactory.createCommand(queryRiskScoreCMD).execute()
                        .catch((ex) => { externalQuestionerMode(0, data); })
                        .then((value) => {
                            if (value?.payload?.socialAccount?.externalRiskScore) {
                                externalQuestionerMode(value.payload.socialAccount.externalRiskScore,data);
                            } else {
                                externalQuestionerMode(0, data);
                            }
                        });
                } catch (exception) { externalQuestionerMode(0, data); }
            } else {
                externalQuestionerMode(externalRiskScore,data);
            }
        } else {
            handleAddFollower && handleAddFollower(data);
        }
    }, [handleAddFollower, userData, tabValue, copyData]);

    const handleUncopy = useCallback(() => {
        handleUncopyFollower && handleUncopyFollower();
    }, [handleUncopyFollower]);

    const handleChangeEquity = useCallback((value) => {
        if (typeof value === 'string') {
            value = parseInt(value.replace(/,/g, ''));
        }
        if (value) {
            setUserData((prev) => ({
                ...prev,
                equity: value
            }));
        }
    }, [setUserData]);

    const handleChangeLotAmount = useCallback((value) => {
        setUserData((prev) => ({
            ...prev,
            lotAmount: value
        }));
    }, [setUserData]);

    const handleCurrencyChange = useCallback((data) => {
        setUserData((prev) => ({
            ...prev,
            instruments: data
        }));
    }, [setUserData]);

    const createUserData = useCallback(() => {
        setUserData((prev) => ({
            ...prev,
            amount: Amount,
            equity: CopyType === 2 ? Amount : DefaultEquityAllocationAmount,
            lotAmount: CopyType === 1 ? Amount : DefaultCopyByFixedLotAmount,
            instruments: Instruments || [],
            copyType: CopyType
        }));
        if (CopyType === 1) {
            setTabValue(1);
        }
    }, [
        CopyType,
        setUserData,
        Amount,
        Instruments,
        DefaultEquityAllocationAmount,
        setTabValue
    ]);

    const copyDataFetch = useCallback(async () => {
        if (!nickname || !getDataForCopy) return null;
        const userInput = { masterNickname: nickname };
        const commandObj = { ...getDataForCopy, userInput };
        const command = CommandFactory.createCommand(commandObj);
        try {
            setIsLoading(true);
            const res = await command.execute();
            if (res.isSuccessful) {
                setCopyData(res.payload);
                return res.payload;
            }
            if (!res.isSuccessful) {
                console.error('Error fetch copy data'); // TODO: remove log add error handler
            }
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            console.error(err); // TODO: remove log add error handler
        }
    }, [nickname, getDataForCopy]);

    useEffect(() => {
        copyDataFetch().then(() => setIsLoading(false));
        createUserData();
    }, [copyDataFetch, createUserData]);

    return (
        <div className='mobile-profile-copy__main'>

            <div className='mobile-profile-copy__main__scrollable'>
                {/* REMOVE FOLLOWER */}
                {alreadyCopied && (
                    <div className='mobile-profile-copy__main__already-copied'>
                        <p className='mobile-profile-copy__main__already-copied__text'>
                            {uncopyText}
                        </p>
                        <MobileButton
                            title={t('Button.Uncopy')}
                            onClick={handleUncopy}
                            type='secondary'
                        />
                    </div>
                )}

                <div className='mobile-profile-copy__main__tab'>
                    <MobileTab
                        options={optionsTab}
                        value={tabValue}
                        onClick={handleClickTab}
                    />
                </div>

                <div className='mobile-profile-copy__main__input'>
                    <div className='mobile-profile-copy__main__input-wrapper'>
                        {tabValue === 0 && (
                            <MobileInputStepper
                                selectedUnits={userData.equity}
                                setValue={handleChangeEquity}
                                min={MinimumEquityAllocationAmount}
                                stepValue={1}
                                pipDigit={0}
                                inputDescription={symbol}
                            />
                        )}
                        {!hideCopyByFixedLotState && tabValue === 1 && (
                            <MobileInputStepper
                                selectedUnits={userData.lotAmount}
                                setValue={handleChangeLotAmount}
                                max={MaximumCopyByFixedLotAmount}
                                stepValue={0.10}
                                pipDigit={2}
                                roundMax
                                inputDescription='lots'
                            />
                        )}
                    </div>
                    <div className='mobile-profile-copy__main__input-inner'>
                        {/* TODO: locae this */}
                        <p>
                            {tabValue === 0 ? symbol : 'lots'}
                        </p>
                        <p>
                            {tabValue === 0
                                ? t('General.Equity')
                                : t('Social.CopyTab.ByFixedHeader')}
                        </p>
                    </div>
                </div>

                <div className='mobile-profile-copy__main__desc'>
                    {currentDesc}
                </div>

                {/* ERROR */}
                <div className='mobile-profile-copy__main__error'>
                    {parseFloat(userData.equity) <= 0 && tabValue === 0 && (
                        <div className='warning'>
                            {t('Social.CopyTab.Errors.InvalidAmount')}
                        </div>
                    )}
                    {userData.equity === '' && tabValue === 0 && (
                        <div className='warning'>
                            {t('Social.CopyTab.Errors.EmptyEquity')}
                        </div>
                    )}
                    {parseFloat(userData.lotAmount) <= 0 && tabValue === 1 && (
                        <div className='warning'>
                            {t('Social.CopyTab.Errors.InvalidAmount')}
                        </div>
                    )}
                    {userData.lotAmount === '' && tabValue === 1 && (
                        <div className='warning'>
                            {t('Social.CopyTab.Errors.EmptyEquity')}
                        </div>
                    )}
                    {isInvalidEquityAmount && (
                        <div className='warning'>
                            {t('Social.CopyTab.Errors.InvalidAmountMaxEquity', {
                                equity: Math.abs(
                                    pnlTick.equity - parseFloat(userData.equity)
                                )
                            })}
                        </div>
                    )}
                    {isOverCopy && (
                        <div className='warning'>
                            {t('Social.CopyTab.Errors.InvalidAmountOverCopy')}
                        </div>
                    )}
                </div>

                {/* CURRENCY SELECT */}
                <MobileProfileCopyCurrency
                    userData={userData}
                    copyData={copyData}
                    handleChange={handleCurrencyChange}
                />
            </div>

            <div className='mobile-profile-copy__main__button'>
                {(isOverCopy || isInvalidEquityAmount) && (
                    <div className='mobile-profile-copy__main__button__wrapper'>
                        <MobileButtonLink
                            blank
                            title={t('Socials.Button.Copy')}
                            type='secondary'
                            url={depositUrl}
                        />
                    </div>
                )}

                {!isOverCopy && !isInvalidEquityAmount && (
                    <div className='mobile-profile-copy__main__button__wrapper'>
                        <MobileButton
                            title={buttonTitle}
                            disabled={disableButton}
                            onClick={handleCopy}
                        />
                    </div>
                )}
            </div>

        </div>
    );
};
export default MobileProfileCopyMain;
