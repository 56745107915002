import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';

import MobileButton from '../../Buttons/MobileButton';
import MobileLoader from '../../MobileLoader/MobileLoader';

const MobileProfileCopySuccess = ({onClose, title = '', requestData}) => {
	const [isLoading, setIsLoading] = useState(false);

	const {t} = useTranslation();

	const handleClose = useCallback(() => {
		setIsLoading(false);
		onClose && onClose();
	}, [onClose, setIsLoading]);

	const handleCloseClick = useCallback(() => {
		setIsLoading(true);
		requestData && requestData()
				.then(() => handleClose())
				.catch(() => handleClose());
	}, [handleClose, setIsLoading]);

	return (
			<>
				<MobileLoader isLoading={isLoading} size="large"/>

				<div className='mobile-profile-copy__success'>
					<div className="mobile-profile-copy__success__wrapper">
						<p className="mobile-profile-copy__success__text">
							{title}
						</p>
					</div>
					<div className="mobile-profile-copy__success__button">
						<MobileButton
								title={t('General.Close')}
								onClick={handleCloseClick}
						/>
					</div>
				</div>
			</>
	);
};

export default MobileProfileCopySuccess;
