import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';

const arrayLength = arr => {
	return Object.keys(arr).length;
};

export const CounterOpenPosition = () => {
	const {positions} = useSelector(state => state.account.positions.open,
			shallowEqual);
	const quantity = arrayLength(positions);

	return (
			<span className='mobile-position-counter'>
				{` (${quantity})`}
			</span>
	);
};

export const CounterPendingOrders = () => {
	const {positions} = useSelector(state => state.account.positions.pending,
			shallowEqual);
	const quantity = arrayLength(positions);

	return (
			<span className='mobile-position-counter'>
				{` (${quantity})`}
			</span>
	);
};

export const CounterClosedPosition = () => {
	const {positions, isGetAllRequsted} = useSelector(
			state => state.account.positions.closed, shallowEqual);
	const quantity = arrayLength(positions);

	if (!isGetAllRequsted) {
		// return <MobileLoader isLoading={!isGetAllRequsted}/>;
		return null;
	}

	return (
			<span
					className='mobile-position-counter'
			>
						{` (${quantity})`}
				</span>
	);
};

const MobilePositionCount = {
	CounterOpenPosition,
	CounterPendingOrders,
	CounterClosedPosition,
};

export default MobilePositionCount;


