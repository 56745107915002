export const getTouches = evt => evt.touches || evt.originalEvent.touches;

const disableClickOnPage = e => {
	e && e.preventDefault();
	e && e.stopImmediatePropagation();
	e && e.stopPropagation();
};

const addDisableClickOnPage = () => {
	document.addEventListener('click', disableClickOnPage, true);
	document.addEventListener('touchstart', disableClickOnPage, true);
	document.addEventListener('touchmove', disableClickOnPage, true);
	return null;
};

const removeDisableClickOnPage = () => {
	document.removeEventListener('click', disableClickOnPage, true);
	document.removeEventListener('touchstart', disableClickOnPage, true);
	document.removeEventListener('touchmove', disableClickOnPage, true);
	return null;
};

const disableClick = duration => {
	addDisableClickOnPage();
	const time = setTimeout(() => {
		removeDisableClickOnPage();
		clearTimeout(time);
	}, duration + 100);
};

const actionHeight = side => {
	const footer = {
		heightOpen: ((80 - side) * 0.25),
		heightClose: ((80 - side) * 0.7),
	};
	const trade = {
		heightOpen: (side * 0.2),
		heightClose: (side * 0.2),
	};
	const burger = {
		widthClose: (side * 0.25),
	};
	return {
		footer,
		trade,
		burger,
	};
};

const animationFunc = {
	move: 'linear',
	open: 'cubic-bezier(.17,.67,.83,.67)',
	close: 'cubic-bezier(.68,.4,.15,.74)',
};

// FOOTER
const handleSwipeFooterMove = data => {
	const {yMove, item, height} = data;
	let move = yMove;
	if (yMove > 0) move = 0;
	if (yMove < (80 - height)) move = 80 - height;

	if (item && item.style) {
		item.style.transition = `transform 0.05s ${animationFunc.move}`;
		item.style.transform = `translateY(${move}px)`;
	}
};
const handleSwipeFooterEnd = data => {
	const {height, yMove, isOpen, item, onOpen, onClose} = data;

	const {heightOpen, heightClose} = actionHeight(height).footer;

	const createTransition = () => {
		let anim = animationFunc.open;
		let duration = 100;
		const durationAll = 600;
		const heightPercent = Math.abs(yMove * 100 / height) / 100;

		if (!isOpen) {
			duration = durationAll * heightPercent;
		}
		if (isOpen) {
			duration = durationAll * (1 - heightPercent);
		}

		if (yMove === 0) {
			duration = 400;
			anim = animationFunc.close;
		}

		if (item) {
			item.style.transition = `transform ${duration}ms ${anim}`;
		}
	};

	const handleOpen = () => {
		createTransition();
		if (item) {
			item.style.transform = `translateY(${80 - height}px)`;
			onOpen && onOpen();
		}
	};

	const handleClose = () => {
		createTransition();
		if (item) {
			item.style.transform = `translateY(${0}px)`;
			onClose && onClose();
		}
	};

	if (!!yMove) {
		if (!isOpen) {
			if (yMove < heightOpen) {
				handleOpen();
			} else {
				handleClose();
			}
		}
		if (isOpen) {
			if (yMove < heightClose) {
				handleOpen();
			} else {
				handleClose();
			}
		}
	} else {
		if (isOpen) handleClose();
		if (!isOpen) handleOpen();
	}

};

export const swipeFooter = {
	start: data => data,
	move: handleSwipeFooterMove,
	end: handleSwipeFooterEnd,
};

//TRADE
const handleSwipeTradeMove = data => {
	const {yMove, item, height} = data;

	let move = yMove;
	if (yMove < 0) move = 0;
	if (yMove > height) move = height;

	if (item && item.style) {
		item.style.transition = `transform 0.05s ${animationFunc.move}`;
		item.style.transform = `translateY(${move}px)`;
	}
};
const handleSwipeTradeEnd = data => {
	const {height, yMove, isOpen, item, onOpen, onClose} = data;
	const {heightClose} = actionHeight(height).trade;

	const handleOpen = () => {
		if (item) {
			item.style.transition = `transform ${200}ms ${animationFunc.open}`;
			item.style.transform = `translateY(${0}px)`;
			onOpen && onOpen();
		}
	};

	const handleClose = () => {
		let duration = 100;
		const durationAll = 600;
		const heightPercent = Math.abs(yMove * 100 / height) / 100;
		duration = durationAll * heightPercent;

		if (yMove === 0) {
			duration = 400;
		}

		if (item) {
			item.style.transition = `transform ${duration}ms ${animationFunc.close}`;
			item.style.transform = `translateY(${height}px)`;
			disableClick(duration);
			const t = setTimeout(() => {
				onClose && onClose();
				clearTimeout(t);
			}, duration);
		}
	};

	if (!!yMove) {
		if (isOpen) {
			if (yMove > heightClose) {
				handleClose();
			} else {
				handleOpen();
			}
		}
	} else {
		if (isOpen) handleClose();
		if (!isOpen) handleOpen();
	}
};

export const swipeTrade = {
	start: data => data,
	move: handleSwipeTradeMove,
	end: handleSwipeTradeEnd,
};

// PROFILE
const handleSwipeProfileMove = data => {
	const {yMove, item, height} = data;

	let move = yMove;
	if (yMove < 0) move = 0;
	if (yMove > height) move = height;

	if (item && item.style) {
		item.style.transition = `transform 0.05s ${animationFunc.move}`;
		item.style.transform = `translateY(${move}px)`;
	}
};

const handleSwipeProfileEnd = data => {
	const {height, yMove, isOpen, item, onOpen, onClose} = data;
	const {heightClose} = actionHeight(height).trade;

	const handleOpen = () => {
		if (item) {
			item.style.transition = `transform ${200}ms ${animationFunc.open}`;
			item.style.transform = `translateY(${0}px)`;
			onOpen && onOpen();
		}
	};

	const handleClose = () => {
		let duration = 100;
		const durationAll = 600;
		const heightPercent = Math.abs(yMove * 100 / height) / 100;
		duration = durationAll * heightPercent;

		if (yMove === 0) {
			duration = 400;
		}

		if (item) {
			item.style.transition = `transform ${duration}ms ${animationFunc.close}`;
			item.style.transform = `translateY(${height}px)`;
			disableClick(duration);
			const t = setTimeout(() => {
				onClose && onClose();
				clearTimeout(t);
			}, duration);
		}
	};

	if (!!yMove) {
		if (isOpen) {
			if (yMove > heightClose) {
				handleClose();
			} else {
				handleOpen();
			}
		}
	} else {
		if (isOpen) handleClose();
	}
};

export const swipeProfile = {
	start: data => data,
	move: handleSwipeProfileMove,
	end: handleSwipeProfileEnd,
};

// BURGER
const handleSwipeBurgerMove = data => {
	const {xMove, item, width, onWidth} = data;

	let move = xMove;
	if (xMove > 0) move = 0;
	if (xMove < -width * 0.8) move = -width * 0.8;

	const percent = (((move + width * 0.8) * 100) / width) * 1.25;
	onWidth && onWidth(percent);

	// const header = document.getElementById('mobile-header');
	// if (header && header.style) {
	// 	header.style.marginLeft = `${(width * (percent / 1.25)) / 100}px`;
	// 	header.style.transition = `all 0.05s ${animationFunc.move}`;
	// }

	if (item && item.style) {
		item.style.transition = `transform 0.05s ${animationFunc.move}`;
		item.style.transform = `translateX(${move}px)`;
	}
};
const handleSwipeBurgerEnd = data => {
	const {width, xMove, isOpen, item, onOpen, onClose} = data;
	const {widthClose} = actionHeight(width).burger;

	const handleOpen = () => {
		if (item) {
			item.style.transition = `transform ${200}ms ${animationFunc.open}`;
			item.style.transform = `translateX(${0}px)`;
			onOpen && onOpen();
		}
	};

	const handleClose = () => {
		let duration = 300;
		const durationAll = 600;
		const heightPercent = Math.abs(xMove * 100 / width) / 100;
		duration = durationAll * heightPercent;

		if (xMove === 0) {
			duration = 300;
		}

		if (item) {
			item.style.transition = `transform ${duration}ms ${animationFunc.close}`;
			item.style.transform = `translateX(${-width}px)`;
			disableClick(duration);
			const t = setTimeout(() => {
				onClose && onClose();
				clearTimeout(t);
			}, duration);
		}
	};

	if (!!xMove) {
		if (isOpen) {
			if (xMove < -widthClose) {
				handleClose();
			} else {
				handleOpen();
			}
		}
	}
};

export const swipeBurger = {
	start: data => data,
	move: handleSwipeBurgerMove,
	end: handleSwipeBurgerEnd,
};
