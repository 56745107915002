import {createContext} from 'react';

const defaultValue = {
	theme: {},
	themeSettings: {},
	allowChangeTheme: false,
	activeTheme: 'dark',
	isDarkTheme: true,
	isLightTheme: false,
	handleToggleTheme: () => null,
	logo: '',
	hideLogo: false,
	lightTheme: {},
	darkTheme: {},
};

const ThemeContextMobile = createContext(defaultValue);

export default ThemeContextMobile;
